{
  "metadata": {
    "version": 4.5,
    "type": "Object",
    "generator": "Object3D.toJSON"
  },
  "geometries": [
    {
      "uuid": "86341542-03B4-4035-8EEC-8C134D3C39C2",
      "type": "BufferGeometry",
      "data": {
        "attributes": {
          "position": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              0.38006699085235596,
              8.315730094909668,
              0.05890360102057457,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              1.2560800313949585,
              12.066800117492676,
              0.05892520025372505,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              1.2560800313949585,
              12.066800117492676,
              0.05892520025372505,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              0.38006699085235596,
              8.315730094909668,
              0.7678700089454651,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              1.2560800313949585,
              12.066800117492676,
              0.7678700089454651,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              2.3156700134277344,
              8.315730094909668,
              0.7678700089454651,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              2.3156700134277344,
              12.066800117492676,
              0.7678700089454651,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              2.3156700134277344,
              12.066800117492676,
              0.7678700089454651,
              1.2560800313949585,
              12.066800117492676,
              0.7678700089454651,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              2.3156700134277344,
              12.066800117492676,
              0.7678700089454651,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              0.38006699085235596,
              8.315730094909668,
              0.05890360102057457,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              0.38006699085235596,
              8.315730094909668,
              0.7678700089454651,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              0.27921199798583984,
              8.315730094909668,
              0.15977999567985535,
              0.38006699085235596,
              8.315730094909668,
              0.05890360102057457,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              3.4007298946380615,
              8.315730094909668,
              0.15977999567985535,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              3.4007298946380615,
              8.315730094909668,
              0.15977999567985535,
              3.4007298946380615,
              8.315730094909668,
              0.667015016078949,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              2.3156700134277344,
              8.315730094909668,
              0.7678700089454651,
              3.4007298946380615,
              8.315730094909668,
              0.15977999567985535,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.4007298946380615,
              8.315730094909668,
              0.667015016078949,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.4007298946380615,
              12.066800117492676,
              0.667015016078949,
              3.4007298946380615,
              8.315730094909668,
              0.667015016078949,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.4007298946380615,
              12.066800117492676,
              0.667015016078949,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.3075900077819824,
              15.85669994354248,
              0.058925699442625046,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              3.3075900077819824,
              15.85669994354248,
              0.7678710222244263,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              3.4007298946380615,
              15.817999839782715,
              0.15978099405765533,
              3.3075900077819824,
              15.85669994354248,
              0.058925699442625046,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              0.3707889914512634,
              15.860199928283691,
              0.058925699442625046,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              0.3707889914512634,
              15.860199928283691,
              0.058925699442625046,
              0.27921199798583984,
              15.817999839782715,
              0.15978099405765533,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              0.27921199798583984,
              15.817999839782715,
              0.15978099405765533,
              0.27921199798583984,
              15.817999839782715,
              0.6670160293579102,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              1.2595900297164917,
              16.27050018310547,
              0.7678710222244263,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.27921199798583984,
              15.817999839782715,
              0.15978099405765533,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.27921199798583984,
              15.817999839782715,
              0.6670160293579102,
              0.27921199798583984,
              15.817999839782715,
              0.15978099405765533,
              0.27921199798583984,
              12.066800117492676,
              0.15977999567985535,
              0.27921199798583984,
              8.315730094909668,
              0.15977999567985535,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.27921199798583984,
              8.315730094909668,
              0.15977999567985535,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              1.2560800313949585,
              12.066800117492676,
              0.05892520025372505,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.536180019378662,
              14.794599533081055,
              0.21800699830055237,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.2560800313949585,
              12.066800117492676,
              0.7678700089454651,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.2560800313949585,
              12.066800117492676,
              0.7678700089454651,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.2560800313949585,
              12.066800117492676,
              0.7678700089454651,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              1.2560800313949585,
              8.315730094909668,
              0.7678700089454651,
              1.2560800313949585,
              8.315730094909668,
              0.05890360102057457,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              1.536180019378662,
              14.794599533081055,
              0.21800699830055237,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              2.3156700134277344,
              12.066800117492676,
              0.7678700089454651,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              2.3156700134277344,
              8.315730094909668,
              0.7678700089454651,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              2.3156700134277344,
              8.315730094909668,
              0.7678700089454651,
              1.4998699426651,
              12.066800117492676,
              0.645986020565033,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              2.3156700134277344,
              12.066800117492676,
              0.7678700089454651,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              1.9600600004196167,
              12.066800117492676,
              0.645986020565033,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              1.4998699426651,
              8.315730094909668,
              0.645986020565033,
              1.4998500347137451,
              8.315730094909668,
              0.18080900609493256,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              2.3156700134277344,
              8.315730094909668,
              0.7678700089454651,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              1.9600600004196167,
              8.315730094909668,
              0.645986020565033,
              1.9600800275802612,
              8.315730094909668,
              0.18080900609493256,
              2.3156700134277344,
              8.315730094909668,
              0.05890360102057457,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              0.7577660083770752,
              19.489599227905273,
              0.06452679634094238,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              0.7577660083770752,
              19.489599227905273,
              0.06452679634094238,
              0.6056810021400452,
              19.489599227905273,
              0.2165279984474182,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              0.6056810021400452,
              19.489599227905273,
              0.2165279984474182,
              0.6054900288581848,
              19.489599227905273,
              0.6102480292320251,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              2.7024800777435303,
              19.489599227905273,
              0.7622489929199219,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              2.7024800777435303,
              19.489599227905273,
              0.7622489929199219,
              2.8544399738311768,
              19.489599227905273,
              0.6102480292320251,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              2.854249954223633,
              19.489599227905273,
              0.2165279984474182,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              2.8544399738311768,
              19.489599227905273,
              0.6102480292320251,
              1.2595900297164917,
              16.27050018310547,
              0.7678710222244263,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.9515399932861328,
              13.876099586486816,
              0.20160600543022156,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              1.9515399932861328,
              13.876099586486816,
              0.20160600543022156,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.3707889914512634,
              15.860199928283691,
              0.058925699442625046,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              1.2595900297164917,
              16.27050018310547,
              0.058925699442625046,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              2.3121700286865234,
              16.27050018310547,
              0.058925699442625046,
              3.3075900077819824,
              15.85669994354248,
              0.058925699442625046,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              1.2595900297164917,
              16.27050018310547,
              0.7678710222244263,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              1.2595900297164917,
              16.27050018310547,
              0.7678710222244263,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              3.3075900077819824,
              15.85669994354248,
              0.7678710222244263,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              3.4007298946380615,
              15.817999839782715,
              0.15978099405765533,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              1.5361599922180176,
              14.794599533081055,
              0.6087679862976074,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.2595900297164917,
              16.27050018310547,
              0.7678710222244263,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.5361599922180176,
              14.794599533081055,
              0.6087679862976074,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              2.3121700286865234,
              16.27050018310547,
              0.7678710222244263,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              1.5361599922180176,
              14.794599533081055,
              0.6087679862976074,
              1.536180019378662,
              14.794599533081055,
              0.21800699830055237,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              1.536180019378662,
              14.794599533081055,
              0.21800699830055237,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              1.9503599405288696,
              14.826899528503418,
              0.6236050128936768,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              1.9503200054168701,
              14.826899528503418,
              0.20317000150680542,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.9515399932861328,
              13.876099586486816,
              0.20160600543022156,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.9515600204467773,
              13.876099586486816,
              0.625190019607544,
              1.9515399932861328,
              13.876099586486816,
              0.20160600543022156,
              1.9515399932861328,
              13.876099586486816,
              0.20160600543022156,
              1.9515600204467773,
              13.876099586486816,
              0.625190019607544,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              1.9515600204467773,
              13.876099586486816,
              0.625190019607544,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              2.1449899673461914,
              14.68970012664795,
              0.6457539796829224,
              2.1449899673461914,
              14.68970012664795,
              0.18102100491523743,
              1.536180019378662,
              14.794599533081055,
              0.21800699830055237,
              1.5361599922180176,
              14.794599533081055,
              0.6087679862976074,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.5361599922180176,
              14.794599533081055,
              0.6087679862976074,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.4283900260925293,
              14.68970012664795,
              0.18102100491523743,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.4283900260925293,
              14.68970012664795,
              0.6457539796829224,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.5345799922943115,
              13.908699989318848,
              0.2167389988899231,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              1.4325599670410156,
              13.51159954071045,
              0.6452249884605408,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.5558600425720215,
              13.444700241088867,
              0.595579981803894,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              2.140929937362671,
              13.51159954071045,
              0.6452249884605408,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              1.9348900318145752,
              13.42300033569336,
              0.6056399941444397,
              0.27921199798583984,
              12.066800117492676,
              0.15977999567985535,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.2560800313949585,
              12.066800117492676,
              0.05892520025372505,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              1.4998500347137451,
              12.066800117492676,
              0.18080900609493256,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              2.3156700134277344,
              12.066800117492676,
              0.05892520025372505,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              1.9600800275802612,
              12.066800117492676,
              0.18080900609493256,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              1.4325599670410156,
              13.51159954071045,
              0.18157100677490234,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              1.5558600425720215,
              13.444700241088867,
              0.23121599853038788,
              1.5276399850845337,
              13.541099548339844,
              0.20957399904727936,
              2.140929937362671,
              13.51159954071045,
              0.18157100677490234,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              1.9348900318145752,
              13.42300033569336,
              0.2211350053548813,
              1.9532599449157715,
              13.515000343322754,
              0.19744299352169037,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              1.430150032043457,
              14.009200096130371,
              0.1825850009918213,
              1.395400047302246,
              13.621500015258789,
              0.15790000557899475,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              2.1776599884033203,
              13.621500015258789,
              0.15790000557899475,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              2.143239974975586,
              14.009200096130371,
              0.1825850009918213,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.430150032043457,
              14.009200096130371,
              0.6442109942436218,
              1.395400047302246,
              13.621500015258789,
              0.6688960194587708,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.9515600204467773,
              13.876099586486816,
              0.625190019607544,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              1.5345499515533447,
              13.908699989318848,
              0.6100360155105591,
              1.5276399850845337,
              13.541099548339844,
              0.617222011089325,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              2.1776599884033203,
              13.621500015258789,
              0.6688960194587708,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              2.143239974975586,
              14.009200096130371,
              0.6442109942436218,
              1.9515600204467773,
              13.876099586486816,
              0.625190019607544,
              1.9532599449157715,
              13.515000343322754,
              0.6293320059776306,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.4565000534057617,
              16.28190040588379,
              0.13674399256706238,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.4564199447631836,
              16.28190040588379,
              0.6900309920310974,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              2.003509998321533,
              16.28190040588379,
              0.6900309920310974,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              2.003429889678955,
              16.28190040588379,
              0.13674399256706238,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.532080054283142,
              16.28190040588379,
              0.2123430073261261,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.532039999961853,
              16.28190040588379,
              0.6144319772720337,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.9278899431228638,
              16.28190040588379,
              0.6144319772720337,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              1.9278500080108643,
              16.28190040588379,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.1693899631500244,
              16.3257999420166,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.1693899631500244,
              16.3257999420166,
              0.2123430073261261,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              2.2905499935150146,
              16.3257999420166,
              0.2123430073261261,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.169219970703125,
              16.3257999420166,
              0.6144319772720337,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.169219970703125,
              16.3257999420166,
              0.6144319772720337,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.1693899631500244,
              16.3257999420166,
              0.2123430073261261,
              1.532080054283142,
              16.294700622558594,
              0.2123430073261261,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              2.290709972381592,
              16.3257999420166,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              2.290709972381592,
              16.3257999420166,
              0.6144319772720337,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              1.169219970703125,
              16.3257999420166,
              0.6144319772720337,
              1.532039999961853,
              16.294700622558594,
              0.6144319772720337,
              1.9278500080108643,
              16.294700622558594,
              0.2123430073261261,
              2.2905499935150146,
              16.3257999420166,
              0.2123430073261261,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              2.2905499935150146,
              16.3257999420166,
              0.2123430073261261,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              2.290709972381592,
              16.3257999420166,
              0.6144319772720337,
              1.9278899431228638,
              16.294700622558594,
              0.6144319772720337,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.2905499935150146,
              16.3257999420166,
              0.2123430073261261,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              1.1693899631500244,
              16.3257999420166,
              0.2123430073261261,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              1.169219970703125,
              16.3257999420166,
              0.6144319772720337,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              2.290709972381592,
              16.3257999420166,
              0.6144319772720337,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              2.37785005569458,
              16.33329963684082,
              0.29994699358940125,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              2.290210008621216,
              16.33329963684082,
              0.2123430073261261,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              1.169700026512146,
              16.33329963684082,
              0.2123430073261261,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              1.0820800065994263,
              16.33329963684082,
              0.29994699358940125,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              1.081969976425171,
              16.33329963684082,
              0.5268290042877197,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              1.1695400476455688,
              16.33329963684082,
              0.6144319772720337,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              2.2904000282287598,
              16.33329963684082,
              0.6144319772720337,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              2.377959966659546,
              16.33329963684082,
              0.5268290042877197,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              1.169700026512146,
              18.91950035095215,
              0.2123430073261261,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              1.0820800065994263,
              18.91950035095215,
              0.29994699358940125,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              1.081969976425171,
              18.91950035095215,
              0.5268290042877197,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              1.1695400476455688,
              18.91950035095215,
              0.6144329905509949,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              2.2904000282287598,
              18.91950035095215,
              0.6144329905509949,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              2.377959966659546,
              18.91950035095215,
              0.5268290042877197,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.37785005569458,
              18.91950035095215,
              0.29994699358940125,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              2.290210008621216,
              18.91950035095215,
              0.2123430073261261,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              0.7577660083770752,
              19.078500747680664,
              0.06452679634094238,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.6056810021400452,
              19.078500747680664,
              0.21654899418354034,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.6054900288581848,
              19.078500747680664,
              0.6102480292320251,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              0.7574489712715149,
              19.078500747680664,
              0.7622489929199219,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              2.7024800777435303,
              19.078500747680664,
              0.7622489929199219,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.8544399738311768,
              19.078500747680664,
              0.6102480292320251,
              2.702169895172119,
              19.078500747680664,
              0.06452679634094238,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.854249954223633,
              19.078500747680664,
              0.21654899418354034,
              0.7577660083770752,
              19.489599227905273,
              0.06452679634094238,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              0.6056810021400452,
              19.489599227905273,
              0.2165279984474182,
              0.7577660083770752,
              19.489599227905273,
              0.06452679634094238,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.7577660083770752,
              19.489599227905273,
              0.06452679634094238,
              0.7065150141716003,
              19.296300888061523,
              0.003954499959945679,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.6054900288581848,
              19.489599227905273,
              0.6102480292320251,
              0.6056810021400452,
              19.489599227905273,
              0.2165279984474182,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.6056810021400452,
              19.489599227905273,
              0.2165279984474182,
              0.5496100187301636,
              19.295299530029297,
              0.24888500571250916,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              0.6054900288581848,
              19.489599227905273,
              0.6102480292320251,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              0.6054900288581848,
              19.489599227905273,
              0.6102480292320251,
              0.5494199991226196,
              19.295299530029297,
              0.5778909921646118,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              2.7024800777435303,
              19.489599227905273,
              0.7622489929199219,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              0.7574489712715149,
              19.489599227905273,
              0.7622489929199219,
              0.7061759829521179,
              19.296300888061523,
              0.8228219747543335,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              2.8544399738311768,
              19.489599227905273,
              0.6102480292320251,
              2.7024800777435303,
              19.489599227905273,
              0.7622489929199219,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.7024800777435303,
              19.489599227905273,
              0.7622489929199219,
              2.753740072250366,
              19.296300888061523,
              0.8228219747543335,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.854249954223633,
              19.489599227905273,
              0.2165279984474182,
              2.8544399738311768,
              19.489599227905273,
              0.6102480292320251,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.8544399738311768,
              19.489599227905273,
              0.6102480292320251,
              2.9105100631713867,
              19.295299530029297,
              0.5778909921646118,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.702169895172119,
              19.489599227905273,
              0.06452679634094238,
              2.854249954223633,
              19.489599227905273,
              0.2165279984474182,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              2.854249954223633,
              19.489599227905273,
              0.2165279984474182,
              2.9103200435638428,
              19.295299530029297,
              0.24888500571250916,
              2.753420114517212,
              19.296300888061523,
              0.003954499959945679,
              0.38006699085235596,
              8.315730094909668,
              0.05890360102057457,
              0.27921199798583984,
              8.315730094909668,
              0.15977999567985535,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              0.27921199798583984,
              8.315730094909668,
              0.15977999567985535,
              0.27921199798583984,
              12.066800117492676,
              0.15977999567985535,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.4007298946380615,
              8.315730094909668,
              0.15977999567985535,
              3.2998600006103516,
              8.315730094909668,
              0.05890360102057457,
              0.38006699085235596,
              12.066800117492676,
              0.05892520025372505,
              0.27921199798583984,
              12.066800117492676,
              0.15977999567985535,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              0.27921199798583984,
              12.066800117492676,
              0.15977999567985535,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              3.3075900077819824,
              15.85669994354248,
              0.058925699442625046,
              3.4007298946380615,
              15.817999839782715,
              0.15978099405765533,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              3.4007298946380615,
              15.817999839782715,
              0.15978099405765533,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.38006699085235596,
              8.315730094909668,
              0.7678700089454651,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.27921199798583984,
              8.315730094909668,
              0.667015016078949,
              0.38006699085235596,
              8.315730094909668,
              0.7678700089454651,
              3.2998600006103516,
              8.315730094909668,
              0.7678700089454651,
              3.4007298946380615,
              8.315730094909668,
              0.667015016078949,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              3.4007298946380615,
              8.315730094909668,
              0.667015016078949,
              3.4007298946380615,
              12.066800117492676,
              0.667015016078949,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              0.3707889914512634,
              15.860199928283691,
              0.7678710222244263,
              0.27921199798583984,
              15.817999839782715,
              0.6670160293579102,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              0.27921199798583984,
              15.817999839782715,
              0.6670160293579102,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              3.2998600006103516,
              12.066800117492676,
              0.7678700089454651,
              3.4007298946380615,
              12.066800117492676,
              0.667015016078949,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              3.4007298946380615,
              12.066800117492676,
              0.667015016078949,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              0.37877801060676575,
              14.562199592590332,
              0.757282018661499,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              0.27921199798583984,
              14.550100326538086,
              0.667015016078949,
              0.27921199798583984,
              13.653200149536133,
              0.667015016078949,
              0.37505799531936646,
              13.68280029296875,
              0.7575780153274536,
              0.37877801060676575,
              14.562199592590332,
              0.0694928988814354,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.3707889914512634,
              15.860199928283691,
              0.058925699442625046,
              0.27921199798583984,
              14.550100326538086,
              0.15978099405765533,
              0.27921199798583984,
              15.817999839782715,
              0.15978099405765533,
              0.3707889914512634,
              15.860199928283691,
              0.058925699442625046,
              3.3009400367736816,
              14.561200141906738,
              0.06862639635801315,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              3.4007298946380615,
              14.550100326538086,
              0.15978099405765533,
              3.4007298946380615,
              13.653200149536133,
              0.15978099405765533,
              3.304110050201416,
              13.6806001663208,
              0.06841489672660828,
              3.3009400367736816,
              14.561200141906738,
              0.7581700086593628,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.3075900077819824,
              15.85669994354248,
              0.7678710222244263,
              3.4007298946380615,
              14.550100326538086,
              0.667015016078949,
              3.4007298946380615,
              15.817999839782715,
              0.6670160293579102,
              3.3075900077819824,
              15.85669994354248,
              0.7678710222244263,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.27921199798583984,
              12.066800117492676,
              0.667015016078949,
              0.38006699085235596,
              12.066800117492676,
              0.7678700089454651,
              0.5489130020141602,
              13.298299789428711,
              0.10749299824237823,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              0.4511849880218506,
              13.274800300598145,
              0.20016899704933167,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              3.4007298946380615,
              12.066800117492676,
              0.15977999567985535,
              3.2998600006103516,
              12.066800117492676,
              0.05892520025372505,
              3.118839979171753,
              13.29640007019043,
              0.7199789881706238,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              3.2170701026916504,
              13.274800300598145,
              0.6266270279884338,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              0.37657999992370605,
              13.36139965057373,
              0.7592480182647705,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              0.27921199798583984,
              13.336600303649902,
              0.667015016078949,
              0.4511849880218506,
              13.274800300598145,
              0.6266270279884338,
              0.5489130020141602,
              13.298299789428711,
              0.7193030118942261,
              0.37657999992370605,
              13.36139965057373,
              0.06754840165376663,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              0.27921199798583984,
              13.336600303649902,
              0.15978099405765533,
              0.27921199798583984,
              13.653200149536133,
              0.15978099405765533,
              0.37505799531936646,
              13.68280029296875,
              0.06921800225973129,
              3.302799940109253,
              13.359399795532227,
              0.06682980060577393,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              3.4007298946380615,
              13.336600303649902,
              0.15978099405765533,
              3.2170701026916504,
              13.274800300598145,
              0.20016899704933167,
              3.118839979171753,
              13.29640007019043,
              0.10681699961423874,
              3.302799940109253,
              13.359399795532227,
              0.7599449753761292,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              3.4007298946380615,
              13.336600303649902,
              0.667015016078949,
              3.4007298946380615,
              13.653200149536133,
              0.667015016078949,
              3.304110050201416,
              13.6806001663208,
              0.7583600282669067,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.40517500042915344,
              13.304900169372559,
              0.3611319959163666,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.3611319959163666,
              0.441210001707077,
              13.288999557495117,
              0.29290899634361267,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.3611319959163666,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.3331049978733063,
              13.336600303649902,
              0.3611319959163666,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.3331049978733063,
              13.336600303649902,
              0.3611319959163666,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.3331049978733063,
              13.336600303649902,
              0.3611319959163666,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.3331049978733063,
              13.336600303649902,
              0.2247070074081421,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.3331049978733063,
              13.336600303649902,
              0.2247070074081421,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.40517500042915344,
              13.304900169372559,
              0.2247070074081421,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.40517500042915344,
              13.304900169372559,
              0.2247070074081421,
              0.3331049978733063,
              13.336600303649902,
              0.2247070074081421,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.441210001707077,
              13.288999557495117,
              0.29290899634361267,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.441210001707077,
              13.288999557495117,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.2247070074081421,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.4675230085849762,
              13.446200370788574,
              0.3611319959163666,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.39243099093437195,
              13.471099853515625,
              0.3611319959163666,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.3548949956893921,
              13.483599662780762,
              0.29290899634361267,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.39243099093437195,
              13.471099853515625,
              0.2247070074081421,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.5050789713859558,
              13.433799743652344,
              0.29290899634361267,
              0.4675230085849762,
              13.446200370788574,
              0.2247070074081421,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.5086089968681335,
              13.625499725341797,
              0.3611319959163666,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.43081098794937134,
              13.63860034942627,
              0.3611319959163666,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.3919230103492737,
              13.645099639892578,
              0.29290899634361267,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.43081098794937134,
              13.63860034942627,
              0.2247070074081421,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.5475180149078369,
              13.618900299072266,
              0.29290899634361267,
              0.5086089968681335,
              13.625499725341797,
              0.2247070074081421,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.531158983707428,
              13.831999778747559,
              0.3611319959163666,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.4525589942932129,
              13.837699890136719,
              0.3611319959163666,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.4132480025291443,
              13.84060001373291,
              0.29290899634361267,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.4525589942932129,
              13.837699890136719,
              0.2247070074081421,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.5704699754714966,
              13.82919979095459,
              0.29290899634361267,
              0.531158983707428,
              13.831999778747559,
              0.2247070074081421,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.5393379926681519,
              14.060799598693848,
              0.3611319959163666,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.46056899428367615,
              14.061800003051758,
              0.3611319959163666,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.42117398977279663,
              14.062299728393555,
              0.29290899634361267,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.46056899428367615,
              14.061800003051758,
              0.2247070074081421,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.5787129998207092,
              14.06029987335205,
              0.29290899634361267,
              0.5393379926681519,
              14.060799598693848,
              0.2247070074081421,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.5368450284004211,
              14.306400299072266,
              0.3611319959163666,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.4580959975719452,
              14.30459976196289,
              0.3611319959163666,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.4187430143356323,
              14.303600311279297,
              0.29290899634361267,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.4580959975719452,
              14.30459976196289,
              0.2247070074081421,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.5762190222740173,
              14.30739974975586,
              0.29290899634361267,
              0.5368450284004211,
              14.306400299072266,
              0.2247070074081421,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.5273129940032959,
              14.563199996948242,
              0.3611319959163666,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.4486280083656311,
              14.559800148010254,
              0.3611319959163666,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.4092960059642792,
              14.557999610900879,
              0.29290899634361267,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.4486280083656311,
              14.559800148010254,
              0.2247070074081421,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.5666660070419312,
              14.564900398254395,
              0.29290899634361267,
              0.5273129940032959,
              14.563199996948242,
              0.2247070074081421,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.5145260095596313,
              14.820199966430664,
              0.3611319959163666,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.4356510043144226,
              14.820899963378906,
              0.36111098527908325,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.39606600999832153,
              14.82409954071045,
              0.29290899634361267,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.43537598848342896,
              14.826499938964844,
              0.2247070074081421,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              0.5538579821586609,
              14.822600364685059,
              0.292930006980896,
              0.5142729878425598,
              14.825699806213379,
              0.2247070074081421,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              0.61593097448349,
              16.288700103759766,
              0.4806489944458008,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              0.536063015460968,
              16.27519989013672,
              0.47971901297569275,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              0.49571600556373596,
              16.270099639892578,
              0.41096800565719604,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              0.5352169871330261,
              16.278400421142578,
              0.34312498569488525,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              0.6554530262947083,
              16.297000885009766,
              0.4128060042858124,
              0.615086019039154,
              16.291900634765625,
              0.34403398633003235,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              -2.1393001079559326,
              22.78499984741211,
              0.2333730012178421,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              -2.215240001678467,
              22.763900756835938,
              0.24279899895191193,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              -2.2200000286102295,
              22.653600692749023,
              0.1833679974079132,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              -2.1487700939178467,
              22.56450080871582,
              0.11451099812984467,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -2.0680999755859375,
              22.695899963378906,
              0.1645369976758957,
              -2.0728399753570557,
              22.585599899291992,
              0.10510600358247757,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -1.9665900468826294,
              21.90760040283203,
              0.22523599863052368,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -1.9665900468826294,
              21.90760040283203,
              0.22523599863052368,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -2.0985100269317627,
              22.63159942626953,
              -0.11712600290775299,
              -1.9665900468826294,
              21.90760040283203,
              0.22523599863052368,
              -2.0741000175476074,
              21.926700592041016,
              0.30702799558639526,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.1723999977111816,
              22.606399536132812,
              -0.09459660202264786,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.0741000175476074,
              21.926700592041016,
              0.30702799558639526,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.190390110015869,
              22.564899444580078,
              0.005222979933023453,
              -2.0741000175476074,
              21.926700592041016,
              0.30702799558639526,
              -1.9501899480819702,
              21.967899322509766,
              0.34648600220680237,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -2.1344900131225586,
              22.548599243164062,
              0.08251290023326874,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -1.9501899480819702,
              21.967899322509766,
              0.34648600220680237,
              -2.042609930038452,
              22.615299224853516,
              -0.039836399257183075,
              -2.0606000423431396,
              22.57379913330078,
              0.05998320132493973,
              -1.9501899480819702,
              21.967899322509766,
              0.34648600220680237,
              0.3331049978733063,
              13.336600303649902,
              0.2247070074081421,
              0.40517500042915344,
              13.304900169372559,
              0.2247070074081421,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.2247070074081421,
              0.441210001707077,
              13.288999557495117,
              0.29290899634361267,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.3331049978733063,
              13.336600303649902,
              0.3611319959163666,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.3611319959163666,
              0.2970919907093048,
              13.352499961853027,
              0.29290899634361267,
              0.441210001707077,
              13.288999557495117,
              0.29290899634361267,
              0.40517500042915344,
              13.304900169372559,
              0.3611319959163666,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -1.9665900468826294,
              21.90760040283203,
              0.22523599863052368,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -2.0741000175476074,
              21.926700592041016,
              0.30702799558639526,
              -2.0437300205230713,
              21.90019989013672,
              0.23933300375938416,
              -1.9501899480819702,
              21.967899322509766,
              0.34648600220680237,
              -2.027329921722412,
              21.960500717163086,
              0.36058300733566284,
              -1.9198399782180786,
              21.9414005279541,
              0.2788130044937134,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.9907000064849854,
              12.755999565124512,
              0.3672179877758026,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              2.9907000064849854,
              12.755999565124512,
              0.3672179877758026,
              3.0300300121307373,
              12.75790023803711,
              0.2989949882030487,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.912100076675415,
              12.751099586486816,
              0.3672179877758026,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.912100076675415,
              12.751099586486816,
              0.3672179877758026,
              2.9907000064849854,
              12.755999565124512,
              0.3672179877758026,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.912100076675415,
              12.751099586486816,
              0.3672179877758026,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.9121599197387695,
              12.749899864196777,
              0.23079299926757812,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.9121599197387695,
              12.749899864196777,
              0.23079299926757812,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              2.990760087966919,
              12.754799842834473,
              0.23079299926757812,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.990760087966919,
              12.754799842834473,
              0.23079299926757812,
              2.9121599197387695,
              12.749899864196777,
              0.23079299926757812,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              3.0300300121307373,
              12.75790023803711,
              0.2989949882030487,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              3.0300300121307373,
              12.75790023803711,
              0.2989949882030487,
              2.990760087966919,
              12.754799842834473,
              0.23079299926757812,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.930459976196289,
              13.715499877929688,
              0.3586589992046356,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.8518600463867188,
              13.710700035095215,
              0.3586589992046356,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.812570095062256,
              13.707799911499023,
              0.2904359996318817,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              2.8519299030303955,
              13.709600448608398,
              0.22223399579524994,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.969820022583008,
              13.717300415039062,
              0.29045701026916504,
              2.9305500984191895,
              13.714300155639648,
              0.22223399579524994,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.8911499977111816,
              14.393799781799316,
              0.35305801033973694,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.8125100135803223,
              14.389599800109863,
              0.35305801033973694,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.7732200622558594,
              14.38700008392334,
              0.28485599160194397,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.812570095062256,
              14.38860034942627,
              0.2166540026664734,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.9305100440979004,
              14.395400047302246,
              0.28485599160194397,
              2.8912200927734375,
              14.392800331115723,
              0.2166540026664734,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.86926007270813,
              14.837699890136719,
              0.34995099902153015,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.7905499935150146,
              14.834600448608398,
              0.34995099902153015,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.7512400150299072,
              14.832599639892578,
              0.28172799944877625,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.7906200885772705,
              14.833800315856934,
              0.21352599561214447,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.908629894256592,
              14.838899612426758,
              0.28174999356269836,
              2.86929988861084,
              14.836999893188477,
              0.21354800462722778,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.8613100051879883,
              15.093400001525879,
              0.3488309979438782,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.782520055770874,
              15.093000411987305,
              0.34880998730659485,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.743149995803833,
              15.092700004577637,
              0.28058698773384094,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.7825400829315186,
              15.092900276184082,
              0.21238499879837036,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.900710105895996,
              15.093500137329102,
              0.2806290090084076,
              2.861330032348633,
              15.093299865722656,
              0.2124059945344925,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.863529920578003,
              15.199999809265137,
              0.3491480052471161,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.785099983215332,
              15.216099739074707,
              0.3491699993610382,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.7459399700164795,
              15.226099967956543,
              0.2809680104255676,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.7852001190185547,
              15.220000267028809,
              0.21276600658893585,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.9028000831604004,
              15.193900108337402,
              0.2809250056743622,
              2.8636600971221924,
              15.203900337219238,
              0.21274499595165253,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.8315999507904053,
              15.121000289916992,
              0.3446039855480194,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.8195700645446777,
              15.301300048828125,
              0.35405200719833374,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.8217499256134033,
              15.413599967956543,
              0.2917250096797943,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.835969924926758,
              15.345600128173828,
              0.21997299790382385,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.8457999229431152,
              15.053000450134277,
              0.27285200357437134,
              2.8480000495910645,
              15.165200233459473,
              0.2105250060558319,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.9018099308013916,
              14.993000030517578,
              0.35457998514175415,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.7976999282836914,
              15.341099739074707,
              0.35094499588012695,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.7425498962402344,
              15.55780029296875,
              0.2804819941520691,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.791520118713379,
              15.42650032043457,
              0.21365299820899963,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              2.9507699012756348,
              14.861700057983398,
              0.2877520024776459,
              2.8956298828125,
              15.078399658203125,
              0.21728800237178802,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              2.7842800617218018,
              16.664100646972656,
              0.33606600761413574,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              2.7043399810791016,
              16.67840003967285,
              0.33872899413108826,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              2.6619699001312256,
              16.684200286865234,
              0.27190101146698,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              2.699549913406372,
              16.675800323486328,
              0.2024099975824356,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              2.8218300342559814,
              16.655799865722656,
              0.26657500863075256,
              2.77947998046875,
              16.66160011291504,
              0.1997469961643219,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              5.514679908752441,
              22.75860023498535,
              0.09003689885139465,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              5.448609828948975,
              22.802600860595703,
              0.08107569813728333,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              5.378719806671143,
              22.724700927734375,
              0.012725800275802612,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              5.3748698234558105,
              22.6028995513916,
              -0.04666290059685707,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.510829925537109,
              22.636699676513672,
              0.03064809925854206,
              5.440939903259277,
              22.558799743652344,
              -0.0377018004655838,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.194169998168945,
              21.9237003326416,
              -0.29922398924827576,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.194169998168945,
              21.9237003326416,
              -0.29922398924827576,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.5149102210998535,
              22.6117000579834,
              -0.18270699679851532,
              5.194169998168945,
              21.9237003326416,
              -0.29922398924827576,
              5.072179794311523,
              21.975000381469727,
              -0.26606300473213196,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.448019981384277,
              22.6476993560791,
              -0.2037149965763092,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.072179794311523,
              21.975000381469727,
              -0.26606300473213196,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.378459930419922,
              22.631999969482422,
              -0.15480999648571014,
              5.072179794311523,
              21.975000381469727,
              -0.26606300473213196,
              5.1521501541137695,
              21.921300888061523,
              -0.16945600509643555,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.375760078430176,
              22.580400466918945,
              -0.08493799716234207,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.1521501541137695,
              21.921300888061523,
              -0.16945600509643555,
              5.512199878692627,
              22.560199737548828,
              -0.1128150001168251,
              5.442649841308594,
              22.54450035095215,
              -0.06392999738454819,
              5.1521501541137695,
              21.921300888061523,
              -0.16945600509643555,
              2.9121599197387695,
              12.749899864196777,
              0.23079299926757812,
              2.990760087966919,
              12.754799842834473,
              0.23079299926757812,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.990760087966919,
              12.754799842834473,
              0.23079299926757812,
              3.0300300121307373,
              12.75790023803711,
              0.2989949882030487,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.912100076675415,
              12.751099586486816,
              0.3672179877758026,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              2.9907000064849854,
              12.755999565124512,
              0.3672179877758026,
              2.8728299140930176,
              12.748000144958496,
              0.2989949882030487,
              3.0300300121307373,
              12.75790023803711,
              0.2989949882030487,
              2.9907000064849854,
              12.755999565124512,
              0.3672179877758026,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.194169998168945,
              21.9237003326416,
              -0.29922398924827576,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.072179794311523,
              21.975000381469727,
              -0.26606300473213196,
              5.126850128173828,
              21.95870018005371,
              -0.32038000226020813,
              5.1521501541137695,
              21.921300888061523,
              -0.16945600509643555,
              5.084839820861816,
              21.956300735473633,
              -0.19061200320720673,
              5.206830024719238,
              21.905000686645508,
              -0.2237509936094284,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              -0.9170169830322266,
              16.394899368286133,
              6.2871599197387695,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -3.089329957962036,
              16.394899368286133,
              4.83568000793457,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -4.5408101081848145,
              16.394899368286133,
              2.663360118865967,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -5.050519943237305,
              16.394899368286133,
              0.10096299648284912,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -4.5408101081848145,
              16.394899368286133,
              -2.461440086364746,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -3.089329957962036,
              16.394899368286133,
              -4.633739948272705,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              -0.9170169830322266,
              16.394899368286133,
              -6.085229873657227,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              1.6453900337219238,
              16.394899368286133,
              -6.594940185546875,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              4.207789897918701,
              16.394899368286133,
              -6.085229873657227,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              6.380099773406982,
              16.394899368286133,
              -4.633739948272705,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              7.83158016204834,
              16.394899368286133,
              -2.461440086364746,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              8.341279983520508,
              16.394899368286133,
              0.10096299648284912,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              7.83158016204834,
              16.394899368286133,
              2.6633899211883545,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              6.380080223083496,
              16.394899368286133,
              4.83568000793457,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              4.207789897918701,
              16.394899368286133,
              6.2871599197387695,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              1.6453900337219238,
              16.394899368286133,
              6.796860218048096,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              -1.5480799674987793,
              19.261899948120117,
              7.810679912567139,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -4.255360126495361,
              19.261899948120117,
              6.001709938049316,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -6.064310073852539,
              19.261899948120117,
              3.2944300174713135,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -6.699540138244629,
              19.261899948120117,
              0.10096299648284912,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -6.064310073852539,
              19.261899948120117,
              -3.0924999713897705,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -4.255360126495361,
              19.261899948120117,
              -5.799779891967773,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              -1.5480799674987793,
              19.261899948120117,
              -7.608729839324951,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              1.6453900337219238,
              19.261899948120117,
              -8.2439603805542,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              4.838850021362305,
              19.261899948120117,
              -7.608729839324951,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              7.546130180358887,
              19.261899948120117,
              -5.799779891967773,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              9.355079650878906,
              19.261899948120117,
              -3.0924999713897705,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              9.990309715270996,
              19.261899948120117,
              0.10096299648284912,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              9.355079650878906,
              19.261899948120117,
              3.2944300174713135,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              7.546130180358887,
              19.261899948120117,
              6.001729965209961,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              4.83882999420166,
              19.261899948120117,
              7.810679912567139,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              1.6453900337219238,
              19.261899948120117,
              8.445879936218262,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              -1.8111900091171265,
              22.7185001373291,
              8.445879936218262,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -4.741549968719482,
              22.7185001373291,
              6.4878997802734375,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -6.699540138244629,
              22.7185001373291,
              3.5575599670410156,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -7.38709020614624,
              22.7185001373291,
              0.1009640023112297,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -6.699540138244629,
              22.7185001373291,
              -3.355609893798828,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -4.741549968719482,
              22.7185001373291,
              -6.285950183868408,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              -1.8111900091171265,
              22.7185001373291,
              -8.2439603805542,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              1.6453900337219238,
              22.7185001373291,
              -8.931509971618652,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              5.101960182189941,
              22.7185001373291,
              -8.2439603805542,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              8.032320022583008,
              22.7185001373291,
              -6.285950183868408,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              9.990309715270996,
              22.7185001373291,
              -3.355609893798828,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              10.677900314331055,
              22.7185001373291,
              0.1009640023112297,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              9.990309715270996,
              22.7185001373291,
              3.5575599670410156,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              8.032299995422363,
              22.7185001373291,
              6.4878997802734375,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              5.101960182189941,
              22.7185001373291,
              8.445879936218262,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              1.6453900337219238,
              22.7185001373291,
              9.133440017700195,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              -1.5480799674987793,
              26.175100326538086,
              7.810679912567139,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -4.255360126495361,
              26.175100326538086,
              6.001709938049316,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -6.064310073852539,
              26.175100326538086,
              3.2944300174713135,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -6.699540138244629,
              26.175100326538086,
              0.1009640023112297,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -6.064310073852539,
              26.175100326538086,
              -3.0924999713897705,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -4.255360126495361,
              26.175100326538086,
              -5.799779891967773,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              -1.5480799674987793,
              26.175100326538086,
              -7.608729839324951,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              1.6453900337219238,
              26.175100326538086,
              -8.2439603805542,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              4.838850021362305,
              26.175100326538086,
              -7.608729839324951,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              7.546130180358887,
              26.175100326538086,
              -5.799779891967773,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              9.355079650878906,
              26.175100326538086,
              -3.092479944229126,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              9.990309715270996,
              26.175100326538086,
              0.10098499804735184,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              9.355079650878906,
              26.175100326538086,
              3.2944300174713135,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              7.546130180358887,
              26.175100326538086,
              6.001729965209961,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              4.83882999420166,
              26.175100326538086,
              7.810679912567139,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              1.6453900337219238,
              26.175100326538086,
              8.445879936218262,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              1.6453900337219238,
              31.063400268554688,
              3.5575599670410156,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              1.6453900337219238,
              31.063400268554688,
              3.5575599670410156,
              0.32260099053382874,
              31.063400268554688,
              3.2944300174713135,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              -0.7987890243530273,
              29.10540008544922,
              6.001709938049316,
              0.32260099053382874,
              31.063400268554688,
              3.2944300174713135,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              0.32260099053382874,
              31.063400268554688,
              3.2944300174713135,
              -0.7987890243530273,
              31.063400268554688,
              2.545140027999878,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              -2.8708600997924805,
              29.10540008544922,
              4.6172099113464355,
              -0.7987890243530273,
              31.063400268554688,
              2.545140027999878,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -0.7987890243530273,
              31.063400268554688,
              2.545140027999878,
              -1.5480799674987793,
              31.063400268554688,
              1.4237500429153442,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -4.255360126495361,
              29.10540008544922,
              2.545140027999878,
              -1.5480799674987793,
              31.063400268554688,
              1.4237500429153442,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -1.5480799674987793,
              31.063400268554688,
              1.4237500429153442,
              -1.8111900091171265,
              31.063400268554688,
              0.1009650006890297,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -4.741549968719482,
              29.10540008544922,
              0.1009640023112297,
              -1.8111900091171265,
              31.063400268554688,
              0.1009650006890297,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -1.8111900091171265,
              31.063400268554688,
              0.1009650006890297,
              -1.5480799674987793,
              31.063400268554688,
              -1.2217999696731567,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -4.255360126495361,
              29.10540008544922,
              -2.3431899547576904,
              -1.5480799674987793,
              31.063400268554688,
              -1.2217999696731567,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -1.5480799674987793,
              31.063400268554688,
              -1.2217999696731567,
              -0.7987890243530273,
              31.063400268554688,
              -2.3431899547576904,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -2.8708600997924805,
              29.10540008544922,
              -4.415259838104248,
              -0.7987890243530273,
              31.063400268554688,
              -2.3431899547576904,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              -0.7987890243530273,
              31.063400268554688,
              -2.3431899547576904,
              0.32260099053382874,
              31.063400268554688,
              -3.0924999713897705,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              -0.7987890243530273,
              29.10540008544922,
              -5.799779891967773,
              0.32260099053382874,
              31.063400268554688,
              -3.0924999713897705,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              0.32260099053382874,
              31.063400268554688,
              -3.0924999713897705,
              1.6453900337219238,
              31.063400268554688,
              -3.355609893798828,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              1.6453900337219238,
              29.10540008544922,
              -6.285950183868408,
              1.6453900337219238,
              31.063400268554688,
              -3.355609893798828,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              1.6453900337219238,
              31.063400268554688,
              -3.355609893798828,
              2.968169927597046,
              31.063400268554688,
              -3.0924999713897705,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              4.089560031890869,
              29.10540008544922,
              -5.799779891967773,
              2.968169927597046,
              31.063400268554688,
              -3.0924999713897705,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              2.968169927597046,
              31.063400268554688,
              -3.0924999713897705,
              4.089560031890869,
              31.063400268554688,
              -2.3431899547576904,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              6.161630153656006,
              29.10540008544922,
              -4.415259838104248,
              4.089560031890869,
              31.063400268554688,
              -2.3431899547576904,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              4.089560031890869,
              31.063400268554688,
              -2.3431899547576904,
              4.838850021362305,
              31.063400268554688,
              -1.2217999696731567,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              7.546130180358887,
              29.10540008544922,
              -2.3431899547576904,
              4.838850021362305,
              31.063400268554688,
              -1.2217999696731567,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              4.838850021362305,
              31.063400268554688,
              -1.2217999696731567,
              5.101960182189941,
              31.063400268554688,
              0.1009650006890297,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              8.032320022583008,
              29.10540008544922,
              0.10098499804735184,
              5.101960182189941,
              31.063400268554688,
              0.1009650006890297,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              5.101960182189941,
              31.063400268554688,
              0.1009650006890297,
              4.838850021362305,
              31.063400268554688,
              1.4237500429153442,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              7.546130180358887,
              29.10540008544922,
              2.545140027999878,
              4.838850021362305,
              31.063400268554688,
              1.4237500429153442,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              4.838850021362305,
              31.063400268554688,
              1.4237500429153442,
              4.089560031890869,
              31.063400268554688,
              2.545140027999878,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              6.161610126495361,
              29.10540008544922,
              4.6172099113464355,
              4.089560031890869,
              31.063400268554688,
              2.545140027999878,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              4.089560031890869,
              31.063400268554688,
              2.545140027999878,
              2.9681499004364014,
              31.063400268554688,
              3.2944300174713135,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              4.089540004730225,
              29.10540008544922,
              6.001729965209961,
              2.9681499004364014,
              31.063400268554688,
              3.2944300174713135,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              2.9681499004364014,
              31.063400268554688,
              3.2944300174713135,
              1.6453900337219238,
              31.063400268554688,
              3.5575599670410156,
              1.6453900337219238,
              29.10540008544922,
              6.4878997802734375,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              0.32260099053382874,
              31.063400268554688,
              3.2944300174713135,
              1.6453900337219238,
              31.063400268554688,
              3.5575599670410156,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              -0.7987890243530273,
              31.063400268554688,
              2.545140027999878,
              0.32260099053382874,
              31.063400268554688,
              3.2944300174713135,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              -1.5480799674987793,
              31.063400268554688,
              1.4237500429153442,
              -0.7987890243530273,
              31.063400268554688,
              2.545140027999878,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              -1.8111900091171265,
              31.063400268554688,
              0.1009650006890297,
              -1.5480799674987793,
              31.063400268554688,
              1.4237500429153442,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              -1.5480799674987793,
              31.063400268554688,
              -1.2217999696731567,
              -1.8111900091171265,
              31.063400268554688,
              0.1009650006890297,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              -0.7987890243530273,
              31.063400268554688,
              -2.3431899547576904,
              -1.5480799674987793,
              31.063400268554688,
              -1.2217999696731567,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              0.32260099053382874,
              31.063400268554688,
              -3.0924999713897705,
              -0.7987890243530273,
              31.063400268554688,
              -2.3431899547576904,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              1.6453900337219238,
              31.063400268554688,
              -3.355609893798828,
              0.32260099053382874,
              31.063400268554688,
              -3.0924999713897705,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              2.968169927597046,
              31.063400268554688,
              -3.0924999713897705,
              1.6453900337219238,
              31.063400268554688,
              -3.355609893798828,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              4.089560031890869,
              31.063400268554688,
              -2.3431899547576904,
              2.968169927597046,
              31.063400268554688,
              -3.0924999713897705,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              4.838850021362305,
              31.063400268554688,
              -1.2217999696731567,
              4.089560031890869,
              31.063400268554688,
              -2.3431899547576904,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              5.101960182189941,
              31.063400268554688,
              0.1009650006890297,
              4.838850021362305,
              31.063400268554688,
              -1.2217999696731567,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              4.838850021362305,
              31.063400268554688,
              1.4237500429153442,
              5.101960182189941,
              31.063400268554688,
              0.1009650006890297,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              4.089560031890869,
              31.063400268554688,
              2.545140027999878,
              4.838850021362305,
              31.063400268554688,
              1.4237500429153442,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              2.9681499004364014,
              31.063400268554688,
              3.2944300174713135,
              4.089560031890869,
              31.063400268554688,
              2.545140027999878,
              1.6453900337219238,
              31.750999450683594,
              0.1009650006890297,
              1.6453900337219238,
              31.063400268554688,
              3.5575599670410156,
              2.9681499004364014,
              31.063400268554688,
              3.2944300174713135,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              -0.5732390284538269,
              14.450300216674805,
              5.457170009613037,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -2.454090118408203,
              14.450300216674805,
              4.200429916381836,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -3.710819959640503,
              14.450300216674805,
              2.3195900917053223,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -4.152140140533447,
              14.450300216674805,
              0.10096299648284912,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -3.710819959640503,
              14.450300216674805,
              -2.1176400184631348,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -2.454090118408203,
              14.450300216674805,
              -3.9985098838806152,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              -0.5743380188941956,
              14.450300216674805,
              -5.2734198570251465,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              1.6441400051116943,
              14.450300216674805,
              -5.826789855957031,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              3.8640100955963135,
              14.450300216674805,
              -5.255249977111816,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              5.7448601722717285,
              14.450300216674805,
              -3.9985098838806152,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              7.001589775085449,
              14.450300216674805,
              -2.1176400184631348,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              7.442910194396973,
              14.450300216674805,
              0.10096299648284912,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              7.001589775085449,
              14.450300216674805,
              2.3195900917053223,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              5.7448601722717285,
              14.450300216674805,
              4.200429916381836,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              3.863990068435669,
              14.450300216674805,
              5.457170009613037,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              1.6453900337219238,
              14.450300216674805,
              5.898489952087402,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              -0.2164199948310852,
              12.03380012512207,
              4.595759868621826,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              -1.7947900295257568,
              12.03380012512207,
              3.5411300659179688,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -2.849410057067871,
              12.03380012512207,
              1.9627699851989746,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -3.219759941101074,
              12.03380012512207,
              0.10096199810504913,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -2.849410057067871,
              12.03380012512207,
              -1.7608400583267212,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              -1.7947900295257568,
              12.03380012512207,
              -3.339210033416748,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              -0.2164199948310852,
              12.03380012512207,
              -4.3938398361206055,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              1.6453900337219238,
              12.03380012512207,
              -4.7641801834106445,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              3.507189989089966,
              12.03380012512207,
              -4.3938398361206055,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              5.085559844970703,
              12.03380012512207,
              -3.339210033416748,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              6.1401801109313965,
              12.03380012512207,
              -1.7608400583267212,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              6.5105299949646,
              12.03380012512207,
              0.10096199810504913,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              6.1401801109313965,
              12.03380012512207,
              1.9627699851989746,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              5.085559844970703,
              12.03380012512207,
              3.5411300659179688,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              3.507189989089966,
              12.03380012512207,
              4.595759868621826,
              1.6453900337219238,
              12.03380012512207,
              4.966100215911865,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              -0.23444800078868866,
              10.976400375366211,
              4.6392598152160645,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              -1.828070044517517,
              10.976400375366211,
              3.5744199752807617,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -2.8929100036621094,
              10.976400375366211,
              1.9808000326156616,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -3.266819953918457,
              10.976400375366211,
              0.10096199810504913,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -2.8929100036621094,
              10.976400375366211,
              -1.7788499593734741,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              -1.828070044517517,
              10.976400375366211,
              -3.372499942779541,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              -0.23444800078868866,
              10.976400375366211,
              -4.4373297691345215,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              1.6453900337219238,
              10.976400375366211,
              -4.81125020980835,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              3.5251998901367188,
              10.976400375366211,
              -4.4373297691345215,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              5.118840217590332,
              10.976400375366211,
              -3.372499942779541,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              6.183680057525635,
              10.976400375366211,
              -1.7788499593734741,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              6.557590007781982,
              10.976400375366211,
              0.10096199810504913,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              6.183680057525635,
              10.976400375366211,
              1.9808000326156616,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              5.118840217590332,
              10.976400375366211,
              3.5744199752807617,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              3.5251998901367188,
              10.976400375366211,
              4.6392598152160645,
              1.6453900337219238,
              10.976400375366211,
              5.013169765472412,
              0.5561619997024536,
              8.453470230102539,
              2.7305500507354736,
              1.6453900337219238,
              8.453470230102539,
              2.947230100631714,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              1.6453900337219238,
              8.453470230102539,
              2.947230100631714,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              -0.3672170042991638,
              8.453470230102539,
              2.1135599613189697,
              0.5561619997024536,
              8.453470230102539,
              2.7305500507354736,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              0.5561619997024536,
              8.453470230102539,
              2.7305500507354736,
              0.16626699268817902,
              9.387200355529785,
              3.6718900203704834,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              -0.9842039942741394,
              8.453470230102539,
              1.1901899576187134,
              -0.3672170042991638,
              8.453470230102539,
              2.1135599613189697,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -0.3672170042991638,
              8.453470230102539,
              2.1135599613189697,
              -1.0876799821853638,
              9.387200355529785,
              2.8340299129486084,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -1.2008800506591797,
              8.453470230102539,
              0.10096199810504913,
              -0.9842039942741394,
              8.453470230102539,
              1.1901899576187134,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -0.9842039942741394,
              8.453470230102539,
              1.1901899576187134,
              -1.9255499839782715,
              9.387200355529785,
              1.5801000595092773,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -0.9842039942741394,
              8.453470230102539,
              -0.9882400035858154,
              -1.2008800506591797,
              8.453470230102539,
              0.10096199810504913,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -1.2008800506591797,
              8.453470230102539,
              0.10096199810504913,
              -2.219759941101074,
              9.387200355529785,
              0.10096199810504913,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -0.3672170042991638,
              8.453470230102539,
              -1.9116400480270386,
              -0.9842039942741394,
              8.453470230102539,
              -0.9882400035858154,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              -0.9842039942741394,
              8.453470230102539,
              -0.9882400035858154,
              -1.9255499839782715,
              9.387200355529785,
              -1.378159999847412,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              0.5561619997024536,
              8.453470230102539,
              -2.528630018234253,
              -0.3672170042991638,
              8.453470230102539,
              -1.9116400480270386,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              -0.3672170042991638,
              8.453470230102539,
              -1.9116400480270386,
              -1.0876799821853638,
              9.387200355529785,
              -2.6321001052856445,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              1.6453900337219238,
              8.453470230102539,
              -2.745300054550171,
              0.5561619997024536,
              8.453470230102539,
              -2.528630018234253,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              0.5561619997024536,
              8.453470230102539,
              -2.528630018234253,
              0.16626699268817902,
              9.387200355529785,
              -3.4699699878692627,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              2.734610080718994,
              8.453470230102539,
              -2.528630018234253,
              1.6453900337219238,
              8.453470230102539,
              -2.745300054550171,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              1.6453900337219238,
              8.453470230102539,
              -2.745300054550171,
              1.6453900337219238,
              9.387200355529785,
              -3.7641899585723877,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              3.657989978790283,
              8.453470230102539,
              -1.9116400480270386,
              2.734610080718994,
              8.453470230102539,
              -2.528630018234253,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              2.734610080718994,
              8.453470230102539,
              -2.528630018234253,
              3.124500036239624,
              9.387200355529785,
              -3.4699699878692627,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              4.274970054626465,
              8.453470230102539,
              -0.9882400035858154,
              3.657989978790283,
              8.453470230102539,
              -1.9116400480270386,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              3.657989978790283,
              8.453470230102539,
              -1.9116400480270386,
              4.3784499168396,
              9.387200355529785,
              -2.6321001052856445,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              4.491650104522705,
              8.453470230102539,
              0.10096199810504913,
              4.274970054626465,
              8.453470230102539,
              -0.9882400035858154,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              4.274970054626465,
              8.453470230102539,
              -0.9882400035858154,
              5.216320037841797,
              9.387200355529785,
              -1.378159999847412,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              4.274970054626465,
              8.453470230102539,
              1.1901899576187134,
              4.491650104522705,
              8.453470230102539,
              0.10096199810504913,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              4.491650104522705,
              8.453470230102539,
              0.10096199810504913,
              5.5105299949646,
              9.387200355529785,
              0.10096199810504913,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              3.657989978790283,
              8.453470230102539,
              2.1135599613189697,
              4.274970054626465,
              8.453470230102539,
              1.1901899576187134,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              4.274970054626465,
              8.453470230102539,
              1.1901899576187134,
              5.216320037841797,
              9.387200355529785,
              1.5801000595092773,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              2.7345900535583496,
              8.453470230102539,
              2.7305500507354736,
              3.657989978790283,
              8.453470230102539,
              2.1135599613189697,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              3.657989978790283,
              8.453470230102539,
              2.1135599613189697,
              4.3784499168396,
              9.387200355529785,
              2.8340299129486084,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              1.6453900337219238,
              8.453470230102539,
              2.947230100631714,
              2.7345900535583496,
              8.453470230102539,
              2.7305500507354736,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              2.7345900535583496,
              8.453470230102539,
              2.7305500507354736,
              3.124500036239624,
              9.387200355529785,
              3.6718900203704834,
              1.6453900337219238,
              9.387200355529785,
              3.9661099910736084,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              3.2202000617980957,
              6.2846999168396,
              3.4240000247955322,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              3.2202000617980957,
              6.2846999168396,
              3.4240000247955322,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              3.2202000617980957,
              6.2846999168396,
              3.4240000247955322,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              -0.44592300057411194,
              4.47422981262207,
              3.585089921951294,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              -0.44592300057411194,
              4.47422981262207,
              3.585089921951294,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              -0.4534040093421936,
              5.689589977264404,
              3.589169979095459,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              1.4336800575256348,
              5.222970008850098,
              3.8003900051116943,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              -0.4534040093421936,
              5.689589977264404,
              3.589169979095459,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              -0.5872930288314819,
              3.3823299407958984,
              3.0303499698638916,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -0.5872930288314819,
              3.3823299407958984,
              3.0303499698638916,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              1.4336800575256348,
              5.222970008850098,
              3.8003900051116943,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              1.4336800575256348,
              5.222970008850098,
              3.8003900051116943,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              1.4336800575256348,
              5.222970008850098,
              3.8003900051116943,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              3.1899099349975586,
              5.191150188446045,
              3.4397499561309814,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              4.577390193939209,
              5.102190017700195,
              2.304219961166382,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              5.278240203857422,
              5.004670143127441,
              0.6539949774742126,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              5.1319098472595215,
              4.907139778137207,
              -1.132930040359497,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              4.171899795532227,
              4.809599876403809,
              -2.647130012512207,
              2.6182000637054443,
              4.712080001831055,
              -3.5417699813842773,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              1.4336800575256348,
              5.222970008850098,
              3.8003900051116943,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              -0.28901800513267517,
              5.2393798828125,
              3.3035600185394287,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -1.5835000276565552,
              5.325439929962158,
              2.063070058822632,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -2.1531898975372314,
              5.422969818115234,
              0.3630959987640381,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -1.8676400184631348,
              5.520510196685791,
              -1.4069199562072754,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              -0.7922160029411316,
              5.618030071258545,
              -2.841439962387085,
              0.8266869783401489,
              5.715559959411621,
              -3.6118900775909424,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              3.2202000617980957,
              6.2846999168396,
              3.4240000247955322,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              4.5612897872924805,
              6.212399959564209,
              2.2520198822021484,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              5.281750202178955,
              6.109620094299316,
              0.6513959765434265,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              5.13178014755249,
              6.008110046386719,
              -1.132889986038208,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              4.171860218048096,
              5.910600185394287,
              -2.647089958190918,
              2.6181800365448,
              5.813079833984375,
              -3.5417399406433105,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -1.5833100080490112,
              4.224530220031738,
              2.0629498958587646,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -2.153019905090332,
              4.322020053863525,
              0.36307498812675476,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -1.8675099611282349,
              4.419539928436279,
              -1.4068599939346313,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              -0.792153000831604,
              4.517049789428711,
              -2.841399908065796,
              0.8266869783401489,
              4.614570140838623,
              -3.611870050430298,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              -1.5209200382232666,
              6.941490173339844,
              2.0544700622558594,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -2.0831499099731445,
              6.500110149383545,
              0.3573479950428009,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              -1.8027299642562866,
              6.589719772338867,
              -1.3793599605560303,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              -0.7474319934844971,
              6.679309844970703,
              -2.7867300510406494,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              0.84095299243927,
              6.768899917602539,
              -3.542419910430908,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              2.598479986190796,
              6.8584699630737305,
              -3.4734199047088623,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              4.122529983520508,
              6.948019981384277,
              -2.595599889755249,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              5.064020156860352,
              7.037569999694824,
              -1.1101700067520142,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              5.213850021362305,
              7.126649856567383,
              0.6437450051307678,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -1.5209200382232666,
              6.941490173339844,
              2.0544700622558594,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -1.5209200382232666,
              6.941490173339844,
              2.0544700622558594,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -2.067110061645508,
              6.985360145568848,
              0.35927098989486694,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              -1.7879999876022339,
              7.03223991394043,
              -1.3707000017166138,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              -0.7369059920310974,
              7.079100131988525,
              -2.772819995880127,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              0.8453909754753113,
              7.125949859619141,
              -3.5258700847625732,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              2.596410036087036,
              7.172830104827881,
              -3.4573299884796143,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              4.114980220794678,
              7.219679832458496,
              -2.582900047302246,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              5.053279876708984,
              7.266560077667236,
              -1.1029200553894043,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              4.766479969024658,
              7.57735013961792,
              2.4685399532318115,
              5.196300029754639,
              7.313419818878174,
              0.6435970067977905,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              4.766479969024658,
              7.57735013961792,
              2.4685399532318115,
              3.369070053100586,
              7.388659954071045,
              3.7347300052642822,
              2.000030040740967,
              7.387050151824951,
              4.218669891357422,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              2.000030040740967,
              7.387050151824951,
              4.218669891357422,
              2.0524399280548096,
              7.253900051116943,
              4.085309982299805,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              3.369070053100586,
              7.388659954071045,
              3.7347300052642822,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              3.369070053100586,
              7.388659954071045,
              3.7347300052642822,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              4.550300121307373,
              7.212329864501953,
              2.293839931488037,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              2.0524399280548096,
              7.253900051116943,
              4.085309982299805,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              3.4139199256896973,
              7.245110034942627,
              3.726170063018799,
              2.0524399280548096,
              7.253900051116943,
              4.085309982299805,
              3.2202000617980957,
              6.2846999168396,
              3.4240000247955322,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              1.4520699977874756,
              6.305769920349121,
              3.7706799507141113,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              -1.5209200382232666,
              6.941490173339844,
              2.0544700622558594,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              -1.5302000045776367,
              6.41733980178833,
              2.041069984436035,
              -0.2888070046901703,
              6.3658599853515625,
              3.3411800861358643,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -1.5209200382232666,
              6.941490173339844,
              2.0544700622558594,
              -0.3045729994773865,
              6.91664981842041,
              3.4519898891448975,
              2.000030040740967,
              7.387050151824951,
              4.218669891357422,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              2.0524399280548096,
              7.253900051116943,
              4.085309982299805,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              2.0524399280548096,
              7.253900051116943,
              4.085309982299805,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -2.3416099548339844,
              7.53046989440918,
              0.40118101239204407,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              -2.0407299995422363,
              7.536330223083496,
              -1.4636900424957275,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              -0.9076970219612122,
              7.542200088500977,
              -2.9751200675964355,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              0.7979649901390076,
              7.548059940338135,
              -3.7868900299072266,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              2.6855099201202393,
              7.553909778594971,
              -3.7130000591278076,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              4.322509765625,
              7.559760093688965,
              -2.770390033721924,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              5.333930015563965,
              7.565619945526123,
              -1.1750099658966064,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              4.766479969024658,
              7.57735013961792,
              2.4685399532318115,
              5.488110065460205,
              7.5714898109436035,
              0.707656979560852,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              3.437469959259033,
              8.21699047088623,
              3.8355400562286377,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              3.437469959259033,
              8.21699047088623,
              3.8355400562286377,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -1.7531499862670898,
              7.524620056152344,
              2.2295100688934326,
              -0.4223789870738983,
              7.521739959716797,
              3.759350061416626,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -2.366379976272583,
              8.21699047088623,
              0.40490099787712097,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              -2.0635600090026855,
              8.21699047088623,
              -1.4721200466156006,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              -0.9231250286102295,
              8.21699047088623,
              -2.993419885635376,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              0.7936530113220215,
              8.21699047088623,
              -3.8104701042175293,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              2.69350004196167,
              8.21699047088623,
              -3.7361199855804443,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              4.341169834136963,
              8.21699047088623,
              -2.7873599529266357,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              5.359210014343262,
              8.21699047088623,
              -1.1815799474716187,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              4.675940036773682,
              8.519559860229492,
              2.3949899673461914,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              4.675940036773682,
              8.519559860229492,
              2.3949899673461914,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              5.514379978179932,
              8.21699047088623,
              0.7133839726448059,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              3.437469959259033,
              8.21699047088623,
              3.8355400562286377,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              1.5919100046157837,
              8.21699047088623,
              4.347569942474365,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -1.7740099430084229,
              8.21699047088623,
              2.2448999881744385,
              -0.43493300676345825,
              8.21699047088623,
              3.785130023956299,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -2.2347099781036377,
              8.519559860229492,
              0.3848020136356354,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              -1.942330002784729,
              8.519559860229492,
              -1.42753005027771,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              -0.8412070274353027,
              8.519559860229492,
              -2.8963699340820312,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              0.816415011882782,
              8.519559860229492,
              -3.685270071029663,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              2.6507699489593506,
              8.519559860229492,
              -3.6134800910949707,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              4.241620063781738,
              8.519559860229492,
              -2.697429895401001,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              5.224559783935547,
              8.519559860229492,
              -1.1469800472259521,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              4.675940036773682,
              8.519559860229492,
              2.3949899673461914,
              5.374380111694336,
              8.519559860229492,
              0.6826329827308655,
              1.5887399911880493,
              8.713850021362305,
              4.186039924621582,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              1.5887399911880493,
              8.713850021362305,
              4.186039924621582,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              1.5883599519729614,
              8.519559860229492,
              4.1946001052856445,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -1.6626100540161133,
              8.519559860229492,
              2.1609299182891846,
              -0.37036600708961487,
              8.519559860229492,
              3.648819923400879,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -2.2278199195861816,
              8.713850021362305,
              0.3837659955024719,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              -1.9359899759292603,
              8.713850021362305,
              -1.4251799583435059,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              -0.8369160294532776,
              8.713850021362305,
              -2.8912999629974365,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              0.8175989985466003,
              8.713850021362305,
              -3.678719997406006,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              2.6485300064086914,
              8.713850021362305,
              -3.6070499420166016,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              4.2364501953125,
              8.713850021362305,
              -2.6927199363708496,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              5.2175397872924805,
              8.713850021362305,
              -1.145169973373413,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              5.367090225219727,
              8.713850021362305,
              0.6810479760169983,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              1.5887399911880493,
              8.713850021362305,
              4.186039924621582,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              1.5887399911880493,
              8.713850021362305,
              4.186039924621582,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              1.5887399911880493,
              8.713850021362305,
              4.186039924621582,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -1.6568200588226318,
              8.71387004852295,
              2.1566600799560547,
              -0.3668789863586426,
              8.713850021362305,
              3.6416499614715576,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -2.357609987258911,
              8.80029010772705,
              0.4034639894962311,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              -2.0554800033569336,
              8.80029010772705,
              -1.469230055809021,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              -0.9176719784736633,
              8.80029010772705,
              -2.986999988555908,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              0.7951539754867554,
              8.80029010772705,
              -3.8021700382232666,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              2.690609931945801,
              8.80029010772705,
              -3.727979898452759,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              4.334469795227051,
              8.80029010772705,
              -2.7814199924468994,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              5.35014009475708,
              8.80029010772705,
              -1.1793400049209595,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              5.504960060119629,
              8.80029010772705,
              0.7112500071525574,
              3.5722599029541016,
              9.193380355834961,
              4.035429954528809,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              1.5933899879455566,
              8.80029010772705,
              4.337339878082275,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -1.766469955444336,
              8.80029010772705,
              2.2387900352478027,
              -0.43114998936653137,
              8.80029010772705,
              3.7761900424957275,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              -2.5913798809051514,
              9.193380355834961,
              0.43911799788475037,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              -1.343690037727356,
              9.186869621276855,
              -2.9678900241851807,
              -2.2707200050354004,
              9.193380355834961,
              -1.5484399795532227,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              1.0801600217819214,
              9.186869621276855,
              -4.121449947357178,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              2.7664599418640137,
              9.193380355834961,
              -3.945739984512329,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              4.511129856109619,
              9.193380355834961,
              -2.941119909286499,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              5.589109897613525,
              9.193380355834961,
              -1.240779995918274,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              5.753439903259277,
              9.193380355834961,
              0.7657560110092163,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              3.5074899196624756,
              9.502900123596191,
              3.9271199703216553,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              3.5722599029541016,
              9.193380355834961,
              4.035429954528809,
              3.5074899196624756,
              9.502900123596191,
              3.9271199703216553,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              1.6026500463485718,
              9.193380355834961,
              4.607639789581299,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -1.9641599655151367,
              9.193380355834961,
              2.387579917907715,
              -0.5460379719734192,
              9.193380355834961,
              4.018290042877197,
              -1.0142199993133545,
              9.639410018920898,
              1.6455600261688232,
              -1.4773199558258057,
              9.63938045501709,
              0.26367899775505066,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -1.4773199558258057,
              9.63938045501709,
              0.26367899775505066,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -1.4773199558258057,
              9.63938045501709,
              0.26367899775505066,
              -1.2452000379562378,
              9.63938045501709,
              -1.1751400232315063,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -1.2452000379562378,
              9.63938045501709,
              -1.1751400232315063,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -2.469599962234497,
              9.502900123596191,
              0.4208579957485199,
              -1.2452000379562378,
              9.63938045501709,
              -1.1751400232315063,
              -0.37102100253105164,
              9.63938045501709,
              -2.3412699699401855,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -0.37102100253105164,
              9.63938045501709,
              -2.3412699699401855,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              -2.1585800647735596,
              9.502900123596191,
              -1.5069299936294556,
              -0.37102100253105164,
              9.63938045501709,
              -2.3412699699401855,
              0.9449779987335205,
              9.639410018920898,
              -2.967550039291382,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              0.9449779987335205,
              9.639410018920898,
              -2.967550039291382,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              -0.9872900247573853,
              9.502900123596191,
              -3.069380044937134,
              0.9449779987335205,
              9.639410018920898,
              -2.967550039291382,
              2.4012699127197266,
              9.63938045501709,
              -2.910550117492676,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              2.4012699127197266,
              9.63938045501709,
              -2.910550117492676,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              0.7759209871292114,
              9.502900123596191,
              -3.908519983291626,
              2.4012699127197266,
              9.63938045501709,
              -2.910550117492676,
              3.6642799377441406,
              9.639410018920898,
              -2.183310031890869,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              3.6642799377441406,
              9.639410018920898,
              -2.183310031890869,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              2.7271499633789062,
              9.502900123596191,
              -3.832159996032715,
              3.6642799377441406,
              9.639410018920898,
              -2.183310031890869,
              4.444640159606934,
              9.63938045501709,
              -0.9524170160293579,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              4.444640159606934,
              9.63938045501709,
              -0.9524170160293579,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              4.4193902015686035,
              9.502900123596191,
              -2.8577399253845215,
              4.444640159606934,
              9.63938045501709,
              -0.9524170160293579,
              4.563590049743652,
              9.639410018920898,
              0.5001559853553772,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              4.563590049743652,
              9.639410018920898,
              0.5001559853553772,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              5.464950084686279,
              9.502900123596191,
              -1.2085299491882324,
              4.563590049743652,
              9.639410018920898,
              0.5001559853553772,
              3.993880033493042,
              9.639410018920898,
              1.841599941253662,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              3.993880033493042,
              9.639410018920898,
              1.841599941253662,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              5.624320030212402,
              9.502900123596191,
              0.7376899719238281,
              1.5846600532531738,
              9.639410018920898,
              3.2960801124572754,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              2.9563798904418945,
              9.639410018920898,
              3.0538299083709717,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              3.5074899196624756,
              9.502900123596191,
              3.9271199703216553,
              2.9563798904418945,
              9.639410018920898,
              3.0538299083709717,
              1.5846600532531738,
              9.639410018920898,
              3.2960801124572754,
              -0.031723301857709885,
              9.63938045501709,
              2.8744800090789795,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              -0.031723301857709885,
              9.63938045501709,
              2.8744800090789795,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              1.598230004310608,
              9.502889633178711,
              4.4628400802612305,
              -0.031723301857709885,
              9.63938045501709,
              2.8744800090789795,
              -1.0142199993133545,
              9.639410018920898,
              1.6455600261688232,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              -1.0142199993133545,
              9.639410018920898,
              1.6455600261688232,
              -1.8616100549697876,
              9.502900123596191,
              2.311929941177368,
              -0.484281986951828,
              9.502900123596191,
              3.8916099071502686,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              3.0986099243164062,
              3.2286300659179688,
              3.25957989692688,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              4.407129764556885,
              3.215169906616211,
              2.187700033187866,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              5.067399978637695,
              3.201809883117676,
              0.6308519840240479,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              4.928460121154785,
              3.18858003616333,
              -1.0540800094604492,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              4.022580146789551,
              3.1754798889160156,
              -2.480950117111206,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              2.5577900409698486,
              3.1626100540161133,
              -3.322999954223633,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              0.8700770139694214,
              3.150010108947754,
              -3.387739896774292,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -0.6537410020828247,
              3.1377499103546143,
              -2.6609299182891846,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -1.6647900342941284,
              3.125920057296753,
              -1.3097599744796753,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              -1.9319900274276733,
              3.114609956741333,
              0.3557620048522949,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              -1.3949600458145142,
              3.103869915008545,
              1.9540799856185913,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              1.2986299991607666,
              3.180299997329712,
              3.568819999694824,
              -0.15145200490951538,
              3.1017000675201416,
              3.0524098873138428,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              3.334290027618408,
              5.954939842224121,
              3.7192800045013428,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              4.838679790496826,
              5.8590898513793945,
              2.488110065460205,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              5.5913801193237305,
              5.74537992477417,
              0.7036409974098206,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              5.432819843292236,
              5.638020038604736,
              -1.2340199947357178,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              2.7067298889160156,
              5.42464017868042,
              -3.8495500087738037,
              4.392360210418701,
              5.531370162963867,
              -2.8775200843811035,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              -0.44592300057411194,
              4.47422981262207,
              3.585089921951294,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              -0.44592300057411194,
              4.47422981262207,
              3.585089921951294,
              -0.44592300057411194,
              4.47422981262207,
              3.585089921951294,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -1.8493399620056152,
              4.576419830322266,
              2.2313499450683594,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -2.468179941177368,
              4.682919979095459,
              0.3886690139770508,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -2.159719944000244,
              4.789480209350586,
              -1.531190037727356,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              -0.9939690232276917,
              4.896130084991455,
              -3.0883400440216064,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              3.4314000606536865,
              6.897759914398193,
              3.73225998878479,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              5.540629863739014,
              6.949049949645996,
              0.6672049760818481,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              5.418540000915527,
              6.8365302085876465,
              -1.229200005531311,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              4.381830215454102,
              6.730770111083984,
              -2.866489887237549,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              2.7024800777435303,
              6.624949932098389,
              -3.834779977798462,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              -0.4534040093421936,
              5.689589977264404,
              3.589169979095459,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              -0.4534040093421936,
              5.689589977264404,
              3.589169979095459,
              -0.4534040093421936,
              5.689589977264404,
              3.589169979095459,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -1.862380027770996,
              5.782639980316162,
              2.2396299839019775,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -2.4834799766540527,
              5.889860153198242,
              0.38993701338768005,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -2.1737499237060547,
              5.997139930725098,
              -1.5371500253677368,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              -1.003559947013855,
              6.1044697761535645,
              -3.100029945373535,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              -0.5872930288314819,
              3.3823299407958984,
              3.0303499698638916,
              -0.6186149716377258,
              3.535550117492676,
              2.989729881286621,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -0.6186149716377258,
              3.535550117492676,
              2.989729881286621,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -1.8109999895095825,
              3.393209934234619,
              2.1552000045776367,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -2.459480047225952,
              3.462739944458008,
              0.38683000206947327,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -2.158090114593506,
              3.5437300205230713,
              -1.5318100452423096,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              -0.9971809983253479,
              3.6279098987579346,
              -3.094130039215088,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              0.7607679963111877,
              3.7147998809814453,
              -3.9395198822021484,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              3.3324499130249023,
              4.739809989929199,
              3.738909959793091,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              4.843500137329102,
              4.6426100730896,
              2.503459930419922,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              5.607590198516846,
              4.535399913787842,
              0.7069169878959656,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              5.4491801261901855,
              4.42818021774292,
              -1.2394700050354004,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              4.404069900512695,
              4.320940017700195,
              -2.8896899223327637,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              1.7296099662780762,
              6.929609775543213,
              4.058300018310547,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              3.3792800903320312,
              6.678609848022461,
              3.649549961090088,
              3.4314000606536865,
              6.897759914398193,
              3.73225998878479,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              0.7624800205230713,
              5.002840042114258,
              -3.925640106201172,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              0.759456992149353,
              6.211850166320801,
              -3.9403300285339355,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              2.711319923400879,
              4.213709831237793,
              -3.8654000759124756,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              1.4207899570465088,
              5.669869899749756,
              4.1288299560546875,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              3.3787500858306885,
              5.806739807128906,
              3.8309099674224854,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              3.3311400413513184,
              5.634699821472168,
              3.736140012741089,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              4.926750183105469,
              5.707789897918701,
              2.563309907913208,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              4.839210033416748,
              5.537630081176758,
              2.5004398822784424,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              5.706560134887695,
              5.596829891204834,
              0.7229160070419312,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              5.599740028381348,
              5.430369853973389,
              0.7060710191726685,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              5.543039798736572,
              5.487239837646484,
              -1.2707099914550781,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              5.439499855041504,
              5.3234100341796875,
              -1.2357699871063232,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              4.471930027008057,
              5.3778300285339355,
              -2.9602200984954834,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              4.395549774169922,
              5.216489791870117,
              -2.8801798820495605,
              2.7075300216674805,
              5.109630107879639,
              -3.850759983062744,
              2.738369941711426,
              5.268439769744873,
              -3.958440065383911,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              1.4207600355148315,
              4.775129795074463,
              4.131110191345215,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              -0.5054590106010437,
              4.62322998046875,
              3.68176007270813,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -0.45268601179122925,
              4.796309947967529,
              3.5880300998687744,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -1.9496599435806274,
              4.721429824829102,
              2.295219898223877,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -1.8596099615097046,
              4.890059947967529,
              2.2383599281311035,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -2.585330009460449,
              4.830820083618164,
              0.3984760046005249,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -2.4778199195861816,
              4.997109889984131,
              0.3898949921131134,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -2.2667300701141357,
              4.940209865570068,
              -1.5764000415802002,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              -2.1662700176239014,
              5.104090213775635,
              -1.5334999561309814,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              -1.0668400526046753,
              5.049630165100098,
              -3.1770200729370117,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              -0.9969490170478821,
              5.211009979248047,
              -3.091279983520508,
              0.7617610096931458,
              5.317870140075684,
              -3.9269299507141113,
              0.7394639849662781,
              5.159019947052002,
              -4.0366997718811035,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              3.4314000606536865,
              6.897759914398193,
              3.73225998878479,
              4.660130023956299,
              7.064189910888672,
              2.3127200603485107,
              3.4289300441741943,
              7.08450984954834,
              3.737410068511963,
              3.4314000606536865,
              6.897759914398193,
              3.73225998878479,
              4.708769798278809,
              6.897950172424316,
              2.3192899227142334,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              4.684549808502197,
              6.67464017868042,
              2.3074100017547607,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              5.667359828948975,
              6.8265299797058105,
              0.6814709901809692,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              5.578549861907959,
              6.630189895629883,
              0.6695929765701294,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              5.542829990386963,
              6.721660137176514,
              -1.2706400156021118,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              5.453279972076416,
              6.534239768981934,
              -1.2404199838638306,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              4.471779823303223,
              6.612380027770996,
              -2.9600698947906494,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              4.4056901931762695,
              6.426730155944824,
              -2.890810012817383,
              2.71163010597229,
              6.319260120391846,
              -3.8650200366973877,
              2.7383100986480713,
              6.503089904785156,
              -3.958250045776367,
              1.416849970817566,
              5.842289924621582,
              4.23415994644165,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              1.422029972076416,
              5.9864501953125,
              4.115409851074219,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              -0.5064529776573181,
              5.8667402267456055,
              3.684070110321045,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -0.45315098762512207,
              6.021699905395508,
              3.6003100872039795,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -1.9497499465942383,
              5.957180023193359,
              2.295789957046509,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -1.8469899892807007,
              6.097720146179199,
              2.232450008392334,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -2.585249900817871,
              6.0657501220703125,
              0.3984760046005249,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -2.4622700214385986,
              6.200799942016602,
              0.38862699270248413,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -2.266619920730591,
              6.175099849700928,
              -1.5763599872589111,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              -2.1519200801849365,
              6.306960105895996,
              -1.5274100303649902,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              -1.0667400360107422,
              6.284450054168701,
              -3.1768999099731445,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              -0.9871000051498413,
              6.41303014755249,
              -3.0792500972747803,
              0.7648890018463135,
              6.519020080566406,
              -3.9117701053619385,
              0.7394850254058838,
              6.39378023147583,
              -4.036509990692139,
              -0.6186149716377258,
              3.535550117492676,
              2.989729881286621,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -1.919119954109192,
              3.509010076522827,
              2.2064099311828613,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -1.8433799743652344,
              3.7055599689483643,
              2.1653599739074707,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -2.5875699520111084,
              3.5913100242614746,
              0.39836999773979187,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -2.4923601150512695,
              3.78518009185791,
              0.39105701446533203,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -2.2715699672698975,
              3.6953999996185303,
              -1.5788099765777588,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              -2.1801600456237793,
              3.892199993133545,
              -1.5394400358200073,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              -1.072059988975525,
              3.8007500171661377,
              -3.1838901042938232,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              -1.0068199634552002,
              3.99931001663208,
              -3.103369951248169,
              0.7585700154304504,
              4.106490135192871,
              -3.9426701068878174,
              0.737371027469635,
              3.907170057296753,
              -4.048220157623291,
              1.4169800281524658,
              4.586180210113525,
              4.255740165710449,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              1.4239599704742432,
              4.298980236053467,
              4.164519786834717,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              3.3864500522613525,
              4.547969818115234,
              3.8528499603271484,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              3.3491899967193604,
              4.258130073547363,
              3.7754299640655518,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              4.942349910736084,
              4.4487199783325195,
              2.5779800415039062,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              4.870890140533447,
              4.173590183258057,
              2.525209903717041,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              5.727039813995361,
              4.339630126953125,
              0.7265509963035583,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              5.635630130767822,
              4.079750061035156,
              0.7125170230865479,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              5.561279773712158,
              4.230800151824951,
              -1.2766200304031372,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              5.4700398445129395,
              3.9867000579833984,
              -1.2452199459075928,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              4.483850002288818,
              4.122379779815674,
              -2.972330093383789,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              4.414649963378906,
              3.8946099281311035,
              -2.8988699913024902,
              2.714210033416748,
              3.8038599491119385,
              -3.8703699111938477,
              2.7426199913024902,
              4.0144500732421875,
              -3.972320079803467,
              1.6072800159454346,
              7.518849849700928,
              4.34896993637085,
              2.000030040740967,
              7.387050151824951,
              4.218669891357422,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              2.000030040740967,
              7.387050151824951,
              4.218669891357422,
              3.369070053100586,
              7.388659954071045,
              3.7347300052642822,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              4.766479969024658,
              7.57735013961792,
              2.4685399532318115,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              3.369070053100586,
              7.388659954071045,
              3.7347300052642822,
              4.548399925231934,
              7.359829902648926,
              2.3124799728393555,
              3.437469959259033,
              8.21699047088623,
              3.8355400562286377,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              3.4296000003814697,
              7.583199977874756,
              3.847249984741211,
              4.766479969024658,
              7.57735013961792,
              2.4685399532318115,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              4.675940036773682,
              8.519559860229492,
              2.3949899673461914,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              3.437469959259033,
              8.21699047088623,
              3.8355400562286377,
              4.790410041809082,
              8.21699047088623,
              2.4841198921203613,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              3.3754398822784424,
              8.519559860229492,
              3.7070000171661377,
              4.675940036773682,
              8.519559860229492,
              2.3949899673461914,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              3.3753299713134766,
              8.713850021362305,
              3.6987099647521973,
              4.670660018920898,
              8.713850021362305,
              2.3898398876190186,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              3.5722599029541016,
              9.193380355834961,
              4.035429954528809,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              3.4445900917053223,
              8.80029010772705,
              3.8186299800872803,
              4.783860206604004,
              8.80029010772705,
              2.477799892425537,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              3.5074899196624756,
              9.502900123596191,
              3.9271199703216553,
              3.5722599029541016,
              9.193380355834961,
              4.035429954528809,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              3.5722599029541016,
              9.193380355834961,
              4.035429954528809,
              4.989689826965332,
              9.193380355834961,
              2.63457989692688,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              3.993880033493042,
              9.639410018920898,
              1.841599941253662,
              2.9563798904418945,
              9.639410018920898,
              3.0538299083709717,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              2.9563798904418945,
              9.639410018920898,
              3.0538299083709717,
              3.5074899196624756,
              9.502900123596191,
              3.9271199703216553,
              4.885200023651123,
              9.502900123596191,
              2.5502099990844727,
              -0.26069799065589905,
              4.04748010635376,
              3.2228000164031982,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -0.5872930288314819,
              3.3823299407958984,
              3.0303499698638916,
              -0.6031230092048645,
              3.7487800121307373,
              3.0117499828338623,
              -0.6186149716377258,
              3.535550117492676,
              2.989729881286621,
              -0.5872930288314819,
              3.3823299407958984,
              3.0303499698638916,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              -2.245529890060425,
              22.650100708007812,
              0.11366599798202515,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.11366599798202515,
              -2.2382800579071045,
              22.595800399780273,
              0.058926500380039215,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.11366599798202515,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              -2.2527599334716797,
              22.704299926757812,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              -2.2527599334716797,
              22.704299926757812,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              -2.245529890060425,
              22.650100708007812,
              0.004166250117123127,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.004166250117123127,
              -2.2527599334716797,
              22.704299926757812,
              0.058926500380039215,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              -2.2382800579071045,
              22.595800399780273,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              -2.2382800579071045,
              22.595800399780273,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.004166250117123127,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.11366599798202515,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              -0.0015005699824541807,
              23.004499435424805,
              0.058926500380039215,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              -0.00023248299839906394,
              22.89419937133789,
              0.058926500380039215,
              -0.0008665259811095893,
              22.949399948120117,
              0.0041662901639938354,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              5.476109981536865,
              22.739900588989258,
              0.11366599798202515,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              5.479800224304199,
              22.685199737548828,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              5.479800224304199,
              22.685199737548828,
              0.058926500380039215,
              5.472430229187012,
              22.79450035095215,
              0.058926500380039215,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              5.476109981536865,
              22.739900588989258,
              0.11366599798202515,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.11366599798202515,
              5.476109981536865,
              22.739900588989258,
              0.11366599798202515,
              5.476109981536865,
              22.739900588989258,
              0.0041662598960101604,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              5.472430229187012,
              22.79450035095215,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              3.2625300884246826,
              22.645200729370117,
              0.058926500380039215,
              5.472430229187012,
              22.79450035095215,
              0.058926500380039215,
              5.479800224304199,
              22.685199737548828,
              0.058926500380039215,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              5.476109981536865,
              22.739900588989258,
              0.0041662598960101604,
              3.26023006439209,
              22.53529930114746,
              0.058926500380039215,
              3.261389970779419,
              22.590200424194336,
              0.004166239872574806,
              5.476109981536865,
              22.739900588989258,
              0.0041662598960101604,
              -2.2527599334716797,
              22.704299926757812,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.004166250117123127,
              -2.245529890060425,
              22.650100708007812,
              0.11366599798202515,
              -2.245529890060425,
              22.650100708007812,
              0.004166250117123127,
              -2.2382800579071045,
              22.595800399780273,
              0.058926500380039215,
              -2.245529890060425,
              22.650100708007812,
              0.11366599798202515,
              5.472430229187012,
              22.79450035095215,
              0.058926500380039215,
              5.476109981536865,
              22.739900588989258,
              0.11366599798202515,
              5.479800224304199,
              22.685199737548828,
              0.058926500380039215,
              5.476109981536865,
              22.739900588989258,
              0.0041662598960101604,
              5.472430229187012,
              22.79450035095215,
              0.058926500380039215,
              5.479800224304199,
              22.685199737548828,
              0.058926500380039215,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              2.6536800861358643,
              2.111999988555908,
              2.40664005279541,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              3.5818800926208496,
              2.111999988555908,
              1.6470099687576294,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              4.050759792327881,
              2.111999988555908,
              0.5430160164833069,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              3.952860116958618,
              2.111999988555908,
              -0.652409017086029,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              3.3106400966644287,
              2.111999988555908,
              -1.6654000282287598,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              2.2712299823760986,
              2.111999988555908,
              -2.2639200687408447,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              1.0727200508117676,
              2.111999988555908,
              -2.3108301162719727,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              -0.010313799604773521,
              2.111999988555908,
              -1.7954000234603882,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              -0.7297419905662537,
              2.111999988555908,
              -0.8356900215148926,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              -0.9207789897918701,
              2.111999988555908,
              0.34840700030326843,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              -0.5396559834480286,
              2.111999988555908,
              1.4856699705123901,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              1.4787800312042236,
              2.111999988555908,
              2.6479101181030273,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              0.326321005821228,
              2.111999988555908,
              2.3155500888824463,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              2.253200054168701,
              1.1800800561904907,
              1.6366599798202515,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              2.83843994140625,
              1.1800800561904907,
              1.1576999425888062,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              3.1340599060058594,
              1.1800800561904907,
              0.46162599325180054,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              3.0723400115966797,
              1.1800800561904907,
              -0.2920829951763153,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              2.667419910430908,
              1.1800800561904907,
              -0.9307969808578491,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              2.012049913406372,
              1.1800800561904907,
              -1.3081599473953247,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              1.2563799619674683,
              1.1800800561904907,
              -1.33774995803833,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              0.5735350251197815,
              1.1800800561904907,
              -1.012760043144226,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              0.11991900205612183,
              1.1800800561904907,
              -0.4076479971408844,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              -0.0005283699720166624,
              1.1800800561904907,
              0.33893901109695435,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              0.23977400362491608,
              1.1800800561904907,
              1.0559799671173096,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              1.512410044670105,
              1.1800800561904907,
              1.7887799739837646,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              0.7857699990272522,
              1.1800800561904907,
              1.5792100429534912,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              0.8388180136680603,
              0.7208229899406433,
              1.4871100187301636,
              0.32953399419784546,
              0.7208229899406433,
              0.9990829825401306,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.32953399419784546,
              0.7208229899406433,
              0.9990829825401306,
              0.1053989976644516,
              0.7208229899406433,
              0.3302519917488098,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.1053989976644516,
              0.7208229899406433,
              0.3302519917488098,
              0.21775199472904205,
              0.7208229899406433,
              -0.36611801385879517,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              1.2777700424194336,
              0.7208229899406433,
              -1.2336399555206299,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              1.2777700424194336,
              0.7208229899406433,
              -1.2336399555206299,
              1.9825899600982666,
              0.7208229899406433,
              -1.2060400247573853,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              1.9825899600982666,
              0.7208229899406433,
              -1.2060400247573853,
              2.593869924545288,
              0.7208229899406433,
              -0.8540560007095337,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              0.8388180136680603,
              0.7208229899406433,
              1.4871100187301636,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              3.0291199684143066,
              0.7208229899406433,
              0.44469699263572693,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              3.0291199684143066,
              0.7208229899406433,
              0.44469699263572693,
              2.7534000873565674,
              0.7208229899406433,
              1.0939600467681885,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              1.512410044670105,
              0.7208229899406433,
              1.7887799739837646,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              1.512410044670105,
              0.7208229899406433,
              1.7887799739837646,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              2.253200054168701,
              0.8240240216255188,
              1.6366599798202515,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              2.2075300216674805,
              0.7208229899406433,
              1.5406800508499146,
              2.7534000873565674,
              0.7208229899406433,
              1.0939600467681885,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              2.83843994140625,
              0.8240240216255188,
              1.1576999425888062,
              2.7534000873565674,
              0.7208229899406433,
              1.0939600467681885,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              2.7534000873565674,
              0.7208229899406433,
              1.0939600467681885,
              3.0291199684143066,
              0.7208229899406433,
              0.44469699263572693,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              3.1340599060058594,
              0.8240240216255188,
              0.46162599325180054,
              3.0291199684143066,
              0.7208229899406433,
              0.44469699263572693,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              3.0291199684143066,
              0.7208229899406433,
              0.44469699263572693,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              3.0723400115966797,
              0.8240240216255188,
              -0.2920829951763153,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              2.9715499877929688,
              0.7208229899406433,
              -0.258309006690979,
              2.593869924545288,
              0.7208229899406433,
              -0.8540560007095337,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              2.667419910430908,
              0.8240240216255188,
              -0.9307969808578491,
              2.593869924545288,
              0.7208229899406433,
              -0.8540560007095337,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              2.593869924545288,
              0.7208229899406433,
              -0.8540560007095337,
              1.9825899600982666,
              0.7208229899406433,
              -1.2060400247573853,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              2.012049913406372,
              0.8240240216255188,
              -1.3081599473953247,
              1.9825899600982666,
              0.7208229899406433,
              -1.2060400247573853,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              1.9825899600982666,
              0.7208229899406433,
              -1.2060400247573853,
              1.2777700424194336,
              0.7208229899406433,
              -1.2336399555206299,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              1.2563799619674683,
              0.8240240216255188,
              -1.33774995803833,
              1.2777700424194336,
              0.7208229899406433,
              -1.2336399555206299,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              1.2777700424194336,
              0.7208229899406433,
              -1.2336399555206299,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              0.5735350251197815,
              0.8240240216255188,
              -1.012760043144226,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              0.6408489942550659,
              0.7208229899406433,
              -0.9305009841918945,
              0.21775199472904205,
              0.7208229899406433,
              -0.36611801385879517,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              0.11991900205612183,
              0.8240240216255188,
              -0.4076479971408844,
              0.21775199472904205,
              0.7208229899406433,
              -0.36611801385879517,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              0.21775199472904205,
              0.7208229899406433,
              -0.36611801385879517,
              0.1053989976644516,
              0.7208229899406433,
              0.3302519917488098,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              -0.0005283699720166624,
              0.8240240216255188,
              0.33893901109695435,
              0.1053989976644516,
              0.7208229899406433,
              0.3302519917488098,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              0.1053989976644516,
              0.7208229899406433,
              0.3302519917488098,
              0.32953399419784546,
              0.7208229899406433,
              0.9990829825401306,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              0.23977400362491608,
              0.8240240216255188,
              1.0559799671173096,
              0.32953399419784546,
              0.7208229899406433,
              0.9990829825401306,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              0.32953399419784546,
              0.7208229899406433,
              0.9990829825401306,
              0.8388180136680603,
              0.7208229899406433,
              1.4871100187301636,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              0.7857699990272522,
              0.8240240216255188,
              1.5792100429534912,
              0.8388180136680603,
              0.7208229899406433,
              1.4871100187301636,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              0.8388180136680603,
              0.7208229899406433,
              1.4871100187301636,
              1.512410044670105,
              0.7208229899406433,
              1.7887799739837646,
              1.512410044670105,
              0.8240240216255188,
              1.7887799739837646,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              0.9650350213050842,
              19.43239974975586,
              0.603568971157074,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              0.9650350213050842,
              19.43239974975586,
              0.603568971157074,
              1.0069899559020996,
              19.437299728393555,
              0.5582349896430969,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              0.9650350213050842,
              19.43239974975586,
              0.603568971157074,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              0.9231039881706238,
              19.412399291992188,
              0.5626310110092163,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.9231039881706238,
              19.412399291992188,
              0.5626310110092163,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              0.9650350213050842,
              19.417200088500977,
              0.5172970294952393,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.9650350213050842,
              19.417200088500977,
              0.5172970294952393,
              0.9231039881706238,
              19.412399291992188,
              0.5626310110092163,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              1.0069899559020996,
              19.437299728393555,
              0.5582349896430969,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              1.0069899559020996,
              19.437299728393555,
              0.5582349896430969,
              0.9650350213050842,
              19.417200088500977,
              0.5172970294952393,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.039289601147174835,
              22.46150016784668,
              0.06945160031318665,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              -0.009278169833123684,
              22.463300704956055,
              0.024688100442290306,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.08377829939126968,
              22.458099365234375,
              0.025596899911761284,
              0.03518940135836601,
              22.459800720214844,
              -0.019187700003385544,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              0.08007969707250595,
              22.562400817871094,
              0.08316809684038162,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.03628839924931526,
              22.569499969482422,
              0.036903999745845795,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              0.11839699745178223,
              22.538700103759766,
              0.038954101502895355,
              0.07462690025568008,
              22.545799255371094,
              -0.007309949956834316,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              0.16557000577449799,
              22.838600158691406,
              -0.07688560336828232,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              0.11901000142097473,
              22.822900772094727,
              -0.11526600271463394,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              0.21743500232696533,
              22.843599319458008,
              -0.1333789974451065,
              0.17087499797344208,
              22.827899932861328,
              -0.1717810034751892,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.06304509937763214,
              22.937000274658203,
              -0.03565160185098648,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              -0.010715300217270851,
              22.972299575805664,
              -0.06836830079555511,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.09161929786205292,
              22.984800338745117,
              -0.06399340182542801,
              0.017858900129795074,
              23.020099639892578,
              -0.09673120081424713,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              0.0983612984418869,
              22.940900802612305,
              0.026569200679659843,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.05520410090684891,
              22.979299545288086,
              0.04895089939236641,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.13116200268268585,
              22.98940086364746,
              0.006469979882240295,
              0.0880052000284195,
              23.027799606323242,
              0.028851699084043503,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.14458300173282623,
              22.942399978637695,
              0.11121399700641632,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.10220800340175629,
              22.98080062866211,
              0.1350329965353012,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.1793919950723648,
              22.990999221801758,
              0.09481339901685715,
              0.13703800737857819,
              23.029399871826172,
              0.11861100047826767,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.1787160038948059,
              22.93589973449707,
              0.1785919964313507,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.1381790041923523,
              22.974000930786133,
              0.20600299537181854,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.2182379961013794,
              22.983699798583984,
              0.17151199281215668,
              0.1777220070362091,
              23.021799087524414,
              0.1989230066537857,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.19399599730968475,
              22.922199249267578,
              0.215830996632576,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.15726399421691895,
              22.95680046081543,
              0.2524999976158142,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.24332499504089355,
              22.96109962463379,
              0.23259100317955017,
              0.20659300684928894,
              22.995800018310547,
              0.2692599892616272,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.19452500343322754,
              22.910600662231445,
              0.22430600225925446,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.15840500593185425,
              22.930999755859375,
              0.27135199308395386,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.24545900523662567,
              22.912900924682617,
              0.2678230106830597,
              0.2093610018491745,
              22.933300018310547,
              0.3148899972438812,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.1802159994840622,
              22.875600814819336,
              0.2204809933900833,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.1387290060520172,
              22.88290023803711,
              0.26611098647117615,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.2173289954662323,
              22.844100952148438,
              0.2603200078010559,
              0.17586299777030945,
              22.85140037536621,
              0.30594998598098755,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.1312050074338913,
              22.80030059814453,
              0.17884500324726105,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.08582829684019089,
              22.801300048828125,
              0.22117799520492554,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.16113199293613434,
              22.757400512695312,
              0.21230199933052063,
              0.11575499922037125,
              22.758399963378906,
              0.2546350061893463,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.08371490240097046,
              22.730899810791016,
              0.1205340027809143,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.031998101621866226,
              22.722299575805664,
              0.15502600371837616,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.11015400290489197,
              22.68269920349121,
              0.14938299357891083,
              0.05843770131468773,
              22.674100875854492,
              0.183896005153656,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.07113970071077347,
              22.704099655151367,
              0.08044169843196869,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.01185659971088171,
              22.679399490356445,
              0.09086109697818756,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.0945781022310257,
              22.649499893188477,
              0.09973769634962082,
              0.03529509902000427,
              22.624799728393555,
              0.11015699803829193,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.07644449919462204,
              22.7012996673584,
              0.05584080144762993,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.025171499699354172,
              22.672500610351562,
              0.029105300083756447,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.10307399928569794,
              22.645099639892578,
              0.06036359816789627,
              0.051801301538944244,
              22.616300582885742,
              0.03362809866666794,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.09975620359182358,
              22.715200424194336,
              0.024603599682450294,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.06367909908294678,
              22.69540023803711,
              -0.022526999935507774,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.132409006357193,
              22.662599563598633,
              0.021031800657510757,
              0.09635350108146667,
              22.642799377441406,
              -0.02609880082309246,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.1451749950647354,
              22.75040054321289,
              -0.01238229963928461,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.11736100167036057,
              22.73699951171875,
              -0.06621260195970535,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.1811250001192093,
              22.700300216674805,
              -0.018617000430822372,
              0.15331199765205383,
              22.686899185180664,
              -0.07244729995727539,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.20536699891090393,
              22.800800323486328,
              -0.04993880167603493,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.18116700649261475,
              22.79050064086914,
              -0.10603000223636627,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.2427539974451065,
              22.75200080871582,
              -0.05708229914307594,
              0.21853399276733398,
              22.741600036621094,
              -0.11317399889230728,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.08130549639463425,
              23.18939971923828,
              -0.11241299659013748,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.13038000464439392,
              23.21190071105957,
              -0.14280499517917633,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.13038000464439392,
              23.21190071105957,
              -0.14280499517917633,
              0.05634529888629913,
              23.171300888061523,
              -0.16611699759960175,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.08130549639463425,
              23.18939971923828,
              -0.11241299659013748,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.25881698727607727,
              22.846500396728516,
              -0.07914700359106064,
              0.08130549639463425,
              23.18939971923828,
              -0.11241299659013748,
              0.10542000085115433,
              23.19379997253418,
              -0.19652999937534332,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.05634529888629913,
              23.171300888061523,
              -0.16611699759960175,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.23104499280452728,
              22.833799362182617,
              -0.13339999318122864,
              0.05634529888629913,
              23.171300888061523,
              -0.16611699759960175,
              0.13038000464439392,
              23.21190071105957,
              -0.14280499517917633,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.10542000085115433,
              23.19379997253418,
              -0.19652999937534332,
              0.3297869861125946,
              22.82670021057129,
              -0.10546000301837921,
              0.3020159900188446,
              22.81399917602539,
              -0.15969200432300568,
              0.10542000085115433,
              23.19379997253418,
              -0.19652999937534332,
              0.9231039881706238,
              19.412399291992188,
              0.5626310110092163,
              0.9650350213050842,
              19.417200088500977,
              0.5172970294952393,
              0.9650350213050842,
              19.43239974975586,
              0.603568971157074,
              0.9650350213050842,
              19.417200088500977,
              0.5172970294952393,
              1.0069899559020996,
              19.437299728393555,
              0.5582349896430969,
              0.9650350213050842,
              19.43239974975586,
              0.603568971157074,
              0.05634529888629913,
              23.171300888061523,
              -0.16611699759960175,
              0.08130549639463425,
              23.18939971923828,
              -0.11241299659013748,
              0.13038000464439392,
              23.21190071105957,
              -0.14280499517917633,
              0.10542000085115433,
              23.19379997253418,
              -0.19652999937534332,
              0.05634529888629913,
              23.171300888061523,
              -0.16611699759960175,
              0.13038000464439392,
              23.21190071105957,
              -0.14280499517917633,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              2.1880600452423096,
              19.166900634765625,
              0.34585198760032654,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              2.1880600452423096,
              19.166900634765625,
              0.34585198760032654,
              2.1958200931549072,
              19.17169952392578,
              0.4071219861507416,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              2.2463700771331787,
              19.146799087524414,
              0.340039998292923,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              2.2463700771331787,
              19.146799087524414,
              0.340039998292923,
              2.1880600452423096,
              19.166900634765625,
              0.34585198760032654,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              2.254149913787842,
              19.151599884033203,
              0.401309996843338,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              2.254149913787842,
              19.151599884033203,
              0.401309996843338,
              2.2463700771331787,
              19.146799087524414,
              0.340039998292923,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              2.1958200931549072,
              19.17169952392578,
              0.4071219861507416,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              2.1958200931549072,
              19.17169952392578,
              0.4071219861507416,
              2.254149913787842,
              19.151599884033203,
              0.401309996843338,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              3.1922600269317627,
              22.195999145507812,
              -0.019969699904322624,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              3.2578001022338867,
              22.19770050048828,
              -0.028423599898815155,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.1972899436950684,
              22.1924991607666,
              0.04229329898953438,
              3.262809991836548,
              22.19420051574707,
              0.03386060148477554,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.155550003051758,
              22.29680061340332,
              0.002454289933666587,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.21914005279541,
              22.30389976501465,
              -0.0013499699998646975,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.164789915084839,
              22.273099899291992,
              0.06023680046200752,
              3.2283599376678467,
              22.28019905090332,
              0.05641140043735504,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.2232000827789307,
              22.572999954223633,
              0.17081399261951447,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.2825300693511963,
              22.557300567626953,
              0.15982399880886078,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.2331600189208984,
              22.577999114990234,
              0.2468779981136322,
              3.292479991912842,
              22.562299728393555,
              0.23588800430297852,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.257499933242798,
              22.67140007019043,
              0.0657740980386734,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.3299999237060547,
              22.70680046081543,
              0.030309999361634254,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.260859966278076,
              22.719200134277344,
              0.1058880016207695,
              3.333359956741333,
              22.754600524902344,
              0.07042379677295685,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.1871700286865234,
              22.67530059814453,
              0.052839700132608414,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.1977601051330566,
              22.713699340820312,
              0.005392080172896385,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.181459903717041,
              22.723899841308594,
              0.0908823013305664,
              3.192049980163574,
              22.762300491333008,
              0.04343470185995102,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.092590093612671,
              22.676799774169922,
              0.033839501440525055,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.1015899181365967,
              22.715200424194336,
              -0.0139250997453928,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              3.082779884338379,
              22.725400924682617,
              0.07105779647827148,
              3.091789960861206,
              22.763900756835938,
              0.023293200880289078,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              3.019040107727051,
              22.670400619506836,
              0.01667799986898899,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              3.024090051651001,
              22.70840072631836,
              -0.03199540078639984,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              2.999069929122925,
              22.71809959411621,
              0.05150819942355156,
              3.004120111465454,
              22.756200790405273,
              0.0028347698971629143,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              2.9807000160217285,
              22.656600952148438,
              0.00444100983440876,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              2.976219892501831,
              22.691299438476562,
              -0.04727580025792122,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.9361701011657715,
              22.695499420166016,
              0.03145129978656769,
              2.931689977645874,
              22.730199813842773,
              -0.020265499129891396,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.9738800525665283,
              22.645000457763672,
              -0.0006102090119384229,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.961050033569336,
              22.6653995513916,
              -0.058519501239061356,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              2.907789945602417,
              22.647300720214844,
              0.010443300008773804,
              2.8949599266052246,
              22.667699813842773,
              -0.04746599867939949,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              2.9860100746154785,
              22.610000610351562,
              -0.009106400422751904,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              2.9776999950408936,
              22.617300033569336,
              -0.07020699977874756,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              2.9316298961639404,
              22.578500747680664,
              -0.006274340208619833,
              2.9233200550079346,
              22.585800170898438,
              -0.06737499684095383,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              3.0493900775909424,
              22.534700393676758,
              -0.019885100424289703,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              3.046109914779663,
              22.53569984436035,
              -0.08185230195522308,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.004539966583252,
              22.49180030822754,
              -0.018469100818037987,
              3.001270055770874,
              22.492799758911133,
              -0.08043629676103592,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.1245899200439453,
              22.465299606323242,
              -0.018786100670695305,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.1314098834991455,
              22.45669937133789,
              -0.08058430254459381,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.0854899883270264,
              22.41710090637207,
              -0.01707419939339161,
              3.092289924621582,
              22.40850067138672,
              -0.07887230068445206,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.1633899211883545,
              22.438499450683594,
              -0.0026603098958730698,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.193510055541992,
              22.413799285888672,
              -0.0547574982047081,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.1335299015045166,
              22.383899688720703,
              0.002898130100220442,
              3.163670063018799,
              22.35930061340332,
              -0.04919910058379173,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.1788198947906494,
              22.435699462890625,
              0.017227500677108765,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.2322700023651123,
              22.40690040588379,
              -0.004858329892158508,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.1582300662994385,
              22.379499435424805,
              0.034706000238657,
              3.2116599082946777,
              22.35070037841797,
              0.012620099820196629,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.1877799034118652,
              22.449600219726562,
              0.05516450107097626,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.2470600605010986,
              22.429899215698242,
              0.05782739818096161,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.1695001125335693,
              22.39699935913086,
              0.08247060328722,
              3.2287800312042236,
              22.377300262451172,
              0.0851546972990036,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.186889886856079,
              22.484800338745117,
              0.11375000327825546,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.24603009223938,
              22.471399307250977,
              0.12704400718212128,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.168570041656494,
              22.43470001220703,
              0.14528299868106842,
              3.227679967880249,
              22.421300888061523,
              0.15857699513435364,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.1769800186157227,
              22.53529930114746,
              0.1839810013771057,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.2355198860168457,
              22.524900436401367,
              0.2015019953250885,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.1584200859069824,
              22.486400604248047,
              0.2172050029039383,
              3.216949939727783,
              22.47599983215332,
              0.23472599685192108,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.3045899868011475,
              22.923900604248047,
              0.1291150003671646,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.296329975128174,
              22.946300506591797,
              0.1862419992685318,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.296329975128174,
              22.946300506591797,
              0.1862419992685318,
              3.3617799282073975,
              22.90570068359375,
              0.1445010006427765,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.3045899868011475,
              22.923900604248047,
              0.1291150003671646,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.165019989013672,
              22.58099937438965,
              0.24370799958705902,
              3.3045899868011475,
              22.923900604248047,
              0.1291150003671646,
              3.353519916534424,
              22.928199768066406,
              0.2016499936580658,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.3617799282073975,
              22.90570068359375,
              0.1445010006427765,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.224410057067871,
              22.568199157714844,
              0.25729799270629883,
              3.3617799282073975,
              22.90570068359375,
              0.1445010006427765,
              3.296329975128174,
              22.946300506591797,
              0.1862419992685318,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.353519916534424,
              22.928199768066406,
              0.2016499936580658,
              3.1395299434661865,
              22.561100006103516,
              0.31497400999069214,
              3.1989400386810303,
              22.54840087890625,
              0.32856398820877075,
              3.353519916534424,
              22.928199768066406,
              0.2016499936580658,
              2.2463700771331787,
              19.146799087524414,
              0.340039998292923,
              2.254149913787842,
              19.151599884033203,
              0.401309996843338,
              2.1880600452423096,
              19.166900634765625,
              0.34585198760032654,
              2.254149913787842,
              19.151599884033203,
              0.401309996843338,
              2.1958200931549072,
              19.17169952392578,
              0.4071219861507416,
              2.1880600452423096,
              19.166900634765625,
              0.34585198760032654,
              3.3617799282073975,
              22.90570068359375,
              0.1445010006427765,
              3.3045899868011475,
              22.923900604248047,
              0.1291150003671646,
              3.296329975128174,
              22.946300506591797,
              0.1862419992685318,
              3.353519916534424,
              22.928199768066406,
              0.2016499936580658,
              3.3617799282073975,
              22.90570068359375,
              0.1445010006427765,
              3.296329975128174,
              22.946300506591797,
              0.1862419992685318
            ],
            "normalized": false
          },
          "normal": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              0,
              0,
              -1,
              0.016602100804448128,
              0.028321199119091034,
              -0.9994509816169739,
              0.22974300384521484,
              0,
              -0.973235011100769,
              0.016602100804448128,
              0.028321199119091034,
              -0.9994509816169739,
              0.22403599321842194,
              0.021149300038814545,
              -0.9743340015411377,
              0.22974300384521484,
              0,
              -0.973235011100769,
              -0.164342001080513,
              0,
              -0.9863889813423157,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              0,
              0,
              -1,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              -0.014984600245952606,
              0.02826010063290596,
              -0.9994810223579407,
              0,
              0,
              -1,
              0.09121979773044586,
              -0.08642839640378952,
              -0.9920650124549866,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              0.016602100804448128,
              0.028321199119091034,
              -0.9994509816169739,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              0.22403599321842194,
              0.021149300038814545,
              -0.9743340015411377,
              0.016602100804448128,
              0.028321199119091034,
              -0.9994509816169739,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.014984600245952606,
              0.02826010063290596,
              -0.9994810223579407,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.08426160365343094,
              -0.09070099890232086,
              -0.9922789931297302,
              -0.014984600245952606,
              0.02826010063290596,
              -0.9994810223579407,
              0,
              0,
              1,
              0.22971299290657043,
              0,
              0.973235011100769,
              0.016602100804448128,
              0.028321199119091034,
              0.9994509816169739,
              0.22971299290657043,
              0,
              0.973235011100769,
              0.22403599321842194,
              0.021149300038814545,
              0.9743340015411377,
              0.016602100804448128,
              0.028321199119091034,
              0.9994509816169739,
              -0.164342001080513,
              0,
              0.9863889813423157,
              0,
              0,
              1,
              -0.15579700469970703,
              0.02447579987347126,
              0.9874569773674011,
              0,
              0,
              1,
              -0.014984600245952606,
              0.02826010063290596,
              0.9994810223579407,
              -0.15579700469970703,
              0.02447579987347126,
              0.9874569773674011,
              0.22403599321842194,
              0.021149300038814545,
              0.9743340015411377,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0.016602100804448128,
              0.028321199119091034,
              0.9994509816169739,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0.09121979773044586,
              -0.08642839640378952,
              0.9920650124549866,
              0.016602100804448128,
              0.028321199119091034,
              0.9994509816169739,
              -0.15579700469970703,
              0.02447579987347126,
              0.9874569773674011,
              -0.014984600245952606,
              0.02826010063290596,
              0.9994810223579407,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              -0.014984600245952606,
              0.02826010063290596,
              0.9994810223579407,
              -0.08423110097646713,
              -0.09064000099897385,
              0.9923089742660522,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              1,
              0,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.9999690055847168,
              0,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.9999690055847168,
              0,
              0,
              0.9886170029640198,
              0.15030400454998016,
              0,
              0.9886170029640198,
              0.15030400454998016,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.9886170029640198,
              0.15030400454998016,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.9971920251846313,
              0.07455670088529587,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.2287359982728958,
              0.9734789729118347,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.2287359982728958,
              0.9734789729118347,
              0,
              0.22876699268817902,
              0.9734789729118347,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.22876699268817902,
              0.9734789729118347,
              0,
              0.3838309943675995,
              0.923367977142334,
              0.000030518498533638194,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              0.3838309943675995,
              0.923367977142334,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.419050008058548,
              0.9079260230064392,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              -0.41907998919487,
              0.9079260230064392,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              -1,
              0,
              0,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              0.22974300384521484,
              0,
              -0.973235011100769,
              0.22974300384521484,
              0,
              -0.973235011100769,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              0.22974300384521484,
              0,
              -0.973235011100769,
              0.22403599321842194,
              0.021149300038814545,
              -0.9743340015411377,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              0.2321539968252182,
              -0.03698839992284775,
              -0.9719539880752563,
              0.18228699266910553,
              -0.04184089973568916,
              -0.9823300242424011,
              0.15414899587631226,
              -0.024750500917434692,
              -0.9877319931983948,
              0.18228699266910553,
              -0.04184089973568916,
              -0.9823300242424011,
              0.21338500082492828,
              -0.04031499847769737,
              -0.9761040210723877,
              0.15414899587631226,
              -0.024750500917434692,
              -0.9877319931983948,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              0.22403599321842194,
              0.021149300038814545,
              0.9743340015411377,
              0.22971299290657043,
              0,
              0.973235011100769,
              0.22403599321842194,
              0.021149300038814545,
              0.9743340015411377,
              0.22971299290657043,
              0,
              0.973235011100769,
              0.22971299290657043,
              0,
              0.973235011100769,
              0.22403599321842194,
              0.021149300038814545,
              0.9743340015411377,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.03686640039086342,
              0.9992979764938354,
              0,
              0.22876699268817902,
              0.9734789729118347,
              0,
              0.03686640039086342,
              0.9992979764938354,
              0,
              0.22876699268817902,
              0.9734789729118347,
              0,
              0.2287359982728958,
              0.9734789729118347,
              0,
              0.03686640039086342,
              0.9992979764938354,
              0,
              -0.164342001080513,
              0,
              -0.9863889813423157,
              0.22974300384521484,
              0,
              -0.973235011100769,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              0.22974300384521484,
              0,
              -0.973235011100769,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              -0.164342001080513,
              0,
              -0.9863889813423157,
              -0.164342001080513,
              0,
              -0.9863889813423157,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              -0.164342001080513,
              0,
              -0.9863889813423157,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              -0.1126129999756813,
              -0.04812769964337349,
              -0.9924619793891907,
              -0.12265399843454361,
              -0.047883499413728714,
              -0.99127197265625,
              0.18228699266910553,
              -0.04184089973568916,
              -0.9823300242424011,
              -0.12265399843454361,
              -0.047883499413728714,
              -0.99127197265625,
              0.21338500082492828,
              -0.04031499847769737,
              -0.9761040210723877,
              0.18228699266910553,
              -0.04184089973568916,
              -0.9823300242424011,
              -0.14075100421905518,
              -0.04400769993662834,
              -0.9890440106391907,
              -0.11319299787282944,
              -0.028321199119091034,
              -0.9931640028953552,
              -0.1126129999756813,
              -0.04812769964337349,
              -0.9924619793891907,
              -0.11319299787282944,
              -0.028321199119091034,
              -0.9931640028953552,
              -0.12265399843454361,
              -0.047883499413728714,
              -0.99127197265625,
              -0.1126129999756813,
              -0.04812769964337349,
              -0.9924619793891907,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              -0.164342001080513,
              0,
              0.9863889813423157,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              0.22971299290657043,
              0,
              0.973235011100769,
              -0.164342001080513,
              0,
              0.9863889813423157,
              -0.15579700469970703,
              0.02447579987347126,
              0.9874569773674011,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              -0.164342001080513,
              0,
              0.9863889813423157,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              -0.164342001080513,
              0,
              0.9863889813423157,
              -0.164342001080513,
              0,
              0.9863889813423157,
              0.22467699646949768,
              0.013672299683094025,
              0.9743340015411377,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              -0.15579700469970703,
              0.02447579987347126,
              0.9874569773674011,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              -0.1569570004940033,
              0.017487099394202232,
              0.9874259829521179,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              -0.05774100124835968,
              0.998320996761322,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              -0.05774100124835968,
              0.998320996761322,
              0,
              -0.05774100124835968,
              0.998320996761322,
              0,
              -0.26755601167678833,
              0.9635300040245056,
              0,
              0.0891140028834343,
              -0.0197759997099638,
              -0.9958189725875854,
              0.15414899587631226,
              -0.024750500917434692,
              -0.9877319931983948,
              0.09576710313558578,
              0.01916559971868992,
              -0.9952089786529541,
              0.15414899587631226,
              -0.024750500917434692,
              -0.9877319931983948,
              0.16037499904632568,
              0.014007999561727047,
              -0.9869379997253418,
              0.09576710313558578,
              0.01916559971868992,
              -0.9952089786529541,
              -0.10794399678707123,
              0.017151400446891785,
              -0.9939879775047302,
              -0.11319299787282944,
              -0.028321199119091034,
              -0.9931640028953552,
              -0.0877406969666481,
              0.019074099138379097,
              -0.9959409832954407,
              -0.11319299787282944,
              -0.028321199119091034,
              -0.9931640028953552,
              -0.08130130171775818,
              -0.019989600405097008,
              -0.9964600205421448,
              -0.0877406969666481,
              0.019074099138379097,
              -0.9959409832954407,
              0.1604049950838089,
              0.014038500376045704,
              0.9869379997253418,
              0.15421000123023987,
              -0.024750500917434692,
              0.9877009987831116,
              0.09576710313558578,
              0.01916559971868992,
              0.9952089786529541,
              0.15421000123023987,
              -0.024750500917434692,
              0.9877009987831116,
              0.0891140028834343,
              -0.0197759997099638,
              0.9958189725875854,
              0.09576710313558578,
              0.01916559971868992,
              0.9952089786529541,
              -0.08130130171775818,
              -0.020020099356770515,
              0.9964600205421448,
              -0.11322399973869324,
              -0.028321199119091034,
              0.9931640028953552,
              -0.08771020174026489,
              0.019074099138379097,
              0.9959409832954407,
              -0.11322399973869324,
              -0.028321199119091034,
              0.9931640028953552,
              -0.10794399678707123,
              0.017151400446891785,
              0.9939879775047302,
              -0.08771020174026489,
              0.019074099138379097,
              0.9959409832954407,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              0.20468799769878387,
              0.018555300310254097,
              -0.9786369800567627,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              0.16037499904632568,
              0.014007999561727047,
              -0.9869379997253418,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              0.16037499904632568,
              0.014007999561727047,
              -0.9869379997253418,
              -0.10251200199127197,
              0.024140100926160812,
              -0.9944149851799011,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              0.20468799769878387,
              0.018555300310254097,
              -0.9786369800567627,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              0.20468799769878387,
              0.018555300310254097,
              -0.9786369800567627,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              -0.10794399678707123,
              0.017151400446891785,
              -0.9939879775047302,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              -0.10251200199127197,
              0.024140100926160812,
              -0.9944149851799011,
              -0.10794399678707123,
              0.017151400446891785,
              -0.9939879775047302,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              0.06891079992055893,
              -0.04168830066919327,
              -0.9967340230941772,
              0.2321539968252182,
              -0.03698839992284775,
              -0.9719539880752563,
              0.0891140028834343,
              -0.0197759997099638,
              -0.9958189725875854,
              0.2321539968252182,
              -0.03698839992284775,
              -0.9719539880752563,
              0.15414899587631226,
              -0.024750500917434692,
              -0.9877319931983948,
              0.0891140028834343,
              -0.0197759997099638,
              -0.9958189725875854,
              -0.11319299787282944,
              -0.028321199119091034,
              -0.9931640028953552,
              -0.14075100421905518,
              -0.04400769993662834,
              -0.9890440106391907,
              -0.08130130171775818,
              -0.019989600405097008,
              -0.9964600205421448,
              -0.14075100421905518,
              -0.04400769993662834,
              -0.9890440106391907,
              -0.06265450268983841,
              -0.04190190136432648,
              -0.9971309900283813,
              -0.08130130171775818,
              -0.019989600405097008,
              -0.9964600205421448,
              0.15421000123023987,
              -0.024750500917434692,
              0.9877009987831116,
              0.23224599659442902,
              -0.03698839992284775,
              0.9719229936599731,
              0.0891140028834343,
              -0.0197759997099638,
              0.9958189725875854,
              0.23224599659442902,
              -0.03698839992284775,
              0.9719229936599731,
              0.06891079992055893,
              -0.04168830066919327,
              0.9967340230941772,
              0.0891140028834343,
              -0.0197759997099638,
              0.9958189725875854,
              -0.06265450268983841,
              -0.04190190136432648,
              0.9971309900283813,
              -0.1408119946718216,
              -0.04400769993662834,
              0.9890440106391907,
              -0.08130130171775818,
              -0.020020099356770515,
              0.9964600205421448,
              -0.1408119946718216,
              -0.04400769993662834,
              0.9890440106391907,
              -0.11322399973869324,
              -0.028321199119091034,
              0.9931640028953552,
              -0.08130130171775818,
              -0.020020099356770515,
              0.9964600205421448,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.2134770005941391,
              -0.04031499847769737,
              0.9761040210723877,
              0.18237900733947754,
              -0.04184089973568916,
              0.9823300242424011,
              0.15421000123023987,
              -0.024750500917434692,
              0.9877009987831116,
              0.18237900733947754,
              -0.04184089973568916,
              0.9823300242424011,
              0.23224599659442902,
              -0.03698839992284775,
              0.9719229936599731,
              0.15421000123023987,
              -0.024750500917434692,
              0.9877009987831116,
              -0.1126440018415451,
              -0.04812769964337349,
              0.9924619793891907,
              0.18237900733947754,
              -0.04184089973568916,
              0.9823300242424011,
              -0.12271499633789062,
              -0.047883499413728714,
              0.99127197265625,
              0.18237900733947754,
              -0.04184089973568916,
              0.9823300242424011,
              0.2134770005941391,
              -0.04031499847769737,
              0.9761040210723877,
              -0.12271499633789062,
              -0.047883499413728714,
              0.99127197265625,
              -0.1408119946718216,
              -0.04400769993662834,
              0.9890440106391907,
              -0.1126440018415451,
              -0.04812769964337349,
              0.9924619793891907,
              -0.11322399973869324,
              -0.028321199119091034,
              0.9931640028953552,
              -0.1126440018415451,
              -0.04812769964337349,
              0.9924619793891907,
              -0.12271499633789062,
              -0.047883499413728714,
              0.99127197265625,
              -0.11322399973869324,
              -0.028321199119091034,
              0.9931640028953552,
              0.4318059980869293,
              -0.9019439816474915,
              0,
              0.4317759871482849,
              -0.9019439816474915,
              0,
              -0.2787559926509857,
              -0.9603559970855713,
              0,
              0.4317759871482849,
              -0.9019439816474915,
              0,
              -0.2786949872970581,
              -0.9603559970855713,
              0,
              -0.2787559926509857,
              -0.9603559970855713,
              0,
              -0.2787559926509857,
              -0.9603559970855713,
              0,
              -0.2786949872970581,
              -0.9603559970855713,
              0,
              -0.5760369896888733,
              -0.81740802526474,
              0,
              -0.2786949872970581,
              -0.9603559970855713,
              0,
              -0.5760369896888733,
              -0.81740802526474,
              0,
              -0.5760369896888733,
              -0.81740802526474,
              0,
              0.4282970130443573,
              0.9036229848861694,
              0,
              0.4283269941806793,
              0.9035919904708862,
              0,
              -0.2739950120449066,
              0.9617300033569336,
              0,
              0.4283269941806793,
              0.9035919904708862,
              0,
              -0.2739950120449066,
              0.9616990089416504,
              0,
              -0.2739950120449066,
              0.9617300033569336,
              0,
              -0.2739950120449066,
              0.9617300033569336,
              0,
              -0.2739950120449066,
              0.9616990089416504,
              0,
              -0.570360004901886,
              0.8213750123977661,
              0,
              -0.2739950120449066,
              0.9616990089416504,
              0,
              -0.570360004901886,
              0.8213750123977661,
              0,
              -0.570360004901886,
              0.8213750123977661,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              -0.9999690055847168,
              0.002563549904152751,
              0,
              0.4317759871482849,
              -0.9019439816474915,
              0,
              0.4318059980869293,
              -0.9019439816474915,
              0,
              0.6973170042037964,
              -0.7167270183563232,
              0,
              0.4318059980869293,
              -0.9019439816474915,
              0,
              0.6973170042037964,
              -0.7167270183563232,
              0,
              0.6973170042037964,
              -0.7167270183563232,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.9999690055847168,
              0.002563549904152751,
              0,
              0.6932889819145203,
              0.7206339836120605,
              0,
              0.6932889819145203,
              0.7206339836120605,
              0,
              0.4282970130443573,
              0.9036229848861694,
              0,
              0.6932889819145203,
              0.7206339836120605,
              0,
              0.4283269941806793,
              0.9035919904708862,
              0,
              0.4282970130443573,
              0.9036229848861694,
              0,
              0.1030300036072731,
              -0.039368901401758194,
              -0.9938960075378418,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              0.09121979773044586,
              -0.08642839640378952,
              -0.9920650124549866,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              0.09121979773044586,
              -0.08642839640378952,
              -0.9920650124549866,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              -0.08426160365343094,
              -0.09070099890232086,
              -0.9922789931297302,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              -0.09372229874134064,
              -0.036591701209545135,
              -0.994903028011322,
              -0.08426160365343094,
              -0.09070099890232086,
              -0.9922789931297302,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.09121979773044586,
              -0.08642839640378952,
              0.9920650124549866,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.1030300036072731,
              -0.039368901401758194,
              0.9938960075378418,
              0.09121979773044586,
              -0.08642839640378952,
              0.9920650124549866,
              -0.09369180351495743,
              -0.03656119853258133,
              0.994903028011322,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.08423110097646713,
              -0.09064000099897385,
              0.9923089742660522,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              -0.08423110097646713,
              -0.09064000099897385,
              0.9923089742660522,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.31888800859451294,
              -0.9477829933166504,
              0,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.24366000294685364,
              -0.07254250347614288,
              0.9671319723129272,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              0.1647389978170395,
              -0.07300029695034027,
              0.9836120009422302,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              -0.12573599815368652,
              -0.10397700220346451,
              0.9865720272064209,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              -0.11645899713039398,
              -0.07693719863891602,
              0.9902039766311646,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              -0.9899899959564209,
              0.14093400537967682,
              0,
              -0.9975280165672302,
              0.06988739967346191,
              0,
              -0.9899899959564209,
              0.14093400537967682,
              0,
              -0.9899899959564209,
              0.14093400537967682,
              0,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              0.22403599321842194,
              0.021149300038814545,
              -0.9743340015411377,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              0.22467699646949768,
              0.013672299683094025,
              -0.9743030071258545,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              -0.15579700469970703,
              0.02447579987347126,
              -0.9874569773674011,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.1569570004940033,
              0.017487099394202232,
              -0.9874259829521179,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              -0.338144987821579,
              -0.9410690069198608,
              0,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              0.24366000294685364,
              -0.07254250347614288,
              -0.9671319723129272,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              0.1647080034017563,
              -0.07303079962730408,
              -0.9836120009422302,
              0.16663099825382233,
              -0.08169809728860855,
              -0.9826040267944336,
              -0.1256749927997589,
              -0.10400699824094772,
              -0.9865720272064209,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              -0.11645899713039398,
              -0.07696770131587982,
              -0.9902039766311646,
              -0.07608260214328766,
              -0.11777099967002869,
              -0.9901120066642761,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              0.09576710313558578,
              0.01916559971868992,
              -0.9952089786529541,
              0.16037499904632568,
              0.014007999561727047,
              -0.9869379997253418,
              0.1030300036072731,
              -0.039368901401758194,
              -0.9938960075378418,
              0.16037499904632568,
              0.014007999561727047,
              -0.9869379997253418,
              0.21164600551128387,
              -0.04840239882469177,
              -0.9761350154876709,
              0.1030300036072731,
              -0.039368901401758194,
              -0.9938960075378418,
              -0.1164890006184578,
              -0.06100650131702423,
              -0.9913020133972168,
              -0.10794399678707123,
              0.017151400446891785,
              -0.9939879775047302,
              -0.09372229874134064,
              -0.036591701209545135,
              -0.994903028011322,
              -0.10794399678707123,
              0.017151400446891785,
              -0.9939879775047302,
              -0.0877406969666481,
              0.019074099138379097,
              -0.9959409832954407,
              -0.09372229874134064,
              -0.036591701209545135,
              -0.994903028011322,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.1604049950838089,
              0.014038500376045704,
              0.9869379997253418,
              0.1030300036072731,
              -0.039368901401758194,
              0.9938960075378418,
              0.1604049950838089,
              0.014038500376045704,
              0.9869379997253418,
              0.09576710313558578,
              0.01916559971868992,
              0.9952089786529541,
              0.1030300036072731,
              -0.039368901401758194,
              0.9938960075378418,
              -0.08771020174026489,
              0.019074099138379097,
              0.9959409832954407,
              -0.10794399678707123,
              0.017151400446891785,
              0.9939879775047302,
              -0.09369180351495743,
              -0.03656119853258133,
              0.994903028011322,
              -0.10794399678707123,
              0.017151400446891785,
              0.9939879775047302,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.09369180351495743,
              -0.03656119853258133,
              0.994903028011322,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.2047490030527115,
              0.018585799261927605,
              0.9786069989204407,
              0.1604049950838089,
              0.014038500376045704,
              0.9869379997253418,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              0.1604049950838089,
              0.014038500376045704,
              0.9869379997253418,
              0.2117069959640503,
              -0.04840239882469177,
              0.9761040210723877,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              -0.10251200199127197,
              0.024140100926160812,
              0.9944149851799011,
              0.2047490030527115,
              0.018585799261927605,
              0.9786069989204407,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              0.2047490030527115,
              0.018585799261927605,
              0.9786069989204407,
              0.16666199266910553,
              -0.08169809728860855,
              0.9826040267944336,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              -0.1164890006184578,
              -0.060975998640060425,
              0.9913020133972168,
              -0.10794399678707123,
              0.017151400446891785,
              0.9939879775047302,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              -0.10794399678707123,
              0.017151400446891785,
              0.9939879775047302,
              -0.10251200199127197,
              0.024140100926160812,
              0.9944149851799011,
              -0.07611320167779922,
              -0.11777099967002869,
              0.9901120066642761,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -1,
              0,
              -0.00010524899698793888,
              -1,
              0,
              -0.00010524899698793888,
              -1,
              0,
              -0.00010524899698793888,
              -1,
              0,
              -0.00010524899698793888,
              -1,
              0,
              -0.00010524899698793888,
              -1,
              0,
              -0.00010524899698793888,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              -0.00010495199967408553,
              1,
              0,
              -0.00010495199967408553,
              1,
              0,
              -0.00010495199967408553,
              1,
              0,
              -0.00010495199967408553,
              1,
              0,
              -0.00010495199967408553,
              1,
              0,
              -0.00010495199967408553,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              -6.203700220197561e-9,
              3.283930141151359e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              2.4292801104053297e-9,
              3.638920134108048e-7,
              -1,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08530019968748093,
              -0.9963549971580505,
              0.00000816472038422944,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              -0.08531180024147034,
              -0.9963539838790894,
              -0.000006843620212748647,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.08530910313129425,
              -0.9963549971580505,
              0.000007163050213421229,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0.0852975994348526,
              -0.9963549971580505,
              -0.000005205959951126715,
              0,
              1.152340018961695e-7,
              -1,
              0,
              1.152340018961695e-7,
              -1,
              0,
              1.152340018961695e-7,
              -1,
              0,
              1.152340018961695e-7,
              -1,
              0,
              1.152340018961695e-7,
              -1,
              0,
              1.152340018961695e-7,
              -1,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              -1,
              5.579989942328112e-11,
              -0.0004660509875975549,
              0,
              -1.152340018961695e-7,
              1,
              0,
              -1.152340018961695e-7,
              1,
              0,
              -1.152340018961695e-7,
              1,
              0,
              -1.152340018961695e-7,
              1,
              0,
              -1.152340018961695e-7,
              1,
              0,
              -1.152340018961695e-7,
              1,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              1,
              5.579989942328112e-11,
              -0.00046552601270377636,
              0.7065749764442444,
              0.03194719925522804,
              -0.7069169878959656,
              0.7065749764442444,
              0.03194719925522804,
              -0.7069169878959656,
              0.7065749764442444,
              0.03194719925522804,
              -0.7069169878959656,
              -0.7067040205001831,
              0.02994910068809986,
              -0.7068750262260437,
              -0.7067040205001831,
              0.02994910068809986,
              -0.7068750262260437,
              -0.7067040205001831,
              0.02994910068809986,
              -0.7068750262260437,
              -0.7069590091705322,
              0.030032599344849586,
              0.706616997718811,
              -0.7069590091705322,
              0.030032599344849586,
              0.706616997718811,
              -0.7069590091705322,
              0.030032599344849586,
              0.706616997718811,
              0.706957995891571,
              0.030043600127100945,
              0.706616997718811,
              0.706957995891571,
              0.030043600127100945,
              0.706616997718811,
              0.706957995891571,
              0.030043600127100945,
              0.706616997718811,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              0.7069360017776489,
              8.499910109094344e-8,
              -0.7072780132293701,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7070220112800598,
              8.50093968551846e-8,
              -0.7071920037269592,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              -0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0.7072780132293701,
              -8.140600016304234e-8,
              0.7069360017776489,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              0,
              -0.6809369921684265,
              -0.732342004776001,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.2635140120983124,
              -0.9279389977455139,
              -0.263606995344162,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.31651800870895386,
              -0.9485870003700256,
              -0.00015094999980647117,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              -0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0,
              -0.6809369921684265,
              0.732342004776001,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.26350900530815125,
              -0.9279950261116028,
              0.26341599225997925,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.31651800870895386,
              -0.9485870003700256,
              -0.0001509019930381328,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0.2635039985179901,
              -0.9279379844665527,
              -0.263619989156723,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              0,
              -0.26787498593330383,
              -0.963454008102417,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.9681450128555298,
              -0.2503890097141266,
              -0.0005095870001241565,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              -0.7681769728660583,
              -0.22853200137615204,
              0.5980610251426697,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0,
              -0.26787498593330383,
              0.963454008102417,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.7681639790534973,
              -0.22849300503730774,
              0.5980920195579529,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.9681450128555298,
              -0.2503890097141266,
              -0.0005097480025142431,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0.7679430246353149,
              -0.22848600149154663,
              -0.5983790159225464,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              0,
              0.2990269958972931,
              -0.9542449712753296,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.9608139991760254,
              0.27719300985336304,
              -0.0005057110101915896,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              -0.7636680006980896,
              0.25464698672294617,
              0.5932679772377014,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0,
              0.2990269958972931,
              0.9542449712753296,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.7636569738388062,
              0.2546049952507019,
              0.5932999849319458,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.9608139991760254,
              0.27719300985336304,
              -0.0005058699753135443,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              0.7634289860725403,
              0.25456398725509644,
              -0.5936110019683838,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              -0.707144021987915,
              0.000002076559894703678,
              -0.707069993019104,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              0.707069993019104,
              0.0000020763500288012438,
              -0.707144021987915,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              -0.7033079862594604,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              0.7048029899597168,
              -0.004515119828283787,
              -0.7093889713287354,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              -0.7071070075035095,
              -7.83320999175885e-8,
              0.7071070075035095,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              0.7070329785346985,
              -7.919420141888622e-8,
              0.7071809768676758,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              -0.7063300013542175,
              -0.0051203700713813305,
              0.707863986492157,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              0.6995069980621338,
              0.13010700047016144,
              0.7026829719543457,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6872879862785339,
              0.0002616519923321903,
              0.7263849973678589,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              -0.6794080138206482,
              0.0015363399870693684,
              -0.7337589859962463,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.6877779960632324,
              0.0003911480016540736,
              -0.7259209752082825,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              0.680791974067688,
              0.001292550005018711,
              0.732475996017456,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.6793670058250427,
              0.0015448000049218535,
              0.733797013759613,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              -0.7063699960708618,
              -0.005114329978823662,
              -0.7078239917755127,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.6808350086212158,
              0.00130152003839612,
              -0.7324360013008118,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              0.7048029899597168,
              -0.004515300039201975,
              0.7093889713287354,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.700082004070282,
              0.12345799803733826,
              0.7033079862594604,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              -0.4491859972476959,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.6995059847831726,
              0.13010700047016144,
              -0.7026829719543457,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              0.2056960016489029,
              -0.8806710243225098,
              0.42674100399017334,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.21011799573898315,
              -0.8683789968490601,
              0.4491859972476959,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              -0.6872879862785339,
              0.0002617290010675788,
              -0.7263849973678589,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.20568999648094177,
              -0.8806949853897095,
              -0.42669299244880676,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.6876950263977051,
              0.0003914519911631942,
              0.7259989976882935,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0.7924129962921143,
              -0.3494980037212372,
              0.4998930096626282,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0.7924129962921143,
              -0.3494980037212372,
              0.4998930096626282,
              0.7924129962921143,
              -0.3494980037212372,
              0.4998930096626282,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.8211309909820557,
              0.27240800857543945,
              0.5015109777450562,
              -0.7924739718437195,
              0.3494980037212372,
              0.49980199337005615,
              -0.8210999965667725,
              0.27243900299072266,
              0.5015109777450562,
              -0.7924739718437195,
              0.3494980037212372,
              0.49980199337005615,
              -0.7924739718437195,
              0.3494980037212372,
              0.49980199337005615,
              -0.8210999965667725,
              0.27243900299072266,
              0.5015109777450562,
              -0.8210390210151672,
              0.27240800857543945,
              -0.5016329884529114,
              -0.7924129962921143,
              0.349467009305954,
              -0.4999240040779114,
              -0.8210700154304504,
              0.27237799763679504,
              -0.5016329884529114,
              -0.7924129962921143,
              0.349467009305954,
              -0.4999240040779114,
              -0.7924129962921143,
              0.349467009305954,
              -0.4999240040779114,
              -0.8210700154304504,
              0.27237799763679504,
              -0.5016329884529114,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.792352020740509,
              -0.349467009305954,
              -0.5000150203704834,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.792352020740509,
              -0.349467009305954,
              -0.5000150203704834,
              0.792352020740509,
              -0.349467009305954,
              -0.5000150203704834,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0.8210390210151672,
              -0.27240800857543945,
              0.5016329884529114,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.8211309909820557,
              0.27240800857543945,
              0.5015109777450562,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.8211309909820557,
              0.27240800857543945,
              0.5015109777450562,
              -0.8210999965667725,
              0.27243900299072266,
              0.5015109777450562,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              -0.8210390210151672,
              0.27240800857543945,
              -0.5016329884529114,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              -0.8210390210151672,
              0.27240800857543945,
              -0.5016329884529114,
              -0.8210700154304504,
              0.27237799763679504,
              -0.5016329884529114,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.8209779858589172,
              -0.27237799763679504,
              -0.5017549991607666,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8636429905891418,
              -0.06247140094637871,
              0.5001680254936218,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0.8636429905891418,
              -0.0624408982694149,
              0.5001680254936218,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0.8536030054092407,
              -0.1438950002193451,
              0.5006260275840759,
              0.8636429905891418,
              -0.0624408982694149,
              0.5001680254936218,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.853663980960846,
              0.1439249962568283,
              0.5005040168762207,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              -0.8536030054092407,
              0.1438950002193451,
              -0.500594973564148,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8535419702529907,
              -0.1438950002193451,
              -0.5007479786872864,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0.8636429905891418,
              -0.06247140094637871,
              0.5001680254936218,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0.8636429905891418,
              -0.06247140094637871,
              0.5001680254936218,
              0.8636429905891418,
              -0.0624408982694149,
              0.5001680254936218,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.8636429905891418,
              0.06247140094637871,
              0.5001980066299438,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              -0.8635820150375366,
              0.06247140094637871,
              -0.5002899765968323,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8635820150375366,
              -0.0624408982694149,
              -0.5002899765968323,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0.8659629821777344,
              -0.01104770042002201,
              0.4999539852142334,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.8659319877624512,
              0.011078200303018093,
              0.5000150203704834,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              -0.865871012210846,
              0.011078200303018093,
              -0.5001369714736938,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.8659020066261292,
              -0.01104770042002201,
              -0.5000759959220886,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0.865809977054596,
              0.020416900515556335,
              0.4999240040779114,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              -0.00006103699706727639,
              0,
              1,
              0,
              0,
              1,
              -0.00006103699706727639,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.00006103699706727639,
              0,
              1,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8658409714698792,
              -0.020447399467229843,
              0.49983200430870056,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              -0.8657799959182739,
              -0.020447399467229843,
              -0.4999539852142334,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8657190203666687,
              0.020416900515556335,
              -0.5000460147857666,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.866756021976471,
              -0.02844329923391342,
              0.4978480041027069,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              0.866756021976471,
              -0.028412699699401855,
              0.4978480041027069,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              0.8651689887046814,
              0.037537798285484314,
              0.5000460147857666,
              0.866756021976471,
              -0.028412699699401855,
              0.4978480041027069,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              -0.00006103699706727639,
              0,
              1,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              -0.00006103699706727639,
              0,
              1,
              -0.00006103699706727639,
              0,
              1,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8652909994125366,
              -0.037537798285484314,
              0.49983200430870056,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8649560213088989,
              -0.04297009855508804,
              -0.499985009431839,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              -0.8649560213088989,
              -0.04297009855508804,
              -0.499985009431839,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              -0.8652300238609314,
              -0.037537798285484314,
              -0.4999240040779114,
              -0.8649560213088989,
              -0.04297009855508804,
              -0.499985009431839,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              0,
              0,
              -1,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              0.8646810054779053,
              0.012268399819731712,
              -0.5021209716796875,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.8646810054779053,
              0.01223789993673563,
              -0.5021209716796875,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.8651689887046814,
              0.037537798285484314,
              -0.5000460147857666,
              0.8646810054779053,
              0.01223789993673563,
              -0.5021209716796875,
              0.8648030161857605,
              0.1359899938106537,
              0.48329100012779236,
              0.866756021976471,
              -0.02844329923391342,
              0.4978480041027069,
              0.8647720217704773,
              0.13620400428771973,
              0.48329100012779236,
              0.866756021976471,
              -0.02844329923391342,
              0.4978480041027069,
              0.866756021976471,
              -0.028412699699401855,
              0.4978480041027069,
              0.8647720217704773,
              0.13620400428771973,
              0.48329100012779236,
              0.02139350026845932,
              -0.013123000040650368,
              0.9996640086174011,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              0.02139350026845932,
              -0.013061899691820145,
              0.9996640086174011,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              0.00018311099847778678,
              -0.0406200997531414,
              0.9991459846496582,
              0.02139350026845932,
              -0.013061899691820145,
              0.9996640086174011,
              -0.8316289782524109,
              -0.14749599993228912,
              0.5353249907493591,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8315989971160889,
              -0.14767900109291077,
              0.5353559851646423,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8647109866142273,
              -0.01223789993673563,
              0.5020909905433655,
              -0.8315989971160889,
              -0.14767900109291077,
              0.5353559851646423,
              -0.8648639917373657,
              -0.1359899938106537,
              -0.48316898941993713,
              -0.8643450140953064,
              0.09970399737358093,
              -0.49284300208091736,
              -0.8648639917373657,
              -0.13617399334907532,
              -0.4831390082836151,
              -0.8643450140953064,
              0.09970399737358093,
              -0.49284300208091736,
              -0.8643450140953064,
              0.09970399737358093,
              -0.49284300208091736,
              -0.8648639917373657,
              -0.13617399334907532,
              -0.4831390082836151,
              -0.02151549980044365,
              0.01315349992364645,
              -0.9996640086174011,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              -0.021546099334955215,
              0.013061899691820145,
              -0.9996640086174011,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              -0.00036622199695557356,
              0.04065069928765297,
              -0.9991459846496582,
              -0.021546099334955215,
              0.013061899691820145,
              -0.9996640086174011,
              0.8316599726676941,
              0.14749599993228912,
              -0.5352950096130371,
              0.8646810054779053,
              0.012268399819731712,
              -0.5021209716796875,
              0.8316289782524109,
              0.14764900505542755,
              -0.5353249907493591,
              0.8646810054779053,
              0.012268399819731712,
              -0.5021209716796875,
              0.8646810054779053,
              0.01223789993673563,
              -0.5021209716796875,
              0.8316289782524109,
              0.14764900505542755,
              -0.5353249907493591,
              0.8171640038490295,
              0.364329993724823,
              0.4466080069541931,
              0.8648030161857605,
              0.1359899938106537,
              0.48329100012779236,
              0.8171640038490295,
              0.364329993724823,
              0.4466080069541931,
              0.8648030161857605,
              0.1359899938106537,
              0.48329100012779236,
              0.8647720217704773,
              0.13620400428771973,
              0.48329100012779236,
              0.8171640038490295,
              0.364329993724823,
              0.4466080069541931,
              0.04214610159397125,
              0.0550553984940052,
              0.9975889921188354,
              0.02139350026845932,
              -0.013123000040650368,
              0.9996640086174011,
              0.04214610159397125,
              0.0550553984940052,
              0.9975889921188354,
              0.02139350026845932,
              -0.013123000040650368,
              0.9996640086174011,
              0.02139350026845932,
              -0.013061899691820145,
              0.9996640086174011,
              0.04214610159397125,
              0.0550553984940052,
              0.9975889921188354,
              -0.7750179767608643,
              -0.3092750012874603,
              0.5510420203208923,
              -0.8316289782524109,
              -0.14749599993228912,
              0.5353249907493591,
              -0.7750179767608643,
              -0.3092750012874603,
              0.5510420203208923,
              -0.8316289782524109,
              -0.14749599993228912,
              0.5353249907493591,
              -0.8315989971160889,
              -0.14767900109291077,
              0.5353559851646423,
              -0.7750179767608643,
              -0.3092750012874603,
              0.5510420203208923,
              -0.7857909798622131,
              -0.6102179884910583,
              -0.10071100294589996,
              -0.8648639917373657,
              -0.1359899938106537,
              -0.48316898941993713,
              -0.7930229902267456,
              -0.5957520008087158,
              -0.12704899907112122,
              -0.8648639917373657,
              -0.1359899938106537,
              -0.48316898941993713,
              -0.8648639917373657,
              -0.13617399334907532,
              -0.4831390082836151,
              -0.7930229902267456,
              -0.5957520008087158,
              -0.12704899907112122,
              -0.04217660054564476,
              -0.05508590117096901,
              -0.9975889921188354,
              -0.02151549980044365,
              0.01315349992364645,
              -0.9996640086174011,
              -0.04217660054564476,
              -0.05508590117096901,
              -0.9975889921188354,
              -0.02151549980044365,
              0.01315349992364645,
              -0.9996640086174011,
              -0.021546099334955215,
              0.013061899691820145,
              -0.9996640086174011,
              -0.04217660054564476,
              -0.05508590117096901,
              -0.9975889921188354,
              0.7750179767608643,
              0.3092750012874603,
              -0.5510119795799255,
              0.8316599726676941,
              0.14749599993228912,
              -0.5352950096130371,
              0.7750179767608643,
              0.3092750012874603,
              -0.5510119795799255,
              0.8316599726676941,
              0.14749599993228912,
              -0.5352950096130371,
              0.8316289782524109,
              0.14764900505542755,
              -0.5353249907493591,
              0.7750179767608643,
              0.3092750012874603,
              -0.5510119795799255,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              0.6295049786567688,
              0.739982008934021,
              -0.23685400187969208,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.3487040102481842,
              0.8373969793319702,
              -0.42085000872612,
              -0.9642630219459534,
              -0.1065400019288063,
              -0.24253100156784058,
              -0.9780570268630981,
              0.09759820252656937,
              -0.18405699729919434,
              -0.9650560021400452,
              -0.10196200013160706,
              -0.24133999645709991,
              -0.9780570268630981,
              0.09759820252656937,
              -0.18405699729919434,
              -0.9780570268630981,
              0.09759820252656937,
              -0.18405699729919434,
              -0.9650560021400452,
              -0.10196200013160706,
              -0.24133999645709991,
              -0.6294749975204468,
              -0.739982008934021,
              0.2369460016489029,
              -0.7857909798622131,
              -0.6102179884910583,
              -0.10071100294589996,
              -0.6294749975204468,
              -0.739982008934021,
              0.2369460016489029,
              -0.7857909798622131,
              -0.6102179884910583,
              -0.10071100294589996,
              -0.7930229902267456,
              -0.5957520008087158,
              -0.12704899907112122,
              -0.6294749975204468,
              -0.739982008934021,
              0.2369460016489029,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.34855198860168457,
              -0.837306022644043,
              0.42115500569343567,
              0.963683009147644,
              0.10977499932050705,
              0.24332399666309357,
              0.9780570268630981,
              -0.09744559973478317,
              0.1839659959077835,
              0.9657580256462097,
              0.09796439856290817,
              0.24021099507808685,
              0.9780570268630981,
              -0.09744559973478317,
              0.1839659959077835,
              0.9780570268630981,
              -0.09744559973478317,
              0.1839659959077835,
              0.9657580256462097,
              0.09796439856290817,
              0.24021099507808685,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              0.7881100177764893,
              -0.13983599841594696,
              -0.5994139909744263,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.12018200010061264,
              -0.4421829879283905,
              -0.888821005821228,
              -0.9082310199737549,
              -0.3022550046443939,
              -0.28928500413894653,
              -0.9642630219459534,
              -0.1065400019288063,
              -0.24253100156784058,
              -0.9082310199737549,
              -0.3022550046443939,
              -0.28928500413894653,
              -0.9642630219459534,
              -0.1065400019288063,
              -0.24253100156784058,
              -0.9650560021400452,
              -0.10196200013160706,
              -0.24133999645709991,
              -0.9082310199737549,
              -0.3022550046443939,
              -0.28928500413894653,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              -0.787988007068634,
              0.1399269998073578,
              0.5995669960975647,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.12018200010061264,
              0.4421829879283905,
              0.888821005821228,
              0.9082610011100769,
              0.3022550046443939,
              0.28925400972366333,
              0.963683009147644,
              0.10977499932050705,
              0.24332399666309357,
              0.9082610011100769,
              0.3022550046443939,
              0.28925400972366333,
              0.963683009147644,
              0.10977499932050705,
              0.24332399666309357,
              0.9657580256462097,
              0.09796439856290817,
              0.24021099507808685,
              0.9082610011100769,
              0.3022550046443939,
              0.28925400972366333,
              -0.4034850001335144,
              -0.9149749875068665,
              0.00018311099847778678,
              -0.4034850001335144,
              -0.9149749875068665,
              0.00018311099847778678,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              0.00018311099847778678,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              -0.00018311099847778678,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              -0.00018311099847778678,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              0,
              -0.4034850001335144,
              -0.9149749875068665,
              -0.00018311099847778678,
              0.16251100599765778,
              -0.8919950127601624,
              0.4217959940433502,
              0.16238899528980255,
              -0.8919640183448792,
              0.42188799381256104,
              0.16245000064373016,
              -0.8919950127601624,
              0.4217660129070282,
              0.16245000064373016,
              -0.8919950127601624,
              0.4217660129070282,
              0.16248099505901337,
              -0.8920260071754456,
              0.4217349886894226,
              0.16251100599765778,
              -0.8919950127601624,
              0.4217959940433502,
              0.16248099505901337,
              -0.8920260071754456,
              0.4217349886894226,
              0.16254200041294098,
              -0.8919950127601624,
              0.4217660129070282,
              0.16251100599765778,
              -0.8919950127601624,
              0.4217959940433502,
              0.16232800483703613,
              -0.8920869827270508,
              0.4216740131378174,
              0.16248099505901337,
              -0.8920260071754456,
              0.4217349886894226,
              0.16245000064373016,
              -0.8919950127601624,
              0.4217660129070282,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              0.8640710115432739,
              0.05868709832429886,
              0.4999240040779114,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              0.8640710115432739,
              0.05868709832429886,
              0.4999240040779114,
              0.8640710115432739,
              0.05868709832429886,
              0.4999240040779114,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.0005188150098547339,
              0.008880889974534512,
              0.9999390244483948,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.0005188150098547339,
              0.008880889974534512,
              0.9999390244483948,
              -0.0005188150098547339,
              0.008880889974534512,
              0.9999390244483948,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.8645889759063721,
              -0.049806199967861176,
              0.499985009431839,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.8645889759063721,
              -0.049806199967861176,
              0.499985009431839,
              -0.8645889759063721,
              -0.049806199967861176,
              0.499985009431839,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              -0.8640090227127075,
              -0.05868709832429886,
              -0.499985009431839,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              -0.8640090227127075,
              -0.05868709832429886,
              -0.499985009431839,
              -0.8640090227127075,
              -0.05868709832429886,
              -0.499985009431839,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.0005188150098547339,
              -0.008880889974534512,
              -0.9999390244483948,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.0005188150098547339,
              -0.008880889974534512,
              -0.9999390244483948,
              0.0005188150098547339,
              -0.008880889974534512,
              -0.9999390244483948,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.8646199703216553,
              0.049806199967861176,
              -0.4999240040779114,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.8646199703216553,
              0.049806199967861176,
              -0.4999240040779114,
              0.8646199703216553,
              0.049806199967861176,
              -0.4999240040779114,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              0.864162027835846,
              0.056459199637174606,
              0.499985009431839,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.0004882959765382111,
              0.008545179851353168,
              0.9999390244483948,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.8646810054779053,
              -0.047883499413728714,
              0.499985009431839,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              -0.864162027835846,
              -0.0564287006855011,
              -0.5000150203704834,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.0004882959765382111,
              -0.00851466041058302,
              -0.9999390244483948,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.8647419810295105,
              0.047883499413728714,
              -0.4998930096626282,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              0.8645280003547668,
              0.05017239972949028,
              0.5000460147857666,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.00039674100116826594,
              0.007599109783768654,
              0.9999690055847168,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8650169968605042,
              -0.04257329925894737,
              0.4998930096626282,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              -0.8645280003547668,
              -0.05017239972949028,
              -0.5000460147857666,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.0004577779909595847,
              -0.007568589877337217,
              -0.9999690055847168,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.864986002445221,
              0.04257329925894737,
              -0.4999240040779114,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              0.8651390075683594,
              0.037629298865795135,
              0.5000759959220886,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.00027466699248179793,
              0.005676439963281155,
              0.9999690055847168,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8655350208282471,
              -0.03195289894938469,
              0.49977099895477295,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.8658409714698792,
              -0.0047914101742208,
              -0.500229001045227,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              -0.8658409714698792,
              -0.0047608898021280766,
              -0.500229001045227,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              -0.8651390075683594,
              -0.037629298865795135,
              -0.5000759959220886,
              -0.8658409714698792,
              -0.0047608898021280766,
              -0.500229001045227,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.00039674100116826594,
              -0.005676439963281155,
              -0.9999690055847168,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.865444004535675,
              0.03192239999771118,
              -0.4999240040779114,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.8657490015029907,
              -0.019501300528645515,
              0.5000460147857666,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              0.8657490015029907,
              -0.019501300528645515,
              0.5000460147857666,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              0.865871012210846,
              0.0047914101742208,
              0.5001980066299438,
              0.8657490015029907,
              -0.019501300528645515,
              0.5000460147857666,
              -0.00027466699248179793,
              -0.0029297799337655306,
              0.9999690055847168,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.00027466699248179793,
              -0.0029297799337655306,
              0.9999690055847168,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.00024414798826910555,
              0.0007019260083325207,
              0.9999690055847168,
              -0.00027466699248179793,
              -0.0029297799337655306,
              0.9999690055847168,
              -0.845881998538971,
              0.15884900093078613,
              0.5091400146484375,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.845881998538971,
              0.15881800651550293,
              0.5091099739074707,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.8660849928855896,
              -0.0039979200810194016,
              0.49983200430870056,
              -0.845881998538971,
              0.15881800651550293,
              0.5091099739074707,
              -0.8438370227813721,
              0.18784099817276,
              -0.5026090145111084,
              -0.8658409714698792,
              -0.0047914101742208,
              -0.500229001045227,
              -0.8438370227813721,
              0.1878719925880432,
              -0.5026090145111084,
              -0.8658409714698792,
              -0.0047914101742208,
              -0.500229001045227,
              -0.8658409714698792,
              -0.0047608898021280766,
              -0.500229001045227,
              -0.8438370227813721,
              0.1878719925880432,
              -0.5026090145111084,
              0.00027466699248179793,
              0.00299080996774137,
              -0.9999690055847168,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.00027466699248179793,
              0.00299080996774137,
              -0.9999690055847168,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.0003051850071642548,
              -0.0006714069750159979,
              -0.9999690055847168,
              0.00027466699248179793,
              0.00299080996774137,
              -0.9999690055847168,
              0.8660240173339844,
              -0.016602100804448128,
              -0.4996800124645233,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.8660240173339844,
              -0.016602100804448128,
              -0.4996800124645233,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.8660539984703064,
              0.004028439987450838,
              -0.49986299872398376,
              0.8660240173339844,
              -0.016602100804448128,
              -0.4996800124645233,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.7976930141448975,
              0.35078001022338867,
              -0.4904929995536804,
              -0.22531799972057343,
              -0.06576739996671677,
              0.9720450043678284,
              -0.006530960090458393,
              -0.05111850053071976,
              0.9986569881439209,
              -0.22534899413585663,
              -0.06576739996671677,
              0.9720450043678284,
              -0.006530960090458393,
              -0.05111850053071976,
              0.9986569881439209,
              -0.006530960090458393,
              -0.05111850053071976,
              0.9986569881439209,
              -0.22534899413585663,
              -0.06576739996671677,
              0.9720450043678284,
              -0.8082519769668579,
              0.29770800471305847,
              0.50798100233078,
              -0.845881998538971,
              0.15884900093078613,
              0.5091400146484375,
              -0.8082519769668579,
              0.29770800471305847,
              0.50798100233078,
              -0.845881998538971,
              0.15884900093078613,
              0.5091400146484375,
              -0.845881998538971,
              0.15881800651550293,
              0.5091099739074707,
              -0.8082519769668579,
              0.29770800471305847,
              0.50798100233078,
              -0.7976620197296143,
              0.35071900486946106,
              -0.49061599373817444,
              -0.8438370227813721,
              0.18784099817276,
              -0.5026090145111084,
              -0.7976620197296143,
              0.35071900486946106,
              -0.49061599373817444,
              -0.8438370227813721,
              0.18784099817276,
              -0.5026090145111084,
              -0.8438370227813721,
              0.1878719925880432,
              -0.5026090145111084,
              -0.7976620197296143,
              0.35071900486946106,
              -0.49061599373817444,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              0.01065099984407425,
              0.05301060155034065,
              -0.9985349774360657,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8082219958305359,
              0.2978610098361969,
              0.5079500079154968,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.8556470274925232,
              -0.4494459927082062,
              0.2565999925136566,
              -0.2608720064163208,
              -0.06790369749069214,
              0.9629510045051575,
              -0.22531799972057343,
              -0.06576739996671677,
              0.9720450043678284,
              -0.2608720064163208,
              -0.06790369749069214,
              0.9629510045051575,
              -0.22531799972057343,
              -0.06576739996671677,
              0.9720450043678284,
              -0.22534899413585663,
              -0.06576739996671677,
              0.9720450043678284,
              -0.2608720064163208,
              -0.06790369749069214,
              0.9629510045051575,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.5938599705696106,
              -0.38148099184036255,
              -0.7083349823951721,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              -0.8554030060768127,
              -0.4496900141239166,
              0.2568739950656891,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              0.25934600830078125,
              0.06756799668073654,
              -0.9634079933166504,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              -0.5945010185241699,
              -0.3813289999961853,
              -0.707876980304718,
              0.8432260155677795,
              -0.13300000131130219,
              0.5207679867744446,
              0.8722500205039978,
              0.06756799668073654,
              0.4843589961528778,
              0.8432260155677795,
              -0.1330299973487854,
              0.5207679867744446,
              0.8722500205039978,
              0.06756799668073654,
              0.4843589961528778,
              0.8722500205039978,
              0.06756799668073654,
              0.4843589961528778,
              0.8432260155677795,
              -0.1330299973487854,
              0.5207679867744446,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              0.017181899398565292,
              0.011413900181651115,
              0.9997860193252563,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              0.017181899398565292,
              0.011413900181651115,
              0.9997860193252563,
              0.017181899398565292,
              0.011413900181651115,
              0.9997860193252563,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              -0.8487499952316284,
              0.1656849980354309,
              0.5021209716796875,
              -0.854578971862793,
              -0.055970899760723114,
              0.5162510275840759,
              -0.8487499952316284,
              0.16574600338935852,
              0.5021209716796875,
              -0.854578971862793,
              -0.055970899760723114,
              0.5162510275840759,
              -0.854578971862793,
              -0.055970899760723114,
              0.5162510275840759,
              -0.8487499952316284,
              0.16574600338935852,
              0.5021209716796875,
              -0.8431349992752075,
              0.1330299973487854,
              -0.520950973033905,
              -0.8721269965171814,
              -0.06738489866256714,
              -0.48460298776626587,
              -0.8431040048599243,
              0.13318300247192383,
              -0.5209810137748718,
              -0.8721269965171814,
              -0.06738489866256714,
              -0.48460298776626587,
              -0.8721269965171814,
              -0.06738489866256714,
              -0.48460298776626587,
              -0.8431040048599243,
              0.13318300247192383,
              -0.5209810137748718,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              -0.017181899398565292,
              -0.011413900181651115,
              -0.9997860193252563,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              -0.017181899398565292,
              -0.011413900181651115,
              -0.9997860193252563,
              -0.017181899398565292,
              -0.011413900181651115,
              -0.9997860193252563,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              0.8488720059394836,
              -0.1656540036201477,
              -0.501937985420227,
              0.8547319769859314,
              0.0560929998755455,
              -0.5159760117530823,
              0.8488720059394836,
              -0.1656849980354309,
              -0.501937985420227,
              0.8547319769859314,
              0.0560929998755455,
              -0.5159760117530823,
              0.8547319769859314,
              0.0560929998755455,
              -0.5159760117530823,
              0.8488720059394836,
              -0.1656849980354309,
              -0.501937985420227,
              0.9368270039558411,
              -0.19116799533367157,
              0.29288598895072937,
              0.8432260155677795,
              -0.13300000131130219,
              0.5207679867744446,
              0.9360330104827881,
              -0.1922059953212738,
              0.2946870028972626,
              0.8432260155677795,
              -0.13300000131130219,
              0.5207679867744446,
              0.8432260155677795,
              -0.1330299973487854,
              0.5207679867744446,
              0.9360330104827881,
              -0.1922059953212738,
              0.2946870028972626,
              -0.022583700716495514,
              0.05127109959721565,
              0.9984130263328552,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              -0.022583700716495514,
              0.05127109959721565,
              0.9984130263328552,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              -0.0026856299955397844,
              0.03134249895811081,
              0.9994810223579407,
              -0.022583700716495514,
              0.05127109959721565,
              0.9984130263328552,
              -0.8009889721870422,
              0.379254013299942,
              0.46320998668670654,
              -0.8487499952316284,
              0.1656849980354309,
              0.5021209716796875,
              -0.8009889721870422,
              0.379254013299942,
              0.46320998668670654,
              -0.8487499952316284,
              0.1656849980354309,
              0.5021209716796875,
              -0.8487499952316284,
              0.16574600338935852,
              0.5021209716796875,
              -0.8009889721870422,
              0.379254013299942,
              0.46320998668670654,
              -0.9369789958000183,
              0.1908929944038391,
              -0.2925199866294861,
              -0.8431349992752075,
              0.1330299973487854,
              -0.520950973033905,
              -0.9354230165481567,
              0.19293799996376038,
              -0.2961210012435913,
              -0.8431349992752075,
              0.1330299973487854,
              -0.520950973033905,
              -0.8431040048599243,
              0.13318300247192383,
              -0.5209810137748718,
              -0.9354230165481567,
              0.19293799996376038,
              -0.2961210012435913,
              0.022583700716495514,
              -0.05127109959721565,
              -0.9984130263328552,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              0.022583700716495514,
              -0.05127109959721565,
              -0.9984130263328552,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              0.0026856299955397844,
              -0.03134249895811081,
              -0.9994810223579407,
              0.022583700716495514,
              -0.05127109959721565,
              -0.9984130263328552,
              0.8010500073432922,
              -0.37928399443626404,
              -0.4630570113658905,
              0.8488720059394836,
              -0.1656540036201477,
              -0.501937985420227,
              0.8010500073432922,
              -0.37928399443626404,
              -0.4630570113658905,
              0.8488720059394836,
              -0.1656540036201477,
              -0.501937985420227,
              0.8488720059394836,
              -0.1656849980354309,
              -0.501937985420227,
              0.8010500073432922,
              -0.37928399443626404,
              -0.4630570113658905,
              0.9990540146827698,
              -0.036316998302936554,
              0.02334669977426529,
              0.9368270039558411,
              -0.19116799533367157,
              0.29288598895072937,
              0.9990540146827698,
              -0.036316998302936554,
              0.02334669977426529,
              0.9368270039558411,
              -0.19116799533367157,
              0.29288598895072937,
              0.9360330104827881,
              -0.1922059953212738,
              0.2946870028972626,
              0.9990540146827698,
              -0.036316998302936554,
              0.02334669977426529,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              0.5366989970207214,
              0.7418739795684814,
              -0.40189799666404724,
              -0.5412759780883789,
              0.6039919853210449,
              -0.5849480032920837,
              -0.46247801184654236,
              0.778007984161377,
              -0.425152987241745,
              -0.5410630106925964,
              0.6046630144119263,
              -0.5844290256500244,
              -0.46247801184654236,
              0.778007984161377,
              -0.425152987241745,
              -0.46247801184654236,
              0.778007984161377,
              -0.425152987241745,
              -0.5410630106925964,
              0.6046630144119263,
              -0.5844290256500244,
              -0.9990540146827698,
              0.036286499351263046,
              -0.023407699540257454,
              -0.9369789958000183,
              0.1908929944038391,
              -0.2925199866294861,
              -0.9990540146827698,
              0.036286499351263046,
              -0.023407699540257454,
              -0.9369789958000183,
              0.1908929944038391,
              -0.2925199866294861,
              -0.9354230165481567,
              0.19293799996376038,
              -0.2961210012435913,
              -0.9990540146827698,
              0.036286499351263046,
              -0.023407699540257454,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              -0.536607027053833,
              -0.7419350147247314,
              0.40186798572540283,
              0.5413069725036621,
              -0.603564977645874,
              0.5853760242462158,
              0.46241599321365356,
              -0.778007984161377,
              0.4251840114593506,
              0.5403299927711487,
              -0.6065859794616699,
              0.5831170082092285,
              0.46241599321365356,
              -0.778007984161377,
              0.4251840114593506,
              0.46241599321365356,
              -0.778007984161377,
              0.4251840114593506,
              0.5403299927711487,
              -0.6065859794616699,
              0.5831170082092285,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.8943139910697937,
              -0.37577399611473083,
              -0.24283599853515625,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              0.30805400013923645,
              0.017456600442528725,
              -0.9512010216712952,
              -0.5864130258560181,
              0.39326199889183044,
              -0.7080900073051453,
              -0.5412759780883789,
              0.6039919853210449,
              -0.5849480032920837,
              -0.5864130258560181,
              0.39326199889183044,
              -0.7080900073051453,
              -0.5412759780883789,
              0.6039919853210449,
              -0.5849480032920837,
              -0.5410630106925964,
              0.6046630144119263,
              -0.5844290256500244,
              -0.5864130258560181,
              0.39326199889183044,
              -0.7080900073051453,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.8943139910697937,
              0.3757439851760864,
              0.24289700388908386,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              -0.30805400013923645,
              -0.017456600442528725,
              0.9512010216712952,
              0.586260974407196,
              -0.39323100447654724,
              0.7082740068435669,
              0.5413069725036621,
              -0.603564977645874,
              0.5853760242462158,
              0.586260974407196,
              -0.39323100447654724,
              0.7082740068435669,
              0.5413069725036621,
              -0.603564977645874,
              0.5853760242462158,
              0.5403299927711487,
              -0.6065859794616699,
              0.5831170082092285,
              0.586260974407196,
              -0.39323100447654724,
              0.7082740068435669,
              0.06268499791622162,
              -0.9979860186576843,
              0.008819850161671638,
              0.06268499791622162,
              -0.9979860186576843,
              0.008819850161671638,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.06268499791622162,
              -0.9979860186576843,
              0.008819850161671638,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.0625934973359108,
              -0.9979860186576843,
              0.009002960287034512,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.0625934973359108,
              -0.9979860186576843,
              0.009002960287034512,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.06265450268983841,
              -0.9979860186576843,
              0.008911400102078915,
              0.0625934973359108,
              -0.9979860186576843,
              0.009002960287034512,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.4177680015563965,
              -0.895779013633728,
              -0.15170800685882568,
              -0.41767600178718567,
              -0.8958100080490112,
              -0.15170800685882568,
              -0.41767600178718567,
              -0.8958100080490112,
              -0.15170800685882568,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.41761499643325806,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.4177370071411133,
              -0.895779013633728,
              -0.15179899334907532,
              -0.41764599084854126,
              -0.8958399891853333,
              -0.15170800685882568,
              -0.41767600178718567,
              -0.8958100080490112,
              -0.15170800685882568,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              -0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.8870199918746948,
              -0.4617150127887726,
              0,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.627918004989624,
              -0.4609520137310028,
              -0.627032995223999,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              -0.3457440137863159,
              -0.4539929926395416,
              -0.8211619853973389,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              0.0007019260083325207,
              -0.4498730003833771,
              -0.8930630087852478,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0.34540799260139465,
              -0.45545798540115356,
              -0.8204900026321411,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.6272159814834595,
              -0.4617150127887726,
              -0.6272159814834595,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.8194829821586609,
              -0.4617150127887726,
              -0.33942699432373047,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.8870199918746948,
              -0.4617150127887726,
              0,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.8194829821586609,
              -0.4617150127887726,
              0.33942699432373047,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.6272159814834595,
              -0.4617150127887726,
              0.6272159814834595,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0.33942699432373047,
              -0.4617150127887726,
              0.8194829821586609,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              0,
              -0.4617150127887726,
              0.8870199918746948,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              0,
              0,
              1,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0,
              0,
              1,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              -0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.6636559963226318,
              -0.34501200914382935,
              0.6636559963226318,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -1,
              0,
              0,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -0.9385660290718079,
              -0.34501200914382935,
              0,
              -1,
              0,
              0,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -1,
              0,
              0,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              -0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              0,
              0,
              -1,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              0,
              -0.34501200914382935,
              -0.9385660290718079,
              0,
              0,
              -1,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0,
              0,
              -1,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.359171986579895,
              -0.34501200914382935,
              -0.8671219944953918,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.6636559963226318,
              -0.34501200914382935,
              -0.6636559963226318,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.8671219944953918,
              -0.34501200914382935,
              -0.359171986579895,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              1,
              0,
              0,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              0.9385660290718079,
              -0.34501200914382935,
              0,
              1,
              0,
              0,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              1,
              0,
              0,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              0.8671219944953918,
              -0.34501200914382935,
              0.359171986579895,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.707082986831665,
              0,
              0.707082986831665,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.6636559963226318,
              -0.34501200914382935,
              0.6636859774589539,
              0.707082986831665,
              0,
              0.707082986831665,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0.707082986831665,
              0,
              0.707082986831665,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0.359171986579895,
              -0.34501200914382935,
              0.8671219944953918,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0,
              0,
              1,
              0,
              -0.34501200914382935,
              0.9385660290718079,
              0,
              0,
              1,
              0,
              0.3743399977684021,
              0.9272739887237549,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              0,
              0.3743399977684021,
              0.9272739887237549,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              -0.3826720118522644,
              0,
              0.9238560199737549,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.707082986831665,
              0,
              0.707082986831665,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -0.9238560199737549,
              0,
              0.3826720118522644,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -1,
              0,
              0,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.9238560199737549,
              0,
              -0.3826720118522644,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.707082986831665,
              0,
              -0.707082986831665,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              -0.3826720118522644,
              0,
              -0.9238560199737549,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0,
              0,
              -1,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0.3826720118522644,
              0,
              -0.9238560199737549,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              0.9238560199737549,
              0,
              -0.3826720118522644,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              1,
              0,
              0,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              0.9272739887237549,
              0.3743399977684021,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9272739887237549,
              0.3743399977684021,
              0,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.9272739887237549,
              0.3743399977684021,
              0,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.9238560199737549,
              0,
              0.3826720118522644,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.707082986831665,
              0,
              0.707082986831665,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.707082986831665,
              0,
              0.707082986831665,
              0.707082986831665,
              0,
              0.707082986831665,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0.3826720118522644,
              0,
              0.9238560199737549,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0,
              0,
              1,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0,
              0.3743399977684021,
              0.9272739887237549,
              0,
              0,
              1,
              0,
              0.3743399977684021,
              0.9272739887237549,
              0,
              0.6951509714126587,
              0.7188330292701721,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0,
              0.6951509714126587,
              0.7188330292701721,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              -0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -0.9272739887237549,
              0.3743399977684021,
              0,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              -0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              0,
              0.3743399977684021,
              -0.9272739887237549,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0.3548389971256256,
              0.3743399977684021,
              -0.8566849827766418,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.6556599736213684,
              0.3743399977684021,
              -0.6556599736213684,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              0.8566849827766418,
              0.3743399977684021,
              -0.3548389971256256,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.9272739887237549,
              0.3743399977684021,
              0,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.9272739887237549,
              0.3743399977684021,
              0,
              0.9272739887237549,
              0.3743399977684021,
              0,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.8566849827766418,
              0.3743399977684021,
              0.3548389971256256,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.6556599736213684,
              0.3743399977684021,
              0.6556599736213684,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0.3548389971256256,
              0.3743399977684021,
              0.8566849827766418,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0,
              0.3743399977684021,
              0.9272739887237549,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0,
              0.6951509714126587,
              0.7188330292701721,
              0,
              0.3743399977684021,
              0.9272739887237549,
              0,
              0.6951509714126587,
              0.7188330292701721,
              0,
              0.9151589870452881,
              0.4030880033969879,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              0,
              0.9151589870452881,
              0.4030880033969879,
              -0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              -0.27509400248527527,
              0.6951509714126587,
              0.6641129851341248,
              -0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              -0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              -0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              -0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              -0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              -0.4030880033969879,
              0.9151589870452881,
              0,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.7188330292701721,
              0.6951509714126587,
              0,
              -0.4030880033969879,
              0.9151589870452881,
              0,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.4030880033969879,
              0.9151589870452881,
              0,
              -0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.6641129851341248,
              0.6951509714126587,
              -0.27509400248527527,
              -0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              -0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              -0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              -0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              -0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              -0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              -0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              -0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0,
              0.9151589870452881,
              -0.4030880033969879,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              0,
              0.6951509714126587,
              -0.7188330292701721,
              0,
              0.9151589870452881,
              -0.4030880033969879,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0,
              0.9151589870452881,
              -0.4030880033969879,
              0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0.27509400248527527,
              0.6951509714126587,
              -0.6641129851341248,
              0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.5082859992980957,
              0.6951509714126587,
              -0.5082859992980957,
              0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.6641129851341248,
              0.6951509714126587,
              -0.27506300806999207,
              0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0.4030880033969879,
              0.9151589870452881,
              0,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.7188330292701721,
              0.6951509714126587,
              0,
              0.4030880033969879,
              0.9151589870452881,
              0,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.4030880033969879,
              0.9151589870452881,
              0,
              0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.6641129851341248,
              0.6951509714126587,
              0.27509400248527527,
              0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.5082859992980957,
              0.6951509714126587,
              0.5082859992980957,
              0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0.27506300806999207,
              0.6951509714126587,
              0.6641129851341248,
              0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0,
              0.6951509714126587,
              0.7188330292701721,
              0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0,
              0.9151589870452881,
              0.4030880033969879,
              0,
              0.6951509714126587,
              0.7188330292701721,
              0,
              1,
              0,
              -0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0,
              0.9151589870452881,
              0.4030880033969879,
              0,
              1,
              0,
              -0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              -0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0,
              1,
              0,
              -0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              -0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0,
              1,
              0,
              -0.4030880033969879,
              0.9151589870452881,
              0,
              -0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0,
              1,
              0,
              -0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              -0.4030880033969879,
              0.9151589870452881,
              0,
              0,
              1,
              0,
              -0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              -0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0,
              1,
              0,
              -0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              -0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0,
              1,
              0,
              0,
              0.9151589870452881,
              -0.4030880033969879,
              -0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0,
              1,
              0,
              0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0,
              0.9151589870452881,
              -0.4030880033969879,
              0,
              1,
              0,
              0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0.15424099564552307,
              0.9151589870452881,
              -0.37238699197769165,
              0,
              1,
              0,
              0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0.28501200675964355,
              0.9151589870452881,
              -0.28501200675964355,
              0,
              1,
              0,
              0.4030880033969879,
              0.9151589870452881,
              0,
              0.37238699197769165,
              0.9151589870452881,
              -0.15424099564552307,
              0,
              1,
              0,
              0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0.4030880033969879,
              0.9151589870452881,
              0,
              0,
              1,
              0,
              0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0.37238699197769165,
              0.9151589870452881,
              0.15424099564552307,
              0,
              1,
              0,
              0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0.28501200675964355,
              0.9151589870452881,
              0.28501200675964355,
              0,
              1,
              0,
              0,
              0.9151589870452881,
              0.4030880033969879,
              0.15424099564552307,
              0.9151589870452881,
              0.37238699197769165,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              -0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.9202250242233276,
              -0.3913080096244812,
              0,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.6519359946250916,
              -0.3910030126571655,
              -0.6496779918670654,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              -0.36405500769615173,
              -0.38850098848342896,
              -0.8464609980583191,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              0.0016479999758303165,
              -0.3895379900932312,
              -0.9209880232810974,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0.3644520044326782,
              -0.3886840045452118,
              -0.8462169766426086,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.6506850123405457,
              -0.3913080096244812,
              -0.6506850123405457,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.850184977054596,
              -0.3913080096244812,
              -0.3521530032157898,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.9202250242233276,
              -0.3913080096244812,
              0,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.850184977054596,
              -0.3913080096244812,
              0.3521530032157898,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.6506850123405457,
              -0.3913080096244812,
              0.6506850123405457,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0.3521530032157898,
              -0.3913080096244812,
              0.850184977054596,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0,
              -0.3913080096244812,
              0.9202250242233276,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              -0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.9862059950828552,
              -0.16534900665283203,
              0,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.6979579925537109,
              -0.1660510003566742,
              -0.6965849995613098,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              -0.38413599133491516,
              -0.1726129949092865,
              -0.9069799780845642,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              0.0010986699489876628,
              -0.17883799970149994,
              -0.9838560223579407,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0.384227991104126,
              -0.1709039956331253,
              -0.9072539806365967,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.6973479986190796,
              -0.16534900665283203,
              -0.6973479986190796,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.9111300110816956,
              -0.16534900665283203,
              -0.3773919939994812,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.9862059950828552,
              -0.16534900665283203,
              0,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.9111300110816956,
              -0.16534900665283203,
              0.3773919939994812,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.6973479986190796,
              -0.16534900665283203,
              0.6973479986190796,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0.3773919939994812,
              -0.16534900665283203,
              0.9111300110816956,
              0,
              -0.16534900665283203,
              0.9862059950828552,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              -0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.9665210247039795,
              -0.2564780116081238,
              0,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              -0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              0,
              -0.2564780116081238,
              -0.9665210247039795,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0.36985400319099426,
              -0.2564780116081238,
              -0.8929719924926758,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.6834319829940796,
              -0.2564780116081238,
              -0.6834319829940796,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.8929719924926758,
              -0.2564780116081238,
              -0.36985400319099426,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.9665210247039795,
              -0.2564780116081238,
              0,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.8929719924926758,
              -0.2564780116081238,
              0.36985400319099426,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.6834319829940796,
              -0.2564780116081238,
              0.6834319829940796,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0.36985400319099426,
              -0.2564780116081238,
              0.8929719924926758,
              0,
              -0.2564780116081238,
              0.9665210247039795,
              -0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              0,
              -0.7372360229492188,
              0.6756190061569214,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0,
              -0.7372360229492188,
              0.6756190061569214,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              -0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              -0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              -0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              -0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              -0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.6756190061569214,
              -0.7372360229492188,
              0,
              -0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              -0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              -0.6756190061569214,
              -0.7372360229492188,
              0,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.6756190061569214,
              -0.7372360229492188,
              0,
              -0.7673879861831665,
              -0.6411629915237427,
              0,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              -0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              -0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              -0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              -0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              -0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0,
              -0.7372360229492188,
              -0.6756190061569214,
              -0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              -0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              -0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              0,
              -0.7372360229492188,
              -0.6756190061569214,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0,
              -0.7372360229492188,
              -0.6756190061569214,
              0,
              -0.6411629915237427,
              -0.7673879861831665,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.25855299830436707,
              -0.7372360229492188,
              -0.6241949796676636,
              0.29364898800849915,
              -0.6411629915237427,
              -0.7089750170707703,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.4777370095252991,
              -0.7372360229492188,
              -0.4777370095252991,
              0.542618989944458,
              -0.6411629915237427,
              -0.542618989944458,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.6756190061569214,
              -0.7372360229492188,
              0,
              0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.6241949796676636,
              -0.7372360229492188,
              -0.25855299830436707,
              0.7089750170707703,
              -0.6411629915237427,
              -0.29364898800849915,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              0.6756190061569214,
              -0.7372360229492188,
              0,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.6756190061569214,
              -0.7372360229492188,
              0,
              0.7673879861831665,
              -0.6411629915237427,
              0,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.6241649985313416,
              -0.7372360229492188,
              0.25855299830436707,
              0.7089750170707703,
              -0.6411629915237427,
              0.29364898800849915,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0.4777370095252991,
              -0.7372360229492188,
              0.4777370095252991,
              0.542618989944458,
              -0.6411629915237427,
              0.542618989944458,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0,
              -0.7372360229492188,
              0.6756190061569214,
              0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              0.25855299830436707,
              -0.7372360229492188,
              0.6241949796676636,
              0.29364898800849915,
              -0.6411629915237427,
              0.7089750170707703,
              0,
              -0.6411629915237427,
              0.7673879861831665,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              0.44767600297927856,
              0.154789999127388,
              0.8806729912757874,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.44767600297927856,
              0.154789999127388,
              0.8806729912757874,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.44767600297927856,
              0.154789999127388,
              0.8806729912757874,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              -0.4474929869174957,
              -0.555649995803833,
              0.7007049918174744,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              -0.4474929869174957,
              -0.555649995803833,
              0.7007049918174744,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              -0.4291819930076599,
              -0.5537580251693726,
              0.7135229706764221,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.03918579965829849,
              -0.0005798520287498832,
              0.9992070198059082,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              -0.4291819930076599,
              -0.5537580251693726,
              0.7135229706764221,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.4492630064487457,
              -0.43974098563194275,
              0.777642011642456,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.4492630064487457,
              -0.43974098563194275,
              0.777642011642456,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              -0.03918579965829849,
              -0.0005798520287498832,
              0.9992070198059082,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              -0.03918579965829849,
              -0.0005798520287498832,
              0.9992070198059082,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              -0.03918579965829849,
              -0.0005798520287498832,
              0.9992070198059082,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              0.4291819930076599,
              -0.008728289976716042,
              0.9031649827957153,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.7996150255203247,
              -0.01120030041784048,
              0.6003599762916565,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.9868469834327698,
              -0.01934869959950447,
              0.1604049950838089,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.9483320116996765,
              -0.02865689992904663,
              -0.31592801213264465,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.6931059956550598,
              -0.038026098161935806,
              -0.7198100090026855,
              0.2796410024166107,
              -0.04739519953727722,
              -0.9589220285415649,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              -0.03918579965829849,
              -0.0005798520287498832,
              0.9992070198059082,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.49851998686790466,
              0.011169799603521824,
              0.8667870163917542,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.8440809845924377,
              0.012024300172924995,
              0.5360580086708069,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.9963679909706116,
              0.019684400409460068,
              0.08282720297574997,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.9207429885864258,
              0.028900999575853348,
              -0.3890500068664551,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.6347240209579468,
              0.038148101419210434,
              -0.7717519998550415,
              -0.2038639932870865,
              0.04739519953727722,
              -0.9778439998626709,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.44767600297927856,
              0.154789999127388,
              0.8806729912757874,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.7988830208778381,
              0.18686500191688538,
              0.5716729760169983,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.974943995475769,
              0.13925600051879883,
              0.17334499955177307,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.9441819787025452,
              0.0794702023267746,
              -0.3195900022983551,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.6873379945755005,
              0.06604210287332535,
              -0.7232890129089355,
              0.27320200204849243,
              0.05670339986681938,
              -0.9602649807929993,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.8145700097084045,
              -0.17163600027561188,
              0.5540329813957214,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.9926149845123291,
              -0.09179969877004623,
              0.07919549942016602,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.9158909916877747,
              -0.07562489807605743,
              -0.39417698979377747,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.6286810040473938,
              -0.06622520089149475,
              -0.7748039960861206,
              -0.19733299314975739,
              -0.05682549998164177,
              -0.9786679744720459,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              -0.8421580195426941,
              -0.2503739893436432,
              0.47752299904823303,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.8748440146446228,
              0.4747759997844696,
              0.0958586037158966,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.8107240200042725,
              0.49461299180984497,
              -0.31314998865127563,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              -0.5675219893455505,
              0.510208010673523,
              -0.6461989879608154,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              -0.20163600146770477,
              0.5258030295372009,
              -0.8263189792633057,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              0.20136100053787231,
              0.5413069725036621,
              -0.8163400292396545,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.549576997756958,
              0.5567799806594849,
              -0.6228219866752625,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              0.7658929824829102,
              0.5721920132637024,
              -0.29319098591804504,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.8356879949569702,
              0.5367599725723267,
              0.11606200039386749,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.8421580195426941,
              -0.2503739893436432,
              0.47752299904823303,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.8421580195426941,
              -0.2503739893436432,
              0.47752299904823303,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.9711599946022034,
              -0.2277899980545044,
              0.06988739967346191,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.8935509920120239,
              -0.23325300216674805,
              -0.383556991815567,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              -0.6117439866065979,
              -0.24601000547409058,
              -0.7517930269241333,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              -0.19245000183582306,
              -0.26581600308418274,
              -0.9446089863777161,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.265267014503479,
              -0.29364898800849915,
              -0.9183319807052612,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.6530960202217102,
              -0.3292340040206909,
              -0.68190598487854,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              0.8815879821777344,
              -0.36726000905036926,
              -0.2964569926261902,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.7677540183067322,
              -0.39289501309394836,
              0.5060880184173584,
              0.9029210209846497,
              -0.407819002866745,
              0.13541099429130554,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              0.7677540183067322,
              -0.39289501309394836,
              0.5060880184173584,
              0.4800559878349304,
              -0.3935059905052185,
              0.7839900255203247,
              0.2664879858493805,
              -0.3979310095310211,
              0.8778340220451355,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.2664879858493805,
              -0.3979310095310211,
              0.8778340220451355,
              0.24756599962711334,
              -0.319925993680954,
              0.9145179986953735,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.4800559878349304,
              -0.3935059905052185,
              0.7839900255203247,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.4800559878349304,
              -0.3935059905052185,
              0.7839900255203247,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.8108159899711609,
              0.3186439871788025,
              0.4909209907054901,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.24756599962711334,
              -0.319925993680954,
              0.9145179986953735,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.5626090168952942,
              -0.012726199813187122,
              0.8265939950942993,
              0.24756599962711334,
              -0.319925993680954,
              0.9145179986953735,
              0.44767600297927856,
              0.154789999127388,
              0.8806729912757874,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              -0.062288299202919006,
              0.22772899270057678,
              0.9717090129852295,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              -0.8421580195426941,
              -0.2503739893436432,
              0.47752299904823303,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              -0.7700740098953247,
              0.4175240099430084,
              0.4822840094566345,
              -0.47935399413108826,
              0.32721900939941406,
              0.8142949938774109,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.8421580195426941,
              -0.2503739893436432,
              0.47752299904823303,
              -0.5049899816513062,
              -0.33817601203918457,
              0.7940919995307922,
              0.2664879858493805,
              -0.3979310095310211,
              0.8778340220451355,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              0.24756599962711334,
              -0.319925993680954,
              0.9145179986953735,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              0.24756599962711334,
              -0.319925993680954,
              0.9145179986953735,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.9671930074691772,
              -0.24332399666309357,
              0.07296980172395706,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.8903779983520508,
              -0.2516860067844391,
              -0.37928399443626404,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              -0.6092100143432617,
              -0.2648699879646301,
              -0.7474290132522583,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              -0.1899780035018921,
              -0.2839440107345581,
              -0.9398170113563538,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.26719000935554504,
              -0.3094579875469208,
              -0.9125949740409851,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.6535840034484863,
              -0.34073901176452637,
              -0.6758019924163818,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              0.8798180222511292,
              -0.3761410117149353,
              -0.2904750108718872,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.7677540183067322,
              -0.39289501309394836,
              0.5060880184173584,
              0.901760995388031,
              -0.4078800082206726,
              0.14285700023174286,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              0.4930570125579834,
              0.21414799988269806,
              0.8431959748268127,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.4930570125579834,
              0.21414799988269806,
              0.8431959748268127,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.8447830080986023,
              -0.2408829927444458,
              0.4777980148792267,
              -0.515762984752655,
              -0.24884800612926483,
              0.8197579979896545,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.9803159832954407,
              0.18182900547981262,
              0.07647939771413803,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.9064000248908997,
              0.1769769936800003,
              -0.3834649920463562,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              -0.6239200234413147,
              0.17441299557685852,
              -0.7617419958114624,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              -0.1974239945411682,
              0.17456600069999695,
              -0.9646289944648743,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.27420899271965027,
              0.17728200554847717,
              -0.9451580047607422,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.6818450093269348,
              0.18192100524902344,
              -0.7084869742393494,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              0.932066023349762,
              0.18726199865341187,
              -0.3100990056991577,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              0.8098090291023254,
              0.24207299947738647,
              0.5343790054321289,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              0.8098090291023254,
              0.24207299947738647,
              0.5343790054321289,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.969726026058197,
              0.19132100045681,
              0.15170800685882568,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              0.4930570125579834,
              0.21414799988269806,
              0.8431959748268127,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.0034180700313299894,
              0.21854299306869507,
              0.975799024105072,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.853725016117096,
              0.1886650025844574,
              0.4853050112724304,
              -0.5200960040092468,
              0.20368100702762604,
              0.8294320106506348,
              -0.8707540035247803,
              0.03698839992284775,
              0.49028000235557556,
              -0.99652099609375,
              0.03564560040831566,
              0.07528919726610184,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.99652099609375,
              0.03564560040831566,
              0.07528919726610184,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.99652099609375,
              0.03564560040831566,
              0.07528919726610184,
              -0.9197670221328735,
              0.03473009914159775,
              -0.3908810019493103,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.9197670221328735,
              0.03473009914159775,
              -0.3908810019493103,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.9707630276679993,
              0.2291020005941391,
              0.07150489836931229,
              -0.9197670221328735,
              0.03473009914159775,
              -0.3908810019493103,
              -0.6328930258750916,
              0.034150201827287674,
              -0.7734609842300415,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.6328930258750916,
              0.034150201827287674,
              -0.7734609842300415,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.896602988243103,
              0.22327299416065216,
              -0.38233599066734314,
              -0.6328930258750916,
              0.034150201827287674,
              -0.7734609842300415,
              -0.20114700496196747,
              0.034241799265146255,
              -0.9789419770240784,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.20114700496196747,
              0.034241799265146255,
              -0.9789419770240784,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              -0.6174499988555908,
              0.2200690060853958,
              -0.7551500201225281,
              -0.20114700496196747,
              0.034241799265146255,
              -0.9789419770240784,
              0.27665001153945923,
              0.03491320088505745,
              -0.9603260159492493,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              0.27665001153945923,
              0.03491320088505745,
              -0.9603260159492493,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              -0.19684399664402008,
              0.2204660028219223,
              -0.9553210139274597,
              0.27665001153945923,
              0.03491320088505745,
              -0.9603260159492493,
              0.6911529898643494,
              0.03595079854130745,
              -0.721763014793396,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              0.6911529898643494,
              0.03595079854130745,
              -0.721763014793396,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.26850199699401855,
              0.2243109941482544,
              -0.9367660284042358,
              0.6911529898643494,
              0.03595079854130745,
              -0.721763014793396,
              0.9473860263824463,
              0.03717150166630745,
              -0.3179109990596771,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.9473860263824463,
              0.03717150166630745,
              -0.3179109990596771,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.6716510057449341,
              0.23071999847888947,
              -0.7040010094642639,
              0.9473860263824463,
              0.03717150166630745,
              -0.3179109990596771,
              0.9877009987831116,
              0.037629298865795135,
              0.1516769975423813,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.9877009987831116,
              0.037629298865795135,
              0.1516769975423813,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              0.9201329946517944,
              0.2382269948720932,
              -0.31070899963378906,
              0.9877009987831116,
              0.037629298865795135,
              0.1516769975423813,
              0.8342540264129639,
              0.03601180016994476,
              0.5501570105552673,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              0.8342540264129639,
              0.03601180016994476,
              0.5501570105552673,
              0.8098090291023254,
              0.24207299947738647,
              0.5343790054321289,
              0.9587699770927429,
              0.2439039945602417,
              0.14578700065612793,
              -0.002319409977644682,
              0.043153200298547745,
              0.9990540146827698,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              0.5066379904747009,
              0.039155200123786926,
              0.8612319827079773,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              0.5066379904747009,
              0.039155200123786926,
              0.8612319827079773,
              -0.002319409977644682,
              0.043153200298547745,
              0.9990540146827698,
              -0.5345010161399841,
              0.04116950184106827,
              0.8441420197486877,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              -0.5345010161399841,
              0.04116950184106827,
              0.8441420197486877,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.0026856299955397844,
              0.260017991065979,
              0.9655749797821045,
              -0.5345010161399841,
              0.04116950184106827,
              0.8441420197486877,
              -0.8707540035247803,
              0.03698839992284775,
              0.49028000235557556,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.8707540035247803,
              0.03698839992284775,
              0.49028000235557556,
              -0.8481090068817139,
              0.23587800562381744,
              0.4743489921092987,
              -0.5202800035476685,
              0.25675201416015625,
              0.8144469857215881,
              -0.46378999948501587,
              -0.8441420197486877,
              0.26889899373054504,
              -0.5488749742507935,
              -0.8346199989318848,
              0.046021901071071625,
              -0.4725790023803711,
              -0.8431959748268127,
              0.2562029957771301,
              -0.5488749742507935,
              -0.8346199989318848,
              0.046021901071071625,
              -0.5502179861068726,
              -0.8342229723930359,
              0.03549300134181976,
              -0.4725790023803711,
              -0.8431959748268127,
              0.2562029957771301,
              -0.5488749742507935,
              -0.8346199989318848,
              0.046021901071071625,
              -0.51835697889328,
              -0.8271430134773254,
              -0.2169869989156723,
              -0.5502179861068726,
              -0.8342229723930359,
              0.03549300134181976,
              -0.51835697889328,
              -0.8271430134773254,
              -0.2169869989156723,
              -0.5157930254936218,
              -0.8269910216331482,
              -0.22363999485969543,
              -0.5502179861068726,
              -0.8342229723930359,
              0.03549300134181976,
              -0.51835697889328,
              -0.8271430134773254,
              -0.2169869989156723,
              -0.3605149984359741,
              -0.8229320049285889,
              -0.4390699863433838,
              -0.5157930254936218,
              -0.8269910216331482,
              -0.22363999485969543,
              -0.3605149984359741,
              -0.8229320049285889,
              -0.4390699863433838,
              -0.3588060140609741,
              -0.8229010105133057,
              -0.4405040144920349,
              -0.5157930254936218,
              -0.8269910216331482,
              -0.22363999485969543,
              -0.3605149984359741,
              -0.8229320049285889,
              -0.4390699863433838,
              -0.11279600113630295,
              -0.823419988155365,
              -0.5560470223426819,
              -0.3588060140609741,
              -0.8229010105133057,
              -0.4405040144920349,
              -0.11279600113630295,
              -0.823419988155365,
              -0.5560470223426819,
              -0.11606200039386749,
              -0.8233889937400818,
              -0.5554370284080505,
              -0.3588060140609741,
              -0.8229010105133057,
              -0.4405040144920349,
              -0.11279600113630295,
              -0.823419988155365,
              -0.5560470223426819,
              0.158269003033638,
              -0.8284860253334045,
              -0.5371559858322144,
              -0.11606200039386749,
              -0.8233889937400818,
              -0.5554370284080505,
              0.158269003033638,
              -0.8284860253334045,
              -0.5371559858322144,
              0.15073099732398987,
              -0.8282719850540161,
              -0.5396280288696289,
              -0.11606200039386749,
              -0.8233889937400818,
              -0.5554370284080505,
              0.158269003033638,
              -0.8284860253334045,
              -0.5371559858322144,
              0.3821829855442047,
              -0.83645099401474,
              -0.39274299144744873,
              0.15073099732398987,
              -0.8282719850540161,
              -0.5396280288696289,
              0.3821829855442047,
              -0.83645099401474,
              -0.39274299144744873,
              0.37507200241088867,
              -0.8361160159111023,
              -0.40024998784065247,
              0.15073099732398987,
              -0.8282719850540161,
              -0.5396280288696289,
              0.3821829855442047,
              -0.83645099401474,
              -0.39274299144744873,
              0.5081639885902405,
              -0.845088005065918,
              -0.16592900454998016,
              0.37507200241088867,
              -0.8361160159111023,
              -0.40024998784065247,
              0.5081639885902405,
              -0.845088005065918,
              -0.16592900454998016,
              0.505416989326477,
              -0.8447520136833191,
              -0.17575600743293762,
              0.37507200241088867,
              -0.8361160159111023,
              -0.40024998784065247,
              0.5081639885902405,
              -0.845088005065918,
              -0.16592900454998016,
              0.5169529914855957,
              -0.8519240021705627,
              0.08328499644994736,
              0.505416989326477,
              -0.8447520136833191,
              -0.17575600743293762,
              0.5169529914855957,
              -0.8519240021705627,
              0.08328499644994736,
              0.5186010003089905,
              -0.8517109751701355,
              0.07483139634132385,
              0.505416989326477,
              -0.8447520136833191,
              -0.17575600743293762,
              0.5169529914855957,
              -0.8519240021705627,
              0.08328499644994736,
              0.4330579936504364,
              -0.8525040149688721,
              0.2926419973373413,
              0.5186010003089905,
              -0.8517109751701355,
              0.07483139634132385,
              0.4330579936504364,
              -0.8525040149688721,
              0.2926419973373413,
              0.4378190040588379,
              -0.8525350093841553,
              0.28537899255752563,
              0.5186010003089905,
              -0.8517109751701355,
              0.07483139634132385,
              0.2599869966506958,
              -0.8574789762496948,
              0.4439220130443573,
              0.004181039985269308,
              -0.864100992679596,
              0.503250002861023,
              0.26068899035453796,
              -0.8574789762496948,
              0.4435249865055084,
              0.004181039985269308,
              -0.864100992679596,
              0.503250002861023,
              -0.0006408889894373715,
              -0.8642539978027344,
              0.5030059814453125,
              0.26068899035453796,
              -0.8574789762496948,
              0.4435249865055084,
              0.004181039985269308,
              -0.864100992679596,
              0.503250002861023,
              -0.26285600662231445,
              -0.8630329966545105,
              0.4313490092754364,
              -0.0006408889894373715,
              -0.8642539978027344,
              0.5030059814453125,
              -0.26285600662231445,
              -0.8630329966545105,
              0.4313490092754364,
              -0.28058698773384094,
              -0.8620560169219971,
              0.42197901010513306,
              -0.0006408889894373715,
              -0.8642539978027344,
              0.5030059814453125,
              -0.26285600662231445,
              -0.8630329966545105,
              0.4313490092754364,
              -0.46378999948501587,
              -0.8441420197486877,
              0.26889899373054504,
              -0.28058698773384094,
              -0.8620560169219971,
              0.42197901010513306,
              -0.46378999948501587,
              -0.8441420197486877,
              0.26889899373054504,
              -0.4725790023803711,
              -0.8431959748268127,
              0.2562029957771301,
              -0.28058698773384094,
              -0.8620560169219971,
              0.42197901010513306,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.7436450123786926,
              -0.5282449722290039,
              0.40974199771881104,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.8554949760437012,
              -0.5143280029296875,
              0.05929749831557274,
              -0.7436450123786926,
              -0.5282449722290039,
              0.40974199771881104,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.8554949760437012,
              -0.5143280029296875,
              0.05929749831557274,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.7744070291519165,
              -0.5511639714241028,
              -0.31055599451065063,
              -0.8554949760437012,
              -0.5143280029296875,
              0.05929749831557274,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.7744070291519165,
              -0.5511639714241028,
              -0.31055599451065063,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.4938510060310364,
              -0.4665359854698181,
              -0.7337870001792908,
              -0.7744070291519165,
              -0.5511639714241028,
              -0.31055599451065063,
              -0.5191199779510498,
              0.28159400820732117,
              -0.8069400191307068,
              -0.29899001121520996,
              0.2817769944667816,
              -0.9116790294647217,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.29899001121520996,
              0.2817769944667816,
              -0.9116790294647217,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.2582780122756958,
              -0.46681100130081177,
              -0.8457589745521545,
              -0.4938510060310364,
              -0.4665359854698181,
              -0.7337870001792908,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              -0.4938510060310364,
              -0.4665359854698181,
              -0.7337870001792908,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              -0.2582780122756958,
              -0.46681100130081177,
              -0.8457589745521545,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              0.24665099382400513,
              -0.5529950261116028,
              -0.7958009839057922,
              -0.2582780122756958,
              -0.46681100130081177,
              -0.8457589745521545,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.24665099382400513,
              -0.5529950261116028,
              -0.7958009839057922,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.5882750153541565,
              -0.5169839859008789,
              -0.621783971786499,
              0.24665099382400513,
              -0.5529950261116028,
              -0.7958009839057922,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.5882750153541565,
              -0.5169839859008789,
              -0.621783971786499,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.8019649982452393,
              -0.530472993850708,
              -0.27463600039482117,
              0.5882750153541565,
              -0.5169839859008789,
              -0.621783971786499,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.8019649982452393,
              -0.530472993850708,
              -0.27463600039482117,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.83120197057724,
              -0.5420699715614319,
              0.12335599958896637,
              0.8019649982452393,
              -0.530472993850708,
              -0.27463600039482117,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.83120197057724,
              -0.5420699715614319,
              0.12335599958896637,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.6998199820518494,
              -0.5445719957351685,
              0.4622330069541931,
              0.83120197057724,
              -0.5420699715614319,
              0.12335599958896637,
              0.5047760009765625,
              -0.08163700252771378,
              0.8593710064888,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              0.4222239851951599,
              -0.551256000995636,
              0.7195960283279419,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              0.002410959918051958,
              -0.5617849826812744,
              0.8272349834442139,
              0.4222239851951599,
              -0.551256000995636,
              0.7195960283279419,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              0.002410959918051958,
              -0.5617849826812744,
              0.8272349834442139,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              -0.45197901129722595,
              -0.5582749843597412,
              0.6956999897956848,
              0.002410959918051958,
              -0.5617849826812744,
              0.8272349834442139,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.45197901129722595,
              -0.5582749843597412,
              0.6956999897956848,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.7436450123786926,
              -0.5282449722290039,
              0.40974199771881104,
              -0.45197901129722595,
              -0.5582749843597412,
              0.6956999897956848,
              -0.8083739876747131,
              0.38023000955581665,
              0.44935500621795654,
              -0.9273660182952881,
              0.3682059943675995,
              0.06622520089149475,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.9273660182952881,
              0.3682059943675995,
              0.06622520089149475,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.9273660182952881,
              0.3682059943675995,
              0.06622520089149475,
              -0.837306022644043,
              0.4313180148601532,
              -0.33591699600219727,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.837306022644043,
              0.4313180148601532,
              -0.33591699600219727,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.9938349723815918,
              -0.07669299840927124,
              0.0798059031367302,
              -0.837306022644043,
              0.4313180148601532,
              -0.33591699600219727,
              -0.5191199779510498,
              0.28159400820732117,
              -0.8069400191307068,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.5191199779510498,
              0.28159400820732117,
              -0.8069400191307068,
              -0.7228000164031982,
              -0.06201360002160072,
              -0.6882230043411255,
              -0.9276099801063538,
              -0.06369210034608841,
              -0.36802300810813904,
              -0.29899001121520996,
              0.2817769944667816,
              -0.9116790294647217,
              0.2668839991092682,
              0.43278300762176514,
              -0.8610489964485168,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              0.2668839991092682,
              0.43278300762176514,
              -0.8610489964485168,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              -0.07803580164909363,
              -0.06216619908809662,
              -0.9949949979782104,
              0.2668839991092682,
              0.43278300762176514,
              -0.8610489964485168,
              0.6396369934082031,
              0.3709520101547241,
              -0.6732079982757568,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              0.6396369934082031,
              0.3709520101547241,
              -0.6732079982757568,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.2996920049190521,
              -0.06393630057573318,
              -0.9518719911575317,
              0.6396369934082031,
              0.3709520101547241,
              -0.6732079982757568,
              0.87493497133255,
              0.38199999928474426,
              -0.29752498865127563,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.87493497133255,
              0.38199999928474426,
              -0.29752498865127563,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.6929230093955994,
              -0.07660149782896042,
              -0.7168800234794617,
              0.87493497133255,
              0.38199999928474426,
              -0.29752498865127563,
              0.9101840257644653,
              0.3909119963645935,
              0.13675299286842346,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.9101840257644653,
              0.3909119963645935,
              0.13675299286842346,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.9466230273246765,
              -0.07831049710512161,
              -0.3126010000705719,
              0.9101840257644653,
              0.3909119963645935,
              0.13675299286842346,
              0.768090009689331,
              0.39060598611831665,
              0.5073400139808655,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.768090009689331,
              0.39060598611831665,
              0.5073400139808655,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.9845269918441772,
              -0.08023320138454437,
              0.15576599538326263,
              0.0041505200788378716,
              0.4121829867362976,
              0.9110689759254456,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              0.46415600180625916,
              0.3968319892883301,
              0.7918639779090881,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              0.5047760009765625,
              -0.08163700252771378,
              0.8593710064888,
              0.46415600180625916,
              0.3968319892883301,
              0.7918639779090881,
              0.0041505200788378716,
              0.4121829867362976,
              0.9110689759254456,
              -0.4924769997596741,
              0.41019898653030396,
              0.767570972442627,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              -0.4924769997596741,
              0.41019898653030396,
              0.767570972442627,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              0.008484150283038616,
              -0.07959230244159698,
              0.9967650175094604,
              -0.4924769997596741,
              0.41019898653030396,
              0.767570972442627,
              -0.8083739876747131,
              0.38023000955581665,
              0.44935500621795654,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              -0.8083739876747131,
              0.38023000955581665,
              0.44935500621795654,
              -0.8648949861526489,
              -0.0787988007068634,
              0.4956820011138916,
              -0.5231789946556091,
              -0.07818839699029922,
              0.8485980033874512,
              -0.11535999923944473,
              0.991424024105072,
              0.06088440120220184,
              -0.1342809945344925,
              0.9908750057220459,
              0.008362069725990295,
              -0.11242999881505966,
              0.9915159940719604,
              0.0651874989271164,
              -0.1342809945344925,
              0.9908750057220459,
              0.008362069725990295,
              -0.13370199501514435,
              0.9909359812736511,
              0.01165810041129589,
              -0.11242999881505966,
              0.9915159940719604,
              0.0651874989271164,
              -0.1342809945344925,
              0.9908750057220459,
              0.008362069725990295,
              -0.12768900394439697,
              0.9902340173721313,
              -0.05578780174255371,
              -0.13370199501514435,
              0.9909359812736511,
              0.01165810041129589,
              -0.12768900394439697,
              0.9902340173721313,
              -0.05578780174255371,
              -0.12848299741744995,
              0.9902340173721313,
              -0.05355999991297722,
              -0.13370199501514435,
              0.9909359812736511,
              0.01165810041129589,
              -0.12768900394439697,
              0.9902340173721313,
              -0.05578780174255371,
              -0.08935820311307907,
              0.9898980259895325,
              -0.10989700257778168,
              -0.12848299741744995,
              0.9902340173721313,
              -0.05355999991297722,
              -0.08935820311307907,
              0.9898980259895325,
              -0.10989700257778168,
              -0.08993799984455109,
              0.9898980259895325,
              -0.10943900048732758,
              -0.12848299741744995,
              0.9902340173721313,
              -0.05355999991297722,
              -0.08935820311307907,
              0.9898980259895325,
              -0.10989700257778168,
              -0.0290840994566679,
              0.9899290204048157,
              -0.13837100565433502,
              -0.08993799984455109,
              0.9898980259895325,
              -0.10943900048732758,
              -0.0290840994566679,
              0.9899290204048157,
              -0.13837100565433502,
              -0.027985500171780586,
              0.9899290204048157,
              -0.13855400681495667,
              -0.08993799984455109,
              0.9898980259895325,
              -0.10943900048732758,
              -0.0290840994566679,
              0.9899290204048157,
              -0.13837100565433502,
              0.03686640039086342,
              0.9903259873390198,
              -0.13364100456237793,
              -0.027985500171780586,
              0.9899290204048157,
              -0.13855400681495667,
              0.03686640039086342,
              0.9903259873390198,
              -0.13364100456237793,
              0.03933839872479439,
              0.9903560280799866,
              -0.13275599479675293,
              -0.027985500171780586,
              0.9899290204048157,
              -0.13855400681495667,
              0.03686640039086342,
              0.9903259873390198,
              -0.13364100456237793,
              0.09134189784526825,
              0.9909359812736511,
              -0.09823910146951675,
              0.03933839872479439,
              0.9903560280799866,
              -0.13275599479675293,
              0.09134189784526825,
              0.9909359812736511,
              -0.09823910146951675,
              0.09356970340013504,
              0.9909660220146179,
              -0.09576710313558578,
              0.03933839872479439,
              0.9903560280799866,
              -0.13275599479675293,
              0.09134189784526825,
              0.9909359812736511,
              -0.09823910146951675,
              0.12189099937677383,
              0.9916070103645325,
              -0.04287850111722946,
              0.09356970340013504,
              0.9909660220146179,
              -0.09576710313558578,
              0.12189099937677383,
              0.9916070103645325,
              -0.04287850111722946,
              0.12265399843454361,
              0.9916380047798157,
              -0.039796099066734314,
              0.09356970340013504,
              0.9909660220146179,
              -0.09576710313558578,
              0.12189099937677383,
              0.9916070103645325,
              -0.04287850111722946,
              0.1230200007557869,
              0.992218017578125,
              0.017670199275016785,
              0.12265399843454361,
              0.9916380047798157,
              -0.039796099066734314,
              0.1230200007557869,
              0.992218017578125,
              0.017670199275016785,
              0.12244000285863876,
              0.992247998714447,
              0.020111700519919395,
              0.12265399843454361,
              0.9916380047798157,
              -0.039796099066734314,
              0.1230200007557869,
              0.992218017578125,
              0.017670199275016785,
              0.10495299845933914,
              0.9922789931297302,
              0.06595049798488617,
              0.12244000285863876,
              0.992247998714447,
              0.020111700519919395,
              0.10495299845933914,
              0.9922789931297302,
              0.06595049798488617,
              0.1034879982471466,
              0.992247998714447,
              0.06845299899578094,
              0.12244000285863876,
              0.992247998714447,
              0.020111700519919395,
              -0.0018921500304713845,
              0.9926149845123291,
              0.12106700241565704,
              -0.0008239999879151583,
              0.9926149845123291,
              0.12118899822235107,
              0.06048769876360893,
              0.9924920201301575,
              0.10605199635028839,
              -0.0008239999879151583,
              0.9926149845123291,
              0.12118899822235107,
              0.061983101069927216,
              0.9924920201301575,
              0.10513599961996078,
              0.06048769876360893,
              0.9924920201301575,
              0.10605199635028839,
              -0.0018921500304713845,
              0.9926149845123291,
              0.12106700241565704,
              -0.06851410120725632,
              0.9926760196685791,
              0.09939879924058914,
              -0.0008239999879151583,
              0.9926149845123291,
              0.12118899822235107,
              -0.06851410120725632,
              0.9926760196685791,
              0.09939879924058914,
              -0.0619220994412899,
              0.9927669763565063,
              0.10266400128602982,
              -0.0008239999879151583,
              0.9926149845123291,
              0.12118899822235107,
              -0.06851410120725632,
              0.9926760196685791,
              0.09939879924058914,
              -0.11535999923944473,
              0.991424024105072,
              0.06088440120220184,
              -0.0619220994412899,
              0.9927669763565063,
              0.10266400128602982,
              -0.11535999923944473,
              0.991424024105072,
              0.06088440120220184,
              -0.11242999881505966,
              0.9915159940719604,
              0.0651874989271164,
              -0.0619220994412899,
              0.9927669763565063,
              0.10266400128602982,
              -0.02282780036330223,
              -0.6544079780578613,
              0.7557600140571594,
              0.3190099895000458,
              -0.6515700221061707,
              0.6882230043411255,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              0.3190099895000458,
              -0.6515700221061707,
              0.6882230043411255,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              0.3190099895000458,
              -0.6515700221061707,
              0.6882230043411255,
              0.5918149948120117,
              -0.6655480265617371,
              0.4547260105609894,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              0.5918149948120117,
              -0.6655480265617371,
              0.4547260105609894,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.34589698910713196,
              -0.5671250224113464,
              0.7474290132522583,
              0.5918149948120117,
              -0.6655480265617371,
              0.4547260105609894,
              0.7202979922294617,
              -0.6820579767227173,
              0.12622499465942383,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.7202979922294617,
              -0.6820579767227173,
              0.12622499465942383,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.6433910131454468,
              -0.5860769748687744,
              0.4924769997596741,
              0.7202979922294617,
              -0.6820579767227173,
              0.12622499465942383,
              0.6812040209770203,
              -0.6987209916114807,
              -0.21839000284671783,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.6812040209770203,
              -0.6987209916114807,
              -0.21839000284671783,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.7800229787826538,
              -0.6109809875488281,
              0.13501399755477905,
              0.6812040209770203,
              -0.6987209916114807,
              -0.21839000284671783,
              0.49067699909210205,
              -0.7127289772033691,
              -0.5011749863624573,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.49067699909210205,
              -0.7127289772033691,
              -0.5011749863624573,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.7332680225372314,
              -0.6379280090332031,
              -0.2351759970188141,
              0.49067699909210205,
              -0.7127289772033691,
              -0.5011749863624573,
              0.19626499712467194,
              -0.7221289873123169,
              -0.6632890105247498,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.19626499712467194,
              -0.7221289873123169,
              -0.6632890105247498,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              0.5248270034790039,
              -0.6648759841918945,
              -0.531449019908905,
              0.19626499712467194,
              -0.7221289873123169,
              -0.6632890105247498,
              -0.13669200241565704,
              -0.7259129881858826,
              -0.6740319728851318,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              -0.13669200241565704,
              -0.7259129881858826,
              -0.6740319728851318,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              0.21240900456905365,
              -0.6906949877738953,
              -0.6912140250205994,
              -0.13669200241565704,
              -0.7259129881858826,
              -0.6740319728851318,
              -0.43766599893569946,
              -0.7241740226745605,
              -0.5328840017318726,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              -0.43766599893569946,
              -0.7241740226745605,
              -0.5328840017318726,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.1269569993019104,
              -0.7150179743766785,
              -0.6874600052833557,
              -0.43766599893569946,
              -0.7241740226745605,
              -0.5328840017318726,
              -0.6420180201530457,
              -0.7178869843482971,
              -0.2690820097923279,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.6420180201530457,
              -0.7178869843482971,
              -0.2690820097923279,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.41541799902915955,
              -0.7380290031433105,
              -0.5316929817199707,
              -0.6420180201530457,
              -0.7178869843482971,
              -0.2690820097923279,
              -0.7025060057640076,
              -0.7089750170707703,
              0.061586398631334305,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.7025060057640076,
              -0.7089750170707703,
              0.061586398631334305,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.5908989906311035,
              -0.7605819702148438,
              -0.26889899373054504,
              -0.7025060057640076,
              -0.7089750170707703,
              0.061586398631334305,
              -0.6003599762916565,
              -0.6935939788818359,
              0.39802199602127075,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.6003599762916565,
              -0.6935939788818359,
              0.39802199602127075,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.6250500082969666,
              -0.7796559929847717,
              0.03744620084762573,
              -0.6003599762916565,
              -0.6935939788818359,
              0.39802199602127075,
              -0.3612479865550995,
              -0.671163022518158,
              0.6472979784011841,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.3612479865550995,
              -0.671163022518158,
              0.6472979784011841,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.531358003616333,
              -0.7632070183753967,
              0.36759498715400696,
              -0.3612479865550995,
              -0.671163022518158,
              0.6472979784011841,
              -0.02282780036330223,
              -0.6544079780578613,
              0.7557600140571594,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              -0.02282780036330223,
              -0.6544079780578613,
              0.7557600140571594,
              -0.08389540016651154,
              -0.5524160265922546,
              0.8293099999427795,
              -0.35630398988723755,
              -0.5211340188980103,
              0.7755060195922852,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              0.34119701385498047,
              0.6662189960479736,
              0.6630759835243225,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.628741979598999,
              0.6497390270233154,
              0.42716801166534424,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.7661060094833374,
              0.6366159915924072,
              0.08801539987325668,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.7268900275230408,
              0.6272159814834595,
              -0.2796109914779663,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              0.18793299794197083,
              0.6094849705696106,
              -0.7701650261878967,
              0.5188149809837341,
              0.6189759969711304,
              -0.5896180272102356,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              -0.4474929869174957,
              -0.555649995803833,
              0.7007049918174744,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.4474929869174957,
              -0.555649995803833,
              0.7007049918174744,
              -0.4474929869174957,
              -0.555649995803833,
              0.7007049918174744,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.6630449891090393,
              -0.6448559761047363,
              0.38013899326324463,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.768975019454956,
              -0.6386299729347229,
              0.028321199119091034,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.7017430067062378,
              -0.6287729740142822,
              -0.33491000533103943,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.47105300426483154,
              -0.6191290020942688,
              -0.6282849907875061,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.4987030029296875,
              -0.15338599681854248,
              0.8530840277671814,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.665090024471283,
              0.7359229922294617,
              0.12659099698066711,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.5473799705505371,
              0.8064519762992859,
              -0.22354799509048462,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.3846549987792969,
              0.7981200218200684,
              -0.46366798877716064,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              0.13116900622844696,
              0.7835320234298706,
              -0.6073179841041565,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              -0.4291819930076599,
              -0.5537580251693726,
              0.7135229706764221,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.4291819930076599,
              -0.5537580251693726,
              0.7135229706764221,
              -0.4291819930076599,
              -0.5537580251693726,
              0.7135229706764221,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.7220069766044617,
              -0.5458539724349976,
              0.42509201169013977,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.8422189950942993,
              -0.5375840067863464,
              0.04022340103983879,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.7691580057144165,
              -0.5297709703445435,
              -0.3573409914970398,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.51835697889328,
              -0.5220800042152405,
              -0.6772670149803162,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              -0.4492630064487457,
              -0.43974098563194275,
              0.777642011642456,
              -0.5528119802474976,
              -0.03961300104856491,
              0.8323310017585754,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.5528119802474976,
              -0.03961300104856491,
              0.8323310017585754,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.5787220001220703,
              -0.6667680144309998,
              0.4695580005645752,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.6524249911308289,
              -0.757194995880127,
              0.030945800244808197,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.6379590034484863,
              -0.7073889970779419,
              -0.30420899391174316,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.4594869911670685,
              -0.6509900093078613,
              -0.6041749715805054,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              -0.1374250054359436,
              -0.5995969772338867,
              -0.7883849740028381,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              0.3705250024795532,
              0.5684379935264587,
              0.7345499992370605,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.679830014705658,
              0.559984028339386,
              0.4734950065612793,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.8284249901771545,
              0.550400972366333,
              0.1034879982471466,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.7877740263938904,
              0.5407570004463196,
              -0.294869989156723,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.565172016620636,
              0.5310530066490173,
              -0.631305992603302,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              0.009338660165667534,
              -0.4073610007762909,
              0.9132050275802612,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.4381850063800812,
              -0.3647879958152771,
              0.8214970231056213,
              0.4987030029296875,
              -0.15338599681854248,
              0.8530840277671814,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              -0.12714000046253204,
              -0.609637975692749,
              -0.7824029922485352,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              -0.14557300508022308,
              -0.5145419836044312,
              -0.844996988773346,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              0.20923499763011932,
              0.5213479995727539,
              -0.8272650241851807,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              -0.039796099066734314,
              -0.554735004901886,
              0.8310499787330627,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              0.42893800139427185,
              0.062807098031044,
              0.9011200070381165,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.3395789861679077,
              -0.5569930076599121,
              0.7578970193862915,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.7996150255203247,
              0.045594699680805206,
              0.5987430214881897,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.6419870257377625,
              -0.5652030110359192,
              0.5180519819259644,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.9868159890174866,
              0.03704949840903282,
              0.15747599303722382,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.8030340075492859,
              -0.573809027671814,
              0.16064900159835815,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.9472640156745911,
              0.030579499900341034,
              -0.31894901394844055,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              0.780174970626831,
              -0.5822629928588867,
              -0.22852300107479095,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              0.6911219954490662,
              0.018768899142742157,
              -0.7224649786949158,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              0.5805230140686035,
              -0.5911740064620972,
              -0.559893012046814,
              0.25244900584220886,
              -0.6002990007400513,
              -0.7588430047035217,
              0.2768329977989197,
              0.006195260211825371,
              -0.9608749747276306,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.025940699502825737,
              0.5680410265922546,
              0.8225650191307068,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.5027310252189636,
              0.0035096299834549427,
              0.8644059896469116,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.4003419876098633,
              0.5618759989738464,
              0.7238690257072449,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.8446609973907471,
              -0.051759399473667145,
              0.5327619910240173,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.6812649965286255,
              0.5649279952049255,
              0.46546798944473267,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.9958490133285522,
              -0.04422130063176155,
              0.0795006975531578,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.8135619759559631,
              0.5732290148735046,
              0.09741509705781937,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.9194009900093079,
              -0.031586699187755585,
              -0.39201000332832336,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.7600939869880676,
              0.5820490121841431,
              -0.28885799646377563,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.6325569748878479,
              -0.01895200088620186,
              -0.7742549777030945,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              -0.5350509881973267,
              0.5910210013389587,
              -0.603626012802124,
              -0.19238899648189545,
              0.600176990032196,
              -0.7763599753379822,
              -0.2008419930934906,
              -0.00634784996509552,
              -0.9795830249786377,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.4987030029296875,
              -0.15338599681854248,
              0.8530840277671814,
              0.7360150218009949,
              0.47160300612449646,
              0.4856109917163849,
              0.5102689862251282,
              0.01956240087747574,
              0.8597670197486877,
              0.4987030029296875,
              -0.15338599681854248,
              0.8530840277671814,
              0.8140810132026672,
              0.036591701209545135,
              0.5795770287513733,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.7561879754066467,
              -0.33195000886917114,
              0.5638599991798401,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.9631029963493347,
              0.15552200376987457,
              0.21952000260353088,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.8896759748458862,
              -0.40284401178359985,
              0.21484999358654022,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.9344459772109985,
              0.17191100120544434,
              -0.3118079900741577,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.8382819890975952,
              -0.48588499426841736,
              -0.2473219931125641,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.6773579716682434,
              0.16223600506782532,
              -0.7175210118293762,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              0.6180300116539001,
              -0.5000460147857666,
              -0.6065859794616699,
              0.26456499099731445,
              -0.5072180032730103,
              -0.8201850056648254,
              0.2674340009689331,
              0.14938800036907196,
              -0.9519029855728149,
              -0.038453299552202225,
              0.04947049915790558,
              0.9980159997940063,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.017761800438165665,
              0.6549879908561707,
              0.7553939819335938,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.4986110031604767,
              0.037202101200819016,
              0.8659930229187012,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.3639940023422241,
              0.6467480063438416,
              0.670216977596283,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.8414869904518127,
              0.063509002327919,
              0.5364850163459778,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.6013060212135315,
              0.68196702003479,
              0.41630300879478455,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.9916070103645325,
              0.09662160277366638,
              0.0856655016541481,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.6843469738960266,
              0.7229830026626587,
              0.0944242998957634,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.9166839718818665,
              0.11102599650621414,
              -0.38380101323127747,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.6334419846534729,
              0.7400739789009094,
              -0.22583700716495514,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.6328009963035583,
              0.12381400167942047,
              -0.7643060088157654,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              -0.44627198576927185,
              0.7544479966163635,
              -0.4812459945678711,
              -0.16885900497436523,
              0.7689440250396729,
              -0.6165649890899658,
              -0.20535899698734283,
              0.13660100102424622,
              -0.9690849781036377,
              -0.5528119802474976,
              -0.03961300104856491,
              0.8323310017585754,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.7760249972343445,
              -0.13272500038146973,
              0.6165350079536438,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.7291179895401001,
              0.3596610128879547,
              0.5822020173072815,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.9853820204734802,
              -0.14545099437236786,
              0.08847320079803467,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.8725550174713135,
              0.4768519997596741,
              0.10593000054359436,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.9143350124359131,
              -0.09863580018281937,
              -0.39274299144744873,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.81038898229599,
              0.49372801184654236,
              -0.31540900468826294,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.631305992603302,
              -0.053285300731658936,
              -0.7736750245094299,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              -0.5682550072669983,
              0.5023959875106812,
              -0.6516309976577759,
              -0.19977399706840515,
              0.5117340087890625,
              -0.835565984249115,
              -0.20178799331188202,
              -0.013977499678730965,
              -0.979308009147644,
              -0.045289501547813416,
              0.09503459930419922,
              0.9944149851799011,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              -0.06631670147180557,
              -0.4307689964771271,
              0.899990975856781,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              0.42939499020576477,
              0.13553300499916077,
              0.8928499817848206,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.3856320083141327,
              -0.3891719877719879,
              0.8365430235862732,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.7982119917869568,
              0.12021200358867645,
              0.5902280211448669,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.7223730087280273,
              -0.4073610007762909,
              0.5587329864501953,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.9832760095596313,
              0.09906309843063354,
              0.1526540070772171,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.8849760293960571,
              -0.4377270042896271,
              0.15857400000095367,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.9438459873199463,
              0.07593010365962982,
              -0.32145100831985474,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.8410599827766418,
              -0.4722130000591278,
              -0.2638019919395447,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.6883450150489807,
              0.04983669891953468,
              -0.7236549854278564,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              0.6084780097007751,
              -0.5105440020561218,
              -0.6075009703636169,
              0.2512589991092682,
              -0.5529040098190308,
              -0.7944269776344299,
              0.2747890055179596,
              0.020111700519919395,
              -0.9612720012664795,
              -0.013428100384771824,
              -0.23795300722122192,
              0.9711599946022034,
              0.2664879858493805,
              -0.3979310095310211,
              0.8778340220451355,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.2664879858493805,
              -0.3979310095310211,
              0.8778340220451355,
              0.4800559878349304,
              -0.3935059905052185,
              0.7839900255203247,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.7677540183067322,
              -0.39289501309394836,
              0.5060880184173584,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.4800559878349304,
              -0.3935059905052185,
              0.7839900255203247,
              0.7822200059890747,
              -0.39326199889183044,
              0.4831390082836151,
              0.4930570125579834,
              0.21414799988269806,
              0.8431959748268127,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.4860680103302002,
              -0.2711870074272156,
              0.8307440280914307,
              0.7677540183067322,
              -0.39289501309394836,
              0.5060880184173584,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.8098090291023254,
              0.24207299947738647,
              0.5343790054321289,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              0.4930570125579834,
              0.21414799988269806,
              0.8431959748268127,
              0.816523015499115,
              0.197393998503685,
              0.5424669981002808,
              0.5066379904747009,
              0.039155200123786926,
              0.8612319827079773,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              0.8342540264129639,
              0.03601180016994476,
              0.5501570105552673,
              0.4887230098247528,
              0.25113698840141296,
              0.8354750275611877,
              0.8098090291023254,
              0.24207299947738647,
              0.5343790054321289,
              0.8342540264129639,
              0.03601180016994476,
              0.5501570105552673,
              0.2599869966506958,
              -0.8574789762496948,
              0.4439220130443573,
              0.26068899035453796,
              -0.8574789762496948,
              0.4435249865055084,
              0.4330579936504364,
              -0.8525040149688721,
              0.2926419973373413,
              0.26068899035453796,
              -0.8574789762496948,
              0.4435249865055084,
              0.4378190040588379,
              -0.8525350093841553,
              0.28537899255752563,
              0.4330579936504364,
              -0.8525040149688721,
              0.2926419973373413,
              0.5047760009765625,
              -0.08163700252771378,
              0.8593710064888,
              0.4222239851951599,
              -0.551256000995636,
              0.7195960283279419,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.4222239851951599,
              -0.551256000995636,
              0.7195960283279419,
              0.6998199820518494,
              -0.5445719957351685,
              0.4622330069541931,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.46415600180625916,
              0.3968319892883301,
              0.7918639779090881,
              0.5047760009765625,
              -0.08163700252771378,
              0.8593710064888,
              0.768090009689331,
              0.39060598611831665,
              0.5073400139808655,
              0.5047760009765625,
              -0.08163700252771378,
              0.8593710064888,
              0.8265330195426941,
              -0.08249150216579437,
              0.5567489862442017,
              0.768090009689331,
              0.39060598611831665,
              0.5073400139808655,
              0.10495299845933914,
              0.9922789931297302,
              0.06595049798488617,
              0.06048769876360893,
              0.9924920201301575,
              0.10605199635028839,
              0.1034879982471466,
              0.992247998714447,
              0.06845299899578094,
              0.06048769876360893,
              0.9924920201301575,
              0.10605199635028839,
              0.061983101069927216,
              0.9924920201301575,
              0.10513599961996078,
              0.1034879982471466,
              0.992247998714447,
              0.06845299899578094,
              -0.4608910083770752,
              -0.3676869869232178,
              0.8076720237731934,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.4492630064487457,
              -0.43974098563194275,
              0.777642011642456,
              -0.5665460228919983,
              0.1738329976797104,
              0.8054440021514893,
              -0.5528119802474976,
              -0.03961300104856491,
              0.8323310017585754,
              -0.4492630064487457,
              -0.43974098563194275,
              0.777642011642456,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              0.09344770014286041,
              -0.7008270025253296,
              0.707144021987915,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              0.09344770014286041,
              -0.7008270025253296,
              0.707144021987915,
              0.09344770014286041,
              -0.7008270025253296,
              0.707144021987915,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              -0.0934171974658966,
              0.7007660269737244,
              0.7072049975395203,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              -0.0934171974658966,
              0.7007660269737244,
              0.7072049975395203,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              -0.0934171974658966,
              0.7007660269737244,
              0.7072049975395203,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              -0.09344770014286041,
              0.700918972492218,
              -0.707053005695343,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              -0.09344770014286041,
              0.700918972492218,
              -0.707053005695343,
              -0.09344770014286041,
              0.700918972492218,
              -0.707053005695343,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              0.09344770014286041,
              -0.700980007648468,
              -0.7070220112800598,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              0.09344770014286041,
              -0.700980007648468,
              -0.7070220112800598,
              0.09344770014286041,
              -0.700980007648468,
              -0.7070220112800598,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              0.008056890219449997,
              -0.704397976398468,
              0.70973801612854,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              -0.008056890219449997,
              0.7043370008468628,
              0.7097989916801453,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              -0.008056890219449997,
              0.70448899269104,
              -0.709646999835968,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              0.008056890219449997,
              -0.70455002784729,
              -0.7095860242843628,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              0.047639399766922,
              -0.705374002456665,
              0.7071750164031982,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              0.047639399766922,
              -0.705374002456665,
              0.7071750164031982,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              -0.014862500131130219,
              -0.7054960131645203,
              0.7085480093955994,
              0.047639399766922,
              -0.705374002456665,
              0.7071750164031982,
              -0.047639399766922,
              0.7054659724235535,
              0.7071139812469482,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              -0.047639399766922,
              0.7054659724235535,
              0.7071139812469482,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              0.014862500131130219,
              0.7054960131645203,
              0.7085480093955994,
              -0.047639399766922,
              0.7054659724235535,
              0.7071139812469482,
              -0.047669898718595505,
              0.7055879831314087,
              -0.706991970539093,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              -0.047669898718595505,
              0.7055879831314087,
              -0.706991970539093,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              0.014862500131130219,
              0.7056180238723755,
              -0.7083960175514221,
              -0.047669898718595505,
              0.7055879831314087,
              -0.706991970539093,
              0.047669898718595505,
              -0.7055270075798035,
              -0.707053005695343,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              0.047669898718595505,
              -0.7055270075798035,
              -0.707053005695343,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              -0.014862500131130219,
              -0.7056180238723755,
              -0.7083960175514221,
              0.047669898718595505,
              -0.7055270075798035,
              -0.707053005695343,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              -0.9912109971046448,
              -0.1321759968996048,
              0,
              0.9977110028266907,
              0.06735440343618393,
              0,
              0.9977110028266907,
              0.06735440343618393,
              0.00015259299834724516,
              0.9977110028266907,
              0.06735440343618393,
              0,
              0.9977110028266907,
              0.06735440343618393,
              -0.00015259299834724516,
              0.9977110028266907,
              0.06735440343618393,
              0,
              0.9977110028266907,
              0.06735440343618393,
              0,
              0.31665998697280884,
              -0.6813259720802307,
              0.6599019765853882,
              -0.028717899695038795,
              -0.6780909895896912,
              0.7343969941139221,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              -0.028717899695038795,
              -0.6780909895896912,
              0.7343969941139221,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              0.5814689993858337,
              -0.6900849938392639,
              0.4308300018310547,
              0.31665998697280884,
              -0.6813259720802307,
              0.6599019765853882,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.31665998697280884,
              -0.6813259720802307,
              0.6599019765853882,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.7038789987564087,
              -0.701894998550415,
              0.10876800119876862,
              0.5814689993858337,
              -0.6900849938392639,
              0.4308300018310547,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.5814689993858337,
              -0.6900849938392639,
              0.4308300018310547,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.6626790165901184,
              -0.7137669920921326,
              -0.22660000622272491,
              0.7038789987564087,
              -0.701894998550415,
              0.10876800119876862,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.7038789987564087,
              -0.701894998550415,
              0.10876800119876862,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.4757530093193054,
              -0.7231060266494751,
              -0.5007169842720032,
              0.6626790165901184,
              -0.7137669920921326,
              -0.22660000622272491,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.6626790165901184,
              -0.7137669920921326,
              -0.22660000622272491,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.18890999257564545,
              -0.7281720042228699,
              -0.6588029861450195,
              0.4757530093193054,
              -0.7231060266494751,
              -0.5007169842720032,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              0.4757530093193054,
              -0.7231060266494751,
              -0.5007169842720032,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              -0.13681399822235107,
              -0.7282019853591919,
              -0.6715599894523621,
              0.18890999257564545,
              -0.7281720042228699,
              -0.6588029861450195,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              0.18890999257564545,
              -0.7281720042228699,
              -0.6588029861450195,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              -0.4351629912853241,
              -0.7231060266494751,
              -0.5363929867744446,
              -0.13681399822235107,
              -0.7282019853591919,
              -0.6715599894523621,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.13681399822235107,
              -0.7282019853591919,
              -0.6715599894523621,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.6429640054702759,
              -0.7137669920921326,
              -0.27768799662590027,
              -0.4351629912853241,
              -0.7231060266494751,
              -0.5363929867744446,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.4351629912853241,
              -0.7231060266494751,
              -0.5363929867744446,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.7102569937705994,
              -0.701894998550415,
              0.05340740084648132,
              -0.6429640054702759,
              -0.7137669920921326,
              -0.27768799662590027,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.6429640054702759,
              -0.7137669920921326,
              -0.27768799662590027,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.6133610010147095,
              -0.6900849938392639,
              0.38407498598098755,
              -0.7102569937705994,
              -0.701894998550415,
              0.05340740084648132,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.7102569937705994,
              -0.701894998550415,
              0.05340740084648132,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.36726000905036926,
              -0.6813259720802307,
              0.6331369876861572,
              -0.6133610010147095,
              -0.6900849938392639,
              0.38407498598098755,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.6133610010147095,
              -0.6900849938392639,
              0.38407498598098755,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.028717899695038795,
              -0.6780909895896912,
              0.7343969941139221,
              -0.36726000905036926,
              -0.6813259720802307,
              0.6331369876861572,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              -0.36726000905036926,
              -0.6813259720802307,
              0.6331369876861572,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.3946959972381592,
              -0.3864859938621521,
              0.8335520029067993,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.7340009808540344,
              -0.3925600051879883,
              0.5541549921035767,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.9038059711456299,
              -0.4009220004081726,
              0.1495410054922104,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.8660539984703064,
              -0.4095889925956726,
              -0.28665998578071594,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              0.6306650042533875,
              -0.4165779948234558,
              -0.6547139883041382,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              0.2522050142288208,
              -0.4204539954662323,
              -0.87151700258255,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.18332499265670776,
              -0.4204539954662323,
              -0.8885769844055176,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.5775629878044128,
              -0.4165779948234558,
              -0.7020170092582703,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.8409990072250366,
              -0.4095889925956726,
              -0.3534649908542633,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.9127169847488403,
              -0.4009220004081726,
              0.078432597219944,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.7750790119171143,
              -0.3925600051879883,
              0.4950709939002991,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.03610340133309364,
              -0.3842889964580536,
              0.9224830269813538,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              -0.4586319923400879,
              -0.3865169882774353,
              0.800134003162384,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              -0.2644430100917816,
              -0.8569899797439575,
              0.4422439932823181,
              -0.3574939966201782,
              -0.9059720039367676,
              0.22663000226020813,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.3574939966201782,
              -0.9059720039367676,
              0.22663000226020813,
              -0.42188799381256104,
              -0.9059720039367676,
              0.03451640158891678,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.42188799381256104,
              -0.9059720039367676,
              0.03451640158891678,
              -0.3895989954471588,
              -0.9059720039367676,
              -0.16547100245952606,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.08511610329151154,
              -0.9059720039367676,
              -0.41462400555610657,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              -0.08511610329151154,
              -0.9059720039367676,
              -0.41462400555610657,
              0.11728300154209137,
              -0.9059720039367676,
              -0.40669000148773193,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.11728300154209137,
              -0.9059720039367676,
              -0.40669000148773193,
              0.29285600781440735,
              -0.9059720039367676,
              -0.3055819869041443,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              -0.2644430100917816,
              -0.8569899797439575,
              0.4422439932823181,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.4178589880466461,
              -0.9059720039367676,
              0.06741540133953094,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              0.4178589880466461,
              -0.9059720039367676,
              0.06741540133953094,
              0.3386639952659607,
              -0.9059720039367676,
              0.2538830041885376,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              -0.03451640158891678,
              -0.47025999426841736,
              0.8818320035934448,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              -0.03451640158891678,
              -0.47025999426841736,
              0.8818320035934448,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              0.4142580032348633,
              -0.2956329882144928,
              0.8607749938964844,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.22907200455665588,
              -0.8569899797439575,
              0.46153101325035095,
              0.3386639952659607,
              -0.9059720039367676,
              0.2538830041885376,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.743461012840271,
              -0.3696399927139282,
              0.5572980046272278,
              0.3386639952659607,
              -0.9059720039367676,
              0.2538830041885376,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.3386639952659607,
              -0.9059720039367676,
              0.2538830041885376,
              0.4178589880466461,
              -0.9059720039367676,
              0.06741540133953094,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.917294979095459,
              -0.3696709871292114,
              0.14798399806022644,
              0.4178589880466461,
              -0.9059720039367676,
              0.06741540133953094,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.4178589880466461,
              -0.9059720039367676,
              0.06741540133953094,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.880977988243103,
              -0.3696709871292114,
              -0.2952359914779663,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.4013180136680603,
              -0.9060029983520508,
              -0.13449500501155853,
              0.29285600781440735,
              -0.9059720039367676,
              -0.3055819869041443,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.6428719758987427,
              -0.3696709871292114,
              -0.6708269715309143,
              0.29285600781440735,
              -0.9059720039367676,
              -0.3055819869041443,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              0.29285600781440735,
              -0.9059720039367676,
              -0.3055819869041443,
              0.11728300154209137,
              -0.9059720039367676,
              -0.40669000148773193,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              0.2574540078639984,
              -0.3696399927139282,
              -0.8927580118179321,
              0.11728300154209137,
              -0.9059720039367676,
              -0.40669000148773193,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              0.11728300154209137,
              -0.9059720039367676,
              -0.40669000148773193,
              -0.08511610329151154,
              -0.9059720039367676,
              -0.41462400555610657,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              -0.1868949979543686,
              -0.3696399927139282,
              -0.9101539850234985,
              -0.08511610329151154,
              -0.9059720039367676,
              -0.41462400555610657,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.08511610329151154,
              -0.9059720039367676,
              -0.41462400555610657,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.5884580016136169,
              -0.3696399927139282,
              -0.7190470099449158,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.26807498931884766,
              -0.9059720039367676,
              -0.32755500078201294,
              -0.3895989954471588,
              -0.9059720039367676,
              -0.16547100245952606,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.8552200198173523,
              -0.3696399927139282,
              -0.36320099234580994,
              -0.3895989954471588,
              -0.9059720039367676,
              -0.16547100245952606,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.3895989954471588,
              -0.9059720039367676,
              -0.16547100245952606,
              -0.42188799381256104,
              -0.9059720039367676,
              0.03451640158891678,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.9260540008544922,
              -0.3696399927139282,
              0.07583849877119064,
              -0.42188799381256104,
              -0.9059720039367676,
              0.03451640158891678,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.42188799381256104,
              -0.9059720039367676,
              0.03451640158891678,
              -0.3574939966201782,
              -0.9059720039367676,
              0.22663000226020813,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.7847530245780945,
              -0.3696399927139282,
              0.4974820017814636,
              -0.3574939966201782,
              -0.9059720039367676,
              0.22663000226020813,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.3574939966201782,
              -0.9059720039367676,
              0.22663000226020813,
              -0.2644430100917816,
              -0.8569899797439575,
              0.4422439932823181,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.48026999831199646,
              -0.2956329882144928,
              0.8257700204849243,
              -0.2644430100917816,
              -0.8569899797439575,
              0.4422439932823181,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              -0.2644430100917816,
              -0.8569899797439575,
              0.4422439932823181,
              -0.03451640158891678,
              -0.47025999426841736,
              0.8818320035934448,
              -0.037934500724077225,
              -0.24121800065040588,
              0.969694972038269,
              0.6910920143127441,
              -0.02221749909222126,
              0.7224040031433105,
              0.6769620180130005,
              0.3234660029411316,
              0.6610919833183289,
              0.6913970112800598,
              -0.018402699381113052,
              0.7222210168838501,
              0.6769620180130005,
              0.3234660029411316,
              0.6610919833183289,
              0.6769620180130005,
              0.3234660029411316,
              0.6610919833183289,
              0.6913970112800598,
              -0.018402699381113052,
              0.7222210168838501,
              -0.6771450042724609,
              -0.077913798391819,
              0.7316809892654419,
              -0.684499979019165,
              0.058290399610996246,
              0.7266460061073303,
              -0.6771450042724609,
              -0.077913798391819,
              0.7316809892654419,
              -0.684499979019165,
              0.058290399610996246,
              0.7266460061073303,
              -0.6845300197601318,
              0.059633199125528336,
              0.7265239953994751,
              -0.6771450042724609,
              -0.077913798391819,
              0.7316809892654419,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              -0.677236020565033,
              -0.323496013879776,
              -0.6607869863510132,
              0.6845300197601318,
              -0.059541601687669754,
              -0.7265239953994751,
              0.6770529747009277,
              0.07788319885730743,
              -0.7317730188369751,
              0.6845300197601318,
              -0.057893600314855576,
              -0.7266759872436523,
              0.6770529747009277,
              0.07788319885730743,
              -0.7317730188369751,
              0.6770529747009277,
              0.07788319885730743,
              -0.7317730188369751,
              0.6845300197601318,
              -0.057893600314855576,
              -0.7266759872436523,
              0.6210209727287292,
              -0.3617970049381256,
              0.6952419877052307,
              0.6910920143127441,
              -0.02221749909222126,
              0.7224040031433105,
              0.6210209727287292,
              -0.3617970049381256,
              0.6952419877052307,
              0.6910920143127441,
              -0.02221749909222126,
              0.7224040031433105,
              0.6913970112800598,
              -0.018402699381113052,
              0.7222210168838501,
              0.6210209727287292,
              -0.3617970049381256,
              0.6952419877052307,
              -0.684499979019165,
              0.058290399610996246,
              0.7266460061073303,
              -0.6526380181312561,
              0.3714410066604614,
              0.6603289842605591,
              -0.6845300197601318,
              0.059633199125528336,
              0.7265239953994751,
              -0.6526380181312561,
              0.3714410066604614,
              0.6603289842605591,
              -0.6520580053329468,
              0.37412598729133606,
              0.6594129800796509,
              -0.6845300197601318,
              0.059633199125528336,
              0.7265239953994751,
              -0.7100129723548889,
              0.11688599735498428,
              -0.6943879723548889,
              -0.6211130023002625,
              0.361735999584198,
              -0.6952120065689087,
              -0.7089139819145203,
              0.12100599706172943,
              -0.6947839856147766,
              -0.6211130023002625,
              0.361735999584198,
              -0.6952120065689087,
              -0.6211130023002625,
              0.361735999584198,
              -0.6952120065689087,
              -0.7089139819145203,
              0.12100599706172943,
              -0.6947839856147766,
              0.6519970297813416,
              -0.3747979998588562,
              -0.6590780019760132,
              0.6845300197601318,
              -0.059541601687669754,
              -0.7265239953994751,
              0.6526690125465393,
              -0.3717760145664215,
              -0.6601150035858154,
              0.6845300197601318,
              -0.059541601687669754,
              -0.7265239953994751,
              0.6845300197601318,
              -0.057893600314855576,
              -0.7266759872436523,
              0.6526690125465393,
              -0.3717760145664215,
              -0.6601150035858154,
              0.6882839798927307,
              0.19205300509929657,
              0.6995149850845337,
              0.7504810094833374,
              0.13205400109291077,
              0.6475110054016113,
              0.6910920143127441,
              0.189520001411438,
              0.6974390149116516,
              0.7504810094833374,
              0.13205400109291077,
              0.6475110054016113,
              0.7504810094833374,
              0.13205400109291077,
              0.6475110054016113,
              0.6910920143127441,
              0.189520001411438,
              0.6974390149116516,
              -0.602832019329071,
              0.5384690165519714,
              0.5887330174446106,
              -0.6520580053329468,
              0.37412598729133606,
              0.6594129800796509,
              -0.602832019329071,
              0.5384690165519714,
              0.5887330174446106,
              -0.6520580053329468,
              0.37412598729133606,
              0.6594129800796509,
              -0.6526380181312561,
              0.3714410066604614,
              0.6603289842605591,
              -0.602832019329071,
              0.5384690165519714,
              0.5887330174446106,
              -0.6878569722175598,
              -0.1925410032272339,
              -0.6997889876365662,
              -0.7100129723548889,
              0.11688599735498428,
              -0.6943879723548889,
              -0.6900539994239807,
              -0.19055800139904022,
              -0.6982020139694214,
              -0.7100129723548889,
              0.11688599735498428,
              -0.6943879723548889,
              -0.7089139819145203,
              0.12100599706172943,
              -0.6947839856147766,
              -0.6900539994239807,
              -0.19055800139904022,
              -0.6982020139694214,
              0.6028929948806763,
              -0.5384380221366882,
              -0.5886719822883606,
              0.6519970297813416,
              -0.3747979998588562,
              -0.6590780019760132,
              0.6028929948806763,
              -0.5384380221366882,
              -0.5886719822883606,
              0.6519970297813416,
              -0.3747979998588562,
              -0.6590780019760132,
              0.6526690125465393,
              -0.3717760145664215,
              -0.6601150035858154,
              0.6028929948806763,
              -0.5384380221366882,
              -0.5886719822883606,
              0.620959997177124,
              0.2486339956521988,
              0.7433390021324158,
              0.6882839798927307,
              0.19205300509929657,
              0.6995149850845337,
              0.620959997177124,
              0.2486339956521988,
              0.7433390021324158,
              0.6882839798927307,
              0.19205300509929657,
              0.6995149850845337,
              0.6910920143127441,
              0.189520001411438,
              0.6974390149116516,
              0.620959997177124,
              0.2486339956521988,
              0.7433390021324158,
              -0.49940499663352966,
              -0.7249370217323303,
              0.4743489921092987,
              -0.46290498971939087,
              -0.648701012134552,
              0.6040220260620117,
              -0.498214989900589,
              -0.7223119735717773,
              0.4795680046081543,
              -0.46290498971939087,
              -0.648701012134552,
              0.6040220260620117,
              -0.46290498971939087,
              -0.648701012134552,
              0.6040220260620117,
              -0.498214989900589,
              -0.7223119735717773,
              0.4795680046081543,
              -0.6211130023002625,
              -0.2486950010061264,
              -0.7431870102882385,
              -0.6878569722175598,
              -0.1925410032272339,
              -0.6997889876365662,
              -0.6211130023002625,
              -0.2486950010061264,
              -0.7431870102882385,
              -0.6878569722175598,
              -0.1925410032272339,
              -0.6997889876365662,
              -0.6900539994239807,
              -0.19055800139904022,
              -0.6982020139694214,
              -0.6211130023002625,
              -0.2486950010061264,
              -0.7431870102882385,
              0.49888598918914795,
              0.7235940098762512,
              -0.4769130051136017,
              0.4630880057811737,
              0.6487929821014404,
              -0.6037780046463013,
              0.49806201457977295,
              0.721792995929718,
              -0.4805139899253845,
              0.4630880057811737,
              0.6487929821014404,
              -0.6037780046463013,
              0.4630880057811737,
              0.6487929821014404,
              -0.6037780046463013,
              0.49806201457977295,
              0.721792995929718,
              -0.4805139899253845,
              0.6956080198287964,
              -0.6213260293006897,
              -0.36063700914382935,
              0.6980800032615662,
              -0.6224859952926636,
              -0.35370999574661255,
              0.6955779790878296,
              -0.6213260293006897,
              -0.36063700914382935,
              0.6980800032615662,
              -0.6224859952926636,
              -0.35370999574661255,
              0.6980800032615662,
              -0.6224859952926636,
              -0.35370999574661255,
              0.6955779790878296,
              -0.6213260293006897,
              -0.36063700914382935,
              -0.5307469964027405,
              -0.782738983631134,
              0.3249000012874603,
              -0.49940499663352966,
              -0.7249370217323303,
              0.4743489921092987,
              -0.5307469964027405,
              -0.7827690243721008,
              0.3248699903488159,
              -0.49940499663352966,
              -0.7249370217323303,
              0.4743489921092987,
              -0.498214989900589,
              -0.7223119735717773,
              0.4795680046081543,
              -0.5307469964027405,
              -0.7827690243721008,
              0.3248699903488159,
              -0.6955779790878296,
              0.6214179992675781,
              0.3605459928512573,
              -0.6981409788131714,
              0.6225169897079468,
              0.3535569906234741,
              -0.6955779790878296,
              0.6213870048522949,
              0.3605459928512573,
              -0.6981409788131714,
              0.6225169897079468,
              0.3535569906234741,
              -0.6981409788131714,
              0.6225169897079468,
              0.3535569906234741,
              -0.6955779790878296,
              0.6213870048522949,
              0.3605459928512573,
              0.5308079719543457,
              0.7826780080795288,
              -0.32496100664138794,
              0.49888598918914795,
              0.7235940098762512,
              -0.4769130051136017,
              0.5308079719543457,
              0.7826780080795288,
              -0.32493099570274353,
              0.49888598918914795,
              0.7235940098762512,
              -0.4769130051136017,
              0.49806201457977295,
              0.721792995929718,
              -0.4805139899253845,
              0.5308079719543457,
              0.7826780080795288,
              -0.32493099570274353,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              0.6956080198287964,
              -0.6213260293006897,
              -0.36063700914382935,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              0.6956080198287964,
              -0.6213260293006897,
              -0.36063700914382935,
              0.6955779790878296,
              -0.6213260293006897,
              -0.36063700914382935,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.5307469964027405,
              -0.782738983631134,
              0.3249000012874603,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.5307469964027405,
              -0.782738983631134,
              0.3249000012874603,
              -0.5307469964027405,
              -0.7827690243721008,
              0.3248699903488159,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              -0.6955779790878296,
              0.6214179992675781,
              0.3605459928512573,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              -0.6955779790878296,
              0.6214179992675781,
              0.3605459928512573,
              -0.6955779790878296,
              0.6213870048522949,
              0.3605459928512573,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.5308079719543457,
              0.7826780080795288,
              -0.32496100664138794,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.5308079719543457,
              0.7826780080795288,
              -0.32496100664138794,
              0.5308079719543457,
              0.7826780080795288,
              -0.32493099570274353,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              0.6843780279159546,
              -0.6195260286331177,
              -0.3844110071659088,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              -0.6372259855270386,
              -0.7618340253829956,
              0.11612299829721451,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.6372569799423218,
              -0.7618340253829956,
              0.11612299829721451,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.5619069933891296,
              -0.7835929989814758,
              0.2649010121822357,
              -0.6372569799423218,
              -0.7618340253829956,
              0.11612299829721451,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              -0.684194028377533,
              0.6196780204772949,
              0.3844720125198364,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.5620589852333069,
              0.7834709882736206,
              -0.26496198773384094,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.6174499988555908,
              -0.5332800149917603,
              -0.5782039761543274,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              0.6174499988555908,
              -0.5332499742507935,
              -0.5782340168952942,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              0.65727698802948,
              -0.6098510026931763,
              -0.44276300072669983,
              0.6174499988555908,
              -0.5332499742507935,
              -0.5782340168952942,
              -0.7809380292892456,
              -0.5783870220184326,
              -0.2356639951467514,
              -0.6372259855270386,
              -0.7618340253829956,
              0.11612299829721451,
              -0.7809380292892456,
              -0.5784170031547546,
              -0.2356639951467514,
              -0.6372259855270386,
              -0.7618340253829956,
              0.11612299829721451,
              -0.6372569799423218,
              -0.7618340253829956,
              0.11612299829721451,
              -0.7809380292892456,
              -0.5784170031547546,
              -0.2356639951467514,
              -0.6173589825630188,
              0.5334029793739319,
              0.5781729817390442,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              -0.6173890233039856,
              0.5334029793739319,
              0.5781729817390442,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              -0.6570940017700195,
              0.6100040078163147,
              0.44279301166534424,
              -0.6173890233039856,
              0.5334029793739319,
              0.5781729817390442,
              0.781059980392456,
              0.5782650113105774,
              0.2356330007314682,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.781059980392456,
              0.5782340168952942,
              0.2356639951467514,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.637378990650177,
              0.7617110013961792,
              -0.11618400365114212,
              0.781059980392456,
              0.5782340168952942,
              0.2356639951467514,
              0.6228219866752625,
              -0.32422900199890137,
              -0.7119969725608826,
              0.6174499988555908,
              -0.5332800149917603,
              -0.5782039761543274,
              0.6227909922599792,
              -0.3242590129375458,
              -0.7119969725608826,
              0.6174499988555908,
              -0.5332800149917603,
              -0.5782039761543274,
              0.6174499988555908,
              -0.5332499742507935,
              -0.5782340168952942,
              0.6227909922599792,
              -0.3242590129375458,
              -0.7119969725608826,
              -0.8075199723243713,
              -0.3709520101547241,
              -0.45851001143455505,
              -0.7809380292892456,
              -0.5783870220184326,
              -0.2356639951467514,
              -0.8075199723243713,
              -0.3709520101547241,
              -0.45851001143455505,
              -0.7809380292892456,
              -0.5783870220184326,
              -0.2356639951467514,
              -0.7809380292892456,
              -0.5784170031547546,
              -0.2356639951467514,
              -0.8075199723243713,
              -0.3709520101547241,
              -0.45851001143455505,
              -0.622730016708374,
              0.324319988489151,
              0.7120270133018494,
              -0.6173589825630188,
              0.5334029793739319,
              0.5781729817390442,
              -0.622730016708374,
              0.3243510127067566,
              0.7120270133018494,
              -0.6173589825630188,
              0.5334029793739319,
              0.5781729817390442,
              -0.6173890233039856,
              0.5334029793739319,
              0.5781729817390442,
              -0.622730016708374,
              0.3243510127067566,
              0.7120270133018494,
              0.8076419830322266,
              0.37064701318740845,
              0.4585709869861603,
              0.781059980392456,
              0.5782650113105774,
              0.2356330007314682,
              0.8076419830322266,
              0.37064701318740845,
              0.4585709869861603,
              0.781059980392456,
              0.5782650113105774,
              0.2356330007314682,
              0.781059980392456,
              0.5782340168952942,
              0.2356639951467514,
              0.8076419830322266,
              0.37064701318740845,
              0.4585709869861603,
              0.6747339963912964,
              -0.123478002846241,
              -0.7276219725608826,
              0.6228219866752625,
              -0.32422900199890137,
              -0.7119969725608826,
              0.6747339963912964,
              -0.12350799888372421,
              -0.7276219725608826,
              0.6228219866752625,
              -0.32422900199890137,
              -0.7119969725608826,
              0.6227909922599792,
              -0.3242590129375458,
              -0.7119969725608826,
              0.6747339963912964,
              -0.12350799888372421,
              -0.7276219725608826,
              -0.598406970500946,
              0.5027620196342468,
              -0.6237679719924927,
              -0.6662189960479736,
              0.34470701217651367,
              -0.6612750291824341,
              -0.598468005657196,
              0.5026699900627136,
              -0.6237980127334595,
              -0.6662189960479736,
              0.34470701217651367,
              -0.6612750291824341,
              -0.6662189960479736,
              0.34470701217651367,
              -0.6612750291824341,
              -0.598468005657196,
              0.5026699900627136,
              -0.6237980127334595,
              -0.674642026424408,
              0.1234470009803772,
              0.7277140021324158,
              -0.622730016708374,
              0.324319988489151,
              0.7120270133018494,
              -0.674642026424408,
              0.123478002846241,
              0.7277140021324158,
              -0.622730016708374,
              0.324319988489151,
              0.7120270133018494,
              -0.622730016708374,
              0.3243510127067566,
              0.7120270133018494,
              -0.674642026424408,
              0.123478002846241,
              0.7277140021324158,
              0.5986819863319397,
              -0.5027620196342468,
              0.623524010181427,
              0.6665850281715393,
              -0.3446759879589081,
              0.6609389781951904,
              0.5987120270729065,
              -0.5027009844779968,
              0.623524010181427,
              0.6665850281715393,
              -0.3446759879589081,
              0.6609389781951904,
              0.6665850281715393,
              -0.3446759879589081,
              0.6609389781951904,
              0.5987120270729065,
              -0.5027009844779968,
              0.623524010181427,
              0.7329630255699158,
              -0.01895200088620186,
              -0.6799520254135132,
              0.6747339963912964,
              -0.123478002846241,
              -0.7276219725608826,
              0.732932984828949,
              -0.01904349960386753,
              -0.6800130009651184,
              0.6747339963912964,
              -0.123478002846241,
              -0.7276219725608826,
              0.6747339963912964,
              -0.12350799888372421,
              -0.7276219725608826,
              0.732932984828949,
              -0.01904349960386753,
              -0.6800130009651184,
              -0.4848169982433319,
              0.6907860040664673,
              -0.5363929867744446,
              -0.598406970500946,
              0.5027620196342468,
              -0.6237679719924927,
              -0.4848479926586151,
              0.6907250285148621,
              -0.5364239811897278,
              -0.598406970500946,
              0.5027620196342468,
              -0.6237679719924927,
              -0.598468005657196,
              0.5026699900627136,
              -0.6237980127334595,
              -0.4848479926586151,
              0.6907250285148621,
              -0.5364239811897278,
              -0.7329630255699158,
              0.01895200088620186,
              0.6799520254135132,
              -0.674642026424408,
              0.1234470009803772,
              0.7277140021324158,
              -0.7329630255699158,
              0.018982499837875366,
              0.6799830198287964,
              -0.674642026424408,
              0.1234470009803772,
              0.7277140021324158,
              -0.674642026424408,
              0.123478002846241,
              0.7277140021324158,
              -0.7329630255699158,
              0.018982499837875366,
              0.6799830198287964,
              0.4849089980125427,
              -0.6907860040664673,
              0.5363019704818726,
              0.5986819863319397,
              -0.5027620196342468,
              0.623524010181427,
              0.48493900895118713,
              -0.6907560229301453,
              0.5363320112228394,
              0.5986819863319397,
              -0.5027620196342468,
              0.623524010181427,
              0.5987120270729065,
              -0.5027009844779968,
              0.623524010181427,
              0.48493900895118713,
              -0.6907560229301453,
              0.5363320112228394,
              0.8289740085601807,
              0.1278419941663742,
              -0.5444499850273132,
              0.7329630255699158,
              -0.01895200088620186,
              -0.6799520254135132,
              0.8289740085601807,
              0.127811998128891,
              -0.5444499850273132,
              0.7329630255699158,
              -0.01895200088620186,
              -0.6799520254135132,
              0.732932984828949,
              -0.01904349960386753,
              -0.6800130009651184,
              0.8289740085601807,
              0.127811998128891,
              -0.5444499850273132,
              -0.43403398990631104,
              0.7746819853782654,
              -0.45982199907302856,
              -0.4848169982433319,
              0.6907860040664673,
              -0.5363929867744446,
              -0.4340650141239166,
              0.7746509909629822,
              -0.45982199907302856,
              -0.4848169982433319,
              0.6907860040664673,
              -0.5363929867744446,
              -0.4848479926586151,
              0.6907250285148621,
              -0.5364239811897278,
              -0.4340650141239166,
              0.7746509909629822,
              -0.45982199907302856,
              -0.8290349841117859,
              -0.1277810037136078,
              0.544389009475708,
              -0.7329630255699158,
              0.01895200088620186,
              0.6799520254135132,
              -0.8289740085601807,
              -0.12768900394439697,
              0.5444499850273132,
              -0.7329630255699158,
              0.01895200088620186,
              0.6799520254135132,
              -0.7329630255699158,
              0.018982499837875366,
              0.6799830198287964,
              -0.8289740085601807,
              -0.12768900394439697,
              0.5444499850273132,
              0.43409499526023865,
              -0.7746819853782654,
              0.45976099371910095,
              0.4849089980125427,
              -0.6907860040664673,
              0.5363019704818726,
              0.43412598967552185,
              -0.7746509909629822,
              0.45979198813438416,
              0.4849089980125427,
              -0.6907860040664673,
              0.5363019704818726,
              0.48493900895118713,
              -0.6907560229301453,
              0.5363320112228394,
              0.43412598967552185,
              -0.7746509909629822,
              0.45979198813438416,
              0.9265720248222351,
              0.3456220030784607,
              -0.14813700318336487,
              0.8289740085601807,
              0.1278419941663742,
              -0.5444499850273132,
              0.9265720248222351,
              0.3456529974937439,
              -0.14813700318336487,
              0.8289740085601807,
              0.1278419941663742,
              -0.5444499850273132,
              0.8289740085601807,
              0.127811998128891,
              -0.5444499850273132,
              0.9265720248222351,
              0.3456529974937439,
              -0.14813700318336487,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.43403398990631104,
              0.7746819853782654,
              -0.45982199907302856,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.43403398990631104,
              0.7746819853782654,
              -0.45982199907302856,
              -0.4340650141239166,
              0.7746509909629822,
              -0.45982199907302856,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              -0.8290349841117859,
              -0.1277810037136078,
              0.544389009475708,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              -0.8290349841117859,
              -0.1277810037136078,
              0.544389009475708,
              -0.8289740085601807,
              -0.12768900394439697,
              0.5444499850273132,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              0.4109320044517517,
              -0.859827995300293,
              0.3029879927635193,
              0.43409499526023865,
              -0.7746819853782654,
              0.45976099371910095,
              0.4109320044517517,
              -0.859798014163971,
              0.3030180037021637,
              0.43409499526023865,
              -0.7746819853782654,
              0.45976099371910095,
              0.43412598967552185,
              -0.7746509909629822,
              0.45979198813438416,
              0.4109320044517517,
              -0.859798014163971,
              0.3030180037021637,
              0.8083130121231079,
              0.41627201437950134,
              0.41630300879478455,
              0.9265720248222351,
              0.3456220030784607,
              -0.14813700318336487,
              0.8082519769668579,
              0.41627201437950134,
              0.4164249897003174,
              0.9265720248222351,
              0.3456220030784607,
              -0.14813700318336487,
              0.9265720248222351,
              0.3456529974937439,
              -0.14813700318336487,
              0.8082519769668579,
              0.41627201437950134,
              0.4164249897003174,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.4108709990978241,
              0.859798014163971,
              -0.3031100034713745,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.8082519769668579,
              -0.41630300879478455,
              -0.41636401414871216,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              -0.8082829713821411,
              -0.41633298993110657,
              -0.41630300879478455,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              -0.9266030192375183,
              -0.3455609977245331,
              0.1481979936361313,
              -0.8082829713821411,
              -0.41633298993110657,
              -0.41630300879478455,
              0.4535660147666931,
              -0.8869900107383728,
              0.08661150187253952,
              0.4109320044517517,
              -0.859827995300293,
              0.3029879927635193,
              0.4535660147666931,
              -0.8869900107383728,
              0.08658099919557571,
              0.4109320044517517,
              -0.859827995300293,
              0.3029879927635193,
              0.4109320044517517,
              -0.859798014163971,
              0.3030180037021637,
              0.4535660147666931,
              -0.8869900107383728,
              0.08658099919557571,
              0.5806450247764587,
              0.30930501222610474,
              0.7530750036239624,
              0.8083130121231079,
              0.41627201437950134,
              0.41630300879478455,
              0.5806760191917419,
              0.30933600664138794,
              0.7530440092086792,
              0.8083130121231079,
              0.41627201437950134,
              0.41630300879478455,
              0.8082519769668579,
              0.41627201437950134,
              0.4164249897003174,
              0.5806760191917419,
              0.30933600664138794,
              0.7530440092086792,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.4535360038280487,
              0.8869900107383728,
              -0.08661150187253952,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.5805839896202087,
              -0.30936598777770996,
              -0.7531049847602844,
              -0.8082519769668579,
              -0.41630300879478455,
              -0.41636401414871216,
              -0.5805839896202087,
              -0.30933600664138794,
              -0.7531049847602844,
              -0.8082519769668579,
              -0.41630300879478455,
              -0.41636401414871216,
              -0.8082829713821411,
              -0.41633298993110657,
              -0.41630300879478455,
              -0.5805839896202087,
              -0.30933600664138794,
              -0.7531049847602844,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.4535660147666931,
              -0.8869900107383728,
              0.08661150187253952,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.4535660147666931,
              -0.8869900107383728,
              0.08661150187253952,
              0.4535660147666931,
              -0.8869900107383728,
              0.08658099919557571,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.4491409957408905,
              0.2143010050058365,
              0.8673670291900635,
              0.5806450247764587,
              0.30930501222610474,
              0.7530750036239624,
              0.4491100013256073,
              0.2143010050058365,
              0.8673670291900635,
              0.5806450247764587,
              0.30930501222610474,
              0.7530750036239624,
              0.5806760191917419,
              0.30933600664138794,
              0.7530440092086792,
              0.4491100013256073,
              0.2143010050058365,
              0.8673670291900635,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.5309919714927673,
              0.8457589745521545,
              0.05182040110230446,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              -0.5805839896202087,
              -0.30936598777770996,
              -0.7531049847602844,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              -0.5805839896202087,
              -0.30936598777770996,
              -0.7531049847602844,
              -0.5805839896202087,
              -0.30933600664138794,
              -0.7531049847602844,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.5310829877853394,
              -0.8457289934158325,
              -0.05169840157032013,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.3921320140361786,
              0.16504399478435516,
              0.9049649834632874,
              0.4491409957408905,
              0.2143010050058365,
              0.8673670291900635,
              0.3921020030975342,
              0.16501399874687195,
              0.9049649834632874,
              0.4491409957408905,
              0.2143010050058365,
              0.8673670291900635,
              0.4491100013256073,
              0.2143010050058365,
              0.8673670291900635,
              0.3921020030975342,
              0.16501399874687195,
              0.9049649834632874,
              -0.6027100086212158,
              0.7893610000610352,
              0.11670300364494324,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.6027100086212158,
              0.7893310189247131,
              0.11673299968242645,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.580706000328064,
              0.8080080151557922,
              0.09939879924058914,
              -0.6027100086212158,
              0.7893310189247131,
              0.11673299968242645,
              -0.3921630084514618,
              -0.16513599455356598,
              -0.9049350023269653,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              -0.3921320140361786,
              -0.16510500013828278,
              -0.9049350023269653,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              -0.4491100013256073,
              -0.2142699956893921,
              -0.8673670291900635,
              -0.3921320140361786,
              -0.16510500013828278,
              -0.9049350023269653,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.5807669758796692,
              -0.8079469799995422,
              -0.09936829656362534,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.39112499356269836,
              0.22159500420093536,
              0.8932160139083862,
              0.3921320140361786,
              0.16504399478435516,
              0.9049649834632874,
              0.39115598797798157,
              0.2217469960451126,
              0.893185019493103,
              0.3921320140361786,
              0.16504399478435516,
              0.9049649834632874,
              0.3921020030975342,
              0.16501399874687195,
              0.9049649834632874,
              0.39115598797798157,
              0.2217469960451126,
              0.893185019493103,
              -0.6085389852523804,
              0.7839900255203247,
              0.12247099727392197,
              -0.6027100086212158,
              0.7893610000610352,
              0.11670300364494324,
              -0.6085389852523804,
              0.7839900255203247,
              0.12247099727392197,
              -0.6027100086212158,
              0.7893610000610352,
              0.11670300364494324,
              -0.6027100086212158,
              0.7893310189247131,
              0.11673299968242645,
              -0.6085389852523804,
              0.7839900255203247,
              0.12247099727392197,
              -0.39109501242637634,
              -0.22165599465370178,
              -0.8932459950447083,
              -0.3921630084514618,
              -0.16513599455356598,
              -0.9049350023269653,
              -0.39109501242637634,
              -0.221778005361557,
              -0.893185019493103,
              -0.3921630084514618,
              -0.16513599455356598,
              -0.9049350023269653,
              -0.3921320140361786,
              -0.16510500013828278,
              -0.9049350023269653,
              -0.39109501242637634,
              -0.221778005361557,
              -0.893185019493103,
              0.6083559989929199,
              -0.7840819954872131,
              -0.12274499982595444,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.6083559989929199,
              -0.7840819954872131,
              -0.12274499982595444,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.6026189923286438,
              -0.7893919944763184,
              -0.1169160008430481,
              0.6083559989929199,
              -0.7840819954872131,
              -0.12274499982595444,
              0.4031499922275543,
              0.29279500246047974,
              0.8669999837875366,
              0.39112499356269836,
              0.22159500420093536,
              0.8932160139083862,
              0.4031499922275543,
              0.29279500246047974,
              0.8669999837875366,
              0.39112499356269836,
              0.22159500420093536,
              0.8932160139083862,
              0.39115598797798157,
              0.2217469960451126,
              0.893185019493103,
              0.4031499922275543,
              0.29279500246047974,
              0.8669999837875366,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.792290985584259,
              -0.3625600039958954,
              0.49067699909210205,
              -0.4031189978122711,
              -0.2927339971065521,
              -0.8670309782028198,
              -0.39109501242637634,
              -0.22165599465370178,
              -0.8932459950447083,
              -0.4031189978122711,
              -0.2927339971065521,
              -0.8670309782028198,
              -0.39109501242637634,
              -0.22165599465370178,
              -0.8932459950447083,
              -0.39109501242637634,
              -0.221778005361557,
              -0.893185019493103,
              -0.4031189978122711,
              -0.2927339971065521,
              -0.8670309782028198,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.7923219799995422,
              0.3625899851322174,
              -0.49061599373817444,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              0.28812500834465027,
              -0.9430220127105713,
              0.16635599732398987,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08590959757566452,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08587910234928131,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08590959757566452,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08590959757566452,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08590959757566452,
              -0.4578999876976013,
              0.8848230242729187,
              -0.08590959757566452,
              -0.9975889921188354,
              -0.022247999906539917,
              0.06527909636497498,
              -0.941556990146637,
              0.3234660029411316,
              0.09384439885616302,
              -0.9976500272750854,
              -0.01843320019543171,
              0.06564529985189438,
              -0.941556990146637,
              0.3234660029411316,
              0.09384439885616302,
              -0.941556990146637,
              0.3234660029411316,
              0.09384439885616302,
              -0.9976500272750854,
              -0.01843320019543171,
              0.06564529985189438,
              -0.11667200177907944,
              0.05969420075416565,
              -0.991362988948822,
              -0.12543100118637085,
              -0.07785270363092422,
              -0.9890130162239075,
              -0.11676400154829025,
              0.05835140123963356,
              -0.991424024105072,
              -0.12543100118637085,
              -0.07785270363092422,
              -0.9890130162239075,
              -0.12543100118637085,
              -0.07785270363092422,
              -0.9890130162239075,
              -0.11676400154829025,
              0.05835140123963356,
              -0.991424024105072,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.941556990146637,
              -0.3234660029411316,
              -0.09387490153312683,
              0.11661099642515182,
              -0.059419501572847366,
              0.9913939833641052,
              0.12527799606323242,
              0.07788319885730743,
              0.9890440106391907,
              0.11673299968242645,
              -0.05777150020003319,
              0.9914550185203552,
              0.12527799606323242,
              0.07788319885730743,
              0.9890440106391907,
              0.12527799606323242,
              0.07788319885730743,
              0.9890440106391907,
              0.11673299968242645,
              -0.05777150020003319,
              0.9914550185203552,
              -0.9317610263824463,
              -0.3618580102920532,
              0.029053600504994392,
              -0.9975889921188354,
              -0.022247999906539917,
              0.06527909636497498,
              -0.9317610263824463,
              -0.3618580102920532,
              0.029053600504994392,
              -0.9975889921188354,
              -0.022247999906539917,
              0.06527909636497498,
              -0.9976500272750854,
              -0.01843320019543171,
              0.06564529985189438,
              -0.9317610263824463,
              -0.3618580102920532,
              0.029053600504994392,
              -0.0860012024641037,
              0.37412598729133606,
              -0.923367977142334,
              -0.11667200177907944,
              0.05969420075416565,
              -0.991362988948822,
              -0.0863369032740593,
              0.3714100122451782,
              -0.9244059920310974,
              -0.11667200177907944,
              0.05969420075416565,
              -0.991362988948822,
              -0.11676400154829025,
              0.05835140123963356,
              -0.991424024105072,
              -0.0863369032740593,
              0.3714100122451782,
              -0.9244059920310974,
              0.9883419871330261,
              0.1169160008430481,
              -0.09735400229692459,
              0.9318220019340515,
              0.3617050051689148,
              -0.028687400743365288,
              0.987945020198822,
              0.12097500264644623,
              -0.09628590196371078,
              0.9318220019340515,
              0.3617050051689148,
              -0.028687400743365288,
              0.9318220019340515,
              0.3617050051689148,
              -0.028687400743365288,
              0.987945020198822,
              0.12097500264644623,
              -0.09628590196371078,
              0.08603169769048691,
              -0.374767005443573,
              0.9230930209159851,
              0.11661099642515182,
              -0.059419501572847366,
              0.9913939833641052,
              0.08639790117740631,
              -0.3717150092124939,
              0.9242839813232422,
              0.11661099642515182,
              -0.059419501572847366,
              0.9913939833641052,
              0.11673299968242645,
              -0.05777150020003319,
              0.9914550185203552,
              0.08639790117740631,
              -0.3717150092124939,
              0.9242839813232422,
              -0.9782710075378418,
              0.19208300113677979,
              0.0777612030506134,
              -0.978422999382019,
              0.13205400109291077,
              0.1587270051240921,
              -0.9784839749336243,
              0.1895810067653656,
              0.08124029636383057,
              -0.978422999382019,
              0.13205400109291077,
              0.1587270051240921,
              -0.978422999382019,
              0.13205400109291077,
              0.1587270051240921,
              -0.9784839749336243,
              0.1895810067653656,
              0.08124029636383057,
              -0.0634479969739914,
              0.5384380221366882,
              -0.8402659893035889,
              -0.0860012024641037,
              0.37412598729133606,
              -0.923367977142334,
              -0.0634479969739914,
              0.5384380221366882,
              -0.8402659893035889,
              -0.0860012024641037,
              0.37412598729133606,
              -0.923367977142334,
              -0.0863369032740593,
              0.3714100122451782,
              -0.9244059920310974,
              -0.0634479969739914,
              0.5384380221366882,
              -0.8402659893035889,
              0.9782400131225586,
              -0.1925410032272339,
              -0.07705920189619064,
              0.9883419871330261,
              0.1169160008430481,
              -0.09735400229692459,
              0.978393018245697,
              -0.19058799743652344,
              -0.07977540045976639,
              0.9883419871330261,
              0.1169160008430481,
              -0.09735400229692459,
              0.987945020198822,
              0.12097500264644623,
              -0.09628590196371078,
              0.978393018245697,
              -0.19058799743652344,
              -0.07977540045976639,
              0.0636615976691246,
              -0.5384989976882935,
              0.8402050137519836,
              0.08603169769048691,
              -0.374767005443573,
              0.9230930209159851,
              0.0636615976691246,
              -0.5384989976882935,
              0.8402050137519836,
              0.08603169769048691,
              -0.374767005443573,
              0.9230930209159851,
              0.08639790117740631,
              -0.3717150092124939,
              0.9242839813232422,
              0.0636615976691246,
              -0.5384989976882935,
              0.8402050137519836,
              -0.9685350060462952,
              0.2487259954214096,
              -0.0018921500304713845,
              -0.9782710075378418,
              0.19208300113677979,
              0.0777612030506134,
              -0.9685350060462952,
              0.2487259954214096,
              -0.0018921500304713845,
              -0.9782710075378418,
              0.19208300113677979,
              0.0777612030506134,
              -0.9784839749336243,
              0.1895810067653656,
              0.08124029636383057,
              -0.9685350060462952,
              0.2487259954214096,
              -0.0018921500304713845,
              -0.042329199612140656,
              -0.7249370217323303,
              -0.6874899864196777,
              -0.16504399478435516,
              -0.6487320065498352,
              -0.7428820133209229,
              -0.047059498727321625,
              -0.7223430275917053,
              -0.6899009943008423,
              -0.16504399478435516,
              -0.6487320065498352,
              -0.7428820133209229,
              -0.16504399478435516,
              -0.6487320065498352,
              -0.7428820133209229,
              -0.047059498727321625,
              -0.7223430275917053,
              -0.6899009943008423,
              0.9685350060462952,
              -0.24875600636005402,
              0.0019531799480319023,
              0.9782400131225586,
              -0.1925410032272339,
              -0.07705920189619064,
              0.9685350060462952,
              -0.24875600636005402,
              0.0019531799480319023,
              0.9782400131225586,
              -0.1925410032272339,
              -0.07705920189619064,
              0.978393018245697,
              -0.19058799743652344,
              -0.07977540045976639,
              0.9685350060462952,
              -0.24875600636005402,
              0.0019531799480319023,
              0.044679101556539536,
              0.7236239910125732,
              0.6887109875679016,
              0.16492199897766113,
              0.6487929821014404,
              0.7428510189056396,
              0.047975100576877594,
              0.721792995929718,
              0.6903899908065796,
              0.16492199897766113,
              0.6487929821014404,
              0.7428510189056396,
              0.16492199897766113,
              0.6487929821014404,
              0.7428510189056396,
              0.047975100576877594,
              0.721792995929718,
              0.6903899908065796,
              -0.1708119958639145,
              -0.6213570237159729,
              0.7646409869194031,
              -0.1777700036764145,
              -0.6225169897079468,
              0.7621080279350281,
              -0.17078199982643127,
              -0.6213570237159729,
              0.7646409869194031,
              -0.1777700036764145,
              -0.6225169897079468,
              0.7621080279350281,
              -0.1777700036764145,
              -0.6225169897079468,
              0.7621080279350281,
              -0.17078199982643127,
              -0.6213570237159729,
              0.7646409869194031,
              0.09222690016031265,
              -0.782738983631134,
              -0.6154360175132751,
              -0.042329199612140656,
              -0.7249370217323303,
              -0.6874899864196777,
              0.09225749969482422,
              -0.782738983631134,
              -0.6154360175132751,
              -0.042329199612140656,
              -0.7249370217323303,
              -0.6874899864196777,
              -0.047059498727321625,
              -0.7223430275917053,
              -0.6899009943008423,
              0.09225749969482422,
              -0.782738983631134,
              -0.6154360175132751,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              0.17795300483703613,
              0.6225169897079468,
              -0.7620779871940613,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              0.17795300483703613,
              0.6225169897079468,
              -0.7620779871940613,
              0.17795300483703613,
              0.6225169897079468,
              -0.7620779871940613,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              -0.09222690016031265,
              0.782738983631134,
              0.6154360175132751,
              0.044679101556539536,
              0.7236239910125732,
              0.6887109875679016,
              -0.09225749969482422,
              0.782738983631134,
              0.6154360175132751,
              0.044679101556539536,
              0.7236239910125732,
              0.6887109875679016,
              0.047975100576877594,
              0.721792995929718,
              0.6903899908065796,
              -0.09225749969482422,
              0.782738983631134,
              0.6154360175132751,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              -0.1708119958639145,
              -0.6213570237159729,
              0.7646409869194031,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              -0.1708119958639145,
              -0.6213570237159729,
              0.7646409869194031,
              -0.17078199982643127,
              -0.6213570237159729,
              0.7646409869194031,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.09222690016031265,
              -0.782738983631134,
              -0.6154360175132751,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.09222690016031265,
              -0.782738983631134,
              -0.6154360175132751,
              0.09225749969482422,
              -0.782738983631134,
              -0.6154360175132751,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              0.17093400657176971,
              0.6213570237159729,
              -0.7646409869194031,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.09222690016031265,
              0.782738983631134,
              0.6154360175132751,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.09222690016031265,
              0.782738983631134,
              0.6154360175132751,
              -0.09225749969482422,
              0.782738983631134,
              0.6154360175132751,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              -0.14539000391960144,
              -0.6195870041847229,
              0.7713249921798706,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.1582379937171936,
              -0.7835929989814758,
              -0.6007570028305054,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.08331549912691116,
              0.6098210215568542,
              -0.7881410121917725,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              0.08328499644994736,
              0.609790027141571,
              -0.7881410121917725,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              0.14535999298095703,
              0.6195260286331177,
              -0.7713549733161926,
              0.08328499644994736,
              0.609790027141571,
              -0.7881410121917725,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.158269003033638,
              0.7835630178451538,
              0.6007869839668274,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              0.04596089944243431,
              -0.5333409905433655,
              0.8446300029754639,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              0.04599139839410782,
              -0.5333110094070435,
              0.8446300029754639,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              -0.08337660133838654,
              -0.6099119782447815,
              0.7880489826202393,
              0.04599139839410782,
              -0.5333110094070435,
              0.8446300029754639,
              0.6826689839363098,
              -0.5782650113105774,
              -0.4466690123081207,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.6825770139694214,
              -0.5783259868621826,
              -0.44672998785972595,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.32074999809265137,
              -0.7618029713630676,
              -0.5627920031547546,
              0.6825770139694214,
              -0.5783259868621826,
              -0.44672998785972595,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446300029754639,
              0.08331549912691116,
              0.6098210215568542,
              -0.7881410121917725,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446609973907471,
              0.08331549912691116,
              0.6098210215568542,
              -0.7881410121917725,
              0.08328499644994736,
              0.609790027141571,
              -0.7881410121917725,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446609973907471,
              -0.6825770139694214,
              0.5783259868621826,
              0.44672998785972595,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              -0.6826069951057434,
              0.5782949924468994,
              0.44672998785972595,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              -0.32074999809265137,
              0.7617419958114624,
              0.5628830194473267,
              -0.6826069951057434,
              0.5782949924468994,
              0.44672998785972595,
              0.14511600136756897,
              -0.32416799664497375,
              0.9347820281982422,
              0.04596089944243431,
              -0.5333409905433655,
              0.8446300029754639,
              0.14511600136756897,
              -0.32419800758361816,
              0.9347820281982422,
              0.04596089944243431,
              -0.5333409905433655,
              0.8446300029754639,
              0.04599139839410782,
              -0.5333110094070435,
              0.8446300029754639,
              0.14511600136756897,
              -0.32419800758361816,
              0.9347820281982422,
              0.8703879714012146,
              -0.3707999885082245,
              -0.3238619863986969,
              0.6826689839363098,
              -0.5782650113105774,
              -0.4466690123081207,
              0.8703879714012146,
              -0.3707999885082245,
              -0.3238619863986969,
              0.6826689839363098,
              -0.5782650113105774,
              -0.4466690123081207,
              0.6825770139694214,
              -0.5783259868621826,
              -0.44672998785972595,
              0.8703879714012146,
              -0.3707999885082245,
              -0.3238619863986969,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347509741783142,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446300029754639,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347209930419922,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446300029754639,
              -0.046021901071071625,
              0.5333110094070435,
              -0.8446609973907471,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347209930419922,
              -0.8703879714012146,
              0.3708609938621521,
              0.3238010108470917,
              -0.6825770139694214,
              0.5783259868621826,
              0.44672998785972595,
              -0.8703879714012146,
              0.3708609938621521,
              0.3238010108470917,
              -0.6825770139694214,
              0.5783259868621826,
              0.44672998785972595,
              -0.6826069951057434,
              0.5782949924468994,
              0.44672998785972595,
              -0.8703879714012146,
              0.3708609938621521,
              0.3238010108470917,
              0.12372200191020966,
              -0.12338600307703018,
              0.9845880270004272,
              0.14511600136756897,
              -0.32416799664497375,
              0.9347820281982422,
              0.12375299632549286,
              -0.1234470009803772,
              0.9845880270004272,
              0.14511600136756897,
              -0.32416799664497375,
              0.9347820281982422,
              0.14511600136756897,
              -0.32419800758361816,
              0.9347820281982422,
              0.12375299632549286,
              -0.1234470009803772,
              0.9845880270004272,
              0.8625140190124512,
              0.5027009844779968,
              -0.057679999619722366,
              0.9348120093345642,
              0.34464600682258606,
              -0.08551289886236191,
              0.8625450134277344,
              0.5026699900627136,
              -0.057710498571395874,
              0.9348120093345642,
              0.34464600682258606,
              -0.08551289886236191,
              0.9348120093345642,
              0.34464600682258606,
              -0.08551289886236191,
              0.8625450134277344,
              0.5026699900627136,
              -0.057710498571395874,
              -0.12375299632549286,
              0.1234470009803772,
              -0.9845880270004272,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347509741783142,
              -0.12375299632549286,
              0.123478002846241,
              -0.9845880270004272,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347509741783142,
              -0.14517700672149658,
              0.3242590129375458,
              -0.9347209930419922,
              -0.12375299632549286,
              0.123478002846241,
              -0.9845880270004272,
              -0.8624839782714844,
              -0.5027620196342468,
              0.057710498571395874,
              -0.9348120093345642,
              -0.34464600682258606,
              0.08557389676570892,
              -0.8625450134277344,
              -0.5026400089263916,
              0.05774100124835968,
              -0.9348120093345642,
              -0.34464600682258606,
              0.08557389676570892,
              -0.9348120093345642,
              -0.34464600682258606,
              0.08557389676570892,
              -0.8625450134277344,
              -0.5026400089263916,
              0.05774100124835968,
              0.04974519833922386,
              -0.018982499837875366,
              0.9985659718513489,
              0.12372200191020966,
              -0.12338600307703018,
              0.9845880270004272,
              0.049806199967861176,
              -0.01904349960386753,
              0.9985659718513489,
              0.12372200191020966,
              -0.12338600307703018,
              0.9845880270004272,
              0.12375299632549286,
              -0.1234470009803772,
              0.9845880270004272,
              0.049806199967861176,
              -0.01904349960386753,
              0.9985659718513489,
              0.7225260138511658,
              0.6907860040664673,
              -0.02670370042324066,
              0.8625140190124512,
              0.5027009844779968,
              -0.057679999619722366,
              0.7225559949874878,
              0.6907560229301453,
              -0.02670370042324066,
              0.8625140190124512,
              0.5027009844779968,
              -0.057679999619722366,
              0.8625450134277344,
              0.5026699900627136,
              -0.057710498571395874,
              0.7225559949874878,
              0.6907560229301453,
              -0.02670370042324066,
              -0.04974519833922386,
              0.01895200088620186,
              -0.9985659718513489,
              -0.12375299632549286,
              0.1234470009803772,
              -0.9845880270004272,
              -0.04977570101618767,
              0.019013000652194023,
              -0.9985659718513489,
              -0.12375299632549286,
              0.1234470009803772,
              -0.9845880270004272,
              -0.12375299632549286,
              0.123478002846241,
              -0.9845880270004272,
              -0.04977570101618767,
              0.019013000652194023,
              -0.9985659718513489,
              -0.7225559949874878,
              -0.6907560229301453,
              0.026764700189232826,
              -0.8624839782714844,
              -0.5027620196342468,
              0.057710498571395874,
              -0.722616970539093,
              -0.6906949877738953,
              0.026764700189232826,
              -0.8624839782714844,
              -0.5027620196342468,
              0.057710498571395874,
              -0.8625450134277344,
              -0.5026400089263916,
              0.05774100124835968,
              -0.722616970539093,
              -0.6906949877738953,
              0.026764700189232826,
              -0.1156959980726242,
              0.127811998128891,
              0.9850149750709534,
              0.04974519833922386,
              -0.018982499837875366,
              0.9985659718513489,
              -0.115665003657341,
              0.127811998128891,
              0.9850149750709534,
              0.04974519833922386,
              -0.018982499837875366,
              0.9985659718513489,
              0.049806199967861176,
              -0.01904349960386753,
              0.9985659718513489,
              -0.115665003657341,
              0.127811998128891,
              0.9850149750709534,
              0.6312140226364136,
              0.7747120261192322,
              -0.03695790097117424,
              0.7225260138511658,
              0.6907860040664673,
              -0.02670370042324066,
              0.6312450170516968,
              0.7746819853782654,
              -0.03695790097117424,
              0.7225260138511658,
              0.6907860040664673,
              -0.02670370042324066,
              0.7225559949874878,
              0.6907560229301453,
              -0.02670370042324066,
              0.6312450170516968,
              0.7746819853782654,
              -0.03695790097117424,
              0.11575700342655182,
              -0.1278730034828186,
              -0.9849849939346313,
              -0.04974519833922386,
              0.01895200088620186,
              -0.9985659718513489,
              0.1156959980726242,
              -0.127811998128891,
              -0.9850149750709534,
              -0.04974519833922386,
              0.01895200088620186,
              -0.9985659718513489,
              -0.04977570101618767,
              0.019013000652194023,
              -0.9985659718513489,
              0.1156959980726242,
              -0.127811998128891,
              -0.9850149750709534,
              -0.6312450170516968,
              -0.7746819853782654,
              0.037018999457359314,
              -0.7225559949874878,
              -0.6907560229301453,
              0.026764700189232826,
              -0.6312749981880188,
              -0.7746509909629822,
              0.037018999457359314,
              -0.7225559949874878,
              -0.6907560229301453,
              0.026764700189232826,
              -0.722616970539093,
              -0.6906949877738953,
              0.026764700189232826,
              -0.6312749981880188,
              -0.7746509909629822,
              0.037018999457359314,
              -0.48200899362564087,
              0.3456830084323883,
              0.8050780296325684,
              -0.1156959980726242,
              0.127811998128891,
              0.9850149750709534,
              -0.48197901248931885,
              0.3456830084323883,
              0.8050780296325684,
              -0.1156959980726242,
              0.127811998128891,
              0.9850149750709534,
              -0.115665003657341,
              0.127811998128891,
              0.9850149750709534,
              -0.48197901248931885,
              0.3456830084323883,
              0.8050780296325684,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.6312140226364136,
              0.7747120261192322,
              -0.03695790097117424,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.6312140226364136,
              0.7747120261192322,
              -0.03695790097117424,
              0.6312450170516968,
              0.7746819853782654,
              -0.03695790097117424,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.48200899362564087,
              -0.3457140028476715,
              -0.8050479888916016,
              0.11575700342655182,
              -0.1278730034828186,
              -0.9849849939346313,
              0.48197901248931885,
              -0.3456830084323883,
              -0.8050780296325684,
              0.11575700342655182,
              -0.1278730034828186,
              -0.9849849939346313,
              0.1156959980726242,
              -0.127811998128891,
              -0.9850149750709534,
              0.48197901248931885,
              -0.3456830084323883,
              -0.8050780296325684,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.6312450170516968,
              -0.7746819853782654,
              0.037018999457359314,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.6312450170516968,
              -0.7746819853782654,
              0.037018999457359314,
              -0.6312749981880188,
              -0.7746509909629822,
              0.037018999457359314,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.8384349942207336,
              0.41639500856399536,
              0.35154300928115845,
              -0.48200899362564087,
              0.3456830084323883,
              0.8050780296325684,
              -0.8384659886360168,
              0.41639500856399536,
              0.35148200392723083,
              -0.48200899362564087,
              0.3456830084323883,
              0.8050780296325684,
              -0.48197901248931885,
              0.3456830084323883,
              0.8050780296325684,
              -0.8384659886360168,
              0.41639500856399536,
              0.35148200392723083,
              0.3578909933567047,
              0.8869590163230896,
              -0.2918179929256439,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.3579210042953491,
              0.8869590163230896,
              -0.2917569875717163,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.49632298946380615,
              0.859798014163971,
              -0.11993800103664398,
              0.3579210042953491,
              0.8869590163230896,
              -0.2917569875717163,
              0.8384039998054504,
              -0.4165169894695282,
              -0.35148200392723083,
              0.48200899362564087,
              -0.3457140028476715,
              -0.8050479888916016,
              0.8384349942207336,
              -0.4165169894695282,
              -0.3514209985733032,
              0.48200899362564087,
              -0.3457140028476715,
              -0.8050479888916016,
              0.48197901248931885,
              -0.3456830084323883,
              -0.8050780296325684,
              0.8384349942207336,
              -0.4165169894695282,
              -0.3514209985733032,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.49623098969459534,
              -0.859827995300293,
              0.1199679970741272,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03933839872479439,
              -0.8384349942207336,
              0.41639500856399536,
              0.35154300928115845,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03930779919028282,
              -0.8384349942207336,
              0.41639500856399536,
              0.35154300928115845,
              -0.8384659886360168,
              0.41639500856399536,
              0.35148200392723083,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03930779919028282,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.3578909933567047,
              0.8869590163230896,
              -0.2918179929256439,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.3578909933567047,
              0.8869590163230896,
              -0.2918179929256439,
              0.3579210042953491,
              0.8869590163230896,
              -0.2917569875717163,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.9500110149383545,
              -0.3096410036087036,
              0.03946039825677872,
              0.8384039998054504,
              -0.4165169894695282,
              -0.35148200392723083,
              0.9500110149383545,
              -0.3096410036087036,
              0.03942989930510521,
              0.8384039998054504,
              -0.4165169894695282,
              -0.35148200392723083,
              0.8384349942207336,
              -0.4165169894695282,
              -0.3514209985733032,
              0.9500110149383545,
              -0.3096410036087036,
              0.03942989930510521,
              -0.3016749918460846,
              -0.8457589745521545,
              0.4400460124015808,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.3016749918460846,
              -0.8457589745521545,
              0.440077006816864,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.3578299880027771,
              -0.8869900107383728,
              0.2917869985103607,
              -0.3016749918460846,
              -0.8457589745521545,
              0.440077006816864,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03933839872479439,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03933839872479439,
              -0.9500719904899597,
              0.3094879984855652,
              -0.03930779919028282,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              0.2970370054244995,
              0.8080080151557922,
              -0.508804976940155,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.2970370054244995,
              0.8080080151557922,
              -0.5087739825248718,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.301613986492157,
              0.8457589745521545,
              -0.4400460124015808,
              0.2970370054244995,
              0.8080080151557922,
              -0.5087739825248718,
              0.9530630111694336,
              -0.21454499661922455,
              0.21359899640083313,
              0.9500110149383545,
              -0.3096410036087036,
              0.03946039825677872,
              0.9530630111694336,
              -0.21451500058174133,
              0.21363000571727753,
              0.9500110149383545,
              -0.3096410036087036,
              0.03946039825677872,
              0.9500110149383545,
              -0.3096410036087036,
              0.03942989930510521,
              0.9530630111694336,
              -0.21451500058174133,
              0.21363000571727753,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.3016749918460846,
              -0.8457589745521545,
              0.4400460124015808,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.3016749918460846,
              -0.8457589745521545,
              0.4400460124015808,
              -0.3016749918460846,
              -0.8457589745521545,
              0.440077006816864,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.9452800154685974,
              0.16513599455356598,
              -0.2812890112400055,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              -0.9452800154685974,
              0.16510500013828278,
              -0.2813499867916107,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              -0.9531239867210388,
              0.2143310010433197,
              -0.21350699663162231,
              -0.9452800154685974,
              0.16510500013828278,
              -0.2813499867916107,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.2970370054244995,
              0.8080080151557922,
              -0.508804976940155,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.2970370054244995,
              0.8080080151557922,
              -0.508804976940155,
              0.2970370054244995,
              0.8080080151557922,
              -0.5087739825248718,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.9452499747276306,
              -0.1652580052614212,
              0.2813810110092163,
              0.9530630111694336,
              -0.21454499661922455,
              0.21359899640083313,
              0.9452499747276306,
              -0.1652269959449768,
              0.28141099214553833,
              0.9530630111694336,
              -0.21454499661922455,
              0.21359899640083313,
              0.9530630111694336,
              -0.21451500058174133,
              0.21363000571727753,
              0.9452499747276306,
              -0.1652269959449768,
              0.28141099214553833,
              -0.2978610098361969,
              -0.7893610000610352,
              0.5367900133132935,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.2978610098361969,
              -0.7893310189247131,
              0.5368210077285767,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.29718899726867676,
              -0.8079779744148254,
              0.5087130069732666,
              -0.2978610098361969,
              -0.7893310189247131,
              0.5368210077285767,
              -0.9356060028076172,
              0.22165599465370178,
              -0.27466699481010437,
              -0.9452800154685974,
              0.16513599455356598,
              -0.2812890112400055,
              -0.9356060028076172,
              0.2218089997768402,
              -0.27463600039482117,
              -0.9452800154685974,
              0.16513599455356598,
              -0.2812890112400055,
              -0.9452800154685974,
              0.16510500013828278,
              -0.2813499867916107,
              -0.9356060028076172,
              0.2218089997768402,
              -0.27463600039482117,
              0.2970370054244995,
              0.7840510010719299,
              -0.5449690222740173,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.2970370054244995,
              0.7840510010719299,
              -0.5449690222740173,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.2977690100669861,
              0.7893310189247131,
              -0.5368819832801819,
              0.2970370054244995,
              0.7840510010719299,
              -0.5449690222740173,
              0.9356359839439392,
              -0.22165599465370178,
              0.27457499504089355,
              0.9452499747276306,
              -0.1652580052614212,
              0.2813810110092163,
              0.9356359839439392,
              -0.2218089997768402,
              0.27451398968696594,
              0.9452499747276306,
              -0.1652580052614212,
              0.2813810110092163,
              0.9452499747276306,
              -0.1652269959449768,
              0.28141099214553833,
              0.9356359839439392,
              -0.2218089997768402,
              0.27451398968696594,
              -0.29706698656082153,
              -0.7840510010719299,
              0.5449690222740173,
              -0.2978610098361969,
              -0.7893610000610352,
              0.5367900133132935,
              -0.29706698656082153,
              -0.7840510010719299,
              0.5449690222740173,
              -0.2978610098361969,
              -0.7893610000610352,
              0.5367900133132935,
              -0.2978610098361969,
              -0.7893310189247131,
              0.5368210077285767,
              -0.29706698656082153,
              -0.7840510010719299,
              0.5449690222740173,
              -0.9232760071754456,
              0.2927030026912689,
              -0.2487259954214096,
              -0.9356060028076172,
              0.22165599465370178,
              -0.27466699481010437,
              -0.9232760071754456,
              0.2927030026912689,
              -0.2487259954214096,
              -0.9356060028076172,
              0.22165599465370178,
              -0.27466699481010437,
              -0.9356060028076172,
              0.2218089997768402,
              -0.27463600039482117,
              -0.9232760071754456,
              0.2927030026912689,
              -0.2487259954214096,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.13330499827861786,
              -0.3625290095806122,
              -0.9223610162734985,
              0.9233070015907288,
              -0.29276400804519653,
              0.24848200380802155,
              0.9356359839439392,
              -0.22165599465370178,
              0.27457499504089355,
              0.9233070015907288,
              -0.29276400804519653,
              0.24848200380802155,
              0.9356359839439392,
              -0.22165599465370178,
              0.27457499504089355,
              0.9356359839439392,
              -0.2218089997768402,
              0.27451398968696594,
              0.9233070015907288,
              -0.29276400804519653,
              0.24848200380802155,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.13333499431610107,
              0.3625290095806122,
              0.9223610162734985,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              -0.3126010000705719,
              -0.9430220127105713,
              0.11377300322055817,
              0.36027100682258606,
              0.8847320079803467,
              -0.2956329882144928,
              0.3600879907608032,
              0.8847320079803467,
              -0.29587700963020325,
              0.36027100682258606,
              0.8847320079803467,
              -0.2956329882144928,
              0.3604539930820465,
              0.8847619891166687,
              -0.29538899660110474,
              0.36027100682258606,
              0.8847320079803467,
              -0.2956329882144928,
              0.36027100682258606,
              0.8847320079803467,
              -0.2956329882144928
            ],
            "normalized": false
          },
          "color": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1
            ],
            "normalized": false
          },
          "uv": {
            "itemSize": 2,
            "type": "Float32Array",
            "array": [
              0.9513000249862671,
              0,
              0.9513000249862671,
              0.5,
              0.5281000137329102,
              0,
              0.9513000249862671,
              0.5,
              0.5281000137329102,
              0.5,
              0.5281000137329102,
              0,
              0.47189998626708984,
              0,
              0.47189998626708984,
              0.5,
              0.04390000179409981,
              0,
              0.47189998626708984,
              0.5,
              0.04390000179409981,
              0.5,
              0.04390000179409981,
              0,
              0.953000009059906,
              0.6586999893188477,
              0.5277000069618225,
              0.6586999893188477,
              0.9513000249862671,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.5281000137329102,
              0.5,
              0.9513000249862671,
              0.5,
              0.47189998626708984,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.04390000179409981,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.04309999942779541,
              0.6586999893188477,
              0.04390000179409981,
              0.5,
              0.048700001090765,
              0,
              0.47189998626708984,
              0,
              0.048700001090765,
              0.5,
              0.47189998626708984,
              0,
              0.47189998626708984,
              0.5,
              0.048700001090765,
              0.5,
              0.5281000137329102,
              0,
              0.9560999870300293,
              0,
              0.5281000137329102,
              0.5,
              0.9560999870300293,
              0,
              0.9560999870300293,
              0.5,
              0.5281000137329102,
              0.5,
              0.47189998626708984,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.048700001090765,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.04699999839067459,
              0.6586999893188477,
              0.048700001090765,
              0.5,
              0.5281000137329102,
              0.5,
              0.9560999870300293,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.9560999870300293,
              0.5,
              0.9569000005722046,
              0.6586999893188477,
              0.5277000069618225,
              0.6586999893188477,
              0.048700001090765,
              0,
              0.47189998626708984,
              0,
              0,
              0.857699990272522,
              0.47189998626708984,
              0,
              0.47189998626708984,
              1,
              0,
              0.857699990272522,
              0.47189998626708984,
              1,
              0.048700001090765,
              1,
              0,
              0.857699990272522,
              0,
              0.14229999482631683,
              0.048700001090765,
              0,
              0,
              0.857699990272522,
              0.5281000137329102,
              0,
              0.9560999870300293,
              0,
              0.9560999870300293,
              1,
              0.9560999870300293,
              0,
              1,
              0.14229999482631683,
              0.9560999870300293,
              1,
              1,
              0.14229999482631683,
              1,
              0.857699990272522,
              0.9560999870300293,
              1,
              0.5281000137329102,
              0,
              0.9560999870300293,
              1,
              0.5281000137329102,
              1,
              0,
              0.14229999482631683,
              0.5,
              0.14229999482631683,
              0,
              0.857699990272522,
              0.5,
              0.14229999482631683,
              0.5,
              0.857699990272522,
              0,
              0.857699990272522,
              0.6586999893188477,
              0.16060000658035278,
              0.6586999893188477,
              0.8393999934196472,
              0.5,
              0.14229999482631683,
              0.6586999893188477,
              0.8393999934196472,
              0.5,
              0.857699990272522,
              0.5,
              0.14229999482631683,
              0.04050000011920929,
              0,
              0.4731999933719635,
              0,
              0,
              0.857699990272522,
              0.4731999933719635,
              0,
              0.4731999933719635,
              1,
              0,
              0.857699990272522,
              0.4731999933719635,
              1,
              0.04050000011920929,
              1,
              0,
              0.857699990272522,
              0,
              0.14229999482631683,
              0.04050000011920929,
              0,
              0,
              0.857699990272522,
              0.5267999768257141,
              0,
              0.9557999968528748,
              0,
              0.9557999968528748,
              1,
              0.9557999968528748,
              0,
              1,
              0.14229999482631683,
              0.9557999968528748,
              1,
              1,
              0.14229999482631683,
              1,
              0.857699990272522,
              0.9557999968528748,
              1,
              0.5267999768257141,
              0,
              0.9557999968528748,
              1,
              0.5267999768257141,
              1,
              0.16899999976158142,
              0.14229999482631683,
              0.16899999976158142,
              0.857699990272522,
              0,
              0.14229999482631683,
              0.16899999976158142,
              0.857699990272522,
              0,
              0.857699990272522,
              0,
              0.14229999482631683,
              0.5,
              0.14229999482631683,
              1,
              0.14229999482631683,
              0.5,
              0.857699990272522,
              1,
              0.14229999482631683,
              1,
              0.857699990272522,
              0.5,
              0.857699990272522,
              0.5094000101089478,
              0,
              0.5281000137329102,
              0,
              0.5094000101089478,
              0.5,
              0.5281000137329102,
              0,
              0.5281000137329102,
              0.5,
              0.5094000101089478,
              0.5,
              0.5267999768257141,
              1,
              0.508899986743927,
              1,
              0.5271999835968018,
              0.8309999704360962,
              0.508899986743927,
              1,
              0.5091000199317932,
              0.8309999704360962,
              0.5271999835968018,
              0.8309999704360962,
              0.49059998989105225,
              0.5,
              0.47189998626708984,
              0.5,
              0.49059998989105225,
              0,
              0.47189998626708984,
              0.5,
              0.47189998626708984,
              0,
              0.49059998989105225,
              0,
              0.47189998626708984,
              0.5,
              0.49059998989105225,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.49059998989105225,
              0.5,
              0.49079999327659607,
              0.6586999893188477,
              0.4722999930381775,
              0.6586999893188477,
              0.49059998989105225,
              1,
              0.47189998626708984,
              1,
              0.49059998989105225,
              0,
              0.47189998626708984,
              1,
              0.47189998626708984,
              0,
              0.49059998989105225,
              0,
              0.491100013256073,
              1,
              0.4731999933719635,
              1,
              0.491100013256073,
              0,
              0.4731999933719635,
              1,
              0.4731999933719635,
              0,
              0.491100013256073,
              0,
              0.49059998989105225,
              0,
              0.5094000101089478,
              0,
              0.49059998989105225,
              0.5,
              0.5094000101089478,
              0,
              0.5094000101089478,
              0.5,
              0.49059998989105225,
              0.5,
              0.47189998626708984,
              0,
              0.49059998989105225,
              0,
              0.47189998626708984,
              0.5,
              0.49059998989105225,
              0,
              0.49059998989105225,
              0.5,
              0.47189998626708984,
              0.5,
              0.491100013256073,
              1,
              0.4909000098705292,
              0.8309999704360962,
              0.508899986743927,
              1,
              0.4909000098705292,
              0.8309999704360962,
              0.5091000199317932,
              0.8309999704360962,
              0.508899986743927,
              1,
              0.4731999933719635,
              1,
              0.47279998660087585,
              0.8309999704360962,
              0.491100013256073,
              1,
              0.47279998660087585,
              0.8309999704360962,
              0.4909000098705292,
              0.8309999704360962,
              0.491100013256073,
              1,
              0.5094000101089478,
              0.5,
              0.49059998989105225,
              0.5,
              0.5094000101089478,
              0,
              0.49059998989105225,
              0.5,
              0.49059998989105225,
              0,
              0.5094000101089478,
              0,
              0.5281000137329102,
              0.5,
              0.5094000101089478,
              0.5,
              0.5281000137329102,
              0,
              0.5094000101089478,
              0.5,
              0.5094000101089478,
              0,
              0.5281000137329102,
              0,
              0.49059998989105225,
              0.5,
              0.5094000101089478,
              0.5,
              0.49079999327659607,
              0.6586999893188477,
              0.5094000101089478,
              0.5,
              0.5091999769210815,
              0.6586999893188477,
              0.49079999327659607,
              0.6586999893188477,
              0.5281000137329102,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.5094000101089478,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.5091999769210815,
              0.6586999893188477,
              0.5094000101089478,
              0.5,
              0.5094000101089478,
              1,
              0.49059998989105225,
              1,
              0.5094000101089478,
              0,
              0.49059998989105225,
              1,
              0.49059998989105225,
              0,
              0.5094000101089478,
              0,
              0.5281000137329102,
              1,
              0.5094000101089478,
              1,
              0.5281000137329102,
              0,
              0.5094000101089478,
              1,
              0.5094000101089478,
              0,
              0.5281000137329102,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.5077000260353088,
              1,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.49230000376701355,
              0,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.7821000218391418,
              0.5267999768257141,
              1,
              0.508899986743927,
              1,
              0.5267999768257141,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.5267999768257141,
              0,
              0.9589999914169312,
              0.8309999704360962,
              0.5271999835968018,
              0.8309999704360962,
              0.9606999754905701,
              0.7114999890327454,
              0.5271999835968018,
              0.8309999704360962,
              0.5274999737739563,
              0.7114999890327454,
              0.9606999754905701,
              0.7114999890327454,
              0.4724999964237213,
              0.7114999890327454,
              0.47279998660087585,
              0.8309999704360962,
              0.03629999980330467,
              0.7114999890327454,
              0.47279998660087585,
              0.8309999704360962,
              0.03759999945759773,
              0.8309999704360962,
              0.03629999980330467,
              0.7114999890327454,
              0.4724999964237213,
              0.7114999890327454,
              0.47279998660087585,
              0.8309999704360962,
              0.03929999843239784,
              0.7114999890327454,
              0.47279998660087585,
              0.8309999704360962,
              0.04100000113248825,
              0.8309999704360962,
              0.03929999843239784,
              0.7114999890327454,
              0.9624000191688538,
              0.8309999704360962,
              0.5271999835968018,
              0.8309999704360962,
              0.963699996471405,
              0.7114999890327454,
              0.5271999835968018,
              0.8309999704360962,
              0.5274999737739563,
              0.7114999890327454,
              0.963699996471405,
              0.7114999890327454,
              0.8309999704360962,
              0.14229999482631683,
              0.8309999704360962,
              0.857699990272522,
              0.7114999890327454,
              0.14229999482631683,
              0.8309999704360962,
              0.857699990272522,
              0.7114999890327454,
              0.857699990272522,
              0.7114999890327454,
              0.14229999482631683,
              0.33079999685287476,
              0.14229999482631683,
              0.33079999685287476,
              0.857699990272522,
              0.28850001096725464,
              0.14229999482631683,
              0.33079999685287476,
              0.857699990272522,
              0.28850001096725464,
              0.857699990272522,
              0.28850001096725464,
              0.14229999482631683,
              0.5091999769210815,
              0.7114999890327454,
              0.5091999769210815,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.5091999769210815,
              0.6692000031471252,
              0.5275999903678894,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.49079999327659607,
              0.7114999890327454,
              0.49079999327659607,
              0.6692000031471252,
              0.5091999769210815,
              0.7114999890327454,
              0.49079999327659607,
              0.6692000031471252,
              0.5091999769210815,
              0.6692000031471252,
              0.5091999769210815,
              0.7114999890327454,
              0.4724000096321106,
              0.6692000031471252,
              0.49079999327659607,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.49079999327659607,
              0.6692000031471252,
              0.49079999327659607,
              0.7114999890327454,
              0.4724999964237213,
              0.7114999890327454,
              0.28850001096725464,
              0.14229999482631683,
              0.28850001096725464,
              0.857699990272522,
              0.16899999976158142,
              0.14229999482631683,
              0.28850001096725464,
              0.857699990272522,
              0.16899999976158142,
              0.857699990272522,
              0.16899999976158142,
              0.14229999482631683,
              0.9557999968528748,
              1,
              0.5267999768257141,
              1,
              0.9589999914169312,
              0.8309999704360962,
              0.5267999768257141,
              1,
              0.5271999835968018,
              0.8309999704360962,
              0.9589999914169312,
              0.8309999704360962,
              0.47279998660087585,
              0.8309999704360962,
              0.4731999933719635,
              1,
              0.03759999945759773,
              0.8309999704360962,
              0.4731999933719635,
              1,
              0.04050000011920929,
              1,
              0.03759999945759773,
              0.8309999704360962,
              0.47279998660087585,
              0.8309999704360962,
              0.4731999933719635,
              1,
              0.04100000113248825,
              0.8309999704360962,
              0.4731999933719635,
              1,
              0.044199999421834946,
              1,
              0.04100000113248825,
              0.8309999704360962,
              0.9595000147819519,
              1,
              0.5267999768257141,
              1,
              0.9624000191688538,
              0.8309999704360962,
              0.5267999768257141,
              1,
              0.5271999835968018,
              0.8309999704360962,
              0.9624000191688538,
              0.8309999704360962,
              1,
              0.14229999482631683,
              1,
              0.857699990272522,
              0.8309999704360962,
              0.14229999482631683,
              1,
              0.857699990272522,
              0.8309999704360962,
              0.857699990272522,
              0.8309999704360962,
              0.14229999482631683,
              0.4909000098705292,
              0.8309999704360962,
              0.491100013256073,
              1,
              0.47279998660087585,
              0.8309999704360962,
              0.491100013256073,
              1,
              0.4731999933719635,
              1,
              0.47279998660087585,
              0.8309999704360962,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.5091000199317932,
              0.8309999704360962,
              0.491100013256073,
              1,
              0.4909000098705292,
              0.8309999704360962,
              0.5091000199317932,
              0.8309999704360962,
              0.5267999768257141,
              1,
              0.508899986743927,
              1,
              0.5271999835968018,
              0.8309999704360962,
              0.508899986743927,
              1,
              0.5091000199317932,
              0.8309999704360962,
              0.5271999835968018,
              0.8309999704360962,
              0.5267999768257141,
              1,
              0.5094000101089478,
              0,
              0.47189998626708984,
              0.5,
              0.5094000101089478,
              0,
              0.5094000101089478,
              0.5,
              0.47189998626708984,
              0.5,
              0.47189998626708984,
              0.5,
              0.5094000101089478,
              0.5,
              0.47189998626708984,
              1,
              0.5094000101089478,
              0.5,
              0.49059998989105225,
              0.5,
              0.47189998626708984,
              1,
              0.5281000137329102,
              0,
              1,
              0.14229999482631683,
              0.47189998626708984,
              0.5,
              1,
              0.14229999482631683,
              0.5,
              0.14229999482631683,
              0.47189998626708984,
              0.5,
              0.47189998626708984,
              0.5,
              0.5,
              0.14229999482631683,
              0.4731999933719635,
              1,
              0.5,
              0.14229999482631683,
              0,
              0.14229999482631683,
              0.4731999933719635,
              1,
              0.4731999933719635,
              1,
              0,
              0.14229999482631683,
              0.49059998989105225,
              0.5,
              0,
              0.14229999482631683,
              0.47189998626708984,
              1,
              0.49059998989105225,
              0.5,
              0.5094000101089478,
              0,
              0.5267999768257141,
              1,
              0.49059998989105225,
              0,
              0.5267999768257141,
              1,
              0.4731999933719635,
              1,
              0.49059998989105225,
              0,
              0.49059998989105225,
              0,
              0.4731999933719635,
              1,
              0.47189998626708984,
              0,
              0.4731999933719635,
              1,
              0,
              0.857699990272522,
              0.47189998626708984,
              0,
              0.47189998626708984,
              0,
              0,
              0.857699990272522,
              0.5281000137329102,
              0,
              0,
              0.857699990272522,
              1,
              0.14229999482631683,
              0.5281000137329102,
              0,
              0.9588000178337097,
              0.6692000031471252,
              0.5275999903678894,
              0.6692000031471252,
              0.953000009059906,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.5277000069618225,
              0.6586999893188477,
              0.953000009059906,
              0.6586999893188477,
              0.4722999930381775,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.04309999942779541,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.03779999911785126,
              0.6692000031471252,
              0.04309999942779541,
              0.6586999893188477,
              0.4722999930381775,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.04699999839067459,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.041200000792741776,
              0.6692000031471252,
              0.04699999839067459,
              0.6586999893188477,
              0.9621999859809875,
              0.6692000031471252,
              0.5275999903678894,
              0.6692000031471252,
              0.9569000005722046,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.5277000069618225,
              0.6586999893188477,
              0.9569000005722046,
              0.6586999893188477,
              0.6692000031471252,
              0.14229999482631683,
              0.6692000031471252,
              0.857699990272522,
              0.6586999893188477,
              0.16060000658035278,
              0.6692000031471252,
              0.857699990272522,
              0.6586999893188477,
              0.8393999934196472,
              0.6586999893188477,
              0.16060000658035278,
              0.49079999327659607,
              0.6692000031471252,
              0.4724000096321106,
              0.6692000031471252,
              0.49079999327659607,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.4722999930381775,
              0.6586999893188477,
              0.49079999327659607,
              0.6586999893188477,
              0.5091999769210815,
              0.6692000031471252,
              0.49079999327659607,
              0.6692000031471252,
              0.5091999769210815,
              0.6586999893188477,
              0.49079999327659607,
              0.6692000031471252,
              0.49079999327659607,
              0.6586999893188477,
              0.5091999769210815,
              0.6586999893188477,
              0.5277000069618225,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.5091999769210815,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.5091999769210815,
              0.6692000031471252,
              0.5091999769210815,
              0.6586999893188477,
              0.5,
              0.14229999482631683,
              0.5,
              0.857699990272522,
              0.34130001068115234,
              0.16060000658035278,
              0.5,
              0.857699990272522,
              0.34130001068115234,
              0.8393999934196472,
              0.34130001068115234,
              0.16060000658035278,
              0.5091999769210815,
              0.6586999893188477,
              0.5094000101089478,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.5094000101089478,
              0.5,
              0.5281000137329102,
              0.5,
              0.5277000069618225,
              0.6586999893188477,
              0.49079999327659607,
              0.6586999893188477,
              0.49059998989105225,
              0.5,
              0.5091999769210815,
              0.6586999893188477,
              0.49059998989105225,
              0.5,
              0.5094000101089478,
              0.5,
              0.5091999769210815,
              0.6586999893188477,
              0.47189998626708984,
              0.5,
              0.49059998989105225,
              0.5,
              0.4722999930381775,
              0.6586999893188477,
              0.49059998989105225,
              0.5,
              0.49079999327659607,
              0.6586999893188477,
              0.4722999930381775,
              0.6586999893188477,
              0.34130001068115234,
              0.16060000658035278,
              0.34130001068115234,
              0.8393999934196472,
              0.33079999685287476,
              0.14229999482631683,
              0.34130001068115234,
              0.8393999934196472,
              0.33079999685287476,
              0.857699990272522,
              0.33079999685287476,
              0.14229999482631683,
              0.5091999769210815,
              0.6692000031471252,
              0.5091999769210815,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.5091999769210815,
              0.6586999893188477,
              0.5277000069618225,
              0.6586999893188477,
              0.5275999903678894,
              0.6692000031471252,
              0.49079999327659607,
              0.6692000031471252,
              0.49079999327659607,
              0.6586999893188477,
              0.5091999769210815,
              0.6692000031471252,
              0.49079999327659607,
              0.6586999893188477,
              0.5091999769210815,
              0.6586999893188477,
              0.5091999769210815,
              0.6692000031471252,
              0.4722999930381775,
              0.6586999893188477,
              0.49079999327659607,
              0.6586999893188477,
              0.4724000096321106,
              0.6692000031471252,
              0.49079999327659607,
              0.6586999893188477,
              0.49079999327659607,
              0.6692000031471252,
              0.4724000096321106,
              0.6692000031471252,
              0.9606999754905701,
              0.7114999890327454,
              0.5274999737739563,
              0.7114999890327454,
              0.9588000178337097,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.5275999903678894,
              0.6692000031471252,
              0.9588000178337097,
              0.6692000031471252,
              0.4724000096321106,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.03779999911785126,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.03629999980330467,
              0.7114999890327454,
              0.03779999911785126,
              0.6692000031471252,
              0.4724000096321106,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.041200000792741776,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.03929999843239784,
              0.7114999890327454,
              0.041200000792741776,
              0.6692000031471252,
              0.963699996471405,
              0.7114999890327454,
              0.5274999737739563,
              0.7114999890327454,
              0.9621999859809875,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.5275999903678894,
              0.6692000031471252,
              0.9621999859809875,
              0.6692000031471252,
              0.7114999890327454,
              0.14229999482631683,
              0.7114999890327454,
              0.857699990272522,
              0.6692000031471252,
              0.14229999482631683,
              0.7114999890327454,
              0.857699990272522,
              0.6692000031471252,
              0.857699990272522,
              0.6692000031471252,
              0.14229999482631683,
              0.49079999327659607,
              0.7114999890327454,
              0.4724999964237213,
              0.7114999890327454,
              0.49079999327659607,
              0.6692000031471252,
              0.4724999964237213,
              0.7114999890327454,
              0.4724000096321106,
              0.6692000031471252,
              0.49079999327659607,
              0.6692000031471252,
              0.5091999769210815,
              0.7114999890327454,
              0.49079999327659607,
              0.7114999890327454,
              0.5091999769210815,
              0.6692000031471252,
              0.49079999327659607,
              0.7114999890327454,
              0.49079999327659607,
              0.6692000031471252,
              0.5091999769210815,
              0.6692000031471252,
              0.5275999903678894,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.5091999769210815,
              0.6692000031471252,
              0.5274999737739563,
              0.7114999890327454,
              0.5091999769210815,
              0.7114999890327454,
              0.5091999769210815,
              0.6692000031471252,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.491100013256073,
              0,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              0,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.508899986743927,
              0,
              0.491100013256073,
              0,
              0.508899986743927,
              0,
              0.508899986743927,
              0,
              0.491100013256073,
              0,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              0,
              0.491100013256073,
              1,
              0.508899986743927,
              0,
              0.508899986743927,
              0,
              0.491100013256073,
              0,
              0.508899986743927,
              0,
              0.5077000260353088,
              0,
              0.491100013256073,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0,
              0.491100013256073,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              0,
              0.508899986743927,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0,
              0.508899986743927,
              0,
              0.491100013256073,
              1,
              0.491100013256073,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              1,
              0.49230000376701355,
              1,
              0.508899986743927,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              1,
              0.508899986743927,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              0,
              0.491100013256073,
              1,
              0.491100013256073,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              1,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              1,
              0.491100013256073,
              1,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.508899986743927,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.491100013256073,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.49230000376701355,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              0,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.49230000376701355,
              0,
              0.49230000376701355,
              0,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.5077000260353088,
              0,
              0.5077000260353088,
              0,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.21789999306201935,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.508899986743927,
              0.7821000218391418,
              0.508899986743927,
              0.7821000218391418,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.5077000260353088,
              1,
              0.5077000260353088,
              1,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.49230000376701355,
              1,
              0.49230000376701355,
              1,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.7821000218391418,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              0.491100013256073,
              0.21789999306201935,
              0.491100013256073,
              0.21789999306201935,
              0.49230000376701355,
              0,
              1,
              0.08659999817609787,
              1,
              0.14229999482631683,
              0.5,
              0.08659999817609787,
              1,
              0.14229999482631683,
              0.5,
              0.14229999482631683,
              0.5,
              0.08659999817609787,
              0.5,
              0.07689999788999557,
              0.5,
              0.14229999482631683,
              0,
              0.07689999788999557,
              0.5,
              0.14229999482631683,
              0,
              0.14229999482631683,
              0,
              0.07689999788999557,
              0.49790000915527344,
              0.08720000088214874,
              0.5,
              0.14229999482631683,
              0.3361999988555908,
              0.08560000360012054,
              0.5,
              0.14229999482631683,
              0.34130001068115234,
              0.16060000658035278,
              0.3361999988555908,
              0.08560000360012054,
              1.0052000284194946,
              0.07079999893903732,
              1,
              0.14229999482631683,
              0.8324000239372253,
              0.06480000168085098,
              1,
              0.14229999482631683,
              0.8309999704360962,
              0.14229999482631683,
              0.8324000239372253,
              0.06480000168085098,
              0.5,
              0.9133999943733215,
              0.5,
              0.857699990272522,
              1,
              0.9133999943733215,
              0.5,
              0.857699990272522,
              1,
              0.857699990272522,
              1,
              0.9133999943733215,
              0,
              0.9230999946594238,
              0,
              0.857699990272522,
              0.5,
              0.9230999946594238,
              0,
              0.857699990272522,
              0.5,
              0.857699990272522,
              0.5,
              0.9230999946594238,
              -0.00570000009611249,
              0.9215999841690063,
              0,
              0.857699990272522,
              0.16740000247955322,
              0.9283000230789185,
              0,
              0.857699990272522,
              0.16899999976158142,
              0.857699990272522,
              0.16740000247955322,
              0.9283000230789185,
              0.5022000074386597,
              0.9225000143051147,
              0.5,
              0.857699990272522,
              0.6636999845504761,
              0.9223999977111816,
              0.5,
              0.857699990272522,
              0.6586999893188477,
              0.8393999934196472,
              0.6636999845504761,
              0.9223999977111816,
              0.28459998965263367,
              0.9311000108718872,
              0.28850001096725464,
              0.857699990272522,
              0.32739999890327454,
              0.9276000261306763,
              0.28850001096725464,
              0.857699990272522,
              0.33079999685287476,
              0.857699990272522,
              0.32739999890327454,
              0.9276000261306763,
              0.28459998965263367,
              0.0689999982714653,
              0.28850001096725464,
              0.14229999482631683,
              0.16740000247955322,
              0.07169999927282333,
              0.28850001096725464,
              0.14229999482631683,
              0.16899999976158142,
              0.14229999482631683,
              0.16740000247955322,
              0.07169999927282333,
              0.7150999903678894,
              0.06260000169277191,
              0.7114999890327454,
              0.14229999482631683,
              0.6722999811172485,
              0.06549999862909317,
              0.7114999890327454,
              0.14229999482631683,
              0.6692000031471252,
              0.14229999482631683,
              0.6722999811172485,
              0.06549999862909317,
              0.7150999903678894,
              0.9373999834060669,
              0.7114999890327454,
              0.857699990272522,
              0.8324999809265137,
              0.9351999759674072,
              0.7114999890327454,
              0.857699990272522,
              0.8309999704360962,
              0.857699990272522,
              0.8324999809265137,
              0.9351999759674072,
              0.16740000247955322,
              0.9283000230789185,
              0.16899999976158142,
              0.857699990272522,
              0.28459998965263367,
              0.9309999942779541,
              0.16899999976158142,
              0.857699990272522,
              0.28850001096725464,
              0.857699990272522,
              0.28459998965263367,
              0.9309999942779541,
              0.16740000247955322,
              0.07169999927282333,
              0.16899999976158142,
              0.14229999482631683,
              -0.00570000009611249,
              0.07840000092983246,
              0.16899999976158142,
              0.14229999482631683,
              0,
              0.14229999482631683,
              -0.00570000009611249,
              0.07840000092983246,
              0.8324999809265137,
              0.06480000168085098,
              0.8309999704360962,
              0.14229999482631683,
              0.7150999903678894,
              0.06260000169277191,
              0.8309999704360962,
              0.14229999482631683,
              0.7114999890327454,
              0.14229999482631683,
              0.7150999903678894,
              0.06260000169277191,
              0.8324000239372253,
              0.9351999759674072,
              0.8309999704360962,
              0.857699990272522,
              1.0052000284194946,
              0.9291999936103821,
              0.8309999704360962,
              0.857699990272522,
              1,
              0.857699990272522,
              1.0052000284194946,
              0.9291999936103821,
              0.3361999988555908,
              0.9143999814987183,
              0.34130001068115234,
              0.8393999934196472,
              0.49790000915527344,
              0.9128000140190125,
              0.34130001068115234,
              0.8393999934196472,
              0.5,
              0.857699990272522,
              0.49790000915527344,
              0.9128000140190125,
              0.3465000092983246,
              0.09589999914169312,
              0.34130001068115234,
              0.16060000658035278,
              0.335999995470047,
              0.08349999785423279,
              0.34130001068115234,
              0.16060000658035278,
              0.33079999685287476,
              0.14229999482631683,
              0.335999995470047,
              0.08349999785423279,
              0.6636999845504761,
              0.07760000228881836,
              0.6586999893188477,
              0.16060000658035278,
              0.5022000074386597,
              0.07750000059604645,
              0.6586999893188477,
              0.16060000658035278,
              0.5,
              0.14229999482631683,
              0.5022000074386597,
              0.07750000059604645,
              0.6536999940872192,
              0.911899983882904,
              0.6586999893188477,
              0.8393999934196472,
              0.6643000245094299,
              0.9232000112533569,
              0.6586999893188477,
              0.8393999934196472,
              0.6692000031471252,
              0.857699990272522,
              0.6643000245094299,
              0.9232000112533569,
              0.335999995470047,
              0.9164999723434448,
              0.33079999685287476,
              0.857699990272522,
              0.3465000092983246,
              0.9041000008583069,
              0.33079999685287476,
              0.857699990272522,
              0.34130001068115234,
              0.8393999934196472,
              0.3465000092983246,
              0.9041000008583069,
              0.32739999890327454,
              0.07240000367164612,
              0.33079999685287476,
              0.14229999482631683,
              0.28459998965263367,
              0.06889999657869339,
              0.33079999685287476,
              0.14229999482631683,
              0.28850001096725464,
              0.14229999482631683,
              0.28459998965263367,
              0.06889999657869339,
              0.6643000245094299,
              0.07680000364780426,
              0.6692000031471252,
              0.14229999482631683,
              0.6536999940872192,
              0.08810000121593475,
              0.6692000031471252,
              0.14229999482631683,
              0.6586999893188477,
              0.16060000658035278,
              0.6536999940872192,
              0.08810000121593475,
              0.6722999811172485,
              0.934499979019165,
              0.6692000031471252,
              0.857699990272522,
              0.7150999903678894,
              0.9373999834060669,
              0.6692000031471252,
              0.857699990272522,
              0.7114999890327454,
              0.857699990272522,
              0.7150999903678894,
              0.9373999834060669,
              0.16670000553131104,
              0.013799999840557575,
              0.16670000553131104,
              0,
              0,
              0.013799999840557575,
              0.16670000553131104,
              0,
              0,
              0,
              0,
              0.013799999840557575,
              0.16670000553131104,
              0,
              0.16670000553131104,
              0.013799999840557575,
              0.33329999446868896,
              0,
              0.16670000553131104,
              0.013799999840557575,
              0.33329999446868896,
              0.013799999840557575,
              0.33329999446868896,
              0,
              0.5,
              0.013799999840557575,
              0.5,
              0,
              0.33329999446868896,
              0.013799999840557575,
              0.5,
              0,
              0.33329999446868896,
              0,
              0.33329999446868896,
              0.013799999840557575,
              0.666700005531311,
              0.013799999840557575,
              0.666700005531311,
              0,
              0.5,
              0.013799999840557575,
              0.666700005531311,
              0,
              0.5,
              0,
              0.5,
              0.013799999840557575,
              0.833299994468689,
              0.013799999840557575,
              0.833299994468689,
              0,
              0.666700005531311,
              0.013799999840557575,
              0.833299994468689,
              0,
              0.666700005531311,
              0,
              0.666700005531311,
              0.013799999840557575,
              1,
              0.013799999840557575,
              1,
              0,
              0.833299994468689,
              0.013799999840557575,
              1,
              0,
              0.833299994468689,
              0,
              0.833299994468689,
              0.013799999840557575,
              0.16670000553131104,
              0.03020000085234642,
              0.16670000553131104,
              0.013799999840557575,
              0,
              0.03020000085234642,
              0.16670000553131104,
              0.013799999840557575,
              0,
              0.013799999840557575,
              0,
              0.03020000085234642,
              0.16670000553131104,
              0.013799999840557575,
              0.16670000553131104,
              0.03020000085234642,
              0.33329999446868896,
              0.013799999840557575,
              0.16670000553131104,
              0.03020000085234642,
              0.33329999446868896,
              0.03020000085234642,
              0.33329999446868896,
              0.013799999840557575,
              0.5,
              0.03020000085234642,
              0.5,
              0.013799999840557575,
              0.33329999446868896,
              0.03020000085234642,
              0.5,
              0.013799999840557575,
              0.33329999446868896,
              0.013799999840557575,
              0.33329999446868896,
              0.03020000085234642,
              0.666700005531311,
              0.03020000085234642,
              0.666700005531311,
              0.013799999840557575,
              0.5,
              0.03020000085234642,
              0.666700005531311,
              0.013799999840557575,
              0.5,
              0.013799999840557575,
              0.5,
              0.03020000085234642,
              0.833299994468689,
              0.03020000085234642,
              0.833299994468689,
              0.013799999840557575,
              0.666700005531311,
              0.03020000085234642,
              0.833299994468689,
              0.013799999840557575,
              0.666700005531311,
              0.013799999840557575,
              0.666700005531311,
              0.03020000085234642,
              1,
              0.03020000085234642,
              1,
              0.013799999840557575,
              0.833299994468689,
              0.03020000085234642,
              1,
              0.013799999840557575,
              0.833299994468689,
              0.013799999840557575,
              0.833299994468689,
              0.03020000085234642,
              0.16670000553131104,
              0.048900000751018524,
              0.16670000553131104,
              0.03020000085234642,
              0,
              0.048900000751018524,
              0.16670000553131104,
              0.03020000085234642,
              0,
              0.03020000085234642,
              0,
              0.048900000751018524,
              0.33329999446868896,
              0.048900000751018524,
              0.33329999446868896,
              0.03020000085234642,
              0.16670000553131104,
              0.048900000751018524,
              0.33329999446868896,
              0.03020000085234642,
              0.16670000553131104,
              0.03020000085234642,
              0.16670000553131104,
              0.048900000751018524,
              0.5,
              0.048900000751018524,
              0.5,
              0.03020000085234642,
              0.33329999446868896,
              0.048900000751018524,
              0.5,
              0.03020000085234642,
              0.33329999446868896,
              0.03020000085234642,
              0.33329999446868896,
              0.048900000751018524,
              0.666700005531311,
              0.048900000751018524,
              0.666700005531311,
              0.03020000085234642,
              0.5,
              0.048900000751018524,
              0.666700005531311,
              0.03020000085234642,
              0.5,
              0.03020000085234642,
              0.5,
              0.048900000751018524,
              0.833299994468689,
              0.048900000751018524,
              0.833299994468689,
              0.03020000085234642,
              0.666700005531311,
              0.048900000751018524,
              0.833299994468689,
              0.03020000085234642,
              0.666700005531311,
              0.03020000085234642,
              0.666700005531311,
              0.048900000751018524,
              1,
              0.048900000751018524,
              1,
              0.03020000085234642,
              0.833299994468689,
              0.048900000751018524,
              1,
              0.03020000085234642,
              0.833299994468689,
              0.03020000085234642,
              0.833299994468689,
              0.048900000751018524,
              0.16670000553131104,
              0.06970000267028809,
              0.16670000553131104,
              0.048900000751018524,
              0,
              0.06970000267028809,
              0.16670000553131104,
              0.048900000751018524,
              0,
              0.048900000751018524,
              0,
              0.06970000267028809,
              0.33329999446868896,
              0.06970000267028809,
              0.33329999446868896,
              0.048900000751018524,
              0.16670000553131104,
              0.06970000267028809,
              0.33329999446868896,
              0.048900000751018524,
              0.16670000553131104,
              0.048900000751018524,
              0.16670000553131104,
              0.06970000267028809,
              0.5,
              0.06970000267028809,
              0.5,
              0.048900000751018524,
              0.33329999446868896,
              0.06970000267028809,
              0.5,
              0.048900000751018524,
              0.33329999446868896,
              0.048900000751018524,
              0.33329999446868896,
              0.06970000267028809,
              0.666700005531311,
              0.06970000267028809,
              0.666700005531311,
              0.048900000751018524,
              0.5,
              0.06970000267028809,
              0.666700005531311,
              0.048900000751018524,
              0.5,
              0.048900000751018524,
              0.5,
              0.06970000267028809,
              0.833299994468689,
              0.06970000267028809,
              0.833299994468689,
              0.048900000751018524,
              0.666700005531311,
              0.06970000267028809,
              0.833299994468689,
              0.048900000751018524,
              0.666700005531311,
              0.048900000751018524,
              0.666700005531311,
              0.06970000267028809,
              1,
              0.06970000267028809,
              1,
              0.048900000751018524,
              0.833299994468689,
              0.06970000267028809,
              1,
              0.048900000751018524,
              0.833299994468689,
              0.048900000751018524,
              0.833299994468689,
              0.06970000267028809,
              0.16670000553131104,
              0.09220000356435776,
              0.16670000553131104,
              0.06970000267028809,
              0,
              0.09220000356435776,
              0.16670000553131104,
              0.06970000267028809,
              0,
              0.06970000267028809,
              0,
              0.09220000356435776,
              0.33329999446868896,
              0.09220000356435776,
              0.33329999446868896,
              0.06970000267028809,
              0.16670000553131104,
              0.09220000356435776,
              0.33329999446868896,
              0.06970000267028809,
              0.16670000553131104,
              0.06970000267028809,
              0.16670000553131104,
              0.09220000356435776,
              0.5,
              0.09220000356435776,
              0.5,
              0.06970000267028809,
              0.33329999446868896,
              0.09220000356435776,
              0.5,
              0.06970000267028809,
              0.33329999446868896,
              0.06970000267028809,
              0.33329999446868896,
              0.09220000356435776,
              0.666700005531311,
              0.09220000356435776,
              0.666700005531311,
              0.06970000267028809,
              0.5,
              0.09220000356435776,
              0.666700005531311,
              0.06970000267028809,
              0.5,
              0.06970000267028809,
              0.5,
              0.09220000356435776,
              0.833299994468689,
              0.09220000356435776,
              0.833299994468689,
              0.06970000267028809,
              0.666700005531311,
              0.09220000356435776,
              0.833299994468689,
              0.06970000267028809,
              0.666700005531311,
              0.06970000267028809,
              0.666700005531311,
              0.09220000356435776,
              1,
              0.09220000356435776,
              1,
              0.06970000267028809,
              0.833299994468689,
              0.09220000356435776,
              1,
              0.06970000267028809,
              0.833299994468689,
              0.06970000267028809,
              0.833299994468689,
              0.09220000356435776,
              0.16670000553131104,
              0.11569999903440475,
              0.16670000553131104,
              0.09220000356435776,
              0,
              0.11569999903440475,
              0.16670000553131104,
              0.09220000356435776,
              0,
              0.09220000356435776,
              0,
              0.11569999903440475,
              0.33329999446868896,
              0.11569999903440475,
              0.33329999446868896,
              0.09220000356435776,
              0.16670000553131104,
              0.11569999903440475,
              0.33329999446868896,
              0.09220000356435776,
              0.16670000553131104,
              0.09220000356435776,
              0.16670000553131104,
              0.11569999903440475,
              0.5,
              0.11569999903440475,
              0.5,
              0.09220000356435776,
              0.33329999446868896,
              0.11569999903440475,
              0.5,
              0.09220000356435776,
              0.33329999446868896,
              0.09220000356435776,
              0.33329999446868896,
              0.11569999903440475,
              0.666700005531311,
              0.11569999903440475,
              0.666700005531311,
              0.09220000356435776,
              0.5,
              0.11569999903440475,
              0.666700005531311,
              0.09220000356435776,
              0.5,
              0.09220000356435776,
              0.5,
              0.11569999903440475,
              0.833299994468689,
              0.11569999903440475,
              0.833299994468689,
              0.09220000356435776,
              0.666700005531311,
              0.11569999903440475,
              0.833299994468689,
              0.09220000356435776,
              0.666700005531311,
              0.09220000356435776,
              0.666700005531311,
              0.11569999903440475,
              1,
              0.11569999903440475,
              1,
              0.09220000356435776,
              0.833299994468689,
              0.11569999903440475,
              1,
              0.09220000356435776,
              0.833299994468689,
              0.09220000356435776,
              0.833299994468689,
              0.11569999903440475,
              0.16670000553131104,
              0.13979999721050262,
              0.16670000553131104,
              0.11569999903440475,
              0,
              0.13979999721050262,
              0.16670000553131104,
              0.11569999903440475,
              0,
              0.11569999903440475,
              0,
              0.13979999721050262,
              0.33329999446868896,
              0.13979999721050262,
              0.33329999446868896,
              0.11569999903440475,
              0.16670000553131104,
              0.13979999721050262,
              0.33329999446868896,
              0.11569999903440475,
              0.16670000553131104,
              0.11569999903440475,
              0.16670000553131104,
              0.13979999721050262,
              0.5,
              0.13979999721050262,
              0.5,
              0.11569999903440475,
              0.33329999446868896,
              0.13979999721050262,
              0.5,
              0.11569999903440475,
              0.33329999446868896,
              0.11569999903440475,
              0.33329999446868896,
              0.13979999721050262,
              0.666700005531311,
              0.13979999721050262,
              0.666700005531311,
              0.11569999903440475,
              0.5,
              0.13979999721050262,
              0.666700005531311,
              0.11569999903440475,
              0.5,
              0.11569999903440475,
              0.5,
              0.13979999721050262,
              0.833299994468689,
              0.13979999721050262,
              0.833299994468689,
              0.11569999903440475,
              0.666700005531311,
              0.13979999721050262,
              0.833299994468689,
              0.11569999903440475,
              0.666700005531311,
              0.11569999903440475,
              0.666700005531311,
              0.13979999721050262,
              1,
              0.13979999721050262,
              1,
              0.11569999903440475,
              0.833299994468689,
              0.13979999721050262,
              1,
              0.11569999903440475,
              0.833299994468689,
              0.11569999903440475,
              0.833299994468689,
              0.13979999721050262,
              0.16670000553131104,
              0.27469998598098755,
              0.16670000553131104,
              0.13979999721050262,
              0,
              0.27469998598098755,
              0.16670000553131104,
              0.13979999721050262,
              0,
              0.13979999721050262,
              0,
              0.27469998598098755,
              0.33329999446868896,
              0.27469998598098755,
              0.33329999446868896,
              0.13979999721050262,
              0.16670000553131104,
              0.27469998598098755,
              0.33329999446868896,
              0.13979999721050262,
              0.16670000553131104,
              0.13979999721050262,
              0.16670000553131104,
              0.27469998598098755,
              0.5,
              0.27469998598098755,
              0.5,
              0.13979999721050262,
              0.33329999446868896,
              0.27469998598098755,
              0.5,
              0.13979999721050262,
              0.33329999446868896,
              0.13979999721050262,
              0.33329999446868896,
              0.27469998598098755,
              0.666700005531311,
              0.27469998598098755,
              0.666700005531311,
              0.13979999721050262,
              0.5,
              0.27469998598098755,
              0.666700005531311,
              0.13979999721050262,
              0.5,
              0.13979999721050262,
              0.5,
              0.27469998598098755,
              0.833299994468689,
              0.27469998598098755,
              0.833299994468689,
              0.13979999721050262,
              0.666700005531311,
              0.27469998598098755,
              0.833299994468689,
              0.13979999721050262,
              0.666700005531311,
              0.13979999721050262,
              0.666700005531311,
              0.27469998598098755,
              1,
              0.27469998598098755,
              1,
              0.13979999721050262,
              0.833299994468689,
              0.27469998598098755,
              1,
              0.13979999721050262,
              0.833299994468689,
              0.13979999721050262,
              0.833299994468689,
              0.27469998598098755,
              0.16670000553131104,
              0.913100004196167,
              0.16670000553131104,
              0.27469998598098755,
              0,
              0.913100004196167,
              0.16670000553131104,
              0.27469998598098755,
              0,
              0.27469998598098755,
              0,
              0.913100004196167,
              0.33329999446868896,
              0.913100004196167,
              0.33329999446868896,
              0.27469998598098755,
              0.16670000553131104,
              0.913100004196167,
              0.33329999446868896,
              0.27469998598098755,
              0.16670000553131104,
              0.27469998598098755,
              0.16670000553131104,
              0.913100004196167,
              0.5,
              0.913100004196167,
              0.5,
              0.27469998598098755,
              0.33329999446868896,
              0.913100004196167,
              0.5,
              0.27469998598098755,
              0.33329999446868896,
              0.27469998598098755,
              0.33329999446868896,
              0.913100004196167,
              0.666700005531311,
              0.913100004196167,
              0.666700005531311,
              0.27469998598098755,
              0.5,
              0.913100004196167,
              0.666700005531311,
              0.27469998598098755,
              0.5,
              0.27469998598098755,
              0.5,
              0.913100004196167,
              0.833299994468689,
              0.913100004196167,
              0.833299994468689,
              0.27469998598098755,
              0.666700005531311,
              0.913100004196167,
              0.833299994468689,
              0.27469998598098755,
              0.666700005531311,
              0.27469998598098755,
              0.666700005531311,
              0.913100004196167,
              1,
              0.913100004196167,
              1,
              0.27469998598098755,
              0.833299994468689,
              0.913100004196167,
              1,
              0.27469998598098755,
              0.833299994468689,
              0.27469998598098755,
              0.833299994468689,
              0.913100004196167,
              0.16670000553131104,
              0.9323999881744385,
              0.16670000553131104,
              0.913100004196167,
              0,
              0.9323999881744385,
              0.16670000553131104,
              0.913100004196167,
              0,
              0.913100004196167,
              0,
              0.9323999881744385,
              0.33329999446868896,
              0.9323999881744385,
              0.33329999446868896,
              0.913100004196167,
              0.16670000553131104,
              0.9323999881744385,
              0.33329999446868896,
              0.913100004196167,
              0.16670000553131104,
              0.913100004196167,
              0.16670000553131104,
              0.9323999881744385,
              0.5,
              0.9323999881744385,
              0.5,
              0.913100004196167,
              0.33329999446868896,
              0.9323999881744385,
              0.5,
              0.913100004196167,
              0.33329999446868896,
              0.913100004196167,
              0.33329999446868896,
              0.9323999881744385,
              0.666700005531311,
              0.9323999881744385,
              0.666700005531311,
              0.913100004196167,
              0.5,
              0.9323999881744385,
              0.666700005531311,
              0.913100004196167,
              0.5,
              0.913100004196167,
              0.5,
              0.9323999881744385,
              0.833299994468689,
              0.9323999881744385,
              0.833299994468689,
              0.913100004196167,
              0.666700005531311,
              0.9323999881744385,
              0.833299994468689,
              0.913100004196167,
              0.666700005531311,
              0.913100004196167,
              0.666700005531311,
              0.9323999881744385,
              1,
              0.9323999881744385,
              1,
              0.913100004196167,
              0.833299994468689,
              0.9323999881744385,
              1,
              0.913100004196167,
              0.833299994468689,
              0.913100004196167,
              0.833299994468689,
              0.9323999881744385,
              0.16670000553131104,
              1,
              0.16670000553131104,
              0.9323999881744385,
              0,
              1,
              0.16670000553131104,
              0.9323999881744385,
              0,
              0.9323999881744385,
              0,
              1,
              0.33329999446868896,
              1,
              0.33329999446868896,
              0.9323999881744385,
              0.16670000553131104,
              1,
              0.33329999446868896,
              0.9323999881744385,
              0.16670000553131104,
              0.9323999881744385,
              0.16670000553131104,
              1,
              0.5,
              1,
              0.5,
              0.9323999881744385,
              0.33329999446868896,
              1,
              0.5,
              0.9323999881744385,
              0.33329999446868896,
              0.9323999881744385,
              0.33329999446868896,
              1,
              0.666700005531311,
              1,
              0.666700005531311,
              0.9323999881744385,
              0.5,
              1,
              0.666700005531311,
              0.9323999881744385,
              0.5,
              0.9323999881744385,
              0.5,
              1,
              0.833299994468689,
              1,
              0.833299994468689,
              0.9323999881744385,
              0.666700005531311,
              1,
              0.833299994468689,
              0.9323999881744385,
              0.666700005531311,
              0.9323999881744385,
              0.666700005531311,
              1,
              1,
              1,
              1,
              0.9323999881744385,
              0.833299994468689,
              1,
              1,
              0.9323999881744385,
              0.833299994468689,
              0.9323999881744385,
              0.833299994468689,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.16670000553131104,
              0.08370000123977661,
              0.16670000553131104,
              0,
              0,
              0.08370000123977661,
              0.16670000553131104,
              0,
              0,
              0,
              0,
              0.08370000123977661,
              0.33329999446868896,
              0.08370000123977661,
              0.33329999446868896,
              0,
              0.16670000553131104,
              0.08370000123977661,
              0.33329999446868896,
              0,
              0.16670000553131104,
              0,
              0.16670000553131104,
              0.08370000123977661,
              0.5,
              0.08370000123977661,
              0.5,
              0,
              0.33329999446868896,
              0.08370000123977661,
              0.5,
              0,
              0.33329999446868896,
              0,
              0.33329999446868896,
              0.08370000123977661,
              0.666700005531311,
              0.08370000123977661,
              0.666700005531311,
              0,
              0.5,
              0.08370000123977661,
              0.666700005531311,
              0,
              0.5,
              0,
              0.5,
              0.08370000123977661,
              0.833299994468689,
              0.08370000123977661,
              0.833299994468689,
              0,
              0.666700005531311,
              0.08370000123977661,
              0.833299994468689,
              0,
              0.666700005531311,
              0,
              0.666700005531311,
              0.08370000123977661,
              1,
              0.08370000123977661,
              1,
              0,
              0.833299994468689,
              0.08370000123977661,
              1,
              0,
              0.833299994468689,
              0,
              0.833299994468689,
              0.08370000123977661,
              0.16670000553131104,
              0.14300000667572021,
              0.16670000553131104,
              0.08370000123977661,
              0,
              0.14300000667572021,
              0.16670000553131104,
              0.08370000123977661,
              0,
              0.08370000123977661,
              0,
              0.14300000667572021,
              0.33329999446868896,
              0.14300000667572021,
              0.33329999446868896,
              0.08370000123977661,
              0.16670000553131104,
              0.14300000667572021,
              0.33329999446868896,
              0.08370000123977661,
              0.16670000553131104,
              0.08370000123977661,
              0.16670000553131104,
              0.14300000667572021,
              0.5,
              0.14300000667572021,
              0.5,
              0.08370000123977661,
              0.33329999446868896,
              0.14300000667572021,
              0.5,
              0.08370000123977661,
              0.33329999446868896,
              0.08370000123977661,
              0.33329999446868896,
              0.14300000667572021,
              0.666700005531311,
              0.14300000667572021,
              0.666700005531311,
              0.08370000123977661,
              0.5,
              0.14300000667572021,
              0.666700005531311,
              0.08370000123977661,
              0.5,
              0.08370000123977661,
              0.5,
              0.14300000667572021,
              0.833299994468689,
              0.14300000667572021,
              0.833299994468689,
              0.08370000123977661,
              0.666700005531311,
              0.14300000667572021,
              0.833299994468689,
              0.08370000123977661,
              0.666700005531311,
              0.08370000123977661,
              0.666700005531311,
              0.14300000667572021,
              1,
              0.14300000667572021,
              1,
              0.08370000123977661,
              0.833299994468689,
              0.14300000667572021,
              1,
              0.08370000123977661,
              0.833299994468689,
              0.08370000123977661,
              0.833299994468689,
              0.14300000667572021,
              0.16670000553131104,
              0.18170000612735748,
              0.16670000553131104,
              0.14300000667572021,
              0,
              0.18170000612735748,
              0.16670000553131104,
              0.14300000667572021,
              0,
              0.14300000667572021,
              0,
              0.18170000612735748,
              0.33329999446868896,
              0.18170000612735748,
              0.33329999446868896,
              0.14300000667572021,
              0.16670000553131104,
              0.18170000612735748,
              0.33329999446868896,
              0.14300000667572021,
              0.16670000553131104,
              0.14300000667572021,
              0.16670000553131104,
              0.18170000612735748,
              0.5,
              0.18170000612735748,
              0.5,
              0.14300000667572021,
              0.33329999446868896,
              0.18170000612735748,
              0.5,
              0.14300000667572021,
              0.33329999446868896,
              0.14300000667572021,
              0.33329999446868896,
              0.18170000612735748,
              0.666700005531311,
              0.18170000612735748,
              0.666700005531311,
              0.14300000667572021,
              0.5,
              0.18170000612735748,
              0.666700005531311,
              0.14300000667572021,
              0.5,
              0.14300000667572021,
              0.5,
              0.18170000612735748,
              0.833299994468689,
              0.18170000612735748,
              0.833299994468689,
              0.14300000667572021,
              0.666700005531311,
              0.18170000612735748,
              0.833299994468689,
              0.14300000667572021,
              0.666700005531311,
              0.14300000667572021,
              0.666700005531311,
              0.18170000612735748,
              1,
              0.18170000612735748,
              1,
              0.14300000667572021,
              0.833299994468689,
              0.18170000612735748,
              1,
              0.14300000667572021,
              0.833299994468689,
              0.14300000667572021,
              0.833299994468689,
              0.18170000612735748,
              0.16670000553131104,
              0.20409999787807465,
              0.16670000553131104,
              0.18170000612735748,
              0,
              0.20409999787807465,
              0.16670000553131104,
              0.18170000612735748,
              0,
              0.18170000612735748,
              0,
              0.20409999787807465,
              0.33329999446868896,
              0.20409999787807465,
              0.33329999446868896,
              0.18170000612735748,
              0.16670000553131104,
              0.20409999787807465,
              0.33329999446868896,
              0.18170000612735748,
              0.16670000553131104,
              0.18170000612735748,
              0.16670000553131104,
              0.20409999787807465,
              0.5,
              0.20409999787807465,
              0.5,
              0.18170000612735748,
              0.33329999446868896,
              0.20409999787807465,
              0.5,
              0.18170000612735748,
              0.33329999446868896,
              0.18170000612735748,
              0.33329999446868896,
              0.20409999787807465,
              0.666700005531311,
              0.20409999787807465,
              0.666700005531311,
              0.18170000612735748,
              0.5,
              0.20409999787807465,
              0.666700005531311,
              0.18170000612735748,
              0.5,
              0.18170000612735748,
              0.5,
              0.20409999787807465,
              0.833299994468689,
              0.20409999787807465,
              0.833299994468689,
              0.18170000612735748,
              0.666700005531311,
              0.20409999787807465,
              0.833299994468689,
              0.18170000612735748,
              0.666700005531311,
              0.18170000612735748,
              0.666700005531311,
              0.20409999787807465,
              1,
              0.20409999787807465,
              1,
              0.18170000612735748,
              0.833299994468689,
              0.20409999787807465,
              1,
              0.18170000612735748,
              0.833299994468689,
              0.18170000612735748,
              0.833299994468689,
              0.20409999787807465,
              0.16670000553131104,
              0.2143000066280365,
              0.16670000553131104,
              0.20409999787807465,
              0,
              0.2143000066280365,
              0.16670000553131104,
              0.20409999787807465,
              0,
              0.20409999787807465,
              0,
              0.2143000066280365,
              0.33329999446868896,
              0.2143000066280365,
              0.33329999446868896,
              0.20409999787807465,
              0.16670000553131104,
              0.2143000066280365,
              0.33329999446868896,
              0.20409999787807465,
              0.16670000553131104,
              0.20409999787807465,
              0.16670000553131104,
              0.2143000066280365,
              0.5,
              0.2143000066280365,
              0.5,
              0.20409999787807465,
              0.33329999446868896,
              0.2143000066280365,
              0.5,
              0.20409999787807465,
              0.33329999446868896,
              0.20409999787807465,
              0.33329999446868896,
              0.2143000066280365,
              0.666700005531311,
              0.2143000066280365,
              0.666700005531311,
              0.20409999787807465,
              0.5,
              0.2143000066280365,
              0.666700005531311,
              0.20409999787807465,
              0.5,
              0.20409999787807465,
              0.5,
              0.2143000066280365,
              0.833299994468689,
              0.2143000066280365,
              0.833299994468689,
              0.20409999787807465,
              0.666700005531311,
              0.2143000066280365,
              0.833299994468689,
              0.20409999787807465,
              0.666700005531311,
              0.20409999787807465,
              0.666700005531311,
              0.2143000066280365,
              1,
              0.2143000066280365,
              1,
              0.20409999787807465,
              0.833299994468689,
              0.2143000066280365,
              1,
              0.20409999787807465,
              0.833299994468689,
              0.20409999787807465,
              0.833299994468689,
              0.2143000066280365,
              0.16670000553131104,
              0.21649999916553497,
              0.16670000553131104,
              0.2143000066280365,
              0,
              0.21649999916553497,
              0.16670000553131104,
              0.2143000066280365,
              0,
              0.2143000066280365,
              0,
              0.21649999916553497,
              0.33329999446868896,
              0.21649999916553497,
              0.33329999446868896,
              0.2143000066280365,
              0.16670000553131104,
              0.21649999916553497,
              0.33329999446868896,
              0.2143000066280365,
              0.16670000553131104,
              0.2143000066280365,
              0.16670000553131104,
              0.21649999916553497,
              0.5,
              0.21649999916553497,
              0.5,
              0.2143000066280365,
              0.33329999446868896,
              0.21649999916553497,
              0.5,
              0.2143000066280365,
              0.33329999446868896,
              0.2143000066280365,
              0.33329999446868896,
              0.21649999916553497,
              0.666700005531311,
              0.21649999916553497,
              0.666700005531311,
              0.2143000066280365,
              0.5,
              0.21649999916553497,
              0.666700005531311,
              0.2143000066280365,
              0.5,
              0.2143000066280365,
              0.5,
              0.21649999916553497,
              0.833299994468689,
              0.21649999916553497,
              0.833299994468689,
              0.2143000066280365,
              0.666700005531311,
              0.21649999916553497,
              0.833299994468689,
              0.2143000066280365,
              0.666700005531311,
              0.2143000066280365,
              0.666700005531311,
              0.21649999916553497,
              1,
              0.21649999916553497,
              1,
              0.2143000066280365,
              0.833299994468689,
              0.21649999916553497,
              1,
              0.2143000066280365,
              0.833299994468689,
              0.2143000066280365,
              0.833299994468689,
              0.21649999916553497,
              0.16670000553131104,
              0.21889999508857727,
              0.16670000553131104,
              0.21649999916553497,
              0,
              0.21889999508857727,
              0.16670000553131104,
              0.21649999916553497,
              0,
              0.21649999916553497,
              0,
              0.21889999508857727,
              0.33329999446868896,
              0.21889999508857727,
              0.33329999446868896,
              0.21649999916553497,
              0.16670000553131104,
              0.21889999508857727,
              0.33329999446868896,
              0.21649999916553497,
              0.16670000553131104,
              0.21649999916553497,
              0.16670000553131104,
              0.21889999508857727,
              0.5,
              0.21889999508857727,
              0.5,
              0.21649999916553497,
              0.33329999446868896,
              0.21889999508857727,
              0.5,
              0.21649999916553497,
              0.33329999446868896,
              0.21649999916553497,
              0.33329999446868896,
              0.21889999508857727,
              0.666700005531311,
              0.21889999508857727,
              0.666700005531311,
              0.21649999916553497,
              0.5,
              0.21889999508857727,
              0.666700005531311,
              0.21649999916553497,
              0.5,
              0.21649999916553497,
              0.5,
              0.21889999508857727,
              0.833299994468689,
              0.21889999508857727,
              0.833299994468689,
              0.21649999916553497,
              0.666700005531311,
              0.21889999508857727,
              0.833299994468689,
              0.21649999916553497,
              0.666700005531311,
              0.21649999916553497,
              0.666700005531311,
              0.21889999508857727,
              1,
              0.21889999508857727,
              1,
              0.21649999916553497,
              0.833299994468689,
              0.21889999508857727,
              1,
              0.21649999916553497,
              0.833299994468689,
              0.21649999916553497,
              0.833299994468689,
              0.21889999508857727,
              0.16670000553131104,
              0.3463999927043915,
              0.16670000553131104,
              0.21889999508857727,
              0,
              0.3463999927043915,
              0.16670000553131104,
              0.21889999508857727,
              0,
              0.21889999508857727,
              0,
              0.3463999927043915,
              0.33329999446868896,
              0.3463999927043915,
              0.33329999446868896,
              0.21889999508857727,
              0.16670000553131104,
              0.3463999927043915,
              0.33329999446868896,
              0.21889999508857727,
              0.16670000553131104,
              0.21889999508857727,
              0.16670000553131104,
              0.3463999927043915,
              0.5,
              0.3463999927043915,
              0.5,
              0.21889999508857727,
              0.33329999446868896,
              0.3463999927043915,
              0.5,
              0.21889999508857727,
              0.33329999446868896,
              0.21889999508857727,
              0.33329999446868896,
              0.3463999927043915,
              0.666700005531311,
              0.3463999927043915,
              0.666700005531311,
              0.21889999508857727,
              0.5,
              0.3463999927043915,
              0.666700005531311,
              0.21889999508857727,
              0.5,
              0.21889999508857727,
              0.5,
              0.3463999927043915,
              0.833299994468689,
              0.3463999927043915,
              0.833299994468689,
              0.21889999508857727,
              0.666700005531311,
              0.3463999927043915,
              0.833299994468689,
              0.21889999508857727,
              0.666700005531311,
              0.21889999508857727,
              0.666700005531311,
              0.3463999927043915,
              1,
              0.3463999927043915,
              1,
              0.21889999508857727,
              0.833299994468689,
              0.3463999927043915,
              1,
              0.21889999508857727,
              0.833299994468689,
              0.21889999508857727,
              0.833299994468689,
              0.3463999927043915,
              0.16670000553131104,
              0.920799970626831,
              0.16670000553131104,
              0.3463999927043915,
              0,
              0.920799970626831,
              0.16670000553131104,
              0.3463999927043915,
              0,
              0.3463999927043915,
              0,
              0.920799970626831,
              0.33329999446868896,
              0.920799970626831,
              0.33329999446868896,
              0.3463999927043915,
              0.16670000553131104,
              0.920799970626831,
              0.33329999446868896,
              0.3463999927043915,
              0.16670000553131104,
              0.3463999927043915,
              0.16670000553131104,
              0.920799970626831,
              0.5,
              0.920799970626831,
              0.5,
              0.3463999927043915,
              0.33329999446868896,
              0.920799970626831,
              0.5,
              0.3463999927043915,
              0.33329999446868896,
              0.3463999927043915,
              0.33329999446868896,
              0.920799970626831,
              0.666700005531311,
              0.920799970626831,
              0.666700005531311,
              0.3463999927043915,
              0.5,
              0.920799970626831,
              0.666700005531311,
              0.3463999927043915,
              0.5,
              0.3463999927043915,
              0.5,
              0.920799970626831,
              0.833299994468689,
              0.920799970626831,
              0.833299994468689,
              0.3463999927043915,
              0.666700005531311,
              0.920799970626831,
              0.833299994468689,
              0.3463999927043915,
              0.666700005531311,
              0.3463999927043915,
              0.666700005531311,
              0.920799970626831,
              1,
              0.920799970626831,
              1,
              0.3463999927043915,
              0.833299994468689,
              0.920799970626831,
              1,
              0.3463999927043915,
              0.833299994468689,
              0.3463999927043915,
              0.833299994468689,
              0.920799970626831,
              0.16670000553131104,
              0.9362000226974487,
              0.16670000553131104,
              0.920799970626831,
              0,
              0.9362000226974487,
              0.16670000553131104,
              0.920799970626831,
              0,
              0.920799970626831,
              0,
              0.9362000226974487,
              0.33329999446868896,
              0.9362000226974487,
              0.33329999446868896,
              0.920799970626831,
              0.16670000553131104,
              0.9362000226974487,
              0.33329999446868896,
              0.920799970626831,
              0.16670000553131104,
              0.920799970626831,
              0.16670000553131104,
              0.9362000226974487,
              0.5,
              0.9362000226974487,
              0.5,
              0.920799970626831,
              0.33329999446868896,
              0.9362000226974487,
              0.5,
              0.920799970626831,
              0.33329999446868896,
              0.920799970626831,
              0.33329999446868896,
              0.9362000226974487,
              0.666700005531311,
              0.9362000226974487,
              0.666700005531311,
              0.920799970626831,
              0.5,
              0.9362000226974487,
              0.666700005531311,
              0.920799970626831,
              0.5,
              0.920799970626831,
              0.5,
              0.9362000226974487,
              0.833299994468689,
              0.9362000226974487,
              0.833299994468689,
              0.920799970626831,
              0.666700005531311,
              0.9362000226974487,
              0.833299994468689,
              0.920799970626831,
              0.666700005531311,
              0.920799970626831,
              0.666700005531311,
              0.9362000226974487,
              1,
              0.9362000226974487,
              1,
              0.920799970626831,
              0.833299994468689,
              0.9362000226974487,
              1,
              0.920799970626831,
              0.833299994468689,
              0.920799970626831,
              0.833299994468689,
              0.9362000226974487,
              0.16670000553131104,
              1,
              0.16670000553131104,
              0.9362000226974487,
              0,
              1,
              0.16670000553131104,
              0.9362000226974487,
              0,
              0.9362000226974487,
              0,
              1,
              0.33329999446868896,
              1,
              0.33329999446868896,
              0.9362000226974487,
              0.16670000553131104,
              1,
              0.33329999446868896,
              0.9362000226974487,
              0.16670000553131104,
              0.9362000226974487,
              0.16670000553131104,
              1,
              0.5,
              1,
              0.5,
              0.9362000226974487,
              0.33329999446868896,
              1,
              0.5,
              0.9362000226974487,
              0.33329999446868896,
              0.9362000226974487,
              0.33329999446868896,
              1,
              0.666700005531311,
              1,
              0.666700005531311,
              0.9362000226974487,
              0.5,
              1,
              0.666700005531311,
              0.9362000226974487,
              0.5,
              0.9362000226974487,
              0.5,
              1,
              0.833299994468689,
              1,
              0.833299994468689,
              0.9362000226974487,
              0.666700005531311,
              1,
              0.833299994468689,
              0.9362000226974487,
              0.666700005531311,
              0.9362000226974487,
              0.666700005531311,
              1,
              1,
              1,
              1,
              0.9362000226974487,
              0.833299994468689,
              1,
              1,
              0.9362000226974487,
              0.833299994468689,
              0.9362000226974487,
              0.833299994468689,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.875,
              0,
              0.75,
              0.0625,
              0.875,
              0,
              0.75,
              0.0625,
              0.75,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.75,
              0.125,
              0.875,
              0.0625,
              0.75,
              0.125,
              0.75,
              0.125,
              0.875,
              0.125,
              0.875,
              0.125,
              0.75,
              0.1875,
              0.875,
              0.125,
              0.75,
              0.1875,
              0.75,
              0.1875,
              0.875,
              0.1875,
              0.875,
              0.1875,
              0.75,
              0.25,
              0.875,
              0.1875,
              0.75,
              0.25,
              0.75,
              0.25,
              0.875,
              0.25,
              0.875,
              0.25,
              0.75,
              0.3125,
              0.875,
              0.25,
              0.75,
              0.3125,
              0.75,
              0.3125,
              0.875,
              0.3125,
              0.875,
              0.3125,
              0.75,
              0.375,
              0.875,
              0.3125,
              0.75,
              0.375,
              0.75,
              0.375,
              0.875,
              0.375,
              0.875,
              0.375,
              0.75,
              0.4375,
              0.875,
              0.375,
              0.75,
              0.4375,
              0.75,
              0.4375,
              0.875,
              0.4375,
              0.875,
              0.4375,
              0.75,
              0.5,
              0.875,
              0.4375,
              0.75,
              0.5,
              0.75,
              0.5,
              0.875,
              0.5,
              0.875,
              0.5,
              0.75,
              0.5625,
              0.875,
              0.5,
              0.75,
              0.5625,
              0.75,
              0.5625,
              0.875,
              0.5625,
              0.875,
              0.5625,
              0.75,
              0.625,
              0.875,
              0.5625,
              0.75,
              0.625,
              0.75,
              0.625,
              0.875,
              0.625,
              0.875,
              0.625,
              0.75,
              0.6875,
              0.875,
              0.625,
              0.75,
              0.6875,
              0.75,
              0.6875,
              0.875,
              0.6875,
              0.875,
              0.6875,
              0.75,
              0.75,
              0.875,
              0.6875,
              0.75,
              0.75,
              0.75,
              0.75,
              0.875,
              0.75,
              0.875,
              0.75,
              0.75,
              0.8125,
              0.875,
              0.75,
              0.75,
              0.8125,
              0.75,
              0.8125,
              0.875,
              0.8125,
              0.875,
              0.8125,
              0.75,
              0.875,
              0.875,
              0.8125,
              0.75,
              0.875,
              0.75,
              0.875,
              0.875,
              0.875,
              0.875,
              0.875,
              0.75,
              0.9375,
              0.875,
              0.875,
              0.75,
              0.9375,
              0.75,
              0.9375,
              0.875,
              1,
              0.75,
              1,
              0.875,
              0.9375,
              0.75,
              1,
              0.875,
              0.9375,
              0.875,
              0.9375,
              0.75,
              0,
              0.75,
              0,
              0.625,
              0.0625,
              0.75,
              0,
              0.625,
              0.0625,
              0.625,
              0.0625,
              0.75,
              0.0625,
              0.75,
              0.0625,
              0.625,
              0.125,
              0.75,
              0.0625,
              0.625,
              0.125,
              0.625,
              0.125,
              0.75,
              0.125,
              0.75,
              0.125,
              0.625,
              0.1875,
              0.75,
              0.125,
              0.625,
              0.1875,
              0.625,
              0.1875,
              0.75,
              0.1875,
              0.75,
              0.1875,
              0.625,
              0.25,
              0.75,
              0.1875,
              0.625,
              0.25,
              0.625,
              0.25,
              0.75,
              0.25,
              0.75,
              0.25,
              0.625,
              0.3125,
              0.75,
              0.25,
              0.625,
              0.3125,
              0.625,
              0.3125,
              0.75,
              0.3125,
              0.75,
              0.3125,
              0.625,
              0.375,
              0.75,
              0.3125,
              0.625,
              0.375,
              0.625,
              0.375,
              0.75,
              0.375,
              0.75,
              0.375,
              0.625,
              0.4375,
              0.75,
              0.375,
              0.625,
              0.4375,
              0.625,
              0.4375,
              0.75,
              0.4375,
              0.75,
              0.4375,
              0.625,
              0.5,
              0.75,
              0.4375,
              0.625,
              0.5,
              0.625,
              0.5,
              0.75,
              0.5,
              0.75,
              0.5,
              0.625,
              0.5625,
              0.75,
              0.5,
              0.625,
              0.5625,
              0.625,
              0.5625,
              0.75,
              0.5625,
              0.75,
              0.5625,
              0.625,
              0.625,
              0.75,
              0.5625,
              0.625,
              0.625,
              0.625,
              0.625,
              0.75,
              0.625,
              0.75,
              0.625,
              0.625,
              0.6875,
              0.75,
              0.625,
              0.625,
              0.6875,
              0.625,
              0.6875,
              0.75,
              0.6875,
              0.75,
              0.6875,
              0.625,
              0.75,
              0.75,
              0.6875,
              0.625,
              0.75,
              0.625,
              0.75,
              0.75,
              0.75,
              0.75,
              0.75,
              0.625,
              0.8125,
              0.75,
              0.75,
              0.625,
              0.8125,
              0.625,
              0.8125,
              0.75,
              0.8125,
              0.75,
              0.8125,
              0.625,
              0.875,
              0.75,
              0.8125,
              0.625,
              0.875,
              0.625,
              0.875,
              0.75,
              0.875,
              0.75,
              0.875,
              0.625,
              0.9375,
              0.75,
              0.875,
              0.625,
              0.9375,
              0.625,
              0.9375,
              0.75,
              0.9375,
              0.75,
              0.9375,
              0.625,
              1,
              0.75,
              0.9375,
              0.625,
              1,
              0.625,
              1,
              0.75,
              0,
              0.625,
              0,
              0.5,
              0.0625,
              0.625,
              0,
              0.5,
              0.0625,
              0.5,
              0.0625,
              0.625,
              0.0625,
              0.625,
              0.0625,
              0.5,
              0.125,
              0.625,
              0.0625,
              0.5,
              0.125,
              0.5,
              0.125,
              0.625,
              0.125,
              0.625,
              0.125,
              0.5,
              0.1875,
              0.625,
              0.125,
              0.5,
              0.1875,
              0.5,
              0.1875,
              0.625,
              0.1875,
              0.625,
              0.1875,
              0.5,
              0.25,
              0.625,
              0.1875,
              0.5,
              0.25,
              0.5,
              0.25,
              0.625,
              0.25,
              0.625,
              0.25,
              0.5,
              0.3125,
              0.625,
              0.25,
              0.5,
              0.3125,
              0.5,
              0.3125,
              0.625,
              0.3125,
              0.625,
              0.3125,
              0.5,
              0.375,
              0.625,
              0.3125,
              0.5,
              0.375,
              0.5,
              0.375,
              0.625,
              0.375,
              0.625,
              0.375,
              0.5,
              0.4375,
              0.625,
              0.375,
              0.5,
              0.4375,
              0.5,
              0.4375,
              0.625,
              0.4375,
              0.625,
              0.4375,
              0.5,
              0.5,
              0.625,
              0.4375,
              0.5,
              0.5,
              0.5,
              0.5,
              0.625,
              0.5,
              0.625,
              0.5,
              0.5,
              0.5625,
              0.625,
              0.5,
              0.5,
              0.5625,
              0.5,
              0.5625,
              0.625,
              0.5625,
              0.625,
              0.5625,
              0.5,
              0.625,
              0.625,
              0.5625,
              0.5,
              0.625,
              0.5,
              0.625,
              0.625,
              0.625,
              0.625,
              0.625,
              0.5,
              0.6875,
              0.625,
              0.625,
              0.5,
              0.6875,
              0.5,
              0.6875,
              0.625,
              0.6875,
              0.625,
              0.6875,
              0.5,
              0.75,
              0.625,
              0.6875,
              0.5,
              0.75,
              0.5,
              0.75,
              0.625,
              0.75,
              0.625,
              0.75,
              0.5,
              0.8125,
              0.625,
              0.75,
              0.5,
              0.8125,
              0.5,
              0.8125,
              0.625,
              0.8125,
              0.625,
              0.8125,
              0.5,
              0.875,
              0.625,
              0.8125,
              0.5,
              0.875,
              0.5,
              0.875,
              0.625,
              0.875,
              0.625,
              0.875,
              0.5,
              0.9375,
              0.625,
              0.875,
              0.5,
              0.9375,
              0.5,
              0.9375,
              0.625,
              0.9375,
              0.625,
              0.9375,
              0.5,
              1,
              0.625,
              0.9375,
              0.5,
              1,
              0.5,
              1,
              0.625,
              0,
              0.5,
              0,
              0.375,
              0.0625,
              0.5,
              0,
              0.375,
              0.0625,
              0.375,
              0.0625,
              0.5,
              0.0625,
              0.5,
              0.0625,
              0.375,
              0.125,
              0.5,
              0.0625,
              0.375,
              0.125,
              0.375,
              0.125,
              0.5,
              0.125,
              0.5,
              0.125,
              0.375,
              0.1875,
              0.5,
              0.125,
              0.375,
              0.1875,
              0.375,
              0.1875,
              0.5,
              0.1875,
              0.5,
              0.1875,
              0.375,
              0.25,
              0.5,
              0.1875,
              0.375,
              0.25,
              0.375,
              0.25,
              0.5,
              0.25,
              0.5,
              0.25,
              0.375,
              0.3125,
              0.5,
              0.25,
              0.375,
              0.3125,
              0.375,
              0.3125,
              0.5,
              0.3125,
              0.5,
              0.3125,
              0.375,
              0.375,
              0.5,
              0.3125,
              0.375,
              0.375,
              0.375,
              0.375,
              0.5,
              0.375,
              0.5,
              0.375,
              0.375,
              0.4375,
              0.5,
              0.375,
              0.375,
              0.4375,
              0.375,
              0.4375,
              0.5,
              0.4375,
              0.5,
              0.4375,
              0.375,
              0.5,
              0.5,
              0.4375,
              0.375,
              0.5,
              0.375,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.375,
              0.5625,
              0.5,
              0.5,
              0.375,
              0.5625,
              0.375,
              0.5625,
              0.5,
              0.5625,
              0.5,
              0.5625,
              0.375,
              0.625,
              0.5,
              0.5625,
              0.375,
              0.625,
              0.375,
              0.625,
              0.5,
              0.625,
              0.5,
              0.625,
              0.375,
              0.6875,
              0.5,
              0.625,
              0.375,
              0.6875,
              0.375,
              0.6875,
              0.5,
              0.6875,
              0.5,
              0.6875,
              0.375,
              0.75,
              0.5,
              0.6875,
              0.375,
              0.75,
              0.375,
              0.75,
              0.5,
              0.75,
              0.5,
              0.75,
              0.375,
              0.8125,
              0.5,
              0.75,
              0.375,
              0.8125,
              0.375,
              0.8125,
              0.5,
              0.8125,
              0.5,
              0.8125,
              0.375,
              0.875,
              0.5,
              0.8125,
              0.375,
              0.875,
              0.375,
              0.875,
              0.5,
              0.875,
              0.5,
              0.875,
              0.375,
              0.9375,
              0.5,
              0.875,
              0.375,
              0.9375,
              0.375,
              0.9375,
              0.5,
              0.9375,
              0.5,
              0.9375,
              0.375,
              1,
              0.5,
              0.9375,
              0.375,
              1,
              0.375,
              1,
              0.5,
              0,
              0.375,
              0,
              0.25,
              0.0625,
              0.375,
              0,
              0.25,
              0.0625,
              0.25,
              0.0625,
              0.375,
              0.0625,
              0.375,
              0.0625,
              0.25,
              0.125,
              0.375,
              0.0625,
              0.25,
              0.125,
              0.25,
              0.125,
              0.375,
              0.125,
              0.375,
              0.125,
              0.25,
              0.1875,
              0.375,
              0.125,
              0.25,
              0.1875,
              0.25,
              0.1875,
              0.375,
              0.1875,
              0.375,
              0.1875,
              0.25,
              0.25,
              0.375,
              0.1875,
              0.25,
              0.25,
              0.25,
              0.25,
              0.375,
              0.25,
              0.375,
              0.25,
              0.25,
              0.3125,
              0.375,
              0.25,
              0.25,
              0.3125,
              0.25,
              0.3125,
              0.375,
              0.3125,
              0.375,
              0.3125,
              0.25,
              0.375,
              0.375,
              0.3125,
              0.25,
              0.375,
              0.25,
              0.375,
              0.375,
              0.375,
              0.375,
              0.375,
              0.25,
              0.4375,
              0.375,
              0.375,
              0.25,
              0.4375,
              0.25,
              0.4375,
              0.375,
              0.4375,
              0.375,
              0.4375,
              0.25,
              0.5,
              0.375,
              0.4375,
              0.25,
              0.5,
              0.25,
              0.5,
              0.375,
              0.5,
              0.375,
              0.5,
              0.25,
              0.5625,
              0.375,
              0.5,
              0.25,
              0.5625,
              0.25,
              0.5625,
              0.375,
              0.5625,
              0.375,
              0.5625,
              0.25,
              0.625,
              0.375,
              0.5625,
              0.25,
              0.625,
              0.25,
              0.625,
              0.375,
              0.625,
              0.375,
              0.625,
              0.25,
              0.6875,
              0.375,
              0.625,
              0.25,
              0.6875,
              0.25,
              0.6875,
              0.375,
              0.6875,
              0.375,
              0.6875,
              0.25,
              0.75,
              0.375,
              0.6875,
              0.25,
              0.75,
              0.25,
              0.75,
              0.375,
              0.75,
              0.375,
              0.75,
              0.25,
              0.8125,
              0.375,
              0.75,
              0.25,
              0.8125,
              0.25,
              0.8125,
              0.375,
              0.8125,
              0.375,
              0.8125,
              0.25,
              0.875,
              0.375,
              0.8125,
              0.25,
              0.875,
              0.25,
              0.875,
              0.375,
              0.875,
              0.375,
              0.875,
              0.25,
              0.9375,
              0.375,
              0.875,
              0.25,
              0.9375,
              0.25,
              0.9375,
              0.375,
              0.9375,
              0.375,
              0.9375,
              0.25,
              1,
              0.375,
              0.9375,
              0.25,
              1,
              0.25,
              1,
              0.375,
              0,
              0.25,
              0,
              0.125,
              0.0625,
              0.25,
              0,
              0.125,
              0.0625,
              0.125,
              0.0625,
              0.25,
              0.0625,
              0.25,
              0.0625,
              0.125,
              0.125,
              0.25,
              0.0625,
              0.125,
              0.125,
              0.125,
              0.125,
              0.25,
              0.125,
              0.25,
              0.125,
              0.125,
              0.1875,
              0.25,
              0.125,
              0.125,
              0.1875,
              0.125,
              0.1875,
              0.25,
              0.1875,
              0.25,
              0.1875,
              0.125,
              0.25,
              0.25,
              0.1875,
              0.125,
              0.25,
              0.125,
              0.25,
              0.25,
              0.25,
              0.25,
              0.25,
              0.125,
              0.3125,
              0.25,
              0.25,
              0.125,
              0.3125,
              0.125,
              0.3125,
              0.25,
              0.3125,
              0.25,
              0.3125,
              0.125,
              0.375,
              0.25,
              0.3125,
              0.125,
              0.375,
              0.125,
              0.375,
              0.25,
              0.375,
              0.25,
              0.375,
              0.125,
              0.4375,
              0.25,
              0.375,
              0.125,
              0.4375,
              0.125,
              0.4375,
              0.25,
              0.4375,
              0.25,
              0.4375,
              0.125,
              0.5,
              0.25,
              0.4375,
              0.125,
              0.5,
              0.125,
              0.5,
              0.25,
              0.5,
              0.25,
              0.5,
              0.125,
              0.5625,
              0.25,
              0.5,
              0.125,
              0.5625,
              0.125,
              0.5625,
              0.25,
              0.5625,
              0.25,
              0.5625,
              0.125,
              0.625,
              0.25,
              0.5625,
              0.125,
              0.625,
              0.125,
              0.625,
              0.25,
              0.625,
              0.25,
              0.625,
              0.125,
              0.6875,
              0.25,
              0.625,
              0.125,
              0.6875,
              0.125,
              0.6875,
              0.25,
              0.6875,
              0.25,
              0.6875,
              0.125,
              0.75,
              0.25,
              0.6875,
              0.125,
              0.75,
              0.125,
              0.75,
              0.25,
              0.75,
              0.25,
              0.75,
              0.125,
              0.8125,
              0.25,
              0.75,
              0.125,
              0.8125,
              0.125,
              0.8125,
              0.25,
              0.8125,
              0.25,
              0.8125,
              0.125,
              0.875,
              0.25,
              0.8125,
              0.125,
              0.875,
              0.125,
              0.875,
              0.25,
              0.875,
              0.25,
              0.875,
              0.125,
              0.9375,
              0.25,
              0.875,
              0.125,
              0.9375,
              0.125,
              0.9375,
              0.25,
              0.9375,
              0.25,
              0.9375,
              0.125,
              1,
              0.25,
              0.9375,
              0.125,
              1,
              0.125,
              1,
              0.25,
              0,
              0,
              0.0625,
              0.125,
              0,
              0.125,
              0.0625,
              0,
              0.125,
              0.125,
              0.0625,
              0.125,
              0.125,
              0,
              0.1875,
              0.125,
              0.125,
              0.125,
              0.1875,
              0,
              0.25,
              0.125,
              0.1875,
              0.125,
              0.25,
              0,
              0.3125,
              0.125,
              0.25,
              0.125,
              0.3125,
              0,
              0.375,
              0.125,
              0.3125,
              0.125,
              0.375,
              0,
              0.4375,
              0.125,
              0.375,
              0.125,
              0.4375,
              0,
              0.5,
              0.125,
              0.4375,
              0.125,
              0.5,
              0,
              0.5625,
              0.125,
              0.5,
              0.125,
              0.5625,
              0,
              0.625,
              0.125,
              0.5625,
              0.125,
              0.625,
              0,
              0.6875,
              0.125,
              0.625,
              0.125,
              0.6875,
              0,
              0.75,
              0.125,
              0.6875,
              0.125,
              0.75,
              0,
              0.8125,
              0.125,
              0.75,
              0.125,
              0.8125,
              0,
              0.875,
              0.125,
              0.8125,
              0.125,
              0.875,
              0,
              0.9375,
              0.125,
              0.875,
              0.125,
              0.9375,
              0,
              1,
              0.125,
              0.9375,
              0.125,
              0,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.0625,
              0.875,
              0.0625,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.125,
              0.875,
              0.125,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.1875,
              0.875,
              0.1875,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.25,
              0.875,
              0.25,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.3125,
              0.875,
              0.3125,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.375,
              0.875,
              0.375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.4375,
              0.875,
              0.4375,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.5,
              0.875,
              0.5,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.5625,
              0.875,
              0.5625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.625,
              0.875,
              0.625,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.6875,
              0.875,
              0.6875,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.75,
              0.875,
              0.75,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.8125,
              0.875,
              0.8125,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              0.875,
              0.875,
              0.875,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0.9375,
              0.875,
              0.9375,
              0.875,
              1,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0.25,
              0.29159998893737793,
              0.25,
              0,
              0,
              0.29159998893737793,
              0.25,
              0,
              0,
              0,
              0,
              0.29159998893737793,
              0.25,
              0,
              0.25,
              0.29159998893737793,
              0.5,
              0,
              0.25,
              0.29159998893737793,
              0.5,
              0.29159998893737793,
              0.5,
              0,
              0.75,
              0.29159998893737793,
              0.75,
              0,
              0.5,
              0.29159998893737793,
              0.75,
              0,
              0.5,
              0,
              0.5,
              0.29159998893737793,
              1,
              0.29159998893737793,
              1,
              0,
              0.75,
              0.29159998893737793,
              1,
              0,
              0.75,
              0,
              0.75,
              0.29159998893737793,
              0.25,
              0.7142000198364258,
              0.25,
              0.29159998893737793,
              0,
              0.7142000198364258,
              0.25,
              0.29159998893737793,
              0,
              0.29159998893737793,
              0,
              0.7142000198364258,
              0.25,
              0.29159998893737793,
              0.25,
              0.7142000198364258,
              0.5,
              0.29159998893737793,
              0.25,
              0.7142000198364258,
              0.5,
              0.7142000198364258,
              0.5,
              0.29159998893737793,
              0.75,
              0.7142000198364258,
              0.75,
              0.29159998893737793,
              0.5,
              0.7142000198364258,
              0.75,
              0.29159998893737793,
              0.5,
              0.29159998893737793,
              0.5,
              0.7142000198364258,
              1,
              0.7142000198364258,
              1,
              0.29159998893737793,
              0.75,
              0.7142000198364258,
              1,
              0.29159998893737793,
              0.75,
              0.29159998893737793,
              0.75,
              0.7142000198364258,
              0.25,
              1,
              0.25,
              0.7142000198364258,
              0,
              1,
              0.25,
              0.7142000198364258,
              0,
              0.7142000198364258,
              0,
              1,
              0.5,
              1,
              0.5,
              0.7142000198364258,
              0.25,
              1,
              0.5,
              0.7142000198364258,
              0.25,
              0.7142000198364258,
              0.25,
              1,
              0.75,
              1,
              0.75,
              0.7142000198364258,
              0.5,
              1,
              0.75,
              0.7142000198364258,
              0.5,
              0.7142000198364258,
              0.5,
              1,
              1,
              1,
              1,
              0.7142000198364258,
              0.75,
              1,
              1,
              0.7142000198364258,
              0.75,
              0.7142000198364258,
              0.75,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0,
              0.875,
              0.25,
              0.5960000157356262,
              0.25,
              0,
              0,
              0.5960000157356262,
              0.25,
              0,
              0,
              0,
              0,
              0.5960000157356262,
              0.25,
              0,
              0.25,
              0.5960000157356262,
              0.5,
              0,
              0.25,
              0.5960000157356262,
              0.5,
              0.5960000157356262,
              0.5,
              0,
              0.75,
              0.5960000157356262,
              0.75,
              0,
              0.5,
              0.5960000157356262,
              0.75,
              0,
              0.5,
              0,
              0.5,
              0.5960000157356262,
              1,
              0.5960000157356262,
              1,
              0,
              0.75,
              0.5960000157356262,
              1,
              0,
              0.75,
              0,
              0.75,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.25,
              0.5960000157356262,
              0,
              0.6150000095367432,
              0.25,
              0.5960000157356262,
              0,
              0.5960000157356262,
              0,
              0.6150000095367432,
              0.25,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.5,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.5,
              0.6150000095367432,
              0.5,
              0.5960000157356262,
              0.75,
              0.6150000095367432,
              0.75,
              0.5960000157356262,
              0.5,
              0.6150000095367432,
              0.75,
              0.5960000157356262,
              0.5,
              0.5960000157356262,
              0.5,
              0.6150000095367432,
              1,
              0.6150000095367432,
              1,
              0.5960000157356262,
              0.75,
              0.6150000095367432,
              1,
              0.5960000157356262,
              0.75,
              0.5960000157356262,
              0.75,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.25,
              0.6150000095367432,
              0,
              0.6771000027656555,
              0.25,
              0.6150000095367432,
              0,
              0.6150000095367432,
              0,
              0.6771000027656555,
              0.5,
              0.6771000027656555,
              0.5,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.5,
              0.6150000095367432,
              0.25,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.75,
              0.6771000027656555,
              0.75,
              0.6150000095367432,
              0.5,
              0.6771000027656555,
              0.75,
              0.6150000095367432,
              0.5,
              0.6150000095367432,
              0.5,
              0.6771000027656555,
              1,
              0.6771000027656555,
              1,
              0.6150000095367432,
              0.75,
              0.6771000027656555,
              1,
              0.6150000095367432,
              0.75,
              0.6150000095367432,
              0.75,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.25,
              0.6771000027656555,
              0,
              0.7145000100135803,
              0.25,
              0.6771000027656555,
              0,
              0.6771000027656555,
              0,
              0.7145000100135803,
              0.5,
              0.7145000100135803,
              0.5,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.5,
              0.6771000027656555,
              0.25,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.75,
              0.7145000100135803,
              0.75,
              0.6771000027656555,
              0.5,
              0.7145000100135803,
              0.75,
              0.6771000027656555,
              0.5,
              0.6771000027656555,
              0.5,
              0.7145000100135803,
              1,
              0.7145000100135803,
              1,
              0.6771000027656555,
              0.75,
              0.7145000100135803,
              1,
              0.6771000027656555,
              0.75,
              0.6771000027656555,
              0.75,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.25,
              0.7145000100135803,
              0,
              0.734499990940094,
              0.25,
              0.7145000100135803,
              0,
              0.7145000100135803,
              0,
              0.734499990940094,
              0.5,
              0.734499990940094,
              0.5,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.5,
              0.7145000100135803,
              0.25,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.75,
              0.734499990940094,
              0.75,
              0.7145000100135803,
              0.5,
              0.734499990940094,
              0.75,
              0.7145000100135803,
              0.5,
              0.7145000100135803,
              0.5,
              0.734499990940094,
              1,
              0.734499990940094,
              1,
              0.7145000100135803,
              0.75,
              0.734499990940094,
              1,
              0.7145000100135803,
              0.75,
              0.7145000100135803,
              0.75,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.25,
              0.734499990940094,
              0,
              0.7529000043869019,
              0.25,
              0.734499990940094,
              0,
              0.734499990940094,
              0,
              0.7529000043869019,
              0.5,
              0.7529000043869019,
              0.5,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.5,
              0.734499990940094,
              0.25,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.75,
              0.7529000043869019,
              0.75,
              0.734499990940094,
              0.5,
              0.7529000043869019,
              0.75,
              0.734499990940094,
              0.5,
              0.734499990940094,
              0.5,
              0.7529000043869019,
              1,
              0.7529000043869019,
              1,
              0.734499990940094,
              0.75,
              0.7529000043869019,
              1,
              0.734499990940094,
              0.75,
              0.734499990940094,
              0.75,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.25,
              0.7529000043869019,
              0,
              0.7682999968528748,
              0.25,
              0.7529000043869019,
              0,
              0.7529000043869019,
              0,
              0.7682999968528748,
              0.5,
              0.7682999968528748,
              0.5,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.5,
              0.7529000043869019,
              0.25,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.75,
              0.7682999968528748,
              0.75,
              0.7529000043869019,
              0.5,
              0.7682999968528748,
              0.75,
              0.7529000043869019,
              0.5,
              0.7529000043869019,
              0.5,
              0.7682999968528748,
              1,
              0.7682999968528748,
              1,
              0.7529000043869019,
              0.75,
              0.7682999968528748,
              1,
              0.7529000043869019,
              0.75,
              0.7529000043869019,
              0.75,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.25,
              0.7682999968528748,
              0,
              0.7796000242233276,
              0.25,
              0.7682999968528748,
              0,
              0.7682999968528748,
              0,
              0.7796000242233276,
              0.5,
              0.7796000242233276,
              0.5,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.5,
              0.7682999968528748,
              0.25,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.75,
              0.7796000242233276,
              0.75,
              0.7682999968528748,
              0.5,
              0.7796000242233276,
              0.75,
              0.7682999968528748,
              0.5,
              0.7682999968528748,
              0.5,
              0.7796000242233276,
              1,
              0.7796000242233276,
              1,
              0.7682999968528748,
              0.75,
              0.7796000242233276,
              1,
              0.7682999968528748,
              0.75,
              0.7682999968528748,
              0.75,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.25,
              0.7796000242233276,
              0,
              0.788100004196167,
              0.25,
              0.7796000242233276,
              0,
              0.7796000242233276,
              0,
              0.788100004196167,
              0.5,
              0.788100004196167,
              0.5,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.5,
              0.7796000242233276,
              0.25,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.75,
              0.788100004196167,
              0.75,
              0.7796000242233276,
              0.5,
              0.788100004196167,
              0.75,
              0.7796000242233276,
              0.5,
              0.7796000242233276,
              0.5,
              0.788100004196167,
              1,
              0.788100004196167,
              1,
              0.7796000242233276,
              0.75,
              0.788100004196167,
              1,
              0.7796000242233276,
              0.75,
              0.7796000242233276,
              0.75,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.25,
              0.788100004196167,
              0,
              0.7998999953269958,
              0.25,
              0.788100004196167,
              0,
              0.788100004196167,
              0,
              0.7998999953269958,
              0.5,
              0.7998999953269958,
              0.5,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.5,
              0.788100004196167,
              0.25,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.75,
              0.7998999953269958,
              0.75,
              0.788100004196167,
              0.5,
              0.7998999953269958,
              0.75,
              0.788100004196167,
              0.5,
              0.788100004196167,
              0.5,
              0.7998999953269958,
              1,
              0.7998999953269958,
              1,
              0.788100004196167,
              0.75,
              0.7998999953269958,
              1,
              0.788100004196167,
              0.75,
              0.788100004196167,
              0.75,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.25,
              0.7998999953269958,
              0,
              0.8202999830245972,
              0.25,
              0.7998999953269958,
              0,
              0.7998999953269958,
              0,
              0.8202999830245972,
              0.5,
              0.8202999830245972,
              0.5,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.5,
              0.7998999953269958,
              0.25,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.75,
              0.8202999830245972,
              0.75,
              0.7998999953269958,
              0.5,
              0.8202999830245972,
              0.75,
              0.7998999953269958,
              0.5,
              0.7998999953269958,
              0.5,
              0.8202999830245972,
              1,
              0.8202999830245972,
              1,
              0.7998999953269958,
              0.75,
              0.8202999830245972,
              1,
              0.7998999953269958,
              0.75,
              0.7998999953269958,
              0.75,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.25,
              0.8202999830245972,
              0,
              0.8413000106811523,
              0.25,
              0.8202999830245972,
              0,
              0.8202999830245972,
              0,
              0.8413000106811523,
              0.5,
              0.8413000106811523,
              0.5,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.5,
              0.8202999830245972,
              0.25,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.75,
              0.8413000106811523,
              0.75,
              0.8202999830245972,
              0.5,
              0.8413000106811523,
              0.75,
              0.8202999830245972,
              0.5,
              0.8202999830245972,
              0.5,
              0.8413000106811523,
              1,
              0.8413000106811523,
              1,
              0.8202999830245972,
              0.75,
              0.8413000106811523,
              1,
              0.8202999830245972,
              0.75,
              0.8202999830245972,
              0.75,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.25,
              0.8413000106811523,
              0,
              0.8543999791145325,
              0.25,
              0.8413000106811523,
              0,
              0.8413000106811523,
              0,
              0.8543999791145325,
              0.5,
              0.8543999791145325,
              0.5,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.5,
              0.8413000106811523,
              0.25,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.75,
              0.8543999791145325,
              0.75,
              0.8413000106811523,
              0.5,
              0.8543999791145325,
              0.75,
              0.8413000106811523,
              0.5,
              0.8413000106811523,
              0.5,
              0.8543999791145325,
              1,
              0.8543999791145325,
              1,
              0.8413000106811523,
              0.75,
              0.8543999791145325,
              1,
              0.8413000106811523,
              0.75,
              0.8413000106811523,
              0.75,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.25,
              0.8543999791145325,
              0,
              0.8640000224113464,
              0.25,
              0.8543999791145325,
              0,
              0.8543999791145325,
              0,
              0.8640000224113464,
              0.5,
              0.8640000224113464,
              0.5,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.5,
              0.8543999791145325,
              0.25,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.75,
              0.8640000224113464,
              0.75,
              0.8543999791145325,
              0.5,
              0.8640000224113464,
              0.75,
              0.8543999791145325,
              0.5,
              0.8543999791145325,
              0.5,
              0.8640000224113464,
              1,
              0.8640000224113464,
              1,
              0.8543999791145325,
              0.75,
              0.8640000224113464,
              1,
              0.8543999791145325,
              0.75,
              0.8543999791145325,
              0.75,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.25,
              0.8640000224113464,
              0,
              0.8751999735832214,
              0.25,
              0.8640000224113464,
              0,
              0.8640000224113464,
              0,
              0.8751999735832214,
              0.5,
              0.8751999735832214,
              0.5,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.5,
              0.8640000224113464,
              0.25,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.75,
              0.8751999735832214,
              0.75,
              0.8640000224113464,
              0.5,
              0.8751999735832214,
              0.75,
              0.8640000224113464,
              0.5,
              0.8640000224113464,
              0.5,
              0.8751999735832214,
              1,
              0.8751999735832214,
              1,
              0.8640000224113464,
              0.75,
              0.8751999735832214,
              1,
              0.8640000224113464,
              0.75,
              0.8640000224113464,
              0.75,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.25,
              0.8751999735832214,
              0,
              0.8894000053405762,
              0.25,
              0.8751999735832214,
              0,
              0.8751999735832214,
              0,
              0.8894000053405762,
              0.5,
              0.8894000053405762,
              0.5,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.5,
              0.8751999735832214,
              0.25,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.75,
              0.8894000053405762,
              0.75,
              0.8751999735832214,
              0.5,
              0.8894000053405762,
              0.75,
              0.8751999735832214,
              0.5,
              0.8751999735832214,
              0.5,
              0.8894000053405762,
              1,
              0.8894000053405762,
              1,
              0.8751999735832214,
              0.75,
              0.8894000053405762,
              1,
              0.8751999735832214,
              0.75,
              0.8751999735832214,
              0.75,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.25,
              0.8894000053405762,
              0,
              0.9061999917030334,
              0.25,
              0.8894000053405762,
              0,
              0.8894000053405762,
              0,
              0.9061999917030334,
              0.5,
              0.9061999917030334,
              0.5,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.5,
              0.8894000053405762,
              0.25,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.75,
              0.9061999917030334,
              0.75,
              0.8894000053405762,
              0.5,
              0.9061999917030334,
              0.75,
              0.8894000053405762,
              0.5,
              0.8894000053405762,
              0.5,
              0.9061999917030334,
              1,
              0.9061999917030334,
              1,
              0.8894000053405762,
              0.75,
              0.9061999917030334,
              1,
              0.8894000053405762,
              0.75,
              0.8894000053405762,
              0.75,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.25,
              0.9061999917030334,
              0,
              0.9243999719619751,
              0.25,
              0.9061999917030334,
              0,
              0.9061999917030334,
              0,
              0.9243999719619751,
              0.5,
              0.9243999719619751,
              0.5,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.5,
              0.9061999917030334,
              0.25,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.75,
              0.9243999719619751,
              0.75,
              0.9061999917030334,
              0.5,
              0.9243999719619751,
              0.75,
              0.9061999917030334,
              0.5,
              0.9061999917030334,
              0.5,
              0.9243999719619751,
              1,
              0.9243999719619751,
              1,
              0.9061999917030334,
              0.75,
              0.9243999719619751,
              1,
              0.9061999917030334,
              0.75,
              0.9061999917030334,
              0.75,
              0.9243999719619751,
              0.25,
              1,
              0.25,
              0.9243999719619751,
              0,
              1,
              0.25,
              0.9243999719619751,
              0,
              0.9243999719619751,
              0,
              1,
              0.5,
              1,
              0.5,
              0.9243999719619751,
              0.25,
              1,
              0.5,
              0.9243999719619751,
              0.25,
              0.9243999719619751,
              0.25,
              1,
              0.75,
              1,
              0.75,
              0.9243999719619751,
              0.5,
              1,
              0.75,
              0.9243999719619751,
              0.5,
              0.9243999719619751,
              0.5,
              1,
              1,
              1,
              1,
              0.9243999719619751,
              0.75,
              1,
              1,
              0.9243999719619751,
              0.75,
              0.9243999719619751,
              0.75,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.25,
              0.5960000157356262,
              0.25,
              0,
              0,
              0.5960000157356262,
              0.25,
              0,
              0,
              0,
              0,
              0.5960000157356262,
              0.5,
              0.5960000157356262,
              0.5,
              0,
              0.25,
              0.5960000157356262,
              0.5,
              0,
              0.25,
              0,
              0.25,
              0.5960000157356262,
              0.75,
              0.5960000157356262,
              0.75,
              0,
              0.5,
              0.5960000157356262,
              0.75,
              0,
              0.5,
              0,
              0.5,
              0.5960000157356262,
              1,
              0.5960000157356262,
              1,
              0,
              0.75,
              0.5960000157356262,
              1,
              0,
              0.75,
              0,
              0.75,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.25,
              0.5960000157356262,
              0,
              0.6150000095367432,
              0.25,
              0.5960000157356262,
              0,
              0.5960000157356262,
              0,
              0.6150000095367432,
              0.5,
              0.6150000095367432,
              0.5,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.5,
              0.5960000157356262,
              0.25,
              0.5960000157356262,
              0.25,
              0.6150000095367432,
              0.75,
              0.6150000095367432,
              0.75,
              0.5960000157356262,
              0.5,
              0.6150000095367432,
              0.75,
              0.5960000157356262,
              0.5,
              0.5960000157356262,
              0.5,
              0.6150000095367432,
              1,
              0.6150000095367432,
              1,
              0.5960000157356262,
              0.75,
              0.6150000095367432,
              1,
              0.5960000157356262,
              0.75,
              0.5960000157356262,
              0.75,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.25,
              0.6150000095367432,
              0,
              0.6771000027656555,
              0.25,
              0.6150000095367432,
              0,
              0.6150000095367432,
              0,
              0.6771000027656555,
              0.5,
              0.6771000027656555,
              0.5,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.5,
              0.6150000095367432,
              0.25,
              0.6150000095367432,
              0.25,
              0.6771000027656555,
              0.75,
              0.6771000027656555,
              0.75,
              0.6150000095367432,
              0.5,
              0.6771000027656555,
              0.75,
              0.6150000095367432,
              0.5,
              0.6150000095367432,
              0.5,
              0.6771000027656555,
              1,
              0.6771000027656555,
              1,
              0.6150000095367432,
              0.75,
              0.6771000027656555,
              1,
              0.6150000095367432,
              0.75,
              0.6150000095367432,
              0.75,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.25,
              0.6771000027656555,
              0,
              0.7145000100135803,
              0.25,
              0.6771000027656555,
              0,
              0.6771000027656555,
              0,
              0.7145000100135803,
              0.5,
              0.7145000100135803,
              0.5,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.5,
              0.6771000027656555,
              0.25,
              0.6771000027656555,
              0.25,
              0.7145000100135803,
              0.75,
              0.7145000100135803,
              0.75,
              0.6771000027656555,
              0.5,
              0.7145000100135803,
              0.75,
              0.6771000027656555,
              0.5,
              0.6771000027656555,
              0.5,
              0.7145000100135803,
              1,
              0.7145000100135803,
              1,
              0.6771000027656555,
              0.75,
              0.7145000100135803,
              1,
              0.6771000027656555,
              0.75,
              0.6771000027656555,
              0.75,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.25,
              0.7145000100135803,
              0,
              0.734499990940094,
              0.25,
              0.7145000100135803,
              0,
              0.7145000100135803,
              0,
              0.734499990940094,
              0.5,
              0.734499990940094,
              0.5,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.5,
              0.7145000100135803,
              0.25,
              0.7145000100135803,
              0.25,
              0.734499990940094,
              0.75,
              0.734499990940094,
              0.75,
              0.7145000100135803,
              0.5,
              0.734499990940094,
              0.75,
              0.7145000100135803,
              0.5,
              0.7145000100135803,
              0.5,
              0.734499990940094,
              1,
              0.734499990940094,
              1,
              0.7145000100135803,
              0.75,
              0.734499990940094,
              1,
              0.7145000100135803,
              0.75,
              0.7145000100135803,
              0.75,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.25,
              0.734499990940094,
              0,
              0.7529000043869019,
              0.25,
              0.734499990940094,
              0,
              0.734499990940094,
              0,
              0.7529000043869019,
              0.5,
              0.7529000043869019,
              0.5,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.5,
              0.734499990940094,
              0.25,
              0.734499990940094,
              0.25,
              0.7529000043869019,
              0.75,
              0.7529000043869019,
              0.75,
              0.734499990940094,
              0.5,
              0.7529000043869019,
              0.75,
              0.734499990940094,
              0.5,
              0.734499990940094,
              0.5,
              0.7529000043869019,
              1,
              0.7529000043869019,
              1,
              0.734499990940094,
              0.75,
              0.7529000043869019,
              1,
              0.734499990940094,
              0.75,
              0.734499990940094,
              0.75,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.25,
              0.7529000043869019,
              0,
              0.7682999968528748,
              0.25,
              0.7529000043869019,
              0,
              0.7529000043869019,
              0,
              0.7682999968528748,
              0.5,
              0.7682999968528748,
              0.5,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.5,
              0.7529000043869019,
              0.25,
              0.7529000043869019,
              0.25,
              0.7682999968528748,
              0.75,
              0.7682999968528748,
              0.75,
              0.7529000043869019,
              0.5,
              0.7682999968528748,
              0.75,
              0.7529000043869019,
              0.5,
              0.7529000043869019,
              0.5,
              0.7682999968528748,
              1,
              0.7682999968528748,
              1,
              0.7529000043869019,
              0.75,
              0.7682999968528748,
              1,
              0.7529000043869019,
              0.75,
              0.7529000043869019,
              0.75,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.25,
              0.7682999968528748,
              0,
              0.7796000242233276,
              0.25,
              0.7682999968528748,
              0,
              0.7682999968528748,
              0,
              0.7796000242233276,
              0.5,
              0.7796000242233276,
              0.5,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.5,
              0.7682999968528748,
              0.25,
              0.7682999968528748,
              0.25,
              0.7796000242233276,
              0.75,
              0.7796000242233276,
              0.75,
              0.7682999968528748,
              0.5,
              0.7796000242233276,
              0.75,
              0.7682999968528748,
              0.5,
              0.7682999968528748,
              0.5,
              0.7796000242233276,
              1,
              0.7796000242233276,
              1,
              0.7682999968528748,
              0.75,
              0.7796000242233276,
              1,
              0.7682999968528748,
              0.75,
              0.7682999968528748,
              0.75,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.25,
              0.7796000242233276,
              0,
              0.788100004196167,
              0.25,
              0.7796000242233276,
              0,
              0.7796000242233276,
              0,
              0.788100004196167,
              0.5,
              0.788100004196167,
              0.5,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.5,
              0.7796000242233276,
              0.25,
              0.7796000242233276,
              0.25,
              0.788100004196167,
              0.75,
              0.788100004196167,
              0.75,
              0.7796000242233276,
              0.5,
              0.788100004196167,
              0.75,
              0.7796000242233276,
              0.5,
              0.7796000242233276,
              0.5,
              0.788100004196167,
              1,
              0.788100004196167,
              1,
              0.7796000242233276,
              0.75,
              0.788100004196167,
              1,
              0.7796000242233276,
              0.75,
              0.7796000242233276,
              0.75,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.25,
              0.788100004196167,
              0,
              0.7998999953269958,
              0.25,
              0.788100004196167,
              0,
              0.788100004196167,
              0,
              0.7998999953269958,
              0.5,
              0.7998999953269958,
              0.5,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.5,
              0.788100004196167,
              0.25,
              0.788100004196167,
              0.25,
              0.7998999953269958,
              0.75,
              0.7998999953269958,
              0.75,
              0.788100004196167,
              0.5,
              0.7998999953269958,
              0.75,
              0.788100004196167,
              0.5,
              0.788100004196167,
              0.5,
              0.7998999953269958,
              1,
              0.7998999953269958,
              1,
              0.788100004196167,
              0.75,
              0.7998999953269958,
              1,
              0.788100004196167,
              0.75,
              0.788100004196167,
              0.75,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.25,
              0.7998999953269958,
              0,
              0.8202999830245972,
              0.25,
              0.7998999953269958,
              0,
              0.7998999953269958,
              0,
              0.8202999830245972,
              0.5,
              0.8202999830245972,
              0.5,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.5,
              0.7998999953269958,
              0.25,
              0.7998999953269958,
              0.25,
              0.8202999830245972,
              0.75,
              0.8202999830245972,
              0.75,
              0.7998999953269958,
              0.5,
              0.8202999830245972,
              0.75,
              0.7998999953269958,
              0.5,
              0.7998999953269958,
              0.5,
              0.8202999830245972,
              1,
              0.8202999830245972,
              1,
              0.7998999953269958,
              0.75,
              0.8202999830245972,
              1,
              0.7998999953269958,
              0.75,
              0.7998999953269958,
              0.75,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.25,
              0.8202999830245972,
              0,
              0.8413000106811523,
              0.25,
              0.8202999830245972,
              0,
              0.8202999830245972,
              0,
              0.8413000106811523,
              0.5,
              0.8413000106811523,
              0.5,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.5,
              0.8202999830245972,
              0.25,
              0.8202999830245972,
              0.25,
              0.8413000106811523,
              0.75,
              0.8413000106811523,
              0.75,
              0.8202999830245972,
              0.5,
              0.8413000106811523,
              0.75,
              0.8202999830245972,
              0.5,
              0.8202999830245972,
              0.5,
              0.8413000106811523,
              1,
              0.8413000106811523,
              1,
              0.8202999830245972,
              0.75,
              0.8413000106811523,
              1,
              0.8202999830245972,
              0.75,
              0.8202999830245972,
              0.75,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.25,
              0.8413000106811523,
              0,
              0.8543999791145325,
              0.25,
              0.8413000106811523,
              0,
              0.8413000106811523,
              0,
              0.8543999791145325,
              0.5,
              0.8543999791145325,
              0.5,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.5,
              0.8413000106811523,
              0.25,
              0.8413000106811523,
              0.25,
              0.8543999791145325,
              0.75,
              0.8543999791145325,
              0.75,
              0.8413000106811523,
              0.5,
              0.8543999791145325,
              0.75,
              0.8413000106811523,
              0.5,
              0.8413000106811523,
              0.5,
              0.8543999791145325,
              1,
              0.8543999791145325,
              1,
              0.8413000106811523,
              0.75,
              0.8543999791145325,
              1,
              0.8413000106811523,
              0.75,
              0.8413000106811523,
              0.75,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.25,
              0.8543999791145325,
              0,
              0.8640000224113464,
              0.25,
              0.8543999791145325,
              0,
              0.8543999791145325,
              0,
              0.8640000224113464,
              0.5,
              0.8640000224113464,
              0.5,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.5,
              0.8543999791145325,
              0.25,
              0.8543999791145325,
              0.25,
              0.8640000224113464,
              0.75,
              0.8640000224113464,
              0.75,
              0.8543999791145325,
              0.5,
              0.8640000224113464,
              0.75,
              0.8543999791145325,
              0.5,
              0.8543999791145325,
              0.5,
              0.8640000224113464,
              1,
              0.8640000224113464,
              1,
              0.8543999791145325,
              0.75,
              0.8640000224113464,
              1,
              0.8543999791145325,
              0.75,
              0.8543999791145325,
              0.75,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.25,
              0.8640000224113464,
              0,
              0.8751999735832214,
              0.25,
              0.8640000224113464,
              0,
              0.8640000224113464,
              0,
              0.8751999735832214,
              0.5,
              0.8751999735832214,
              0.5,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.5,
              0.8640000224113464,
              0.25,
              0.8640000224113464,
              0.25,
              0.8751999735832214,
              0.75,
              0.8751999735832214,
              0.75,
              0.8640000224113464,
              0.5,
              0.8751999735832214,
              0.75,
              0.8640000224113464,
              0.5,
              0.8640000224113464,
              0.5,
              0.8751999735832214,
              1,
              0.8751999735832214,
              1,
              0.8640000224113464,
              0.75,
              0.8751999735832214,
              1,
              0.8640000224113464,
              0.75,
              0.8640000224113464,
              0.75,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.25,
              0.8751999735832214,
              0,
              0.8894000053405762,
              0.25,
              0.8751999735832214,
              0,
              0.8751999735832214,
              0,
              0.8894000053405762,
              0.5,
              0.8894000053405762,
              0.5,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.5,
              0.8751999735832214,
              0.25,
              0.8751999735832214,
              0.25,
              0.8894000053405762,
              0.75,
              0.8894000053405762,
              0.75,
              0.8751999735832214,
              0.5,
              0.8894000053405762,
              0.75,
              0.8751999735832214,
              0.5,
              0.8751999735832214,
              0.5,
              0.8894000053405762,
              1,
              0.8894000053405762,
              1,
              0.8751999735832214,
              0.75,
              0.8894000053405762,
              1,
              0.8751999735832214,
              0.75,
              0.8751999735832214,
              0.75,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.25,
              0.8894000053405762,
              0,
              0.9061999917030334,
              0.25,
              0.8894000053405762,
              0,
              0.8894000053405762,
              0,
              0.9061999917030334,
              0.5,
              0.9061999917030334,
              0.5,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.5,
              0.8894000053405762,
              0.25,
              0.8894000053405762,
              0.25,
              0.9061999917030334,
              0.75,
              0.9061999917030334,
              0.75,
              0.8894000053405762,
              0.5,
              0.9061999917030334,
              0.75,
              0.8894000053405762,
              0.5,
              0.8894000053405762,
              0.5,
              0.9061999917030334,
              1,
              0.9061999917030334,
              1,
              0.8894000053405762,
              0.75,
              0.9061999917030334,
              1,
              0.8894000053405762,
              0.75,
              0.8894000053405762,
              0.75,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.25,
              0.9061999917030334,
              0,
              0.9243999719619751,
              0.25,
              0.9061999917030334,
              0,
              0.9061999917030334,
              0,
              0.9243999719619751,
              0.5,
              0.9243999719619751,
              0.5,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.5,
              0.9061999917030334,
              0.25,
              0.9061999917030334,
              0.25,
              0.9243999719619751,
              0.75,
              0.9243999719619751,
              0.75,
              0.9061999917030334,
              0.5,
              0.9243999719619751,
              0.75,
              0.9061999917030334,
              0.5,
              0.9061999917030334,
              0.5,
              0.9243999719619751,
              1,
              0.9243999719619751,
              1,
              0.9061999917030334,
              0.75,
              0.9243999719619751,
              1,
              0.9061999917030334,
              0.75,
              0.9061999917030334,
              0.75,
              0.9243999719619751,
              0.25,
              1,
              0.25,
              0.9243999719619751,
              0,
              1,
              0.25,
              0.9243999719619751,
              0,
              0.9243999719619751,
              0,
              1,
              0.5,
              1,
              0.5,
              0.9243999719619751,
              0.25,
              1,
              0.5,
              0.9243999719619751,
              0.25,
              0.9243999719619751,
              0.25,
              1,
              0.75,
              1,
              0.75,
              0.9243999719619751,
              0.5,
              1,
              0.75,
              0.9243999719619751,
              0.5,
              0.9243999719619751,
              0.5,
              1,
              1,
              1,
              1,
              0.9243999719619751,
              0.75,
              1,
              1,
              0.9243999719619751,
              0.75,
              0.9243999719619751,
              0.75,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ],
            "normalized": false
          }
        },
        "groups": [
          {
            "start": 0,
            "materialIndex": 0,
            "count": 984
          },
          {
            "start": 984,
            "materialIndex": 1,
            "count": 840
          },
          {
            "start": 1824,
            "materialIndex": 2,
            "count": 1008
          },
          {
            "start": 2832,
            "materialIndex": 3,
            "count": 1704
          },
          {
            "start": 4536,
            "materialIndex": 4,
            "count": 84
          },
          {
            "start": 4620,
            "materialIndex": 5,
            "count": 264
          },
          {
            "start": 4884,
            "materialIndex": 6,
            "count": 936
          }
        ]
      }
    }
  ],
  "materials": [
    {
      "uuid": "BEB390CB-F0DD-4BA2-84D5-0BB5EAF18C2B",
      "type": "MeshNormalMaterial",
      "depthFunc": 3,
      "depthTest": true,
      "depthWrite": true,
      "wireframe": true
    }
  ],
  "object": {
    "uuid": "559194F7-FF21-4BB7-B7F5-6864386F4AAB",
    "type": "Mesh",
    "layers": 1,
    "matrix": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    ],
    "geometry": "86341542-03B4-4035-8EEC-8C134D3C39C2",
    "material": "BEB390CB-F0DD-4BA2-84D5-0BB5EAF18C2B"
  }
}
