{
  "metadata": {
    "version": 4.5,
    "type": "Object",
    "generator": "Object3D.toJSON"
  },
  "geometries": [
    {
      "uuid": "6CE0F213-E13B-47C4-A522-48024F54FD50",
      "type": "BufferGeometry",
      "data": {
        "attributes": {
          "position": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              -1.1007299423217773,
              4.143909931182861,
              0.0321355015039444,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              -1.0930399894714355,
              3.9812700748443604,
              -0.015002500265836716,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              -0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              0.11236599832773209,
              0.053321000188589096,
              -0.0344420000910759,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.12379299849271774,
              0.05601600185036659,
              0.00797800999134779,
              1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.1118299961090088,
              4.161910057067871,
              0.03923150151968002,
              -1.110360026359558,
              4.208789825439453,
              0.04290249943733215,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              -1.084380030632019,
              3.981329917907715,
              0.029647499322891235,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              -1.1060999631881714,
              3.9763100147247314,
              0.028110500425100327,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0.12379299849271774,
              0.05601600185036659,
              0.00797800999134779,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9,
              0.12379299849271774,
              0.05601600185036659,
              0.00797800999134779,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0.11236599832773209,
              0.053321000188589096,
              -0.0344420000910759,
              0,
              0.053321000188589096,
              -0.0344420000910759,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0,
              0.053321000188589096,
              -0.0344420000910759,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              -0.004213999956846237,
              4.605460166931152,
              0.1145630031824112,
              -0.004213999956846237,
              4.5833001136779785,
              0.1145630031824112,
              -0.017242999747395515,
              4.6234002113342285,
              0.1145630031824112,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1347600221633911,
              3.7276599407196045,
              0.03005640022456646,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              0.11236599832773209,
              0.053321000188589096,
              -0.0344420000910759,
              0.12379299849271774,
              0.05601600185036659,
              0.00797800999134779,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.004213999956846237,
              4.605460166931152,
              0.1145630031824112,
              -0.017242999747395515,
              4.6234002113342285,
              0.1145630031824112,
              0.01686600036919117,
              4.5986199378967285,
              0.1145630031824112,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              -0.3593049943447113,
              4.41595983505249,
              0.1145630031824112,
              -0.3483169972896576,
              4.449769973754883,
              0.1145630031824112,
              -0.3195520043373108,
              4.470670223236084,
              0.1145630031824112,
              -0.3483169972896576,
              4.449769973754883,
              0.1145630031824112,
              -0.3593049943447113,
              4.41595983505249,
              0.1145630031824112,
              -0.3593049943447113,
              4.483590126037598,
              0.1145630031824112,
              0.46623700857162476,
              4.408929824829102,
              -0.11422500014305115,
              0.45371100306510925,
              4.4261698722839355,
              -0.11422500014305115,
              0.48650598526000977,
              4.402339935302734,
              -0.11422500014305115,
              -0.3195520043373108,
              4.470670223236084,
              0.1145630031824112,
              -0.3483169972896576,
              4.449769973754883,
              0.1145630031824112,
              -0.3593049943447113,
              4.483590126037598,
              0.1145630031824112,
              0.17371700704097748,
              4.4315900802612305,
              0.1145630031824112,
              -0.1928579956293106,
              4.45959997177124,
              0.1145630031824112,
              -0.004213999956846237,
              4.44920015335083,
              0.1145630031824112,
              -0.004213999956846237,
              4.44920015335083,
              0.1145630031824112,
              -0.1928579956293106,
              4.45959997177124,
              0.1145630031824112,
              0.17371700704097748,
              4.466819763183594,
              0.1145630031824112,
              -0.004213999956846237,
              4.44920015335083,
              0.1145630031824112,
              0.17371700704097748,
              4.466819763183594,
              0.1145630031824112,
              0.17371700704097748,
              4.4315900802612305,
              0.1145630031824112,
              0.46623700857162476,
              4.408929824829102,
              -0.11422500014305115,
              0.46623700857162476,
              4.387619972229004,
              -0.11422500014305115,
              0.45371100306510925,
              4.4261698722839355,
              -0.11422500014305115,
              0.46623700857162476,
              4.408929824829102,
              -0.11422500014305115,
              0.48650598526000977,
              4.402339935302734,
              -0.11422500014305115,
              0.46623700857162476,
              4.387619972229004,
              -0.11422500014305115,
              -0.42048901319503784,
              0.042865999042987823,
              0.008821009658277035,
              -0.37656301259994507,
              0.04401100054383278,
              -0.023094000294804573,
              -0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.5019559860229492,
              0.042865999042987823,
              0.008821009658277035,
              -0.45802998542785645,
              0.04401100054383278,
              -0.023094000294804573,
              -0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.5800949931144714,
              0.042865999042987823,
              0.008821009658277035,
              -0.5361689925193787,
              0.04401100054383278,
              -0.023094000294804573,
              -0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.3661929965019226,
              0.042865999042987823,
              0.008821009658277035,
              -0.42048901319503784,
              0.042865999042987823,
              0.008821009658277035,
              -0.37656301259994507,
              0.04401100054383278,
              -0.023094000294804573,
              -0.3661929965019226,
              0.042865999042987823,
              0.008821009658277035,
              -0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.6581810116767883,
              0.042865999042987823,
              0.008821009658277035,
              -0.6142550110816956,
              0.04401100054383278,
              -0.023094000294804573,
              -0.44765999913215637,
              0.042865999042987823,
              0.008821009658277035,
              -0.5019559860229492,
              0.042865999042987823,
              0.008821009658277035,
              -0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.45802998542785645,
              0.04401100054383278,
              -0.023094000294804573,
              -0.44765999913215637,
              0.042865999042987823,
              0.008821009658277035,
              -0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.7367730140686035,
              0.042865999042987823,
              0.008821009658277035,
              -0.6928460001945496,
              0.04401100054383278,
              -0.023094000294804573,
              -0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.5257989764213562,
              0.042865999042987823,
              0.008821009658277035,
              -0.5800949931144714,
              0.042865999042987823,
              0.008821009658277035,
              -0.5361689925193787,
              0.04401100054383278,
              -0.023094000294804573,
              -0.5257989764213562,
              0.042865999042987823,
              0.008821009658277035,
              -0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.8182399868965149,
              0.045878998935222626,
              0.008821009658277035,
              -0.7743129730224609,
              0.04483800008893013,
              -0.023094000294804573,
              -0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              -0.6038849949836731,
              0.042865999042987823,
              0.008821009658277035,
              -0.6581810116767883,
              0.042865999042987823,
              0.008821009658277035,
              -0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.6142550110816956,
              0.04401100054383278,
              -0.023094000294804573,
              -0.6038849949836731,
              0.042865999042987823,
              0.008821009658277035,
              -0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.22225500643253326,
              0.04439900070428848,
              0.022631000727415085,
              -0.24886000156402588,
              0.0431860014796257,
              -0.013987000100314617,
              -0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              -0.6824759840965271,
              0.042865999042987823,
              0.008821009658277035,
              -0.7367730140686035,
              0.042865999042987823,
              0.008821009658277035,
              -0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.6928460001945496,
              0.04401100054383278,
              -0.023094000294804573,
              -0.6824759840965271,
              0.042865999042987823,
              0.008821009658277035,
              -0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              -0.24886000156402588,
              0.0431860014796257,
              -0.013987000100314617,
              -0.22225500643253326,
              0.04439900070428848,
              -0.022631000727415085,
              -0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              -0.7639430165290833,
              0.04344800114631653,
              0.008821009658277035,
              -0.8182399868965149,
              0.045878998935222626,
              0.008821009658277035,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.7743129730224609,
              0.04483800008893013,
              -0.023094000294804573,
              -0.7639430165290833,
              0.04344800114631653,
              0.008821009658277035,
              -0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              -0.22225500643253326,
              0.04439900070428848,
              0.022631000727415085,
              -0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              -0.22225500643253326,
              0.04439900070428848,
              -0.022631000727415085,
              0.37656301259994507,
              0.04401100054383278,
              -0.023094000294804573,
              0.42048901319503784,
              0.042865999042987823,
              0.008821009658277035,
              0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              0.45802998542785645,
              0.04401100054383278,
              -0.023094000294804573,
              0.5019559860229492,
              0.042865999042987823,
              0.008821009658277035,
              0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              0.5361689925193787,
              0.04401100054383278,
              -0.023094000294804573,
              0.5800949931144714,
              0.042865999042987823,
              0.008821009658277035,
              0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              0.42048901319503784,
              0.042865999042987823,
              0.008821009658277035,
              0.3661929965019226,
              0.042865999042987823,
              0.008821009658277035,
              0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              0.37656301259994507,
              0.04401100054383278,
              -0.023094000294804573,
              0.3933410048484802,
              0.04224799945950508,
              5.036350003706502e-9,
              0.3661929965019226,
              0.042865999042987823,
              0.008821009658277035,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              -1.1118299961090088,
              4.161910057067871,
              0.03923150151968002,
              -1.1007299423217773,
              4.143909931182861,
              0.0321355015039444,
              0.6581810116767883,
              0.042865999042987823,
              0.008821009658277035,
              0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              0.6142550110816956,
              0.04401100054383278,
              -0.023094000294804573,
              0.44765999913215637,
              0.042865999042987823,
              0.008821009658277035,
              0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              0.5019559860229492,
              0.042865999042987823,
              0.008821009658277035,
              -0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              -0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.45802998542785645,
              0.04401100054383278,
              -0.023094000294804573,
              0.474808007478714,
              0.04224799945950508,
              5.036350003706502e-9,
              0.44765999913215637,
              0.042865999042987823,
              0.008821009658277035,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              0.6928460001945496,
              0.04401100054383278,
              -0.023094000294804573,
              0.7367730140686035,
              0.042865999042987823,
              0.008821009658277035,
              0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              0.5800949931144714,
              0.042865999042987823,
              0.008821009658277035,
              0.5257989764213562,
              0.042865999042987823,
              0.008821009658277035,
              0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.5361689925193787,
              0.04401100054383278,
              -0.023094000294804573,
              0.5529469847679138,
              0.04224799945950508,
              5.036350003706502e-9,
              0.5257989764213562,
              0.042865999042987823,
              0.008821009658277035,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              0.8182399868965149,
              0.045878998935222626,
              0.008821009658277035,
              0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              0.7743129730224609,
              0.04483800008893013,
              -0.023094000294804573,
              0.6581810116767883,
              0.042865999042987823,
              0.008821009658277035,
              0.6038849949836731,
              0.042865999042987823,
              0.008821009658277035,
              0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.6142550110816956,
              0.04401100054383278,
              -0.023094000294804573,
              0.6310330033302307,
              0.04224799945950508,
              5.036350003706502e-9,
              0.6038849949836731,
              0.042865999042987823,
              0.008821009658277035,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.1158900260925293,
              4.041440010070801,
              0.022098500281572342,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              0.22225500643253326,
              0.04439900070428848,
              0.022631000727415085,
              0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              0.24886000156402588,
              0.0431860014796257,
              -0.013987000100314617,
              0.6824759840965271,
              0.042865999042987823,
              0.008821009658277035,
              0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              0.7367730140686035,
              0.042865999042987823,
              0.008821009658277035,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.0625100135803223,
              4.468410015106201,
              -0.0923445001244545,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              0.6928460001945496,
              0.04401100054383278,
              -0.023094000294804573,
              0.7096239924430847,
              0.04224799945950508,
              5.036350003706502e-9,
              0.6824759840965271,
              0.042865999042987823,
              0.008821009658277035,
              0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.8195220232009888,
              4.656799793243408,
              -0.10972800105810165,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.22225500643253326,
              0.04439900070428848,
              -0.022631000727415085,
              0.24886000156402588,
              0.0431860014796257,
              -0.013987000100314617,
              0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              0.8182399868965149,
              0.045878998935222626,
              0.008821009658277035,
              0.7639430165290833,
              0.04344800114631653,
              0.008821009658277035,
              0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.7743129730224609,
              0.04483800008893013,
              -0.023094000294804573,
              0.7910910248756409,
              0.04347600042819977,
              5.182740014930687e-9,
              0.7639430165290833,
              0.04344800114631653,
              0.008821009658277035,
              0.22960899770259857,
              0.042204998433589935,
              5.031230099206141e-9,
              0.22225500643253326,
              0.04439900070428848,
              0.022631000727415085,
              0.22225500643253326,
              0.04439900070428848,
              -0.022631000727415085,
              0.2987540066242218,
              4.407509803771973,
              -0.11422500014305115,
              0.2555750012397766,
              4.466939926147461,
              -0.11422500014305115,
              0.3686180114746094,
              4.384809970855713,
              -0.11422500014305115,
              0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              0.9153169989585876,
              4.57289981842041,
              -0.11403399705886841,
              0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              0.2987540066242218,
              4.407509803771973,
              -0.11422500014305115,
              0.2987540066242218,
              4.334050178527832,
              -0.11422500014305115,
              0.2555750012397766,
              4.466939926147461,
              -0.11422500014305115,
              0.2987540066242218,
              4.407509803771973,
              -0.11422500014305115,
              0.3686180114746094,
              4.384809970855713,
              -0.11422500014305115,
              0.2987540066242218,
              4.334050178527832,
              -0.11422500014305115,
              0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.062880039215088,
              4.465539932250977,
              -0.0923445001244545,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.950203001499176,
              4.463689804077148,
              -0.11918000131845474,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.6155139803886414,
              4.493020057678223,
              -0.11403399705886841,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              1.0826900005340576,
              4.429059982299805,
              -0.08033949881792068,
              1.062880039215088,
              4.465539932250977,
              -0.0923445001244545,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.1101499795913696,
              4.4138898849487305,
              -0.04208650067448616,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              1.062880039215088,
              4.465539932250977,
              -0.0923445001244545,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              1.1101499795913696,
              4.4138898849487305,
              -0.04208650067448616,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.0826900005340576,
              4.429059982299805,
              -0.08033949881792068,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.0795899629592896,
              4.3846001625061035,
              -0.08370649814605713,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.5331689715385437,
              4.461659908294678,
              -0.11403399705886841,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.8195220232009888,
              4.656799793243408,
              -0.10972800105810165,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              0.8195220232009888,
              4.656799793243408,
              -0.10972800105810165,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              1.0524200201034546,
              4.367700099945068,
              -0.10232999920845032,
              1.0795899629592896,
              4.3846001625061035,
              -0.08370649814605713,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -1.1100499629974365,
              4.414239883422852,
              -0.042129501700401306,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              1.0524200201034546,
              4.367700099945068,
              -0.10232999920845032,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              0.4888190031051636,
              4.662300109863281,
              -0.10972800105810165,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              0.4888190031051636,
              4.662300109863281,
              -0.10972800105810165,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.5331689715385437,
              4.461659908294678,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -1.1158900260925293,
              4.26632022857666,
              -0.022097500041127205,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.4888190031051636,
              4.662300109863281,
              -0.10972800105810165,
              0.5487419962882996,
              4.640649795532227,
              -0.11403399705886841,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -1.0993599891662598,
              4.260049819946289,
              0.06254850327968597,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              0.9129629731178284,
              4.298180103302002,
              -0.13354800641536713,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              1.1103399991989136,
              3.746380090713501,
              0.04265740141272545,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              1.0524200201034546,
              4.367700099945068,
              -0.10232999920845032,
              1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              1.062880039215088,
              4.465539932250977,
              -0.0923445001244545,
              1.0826900005340576,
              4.429059982299805,
              -0.08033949881792068,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              0.47110500931739807,
              4.287320137023926,
              -0.11403399705886841,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              1.1101499795913696,
              4.4138898849487305,
              -0.04208650067448616,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              1.0524200201034546,
              4.367700099945068,
              -0.10232999920845032,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.0826900005340576,
              4.429059982299805,
              -0.08033949881792068,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              -0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -1.1100499629974365,
              4.414239883422852,
              -0.042129501700401306,
              -1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              0.06282199919223785,
              0.2443690001964569,
              0.10008499771356583,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              -0.9424409866333008,
              4.711719989776611,
              0.0220986008644104,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              -0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              -0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              -1.0625100135803223,
              4.468410015106201,
              -0.0923445001244545,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              -0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -1.0625100135803223,
              4.468410015106201,
              -0.0923445001244545,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.0794800519943237,
              4.429959774017334,
              -0.0822795033454895,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.0993599891662598,
              4.260049819946289,
              0.06254850327968597,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              -1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              -1.1100499629974365,
              4.414239883422852,
              -0.042129501700401306,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.051800012588501,
              4.366889953613281,
              -0.10277500003576279,
              -1.0770399570465088,
              4.381219863891602,
              -0.08556050062179565,
              -0.6155139803886414,
              4.493020057678223,
              -0.11403399705886841,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              -0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -1.0770399570465088,
              4.381219863891602,
              -0.08556050062179565,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -0.6155139803886414,
              4.493020057678223,
              -0.11403399705886841,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              -0.6155139803886414,
              4.493020057678223,
              -0.11403399705886841,
              -0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              -0.8367230296134949,
              4.088759899139404,
              -0.11403500288724899,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0,
              4.73291015625,
              -0.06254740059375763,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0,
              4.702300071716309,
              -0.09316039830446243,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0,
              4.702300071716309,
              -0.09316039830446243,
              -0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              -0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              -0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -1.110360026359558,
              4.208789825439453,
              -0.042901501059532166,
              -1.1158900260925293,
              4.26632022857666,
              -0.022097500041127205,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0,
              4.73291015625,
              -0.06254740059375763,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              0,
              4.73291015625,
              0.06254860013723373,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              0,
              4.702300071716309,
              0.09316159784793854,
              -0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -1.051800012588501,
              4.366889953613281,
              -0.10277500003576279,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              -1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -0.9953380227088928,
              4.359930038452148,
              -0.11403399705886841,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -1.051800012588501,
              4.366889953613281,
              -0.10277500003576279,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -1.0770399570465088,
              4.381219863891602,
              -0.08556050062179565,
              -0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.9424409866333008,
              4.711719989776611,
              0.0220986008644104,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              -1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              -0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -1.1100499629974365,
              4.414239883422852,
              -0.042129501700401306,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -1.051800012588501,
              4.366889953613281,
              -0.10277500003576279,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              -0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              0.9594249725341797,
              0.20136399567127228,
              0.10972899943590164,
              0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              0.9594249725341797,
              0.20136499404907227,
              -0.10972899943590164,
              0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -1.1118299961090088,
              4.161910057067871,
              0.03923150151968002,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              -1.1007299423217773,
              4.143909931182861,
              0.0321355015039444,
              1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.9153169989585876,
              4.57289981842041,
              -0.11403399705886841,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              1.0648399591445923,
              0.3922080099582672,
              -0.09494800120592117,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              0,
              4.493020057678223,
              0.11403600126504898,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              -0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              -1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              -0.9594249725341797,
              0.20136499404907227,
              0.10972899943590164,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              -1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              -1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -1.0648399591445923,
              0.392208993434906,
              -0.09494800120592117,
              -1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              -1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              -0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              -0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              -1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              -0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -1.1118299961090088,
              3.9671199321746826,
              0.03923150151968002,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              -0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              -0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              -0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              -1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              1.1103399991989136,
              3.4428598880767822,
              -0.04265660047531128,
              1.0944900512695312,
              3.4548399448394775,
              -0.04629559814929962,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              -0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0,
              0.0920410007238388,
              0.09316100180149078,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0,
              0.05179600045084953,
              0.045914001762866974,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              1.1103399991989136,
              3.418519973754883,
              4.075189963259618e-7,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              -0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              -1.0648399591445923,
              0.392208993434906,
              -0.09494800120592117,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              -1.0648399591445923,
              0.392208993434906,
              -0.09494800120592117,
              -1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              -1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              1.1101499795913696,
              4.4138898849487305,
              -0.04208650067448616,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              1.1103399991989136,
              3.418519973754883,
              4.075189963259618e-7,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              -0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              0.9971299767494202,
              4.179880142211914,
              -0.11334600299596786,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              -1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.110360026359558,
              4.208789825439453,
              0.04290249943733215,
              -1.0993599891662598,
              4.260049819946289,
              0.06254850327968597,
              -1.051800012588501,
              4.366889953613281,
              -0.10277500003576279,
              -1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.0944900512695312,
              3.769350051879883,
              -0.013713600113987923,
              1.1103399991989136,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.0944900512695312,
              3.769350051879883,
              -0.013713600113987923,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.0944900512695312,
              3.757740020751953,
              -0.03609060123562813,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.1347600221633911,
              3.74960994720459,
              -0.02739959955215454,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1347600221633911,
              3.74960994720459,
              -0.02739959955215454,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              -0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              1.1347600221633911,
              3.74960994720459,
              0.02740040048956871,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.1347600221633911,
              3.7276599407196045,
              0.03005640022456646,
              1.1347600221633911,
              3.74960994720459,
              0.02740040048956871,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.74960994720459,
              -0.02739959955215454,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1103399991989136,
              3.418519973754883,
              4.075189963259618e-7,
              1.0944900512695312,
              3.4198999404907227,
              -0.013713600113987923,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1347600221633911,
              3.5946199893951416,
              0.03005640022456646,
              1.1347600221633911,
              3.7276599407196045,
              0.03005640022456646,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1103399991989136,
              3.5946199893951416,
              -0.0475086010992527,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              1.0944900512695312,
              3.757740020751953,
              0.036091398447752,
              1.1103399991989136,
              3.746380090713501,
              0.04265740141272545,
              1.0944900512695312,
              3.7344000339508057,
              -0.04629559814929962,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.0944900512695312,
              3.6885199546813965,
              -0.0475086010992527,
              1.1347600221633911,
              3.74960994720459,
              0.02740040048956871,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              1.1347600221633911,
              3.7276599407196045,
              -0.03005559928715229,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.0944900512695312,
              3.769350051879883,
              0.013714400120079517,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              1.1103399991989136,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.0944900512695312,
              3.4548399448394775,
              0.04629639908671379,
              1.1103399991989136,
              3.4428598880767822,
              0.04265740141272545,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              -1.1473400592803955,
              3.74960994720459,
              -0.01887959986925125,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.1473400592803955,
              3.74960994720459,
              0.01888040080666542,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1304800510406494,
              3.4427499771118164,
              0.0411624014377594,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              1.1347600221633911,
              3.4396300315856934,
              0.02740040048956871,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              1.1347600221633911,
              3.4396300315856934,
              0.02740040048956871,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              1.1347600221633911,
              3.4396300315856934,
              -0.02739959955215454,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1347600221633911,
              3.4396300315856934,
              -0.02739959955215454,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1347600221633911,
              3.4396300315856934,
              -0.02739959955215454,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1347600221633911,
              3.4396300315856934,
              0.02740040048956871,
              1.1304800510406494,
              3.4203200340270996,
              4.077340065578028e-7,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              1.0944900512695312,
              3.6885199546813965,
              0.04750939831137657,
              1.1103399991989136,
              3.5946199893951416,
              0.04750939831137657,
              1.1304800510406494,
              3.4427499771118164,
              0.0411624014377594,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              1.0944900512695312,
              3.4198999404907227,
              0.013714400120079517,
              1.0944900512695312,
              3.4198999404907227,
              -0.013713600113987923,
              1.1103399991989136,
              3.418519973754883,
              4.075189963259618e-7,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              1.0944900512695312,
              3.500730037689209,
              -0.0475086010992527,
              1.1103399991989136,
              3.5946199893951416,
              -0.0475086010992527,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1103399991989136,
              3.4428598880767822,
              -0.04265660047531128,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              1.0944900512695312,
              3.500730037689209,
              0.04750939831137657,
              1.0944900512695312,
              3.4548399448394775,
              0.04629639908671379,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              1.0944900512695312,
              3.43149995803833,
              0.036091398447752,
              1.0944900512695312,
              3.4198999404907227,
              0.013714400120079517,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              1.0944900512695312,
              3.43149995803833,
              0.036091398447752,
              1.1103399991989136,
              3.4428598880767822,
              0.04265740141272545,
              1.0944900512695312,
              3.4548399448394775,
              0.04629639908671379,
              1.0944900512695312,
              3.757740020751953,
              -0.03609060123562813,
              1.1103399991989136,
              3.746380090713501,
              -0.04265660047531128,
              1.0944900512695312,
              3.7344000339508057,
              -0.04629559814929962,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1103399991989136,
              3.5946199893951416,
              -0.0475086010992527,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.0944900512695312,
              3.4548399448394775,
              -0.04629559814929962,
              1.1103399991989136,
              3.4428598880767822,
              -0.04265660047531128,
              1.0944900512695312,
              3.43149995803833,
              -0.03609060123562813,
              1.0944900512695312,
              3.4198999404907227,
              -0.013713600113987923,
              1.0944900512695312,
              3.43149995803833,
              -0.03609060123562813,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1347600221633911,
              3.5946199893951416,
              0.03005640022456646,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              1.0944900512695312,
              3.6885199546813965,
              0.04750939831137657,
              1.0944900512695312,
              3.500730037689209,
              0.04750939831137657,
              1.1103399991989136,
              3.5946199893951416,
              0.04750939831137657,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.141510009765625,
              3.746500015258789,
              0.028362400829792023,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              1.1347600221633911,
              3.5946199893951416,
              -0.03005559928715229,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1347600221633911,
              3.7276599407196045,
              -0.03005559928715229,
              -1.114050030708313,
              3.4428598880767822,
              0.02939240075647831,
              -1.0924299955368042,
              3.4548399448394775,
              0.031900398433208466,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              1.0944900512695312,
              3.6885199546813965,
              0.04750939831137657,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              1.0944900512695312,
              3.7344000339508057,
              0.04629639908671379,
              -1.0924299955368042,
              3.4548399448394775,
              -0.031899601221084595,
              -1.114050030708313,
              3.4428598880767822,
              -0.02939159981906414,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              1.0944900512695312,
              3.7344000339508057,
              0.04629639908671379,
              1.1103399991989136,
              3.746380090713501,
              0.04265740141272545,
              1.0944900512695312,
              3.757740020751953,
              0.036091398447752,
              1.0944900512695312,
              3.757740020751953,
              0.036091398447752,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              1.0944900512695312,
              3.769350051879883,
              0.013714400120079517,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.0944900512695312,
              3.7344000339508057,
              -0.04629559814929962,
              1.1103399991989136,
              3.746380090713501,
              -0.04265660047531128,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.1473400592803955,
              3.5946199893951416,
              0.020710399374365807,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1347600221633911,
              3.5946199893951416,
              -0.03005559928715229,
              1.1347600221633911,
              3.4615800380706787,
              -0.03005559928715229,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.0944900512695312,
              3.6885199546813965,
              -0.0475086010992527,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.1103399991989136,
              3.5946199893951416,
              -0.0475086010992527,
              1.0944900512695312,
              3.500730037689209,
              -0.0475086010992527,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              1.0944900512695312,
              3.4548399448394775,
              -0.04629559814929962,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              1.1347600221633911,
              3.4396300315856934,
              0.02740040048956871,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.7276599407196045,
              -0.03005559928715229,
              1.1347600221633911,
              3.74960994720459,
              -0.02739959955215454,
              1.0944900512695312,
              3.500730037689209,
              -0.0475086010992527,
              1.0944900512695312,
              3.6885199546813965,
              -0.0475086010992527,
              1.1103399991989136,
              3.5946199893951416,
              -0.0475086010992527,
              1.1304800510406494,
              3.746500015258789,
              0.0411624014377594,
              1.1103399991989136,
              3.746380090713501,
              0.04265740141272545,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              1.1103399991989136,
              3.4428598880767822,
              0.04265740141272545,
              1.1304800510406494,
              3.4427499771118164,
              0.0411624014377594,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              -1.114050030708313,
              3.5946199893951416,
              0.03273539990186691,
              -1.0924299955368042,
              3.6885199546813965,
              0.03273539990186691,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.114050030708313,
              3.746380090713501,
              0.02939240075647831,
              -1.141510009765625,
              3.746500015258789,
              0.028362400829792023,
              -1.141510009765625,
              3.746500015258789,
              0.028362400829792023,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.1473400592803955,
              3.74960994720459,
              0.01888040080666542,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              -1.1473400592803955,
              3.74960994720459,
              0.01888040080666542,
              -1.0924299955368042,
              3.6885199546813965,
              -0.032734598964452744,
              -1.114050030708313,
              3.5946199893951416,
              -0.032734598964452744,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.1473400592803955,
              3.030319929122925,
              -0.020709600299596786,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.0924299955368042,
              3.4198999404907227,
              -0.009448589757084846,
              -1.114050030708313,
              3.418519973754883,
              4.075189963259618e-7,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              -1.1473400592803955,
              3.74960994720459,
              0.01888040080666542,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.1473400592803955,
              3.030319929122925,
              0.020710399374365807,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.114050030708313,
              3.4428598880767822,
              -0.02939159981906414,
              -1.0924299955368042,
              3.43149995803833,
              -0.024867599830031395,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.0924299955368042,
              3.769350051879883,
              0.009449450299143791,
              -1.114050030708313,
              3.7707300186157227,
              4.4950499500373553e-7,
              -1.114050030708313,
              3.418519973754883,
              4.075189963259618e-7,
              -1.141510009765625,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.114050030708313,
              3.418519973754883,
              4.075189963259618e-7,
              -1.0924299955368042,
              3.4198999404907227,
              -0.009448589757084846,
              -1.0924299955368042,
              3.4198999404907227,
              0.009449410252273083,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.114050030708313,
              3.4428598880767822,
              0.02939240075647831,
              -1.141510009765625,
              3.4427499771118164,
              0.028362400829792023,
              -1.1473400592803955,
              3.4615800380706787,
              -0.020709600299596786,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.114050030708313,
              3.4428598880767822,
              0.02939240075647831,
              -1.0924299955368042,
              3.43149995803833,
              0.024868400767445564,
              -1.0924299955368042,
              3.4548399448394775,
              0.031900398433208466,
              -1.141510009765625,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.141510009765625,
              3.4427499771118164,
              0.028362400829792023,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.114050030708313,
              3.163360118865967,
              -0.032734598964452744,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.1473400592803955,
              3.3183600902557373,
              0.01888040080666542,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.1473400592803955,
              3.3183600902557373,
              -0.01887959986925125,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              -1.114050030708313,
              3.315119981765747,
              -0.029392600059509277,
              -1.0924299955368042,
              3.303149938583374,
              -0.031899601221084595,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.1473400592803955,
              3.5946199893951416,
              0.020710399374365807,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.1473400592803955,
              3.74960994720459,
              -0.01887959986925125,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.114050030708313,
              3.746380090713501,
              -0.029392600059509277,
              -1.0924299955368042,
              3.757740020751953,
              -0.024867599830031395,
              -1.0924299955368042,
              3.7344000339508057,
              -0.031899601221084595,
              -1.114050030708313,
              3.4428598880767822,
              -0.02939159981906414,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              -1.1473400592803955,
              3.5946199893951416,
              -0.020709600299596786,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.1473400592803955,
              3.4615800380706787,
              -0.020709600299596786,
              -1.114050030708313,
              3.746380090713501,
              0.02939240075647831,
              -1.0924299955368042,
              3.7344000339508057,
              0.031900398433208466,
              -1.0924299955368042,
              3.757740020751953,
              0.024868400767445564,
              -1.114050030708313,
              3.4428598880767822,
              -0.02939159981906414,
              -1.0924299955368042,
              3.4548399448394775,
              -0.031899601221084595,
              -1.0924299955368042,
              3.43149995803833,
              -0.024867599830031395,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.0924299955368042,
              3.7344000339508057,
              -0.031899601221084595,
              -1.0924299955368042,
              3.6885199546813965,
              -0.032734598964452744,
              -1.1473400592803955,
              3.4615800380706787,
              -0.020709600299596786,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.1473400592803955,
              3.5946199893951416,
              -0.020709600299596786,
              -1.114050030708313,
              3.5946199893951416,
              0.03273539990186691,
              -1.0924299955368042,
              3.500730037689209,
              0.03273539990186691,
              -1.0924299955368042,
              3.6885199546813965,
              0.03273539990186691,
              -1.0924299955368042,
              3.338089942932129,
              -0.009448600001633167,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.114050030708313,
              3.3394699096679688,
              3.980950111781567e-7,
              -1.114050030708313,
              3.7707300186157227,
              4.4950499500373553e-7,
              -1.0924299955368042,
              3.769350051879883,
              0.009449450299143791,
              -1.0924299955368042,
              3.769350051879883,
              -0.009448549710214138,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.141510009765625,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.0924299955368042,
              3.4198999404907227,
              0.009449410252273083,
              -1.0924299955368042,
              3.43149995803833,
              0.024868400767445564,
              -1.114050030708313,
              3.5946199893951416,
              -0.032734598964452744,
              -1.0924299955368042,
              3.500730037689209,
              -0.032734598964452744,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              -1.0924299955368042,
              3.0235800743103027,
              -0.031899601221084595,
              -1.114050030708313,
              3.0116100311279297,
              -0.02939159981906414,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.1473400592803955,
              3.5946199893951416,
              -0.020709600299596786,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.1473400592803955,
              3.3183600902557373,
              -0.01887959986925125,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.141510009765625,
              3.315239906311035,
              -0.028361599892377853,
              -1.1473400592803955,
              3.3183600902557373,
              -0.01887959986925125,
              -1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.114050030708313,
              3.163360118865967,
              -0.032734598964452744,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.114050030708313,
              3.0116100311279297,
              0.02939240075647831,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.141510009765625,
              2.9890599250793457,
              3.5632399431051454e-7,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.114050030708313,
              3.0116100311279297,
              -0.02939159981906414,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.0924299955368042,
              2.988640069961548,
              -0.009448640048503876,
              -1.114050030708313,
              2.987260103225708,
              3.5610901250038296e-7,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              0.06282199919223785,
              0.2443690001964569,
              0.10008499771356583,
              0.0025289999321103096,
              0.23587800562381744,
              0.10008499771356583,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.114050030708313,
              3.315119981765747,
              0.02939240075647831,
              -1.141510009765625,
              3.315239906311035,
              0.028362400829792023,
              -1.1473400592803955,
              3.030319929122925,
              0.020710399374365807,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.0924299955368042,
              3.0002400875091553,
              -0.024867599830031395,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.114050030708313,
              3.0116100311279297,
              -0.02939159981906414,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.114050030708313,
              2.987260103225708,
              3.5610901250038296e-7,
              -1.141510009765625,
              2.9890599250793457,
              3.5632399431051454e-7,
              -1.114050030708313,
              2.987260103225708,
              3.5610901250038296e-7,
              -1.0924299955368042,
              2.988640069961548,
              -0.009448640048503876,
              -1.0924299955368042,
              2.988640069961548,
              0.009449359960854053,
              -1.1473400592803955,
              3.030319929122925,
              -0.020709600299596786,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.114050030708313,
              3.163360118865967,
              0.03273539990186691,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.0924299955368042,
              3.0235800743103027,
              0.031900398433208466,
              -1.0924299955368042,
              3.069469928741455,
              0.03273539990186691,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              -1.1473400592803955,
              3.3183600902557373,
              -0.01887959986925125,
              -1.0924299955368042,
              3.338089942932129,
              0.009449400007724762,
              -1.114050030708313,
              3.3394699096679688,
              3.980950111781567e-7,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.0924299955368042,
              3.2572600841522217,
              -0.032734598964452744,
              -1.114050030708313,
              3.163360118865967,
              -0.032734598964452744,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.1473400592803955,
              3.030319929122925,
              0.020710399374365807,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.141510009765625,
              3.315239906311035,
              -0.028361599892377853,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.114050030708313,
              2.987260103225708,
              3.5610901250038296e-7,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.141510009765625,
              2.9890599250793457,
              3.5632399431051454e-7,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.0924299955368042,
              2.988640069961548,
              0.009449359960854053,
              -1.0924299955368042,
              3.0002400875091553,
              0.024868400767445564,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.1473400592803955,
              3.3183600902557373,
              0.01888040080666542,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.1473400592803955,
              3.3183600902557373,
              0.01888040080666542,
              -1.141510009765625,
              3.315239906311035,
              0.028362400829792023,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.141510009765625,
              3.315239906311035,
              0.028362400829792023,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.1473400592803955,
              3.030319929122925,
              -0.020709600299596786,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.114050030708313,
              3.315119981765747,
              0.02939240075647831,
              -1.0924299955368042,
              3.303149938583374,
              0.031900398433208466,
              -1.0924299955368042,
              3.326479911804199,
              0.024868400767445564,
              -1.114050030708313,
              3.0116100311279297,
              -0.02939159981906414,
              -1.0924299955368042,
              3.0235800743103027,
              -0.031899601221084595,
              -1.0924299955368042,
              3.0002400875091553,
              -0.024867599830031395,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.0924299955368042,
              3.303149938583374,
              -0.031899601221084595,
              -1.0924299955368042,
              3.2572600841522217,
              -0.032734598964452744,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.114050030708313,
              3.315119981765747,
              -0.029392600059509277,
              -1.141510009765625,
              3.315239906311035,
              -0.028361599892377853,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.1473400592803955,
              3.030319929122925,
              -0.020709600299596786,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.1060999631881714,
              4.158090114593506,
              0.028110500425100327,
              -1.084380030632019,
              4.1623101234436035,
              0.025190500542521477,
              -1.1060999631881714,
              4.1650800704956055,
              0.020532499998807907,
              -1.0924299955368042,
              2.988640069961548,
              0.009449359960854053,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.114050030708313,
              2.987260103225708,
              3.5610901250038296e-7,
              1.029520034790039,
              4.1471099853515625,
              -0.10972899943590164,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              -0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              -0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              -1.1007299423217773,
              4.143909931182861,
              0.0321355015039444,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.084380030632019,
              4.153059959411621,
              0.029647499322891235,
              -1.1060999631881714,
              4.158090114593506,
              0.028110500425100327,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -1.1060999631881714,
              3.9865200519561768,
              -0.000152524997247383,
              -1.084380030632019,
              3.981329917907715,
              0.00035947500145994127,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -1.084380030632019,
              4.125070095062256,
              0.030159499496221542,
              -1.084380030632019,
              4.153059959411621,
              0.029647499322891235,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              -0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              -0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              -1.1060999631881714,
              4.067200183868408,
              0.030159499496221542,
              -1.084380030632019,
              4.125070095062256,
              0.030159499496221542,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.084380030632019,
              3.9720799922943115,
              0.025190500542521477,
              -1.084380030632019,
              3.981329917907715,
              0.029647499322891235,
              -1.1060999631881714,
              3.9763100147247314,
              0.028110500425100327,
              -1.084380030632019,
              4.1623101234436035,
              0.025190500542521477,
              -1.1060999631881714,
              4.158090114593506,
              0.028110500425100327,
              -1.084380030632019,
              4.153059959411621,
              0.029647499322891235,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.1060999631881714,
              3.9693100452423096,
              0.020532499998807907,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              -1.084380030632019,
              4.00931978225708,
              0.030159499496221542,
              -1.1060999631881714,
              4.067200183868408,
              0.030159499496221542,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              -1.084380030632019,
              3.968280076980591,
              0.015003499574959278,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.084380030632019,
              3.9720799922943115,
              0.004816470202058554,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.1287800073623657,
              4.159560203552246,
              0.00886049959808588,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.1282999515533447,
              4.147950172424316,
              0.00036449398612603545,
              -1.1287800073623657,
              4.159560203552246,
              0.00886049959808588,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              -1.084380030632019,
              3.9720799922943115,
              0.004816470202058554,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.1060999631881714,
              3.9693100452423096,
              0.020532499998807907,
              -1.1060999631881714,
              3.9763100147247314,
              0.028110500425100327,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              -1.1060999631881714,
              4.1650800704956055,
              0.020532499998807907,
              -1.084380030632019,
              4.166110038757324,
              0.015003499574959278,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              -1.1060999631881714,
              4.067200183868408,
              0.030159499496221542,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.084380030632019,
              4.1623101234436035,
              0.004816500004380941,
              -1.084380030632019,
              4.153059959411621,
              0.00035949499579146504,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.1060999631881714,
              4.147870063781738,
              -0.00015250600699800998,
              -1.1282999515533447,
              4.147950172424316,
              0.00036449398612603545,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.1060999631881714,
              3.9865200519561768,
              -0.000152524997247383,
              -1.1282999515533447,
              3.9864399433135986,
              0.00036447501042857766,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              -1.084380030632019,
              3.9720799922943115,
              0.004816470202058554,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              -1.084380030632019,
              3.981329917907715,
              0.00035947500145994127,
              -1.084380030632019,
              4.1623101234436035,
              0.004816500004380941,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.084380030632019,
              4.166110038757324,
              0.015003499574959278,
              0,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              -1.084380030632019,
              3.9720799922943115,
              0.025190500542521477,
              -1.1060999631881714,
              3.9763100147247314,
              0.028110500425100327,
              -1.1060999631881714,
              3.9693100452423096,
              0.020532499998807907,
              -1.084380030632019,
              4.125070095062256,
              0.030159499496221542,
              -1.1060999631881714,
              4.067200183868408,
              0.030159499496221542,
              -1.084380030632019,
              4.00931978225708,
              0.030159499496221542,
              -1.1282999515533447,
              3.9864399433135986,
              0.00036447501042857766,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1282999515533447,
              4.147950172424316,
              0.00036449398612603545,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              0,
              0.15921300649642944,
              0.11403500288724899,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              -0.06973099708557129,
              0.30497100949287415,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              -0.2020380049943924,
              0.2538140118122101,
              0.11403500288724899,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.11695399880409241,
              0.2669000029563904,
              0.10008499771356583,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              0.22099100053310394,
              0.31960999965667725,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.0025289999321103096,
              0.23587800562381744,
              0.10008499771356583,
              -0.058024998754262924,
              0.24225999414920807,
              0.10008499771356583,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.22099100053310394,
              0.31960999965667725,
              0.11403500288724899,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.1361899971961975,
              0.3212929964065552,
              0.10008499771356583,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              0,
              0.15921300649642944,
              0.11403500288724899,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0,
              0.15921300649642944,
              0.11403500288724899,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              0.22099100053310394,
              0.31960999965667725,
              0.11403500288724899,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.21984100341796875,
              0.4441930055618286,
              0.11403500288724899,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0,
              0.15921300649642944,
              0.11403500288724899,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -0.004213999956846237,
              4.605460166931152,
              0.1145630031824112,
              0.01686600036919117,
              4.5986199378967285,
              0.1145630031824112,
              -0.004213999956846237,
              4.5833001136779785,
              0.1145630031824112,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              0,
              0.05179600045084953,
              0.045914001762866974,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              -0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.5487419962882996,
              4.640649795532227,
              -0.11403399705886841,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              1.0795899629592896,
              4.3846001625061035,
              -0.08370649814605713,
              1.0959500074386597,
              4.410399913787842,
              -0.06605350226163864,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              1.1101499795913696,
              4.4138898849487305,
              -0.04208650067448616,
              1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0,
              3.535569906234741,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0,
              3.535569906234741,
              0.11403500288724899,
              1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.2020380049943924,
              0.2538140118122101,
              0.11403500288724899,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0,
              2.4522900581359863,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              0.8859320282936096,
              4.235499858856201,
              -0.11918000131845474,
              0.9129629731178284,
              4.298180103302002,
              -0.13354800641536713,
              0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              0.6135740280151367,
              4.407149791717529,
              -0.14484399557113647,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              0.6135740280151367,
              4.407149791717529,
              -0.14484399557113647,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.7543860077857971,
              4.395390033721924,
              -0.14484399557113647,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0,
              4.662300109863281,
              0.10972999781370163,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              -0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              -0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              -0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0,
              4.702300071716309,
              0.09316159784793854,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -1.0993599891662598,
              3.8229899406433105,
              0.06254850327968597,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.0993599891662598,
              3.643199920654297,
              0.06254839897155762,
              -0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              -0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              -0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              -0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              0,
              4.662300109863281,
              0.10972999781370163,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              -0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              -0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              -0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              -0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              -0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              -0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              -0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              0.9594249725341797,
              0.20136399567127228,
              0.10972899943590164,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.493020057678223,
              0.11403600126504898,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              -0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              -0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.8326730132102966,
              0.2147579938173294,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              0,
              3.909450054168701,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              -0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0,
              3.535569906234741,
              0.11403500288724899,
              -0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.0794800519943237,
              4.429959774017334,
              -0.0822795033454895,
              -1.0625100135803223,
              4.468410015106201,
              -0.0923445001244545,
              -1.0993599891662598,
              3.643199920654297,
              0.06254839897155762,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.8326730132102966,
              0.21475699543952942,
              0.11403500288724899,
              0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              0.15678100287914276,
              0.5536249876022339,
              0.11403500288724899,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.17157499492168427,
              0.43320000171661377,
              0.10706000030040741,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.06973099708557129,
              0.30497100949287415,
              0.10008499771356583,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              -0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              -0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              -0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -1.0648399591445923,
              0.392208993434906,
              -0.09494800120592117,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              0.3693079948425293,
              0.3730889856815338,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              0.21984100341796875,
              0.4441930055618286,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.21984100341796875,
              0.4441930055618286,
              0.11403500288724899,
              0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0.15678100287914276,
              0.5536249876022339,
              0.11403500288724899,
              0.3693079948425293,
              0.3730889856815338,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.3693079948425293,
              0.3730889856815338,
              0.11403500288724899,
              0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.22099100053310394,
              0.31960999965667725,
              0.11403500288724899,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.3693079948425293,
              0.3730889856815338,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              -0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              0.8326730132102966,
              0.21475699543952942,
              0.11403500288724899,
              1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.8326730132102966,
              0.2147579938173294,
              0.11403500288724899,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              -1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              -0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              -0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              -0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0,
              0.0920410007238388,
              0.09316100180149078,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0,
              0.0920410007238388,
              0.09316100180149078,
              -0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              -0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.05841200053691864,
              0.550383985042572,
              0.10706000030040741,
              -0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              -0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              -0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -0.4924109876155853,
              0.30132099986076355,
              0.11403500288724899,
              -0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              0,
              0.05179600045084953,
              0.045914001762866974,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              -0.9594249725341797,
              0.20136499404907227,
              0.10972899943590164,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              -0.2020380049943924,
              0.2538140118122101,
              0.11403500288724899,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.4888190031051636,
              4.662300109863281,
              -0.10972800105810165,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              0,
              4.744440078735352,
              0.039028599858284,
              0,
              4.73291015625,
              0.06254860013723373,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0.28727099299430847,
              0.3736119866371155,
              0.11403500288724899,
              0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.3693079948425293,
              0.3730889856815338,
              0.11403500288724899,
              0,
              0.0920410007238388,
              0.09316100180149078,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0,
              0.15921300649642944,
              0.11403500288724899,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              -0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.058024998754262924,
              0.24225999414920807,
              0.10008499771356583,
              0.0025289999321103096,
              0.23587800562381744,
              0.10008499771356583,
              -0.06973099708557129,
              0.30497100949287415,
              0.10008499771356583,
              -0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              -0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              -0.05841200053691864,
              0.550383985042572,
              0.10706000030040741,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              -0.05841200053691864,
              0.550383985042572,
              0.10706000030040741,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              0.8326730132102966,
              0.21475699543952942,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              -0.8326730132102966,
              0.2147579938173294,
              0.11403500288724899,
              -0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -0.7386170029640198,
              0.30132099986076355,
              0.11403500288724899,
              -0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.05841200053691864,
              0.550383985042572,
              0.10706000030040741,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0.15678100287914276,
              0.5536249876022339,
              0.11403500288724899,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              0.15678100287914276,
              0.5536249876022339,
              0.11403500288724899,
              0.14206400513648987,
              0.3832240104675293,
              0.10008499771356583,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.11695399880409241,
              0.2669000029563904,
              0.10008499771356583,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.1361899971961975,
              0.3212929964065552,
              0.10008499771356583,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              -0.2020380049943924,
              0.2538140118122101,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.1340319961309433,
              0.44491198658943176,
              0.10008499771356583,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.21984100341796875,
              0.4441930055618286,
              0.11403500288724899,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              -0.17157499492168427,
              0.43320000171661377,
              0.10706000030040741,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              -0.17157499492168427,
              0.43320000171661377,
              0.10706000030040741,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.07233300060033798,
              0.45403799414634705,
              0.10008499771356583,
              -0.05841200053691864,
              0.550383985042572,
              0.10706000030040741,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.17157499492168427,
              0.43320000171661377,
              0.10706000030040741,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              -0.17157499492168427,
              0.43320000171661377,
              0.10706000030040741,
              -0.2858949899673462,
              0.45294299721717834,
              0.11403500288724899,
              -0.2860429883003235,
              0.3070560097694397,
              0.11403500288724899,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.1340319961309433,
              0.44491198658943176,
              0.10008499771356583,
              0.14206400513648987,
              0.3832240104675293,
              0.10008499771356583,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              -0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              -0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0,
              0.15921300649642944,
              0.11403500288724899,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -1.0993599891662598,
              3.9389500617980957,
              -0.06254749745130539,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              -0.06973099708557129,
              0.30497100949287415,
              0.10008499771356583,
              0.0025289999321103096,
              0.23587800562381744,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.1361899971961975,
              0.3212929964065552,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.11695399880409241,
              0.2669000029563904,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.06282199919223785,
              0.2443690001964569,
              0.10008499771356583,
              0.11695399880409241,
              0.2669000029563904,
              0.10008499771356583,
              0.06282199919223785,
              0.2443690001964569,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.0025289999321103096,
              0.23587800562381744,
              0.10008499771356583,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              0.1340319961309433,
              0.44491198658943176,
              0.10008499771356583,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              0.1340319961309433,
              0.44491198658943176,
              0.10008499771356583,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              0.052455998957157135,
              0.5523189902305603,
              0.10706000030040741,
              0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              0.21984100341796875,
              0.4441930055618286,
              0.11403500288724899,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.15678100287914276,
              0.5536249876022339,
              0.11403500288724899,
              0.14328700304031372,
              0.4887180030345917,
              0.10706000030040741,
              -1.0794800519943237,
              4.429959774017334,
              -0.0822795033454895,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.0625100135803223,
              4.468410015106201,
              -0.0923445001244545,
              0.1340319961309433,
              0.44491198658943176,
              0.10008499771356583,
              0.06973099708557129,
              0.4565179944038391,
              0.10008499771356583,
              0.14206400513648987,
              0.3832240104675293,
              0.10008499771356583,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.6152989864349365,
              4.73291015625,
              -0.06254740059375763,
              0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              0.8326730132102966,
              4.579579830169678,
              0.11403600126504898,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0,
              4.702300071716309,
              0.09316159784793854,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              1.0795899629592896,
              4.3846001625061035,
              -0.08370649814605713,
              1.0524200201034546,
              4.367700099945068,
              -0.10232999920845032,
              1.0648399591445923,
              4.402130126953125,
              -0.09494750201702118,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.5331689715385437,
              4.461659908294678,
              -0.11403399705886841,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              1.062880039215088,
              4.465539932250977,
              -0.0923445001244545,
              1.0361299514770508,
              4.554609775543213,
              -0.09316050261259079,
              1.0905699729919434,
              4.469470024108887,
              -0.0643405020236969,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.5487419962882996,
              4.640649795532227,
              -0.11403399705886841,
              0.4888190031051636,
              4.662300109863281,
              -0.10972800105810165,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.24537800252437592,
              4.702300071716309,
              0.09316159784793854,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              1.0795899629592896,
              4.3846001625061035,
              -0.08370649814605713,
              1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0,
              4.702300071716309,
              -0.09316039830446243,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0,
              4.702300071716309,
              -0.09316039830446243,
              0,
              4.744440078735352,
              -0.03902740031480789,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              0.7386170029640198,
              4.493020057678223,
              0.11403600126504898,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.9424409866333008,
              4.711719989776611,
              0.0220986008644104,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              1.1158900260925293,
              4.041440010070801,
              0.022098500281572342,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.920091986656189,
              4.192339897155762,
              -0.11403500288724899,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              1.029520034790039,
              4.1471099853515625,
              -0.10972899943590164,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              1.1158900260925293,
              4.041440010070801,
              0.022098500281572342,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1282999515533447,
              3.9864399433135986,
              0.00036447501042857766,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              1.029520034790039,
              4.1471099853515625,
              -0.10972899943590164,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              0.920091986656189,
              4.192339897155762,
              -0.11403500288724899,
              0.8859320282936096,
              4.235499858856201,
              -0.11918000131845474,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.0993599891662598,
              4.254089832305908,
              -0.06254749745130539,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              -1.1287800073623657,
              3.974829912185669,
              0.008860469795763493,
              -1.1282999515533447,
              3.9864399433135986,
              0.00036447501042857766,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              -0.12310300022363663,
              4.285600185394287,
              -0.11403399705886841,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.47110500931739807,
              4.287320137023926,
              -0.11403399705886841,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              -1.1118299961090088,
              4.161910057067871,
              0.03923150151968002,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              0.47110500931739807,
              4.287320137023926,
              -0.11403399705886841,
              0.5198360085487366,
              4.221350193023682,
              -0.11403399705886841,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              0.5198360085487366,
              4.221350193023682,
              -0.11403399705886841,
              0.47110500931739807,
              4.287320137023926,
              -0.11403399705886841,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.8779659867286682,
              4.059179782867432,
              -0.11403500288724899,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              0.8779659867286682,
              4.059179782867432,
              -0.11403500288724899,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              0.578199028968811,
              4.175759792327881,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.6626589894294739,
              4.155980110168457,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.4733070135116577,
              4.178770065307617,
              -0.11403500288724899,
              0.5198360085487366,
              4.221350193023682,
              -0.11403399705886841,
              0.578199028968811,
              4.175759792327881,
              -0.11403500288724899,
              0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.6503700017929077,
              4.519350051879883,
              -0.14484499394893646,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -1.0993599891662598,
              4.344779968261719,
              0.06254850327968597,
              -1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              0.9971299767494202,
              4.179880142211914,
              -0.11334600299596786,
              1.029520034790039,
              4.1471099853515625,
              -0.10972899943590164,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.6082730293273926,
              4.25993013381958,
              -0.11918000131845474,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              0.5572789907455444,
              4.544610023498535,
              -0.11403399705886841,
              0.5331689715385437,
              4.461659908294678,
              -0.11403399705886841,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.5572789907455444,
              4.544610023498535,
              -0.11403399705886841,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              0.5331689715385437,
              4.461659908294678,
              -0.11403399705886841,
              -0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -1.0770399570465088,
              4.381219863891602,
              -0.08556050062179565,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.0931999683380127,
              4.407710075378418,
              -0.06799449771642685,
              -1.0794800519943237,
              4.429959774017334,
              -0.0822795033454895,
              -1.0622899532318115,
              4.398749828338623,
              -0.09680149704217911,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              -0.7351359724998474,
              4.584909915924072,
              0.11403600126504898,
              -0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -0.4924109876155853,
              4.493020057678223,
              0.11403600126504898,
              -0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -0.8328620195388794,
              4.656199932098389,
              0.10972999781370163,
              -0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              -1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              -0.6126130223274231,
              4.584909915924072,
              0.11403600126504898,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.616258978843689,
              4.7494797706604,
              -0.022097399458289146,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.1158900260925293,
              3.6439900398254395,
              -0.022097600623965263,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -0.9022480249404907,
              4.473139762878418,
              0.11403600126504898,
              -0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -0.8976629972457886,
              4.5607099533081055,
              0.11403600126504898,
              -0.9594249725341797,
              4.59296989440918,
              0.10972999781370163,
              -0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.1100499629974365,
              4.414239883422852,
              -0.042129501700401306,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              -1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -0.8367230296134949,
              4.488639831542969,
              -0.11403399705886841,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              -0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              -1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              -1.1158900260925293,
              4.26632022857666,
              -0.022097500041127205,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -0.7361339926719666,
              4.702300071716309,
              0.09316159784793854,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.49299898743629456,
              4.7494797706604,
              0.0220986008644104,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              -0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              -1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              -0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              -0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              -1.1014100313186646,
              4.385890007019043,
              -0.059483498334884644,
              -1.0770399570465088,
              4.381219863891602,
              -0.08556050062179565,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              0,
              4.73291015625,
              -0.06254740059375763,
              0,
              4.744440078735352,
              -0.03902740031480789,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              -0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.12310300022363663,
              4.090869903564453,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0,
              4.744440078735352,
              0.039028599858284,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              -0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              -0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -0.49075600504875183,
              4.702300071716309,
              0.09316159784793854,
              -0.6110230088233948,
              4.662300109863281,
              0.10972999781370163,
              -0.4900909960269928,
              4.584909915924072,
              0.11403600126504898,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -0.9953380227088928,
              4.359930038452148,
              -0.11403399705886841,
              -1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              -1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              -1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.1158900260925293,
              2.4494800567626953,
              0.022099299356341362,
              -1.1158900260925293,
              2.7653799057006836,
              0.022098299115896225,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -0.6110230088233948,
              4.662300109863281,
              -0.10972800105810165,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.1158900260925293,
              3.1168301105499268,
              0.022098399698734283,
              -1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.110360026359558,
              3.914639949798584,
              0.04290249943733215,
              -1.115779995918274,
              3.944040060043335,
              0.019615499302744865,
              -1.1158900260925293,
              3.6439900398254395,
              -0.022097600623965263,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              -1.0993599891662598,
              3.643199920654297,
              0.06254839897155762,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.115779995918274,
              3.944040060043335,
              0.019615499302744865,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.1158900260925293,
              3.82807993888855,
              -0.022098500281572342,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              -1.1118299961090088,
              3.9671199321746826,
              0.03923150151968002,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -1.1158900260925293,
              4.26632022857666,
              -0.022097500041127205,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              -1.0993599891662598,
              4.168290138244629,
              -0.06254749745130539,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.0993599891662598,
              3.1163699626922607,
              -0.06254760175943375,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              -0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              -0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              -1.0993599891662598,
              3.9389500617980957,
              -0.06254749745130539,
              -1.110360026359558,
              3.914639949798584,
              -0.042901501059532166,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              -0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              -0.8367230296134949,
              4.088759899139404,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              -1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -1.0993599891662598,
              3.9389500617980957,
              -0.06254749745130539,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              -1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.110360026359558,
              3.914639949798584,
              -0.042901501059532166,
              -1.0993599891662598,
              3.9389500617980957,
              -0.06254749745130539,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.110360026359558,
              4.208789825439453,
              -0.042901501059532166,
              -1.0993599891662598,
              4.168290138244629,
              -0.06254749745130539,
              -1.110360026359558,
              3.914639949798584,
              -0.042901501059532166,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.1158900260925293,
              3.82807993888855,
              -0.022098500281572342,
              -1.110360026359558,
              4.208789825439453,
              0.04290249943733215,
              -1.1158900260925293,
              4.26632022857666,
              0.022098500281572342,
              -1.115779995918274,
              4.18966007232666,
              0.019615499302744865,
              -1.0993599891662598,
              4.168290138244629,
              -0.06254749745130539,
              -1.110360026359558,
              4.208789825439453,
              -0.042901501059532166,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              0.6626589894294739,
              4.155980110168457,
              -0.11403500288724899,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              -1.1158900260925293,
              3.6439900398254395,
              -0.022097600623965263,
              -1.1158900260925293,
              3.82807993888855,
              -0.022098500281572342,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -1.1158900260925293,
              3.82807993888855,
              -0.022098500281572342,
              -1.1158900260925293,
              3.6439900398254395,
              -0.022097600623965263,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              -0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              -1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              -1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.1118299961090088,
              3.9671199321746826,
              0.03923150151968002,
              -1.110360026359558,
              3.914639949798584,
              0.04290249943733215,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.110360026359558,
              4.208789825439453,
              0.04290249943733215,
              -1.1118299961090088,
              4.161910057067871,
              0.03923150151968002,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              -1.110360026359558,
              3.914639949798584,
              -0.042901501059532166,
              -1.1158900260925293,
              3.82807993888855,
              -0.022098500281572342,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.0993599891662598,
              3.8229899406433105,
              0.06254850327968597,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              -1.0993599891662598,
              4.260049819946289,
              0.06254850327968597,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              0.9349619746208191,
              4.6969499588012695,
              -0.06254740059375763,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -1.0993599891662598,
              3.8229899406433105,
              0.06254850327968597,
              -1.0993599891662598,
              3.643199920654297,
              0.06254839897155762,
              -1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              -1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              -1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              -1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0,
              4.662300109863281,
              0.10972999781370163,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              0,
              4.285600185394287,
              0.11403600126504898,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              -0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              -0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              -0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              -1.0993599891662598,
              3.8229899406433105,
              0.06254850327968597,
              -1.110360026359558,
              3.914639949798584,
              0.04290249943733215,
              -1.1158900260925293,
              3.82807993888855,
              0.022098500281572342,
              -1.110360026359558,
              4.208789825439453,
              0.04290249943733215,
              -1.0993599891662598,
              4.168290138244629,
              0.06254850327968597,
              -1.0993599891662598,
              4.260049819946289,
              0.06254850327968597,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.0993599891662598,
              4.260049819946289,
              -0.06254749745130539,
              -1.068750023841858,
              4.343369960784912,
              -0.09316050261259079,
              0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -0.49075600504875183,
              4.702300071716309,
              -0.09316039830446243,
              -0.49223899841308594,
              4.73291015625,
              -0.06254740059375763,
              -0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -0.8328620195388794,
              4.656199932098389,
              -0.10972800105810165,
              0,
              4.744440078735352,
              -0.03902740031480789,
              0,
              4.73291015625,
              -0.06254740059375763,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              -0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -0.8367230296134949,
              4.088759899139404,
              -0.11403500288724899,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.8367230296134949,
              4.2842698097229,
              -0.11403399705886841,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              0,
              4.73291015625,
              -0.06254740059375763,
              0,
              4.702300071716309,
              -0.09316039830446243,
              -0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.12310300022363663,
              4.493020057678223,
              -0.11403399705886841,
              -0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              -0.36973899602890015,
              4.7494797706604,
              -0.022097399458289146,
              -0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              1.0993599891662598,
              4.040939807891846,
              -0.06254749745130539,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              -0.9953380227088928,
              4.359930038452148,
              -0.11403399705886841,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              -1.0993599891662598,
              3.9389500617980957,
              -0.06254749745130539,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              -0.6155139803886414,
              4.493020057678223,
              -0.11403399705886841,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              -0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              -0.3693079948425293,
              4.285600185394287,
              -0.11403399705886841,
              -0.6155139803886414,
              4.285600185394287,
              -0.11403399705886841,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.8367230296134949,
              4.088759899139404,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.8367230296134949,
              4.088759899139404,
              -0.11403500288724899,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              -0.8367230296134949,
              3.9056200981140137,
              -0.11403500288724899,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              -0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              -0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              -0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              -0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.3693079948425293,
              3.535569906234741,
              -0.11403500288724899,
              -0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -1.0993599891662598,
              4.168290138244629,
              -0.06254749745130539,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              -0.9953380227088928,
              4.359930038452148,
              -0.11403399705886841,
              -0.9542589783668518,
              4.444680213928223,
              -0.11403399705886841,
              -0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.6155139803886414,
              2.7878201007843018,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              0,
              4.662300109863281,
              0.10972999781370163,
              0,
              4.702300071716309,
              0.09316159784793854,
              0,
              4.73291015625,
              0.06254860013723373,
              0,
              4.702300071716309,
              0.09316159784793854,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              -0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -0.8367230296134949,
              2.452169895172119,
              -0.11403500288724899,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.6155139803886414,
              2.4522900581359863,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              -0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              -0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              -1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              -0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              -0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              -1.0993599891662598,
              4.168290138244629,
              -0.06254749745130539,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.0993599891662598,
              4.0510101318359375,
              -0.06254749745130539,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              -0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              0.8779659867286682,
              4.059179782867432,
              -0.11403500288724899,
              -0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              -0.6155139803886414,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              4.090869903564453,
              -0.11403500288724899,
              -0.3693079948425293,
              3.909450054168701,
              -0.11403500288724899,
              -0.6155139803886414,
              3.1616899967193604,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0,
              4.73291015625,
              0.06254860013723373,
              0,
              4.744440078735352,
              0.039028599858284,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              0.9594249725341797,
              0.20136399567127228,
              0.10972899943590164,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.9594249725341797,
              0.20136399567127228,
              0.10972899943590164,
              0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.9594249725341797,
              0.20136399567127228,
              0.10972899943590164,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              1.068750023841858,
              0.4068189859390259,
              0.09316109865903854,
              0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.49075600504875183,
              0.0920410007238388,
              0.09316100180149078,
              0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              0.8326730132102966,
              0.21475699543952942,
              0.11403500288724899,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.9424409866333008,
              0.08262299746274948,
              0.0220979992300272,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.7810270190238953,
              4.621920108795166,
              -0.11403399705886841,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              1.1158900260925293,
              1.8532400131225586,
              0.022098200395703316,
              1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              1.1158900260925293,
              2.2046799659729004,
              0.022098299115896225,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              1.068750023841858,
              1.854200005531311,
              0.0931612029671669,
              0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0,
              0.0920410007238388,
              0.09316100180149078,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0,
              0.0920410007238388,
              0.09316100180149078,
              0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0,
              0.05179600045084953,
              0.045914001762866974,
              0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              0.8779659867286682,
              4.059179782867432,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              1.0993599891662598,
              2.449460029602051,
              -0.0625476986169815,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              1.0993599891662598,
              2.7651500701904297,
              -0.0625476986169815,
              1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              0.9129629731178284,
              4.298180103302002,
              -0.13354800641536713,
              0.8859320282936096,
              4.235499858856201,
              -0.11918000131845474,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              1.1347600221633911,
              3.74960994720459,
              0.02740040048956871,
              1.1347600221633911,
              3.7276599407196045,
              0.03005640022456646,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              0.8705829977989197,
              4.340179920196533,
              -0.14484399557113647,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.9158099889755249,
              4.406680107116699,
              -0.14484399557113647,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.6198940277099609,
              4.2825398445129395,
              -0.1438080072402954,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              -0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.7386170029640198,
              0.4587250053882599,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              0.8326730132102966,
              0.21475699543952942,
              0.11403500288724899,
              1.0826900005340576,
              0.3652780055999756,
              -0.08033999800682068,
              1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              1.062880039215088,
              0.3287999927997589,
              -0.09234499931335449,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.9594249725341797,
              0.20136499404907227,
              0.10972899943590164,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              0.49223899841308594,
              0.061427999287843704,
              -0.06254799664020538,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              -0.849888026714325,
              0.05257900059223175,
              -0.0220979992300272,
              -0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              0.47110500931739807,
              4.287320137023926,
              -0.11403399705886841,
              0.47110500931739807,
              4.4105000495910645,
              -0.11403399705886841,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              -1.0361299514770508,
              0.2397249937057495,
              0.09316100180149078,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              1.1304800510406494,
              3.4427499771118164,
              0.0411624014377594,
              1.1347600221633911,
              3.4396300315856934,
              0.02740040048956871,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.9424409866333008,
              0.08262400329113007,
              0.0220979992300272,
              -0.7395200133323669,
              0.044860001653432846,
              0.0220979992300272,
              1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              0.7810270190238953,
              4.621920108795166,
              -0.11403399705886841,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              0.6155139803886414,
              0.30132099986076355,
              -0.11403500288724899,
              0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -1.028749942779541,
              2.7641899585723877,
              -0.10972899943590164,
              -1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              -0.9594249725341797,
              0.20136499404907227,
              0.10972899943590164,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -0.159403994679451,
              0.5517200231552124,
              0.11403500288724899,
              -0.6126130223274231,
              0.20942999422550201,
              0.11403500288724899,
              -0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -0.6110230088233948,
              0.13203899562358856,
              0.10972899943590164,
              -0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              -0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -0.921064019203186,
              0.12466199696063995,
              0.09316100180149078,
              -0.7361339926719666,
              0.0920410007238388,
              0.09316100180149078,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -0.9349619746208191,
              0.0973849967122078,
              -0.06254799664020538,
              -0.7351359724998474,
              0.20942999422550201,
              0.11403500288724899,
              -0.8326730132102966,
              0.2147579938173294,
              0.11403500288724899,
              -0.8328620195388794,
              0.13814200460910797,
              0.10972899943590164,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.4900909960269928,
              0.20942999422550201,
              0.11403500288724899,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              -0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              -0.616258978843689,
              0.044860001653432846,
              -0.0220979992300272,
              -1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -1.0959500074386597,
              0.3839389979839325,
              -0.06605499982833862,
              -1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.950203001499176,
              4.463689804077148,
              -0.11918000131845474,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -0.6152989864349365,
              0.061427999287843704,
              -0.06254799664020538,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -1.0795899629592896,
              0.4097380042076111,
              -0.08370690047740936,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              -0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              -0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.49299898743629456,
              0.044860001653432846,
              0.0220979992300272,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.36973899602890015,
              0.044860001653432846,
              -0.0220979992300272,
              -0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              -1.1014100313186646,
              0.4084469974040985,
              -0.059484001249074936,
              -1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              -0.16554899513721466,
              0.04493999853730202,
              -0.020868999883532524,
              -0.24647800624370575,
              0.044860001653432846,
              -0.0220979992300272,
              -1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              -1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              -0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              -1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -1.1101499795913696,
              0.38044700026512146,
              -0.042086999863386154,
              -1.0905699729919434,
              0.3248730003833771,
              -0.0643410012125969,
              -0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              -0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              -1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              -0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              -0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              -0.3666140139102936,
              0.13203899562358856,
              0.10972899943590164,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              -0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              -0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              -0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              0,
              4.73291015625,
              0.06254860013723373,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              -1.068750023841858,
              1.5033999681472778,
              0.0931612029671669,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -1.1158900260925293,
              1.5017900466918945,
              0.022099200636148453,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              -1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.0993599891662598,
              1.8535799980163574,
              -0.06254780292510986,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -1.0993599891662598,
              2.2047901153564453,
              -0.0625476986169815,
              -1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              -1.028749942779541,
              1.8550299406051636,
              -0.10972899943590164,
              -1.0993599891662598,
              1.5023599863052368,
              -0.06254780292510986,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -1.028749942779541,
              1.504770040512085,
              -0.10972899943590164,
              -1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -1.0993599891662598,
              0.7482470273971558,
              -0.06254789978265762,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -1.0993599891662598,
              1.1511399745941162,
              -0.06254789978265762,
              -1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -1.028749942779541,
              1.154520034790039,
              -0.10972899943590164,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              -1.068750023841858,
              0.4509719908237457,
              -0.09316089749336243,
              0.9281209707260132,
              4.462009906768799,
              -0.13354800641536713,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              -0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -1.068750023841858,
              1.152609944343567,
              0.09316109865903854,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -1.1158900260925293,
              1.1503499746322632,
              0.022098099812865257,
              -1.1158900260925293,
              0.7477650046348572,
              0.022098099812865257,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              0,
              4.702300071716309,
              -0.09316039830446243,
              0,
              4.73291015625,
              -0.06254740059375763,
              0.12306000292301178,
              4.73291015625,
              -0.06254740059375763,
              -1.0210299491882324,
              0.13971999287605286,
              -0.0220979992300272,
              -1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              -0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.9922019839286804,
              0.740776002407074,
              0.11403500288724899,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -1.068750023841858,
              0.7491369843482971,
              0.09316109865903854,
              -1.068750023841858,
              0.5068569779396057,
              0.09316109865903854,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -0.9922019839286804,
              0.38883501291275024,
              0.11403500288724899,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.9022480249404907,
              0.4677950143814087,
              0.11403500288724899,
              -0.9922019839286804,
              0.49865201115608215,
              0.11403500288724899,
              -0.30120301246643066,
              0.1620589941740036,
              0.11334600299596786,
              -0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              -0.3675679862499237,
              0.20942999422550201,
              0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -1.0993599891662598,
              0.5059829950332642,
              -0.06254789978265762,
              -1.1158900260925293,
              0.4487929940223694,
              -0.02209790050983429,
              -1.1158900260925293,
              0.5055109858512878,
              0.022098099812865257,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.36917901039123535,
              0.061427999287843704,
              -0.06254799664020538,
              -0.24537800252437592,
              0.0920410007238388,
              -0.09316100180149078,
              -0.18178899586200714,
              0.05077999830245972,
              -0.04378899931907654,
              -0.24612000584602356,
              0.061427999287843704,
              -0.06254799664020538,
              -0.1230510026216507,
              0.06291799992322922,
              -0.06477800011634827,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -0.8328620195388794,
              0.13814200460910797,
              -0.10972899943590164,
              -0.7383589744567871,
              0.061427999287843704,
              -0.06254799664020538,
              -0.8466870188713074,
              0.06883999705314636,
              -0.06254799664020538,
              -0.7361339926719666,
              0.0920410007238388,
              -0.09316100180149078,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              -0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3693079948425293,
              0.30132099986076355,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -0.8367230296134949,
              0.3056969940662384,
              -0.11403500288724899,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.9542589783668518,
              0.7225649952888489,
              -0.11403500288724899,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -1.028749942779541,
              0.7502999901771545,
              -0.10972899943590164,
              -1.0648399591445923,
              0.392208993434906,
              -0.09494800120592117,
              -1.0524200201034546,
              0.4266349971294403,
              -0.1023309975862503,
              -1.028749942779541,
              0.40932101011276245,
              -0.10972899943590164,
              -0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              -0.3693079948425293,
              0.4587250053882599,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.9542589783668518,
              1.108839988708496,
              -0.11403500288724899,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              -0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              -0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              -0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              -0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              -0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.12310300022363663,
              3.909450054168701,
              -0.11403500288724899,
              0.12310300022363663,
              3.535569906234741,
              -0.11403500288724899,
              0,
              4.702300071716309,
              0.09316159784793854,
              0,
              4.662300109863281,
              0.10972999781370163,
              0.12220499664545059,
              4.662300109863281,
              0.10972999781370163,
              -1.0318900346755981,
              0.44953298568725586,
              -0.10972899943590164,
              -1.033460021018982,
              0.5030699968338013,
              -0.10972899943590164,
              -0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              -0.9953380227088928,
              4.359930038452148,
              -0.11403399705886841,
              -0.9542589783668518,
              4.270899772644043,
              -0.11403399705886841,
              0.920091986656189,
              4.192339897155762,
              -0.11403500288724899,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.8859320282936096,
              4.235499858856201,
              -0.11918000131845474,
              -0.9542589783668518,
              1.8354500532150269,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              -0.9542589783668518,
              1.4721399545669556,
              -0.11403500288724899,
              -0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0.6160629987716675,
              4.275060176849365,
              -0.13354800641536713,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.6198940277099609,
              4.2825398445129395,
              -0.1438080072402954,
              -0.9953380227088928,
              0.4344089925289154,
              -0.11403500288724899,
              -0.9542589783668518,
              0.4807800054550171,
              -0.11403500288724899,
              -0.9542589783668518,
              0.34966200590133667,
              -0.11403500288724899,
              0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              0.4587250053882599,
              -0.11403500288724899,
              -0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              -1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              -1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              0.12321799993515015,
              4.7494797706604,
              -0.022097399458289146,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              -0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0.24612000584602356,
              0.061427999287843704,
              0.06254799664020538,
              0.24647800624370575,
              0.044860001653432846,
              0.0220979992300272,
              0.36917901039123535,
              0.061427999287843704,
              0.06254799664020538,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0,
              4.744440078735352,
              0.039028599858284,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0,
              4.744440078735352,
              0.039028599858284,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0,
              4.744440078735352,
              -0.03902740031480789,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              1.1158900260925293,
              3.4682700634002686,
              0.02209939993917942,
              1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              1.0993599891662598,
              3.818809986114502,
              -0.06254749745130539,
              -0.06170700117945671,
              4.751629829406738,
              5.664389846060658e-7,
              -0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              -0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.930118978023529,
              3.873699903488159,
              -0.11403500288724899,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              0.6155139803886414,
              3.535569906234741,
              -0.11403500288724899,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.8367230296134949,
              3.5327000617980957,
              -0.11403500288724899,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -0.9542589783668518,
              4.067570209503174,
              -0.11403500288724899,
              -0.9542589783668518,
              3.867150068283081,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.12310300022363663,
              3.1616899967193604,
              -0.11403500288724899,
              0.3693079948425293,
              3.1616899967193604,
              -0.11403500288724899,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -0.8367230296134949,
              3.1597800254821777,
              -0.11403500288724899,
              -0.8367230296134949,
              2.7868599891662598,
              -0.11403500288724899,
              -0.9542589783668518,
              2.777240037918091,
              -0.11403500288724899,
              -0.9424409866333008,
              4.711719989776611,
              0.0220986008644104,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              -1.0361299514770508,
              4.554609775543213,
              0.09316150099039078,
              -0.921064019203186,
              4.669680118560791,
              0.09316159784793854,
              -0.849888026714325,
              4.741759777069092,
              -0.022097399458289146,
              -0.9424409866333008,
              4.711719989776611,
              0.0220986008644104,
              -0.7395200133323669,
              4.7494797706604,
              0.0220986008644104,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0,
              4.744440078735352,
              -0.03902740031480789,
              -0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0,
              4.744440078735352,
              -0.03902740031480789,
              0.000043000000005122274,
              4.7494797706604,
              -0.022097399458289146,
              0.0616380013525486,
              4.747320175170898,
              -0.03314739838242531,
              0.000043000000005122274,
              4.7494797706604,
              0.0220986008644104,
              0,
              4.744440078735352,
              0.039028599858284,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.3693079948425293,
              4.493020057678223,
              -0.11403399705886841,
              0.3666140139102936,
              4.662300109863281,
              -0.10972800105810165,
              1.1347600221633911,
              3.5946199893951416,
              -0.03005559928715229,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.5946199893951416,
              0.03005640022456646,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              0.3675679862499237,
              4.584909915924072,
              0.11403600126504898,
              0.3666140139102936,
              4.662300109863281,
              0.10972999781370163,
              0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.850475013256073,
              4.476019859313965,
              -0.14484499394893646,
              -0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              -0.6155139803886414,
              0.4587250053882599,
              -0.11403500288724899,
              -0.8367230296134949,
              0.4607219994068146,
              -0.11403500288724899,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              -0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              -0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              -0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              0.0616380013525486,
              4.747320175170898,
              0.03314860165119171,
              0,
              4.73291015625,
              0.06254860013723373,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              1.1103399991989136,
              3.746380090713501,
              0.04265740141272545,
              1.0944900512695312,
              3.7344000339508057,
              0.04629639908671379,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              1.1347600221633911,
              3.74960994720459,
              -0.02739959955215454,
              1.1347600221633911,
              3.7276599407196045,
              -0.03005559928715229,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              -0.6155139803886414,
              0.7000920176506042,
              -0.11403500288724899,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.8367230296134949,
              0.7021260261535645,
              -0.11403500288724899,
              -0.8367230296134949,
              1.0751800537109375,
              -0.11403500288724899,
              -0.6155139803886414,
              1.0718300342559814,
              -0.11403500288724899,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              -0.058024998754262924,
              0.24225999414920807,
              0.10008499771356583,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.058024998754262924,
              0.24225999414920807,
              0.10008499771356583,
              -0.06973099708557129,
              0.30497100949287415,
              0.10008499771356583,
              -0.16964000463485718,
              0.3223319947719574,
              0.10706000030040741,
              -0.8367230296134949,
              2.1939399242401123,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.6155139803886414,
              2.193459987640381,
              -0.11403500288724899,
              -0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              1.0781199932098389,
              0.21830500662326813,
              0.0220979992300272,
              1.0781199932098389,
              0.21830500662326813,
              -0.0220979992300272,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -0.12310300022363663,
              2.193459987640381,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -0.12310300022363663,
              1.8195799589157104,
              -0.11403500288724899,
              -0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              -0.3693079948425293,
              2.193459987640381,
              -0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              -0.11403500288724899,
              -0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.12310300022363663,
              1.4457099437713623,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -0.3693079948425293,
              1.0718300342559814,
              -0.11403500288724899,
              -0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              -0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              -0.8367230296134949,
              1.448099970817566,
              -0.11403500288724899,
              -0.8367230296134949,
              1.8210200071334839,
              -0.11403500288724899,
              0.6155139803886414,
              1.8195799589157104,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.6155139803886414,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.4457099437713623,
              -0.11403500288724899,
              0.3693079948425293,
              1.8195799589157104,
              -0.11403500288724899,
              0.8195220232009888,
              4.656799793243408,
              -0.10972800105810165,
              0.7810270190238953,
              4.621920108795166,
              -0.11403399705886841,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.6082730293273926,
              4.25993013381958,
              -0.11918000131845474,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.950203001499176,
              4.463689804077148,
              -0.11918000131845474,
              0.9281209707260132,
              4.462009906768799,
              -0.13354800641536713,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              0.6082730293273926,
              4.25993013381958,
              -0.11918000131845474,
              0.24612000584602356,
              4.73291015625,
              -0.06254740059375763,
              0.36917901039123535,
              4.73291015625,
              -0.06254740059375763,
              0.24537800252437592,
              4.702300071716309,
              -0.09316039830446243,
              0.8705829977989197,
              4.340179920196533,
              -0.14484399557113647,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.8334550261497498,
              4.301159858703613,
              -0.14484399557113647,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.9281209707260132,
              4.462009906768799,
              -0.13354800641536713,
              0.9171670079231262,
              4.461160182952881,
              -0.1438080072402954,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              1.0318900346755981,
              4.344799995422363,
              -0.10972800105810165,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              0.578199028968811,
              4.175759792327881,
              -0.11403500288724899,
              0.6626589894294739,
              4.155980110168457,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.6626589894294739,
              4.155980110168457,
              -0.11403500288724899,
              0.578199028968811,
              4.175759792327881,
              -0.11403500288724899,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.9971299767494202,
              4.179880142211914,
              -0.11334600299596786,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              0.920091986656189,
              4.192339897155762,
              -0.11403500288724899,
              1.028749942779541,
              4.385019779205322,
              -0.10972800105810165,
              0.9916840195655823,
              4.392519950866699,
              -0.11403399705886841,
              1.0226500034332275,
              4.455930233001709,
              -0.10972800105810165,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.7810270190238953,
              4.621920108795166,
              -0.11403399705886841,
              0.8195220232009888,
              4.656799793243408,
              -0.10972800105810165,
              -1.0900499820709229,
              4.4728899002075195,
              -0.064410500228405,
              -1.0781199932098389,
              4.5760297775268555,
              -0.022097500041127205,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              0.9153169989585876,
              4.57289981842041,
              -0.11403399705886841,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.11695399880409241,
              0.2669000029563904,
              0.10008499771356583,
              0.06282199919223785,
              0.2443690001964569,
              0.10008499771356583,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              0.7383589744567871,
              4.73291015625,
              -0.06254740059375763,
              0.8466870188713074,
              4.725500106811523,
              -0.06254740059375763,
              0.7361339926719666,
              4.702300071716309,
              -0.09316039830446243,
              0.9790149927139282,
              4.26662015914917,
              -0.11403399705886841,
              0.9971299767494202,
              4.179880142211914,
              -0.11334600299596786,
              0.920091986656189,
              4.192339897155762,
              -0.11403500288724899,
              0.5572789907455444,
              4.544610023498535,
              -0.11403399705886841,
              0.5487419962882996,
              4.640649795532227,
              -0.11403399705886841,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.6626589894294739,
              4.155980110168457,
              -0.11403500288724899,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.6082730293273926,
              4.25993013381958,
              -0.11918000131845474,
              0.6160629987716675,
              4.275060176849365,
              -0.13354800641536713,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.6160629987716675,
              4.275060176849365,
              -0.13354800641536713,
              0.6082730293273926,
              4.25993013381958,
              -0.11918000131845474,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              1.1347600221633911,
              3.4615800380706787,
              0.03005640022456646,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              1.1347600221633911,
              3.5946199893951416,
              0.03005640022456646,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.5568829774856567,
              4.386469841003418,
              -0.11918000131845474,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              0.9153169989585876,
              4.57289981842041,
              -0.11403399705886841,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.6503700017929077,
              4.519350051879883,
              -0.14484499394893646,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.8859320282936096,
              4.235499858856201,
              -0.11918000131845474,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.9196479916572571,
              4.519810199737549,
              -0.11918000131845474,
              0.9562820196151733,
              4.525730133056641,
              -0.11403399705886841,
              0.950203001499176,
              4.463689804077148,
              -0.11918000131845474,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.5198360085487366,
              4.221350193023682,
              -0.11403399705886841,
              0.5373910069465637,
              4.303170204162598,
              -0.11403399705886841,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.7543860077857971,
              4.395390033721924,
              -0.14484399557113647,
              0.9129629731178284,
              4.298180103302002,
              -0.13354800641536713,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              0.6135740280151367,
              4.407149791717529,
              -0.14484399557113647,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.1473400592803955,
              3.3183600902557373,
              0.01888040080666542,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              0.850475013256073,
              4.476019859313965,
              -0.14484499394893646,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.7672610282897949,
              4.536109924316406,
              -0.14484499394893646,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              -1.115779995918274,
              3.944040060043335,
              0.019615499302744865,
              -1.1118299961090088,
              3.9671199321746826,
              0.03923150151968002,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              0.6753159761428833,
              4.489620208740234,
              -0.14484499394893646,
              0.6503700017929077,
              4.519350051879883,
              -0.14484499394893646,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.17938700318336487,
              0.3838759958744049,
              0.10706000030040741,
              0.14206400513648987,
              0.3832240104675293,
              0.10008499771356583,
              0.1361899971961975,
              0.3212929964065552,
              0.10008499771356583,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.7885860204696655,
              4.271369934082031,
              -0.14484399557113647,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -1.068750023841858,
              4.156559944152832,
              -0.09316050261259079,
              -1.0318900346755981,
              4.256040096282959,
              -0.10972800105810165,
              0.9281209707260132,
              4.462009906768799,
              -0.13354800641536713,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9171670079231262,
              4.461160182952881,
              -0.1438080072402954,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              0.6198940277099609,
              4.2825398445129395,
              -0.1438080072402954,
              0.5850710272789001,
              4.326720237731934,
              -0.13354800641536713,
              0.6198940277099609,
              4.2825398445129395,
              -0.1438080072402954,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              1.068750023841858,
              3.115530014038086,
              0.09316139668226242,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              1.068750023841858,
              2.7647299766540527,
              0.09316129982471466,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              0.630424976348877,
              4.291379928588867,
              -0.14484399557113647,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              0.9171670079231262,
              4.461160182952881,
              -0.1438080072402954,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.8334550261497498,
              4.301159858703613,
              -0.14484399557113647,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0.12379299849271774,
              0.05601600185036659,
              0.00797800999134779,
              0.850475013256073,
              4.476019859313965,
              -0.14484499394893646,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.9129629731178284,
              4.298180103302002,
              -0.13354800641536713,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.8916159868240356,
              4.309639930725098,
              -0.14484399557113647,
              0.9158099889755249,
              4.406680107116699,
              -0.14484399557113647,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9922019839286804,
              4.4054999351501465,
              0.11403600126504898,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              1.068750023841858,
              4.387519836425781,
              0.09316150099039078,
              -1.0930399894714355,
              3.9812700748443604,
              -0.015002500265836716,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.115779995918274,
              3.944040060043335,
              0.019615499302744865,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              1.1304800510406494,
              3.4427499771118164,
              0.0411624014377594,
              1.1103399991989136,
              3.4428598880767822,
              0.04265740141272545,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              0.7672610282897949,
              4.536109924316406,
              -0.14484499394893646,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              0.9171670079231262,
              4.461160182952881,
              -0.1438080072402954,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9158099889755249,
              4.406680107116699,
              -0.14484399557113647,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.7276599407196045,
              0.03005640022456646,
              1.1347600221633911,
              3.5946199893951416,
              0.03005640022456646,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.9153169989585876,
              4.57289981842041,
              -0.11403399705886841,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.6043499708175659,
              4.334770202636719,
              -0.14484399557113647,
              0.579246997833252,
              4.3831400871276855,
              -0.1438080072402954,
              0.6135740280151367,
              4.407149791717529,
              -0.14484399557113647,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.7672610282897949,
              4.536109924316406,
              -0.14484499394893646,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.8705829977989197,
              4.340179920196533,
              -0.14484399557113647,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              0.8705829977989197,
              4.340179920196533,
              -0.14484399557113647,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1060999631881714,
              4.158090114593506,
              0.028110500425100327,
              -1.1060999631881714,
              4.1650800704956055,
              0.020532499998807907,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.9158099889755249,
              4.406680107116699,
              -0.14484399557113647,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.9171670079231262,
              4.461160182952881,
              -0.1438080072402954,
              0.9158099889755249,
              4.406680107116699,
              -0.14484399557113647,
              0.6160629987716675,
              4.275060176849365,
              -0.13354800641536713,
              0.6198940277099609,
              4.2825398445129395,
              -0.1438080072402954,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              0.6548939943313599,
              4.219870090484619,
              -0.11918000131845474,
              0.6160629987716675,
              4.275060176849365,
              -0.13354800641536713,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.1473400592803955,
              3.4615800380706787,
              -0.020709600299596786,
              0.8550040125846863,
              4.606560230255127,
              -0.11403399705886841,
              0.8196730017662048,
              4.587969779968262,
              -0.11918000131845474,
              0.7810270190238953,
              4.621920108795166,
              -0.11403399705886841,
              0.5572789907455444,
              4.544610023498535,
              -0.11403399705886841,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.5909500122070312,
              4.505660057067871,
              -0.11918000131845474,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              1.1103399991989136,
              3.4428598880767822,
              -0.04265660047531128,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              -1.114050030708313,
              3.0116100311279297,
              -0.02939159981906414,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              0.6054970026016235,
              4.488430023193359,
              -0.1438080072402954,
              0.6503700017929077,
              4.519350051879883,
              -0.14484499394893646,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              1.1347600221633911,
              3.4615800380706787,
              -0.03005559928715229,
              1.1347600221633911,
              3.5946199893951416,
              -0.03005559928715229,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              0.6213200092315674,
              4.602630138397217,
              -0.11403399705886841,
              0.7043750286102295,
              4.618480205535889,
              -0.11403399705886841,
              0.6923210024833679,
              4.585440158843994,
              -0.11918000131845474,
              0.7885860204696655,
              4.271369934082031,
              -0.14484399557113647,
              0.8334550261497498,
              4.301159858703613,
              -0.14484399557113647,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              1.1347600221633911,
              3.4396300315856934,
              -0.02739959955215454,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              1.1347600221633911,
              3.4615800380706787,
              -0.03005559928715229,
              -1.0924299955368042,
              3.757740020751953,
              -0.024867599830031395,
              -1.114050030708313,
              3.746380090713501,
              -0.029392600059509277,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              1.1103399991989136,
              3.46412992477417,
              -0.0475086010992527,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              1.1304800510406494,
              3.5946199893951416,
              -0.04577359929680824,
              1.1347600221633911,
              3.4615800380706787,
              -0.03005559928715229,
              1.1304800510406494,
              3.4427499771118164,
              -0.04116160050034523,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.114050030708313,
              3.7707300186157227,
              4.4950499500373553e-7,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1103399991989136,
              3.746380090713501,
              -0.04265660047531128,
              1.0944900512695312,
              3.757740020751953,
              -0.03609060123562813,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              1.1103399991989136,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.0944900512695312,
              3.43149995803833,
              -0.03609060123562813,
              1.1103399991989136,
              3.4428598880767822,
              -0.04265660047531128,
              1.1103399991989136,
              3.424030065536499,
              -0.02609260007739067,
              1.1347600221633911,
              3.7276599407196045,
              -0.03005559928715229,
              1.1347600221633911,
              3.7280099391937256,
              4.4441401314543327e-7,
              1.1347600221633911,
              3.5946199893951416,
              -0.03005559928715229,
              1.1103399991989136,
              3.5946199893951416,
              0.04750939831137657,
              1.0944900512695312,
              3.500730037689209,
              0.04750939831137657,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              -1.114050030708313,
              3.746380090713501,
              -0.029392600059509277,
              -1.0924299955368042,
              3.7344000339508057,
              -0.031899601221084595,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              1.0944900512695312,
              3.4198999404907227,
              0.013714400120079517,
              1.1103399991989136,
              3.418519973754883,
              4.075189963259618e-7,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              -1.0924299955368042,
              3.769350051879883,
              -0.009448549710214138,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              -1.114050030708313,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.0944900512695312,
              3.769350051879883,
              -0.013713600113987923,
              1.0944900512695312,
              3.769350051879883,
              0.013714400120079517,
              1.1103399991989136,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              1.1103399991989136,
              3.746380090713501,
              -0.04265660047531128,
              1.1103399991989136,
              3.765209913253784,
              -0.02609260007739067,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              -1.1473400592803955,
              3.74960994720459,
              -0.01887959986925125,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1347600221633911,
              3.4396300315856934,
              -0.02739959955215454,
              1.1347600221633911,
              3.4615800380706787,
              -0.03005559928715229,
              1.1347600221633911,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              -1.0924299955368042,
              3.769350051879883,
              -0.009448549710214138,
              -1.0924299955368042,
              3.757740020751953,
              -0.024867599830031395,
              -1.0924299955368042,
              3.7344000339508057,
              0.031900398433208466,
              -1.114050030708313,
              3.746380090713501,
              0.02939240075647831,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.141510009765625,
              3.746500015258789,
              0.028362400829792023,
              -1.1473400592803955,
              3.74960994720459,
              0.01888040080666542,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              1.0944900512695312,
              3.43149995803833,
              0.036091398447752,
              1.1103399991989136,
              3.424030065536499,
              0.02609339915215969,
              1.1103399991989136,
              3.4428598880767822,
              0.04265740141272545,
              -1.0924299955368042,
              3.757740020751953,
              0.024868400767445564,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.114050030708313,
              3.746380090713501,
              0.02939240075647831,
              -1.141510009765625,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.114050030708313,
              3.418519973754883,
              4.075189963259618e-7,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.114050030708313,
              3.746380090713501,
              0.02939240075647831,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.141510009765625,
              3.746500015258789,
              0.028362400829792023,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.114050030708313,
              3.5946199893951416,
              0.03273539990186691,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.141510009765625,
              3.4427499771118164,
              0.028362400829792023,
              1.1103399991989136,
              3.5946199893951416,
              0.04750939831137657,
              1.1103399991989136,
              3.46412992477417,
              0.04750939831137657,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1103399991989136,
              3.7251100540161133,
              -0.0475086010992527,
              1.1103399991989136,
              3.746380090713501,
              -0.04265660047531128,
              1.1304800510406494,
              3.746500015258789,
              -0.04116160050034523,
              1.1103399991989136,
              3.5946199893951416,
              0.04750939831137657,
              1.1304800510406494,
              3.5946199893951416,
              0.04577440023422241,
              1.1103399991989136,
              3.7251100540161133,
              0.04750939831137657,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.114050030708313,
              3.5946199893951416,
              -0.032734598964452744,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              -1.141510009765625,
              3.4427499771118164,
              0.028362400829792023,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.0924299955368042,
              3.326479911804199,
              -0.024867599830031395,
              -1.114050030708313,
              3.315119981765747,
              -0.029392600059509277,
              -1.114050030708313,
              3.5946199893951416,
              -0.032734598964452744,
              -1.0924299955368042,
              3.6885199546813965,
              -0.032734598964452744,
              -1.0924299955368042,
              3.500730037689209,
              -0.032734598964452744,
              -1.114050030708313,
              3.5946199893951416,
              0.03273539990186691,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.0924299955368042,
              3.303149938583374,
              0.031900398433208466,
              -1.114050030708313,
              3.315119981765747,
              0.02939240075647831,
              -1.0924299955368042,
              3.500730037689209,
              0.03273539990186691,
              -1.114050030708313,
              3.5946199893951416,
              0.03273539990186691,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.0924299955368042,
              3.43149995803833,
              -0.024867599830031395,
              -1.0924299955368042,
              3.4198999404907227,
              -0.009448589757084846,
              -1.1473400592803955,
              3.4396300315856934,
              0.01888040080666542,
              -1.1473400592803955,
              3.4615800380706787,
              0.020710399374365807,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.114050030708313,
              3.765209913253784,
              0.017979400232434273,
              -1.0924299955368042,
              3.757740020751953,
              0.024868400767445564,
              -1.0924299955368042,
              3.769350051879883,
              0.009449450299143791,
              -1.141510009765625,
              3.4203200340270996,
              4.077340065578028e-7,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.114050030708313,
              3.5946199893951416,
              -0.032734598964452744,
              -1.141510009765625,
              3.5946199893951416,
              -0.03153960034251213,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.1473400592803955,
              3.5946199893951416,
              -0.020709600299596786,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.0924299955368042,
              3.43149995803833,
              0.024868400767445564,
              -1.114050030708313,
              3.4428598880767822,
              0.02939240075647831,
              -1.1473400592803955,
              3.4615800380706787,
              -0.020709600299596786,
              -1.1473400592803955,
              3.4396300315856934,
              -0.01887959986925125,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.1473400592803955,
              3.5946199893951416,
              -0.020709600299596786,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.1473400592803955,
              3.4612300395965576,
              4.1261100136580353e-7,
              -1.1473400592803955,
              3.5946199893951416,
              0.020710399374365807,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.141510009765625,
              3.315239906311035,
              0.028362400829792023,
              -1.114050030708313,
              3.315119981765747,
              0.02939240075647831,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.141510009765625,
              3.7689199447631836,
              4.4929001319360395e-7,
              -1.114050030708313,
              3.7707300186157227,
              4.4950499500373553e-7,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              -1.114050030708313,
              3.7251100540161133,
              0.03273539990186691,
              -1.0924299955368042,
              3.6885199546813965,
              0.03273539990186691,
              -1.0924299955368042,
              3.7344000339508057,
              0.031900398433208466,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.0924299955368042,
              3.4548399448394775,
              0.031900398433208466,
              -1.0924299955368042,
              3.500730037689209,
              0.03273539990186691,
              -1.0924299955368042,
              3.4198999404907227,
              0.009449410252273083,
              -1.114050030708313,
              3.424030065536499,
              0.017979400232434273,
              -1.114050030708313,
              3.418519973754883,
              4.075189963259618e-7,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.114050030708313,
              3.0116100311279297,
              0.02939240075647831,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.114050030708313,
              3.4428598880767822,
              -0.02939159981906414,
              -1.114050030708313,
              3.424030065536499,
              -0.017978599295020103,
              -1.141510009765625,
              3.4427499771118164,
              -0.028361599892377853,
              -1.114050030708313,
              3.0116100311279297,
              0.02939240075647831,
              -1.0924299955368042,
              3.0235800743103027,
              0.031900398433208466,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.114050030708313,
              3.163360118865967,
              0.03273539990186691,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.141510009765625,
              3.163360118865967,
              0.0315404012799263,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.114050030708313,
              3.3394699096679688,
              3.980950111781567e-7,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.114050030708313,
              3.46412992477417,
              -0.032734598964452744,
              -1.0924299955368042,
              3.500730037689209,
              -0.032734598964452744,
              -1.0924299955368042,
              3.4548399448394775,
              -0.031899601221084595,
              -1.1473400592803955,
              3.5946199893951416,
              0.020710399374365807,
              -1.141510009765625,
              3.5946199893951416,
              0.0315404012799263,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              -1.114050030708313,
              3.315119981765747,
              0.02939240075647831,
              -1.0924299955368042,
              3.326479911804199,
              0.024868400767445564,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.0924299955368042,
              3.338089942932129,
              -0.009448600001633167,
              -1.0924299955368042,
              3.326479911804199,
              -0.024867599830031395,
              -1.1473400592803955,
              3.7276599407196045,
              0.020710399374365807,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              -1.1473400592803955,
              3.5946199893951416,
              0.020710399374365807,
              -1.114050030708313,
              3.4428598880767822,
              0.02939240075647831,
              -1.114050030708313,
              3.46412992477417,
              0.03273539990186691,
              -1.141510009765625,
              3.4427499771118164,
              0.028362400829792023,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.141510009765625,
              3.0114901065826416,
              0.028362400829792023,
              -1.1473400592803955,
              3.030319929122925,
              0.020710399374365807,
              -1.114050030708313,
              3.746380090713501,
              -0.029392600059509277,
              -1.114050030708313,
              3.7251100540161133,
              -0.032734598964452744,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.1473400592803955,
              3.030319929122925,
              -0.020709600299596786,
              -1.141510009765625,
              2.9890599250793457,
              3.5632399431051454e-7,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.1473400592803955,
              3.0083699226379395,
              0.01888040080666542,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.141510009765625,
              3.315239906311035,
              0.028362400829792023,
              -1.1473400592803955,
              3.3183600902557373,
              0.01888040080666542,
              -1.1473400592803955,
              3.296410083770752,
              0.020710399374365807,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.141510009765625,
              3.163360118865967,
              -0.03153960034251213,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.0924299955368042,
              3.0002400875091553,
              -0.024867599830031395,
              -1.0924299955368042,
              2.988640069961548,
              -0.009448640048503876,
              -1.114050030708313,
              3.3394699096679688,
              3.980950111781567e-7,
              -1.0924299955368042,
              3.338089942932129,
              0.009449400007724762,
              -1.0924299955368042,
              3.338089942932129,
              -0.009448600001633167,
              -1.141510009765625,
              2.9890599250793457,
              3.5632399431051454e-7,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.1473400592803955,
              3.0083699226379395,
              -0.01887959986925125,
              -1.141510009765625,
              3.0114901065826416,
              -0.028361599892377853,
              -1.114050030708313,
              2.992769956588745,
              -0.017978599295020103,
              -1.114050030708313,
              3.3339500427246094,
              0.017979400232434273,
              -1.0924299955368042,
              3.326479911804199,
              0.024868400767445564,
              -1.0924299955368042,
              3.338089942932129,
              0.009449400007724762,
              -1.0924299955368042,
              3.069469928741455,
              0.03273539990186691,
              -1.114050030708313,
              3.163360118865967,
              0.03273539990186691,
              -1.114050030708313,
              3.032870054244995,
              0.03273539990186691,
              -1.114050030708313,
              3.3394699096679688,
              3.980950111781567e-7,
              -1.114050030708313,
              3.3339500427246094,
              -0.017978599295020103,
              -1.141510009765625,
              3.337660074234009,
              3.978800009463157e-7,
              -1.114050030708313,
              3.0116100311279297,
              0.02939240075647831,
              -1.0924299955368042,
              3.0002400875091553,
              0.024868400767445564,
              -1.0924299955368042,
              3.0235800743103027,
              0.031900398433208466,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.0924299955368042,
              3.2572600841522217,
              0.03273539990186691,
              -1.0924299955368042,
              3.303149938583374,
              0.031900398433208466,
              -1.114050030708313,
              3.315119981765747,
              -0.029392600059509277,
              -1.0924299955368042,
              3.326479911804199,
              -0.024867599830031395,
              -1.0924299955368042,
              3.303149938583374,
              -0.031899601221084595,
              -1.0924299955368042,
              3.2572600841522217,
              0.03273539990186691,
              -1.114050030708313,
              3.2938499450683594,
              0.03273539990186691,
              -1.114050030708313,
              3.163360118865967,
              0.03273539990186691,
              -1.114050030708313,
              3.163360118865967,
              -0.032734598964452744,
              -1.0924299955368042,
              3.069469928741455,
              -0.032734598964452744,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.114050030708313,
              3.163360118865967,
              0.03273539990186691,
              -1.0924299955368042,
              3.069469928741455,
              0.03273539990186691,
              -1.0924299955368042,
              3.2572600841522217,
              0.03273539990186691,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.114050030708313,
              3.032870054244995,
              -0.032734598964452744,
              -1.0924299955368042,
              3.069469928741455,
              -0.032734598964452744,
              -1.0924299955368042,
              3.0235800743103027,
              -0.031899601221084595,
              -1.0924299955368042,
              3.0002400875091553,
              0.024868400767445564,
              -1.114050030708313,
              3.0116100311279297,
              0.02939240075647831,
              -1.114050030708313,
              2.992769956588745,
              0.017979400232434273,
              -1.114050030708313,
              3.163360118865967,
              -0.032734598964452744,
              -1.0924299955368042,
              3.2572600841522217,
              -0.032734598964452744,
              -1.0924299955368042,
              3.069469928741455,
              -0.032734598964452744,
              -1.1473400592803955,
              3.3183600902557373,
              -0.01887959986925125,
              -1.141510009765625,
              3.315239906311035,
              -0.028361599892377853,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.110360026359558,
              4.208789825439453,
              -0.042901501059532166,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.1473400592803955,
              3.296750068664551,
              3.93004000898145e-7,
              -1.1473400592803955,
              3.296410083770752,
              -0.020709600299596786,
              -1.1473400592803955,
              3.163360118865967,
              -0.020709600299596786,
              0.7430440187454224,
              4.078889846801758,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.803167998790741,
              3.906559944152832,
              -0.11403500288724899,
              0.6155139803886414,
              3.909450054168701,
              -0.11403500288724899,
              0.5954750180244446,
              4.095739841461182,
              -0.11403500288724899,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.10797499865293503,
              0.2374570071697235,
              0.10706000030040741,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              -1.114050030708313,
              3.315119981765747,
              -0.029392600059509277,
              -1.114050030708313,
              3.2938499450683594,
              -0.032734598964452744,
              -1.141510009765625,
              3.315239906311035,
              -0.028361599892377853,
              -1.1473400592803955,
              3.030319929122925,
              0.020710399374365807,
              -1.1473400592803955,
              3.163360118865967,
              0.020710399374365807,
              -1.1473400592803955,
              3.0299699306488037,
              3.612009891185153e-7,
              -1.115779995918274,
              3.944040060043335,
              0.019615499302744865,
              -1.110360026359558,
              3.914639949798584,
              0.04290249943733215,
              -1.1118299961090088,
              3.9671199321746826,
              0.03923150151968002,
              -1.1282999515533447,
              4.147950172424316,
              0.00036449398612603545,
              -1.1060999631881714,
              4.147870063781738,
              -0.00015250600699800998,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              0.24537800252437592,
              0.0920410007238388,
              0.09316100180149078,
              0.2444089949131012,
              0.13203899562358856,
              0.10972899943590164,
              0.12220499664545059,
              0.13203899562358856,
              0.10972899943590164,
              -1.068750023841858,
              3.9286599159240723,
              -0.09316050261259079,
              -1.028749942779541,
              4.038809776306152,
              -0.10972899943590164,
              -1.028749942779541,
              3.8149399757385254,
              -0.10972899943590164,
              -1.110360026359558,
              3.914639949798584,
              0.04290249943733215,
              -1.0993599891662598,
              3.8229899406433105,
              0.06254850327968597,
              -1.0993599891662598,
              3.9389500617980957,
              0.06254850327968597,
              -1.110360026359558,
              4.208789825439453,
              -0.042901501059532166,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.1158900260925293,
              4.26632022857666,
              -0.022097500041127205,
              -1.0993599891662598,
              3.467590093612671,
              -0.06254760175943375,
              -1.1158900260925293,
              3.6439900398254395,
              -0.022097600623965263,
              -1.0993599891662598,
              3.8229899406433105,
              -0.06254749745130539,
              -0.3666140139102936,
              0.13203899562358856,
              -0.10972899943590164,
              -0.49075600504875183,
              0.0920410007238388,
              -0.09316100180149078,
              -0.6110230088233948,
              0.13203899562358856,
              -0.10972899943590164,
              -1.110360026359558,
              3.914639949798584,
              -0.042901501059532166,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              1.029520034790039,
              4.1471099853515625,
              -0.10972899943590164,
              0.9971299767494202,
              4.179880142211914,
              -0.11334600299596786,
              1.0323200225830078,
              4.250899791717529,
              -0.10972800105810165,
              -1.1473400592803955,
              3.74960994720459,
              -0.01887959986925125,
              -1.1473400592803955,
              3.7276599407196045,
              -0.020709600299596786,
              -1.1473400592803955,
              3.7280099391937256,
              4.4441401314543327e-7,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              0.8410919904708862,
              4.173210144042969,
              -0.11403500288724899,
              0.7112839818000793,
              4.174829959869385,
              -0.11403500288724899,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              0.8783460259437561,
              4.499410152435303,
              -0.14484499394893646,
              0.8401359915733337,
              4.532619953155518,
              -0.14484499394893646,
              0.8888760209083557,
              4.508240222930908,
              -0.1438080072402954,
              0.9498249888420105,
              4.135270118713379,
              -0.11403500288724899,
              1.029170036315918,
              4.033860206604004,
              -0.10972899943590164,
              0.8779659867286682,
              4.059179782867432,
              -0.11403500288724899,
              0.9406859874725342,
              4.406070232391357,
              -0.13354800641536713,
              0.9281209707260132,
              4.462009906768799,
              -0.13354800641536713,
              0.950203001499176,
              4.463689804077148,
              -0.11918000131845474,
              0.578199028968811,
              4.175759792327881,
              -0.11403500288724899,
              0.5198360085487366,
              4.221350193023682,
              -0.11403399705886841,
              0.5884339809417725,
              4.223420143127441,
              -0.11403399705886841,
              0.9542589783668518,
              2.1987500190734863,
              -0.11403500288724899,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              0.9542589783668518,
              2.450969934463501,
              -0.11403500288724899,
              1.028749942779541,
              2.4493300914764404,
              -0.10972899943590164,
              1.028749942779541,
              2.205280065536499,
              -0.10972899943590164,
              0.12310300022363663,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.12310300022363663,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              2.7878201007843018,
              -0.11403500288724899,
              0.3693079948425293,
              2.4522900581359863,
              -0.11403500288724899,
              0.18178899586200714,
              0.05075699836015701,
              0.04375400021672249,
              0.1230510026216507,
              0.06282199919223785,
              0.0646359995007515,
              0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              1.1158900260925293,
              4.390470027923584,
              -0.022097500041127205,
              1.1081700325012207,
              4.472660064697266,
              -0.022097500041127205,
              -1.1287800073623657,
              4.159560203552246,
              0.00886049959808588,
              -1.1282999515533447,
              4.147950172424316,
              0.00036449398612603545,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1287800073623657,
              4.159560203552246,
              0.00886049959808588,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1060999631881714,
              4.1650800704956055,
              0.020532499998807907,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.084380030632019,
              4.00931978225708,
              -0.00015252199955284595,
              -1.1060999631881714,
              3.9865200519561768,
              -0.000152524997247383,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              -1.1282999515533447,
              3.976449966430664,
              0.02769950032234192,
              -1.1060999631881714,
              3.9763100147247314,
              0.028110500425100327,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              -1.1287800073623657,
              4.159560203552246,
              0.00886049959808588,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1287800073623657,
              4.067200183868408,
              0.00794249027967453,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              -1.1060999631881714,
              4.147870063781738,
              0.030159499496221542,
              -1.1060999631881714,
              4.158090114593506,
              0.028110500425100327,
              -1.1282999515533447,
              4.157949924468994,
              0.02769950032234192,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.6582959890365601,
              4.314760208129883,
              -0.14484399557113647,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.084380030632019,
              4.153059959411621,
              0.00035949499579146504,
              -1.1060999631881714,
              4.147870063781738,
              -0.00015250600699800998,
              -0.2020380049943924,
              0.2538140118122101,
              0.11403500288724899,
              -0.2810100018978119,
              0.22405700385570526,
              0.11403500288724899,
              -0.22467699646949768,
              0.17063599824905396,
              0.11403500288724899,
              -1.1060999631881714,
              3.9693100452423096,
              0.009475469589233398,
              -1.084380030632019,
              3.968280076980591,
              0.015003499574959278,
              -1.1060999631881714,
              3.9693100452423096,
              0.020532499998807907,
              -1.084380030632019,
              4.166110038757324,
              0.015003499574959278,
              -1.1060999631881714,
              4.1650800704956055,
              0.020532499998807907,
              -1.084380030632019,
              4.1623101234436035,
              0.025190500542521477,
              -1.1060999631881714,
              4.1650800704956055,
              0.00947550032287836,
              -1.084380030632019,
              4.1623101234436035,
              0.004816500004380941,
              -1.1060999631881714,
              4.158090114593506,
              0.0018975000130012631,
              -1.1060999631881714,
              4.147870063781738,
              -0.00015250600699800998,
              -1.084380030632019,
              4.125070095062256,
              -0.00015250800061039627,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              -1.084380030632019,
              3.9720799922943115,
              0.025190500542521477,
              -1.1060999631881714,
              3.9693100452423096,
              0.020532499998807907,
              -1.084380030632019,
              3.968280076980591,
              0.015003499574959278,
              -1.1060999631881714,
              4.067200183868408,
              0.030159499496221542,
              -1.1282999515533447,
              4.067200183868408,
              0.029643500223755836,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              -1.084380030632019,
              4.00931978225708,
              -0.00015252199955284595,
              -1.084380030632019,
              3.981329917907715,
              0.00035947500145994127,
              -1.1060999631881714,
              3.9865200519561768,
              -0.000152524997247383,
              -1.084380030632019,
              4.125070095062256,
              -0.00015250800061039627,
              -1.1060999631881714,
              4.147870063781738,
              -0.00015250600699800998,
              -1.084380030632019,
              4.153059959411621,
              0.00035949499579146504,
              -1.084380030632019,
              3.981329917907715,
              0.029647499322891235,
              -1.084380030632019,
              4.00931978225708,
              0.030159499496221542,
              -1.1060999631881714,
              3.9865200519561768,
              0.030159499496221542,
              0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -1.1282999515533447,
              3.9864399433135986,
              0.00036447501042857766,
              -1.1060999631881714,
              3.9865200519561768,
              -0.000152524997247383,
              -1.1060999631881714,
              3.9763100147247314,
              0.0018974699778482318,
              -1.084380030632019,
              4.125070095062256,
              -0.00015250800061039627,
              -1.084380030632019,
              4.00931978225708,
              -0.00015252199955284595,
              -1.1060999631881714,
              4.067200183868408,
              -0.0001525150000816211,
              0.12321799993515015,
              4.7494797706604,
              0.0220986008644104,
              0.12306000292301178,
              4.73291015625,
              0.06254860013723373,
              0.24647800624370575,
              4.7494797706604,
              0.0220986008644104,
              0.11236599832773209,
              0.053321000188589096,
              -0.0344420000910759,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              0,
              0.05179600045084953,
              0.045914001762866974,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0,
              0.051986999809741974,
              -0.046199001371860504,
              0.11988700181245804,
              0.05049600079655647,
              -0.04396900162100792,
              0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.0930399894714355,
              3.9812700748443604,
              -0.015002500265836716,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              -1.0930399894714355,
              3.9812700748443604,
              -0.015002500265836716,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              0.7543860077857971,
              4.395390033721924,
              -0.14484399557113647,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.5487419962882996,
              4.640649795532227,
              -0.11403399705886841,
              0.5572789907455444,
              4.544610023498535,
              -0.11403399705886841,
              0.479436993598938,
              4.580840110778809,
              -0.11403399705886841,
              0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0,
              3.535569906234741,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0,
              3.909450054168701,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              1.068750023841858,
              4.253509998321533,
              0.09316150099039078,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              0.9922019839286804,
              4.258979797363281,
              0.11403600126504898,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              1.1158900260925293,
              3.8197100162506104,
              0.022098500281572342,
              1.1158900260925293,
              4.041440010070801,
              0.022098500281572342,
              1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.7386170029640198,
              4.285600185394287,
              0.11403600126504898,
              -0.9022480249404907,
              4.279550075531006,
              0.11403600126504898,
              0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              -0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              -0.4924109876155853,
              4.285600185394287,
              0.11403600126504898,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              1.1158900260925293,
              0.4038670063018799,
              -0.0220979992300272,
              1.1158900260925293,
              0.4038670063018799,
              0.0220979992300272,
              1.1081700325012207,
              0.3216800093650818,
              -0.0220979992300272,
              -0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              -0.24620500206947327,
              3.909450054168701,
              0.11403500288724899,
              0,
              3.909450054168701,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.4924109876155853,
              3.909450054168701,
              0.11403500288724899,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              -0.9922019839286804,
              3.1233999729156494,
              0.11403500288724899,
              -0.7386170029640198,
              4.090869903564453,
              0.11403500288724899,
              -0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              -0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.7386170029640198,
              3.909450054168701,
              0.11403500288724899,
              -0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.9022480249404907,
              3.1530001163482666,
              0.11403500288724899,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              3.535569906234741,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -0.9922019839286804,
              3.478140115737915,
              0.11403500288724899,
              -0.9022480249404907,
              3.5225300788879395,
              0.11403500288724899,
              -1.0930399894714355,
              3.9812700748443604,
              -0.015002500265836716,
              -1.1118299961090088,
              3.9671199321746826,
              -0.03923049941658974,
              -1.115779995918274,
              3.944040060043335,
              -0.019614500924944878,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              -0.4924109876155853,
              3.535569906234741,
              0.11403500288724899,
              -0.7386170029640198,
              3.535569906234741,
              0.11403500288724899,
              -0.7386170029640198,
              3.1616899967193604,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              -0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              -0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              -0.4924109876155853,
              3.1616899967193604,
              0.11403500288724899,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -1.0993599891662598,
              3.643199920654297,
              0.06254839897155762,
              -1.068750023841858,
              3.466330051422119,
              0.09316139668226242,
              -0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -1.068750023841858,
              4.04000997543335,
              0.09316150099039078,
              -1.068750023841858,
              3.8171300888061523,
              0.09316150099039078,
              -0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              -0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              -0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              -0.24620500206947327,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              0,
              3.1616899967193604,
              0.11403500288724899,
              1.028749942779541,
              3.4646899700164795,
              -0.10972899943590164,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              1.028749942779541,
              3.1144399642944336,
              -0.10972899943590164,
              0.9542589783668518,
              3.1405398845672607,
              -0.11403500288724899,
              0.9542589783668518,
              3.5038399696350098,
              -0.11403500288724899,
              0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              2.7878201007843018,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.7878201007843018,
              0.11403500288724899,
              -0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.7878201007843018,
              0.11403500288724899,
              -0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              -0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              -0.11687199771404266,
              0.5991610288619995,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0.14696800708770752,
              0.27783599495887756,
              0.10706000030040741,
              0.20394399762153625,
              0.2564370036125183,
              0.11403500288724899,
              0.22099100053310394,
              0.31960999965667725,
              0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              -0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              -0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              0.003131000092253089,
              0.20135700702667236,
              0.10706000030040741,
              -0.058024998754262924,
              0.24225999414920807,
              0.10008499771356583,
              -0.10290800034999847,
              0.2337760031223297,
              0.10706000030040741,
              0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              0.4924109876155853,
              0.4587250053882599,
              0.11403500288724899,
              0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0,
              1.4457099437713623,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              0.24620500206947327,
              1.4457099437713623,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              -0.12310300022363663,
              0.7000920176506042,
              -0.11403500288724899,
              -0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.12310300022363663,
              1.0718300342559814,
              -0.11403500288724899,
              0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              -0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0,
              0.7000920176506042,
              0.11403500288724899,
              0,
              1.0718300342559814,
              0.11403500288724899,
              -0.8976629972457886,
              0.23363199830055237,
              0.11403500288724899,
              -0.8326730132102966,
              0.2147579938173294,
              0.11403500288724899,
              -0.9022480249404907,
              0.32120099663734436,
              0.11403500288724899,
              -0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              -0.24620500206947327,
              1.8195799589157104,
              0.11403500288724899,
              0,
              1.8195799589157104,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.7386170029640198,
              2.193459987640381,
              0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              -0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              -0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              -0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              -0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              -0.9022480249404907,
              1.8261100053787231,
              0.11403500288724899,
              -0.9922019839286804,
              1.8482999801635742,
              0.11403500288724899,
              -0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              -0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              -0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              -0.3693079948425293,
              0.5587520003318787,
              0.11403500288724899,
              -0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              -0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              -0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              -0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              -0.9022480249404907,
              0.7093340158462524,
              0.11403500288724899,
              -0.7386170029640198,
              0.7000920176506042,
              0.11403500288724899,
              0,
              2.4522900581359863,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              -0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              0,
              2.193459987640381,
              0.11403500288724899,
              0,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0,
              4.285600185394287,
              0.11403600126504898,
              0.24620500206947327,
              4.090869903564453,
              0.11403500288724899,
              0.24620500206947327,
              4.285600185394287,
              0.11403600126504898,
              0,
              4.285600185394287,
              0.11403600126504898,
              0.6135740280151367,
              4.407149791717529,
              -0.14484399557113647,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0,
              4.702300071716309,
              -0.09316039830446243,
              0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              -0.12220499664545059,
              4.662300109863281,
              -0.10972800105810165,
              0.4924109876155853,
              1.0718300342559814,
              0.11403500288724899,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.7386170029640198,
              1.0718300342559814,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.9922019839286804,
              2.768670082092285,
              0.11403500288724899,
              0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.9022480249404907,
              2.1956400871276855,
              0.11403500288724899,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              -0.24620500206947327,
              1.0718300342559814,
              0.11403500288724899,
              -0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              -0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.1361899971961975,
              0.3212929964065552,
              0.10008499771356583,
              0.14206400513648987,
              0.3832240104675293,
              0.10008499771356583,
              0.07233300060033798,
              0.30744999647140503,
              0.10008499771356583,
              0.4924109876155853,
              1.4457099437713623,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.7386170029640198,
              1.4457099437713623,
              0.11403500288724899,
              0.7386170029640198,
              1.8195799589157104,
              0.11403500288724899,
              0.4924109876155853,
              1.8195799589157104,
              0.11403500288724899,
              0.24620500206947327,
              0.7000920176506042,
              0.11403500288724899,
              0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.7543860077857971,
              4.395390033721924,
              -0.14484399557113647,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.9922019839286804,
              1.4935699701309204,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              0.9022480249404907,
              1.4565800428390503,
              0.11403500288724899,
              0.9022480249404907,
              1.087049961090088,
              0.11403500288724899,
              0.9922019839286804,
              1.1388399600982666,
              0.11403500288724899,
              0.28193798661231995,
              0.5434179902076721,
              0.11403500288724899,
              0.3077569901943207,
              0.6087660193443298,
              0.11403500288724899,
              0.22660699486732483,
              0.5915489792823792,
              0.11403500288724899,
              -0.4924109876155853,
              0.7000920176506042,
              0.11403500288724899,
              -0.3693079948425293,
              0.7000920176506042,
              0.11403500288724899,
              -0.3693079948425293,
              0.8848879933357239,
              0.11403500288724899,
              -0.24620500206947327,
              2.193459987640381,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.24620500206947327,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.4522900581359863,
              0.11403500288724899,
              -0.4924109876155853,
              2.193459987640381,
              0.11403500288724899,
              -0.245045006275177,
              4.584909915924072,
              0.11403600126504898,
              -0.24620500206947327,
              4.493020057678223,
              0.11403600126504898,
              -0.1225230023264885,
              4.584909915924072,
              0.11403600126504898,
              0.850475013256073,
              4.476019859313965,
              -0.14484499394893646,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.7386170029640198,
              2.4522900581359863,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.9022480249404907,
              2.451740026473999,
              0.11403500288724899,
              0.9022480249404907,
              2.7834699153900146,
              0.11403500288724899,
              0.7386170029640198,
              2.7878201007843018,
              0.11403500288724899,
              0.9022480249404907,
              4.081289768218994,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              0.9022480249404907,
              3.89205002784729,
              0.11403500288724899,
              0.9922019839286804,
              3.8328700065612793,
              0.11403500288724899,
              0.9922019839286804,
              4.048679828643799,
              0.11403500288724899,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              0.6753159761428833,
              4.489620208740234,
              -0.14484499394893646,
              0.8705829977989197,
              4.340179920196533,
              -0.14484399557113647,
              0.8334550261497498,
              4.301159858703613,
              -0.14484399557113647,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.6753159761428833,
              4.489620208740234,
              -0.14484499394893646,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              0.7543860077857971,
              4.395390033721924,
              -0.14484399557113647,
              0.6654130220413208,
              4.401020050048828,
              -0.14484399557113647,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.7885860204696655,
              4.271369934082031,
              -0.14484399557113647,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.7885860204696655,
              4.271369934082031,
              -0.14484399557113647,
              0.6968579888343811,
              4.278820037841797,
              -0.14484399557113647,
              0.7020390033721924,
              4.351469993591309,
              -0.14484399557113647,
              0.850475013256073,
              4.476019859313965,
              -0.14484499394893646,
              0.8791729807853699,
              4.43179988861084,
              -0.14484399557113647,
              0.8067319989204407,
              4.439320087432861,
              -0.14484399557113647,
              0.6753159761428833,
              4.489620208740234,
              -0.14484499394893646,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.7642890214920044,
              4.48399019241333,
              -0.14484399557113647,
              -1.1118299961090088,
              4.062300205230713,
              0.03923150151968002,
              -1.1007299423217773,
              4.143909931182861,
              0.0321355015039444,
              -1.1007299423217773,
              3.9859800338745117,
              0.0321355015039444,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.8150039911270142,
              4.245359897613525,
              -0.14484399557113647,
              0.8235610127449036,
              4.223060131072998,
              -0.13354800641536713,
              0.9922019839286804,
              2.2030398845672607,
              0.11403500288724899,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              1.068750023841858,
              2.2049999237060547,
              0.09316129982471466,
              1.068750023841858,
              2.449399948120117,
              0.09316129982471466,
              0.9922019839286804,
              2.449889898300171,
              0.11403500288724899,
              0.7959550023078918,
              4.345849990844727,
              -0.14484399557113647,
              0.8334550261497498,
              4.301159858703613,
              -0.14484399557113647,
              0.7885860204696655,
              4.271369934082031,
              -0.14484399557113647,
              0.6886169910430908,
              4.558169841766357,
              -0.1438080072402954,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.7968590259552002,
              4.56574010848999,
              -0.1438080072402954,
              0.7672610282897949,
              4.536109924316406,
              -0.14484499394893646,
              0.7201849818229675,
              4.519410133361816,
              -0.14484399557113647,
              0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -0.12220499664545059,
              0.13203899562358856,
              -0.10972899943590164,
              -0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              0.12310300022363663,
              0.30132099986076355,
              -0.11403500288724899,
              -1.114050030708313,
              3.765209913253784,
              -0.017978599295020103,
              -1.114050030708313,
              3.746380090713501,
              -0.029392600059509277,
              -1.141510009765625,
              3.746500015258789,
              -0.028361599892377853,
              0.6753159761428833,
              4.489620208740234,
              -0.14484499394893646,
              0.6381880044937134,
              4.450610160827637,
              -0.14484399557113647,
              0.6503700017929077,
              4.519350051879883,
              -0.14484499394893646,
              -0.921064019203186,
              4.669680118560791,
              -0.09316039830446243,
              -0.9594249725341797,
              4.59296989440918,
              -0.10972800105810165,
              -1.0093499422073364,
              4.642899990081787,
              -0.06254740059375763,
              1.1304800510406494,
              3.7689199447631836,
              4.4929001319360395e-7,
              1.1103399991989136,
              3.7707300186157227,
              4.4950499500373553e-7,
              1.1103399991989136,
              3.765209913253784,
              0.02609339915215969,
              -0.9594249725341797,
              0.20136399567127228,
              -0.10972899943590164,
              -0.921064019203186,
              0.12466199696063995,
              -0.09316100180149078,
              -1.0093499422073364,
              0.1514350026845932,
              -0.06254799664020538,
              0.6613500118255615,
              4.246500015258789,
              -0.1438080072402954,
              0.7152379751205444,
              4.238379955291748,
              -0.14484399557113647,
              0.7111210227012634,
              4.215950012207031,
              -0.13354800641536713,
              1.0781199932098389,
              4.5760297775268555,
              0.022098500281572342,
              1.0210299491882324,
              4.654620170593262,
              -0.022097399458289146,
              0.9424409866333008,
              4.711709976196289,
              0.0220986008644104,
              0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0.16554899513721466,
              0.04493999853730202,
              0.020868999883532524,
              -1.115779995918274,
              4.18966007232666,
              -0.019614500924944878,
              -1.1118299961090088,
              4.161910057067871,
              -0.03923049941658974,
              -1.0930399894714355,
              4.1486101150512695,
              -0.015002500265836716,
              1.1158900260925293,
              4.2544097900390625,
              0.022098500281572342,
              1.1158900260925293,
              4.3455400466918945,
              -0.022097500041127205,
              1.1158900260925293,
              4.390470027923584,
              0.022098500281572342,
              -0.11988800019025803,
              0.0504009984433651,
              0.04382599890232086,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0,
              0.05179600045084953,
              0.045914001762866974,
              -0.1451999992132187,
              0.04503599926829338,
              -0.01906299963593483,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9,
              -0.11574699729681015,
              0.04883600026369095,
              0.038127001374959946,
              0,
              0.053321000188589096,
              -0.0344420000910759,
              0.11236599832773209,
              0.053321000188589096,
              -0.0344420000910759,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9,
              -0.11574699729681015,
              0.04883600026369095,
              -0.038127001374959946,
              0,
              0.053321000188589096,
              -0.0344420000910759,
              -0.05491099879145622,
              0.057819001376628876,
              6.892559856197522e-9
            ],
            "normalized": false
          },
          "normal": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              -0.8543959856033325,
              -0.13968299329280853,
              -0.5004429817199707,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              -0.9574570059776306,
              0.2034969925880432,
              0.20444299280643463,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              -0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.14545099437236786,
              -0.8815879821777344,
              0.44895800948143005,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.38630300760269165,
              -0.9065830111503601,
              -0.16977399587631226,
              0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              -0.9881280064582825,
              -0.14471900463104248,
              0.050935398787260056,
              -0.9307230114936829,
              0.004394670017063618,
              0.3656120002269745,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              0.020600000396370888,
              -0.2261119931936264,
              0.9738759994506836,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              -0.023590799421072006,
              -0.485275000333786,
              0.874019980430603,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              -0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              -0.38630300760269165,
              -0.9065830111503601,
              -0.16977399587631226,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125,
              -0.38630300760269165,
              -0.9065830111503601,
              -0.16977399587631226,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              -0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              -0.14545099437236786,
              -0.8815879821777344,
              0.44895800948143005,
              0.0030823699198663235,
              -0.99127197265625,
              0.13165700435638428,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              0.0030823699198663235,
              -0.99127197265625,
              0.13165700435638428,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.9185460209846497,
              0.015106700360774994,
              0.39497101306915283,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              -0.14545099437236786,
              -0.8815879821777344,
              0.44895800948143005,
              -0.38630300760269165,
              -0.9065830111503601,
              -0.16977399587631226,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.09247110038995743,
              -0.9956049919128418,
              -0.013397599570453167,
              -0.024201199412345886,
              -0.9967650175094604,
              -0.07635729759931564,
              -0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.06949059665203094,
              -0.994841992855072,
              0.07385479658842087,
              -0.0278634000569582,
              -0.9990839958190918,
              -0.031678199768066406,
              0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              -0.0278634000569582,
              -0.9990839958190918,
              -0.031678199768066406,
              0.0860012024641037,
              -0.993927001953125,
              -0.06839200109243393,
              -0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              -0.00018311099847778678,
              -0.9999690055847168,
              -0.002563549904152751,
              -0.09247110038995743,
              -0.9956049919128418,
              -0.013397599570453167,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.024201199412345886,
              -0.9967650175094604,
              -0.07635729759931564,
              -0.00018311099847778678,
              -0.9999690055847168,
              -0.002563549904152751,
              -0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              0.06949059665203094,
              -0.994841992855072,
              0.07385479658842087,
              0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              0.0860012024641037,
              -0.993927001953125,
              -0.06839200109243393,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              -0.9881280064582825,
              -0.14471900463104248,
              0.050935398787260056,
              -0.8543959856033325,
              -0.13968299329280853,
              -0.5004429817199707,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              0,
              0,
              -1,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              0.09247110038995743,
              -0.9956049919128418,
              -0.013397599570453167,
              0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              0.024201199412345886,
              -0.9967650175094604,
              -0.07635729759931564,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              -0.06949059665203094,
              -0.994841992855072,
              0.07385479658842087,
              -0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              0.0278634000569582,
              -0.9990839958190918,
              -0.031678199768066406,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              0.031098399311304092,
              -0.999176025390625,
              -0.025727100670337677,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.46247801184654236,
              0.1551869958639145,
              -0.8729209899902344,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.013244999572634697,
              -0.9976810216903687,
              -0.06653030216693878,
              0.017029300332069397,
              -0.9993900060653687,
              -0.029938699677586555,
              -0.028839999809861183,
              -0.9993900060653687,
              -0.01873840019106865,
              0.23068900406360626,
              0.22968199849128723,
              -0.9454939961433411,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              0.061067499220371246,
              0.31894901394844055,
              -0.9457690119743347,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              -0.0860012024641037,
              -0.993927001953125,
              -0.06839200109243393,
              0.0278634000569582,
              -0.9990839958190918,
              -0.031678199768066406,
              -0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              0.09247110038995743,
              -0.9956049919128418,
              -0.013397599570453167,
              0.00018311099847778678,
              -0.9999690055847168,
              -0.002563549904152751,
              0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.024201199412345886,
              -0.9967650175094604,
              -0.07635729759931564,
              0.0705282986164093,
              -0.9973139762878418,
              -0.01947079971432686,
              0.00018311099847778678,
              -0.9999690055847168,
              -0.002563549904152751,
              -0.0562455989420414,
              -0.9975590109825134,
              0.04074219986796379,
              -0.06949059665203094,
              -0.994841992855072,
              0.07385479658842087,
              -0.0860012024641037,
              -0.993927001953125,
              -0.06839200109243393,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.14883899688720703,
              0.7823420166969299,
              -0.6047549843788147,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.06946010142564774,
              0.0934171974658966,
              -0.9931939840316772,
              0.23068900406360626,
              0.22968199849128723,
              -0.9454939961433411,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.5439919829368591,
              0.12024299800395966,
              -0.8304089903831482,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0.322519987821579,
              0.11990699917078018,
              -0.9389020204544067,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0,
              0,
              -1,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              0,
              0,
              -1,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              -0.00015259299834724516,
              0.012817800045013428,
              -0.9999079704284668,
              0,
              0,
              -1,
              0.6908779740333557,
              0.06704919785261154,
              -0.7198100090026855,
              0.5439919829368591,
              0.12024299800395966,
              -0.8304089903831482,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.23068900406360626,
              0.22968199849128723,
              -0.9454939961433411,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.5818349719047546,
              0.5505840182304382,
              -0.5985590219497681,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.9685050249099731,
              -0.013397599570453167,
              -0.24860399961471558,
              0.9867849946022034,
              0.023102499544620514,
              -0.16022199392318726,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.9325850009918213,
              0.06131169945001602,
              -0.355663001537323,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.5439919829368591,
              0.12024299800395966,
              -0.8304089903831482,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0.23538899421691895,
              0.02865689992904663,
              -0.971464991569519,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.9325850009918213,
              0.06131169945001602,
              -0.355663001537323,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.6908779740333557,
              0.06704919785261154,
              -0.7198100090026855,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.6748859882354736,
              -0.013672299683094025,
              -0.7377539873123169,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.3975040018558502,
              0.781059980392456,
              -0.48152101039886475,
              0.5818349719047546,
              0.5505840182304382,
              -0.5985590219497681,
              0.23068900406360626,
              0.22968199849128723,
              -0.9454939961433411,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.3975040018558502,
              0.781059980392456,
              -0.48152101039886475,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.14883899688720703,
              0.7823420166969299,
              -0.6047549843788147,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.5818349719047546,
              0.5505840182304382,
              -0.5985590219497681,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              -0.05743579939007759,
              0.012878799811005592,
              -0.9982600212097168,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              0.061067499220371246,
              0.31894901394844055,
              -0.9457690119743347,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.14883899688720703,
              0.7823420166969299,
              -0.6047549843788147,
              0.23856300115585327,
              0.468641996383667,
              -0.8505510091781616,
              0.061067499220371246,
              0.31894901394844055,
              -0.9457690119743347,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0.4259769916534424,
              0.013031399808824062,
              -0.9046300053596497,
              0.6748859882354736,
              -0.013672299683094025,
              -0.7377539873123169,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              -0.926023006439209,
              0.06302069872617722,
              -0.3721120059490204,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              0.23538899421691895,
              0.02865689992904663,
              -0.971464991569519,
              0.4259769916534424,
              0.013031399808824062,
              -0.9046300053596497,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              -0.010864599607884884,
              0.2299569994211197,
              -0.9731130003929138,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.010864599607884884,
              0.2299569994211197,
              -0.9731130003929138,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              -0.05743579939007759,
              0.012878799811005592,
              -0.9982600212097168,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.9823909997940063,
              0.007873780094087124,
              -0.1865600049495697,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.010864599607884884,
              0.2299569994211197,
              -0.9731130003929138,
              -0.0031128900591284037,
              0.0966826006770134,
              -0.9952999949455261,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.9867849946022034,
              0.023102499544620514,
              -0.16022199392318726,
              0.9685050249099731,
              -0.013397599570453167,
              -0.24860399961471558,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.9762870073318481,
              0.21640700101852417,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.5683770179748535,
              0.8227480053901672,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              -0.8273869752883911,
              0.01065099984407425,
              0.5614799857139587,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.9685050249099731,
              -0.013397599570453167,
              -0.24860399961471558,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.2868430018424988,
              -0.197393998503685,
              -0.9374070167541504,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              0.04293949902057648,
              0.4396800100803375,
              0.8970919847488403,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0.4259769916534424,
              0.013031399808824062,
              -0.9046300053596497,
              0.23538899421691895,
              0.02865689992904663,
              -0.971464991569519,
              0.5439919829368591,
              0.12024299800395966,
              -0.8304089903831482,
              0.6908779740333557,
              0.06704919785261154,
              -0.7198100090026855,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              0.9325850009918213,
              0.06131169945001602,
              -0.355663001537323,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              0.9867849946022034,
              0.023102499544620514,
              -0.16022199392318726,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.4259769916534424,
              0.013031399808824062,
              -0.9046300053596497,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.6908779740333557,
              0.06704919785261154,
              -0.7198100090026855,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.926023006439209,
              0.06302069872617722,
              -0.3721120059490204,
              -0.9866939783096313,
              0.02352979965507984,
              -0.16074100136756897,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              -0.05020289868116379,
              0.15018199384212494,
              0.9873650074005127,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              -0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              -0.3975040018558502,
              0.781059980392456,
              -0.48155200481414795,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.46247801184654236,
              0.1551869958639145,
              -0.8729209899902344,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              -0.3975040018558502,
              0.781059980392456,
              -0.48155200481414795,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.46247801184654236,
              0.1551869958639145,
              -0.8729209899902344,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.6861780285835266,
              0.060060400515794754,
              -0.7249370217323303,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              -0.14920499920845032,
              0.7845699787139893,
              -0.6017640233039856,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.8273869752883911,
              0.01065099984407425,
              0.5614799857139587,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              -0.9866939783096313,
              0.02352979965507984,
              -0.16074100136756897,
              -0.926023006439209,
              0.06302069872617722,
              -0.3721120059490204,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.42188799381256104,
              0.006439410150051117,
              -0.9066129922866821,
              -0.6675310134887695,
              -0.00335703999735415,
              -0.7445300221443176,
              -0.00015259299834724516,
              0.012817800045013428,
              -0.9999079704284668,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.6675310134887695,
              -0.00335703999735415,
              -0.7445300221443176,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              -0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.00015259299834724516,
              0.012817800045013428,
              -0.9999079704284668,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              -0.00015259299834724516,
              0.012817800045013428,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              0,
              0,
              -1,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              0,
              0.8135010004043579,
              -0.581529974937439,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.0013428099919110537,
              0.9999690055847168,
              0,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.9307230114936829,
              0.004394670017063618,
              -0.3656120002269745,
              -0.9823909997940063,
              0.007873780094087124,
              -0.1865600049495697,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.8135010004043579,
              -0.581529974937439,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.8135010004043579,
              0.581529974937439,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0,
              0.5556809902191162,
              0.8313850164413452,
              -0.048982199281454086,
              0.5861999988555908,
              0.8086789846420288,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.5683770179748535,
              0.8227480053901672,
              -0.42188799381256104,
              0.006439410150051117,
              -0.9066129922866821,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.2560200095176697,
              -0.005951109807938337,
              -0.9666429758071899,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              -0.06247140094637871,
              0.00866726040840149,
              -0.9979860186576843,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              -0.42188799381256104,
              0.006439410150051117,
              -0.9066129922866821,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              -0.6675310134887695,
              -0.00335703999735415,
              -0.7445300221443176,
              -0.048982199281454086,
              0.5861999988555908,
              0.8086789846420288,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0,
              0,
              -1,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              -0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              -0.3975040018558502,
              0.781059980392456,
              -0.48155200481414795,
              -0.14920499920845032,
              0.7845699787139893,
              -0.6017640233039856,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.926023006439209,
              0.06302069872617722,
              -0.3721120059490204,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.3975040018558502,
              0.781059980392456,
              -0.48155200481414795,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.42188799381256104,
              0.006439410150051117,
              -0.9066129922866821,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.2152779996395111,
              -0.2038940042257309,
              0.955016016960144,
              0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0,
              0,
              1,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              -0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0,
              0,
              -1,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.9881280064582825,
              -0.14471900463104248,
              0.050935398787260056,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              -0.8543959856033325,
              -0.13968299329280853,
              -0.5004429817199707,
              0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0.06946010142564774,
              0.0934171974658966,
              -0.9931939840316772,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              -0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              0,
              1,
              0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              0.6894739866256714,
              -0.7207859754562378,
              -0.07092499732971191,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0,
              1,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              -0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              -0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.2152779996395111,
              -0.2038639932870865,
              0.955016016960144,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              -0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              -0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              -0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              0,
              0,
              -1,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              -0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              -0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              -0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              -0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              -0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.9855949878692627,
              0.14532899856567383,
              0.08639790117740631,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              -0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              -0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.04293949902057648,
              -0.4396800100803375,
              -0.8970919847488403,
              -0.02578810043632984,
              -0.2112489938735962,
              -0.9770810008049011,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              -0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.05035550147294998,
              -0.9987180233001709,
              0,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              -0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              -0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              -0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              -0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              -0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              -0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              -0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              0,
              0,
              -1,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.9325850009918213,
              0.06131169945001602,
              -0.355663001537323,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              0.05035550147294998,
              -0.9987180233001709,
              0,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0.060029901564121246,
              -0.012909299694001675,
              -0.9981080293655396,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              -0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.9307230114936829,
              0.004394670017063618,
              0.3656120002269745,
              -0.8273869752883911,
              0.01065099984407425,
              0.5614799857139587,
              -0.42188799381256104,
              0.006439410150051117,
              -0.9066129922866821,
              -0.2560200095176697,
              -0.005951109807938337,
              -0.9666429758071899,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              -0.018585799261927605,
              0.974058985710144,
              -0.22543999552726746,
              0.05035550147294998,
              0.9987180233001709,
              0,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              -0.018585799261927605,
              0.974058985710144,
              -0.22543999552726746,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              0.005157629959285259,
              0.6755579710006714,
              -0.7372360229492188,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              0.8736230134963989,
              0.40345498919487,
              -0.27198100090026855,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.8736230134963989,
              0.40345498919487,
              -0.27198100090026855,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0.8736230134963989,
              0.40345498919487,
              0.27198100090026855,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.9185460209846497,
              0.015106700360774994,
              0.39497101306915283,
              0.8736230134963989,
              0.40345498919487,
              0.27198100090026855,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.8736230134963989,
              0.40345498919487,
              -0.27198100090026855,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.05035550147294998,
              -0.9987180233001709,
              0,
              -0.01864680089056492,
              -0.974058985710144,
              -0.22543999552726746,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.9911500215530396,
              0,
              0.13251100480556488,
              0.9185460209846497,
              0.015106700360774994,
              0.39497101306915283,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.04293949902057648,
              0,
              -0.9990540146827698,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              0.005157629959285259,
              0.6755579710006714,
              0.7372360229492188,
              0.04293949902057648,
              0.4396800100803375,
              0.8970919847488403,
              -0.02578810043632984,
              0.2112800031900406,
              -0.9770810008049011,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              -0.007934809662401676,
              0.0034180700313299894,
              -0.9999390244483948,
              0.8736230134963989,
              0.40345498919487,
              0.27198100090026855,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0.9185460209846497,
              0.015106700360774994,
              -0.39497101306915283,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              -0.018585799261927605,
              0.974058985710144,
              0.22543999552726746,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              0.05035550147294998,
              0.9987180233001709,
              0,
              -0.02578810043632984,
              -0.2112489938735962,
              0.9770810008049011,
              0.04293949902057648,
              -0.4396800100803375,
              0.8970919847488403,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              0.5551319718360901,
              -0.39146101474761963,
              0.733847975730896,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.8736230134963989,
              -0.4034239947795868,
              0.27198100090026855,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              0.8736230134963989,
              -0.4034239947795868,
              0.27198100090026855,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              0.8736230134963989,
              -0.4034239947795868,
              -0.27198100090026855,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.8736230134963989,
              -0.4034239947795868,
              -0.27198100090026855,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.8736230134963989,
              -0.4034239947795868,
              -0.27198100090026855,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0.8736230134963989,
              -0.4034239947795868,
              0.27198100090026855,
              0.6471139788627625,
              -0.7623519897460938,
              0,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              -0.007934809662401676,
              0.0034180700313299894,
              0.9999390244483948,
              0.04293949902057648,
              0,
              0.9990540146827698,
              0.5551319718360901,
              -0.39146101474761963,
              0.733847975730896,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              -0.01864680089056492,
              -0.974058985710144,
              0.22543999552726746,
              -0.01864680089056492,
              -0.974058985710144,
              -0.22543999552726746,
              0.05035550147294998,
              -0.9987180233001709,
              0,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              -0.007934809662401676,
              -0.0034180700313299894,
              -0.9999390244483948,
              0.04293949902057648,
              0,
              -0.9990540146827698,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.04293949902057648,
              -0.4396800100803375,
              -0.8970919847488403,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              -0.007934809662401676,
              -0.0034180700313299894,
              0.9999390244483948,
              -0.02578810043632984,
              -0.2112489938735962,
              0.9770810008049011,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              0.005127110052853823,
              -0.6755579710006714,
              0.7372660040855408,
              -0.01864680089056492,
              -0.974058985710144,
              0.22543999552726746,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.005127110052853823,
              -0.6755579710006714,
              0.7372660040855408,
              0.04293949902057648,
              -0.4396800100803375,
              0.8970919847488403,
              -0.02578810043632984,
              -0.2112489938735962,
              0.9770810008049011,
              0.005157629959285259,
              0.6755579710006714,
              -0.7372360229492188,
              0.04293949902057648,
              0.4397110044956207,
              -0.8970919847488403,
              -0.02578810043632984,
              0.2112800031900406,
              -0.9770810008049011,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.04293949902057648,
              0,
              -0.9990540146827698,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              -0.02578810043632984,
              -0.2112489938735962,
              -0.9770810008049011,
              0.04293949902057648,
              -0.4396800100803375,
              -0.8970919847488403,
              0.005127110052853823,
              -0.6755579710006714,
              -0.7372660040855408,
              -0.01864680089056492,
              -0.974058985710144,
              -0.22543999552726746,
              0.005127110052853823,
              -0.6755579710006714,
              -0.7372660040855408,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.9911500215530396,
              0,
              0.13251100480556488,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              -0.007934809662401676,
              0.0034180700313299894,
              0.9999390244483948,
              -0.007934809662401676,
              -0.0034180700313299894,
              0.9999390244483948,
              0.04293949902057648,
              0,
              0.9990540146827698,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              0.9911500215530396,
              0,
              -0.13251100480556488,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.9185460209846497,
              0.015106700360774994,
              -0.39497101306915283,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.007934809662401676,
              0.0034180700313299894,
              0.9999390244483948,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              -0.02578810043632984,
              0.2112800031900406,
              0.9770810008049011,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.02578810043632984,
              0.2112800031900406,
              0.9770810008049011,
              0.04293949902057648,
              0.4396800100803375,
              0.8970919847488403,
              0.005157629959285259,
              0.6755579710006714,
              0.7372360229492188,
              0.005157629959285259,
              0.6755579710006714,
              0.7372360229492188,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              -0.018585799261927605,
              0.974058985710144,
              0.22543999552726746,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              -0.02578810043632984,
              0.2112800031900406,
              -0.9770810008049011,
              0.04293949902057648,
              0.4397110044956207,
              -0.8970919847488403,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              0.9911500215530396,
              0,
              -0.13251100480556488,
              0.9185460209846497,
              -0.015106700360774994,
              -0.39497101306915283,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              -0.007934809662401676,
              0.0034180700313299894,
              -0.9999390244483948,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              0.04293949902057648,
              0,
              -0.9990540146827698,
              -0.007934809662401676,
              -0.0034180700313299894,
              -0.9999390244483948,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              -0.02578810043632984,
              -0.2112489938735962,
              -0.9770810008049011,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              0.8736230134963989,
              -0.4034239947795868,
              0.27198100090026855,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.9185460209846497,
              0.015106700360774994,
              -0.39497101306915283,
              0.8736230134963989,
              0.40345498919487,
              -0.27198100090026855,
              -0.007934809662401676,
              -0.0034180700313299894,
              -0.9999390244483948,
              -0.007934809662401676,
              0.0034180700313299894,
              -0.9999390244483948,
              0.04293949902057648,
              0,
              -0.9990540146827698,
              0.5551620125770569,
              0.39146101474761963,
              0.733847975730896,
              0.04293949902057648,
              0.4396800100803375,
              0.8970919847488403,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              0.04293949902057648,
              -0.4396800100803375,
              0.8970919847488403,
              0.5551319718360901,
              -0.39146101474761963,
              0.733847975730896,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423810243606567,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              0.03872799873352051,
              -0.9496750235557556,
              -0.31073901057243347,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.8508260250091553,
              -0.014221600256860256,
              0.5251929759979248,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              0.01986760087311268,
              -0.5623649954795837,
              -0.8266239762306213,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              0.038697499781847,
              0.9496750235557556,
              0.3107700049877167,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.5936459898948669,
              -0.8046820163726807,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              0.03872799873352051,
              -0.9496750235557556,
              -0.31073901057243347,
              0.03872799873352051,
              -0.9496750235557556,
              0.31073901057243347,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              0.01986760087311268,
              -0.5623649954795837,
              0.8266239762306213,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              -0.5936459898948669,
              -0.8046820163726807,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              0.01986760087311268,
              0.5623649954795837,
              -0.8266239762306213,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423810243606567,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              0.01986760087311268,
              0.5623649954795837,
              0.8266239762306213,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              0.01986760087311268,
              -0.5623649954795837,
              -0.8266239762306213,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              0.038697499781847,
              0.9496750235557556,
              -0.3107999861240387,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              0.038697499781847,
              0.9496750235557556,
              0.3107700049877167,
              0.038697499781847,
              0.9496750235557556,
              -0.3107700049877167,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.5936459898948669,
              -0.8046820163726807,
              0,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              0.03872799873352051,
              -0.9496750235557556,
              0.31073901057243347,
              0.01986760087311268,
              -0.5623649954795837,
              0.8266239762306213,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.5936459898948669,
              -0.8047119975090027,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              0.03872799873352051,
              -0.9496750235557556,
              -0.3107700049877167,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.05020289868116379,
              0.15018199384212494,
              0.9873650074005127,
              -0.0018616300076246262,
              0.10715000331401825,
              0.9942319989204407,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423509836196899,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.8508260250091553,
              -0.014221600256860256,
              0.5251929759979248,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              0.01986760087311268,
              -0.5623340010643005,
              -0.8266239762306213,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.5936459898948669,
              -0.8047119975090027,
              0,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              0.03872799873352051,
              -0.9496750235557556,
              -0.3107700049877167,
              0.03872799873352051,
              -0.9496750235557556,
              0.3107700049877167,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              0.038697499781847,
              0.9496750235557556,
              0.3107999861240387,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.8508260250091553,
              -0.014221600256860256,
              0.5251929759979248,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.5936459898948669,
              -0.8047119975090027,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              0.03872799873352051,
              -0.9496750235557556,
              0.3107700049877167,
              0.01986760087311268,
              -0.5623340010643005,
              0.8266239762306213,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423509836196899,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              0.01986760087311268,
              0.5623340010643005,
              0.8266239762306213,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              0.01986760087311268,
              -0.5623340010643005,
              -0.8266239762306213,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.023621300235390663,
              0.4852440059185028,
              0.874019980430603,
              0.028717899695038795,
              0.7368999719619751,
              0.6753749847412109,
              -0.10763899981975555,
              0.9347820281982422,
              0.33851099014282227,
              0.03872799873352051,
              -0.9496750235557556,
              0.3107700049877167,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              0.23206299543380737,
              -0.01049839984625578,
              -0.9726250171661377,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              -0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              -0.8543959856033325,
              -0.13968299329280853,
              -0.5004429817199707,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.02056949958205223,
              0.2261119931936264,
              0.9738759994506836,
              -0.023621300235390663,
              0.4852440059185028,
              0.874019980430603,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.538254976272583,
              0,
              0.8427690267562866,
              -0.013275600038468838,
              -0.09430219978094101,
              -0.9954220056533813,
              0.02056949958205223,
              -0.2261420041322708,
              -0.9738460183143616,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.00463881017640233,
              0.004425180144608021,
              0.9999690055847168,
              0.02056949958205223,
              0.2261119931936264,
              0.9738759994506836,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              -0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              -0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              -0.011597000062465668,
              0,
              0.9999079704284668,
              0.00463881017640233,
              0.004425180144608021,
              0.9999690055847168,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              0.02877900004386902,
              -0.7368689775466919,
              0.6753749847412109,
              0.020600000396370888,
              -0.2261119931936264,
              0.9738759994506836,
              -0.023590799421072006,
              -0.485275000333786,
              0.874019980430603,
              0.028717899695038795,
              0.7368999719619751,
              0.6753749847412109,
              -0.023621300235390663,
              0.4852440059185028,
              0.874019980430603,
              0.02056949958205223,
              0.2261119931936264,
              0.9738759994506836,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              -0.10760799795389175,
              -0.9347820281982422,
              0.33845001459121704,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              0.00463881017640233,
              -0.004425180144608021,
              0.9999690055847168,
              -0.011597000062465668,
              0,
              0.9999079704284668,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              0.0318307988345623,
              -0.9994810223579407,
              0,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              0.028717899695038795,
              -0.7368999719619751,
              -0.6753439903259277,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              -0.7093719840049744,
              0.6329839825630188,
              -0.3099760115146637,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              -0.6574910283088684,
              0.1848199963569641,
              -0.730430006980896,
              -0.7093719840049744,
              0.6329839825630188,
              -0.3099760115146637,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              0.028717899695038795,
              -0.7368999719619751,
              -0.6753439903259277,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              -0.10760799795389175,
              -0.9347820281982422,
              0.33845001459121704,
              -0.023590799421072006,
              -0.485275000333786,
              0.874019980430603,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              -0.10763899981975555,
              0.9347820281982422,
              0.33851099014282227,
              0.031800299882888794,
              0.9994810223579407,
              0,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              -0.011597000062465668,
              0,
              0.9999079704284668,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              0.028687400743365288,
              0.7368999719619751,
              -0.6753749847412109,
              0.020539000630378723,
              0.2261119931936264,
              -0.9738759994506836,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              -0.013275600038468838,
              0.09430219978094101,
              -0.9954220056533813,
              -0.6574910283088684,
              0.1848199963569641,
              -0.730430006980896,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              -0.013275600038468838,
              -0.09430219978094101,
              -0.9954220056533813,
              -0.6574910283088684,
              -0.1848199963569641,
              -0.7303990125656128,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              0.028717899695038795,
              -0.7368999719619751,
              -0.6753439903259277,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              0.02056949958205223,
              -0.2261420041322708,
              -0.9738460183143616,
              0.028687400743365288,
              0.7368999719619751,
              -0.6753749847412109,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              0.031800299882888794,
              0.9994810223579407,
              0,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.02877900004386902,
              -0.7368689775466919,
              0.6753749847412109,
              -0.023590799421072006,
              -0.485275000333786,
              0.874019980430603,
              -0.10760799795389175,
              -0.9347820281982422,
              0.33845001459121704,
              0.00463881017640233,
              0.004425180144608021,
              0.9999690055847168,
              -0.011597000062465668,
              0,
              0.9999079704284668,
              0.00463881017640233,
              -0.004425180144608021,
              0.9999690055847168,
              -0.6574910283088684,
              -0.1848199963569641,
              -0.7303990125656128,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.6574910283088684,
              0.1848199963569641,
              -0.730430006980896,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              0.045716699212789536,
              0.015991700813174248,
              0.9988099932670593,
              0,
              0,
              0.9999690055847168,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0,
              0,
              0.9999690055847168,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0.04620499908924103,
              0.02639850042760372,
              0.9985659718513489,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.1656239926815033,
              0.15973399579524994,
              0.973143994808197,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              -0.06775110214948654,
              0.015442400239408016,
              0.9975590109825134,
              0,
              0,
              1,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              -0.0018616300076246262,
              0.10715000331401825,
              0.9942319989204407,
              0.061067499220371246,
              0.14102600514888763,
              0.9880980253219604,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              0,
              0,
              1,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              -0.06775110214948654,
              0.015442400239408016,
              0.9975590109825134,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.09830009937286377,
              0.028015999123454094,
              0.9947509765625,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.06775110214948654,
              0.015442400239408016,
              0.9975590109825134,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              -0.06289870291948318,
              -0.019409799948334694,
              0.9978029727935791,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              -0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              -0.0031128900591284037,
              0.0966826006770134,
              -0.9952999949455261,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0.6748859882354736,
              -0.013672299683094025,
              -0.7377539873123169,
              0.787988007068634,
              0.04412980005145073,
              -0.614063024520874,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.3975040018558502,
              0.781059980392456,
              -0.48152101039886475,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.9325850009918213,
              0.06131169945001602,
              -0.355663001537323,
              0.9867849946022034,
              0.023102499544620514,
              -0.16022199392318726,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0,
              0,
              1,
              0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              0,
              -1,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              0.04620499908924103,
              0.02639850042760372,
              0.9985659718513489,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0,
              0,
              1,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.16705800592899323,
              -0.21643699705600739,
              -0.9618819952011108,
              0.2868430018424988,
              -0.197393998503685,
              -0.9374070167541504,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              -0.016754699870944023,
              0.0047914101742208,
              -0.9998469948768616,
              0,
              0,
              -1,
              -0.016754699870944023,
              0.0047914101742208,
              -0.9998469948768616,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0,
              0,
              1,
              0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0,
              0,
              1,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.21283599734306335,
              0.9770810008049011,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              -0.9866939783096313,
              0.02352979965507984,
              -0.16074100136756897,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              -0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              -0.048982199281454086,
              0.5861999988555908,
              0.8086789846420288,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.5556809902191162,
              0.8313850164413452,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0,
              0.29545000195503235,
              0.9553210139274597,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.29545000195503235,
              0.9553210139274597,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.22061799466609955,
              0.9753410220146179,
              -0.8305609822273254,
              -0.006073180120438337,
              0.5568410158157349,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.8755459785461426,
              -0.0038148099556565285,
              0.4830769896507263,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.29545000195503235,
              0.9553210139274597,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0,
              1,
              -0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              -0.538254976272583,
              0,
              0.8427690267562866,
              0,
              0.21283599734306335,
              0.9770810008049011,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.027680300176143646,
              0.9996029734611511,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0,
              0,
              1,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              -1,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0,
              0.027771800756454468,
              0.9996029734611511,
              -0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0,
              0,
              1,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              0,
              0,
              1,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              -0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.2152779996395111,
              -0.2038940042257309,
              0.955016016960144,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0.6894739866256714,
              -0.7207859754562378,
              -0.07092499732971191,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.22061799466609955,
              0.9753410220146179,
              -0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.007477030158042908,
              0.9999690055847168,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0,
              0,
              1,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              -0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0,
              0,
              1,
              0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              -0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.6861780285835266,
              0.060060400515794754,
              -0.7249370217323303,
              -0.46247801184654236,
              0.1551869958639145,
              -0.8729209899902344,
              -0.8755459785461426,
              -0.0038148099556565285,
              0.4830769896507263,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0,
              0,
              1,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              -0.034150201827287674,
              -0.034363798797130585,
              0.9988099932670593,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              0.058504000306129456,
              -0.022888900712132454,
              0.9980159997940063,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0.045716699212789536,
              0.015991700813174248,
              0.9988099932670593,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              -0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              -0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.06289870291948318,
              -0.019409799948334694,
              0.9978029727935791,
              0,
              0,
              1,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              0,
              0,
              1,
              -0.06289870291948318,
              -0.019409799948334694,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0,
              1,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              -0.034150201827287674,
              -0.034363798797130585,
              0.9988099932670593,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0,
              0,
              1,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              0,
              0,
              1,
              -0.06775110214948654,
              0.015442400239408016,
              0.9975590109825134,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              0,
              0,
              1,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              0,
              1,
              0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              -0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              1,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              0,
              0,
              1,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0.007416000124067068,
              -0.009491260163486004,
              0.9999079704284668,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              0,
              0,
              1,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0.025635499507188797,
              -0.09939879924058914,
              0.9946900010108948,
              -0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              -0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              -0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              -0.2152779996395111,
              -0.2038639932870865,
              0.955016016960144,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.04620499908924103,
              0.02639850042760372,
              0.9985659718513489,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              -0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              -0.010864599607884884,
              0.2299569994211197,
              -0.9731130003929138,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              0,
              0.9394509792327881,
              0.3426010012626648,
              0,
              0.8135010004043579,
              0.581529974937439,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.015320300124585629,
              -0.00012207399413455278,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              0,
              0,
              1,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              0.061067499220371246,
              0.14102600514888763,
              0.9880980253219604,
              -0.0018616300076246262,
              0.10715000331401825,
              0.9942319989204407,
              0.045716699212789536,
              0.015991700813174248,
              0.9988099932670593,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              0.025635499507188797,
              -0.09939879924058914,
              0.9946900010108948,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              0.025635499507188797,
              -0.09939879924058914,
              0.9946900010108948,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              -0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              -0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0,
              0,
              1,
              0.007416000124067068,
              -0.009491260163486004,
              0.9999079704284668,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              -0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              -0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.025635499507188797,
              -0.09939879924058914,
              0.9946900010108948,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              -0.034150201827287674,
              -0.034363798797130585,
              0.9988099932670593,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              0,
              0,
              1,
              -0.034150201827287674,
              -0.034363798797130585,
              0.9988099932670593,
              -0.09872739762067795,
              -0.0017090400215238333,
              0.9950860142707825,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              0,
              0,
              0.9999690055847168,
              -0.1656239926815033,
              0.15973399579524994,
              0.973143994808197,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.09830009937286377,
              0.028015999123454094,
              0.9947509765625,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              -0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.04620499908924103,
              0.02639850042760372,
              0.9985659718513489,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.0861537978053093,
              -0.04821920022368431,
              0.9950860142707825,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.06289870291948318,
              -0.019409799948334694,
              0.9978029727935791,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              0.058504000306129456,
              -0.022888900712132454,
              0.9980159997940063,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0.007416000124067068,
              -0.009491260163486004,
              0.9999079704284668,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0.058504000306129456,
              -0.022888900712132454,
              0.9980159997940063,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0.03900270164012909,
              -0.038544900715351105,
              0.9984740018844604,
              0.025635499507188797,
              -0.09939879924058914,
              0.9946900010108948,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0.058504000306129456,
              -0.022888900712132454,
              0.9980159997940063,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              0.058504000306129456,
              -0.022888900712132454,
              0.9980159997940063,
              0.020935699343681335,
              -0.009491260163486004,
              0.9997249841690063,
              0.019318200647830963,
              0.008728289976716042,
              0.9997559785842896,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              -0.0861537978053093,
              -0.04821920022368431,
              0.9950860142707825,
              -0.09872739762067795,
              -0.0017090400215238333,
              0.9950860142707825,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.00015259299834724516,
              -0.0858485996723175,
              0.9962769746780396,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              -0.8002259731292725,
              0.0023804400116205215,
              -0.5996580123901367,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              -0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0.045716699212789536,
              0.015991700813174248,
              0.9988099932670593,
              -0.0018616300076246262,
              0.10715000331401825,
              0.9942319989204407,
              0,
              0,
              0.9999690055847168,
              -0.09830009937286377,
              0.028015999123454094,
              0.9947509765625,
              0,
              0,
              0.9999690055847168,
              -0.1656239926815033,
              0.15973399579524994,
              0.973143994808197,
              0,
              0,
              0.9999690055847168,
              -0.05020289868116379,
              0.15018199384212494,
              0.9873650074005127,
              -0.1656239926815033,
              0.15973399579524994,
              0.973143994808197,
              -0.05020289868116379,
              0.15018199384212494,
              0.9873650074005127,
              0,
              0,
              0.9999690055847168,
              -0.0018616300076246262,
              0.10715000331401825,
              0.9942319989204407,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              -0.0861537978053093,
              -0.04821920022368431,
              0.9950860142707825,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              -0.0861537978053093,
              -0.04821920022368431,
              0.9950860142707825,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              -0.018463699147105217,
              -0.10577700287103653,
              0.9942010045051575,
              0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.06289870291948318,
              -0.019409799948334694,
              0.9978029727935791,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              -0.034150201827287674,
              -0.034363798797130585,
              0.9988099932670593,
              -0.0724204033613205,
              -0.06759849935770035,
              0.9950559735298157,
              -0.6861780285835266,
              0.060060400515794754,
              -0.7249370217323303,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.46247801184654236,
              0.1551869958639145,
              -0.8729209899902344,
              -0.0861537978053093,
              -0.04821920022368431,
              0.9950860142707825,
              -0.0148930000141263,
              -0.04760890081524849,
              0.9987490177154541,
              -0.09872739762067795,
              -0.0017090400215238333,
              0.9950860142707825,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              -0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0,
              0,
              1,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0.005523850210011005,
              0.030060699209570885,
              0.9995120167732239,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.053895700722932816,
              0.2761619985103607,
              0.9595929980278015,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0,
              0.5556809902191162,
              0.8313850164413452,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0.06479080021381378,
              0.9716479778289795,
              0.2273630052804947,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0.6748859882354736,
              -0.013672299683094025,
              -0.7377539873123169,
              0.4259769916534424,
              0.013031399808824062,
              -0.9046300053596497,
              0.5203099846839905,
              0.02346869930624962,
              -0.8536329865455627,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              -0.05743579939007759,
              0.012878799811005592,
              -0.9982600212097168,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              0.5818349719047546,
              0.5505840182304382,
              -0.5985590219497681,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0.8691980242729187,
              0.42280301451683044,
              -0.25632500648498535,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0.5818349719047546,
              0.5505840182304382,
              -0.5985590219497681,
              0.3975040018558502,
              0.781059980392456,
              -0.48152101039886475,
              0.5439919829368591,
              0.12024299800395966,
              -0.8304089903831482,
              0.5309000015258789,
              0.27115699648857117,
              -0.8028500080108643,
              0.8080080151557922,
              0.20178799331188202,
              -0.5534840226173401,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              -0.0031128900591284037,
              0.0966826006770134,
              -0.9952999949455261,
              -0.010864599607884884,
              0.2299569994211197,
              -0.9731130003929138,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.5683770179748535,
              0.8227480053901672,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              0.008178959600627422,
              0.31547001004219055,
              -0.948881983757019,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0,
              0.9762870073318481,
              0.21640700101852417,
              0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0.048982199281454086,
              0.5861999988555908,
              0.8086490035057068,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0.014862500131130219,
              0.5591300129890442,
              0.8289440274238586,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0.6748859882354736,
              -0.013672299683094025,
              -0.7377539873123169,
              0.8453019857406616,
              -0.00958281010389328,
              -0.5341650247573853,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.0013428099919110537,
              0.9999690055847168,
              0,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0.02316349931061268,
              -0.031067799776792526,
              -0.9992370009422302,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              0.23206299543380737,
              -0.01049839984625578,
              -0.9726250171661377,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.6574910283088684,
              -0.1848199963569641,
              -0.7303990125656128,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              0.6218760013580322,
              -0.005371259991079569,
              -0.7830740213394165,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              -0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.23206299543380737,
              -0.01049839984625578,
              -0.9726250171661377,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.02316349931061268,
              -0.031067799776792526,
              -0.9992370009422302,
              0.16705800592899323,
              -0.21643699705600739,
              -0.9618819952011108,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.9685050249099731,
              -0.013397599570453167,
              -0.24860399961471558,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.8447520136833191,
              -0.01770070008933544,
              -0.5348060131072998,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              -0.7093719840049744,
              -0.6329839825630188,
              -0.3100070059299469,
              -0.6574910283088684,
              -0.1848199963569641,
              -0.7303990125656128,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              0,
              0,
              -1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              -0.14920499920845032,
              0.7845699787139893,
              -0.6017640233039856,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              -0.9881280064582825,
              -0.14471900463104248,
              0.050935398787260056,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0,
              0,
              -0.9999690055847168,
              0.01028470043092966,
              -0.0027771799359470606,
              -0.9999390244483948,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.3122960031032562,
              0.0012207400286570191,
              -0.9499499797821045,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0.01028470043092966,
              -0.0027771799359470606,
              -0.9999390244483948,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.010773000307381153,
              -0.027283500880002975,
              -0.9995419979095459,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.0631123036146164,
              0.07519759982824326,
              -0.9951480031013489,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.8621780276298523,
              0.04791409894824028,
              0.5043179988861084,
              -0.5745409727096558,
              0.08502460271120071,
              0.8140199780464172,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              0.060029901564121246,
              -0.012909299694001675,
              -0.9981080293655396,
              0.23206299543380737,
              -0.01049839984625578,
              -0.9726250171661377,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              -0.2987459897994995,
              -0.252936989068985,
              -0.9201639890670776,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.8607140183448792,
              0.42072799801826477,
              -0.2865380048751831,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.03503520041704178,
              0.02395699918270111,
              -0.9990839958190918,
              -0.05743579939007759,
              0.012878799811005592,
              -0.9982600212097168,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.03503520041704178,
              0.02395699918270111,
              -0.9990839958190918,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              -0.05743579939007759,
              0.012878799811005592,
              -0.9982600212097168,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.538254976272583,
              0,
              0.8427690267562866,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              -0.6675310134887695,
              -0.00335703999735415,
              -0.7445300221443176,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.7655569911003113,
              0.04831080138683319,
              -0.6415299773216248,
              -0.6861780285835266,
              0.060060400515794754,
              -0.7249370217323303,
              -0.5066379904747009,
              0.02969449944794178,
              -0.8616290092468262,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              -0.8607140183448792,
              0.42072799801826477,
              -0.2865380048751831,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.0018311099847778678,
              0.06561479717493057,
              0.9978330135345459,
              0,
              0.027771800756454468,
              0.9996029734611511,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.9762870073318481,
              0.21640700101852417,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.053895700722932816,
              0.2761619985103607,
              0.9595630168914795,
              -0.048982199281454086,
              0.5861999988555908,
              0.8086789846420288,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.0035401498898863792,
              0.9811400175094604,
              -0.19312100112438202,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.9936519861221313,
              -0.0038148099556565285,
              -0.11233899742364883,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.011902200058102608,
              0.0072634099051356316,
              0.9998779892921448,
              -0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.035157300531864166,
              0.05825980007648468,
              0.9976810216903687,
              -0.2152779996395111,
              0.2038639932870865,
              0.955016016960144,
              -0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.926023006439209,
              0.06302069872617722,
              -0.3721120059490204,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              -0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              -0.8607140183448792,
              0.42072799801826477,
              -0.2865380048751831,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.8607140183448792,
              0.42072799801826477,
              -0.2865380048751831,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              -0.0038453300949186087,
              0.014648900367319584,
              -0.9998779892921448,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              -0.14920499920845032,
              0.7845699787139893,
              -0.6017640233039856,
              -0.3975040018558502,
              0.781059980392456,
              -0.48155200481414795,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              -0.9866939783096313,
              0.02352979965507984,
              -0.16074100136756897,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              -0.9823909997940063,
              0.007873780094087124,
              -0.1865600049495697,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.048982199281454086,
              0.5861999988555908,
              0.8086789846420288,
              0,
              0.5683770179748535,
              0.8227480053901672,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0,
              0.9762870073318481,
              0.21640700101852417,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              -0.9812309741973877,
              -0.000030518498533638194,
              -0.19278499484062195,
              -0.9866939783096313,
              0.02352979965507984,
              -0.16074100136756897,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              -0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              -0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.839900016784668,
              0.005737479776144028,
              -0.542680025100708,
              -0.6675310134887695,
              -0.00335703999735415,
              -0.7445300221443176,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              0,
              0.8135010004043579,
              -0.581529974937439,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.0013428099919110537,
              0.9999690055847168,
              0,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0,
              0.9394509792327881,
              0.3426010012626648,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              -0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.5683770179748535,
              0.8227480053901672,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.5683770179748535,
              0.8227480053901672,
              -0.0006714069750159979,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.06622520089149475,
              0.9978029727935791,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.06247140094637871,
              0.00866726040840149,
              -0.9979860186576843,
              -0.2560200095176697,
              -0.005951109807938337,
              -0.9666429758071899,
              -0.21616299450397491,
              0.045197900384664536,
              -0.9752799868583679,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              -0.0007019260083325207,
              0.2817470133304596,
              -0.9594709873199463,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.9309059977531433,
              -0.0003051850071642548,
              0.3652459979057312,
              -0.9860529899597168,
              0.1629990041255951,
              0.03299050033092499,
              -0.9936519861221313,
              -0.0038148099556565285,
              -0.11233899742364883,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.9805600047111511,
              -0.022247999906539917,
              0.19480000436306,
              -0.8755459785461426,
              -0.0038148099556565285,
              0.4830769896507263,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.9860529899597168,
              0.1629990041255951,
              0.03299050033092499,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.9823300242424011,
              -0.006744590122252703,
              -0.18686500191688538,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              -0.9855949878692627,
              0.14532899856567383,
              0.08639790117740631,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              -0.9823909997940063,
              0.007873780094087124,
              -0.1865600049495697,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8018739819526672,
              -0.0019837000872939825,
              -0.5974299907684326,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              -0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              -0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              -0.8002259731292725,
              0.0023804400116205215,
              -0.5996580123901367,
              -0.9309059977531433,
              -0.0003051850071642548,
              -0.3652459979057312,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              -0.2560200095176697,
              -0.005951109807938337,
              -0.9666429758071899,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.8002259731292725,
              0.0023804400116205215,
              -0.5996580123901367,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.9309059977531433,
              -0.0003051850071642548,
              -0.3652459979057312,
              -0.8002259731292725,
              0.0023804400116205215,
              -0.5996580123901367,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.9307230114936829,
              0.004394670017063618,
              -0.3656120002269745,
              -0.8018739819526672,
              -0.0019837000872939825,
              -0.5974299907684326,
              -0.9309059977531433,
              -0.0003051850071642548,
              -0.3652459979057312,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.9823300242424011,
              -0.006744590122252703,
              -0.18686500191688538,
              -0.9307230114936829,
              0.004394670017063618,
              0.3656120002269745,
              -0.9824209809303284,
              0.007873780094087124,
              0.18640699982643127,
              -0.9884949922561646,
              -0.14667199552059174,
              0.036378100514411926,
              -0.8018739819526672,
              -0.0019837000872939825,
              -0.5974299907684326,
              -0.9307230114936829,
              0.004394670017063618,
              -0.3656120002269745,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              -0.010773000307381153,
              -0.027283500880002975,
              -0.9995419979095459,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              0,
              0,
              -0.9999690055847168,
              -0.9936519861221313,
              -0.0038148099556565285,
              -0.11233899742364883,
              -0.9823300242424011,
              -0.006744590122252703,
              -0.18686500191688538,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              -0.9823300242424011,
              -0.006744590122252703,
              -0.18686500191688538,
              -0.9936519861221313,
              -0.0038148099556565285,
              -0.11233899742364883,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.9855949878692627,
              0.14532899856567383,
              0.08639790117740631,
              -0.9309059977531433,
              -0.0003051850071642548,
              0.3652459979057312,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.9307230114936829,
              0.004394670017063618,
              0.3656120002269745,
              -0.9881280064582825,
              -0.14471900463104248,
              0.050935398787260056,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              -0.9309059977531433,
              -0.0003051850071642548,
              -0.3652459979057312,
              -0.9823300242424011,
              -0.006744590122252703,
              -0.18686500191688538,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.8305609822273254,
              -0.006073180120438337,
              0.5568410158157349,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.8273869752883911,
              0.01065099984407425,
              0.5614799857139587,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              0.14883899688720703,
              0.7823420166969299,
              -0.6047549843788147,
              0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              0.3975040018558502,
              0.781059980392456,
              -0.48152101039886475,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.8305609822273254,
              -0.006073180120438337,
              0.5568410158157349,
              -0.8755459785461426,
              -0.0038148099556565285,
              0.4830769896507263,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.21283599734306335,
              0.9770810008049011,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              -0.538254976272583,
              0,
              0.8427690267562866,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.538254976272583,
              0,
              0.8427690267562866,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.8305609822273254,
              -0.006073180120438337,
              0.5568410158157349,
              -0.9309059977531433,
              -0.0003051850071642548,
              0.3652459979057312,
              -0.9823300242424011,
              -0.006744590122252703,
              0.1868949979543686,
              -0.9307230114936829,
              0.004394670017063618,
              0.3656120002269745,
              -0.8010799884796143,
              -0.0019837000872939825,
              0.5984979867935181,
              -0.8273869752883911,
              0.01065099984407425,
              0.5614799857139587,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.7907040119171143,
              0.01080359984189272,
              -0.6120489835739136,
              -0.6219059824943542,
              -0.005371259991079569,
              -0.7830439805984497,
              0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              -0.056764401495456696,
              0.2894990146160126,
              -0.955473005771637,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              0,
              0.8135010004043579,
              -0.581529974937439,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              0,
              0.8135010004043579,
              -0.581529974937439,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.9811699986457825,
              -0.1930910050868988,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0.5605940222740173,
              -0.010254199616611004,
              -0.8279979825019836,
              0.8396859765052795,
              -0.015686500817537308,
              -0.5428329706192017,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.2560200095176697,
              -0.005951109807938337,
              -0.9666429758071899,
              -0.06247140094637871,
              0.00866726040840149,
              -0.9979860186576843,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              -0.8002259731292725,
              0.0023804400116205215,
              -0.5996580123901367,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              -0.00015259299834724516,
              0.012817800045013428,
              -0.9999079704284668,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.8018739819526672,
              -0.0019837000872939825,
              -0.5974299907684326,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              -0.06247140094637871,
              0.00866726040840149,
              -0.9979860186576843,
              -0.07373269647359848,
              0.03021330013871193,
              -0.9967960119247437,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.6894739866256714,
              -0.7207859754562378,
              -0.07092499732971191,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0,
              0.29545000195503235,
              0.9553210139274597,
              0,
              0.21283599734306335,
              0.9770810008049011,
              0,
              0.5556809902191162,
              0.8313850164413452,
              0,
              0.8135010004043579,
              0.581529974937439,
              0,
              0.5556809902191162,
              0.8313850164413452,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.14920499920845032,
              0.7845699787139893,
              -0.6017640233039856,
              -0.029725000262260437,
              0.8726159930229187,
              -0.4874719977378845,
              -0.02517779916524887,
              0.5487229824066162,
              -0.8355969786643982,
              -0.8018739819526672,
              -0.0019837000872939825,
              -0.5974299907684326,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.7561569809913635,
              0,
              -0.6543470025062561,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              0.01028470043092966,
              -0.0027771799359470606,
              -0.9999390244483948,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0,
              0.8135010004043579,
              0.581529974937439,
              0,
              0.9394509792327881,
              0.3426010012626648,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              0.2152779996395111,
              -0.2038940042257309,
              0.955016016960144,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0.2152779996395111,
              -0.2038940042257309,
              0.955016016960144,
              0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0.2152779996395111,
              -0.2038940042257309,
              0.955016016960144,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.5974000096321106,
              -0.05844290181994438,
              0.7997990250587463,
              0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.01504560001194477,
              -0.5587940216064453,
              0.8291569948196411,
              0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              0.6894739866256714,
              -0.7207859754562378,
              -0.07092499732971191,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0.6894739866256714,
              -0.7207859754562378,
              -0.07092499732971191,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              0.002899260027334094,
              0.13727200031280518,
              -0.9905089735984802,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              -0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              -0.00958281010389328,
              -0.047059498727321625,
              0.9988399744033813,
              -0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0,
              -0.5512250065803528,
              0.8343150019645691,
              0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.01028470043092966,
              -0.0027771799359470606,
              -0.9999390244483948,
              0,
              0,
              -0.9999690055847168,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0,
              0,
              -0.9999690055847168,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.2868430018424988,
              -0.197393998503685,
              -0.9374070167541504,
              0.16705800592899323,
              -0.21643699705600739,
              -0.9618819952011108,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0.8736230134963989,
              0.40345498919487,
              0.27198100090026855,
              0.9185460209846497,
              0.015106700360774994,
              0.39497101306915283,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              0,
              0,
              -1,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0.22922499477863312,
              -0.02725300006568432,
              -0.9729610085487366,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              -0.4040040075778961,
              -0.31461501121520996,
              -0.858913004398346,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              0.6908779740333557,
              -0.06704919785261154,
              -0.7198100090026855,
              0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.4599440097808838,
              -0.15662099421024323,
              -0.8739889860153198,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.2152779996395111,
              -0.2038639932870865,
              0.955016016960144,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              0,
              -0.8722800016403198,
              -0.4889979958534241,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              -0.20444299280643463,
              -0.9747920036315918,
              -0.08902250230312347,
              -0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              0,
              0,
              -1,
              -0.01638839952647686,
              -0.00042725898674689233,
              -0.9998469948768616,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              -0.5666679739952087,
              -0.28507301211357117,
              0.7730339765548706,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              -0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              -0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              -0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0.5551319718360901,
              -0.39146101474761963,
              0.733847975730896,
              0.8736230134963989,
              -0.4034239947795868,
              0.27198100090026855,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.44541800022125244,
              -0.8599510192871094,
              0.2490919977426529,
              -0.06476029753684998,
              -0.9716479778289795,
              0.2273630052804947,
              0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.002899260027334094,
              0.13727200031280518,
              -0.9905089735984802,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0,
              0,
              -1,
              0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              0,
              -1,
              0.00015259299834724516,
              -0.012817800045013428,
              -0.9999079704284668,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.2152779996395111,
              -0.2038639932870865,
              0.955016016960144,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              0,
              0,
              1,
              0.03561510145664215,
              -0.03570669889450073,
              0.9987180233001709,
              0,
              -0.027771800756454468,
              0.9996029734611511,
              -0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              -0.0006714069750159979,
              -0.22061799466609955,
              0.9753410220146179,
              0.007416000124067068,
              -0.009491260163486004,
              0.9999079704284668,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.2926419973373413,
              -0.5585190057754517,
              0.7761160135269165,
              -0.048982199281454086,
              -0.5861999988555908,
              0.8086789846420288,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              -0.3975040018558502,
              -0.781059980392456,
              -0.48152101039886475,
              -0.0018311099847778678,
              -0.06561479717493057,
              0.9978330135345459,
              -0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              -0.053895700722932816,
              -0.2761619985103607,
              0.9595929980278015,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0,
              -0.06622520089149475,
              0.9978029727935791,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.0035401498898863792,
              -0.9811400175094604,
              -0.19312100112438202,
              -0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              -0.787988007068634,
              -0.04412980005145073,
              -0.614063024520874,
              -0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              -0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.322519987821579,
              0.11990699917078018,
              -0.9389020204544067,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              -0.6748859882354736,
              0.013672299683094025,
              -0.7377539873123169,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              -0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              -0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              -0.014374200254678726,
              -0.9783620238304138,
              0.20627500116825104,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0,
              -0.9928889870643616,
              -0.11893100291490555,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.8453019857406616,
              0.00961332954466343,
              -0.5341650247573853,
              -0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              -0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              0.0010071099968627095,
              -0.9923400282859802,
              -0.12350799888372421,
              -0.007934809662401676,
              -0.9823600053787231,
              -0.18668200075626373,
              -0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              -0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              -0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              -0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              -0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              -0.9325850009918213,
              -0.06131169945001602,
              -0.355663001537323,
              -0.7778859734535217,
              -0.21588799357414246,
              -0.5901060104370117,
              0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              -0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              -0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              -0.005554370116442442,
              -0.29981398582458496,
              0.9539780020713806,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              -0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              -0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              -0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0,
              0.8135010004043579,
              0.581529974937439,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              -0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.834650993347168,
              0.0014954099897295237,
              -0.5507370233535767,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.8324530124664307,
              0,
              -0.5540639758110046,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              -0.6178169846534729,
              0.031495101749897,
              -0.7856680154800415,
              0.582476019859314,
              0.22428099811077118,
              -0.7812430262565613,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.9807429909706116,
              0,
              0.19516600668430328,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.8135010004043579,
              -0.581529974937439,
              0.0063783698715269566,
              0.7844480276107788,
              -0.6201059818267822,
              -0.6894739866256714,
              -0.7207859754562378,
              -0.0708945021033287,
              -0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.5856199860572815,
              0,
              0.8105720281600952,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.14191100001335144,
              -0.021027300506830215,
              0.9896240234375,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.005645920056849718,
              -0.06637780368328094,
              0.9977719783782959,
              -0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              -0.0041505200788378716,
              -0.023804400116205215,
              0.9996950030326843,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.8366950154304504,
              0.04412980005145073,
              -0.5458539724349976,
              -0.969694972038269,
              0.031189899891614914,
              -0.2421949952840805,
              -0.9762259721755981,
              0.023316100239753723,
              0.21533900499343872,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0,
              -0.7877439856529236,
              -0.6159859895706177,
              0.005859550088644028,
              -0.5551319718360901,
              -0.8317210078239441,
              -0.003601179923862219,
              -0.925961971282959,
              -0.37754398584365845,
              -0.00961332954466343,
              -0.8291879892349243,
              -0.5588549971580505,
              0.012207400053739548,
              -0.7282630205154419,
              -0.6851710081100464,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.056764401495456696,
              -0.2894990146160126,
              -0.955473005771637,
              -0.029725000262260437,
              -0.8726159930229187,
              -0.4874719977378845,
              -0.14920499920845032,
              -0.7845699787139893,
              -0.6017640233039856,
              -0.02517779916524887,
              -0.5487229824066162,
              -0.8355969786643982,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              -0.0038453300949186087,
              -0.014648900367319584,
              -0.9998779892921448,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.02826010063290596,
              0.00015259299834724516,
              -0.9995729923248291,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.3196200132369995,
              0.0014648899668827653,
              -0.9475389719009399,
              -0.5152750015258789,
              -0.0263069998472929,
              -0.8565940260887146,
              -0.4259769916534424,
              -0.013031399808824062,
              -0.9046300053596497,
              -0.22144199907779694,
              -0.045625198632478714,
              -0.9740899801254272,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.5556809902191162,
              0.8313850164413452,
              0,
              0.21283599734306335,
              0.9770810008049011,
              0,
              0.29545000195503235,
              0.9553210139274597,
              -0.2565079927444458,
              0.0005798520287498832,
              -0.9665210247039795,
              -0.3143100142478943,
              0.026032300665974617,
              -0.948943018913269,
              -0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              -0.06247140094637871,
              0.00866726040840149,
              -0.9979860186576843,
              -0.02316349931061268,
              -0.005035549867898226,
              -0.9996950030326843,
              0.02316349931061268,
              -0.031067799776792526,
              -0.9992370009422302,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0.16705800592899323,
              -0.21643699705600739,
              -0.9618819952011108,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.0013428099919110537,
              0.9999690055847168,
              0,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              -0.5580919981002808,
              -0.47584500908851624,
              -0.6797389984130859,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.4040040075778961,
              -0.31461501121520996,
              -0.858913004398346,
              -0.06173890084028244,
              -0.004364150110632181,
              -0.9980769753456116,
              -0.0192572008818388,
              0.006958220154047012,
              -0.9997860193252563,
              -0.07403790205717087,
              -0.03030489943921566,
              -0.9967650175094604,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.3177590072154999,
              0,
              -0.9481490254402161,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0.008026369847357273,
              0.9822080135345459,
              -0.18759700655937195,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              -0.0013428099919110537,
              0.9999690055847168,
              0,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0.00958281010389328,
              -0.8291569948196411,
              0.5589159727096558,
              0.0079653300344944,
              -0.9823909997940063,
              0.18659000098705292,
              0.007904290221631527,
              -0.8322700262069702,
              0.5542770028114319,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0,
              0.9394509792327881,
              0.3426010012626648,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0,
              0.9394509792327881,
              0.3426010012626648,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.8324530124664307,
              0,
              -0.5540639758110046,
              0.8326359987258911,
              -0.00012207399413455278,
              -0.5537890195846558,
              -0.0013428099919110537,
              0.9999690055847168,
              0,
              -0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0,
              0,
              -1,
              0.021454500034451485,
              0.0019837000872939825,
              -0.9997559785842896,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.02844329923391342,
              -0.00012207399413455278,
              -0.9995729923248291,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.9728080034255981,
              0.20242899656295776,
              -0.11230800300836563,
              -0.9564809799194336,
              0.09988710284233093,
              0.27408701181411743,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -0.9999690055847168,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.028839999809861183,
              0,
              -0.9995729923248291,
              -0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.8446909785270691,
              0.4360480010509491,
              0.3103429973125458,
              -0.5666679739952087,
              0.28507301211357117,
              0.7730339765548706,
              -0.2926419973373413,
              0.5585190057754517,
              0.7761160135269165,
              -0.20444299280643463,
              0.9747920036315918,
              -0.08902250230312347,
              -0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              -0.06476029753684998,
              0.9716479778289795,
              0.2273630052804947,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              -0.003753779921680689,
              0.9585250020027161,
              -0.28488999605178833,
              0,
              0.9394509792327881,
              -0.3426010012626648,
              -0.005890069995075464,
              0.98956298828125,
              -0.14377300441265106,
              0.003753779921680689,
              0.9585250020027161,
              -0.28492099046707153,
              -0.005890069995075464,
              0.98956298828125,
              0.14377300441265106,
              0,
              0.9394509792327881,
              0.3426010012626648,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              -0.002899260027334094,
              0.009918520227074623,
              -0.9999390244483948,
              -0.003051850013434887,
              0.2843410074710846,
              -0.9587079882621765,
              0.9911500215530396,
              0,
              -0.13251100480556488,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.9911500215530396,
              0,
              0.13251100480556488,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0,
              0.027771800756454468,
              0.9996029734611511,
              0,
              0.22061799466609955,
              0.9753410220146179,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              -0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              0.003753779921680689,
              0.9585250020027161,
              0.28492099046707153,
              0,
              0.8135010004043579,
              0.581529974937439,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0.04293949902057648,
              0.4396800100803375,
              0.8970919847488403,
              -0.02578810043632984,
              0.2112800031900406,
              0.9770810008049011,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              0.8736230134963989,
              0.40345498919487,
              -0.27198100090026855,
              0.9185460209846497,
              0.015106700360774994,
              -0.39497101306915283,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              0.061067499220371246,
              0.14102600514888763,
              0.9880980253219604,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0.061067499220371246,
              0.14102600514888763,
              0.9880980253219604,
              0.045716699212789536,
              0.015991700813174248,
              0.9988099932670593,
              0.06979580223560333,
              0.029755499213933945,
              0.9971010088920593,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.8446909785270691,
              -0.4360789954662323,
              0.3103429973125458,
              0.8603169918060303,
              -0.4191719889640808,
              -0.2900170087814331,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.061067499220371246,
              0.31894901394844055,
              -0.9457690119743347,
              0.002899260027334094,
              0.13727200031280518,
              -0.9905089735984802,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              -0.2987459897994995,
              -0.252936989068985,
              -0.9201639890670776,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              0.322519987821579,
              0.11990699917078018,
              -0.9389020204544067,
              0.582476019859314,
              0.22428099811077118,
              -0.7812430262565613,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              -0.2987459897994995,
              -0.252936989068985,
              -0.9201639890670776,
              0,
              0.8722800016403198,
              -0.48896801471710205,
              0,
              0.7877439856529236,
              -0.6159549951553345,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0,
              -1,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0,
              0,
              -1,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              0.582476019859314,
              0.22428099811077118,
              -0.7812430262565613,
              0.392071008682251,
              0.1608940064907074,
              -0.9057279825210571,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.23538899421691895,
              0.02865689992904663,
              -0.971464991569519,
              0.24686400592327118,
              -0.007721180096268654,
              -0.9689930081367493,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0,
              0,
              -1,
              -0.010773000307381153,
              -0.027283500880002975,
              -0.9995419979095459,
              0,
              0,
              -1,
              -0.010773000307381153,
              -0.027283500880002975,
              -0.9995419979095459,
              0,
              0,
              -1,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              0.060029901564121246,
              -0.012909299694001675,
              -0.9981080293655396,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              0.02316349931061268,
              -0.031067799776792526,
              -0.9992370009422302,
              0.23538899421691895,
              0.02865689992904663,
              -0.971464991569519,
              0.20316199958324432,
              0.014374200254678726,
              -0.9790030121803284,
              0.25714901089668274,
              0.05725270137190819,
              -0.9646599888801575,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              0.002899260027334094,
              0.13727200031280518,
              -0.9905089735984802,
              0.061067499220371246,
              0.31894901394844055,
              -0.9457690119743347,
              -0.7776730060577393,
              0.21234799921512604,
              -0.5916929841041565,
              -0.8607140183448792,
              0.42072799801826477,
              -0.2865380048751831,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              0.23068900406360626,
              0.22968199849128723,
              -0.9454939961433411,
              0.06946010142564774,
              0.0934171974658966,
              -0.9931939840316772,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              -0.1656239926815033,
              0.15973399579524994,
              0.973143994808197,
              -0.05020289868116379,
              0.15018199384212494,
              0.9873650074005127,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              0.029725000262260437,
              0.8726159930229187,
              -0.4874419867992401,
              0.14883899688720703,
              0.7823420166969299,
              -0.6047549843788147,
              0.024750500917434692,
              0.5115820169448853,
              -0.8588520288467407,
              0.12341699749231339,
              -0.05078279972076416,
              -0.9910280108451843,
              0.060029901564121246,
              -0.012909299694001675,
              -0.9981080293655396,
              0.02316349931061268,
              -0.031067799776792526,
              -0.9992370009422302,
              -0.03503520041704178,
              0.02395699918270111,
              -0.9990839958190918,
              -0.0031128900591284037,
              0.0966826006770134,
              -0.9952999949455261,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              -0.010773000307381153,
              -0.027283500880002975,
              -0.9995419979095459,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              -0.2987459897994995,
              -0.252936989068985,
              -0.9201639890670776,
              -0.5580919981002808,
              -0.47584500908851624,
              -0.6797389984130859,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.5580919981002808,
              -0.47584500908851624,
              -0.6797389984130859,
              -0.2987459897994995,
              -0.252936989068985,
              -0.9201639890670776,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              0.9185460209846497,
              -0.015106700360774994,
              0.39493998885154724,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              0.9911500215530396,
              0,
              0.13251100480556488,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.38975200057029724,
              0.0032654800452291965,
              -0.9208959937095642,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              0.06946010142564774,
              0.0934171974658966,
              -0.9931939840316772,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              -0.0631123036146164,
              0.07519759982824326,
              -0.9951480031013489,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.16705800592899323,
              -0.21643699705600739,
              -0.9618819952011108,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              0.2726829946041107,
              0.2264779955148697,
              -0.9350569844245911,
              0.1539050042629242,
              0.08111820369958878,
              -0.9847409725189209,
              0.322519987821579,
              0.11990699917078018,
              -0.9389020204544067,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              0,
              0,
              -1,
              -0.11334600299596786,
              -0.04516740143299103,
              -0.9925230145454407,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.2868430018424988,
              -0.197393998503685,
              -0.9374070167541504,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              -0.016754699870944023,
              0.0047914101742208,
              -0.9998469948768616,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              0,
              0,
              -1,
              -0.0018005899619311094,
              0.017364999279379845,
              -0.9998469948768616,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              -0.9860529899597168,
              0.1629990041255951,
              0.03299050033092499,
              -0.9855949878692627,
              0.14532899856567383,
              0.08639790117740631,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              0,
              0,
              -1,
              -0.0631123036146164,
              0.07519759982824326,
              -0.9951480031013489,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              -0.1378829926252365,
              -0.002746670041233301,
              0.9904170036315918,
              -0.09872739762067795,
              -0.0017090400215238333,
              0.9950860142707825,
              -0.09830009937286377,
              0.028015999123454094,
              0.9947509765625,
              0,
              0,
              -1,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              0,
              0,
              -1,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.5614489912986755,
              -0.0022278500255197287,
              -0.8274790048599243,
              -0.3057650029659271,
              -0.00451673986390233,
              -0.9520859718322754,
              0.582476019859314,
              0.22428099811077118,
              -0.7812430262565613,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.392071008682251,
              0.1608940064907074,
              -0.9057279825210571,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              -0.4040040075778961,
              -0.31461501121520996,
              -0.858913004398346,
              -0.4369640052318573,
              -0.17847199738025665,
              -0.8815580010414124,
              -0.4040040075778961,
              -0.31461501121520996,
              -0.858913004398346,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              -0.03112890012562275,
              -0.02661209926009178,
              -0.9991459846496582,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.392071008682251,
              0.1608940064907074,
              -0.9057279825210571,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0,
              0,
              -1,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              -0.38630300760269165,
              -0.9065830111503601,
              -0.16977399587631226,
              0,
              0,
              -1,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0.2868430018424988,
              -0.197393998503685,
              -0.9374070167541504,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.21414799988269806,
              -0.13971400260925293,
              -0.9667350053787231,
              0.22922499477863312,
              -0.02725300006568432,
              -0.9729610085487366,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.14191100001335144,
              0.021027300506830215,
              0.9896240234375,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5974000096321106,
              0.05844290181994438,
              0.7997990250587463,
              -0.9574570059776306,
              0.2034969925880432,
              0.20444299280643463,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.9860529899597168,
              0.1629990041255951,
              0.03299050033092499,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              0.5551319718360901,
              -0.39146101474761963,
              0.733847975730896,
              0.04293949902057648,
              -0.4396800100803375,
              0.8970919847488403,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              -0.0018005899619311094,
              0.017364999279379845,
              -0.9998469948768616,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              0.392071008682251,
              0.1608940064907074,
              -0.9057279825210571,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.22922499477863312,
              -0.02725300006568432,
              -0.9729610085487366,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.9185460209846497,
              0.015106700360774994,
              0.39497101306915283,
              0.9911500215530396,
              0,
              0.13251100480556488,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              0.06946010142564774,
              0.0934171974658966,
              -0.9931939840316772,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              -0.21921400725841522,
              -0.08734399825334549,
              -0.9717400074005127,
              -0.47987300157546997,
              -0.008148440159857273,
              -0.8772850036621094,
              -0.016754699870944023,
              0.0047914101742208,
              -0.9998469948768616,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              -0.0018005899619311094,
              0.017364999279379845,
              -0.9998469948768616,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.023621300235390663,
              0.4852440059185028,
              0.874019980430603,
              -0.10763899981975555,
              0.9347820281982422,
              0.33851099014282227,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0.22922499477863312,
              -0.02725300006568432,
              -0.9729610085487366,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0.392071008682251,
              0.1608940064907074,
              -0.9057279825210571,
              0.22922499477863312,
              -0.02725300006568432,
              -0.9729610085487366,
              -0.5580919981002808,
              -0.47584500908851624,
              -0.6797389984130859,
              -0.4040040075778961,
              -0.31461501121520996,
              -0.858913004398346,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              -0.20404699444770813,
              -0.3278299868106842,
              -0.9224219918251038,
              -0.5580919981002808,
              -0.47584500908851624,
              -0.6797389984130859,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              0.07199320197105408,
              0.1647389978170395,
              -0.9836729764938354,
              0.12036500126123428,
              0.36353600025177,
              -0.9237650036811829,
              0.002899260027334094,
              0.13727200031280518,
              -0.9905089735984802,
              -0.03503520041704178,
              0.02395699918270111,
              -0.9990839958190918,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              -0.32886698842048645,
              0.224371999502182,
              -0.917294979095459,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              0.04293949902057648,
              -0.4396800100803375,
              -0.8970919847488403,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.4200260043144226,
              0.24799300730228424,
              -0.8729209899902344,
              -0.0631123036146164,
              0.07519759982824326,
              -0.9951480031013489,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              0.9185460209846497,
              -0.015106700360774994,
              -0.39497101306915283,
              0.9911500215530396,
              0,
              -0.13251100480556488,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              -0.019409799948334694,
              0.06463819742202759,
              -0.9977110028266907,
              -0.006591999903321266,
              0.17960099875926971,
              -0.9837030172348022,
              -0.10638800263404846,
              0.3830380141735077,
              -0.9175699949264526,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              0.8736230134963989,
              -0.4034239947795868,
              -0.27198100090026855,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              0.9185460209846497,
              -0.015106700360774994,
              -0.39497101306915283,
              0.01986760087311268,
              0.5623649954795837,
              -0.8266239762306213,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              0.21906200051307678,
              -0.08304090052843094,
              -0.9721670150756836,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              0.6409500241279602,
              0,
              -0.7675409913063049,
              0.9185460209846497,
              -0.015106700360774994,
              -0.39497101306915283,
              0.5551620125770569,
              -0.39142999053001404,
              -0.733847975730896,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              0.04293949902057648,
              0.4397110044956207,
              -0.8970919847488403,
              0.005157629959285259,
              0.6755579710006714,
              -0.7372360229492188,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              0.05035550147294998,
              0.9987180233001709,
              0,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.005127110052853823,
              -0.6755579710006714,
              -0.7372660040855408,
              0.04293949902057648,
              -0.4396800100803375,
              -0.8970919847488403,
              0.15860499441623688,
              -0.8599510192871094,
              -0.48503100872039795,
              0.9185460209846497,
              0.015106700360774994,
              -0.39497101306915283,
              0.9995419979095459,
              0.029938699677586555,
              0,
              0.9911500215530396,
              0,
              -0.13251100480556488,
              0.04293949902057648,
              0,
              0.9990540146827698,
              -0.007934809662401676,
              -0.0034180700313299894,
              0.9999390244483948,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.01864680089056492,
              -0.974058985710144,
              0.22543999552726746,
              0.05035550147294998,
              -0.9987180233001709,
              0,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.038697499781847,
              0.9496750235557556,
              -0.3107700049877167,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.018585799261927605,
              0.974058985710144,
              -0.22543999552726746,
              -0.018585799261927605,
              0.974058985710144,
              0.22543999552726746,
              0.05035550147294998,
              0.9987180233001709,
              0,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0.04293949902057648,
              0.4397110044956207,
              -0.8970919847488403,
              0.1586350053548813,
              0.8599510192871094,
              -0.48503100872039795,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              0.8736230134963989,
              -0.4034239947795868,
              -0.27198100090026855,
              0.9185460209846497,
              -0.015106700360774994,
              -0.39497101306915283,
              0.9995419979095459,
              -0.029938699677586555,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              0.038697499781847,
              0.9496750235557556,
              -0.3107700049877167,
              0.01986760087311268,
              0.5623649954795837,
              -0.8266239762306213,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423810243606567,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              0.005127110052853823,
              -0.6755579710006714,
              0.7372660040855408,
              0.15860499441623688,
              -0.8599810004234314,
              0.48503100872039795,
              0.04293949902057648,
              -0.4396800100803375,
              0.8970919847488403,
              0.01986760087311268,
              0.5623649954795837,
              0.8266239762306213,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423810243606567,
              -0.5936459898948669,
              -0.8046820163726807,
              0,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423810243606567,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              0.04293949902057648,
              0,
              0.9990540146827698,
              0.21906200051307678,
              -0.08304090052843094,
              0.9721670150756836,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.21906200051307678,
              0.08304090052843094,
              -0.9721670150756836,
              0.04293949902057648,
              0.4397110044956207,
              -0.8970919847488403,
              0.5551620125770569,
              0.39146101474761963,
              -0.733847975730896,
              0.04293949902057648,
              0,
              0.9990540146827698,
              0.6409500241279602,
              0,
              0.7675409913063049,
              0.21906200051307678,
              0.08301030099391937,
              0.9721670150756836,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              0.019898099824786186,
              0.5623649954795837,
              -0.8266239762306213,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423509836196899,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              0.01986760087311268,
              -0.5623649954795837,
              -0.8266239762306213,
              0.03872799873352051,
              -0.9496750235557556,
              -0.31073901057243347,
              -0.833827018737793,
              -0.3886229991912842,
              0.39197999238967896,
              -0.8508260250091553,
              -0.014252100139856339,
              0.5251929759979248,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              0.01986760087311268,
              0.5623649954795837,
              0.8266239762306213,
              0.038697499781847,
              0.9496750235557556,
              0.3107700049877167,
              -0.5936459898948669,
              -0.8046820163726807,
              0,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              0.01986760087311268,
              -0.5623649954795837,
              0.8266239762306213,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423509836196899,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              0.03872799873352051,
              -0.9496750235557556,
              0.31073901057243347,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.049592599272727966,
              -0.9987490177154541,
              0,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.029664000496268272,
              -0.33320099115371704,
              -0.9423810243606567,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.5312049984931946,
              0,
              0.8472239971160889,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.029664000496268272,
              0.33320099115371704,
              0.9423509836196899,
              0.01986760087311268,
              0.5623340010643005,
              0.8266239762306213,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              0.038697499781847,
              0.9496750235557556,
              -0.3107999861240387,
              0.019898099824786186,
              0.5623649954795837,
              -0.8266239762306213,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.4370250105857849,
              -0.34632399678230286,
              0.8300729990005493,
              -0.8508260250091553,
              -0.014221600256860256,
              0.5251929759979248,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.11560399830341339,
              0.06256289780139923,
              -0.9913020133972168,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.8508260250091553,
              -0.014221600256860256,
              -0.525223970413208,
              -0.5936459898948669,
              -0.8047119975090027,
              0,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.833827018737793,
              -0.3886530101299286,
              0.39197999238967896,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.4370250105857849,
              0.34635499119758606,
              0.8300420045852661,
              -0.833827018737793,
              0.3886530101299286,
              0.39194899797439575,
              -0.8508560061454773,
              0.014221600256860256,
              0.5251629948616028,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.5312049984931946,
              0,
              -0.8472239971160889,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              0.01986760087311268,
              -0.5623340010643005,
              -0.8266239762306213,
              0.03872799873352051,
              -0.9496750235557556,
              -0.3107700049877167,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              0.038697499781847,
              0.9496750235557556,
              0.3107999861240387,
              0.038697499781847,
              0.9496750235557556,
              -0.3107999861240387,
              -0.5936459898948669,
              -0.8047119975090027,
              0,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.833827018737793,
              -0.3886229991912842,
              -0.39197999238967896,
              -0.4370250105857849,
              -0.34632399678230286,
              -0.8300729990005493,
              -0.09842219948768616,
              -0.7893610000610352,
              -0.6059449911117554,
              -0.09842219948768616,
              0.7893610000610352,
              0.6059759855270386,
              0.01986760087311268,
              0.5623340010643005,
              0.8266239762306213,
              0.038697499781847,
              0.9496750235557556,
              0.3107999861240387,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.11560399830341339,
              -0.06256289780139923,
              0.9913020133972168,
              -0.049623098224401474,
              0.9987490177154541,
              0,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.5936459898948669,
              0.8047119975090027,
              0,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              0.01986760087311268,
              -0.5623340010643005,
              0.8266239762306213,
              0.019531799480319023,
              -0.15024299919605255,
              0.9884330034255981,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              0.019531799480319023,
              0.15024299919605255,
              0.9884330034255981,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              0.019898099824786186,
              0.5623649954795837,
              -0.8266239762306213,
              0.01956240087747574,
              0.15024299919605255,
              -0.9884330034255981,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              -0.11560399830341339,
              0.06256289780139923,
              0.9913020133972168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              -0.021729199215769768,
              0,
              0.9997559785842896,
              0.005218669772148132,
              -0.0030823699198663235,
              0.9999690055847168,
              0.005218669772148132,
              0.0030823699198663235,
              0.9999690055847168,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.11560399830341339,
              -0.06256289780139923,
              -0.9913020133972168,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              0.019531799480319023,
              -0.15024299919605255,
              -0.9884330034255981,
              0.01986760087311268,
              -0.5623340010643005,
              0.8266239762306213,
              -0.029664000496268272,
              -0.33320099115371704,
              0.9423810243606567,
              -0.09842219948768616,
              -0.7893610000610352,
              0.6059449911117554,
              -0.021729199215769768,
              0,
              -0.9997559785842896,
              0.005218669772148132,
              0.0030823699198663235,
              -0.9999690055847168,
              0.005218669772148132,
              -0.0030823699198663235,
              -0.9999690055847168,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.9307230114936829,
              0.004394670017063618,
              -0.3656120002269745,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9994509816169739,
              0.032319098711013794,
              0,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.9696040153503418,
              0,
              -0.24466699361801147,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.08624529838562012,
              0.16620400547981262,
              0.9822989702224731,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.11560399830341339,
              0.06253240257501602,
              -0.9913020133972168,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              -0.8508260250091553,
              -0.014221600256860256,
              0.5251929759979248,
              -0.9696040153503418,
              0,
              0.24463599920272827,
              -0.9994509816169739,
              -0.032319098711013794,
              0,
              -0.9860529899597168,
              0.1629990041255951,
              0.03299050033092499,
              -0.9309059977531433,
              -0.0003051850071642548,
              0.3652459979057312,
              -0.9855949878692627,
              0.14532899856567383,
              0.08639790117740631,
              -0.6574910283088684,
              0.1848199963569641,
              -0.730430006980896,
              -0.013275600038468838,
              0.09430219978094101,
              -0.9954220056533813,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              0.0007934810128062963,
              -0.5551319718360901,
              0.8317509889602661,
              -0.0009155549923889339,
              -0.25034299492836,
              0.9681389927864075,
              0.0026856299955397844,
              -0.32666999101638794,
              0.9451280236244202,
              -0.556505024433136,
              -0.009186070412397385,
              -0.8307750225067139,
              -0.2956629991531372,
              -0.00039674100116826594,
              -0.9552599787712097,
              -0.3081449866294861,
              -0.014130099676549435,
              -0.9512010216712952,
              -0.9309059977531433,
              -0.0003051850071642548,
              0.3652459979057312,
              -0.8305609822273254,
              -0.006073180120438337,
              0.5568410158157349,
              -0.8008670210838318,
              0.0023804400116205215,
              0.5988039970397949,
              -0.9307230114936829,
              0.004394670017063618,
              -0.3656120002269745,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9823909997940063,
              0.007873780094087124,
              -0.1865600049495697,
              -0.8282110095024109,
              -0.010925600305199623,
              -0.5602589845657349,
              -0.9936519861221313,
              -0.0038148099556565285,
              -0.11233899742364883,
              -0.7804189920425415,
              -0.0225226990878582,
              -0.6248049736022949,
              0,
              -0.2817769944667816,
              -0.9594709873199463,
              0,
              -0.5556809902191162,
              -0.8313850164413452,
              -0.0007019260083325207,
              -0.2817470133304596,
              -0.9594709873199463,
              -0.9309059977531433,
              -0.0003051850071642548,
              -0.3652459979057312,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              0.23206299543380737,
              -0.01049839984625578,
              -0.9726250171661377,
              0.060029901564121246,
              -0.012909299694001675,
              -0.9981080293655396,
              0.3191019892692566,
              0.0014038500376045704,
              -0.947691023349762,
              -0.833827018737793,
              0.3886530101299286,
              -0.39197999238967896,
              -0.8508560061454773,
              0.014221600256860256,
              -0.5251929759979248,
              -0.9994509816169739,
              0.0323496013879776,
              0,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              0.046540699899196625,
              -0.13229799270629883,
              -0.9901120066642761,
              -0.04144410043954849,
              -0.1778309941291809,
              -0.9831539988517761,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              0.0316477008163929,
              0.026062799617648125,
              -0.9991459846496582,
              0.051454201340675354,
              0.08441419899463654,
              -0.9950860142707825,
              0.28711798787117004,
              0.28748399019241333,
              -0.9137240052223206,
              0.022553199902176857,
              -0.00903347972780466,
              -0.9996950030326843,
              0.300119012594223,
              -0.01504560001194477,
              -0.953764021396637,
              0.01028470043092966,
              -0.0027771799359470606,
              -0.9999390244483948,
              0.40202000737190247,
              0.020813599228858948,
              -0.9153720140457153,
              0.582476019859314,
              0.22428099811077118,
              -0.7812430262565613,
              0.322519987821579,
              0.11990699917078018,
              -0.9389020204544067,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.03842280060052872,
              -0.039155200123786926,
              -0.9984740018844604,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.003753779921680689,
              -0.9260839819908142,
              0.3772389888763428,
              0.0010986699489876628,
              -0.7354660034179688,
              0.6775410175323486,
              0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              0.9867849946022034,
              0.023102499544620514,
              -0.16022199392318726,
              0.9722279906272888,
              0.20377199351787567,
              -0.11493299901485443,
              -0.7093719840049744,
              0.6329839825630188,
              -0.3099760115146637,
              -0.6574910283088684,
              0.1848199963569641,
              -0.730430006980896,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.7093719840049744,
              0.6329839825630188,
              -0.3099760115146637,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.10763899981975555,
              0.9347820281982422,
              0.33851099014282227,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              0.00463881017640233,
              -0.004425180144608021,
              -0.9999690055847168,
              -0.013275600038468838,
              -0.09430219978094101,
              -0.9954220056533813,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              -0.6392099857330322,
              -0.4939729869365692,
              0.589372992515564,
              -0.023590799421072006,
              -0.485275000333786,
              0.874019980430603,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              -0.7093719840049744,
              0.6329839825630188,
              -0.3099760115146637,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.8462479710578918,
              0,
              -0.5327619910240173,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              -0.034241799265146255,
              0.08682519942522049,
              0.9956049919128418,
              -0.023621300235390663,
              0.4852440059185028,
              0.874019980430603,
              -0.6391800045967102,
              0.493941992521286,
              0.5894039869308472,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              0.020539000630378723,
              0.2261119931936264,
              -0.9738759994506836,
              -0.013275600038468838,
              0.09430219978094101,
              -0.9954220056533813,
              0.04620499908924103,
              0.02639850042760372,
              0.9985659718513489,
              -0.0010986699489876628,
              -0.0035401498898863792,
              0.9999690055847168,
              0.009186070412397385,
              -0.04773090034723282,
              0.9988099932670593,
              -0.13193200528621674,
              -0.9323099851608276,
              -0.336650013923645,
              0.0318307988345623,
              -0.9994810223579407,
              0,
              -0.10760799795389175,
              -0.9347820281982422,
              0.33845001459121704,
              0.031800299882888794,
              0.9994810223579407,
              0,
              -0.10763899981975555,
              0.9347820281982422,
              0.33851099014282227,
              0.028717899695038795,
              0.7368999719619751,
              0.6753749847412109,
              -0.13196200132369995,
              0.9323400259017944,
              -0.33661898970603943,
              0.028687400743365288,
              0.7368999719619751,
              -0.6753749847412109,
              -0.07458720356225967,
              0.5011749863624573,
              -0.8620870113372803,
              -0.013275600038468838,
              0.09430219978094101,
              -0.9954220056533813,
              0.00463881017640233,
              0.004425180144608021,
              -0.9999690055847168,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              0.02877900004386902,
              -0.7368689775466919,
              0.6753749847412109,
              -0.10760799795389175,
              -0.9347820281982422,
              0.33845001459121704,
              0.0318307988345623,
              -0.9994810223579407,
              0,
              -0.011597000062465668,
              0,
              0.9999079704284668,
              -0.7069609761238098,
              0,
              0.7072359919548035,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              0.00463881017640233,
              -0.004425180144608021,
              -0.9999690055847168,
              0.02056949958205223,
              -0.2261420041322708,
              -0.9738460183143616,
              -0.013275600038468838,
              -0.09430219978094101,
              -0.9954220056533813,
              0.00463881017640233,
              0.004425180144608021,
              -0.9999690055847168,
              -0.013275600038468838,
              0.09430219978094101,
              -0.9954220056533813,
              0.020539000630378723,
              0.2261119931936264,
              -0.9738759994506836,
              0.020600000396370888,
              -0.2261119931936264,
              0.9738759994506836,
              0.00463881017640233,
              -0.004425180144608021,
              0.9999690055847168,
              -0.034241799265146255,
              -0.08679459989070892,
              0.9956049919128418,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.6574910283088684,
              -0.1848199963569641,
              -0.7303990125656128,
              -0.013275600038468838,
              -0.09430219978094101,
              -0.9954220056533813,
              -0.07458720356225967,
              -0.5012059807777405,
              -0.8620870113372803,
              0.00463881017640233,
              0.004425180144608021,
              -0.9999690055847168,
              0.00463881017640233,
              -0.004425180144608021,
              -0.9999690055847168,
              -0.14331500232219696,
              0,
              -0.989654004573822,
              0.008026369847357273,
              0.9821770191192627,
              0.18762800097465515,
              0.014069000259041786,
              0.7843869924545288,
              0.620074987411499,
              0.014496300369501114,
              0.978422999382019,
              0.2060299962759018,
              -0.14545099437236786,
              -0.8815879821777344,
              0.44895800948143005,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              -0.1260409951210022,
              -0.9905999898910522,
              -0.052583400160074234,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              0,
              -0.9755849838256836,
              -0.2195809930562973,
              -0.0006408889894373715,
              -0.9258099794387817,
              -0.3779410123825073,
              -0.13647900521755219,
              -0.9811699986457825,
              0.13647900521755219,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.9574570059776306,
              0.2034969925880432,
              0.20444299280643463,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              -0.9574570059776306,
              0.2034969925880432,
              0.20444299280643463,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.0031128900591284037,
              0.0966826006770134,
              -0.9952999949455261,
              -0.03503520041704178,
              0.02395699918270111,
              -0.9990839958190918,
              -0.01013210043311119,
              0.021027300506830215,
              -0.9997249841690063,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.9807429909706116,
              0,
              0.19516600668430328,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.9867849946022034,
              -0.023102499544620514,
              -0.16022199392318726,
              0.9518110156059265,
              -0.07504499703645706,
              0.29725000262260437,
              0.9722279906272888,
              -0.20377199351787567,
              -0.11496300250291824,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.9574570059776306,
              0.2034969925880432,
              0.20444299280643463,
              -0.9906920194625854,
              0.1191440001130104,
              -0.06567580252885818,
              -0.983581006526947,
              0.17056800425052643,
              -0.05844290181994438,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.017334500327706337,
              -0.029847100377082825,
              0.9993900060653687,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.8755459785461426,
              -0.0038148099556565285,
              0.4830769896507263,
              -0.5795469880104065,
              -0.010834099724888802,
              0.814844012260437,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.538254976272583,
              0,
              0.8427690267562866,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.538254976272583,
              0,
              0.8427690267562866,
              -0.5019069910049438,
              0,
              0.8648949861526489,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.3165990114212036,
              0.00027466699248179793,
              -0.9485459923744202,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0.3177590072154999,
              0,
              -0.9481490254402161,
              0.028839999809861183,
              0,
              -0.9995729923248291,
              0.027069900184869766,
              0.0005493329954333603,
              -0.9996029734611511,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.017456600442528725,
              -0.031678199768066406,
              0.9993289709091187,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.1700800061225891,
              0.09006009995937347,
              0.9812920093536377,
              -0.05160680040717125,
              0.01028470043092966,
              0.9985960125923157,
              -0.06775110214948654,
              0.015442400239408016,
              0.9975590109825134,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.007416000124067068,
              -0.009491260163486004,
              0.9999079704284668,
              0,
              0,
              1,
              -0.002258369931951165,
              0.15665200352668762,
              0.9876400232315063,
              0.061067499220371246,
              0.14102600514888763,
              0.9880980253219604,
              0.06164740025997162,
              0.08865629881620407,
              0.9941400289535522,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.035157300531864166,
              -0.05825980007648468,
              0.9976810216903687,
              -0.005523850210011005,
              -0.030060699209570885,
              0.9995120167732239,
              -0.011902200058102608,
              -0.0072634099051356316,
              0.9998779892921448,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.016754699870944023,
              0.0047914101742208,
              -0.9998469948768616,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0.5556809902191162,
              -0.8313850164413452,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0.2817769944667816,
              -0.9594709873199463,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.09830009937286377,
              0.028015999123454094,
              0.9947509765625,
              -0.09872739762067795,
              -0.0017090400215238333,
              0.9950860142707825,
              0,
              0,
              0.9999690055847168,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              -0.0071108099073171616,
              -0.004089479800313711,
              0.9999390244483948,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.06622520089149475,
              0.9978029727935791,
              0,
              0,
              1,
              0,
              0.027680300176143646,
              0.9996029734611511,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              1,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              -1,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.004120000172406435,
              -0.006591999903321266,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.0072023700922727585,
              0.002899260027334094,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              0,
              0,
              -1,
              -0.9840999841690063,
              0,
              -0.17749600112438202,
              -0.8543959856033325,
              -0.13968299329280853,
              -0.5004429817199707,
              -0.8548229932785034,
              0.14841200411319733,
              -0.49717700481414795,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              0.09826959669589996,
              -0.24271400272846222,
              -0.9650869965553284,
              0.14966300129890442,
              -0.3653070032596588,
              -0.9187600016593933,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.5856199860572815,
              0,
              0.8105720281600952,
              0.13269400596618652,
              0,
              0.9911500215530396,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.19803500175476074,
              0.4281750023365021,
              -0.8817099928855896,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              0.07626579701900482,
              0.42756399512290955,
              -0.9007539749145508,
              -0.0018005899619311094,
              0.017364999279379845,
              -0.9998469948768616,
              -0.001434369944036007,
              0.012939799576997757,
              -0.9999079704284668,
              -0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0.013031399808824062,
              -0.287088006734848,
              -0.9577929973602295,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              0,
              -0.01269569993019104,
              -0.9999079704284668,
              -0.09842219948768616,
              0.7893610000610352,
              -0.6059449911117554,
              -0.029664000496268272,
              0.33320099115371704,
              -0.9423810243606567,
              -0.4370560050010681,
              0.34635499119758606,
              -0.8300420045852661,
              0,
              0,
              -1,
              -0.012512600049376488,
              0.003631700063124299,
              -0.9999079704284668,
              -0.0631123036146164,
              0.07519759982824326,
              -0.9951480031013489,
              -0.25061801075935364,
              0.48451200127601624,
              -0.8380990028381348,
              -0.26969200372695923,
              0.20459599792957306,
              -0.9409469962120056,
              -0.5924559831619263,
              0.528764009475708,
              -0.6077460050582886,
              0.6471139788627625,
              0.7623519897460938,
              0,
              0.05035550147294998,
              0.9987180233001709,
              0,
              0.1586350053548813,
              0.8599510192871094,
              0.48503100872039795,
              -0.2711870074272156,
              -0.20422999560832977,
              -0.9405800104141235,
              -0.25061801075935364,
              -0.48451200127601624,
              -0.8380990028381348,
              -0.5930659770965576,
              -0.5276960134506226,
              -0.6080510020256042,
              -0.21405699849128723,
              -0.35679200291633606,
              -0.9092990159988403,
              -0.014038500376045704,
              -0.2452159970998764,
              -0.9693589806556702,
              -0.10583800077438354,
              -0.4430980086326599,
              -0.8901640176773071,
              0.8446909785270691,
              0.4360789954662323,
              0.3103429973125458,
              0.6894739866256714,
              0.7207859754562378,
              -0.0708945021033287,
              0.44538700580596924,
              0.8599510192871094,
              0.2490919977426529,
              0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              -0.05017239972949028,
              -0.9964600205421448,
              0.06707970052957535,
              -0.014862500131130219,
              -0.9929810166358948,
              0.11706899851560593,
              -0.9865720272064209,
              -0.15079200267791748,
              -0.06256289780139923,
              -0.9926450252532959,
              -0.112185999751091,
              -0.045106399804353714,
              -0.9592880010604858,
              -0.18890999257564545,
              0.20990599691867828,
              0.9777519702911377,
              -0.017883799970149994,
              0.20886899530887604,
              0.9685050249099731,
              -0.013397599570453167,
              -0.24860399961471558,
              0.9518110156059265,
              0.07504499703645706,
              0.29725000262260437,
              -0.0039979200810194016,
              -0.9216279983520508,
              0.38798201084136963,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              0,
              -0.8654739856719971,
              0.5008999705314636,
              0.04974519833922386,
              -0.995697021484375,
              -0.0780968964099884,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125,
              0.04177980124950409,
              -0.9905999898910522,
              0.13016100227832794,
              0.0030823699198663235,
              -0.99127197265625,
              0.13165700435638428,
              -0.14545099437236786,
              -0.8815879821777344,
              0.44895800948143005,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125,
              0.050416599959135056,
              -0.9909359812736511,
              -0.12445399910211563,
              0.0030823699198663235,
              -0.99127197265625,
              0.13165700435638428,
              0.03549300134181976,
              -0.9990230202674866,
              -0.025544000789523125
            ],
            "normalized": false
          },
          "color": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1
            ],
            "normalized": false
          },
          "uv": {
            "itemSize": 2,
            "type": "Float32Array",
            "array": [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.8667029738426208,
              0.1294659972190857,
              0.94794100522995,
              0.13363100588321686,
              0.8667029738426208,
              0.23388400673866272,
              0.94794100522995,
              0.13363100588321686,
              0.94794100522995,
              0.23683500289916992,
              0.8667029738426208,
              0.23388400673866272,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.7033460140228271,
              0,
              0,
              0.0073990002274513245,
              0.7033460140228271,
              0.0073990002274513245,
              0.802416980266571,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.94794100522995,
              0.02814199961721897,
              0.8667029738426208,
              0.02564699947834015,
              0,
              0,
              0.8667029738426208,
              0.02564699947834015,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.25553199648857117,
              0.9732810258865356,
              0,
              0,
              0.13329699635505676,
              0.9734100103378296,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.9734100103378296,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.4999989867210388,
              0.5,
              0.8373960256576538,
              0.6640229821205139,
              0.02872299961745739,
              0.46371400356292725,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.4999989867210388,
              0.5,
              0.42535701394081116,
              0.27089399099349976,
              0.8373960256576538,
              0.6640229821205139,
              0.4999989867210388,
              0.5,
              0.02872299961745739,
              0.46371400356292725,
              0.42535701394081116,
              0.27089399099349976,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.14804700016975403,
              0,
              0,
              0.9926009774208069,
              0.2471179962158203,
              0.9926009774208069,
              0.14804700016975403,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.24816200137138367,
              0.0073979999870061874,
              0.34723299741744995,
              0,
              0,
              0.0073979999870061874,
              0.34723299741744995,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.8013740181922913,
              0.9926019906997681,
              0.7023029923439026,
              0,
              0,
              0.9926019906997681,
              0.7023029923439026,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.5152469873428345,
              0,
              0,
              0.0073990002274513245,
              0.44630300998687744,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.44630300998687744,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.5,
              0.922353982925415,
              0.6222350001335144,
              0.9222249984741211,
              0.5,
              0.9730219841003418,
              0.6222350001335144,
              0.9222249984741211,
              0.6222350001335144,
              0.9728919863700867,
              0.5,
              0.9730219841003418,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.13024300336837769,
              0.13329699635505676,
              0.026424000039696693,
              0.2555310130119324,
              0.13011400401592255,
              0.13329699635505676,
              0.026424000039696693,
              0.2555310130119324,
              0.026294000446796417,
              0.2555310130119324,
              0.13011400401592255,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.3461889922618866,
              0,
              0,
              0.9926019906997681,
              0.4452599883079529,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.4452599883079529,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.05205800011754036,
              0.2377839982509613,
              0.05205800011754036,
              0.13458000123500824,
              0.13329699635505676,
              0.23466099798679352,
              0.05205800011754036,
              0.13458000123500824,
              0.13329699635505676,
              0.13024300336837769,
              0.13329699635505676,
              0.23466099798679352,
              0.9926019906997681,
              0.5142030119895935,
              0,
              0,
              0.9926019906997681,
              0.6032320261001587,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.6032320261001587,
              0.6222339868545532,
              0.44297900795936584,
              0.6222339868545532,
              0.5152639746665955,
              0.5,
              0.4431079924106598,
              0.6222339868545532,
              0.5152639746665955,
              0.5,
              0.5153939723968506,
              0.5,
              0.4431079924106598,
              0.5,
              0.7135180234909058,
              0.6222350001335144,
              0.713388979434967,
              0.5,
              0.8179360032081604,
              0.6222350001335144,
              0.713388979434967,
              0.6222350001335144,
              0.8178070187568665,
              0.5,
              0.8179360032081604,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.8176770210266113,
              0.7444689869880676,
              0.922095000743866,
              0.6222350001335144,
              0.8178070187568665,
              0.7444689869880676,
              0.922095000743866,
              0.6222350001335144,
              0.9222249984741211,
              0.6222350001335144,
              0.8178070187568665,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.9727630019187927,
              0,
              0,
              0.6222350001335144,
              0.9728919863700867,
              0.7444689869880676,
              0.9727630019187927,
              0.5,
              0.5153939723968506,
              0.6222339868545532,
              0.5152639746665955,
              0.5,
              0.6091009974479675,
              0.6222339868545532,
              0.5152639746665955,
              0.6222350001335144,
              0.6089709997177124,
              0.5,
              0.6091009974479675,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.26955699920654297,
              0.5529429912567139,
              0.37818101048469543,
              0.7170349955558777,
              0.26955699920654297,
              0.5529429912567139,
              0.3551110029220581,
              0.5392029881477356,
              0.37818101048469543,
              0.7170349955558777,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.5864440202713013,
              0.5849050283432007,
              0.5,
              0.5057690143585205,
              0.5663629770278931,
              0.3906779885292053,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.7033460140228271,
              0,
              0,
              0.0073990002274513245,
              0.6042749881744385,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.6042749881744385,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.8176770210266113,
              0.6222350001335144,
              0.8178070187568665,
              0.7444689869880676,
              0.7132599949836731,
              0.6222350001335144,
              0.8178070187568665,
              0.6222350001335144,
              0.713388979434967,
              0.7444689869880676,
              0.7132599949836731,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.14909100532531738,
              0.0073979999870061874,
              0.03791999816894531,
              0.05205800011754036,
              0.13458000123500824,
              0.0073979999870061874,
              0.03791999816894531,
              0.05205800011754036,
              0.0290910005569458,
              0.05205800011754036,
              0.13458000123500824,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.6042749881744385,
              0,
              0,
              0.0073990002274513245,
              0.5152469873428345,
              0.0073990002274513245,
              0.6042749881744385,
              0.13329699635505676,
              0.5157819986343384,
              0.05205800011754036,
              0.515716016292572,
              0.13329699635505676,
              0.4434970021247864,
              0.05205800011754036,
              0.515716016292572,
              0.05205800011754036,
              0.44418999552726746,
              0.13329699635505676,
              0.4434970021247864,
              0.05205800011754036,
              0.515716016292572,
              0.0073990002274513245,
              0.5152469873428345,
              0.05205800011754036,
              0.44418999552726746,
              0.0073990002274513245,
              0.5152469873428345,
              0.0073990002274513245,
              0.44630300998687744,
              0.05205800011754036,
              0.44418999552726746,
              0,
              0,
              0.0073979999870061874,
              0.34723299741744995,
              0,
              0,
              0.0073979999870061874,
              0.34723299741744995,
              0.0073990002274513245,
              0.44630300998687744,
              0,
              0,
              0.0073990002274513245,
              0.5152469873428345,
              0.05205800011754036,
              0.515716016292572,
              0.0073990002274513245,
              0.6042749881744385,
              0.05205800011754036,
              0.515716016292572,
              0.05205800011754036,
              0.6083599925041199,
              0.0073990002274513245,
              0.6042749881744385,
              0.0073990002274513245,
              0.9014880061149597,
              0.05205899849534035,
              0.9179700016975403,
              0.0073990002274513245,
              0.9617609977722168,
              0.05205899849534035,
              0.9179700016975403,
              0.05205899849534035,
              0.9708200097084045,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.37818101048469543,
              0.7170349955558777,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9014880061149597,
              0,
              0,
              0.0073990002274513245,
              0.802416980266571,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.802416980266571,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.05205899849534035,
              0.9708200097084045,
              0,
              0,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.922743022441864,
              0.25553199648857117,
              0.9226130247116089,
              0.13329699635505676,
              0.9734100103378296,
              0.25553199648857117,
              0.9226130247116089,
              0.25553199648857117,
              0.9732810258865356,
              0.13329699635505676,
              0.9734100103378296,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.5,
              0.8179360032081604,
              0.3777660131454468,
              0.8180660009384155,
              0.5,
              0.7135180234909058,
              0.3777660131454468,
              0.8180660009384155,
              0.3777660131454468,
              0.7136480212211609,
              0.5,
              0.7135180234909058,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.05205800011754036,
              0.6083599925041199,
              0.05205899849534035,
              0.7115640044212341,
              0.0073990002274513245,
              0.6042749881744385,
              0.05205899849534035,
              0.7115640044212341,
              0.0073990002274513245,
              0.7033460140228271,
              0.0073990002274513245,
              0.6042749881744385,
              0,
              0,
              0,
              0,
              0,
              0,
              0.5,
              0.922353982925415,
              0.3777660131454468,
              0.9224839806556702,
              0.5,
              0.8179360032081604,
              0.3777660131454468,
              0.9224839806556702,
              0.3777660131454468,
              0.8180660009384155,
              0.5,
              0.8179360032081604,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.14804700016975403,
              0,
              0,
              0.9926009774208069,
              0.14804700016975403,
              0.9926009774208069,
              0.036876000463962555,
              0,
              0,
              0.2555310130119324,
              0.44336700439453125,
              0.2555310130119324,
              0.3389489948749542,
              0.3777660131454468,
              0.4432379901409149,
              0.2555310130119324,
              0.3389489948749542,
              0.3777660131454468,
              0.3388200104236603,
              0.3777660131454468,
              0.4432379901409149,
              0.7444689869880676,
              0.4428490102291107,
              0.7444689869880676,
              0.3384310007095337,
              0.8667029738426208,
              0.4427199959754944,
              0.7444689869880676,
              0.3384310007095337,
              0.8667029738426208,
              0.33830198645591736,
              0.8667029738426208,
              0.4427199959754944,
              0.6222339868545532,
              0.23414300382137299,
              0.6222339868545532,
              0.3385609984397888,
              0.5,
              0.2342730015516281,
              0.6222339868545532,
              0.3385609984397888,
              0.5,
              0.33869001269340515,
              0.5,
              0.2342730015516281,
              0.6833509802818298,
              0.07745199650526047,
              0.7444679737091064,
              0.12959599494934082,
              0.6222339868545532,
              0.12972499430179596,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.13011400401592255,
              0.3777649998664856,
              0.12998400628566742,
              0.2555310130119324,
              0.2345310002565384,
              0.3777649998664856,
              0.12998400628566742,
              0.3777649998664856,
              0.23440200090408325,
              0.2555310130119324,
              0.2345310002565384,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.2471179962158203,
              0,
              0,
              0.9926009774208069,
              0.3461889922618866,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.3461889922618866,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444679737091064,
              0.025776000693440437,
              0,
              0,
              0.8667029738426208,
              0.02564699947834015,
              0.7444679737091064,
              0.025776000693440437,
              0.7444679737091064,
              0.025776000693440437,
              0.6833509802818298,
              0.025840999558568,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.9607179760932922,
              0,
              0,
              0.9479420185089111,
              0.9698709845542908,
              0,
              0,
              0,
              0,
              0.9479420185089111,
              0.9698709845542908,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777660131454468,
              0.3388200104236603,
              0.3777649998664856,
              0.23440200090408325,
              0.5,
              0.33869001269340515,
              0.3777649998664856,
              0.23440200090408325,
              0.5,
              0.2342730015516281,
              0.5,
              0.33869001269340515,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.14909100532531738,
              0.05205800011754036,
              0.13458000123500824,
              0.0073979999870061874,
              0.24816200137138367,
              0.05205800011754036,
              0.13458000123500824,
              0.05205800011754036,
              0.2377839982509613,
              0.0073979999870061874,
              0.24816200137138367,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.24816200137138367,
              0,
              0,
              0.0073979999870061874,
              0.14909100532531738,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.14909100532531738,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.03791999816894531,
              0,
              0,
              0.05205800011754036,
              0.0290910005569458,
              0,
              0,
              0,
              0,
              0.05205800011754036,
              0.0290910005569458,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.316648006439209,
              0.02622999995946884,
              0.2555310130119324,
              0.026294000446796417,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6833509802818298,
              0.07745199650526047,
              0.6833509802818298,
              0.025840999558568,
              0.7444679737091064,
              0.025776000693440437,
              0.6222339868545532,
              0.23414300382137299,
              0.6222339868545532,
              0.12972499430179596,
              0.7444689869880676,
              0.23401400446891785,
              0.6222339868545532,
              0.12972499430179596,
              0.7444679737091064,
              0.12959599494934082,
              0.7444689869880676,
              0.23401400446891785,
              0.6833509802818298,
              0.025840999558568,
              0.6833509802818298,
              0.07745199650526047,
              0.6222339868545532,
              0.025906000286340714,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.8667029738426208,
              0.1294659972190857,
              0.8667029738426208,
              0.02564699947834015,
              0.94794100522995,
              0.13363100588321686,
              0.8667029738426208,
              0.02564699947834015,
              0.94794100522995,
              0.02814199961721897,
              0.94794100522995,
              0.13363100588321686,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.14909100532531738,
              0,
              0,
              0.0073979999870061874,
              0.03791999816894531,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.03791999816894531,
              0,
              0,
              0.5,
              0.026034999638795853,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.3389489948749542,
              0.2555310130119324,
              0.2345310002565384,
              0.3777660131454468,
              0.3388200104236603,
              0.2555310130119324,
              0.2345310002565384,
              0.3777649998664856,
              0.23440200090408325,
              0.3777660131454468,
              0.3388200104236603,
              0.05205800011754036,
              0.3409869968891144,
              0.05205800011754036,
              0.2377839982509613,
              0.13329699635505676,
              0.33907899260520935,
              0.05205800011754036,
              0.2377839982509613,
              0.13329699635505676,
              0.23466099798679352,
              0.13329699635505676,
              0.33907899260520935,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444679737091064,
              0.12959599494934082,
              0.7444679737091064,
              0.025776000693440437,
              0.8667029738426208,
              0.1294659972190857,
              0.7444679737091064,
              0.025776000693440437,
              0.8667029738426208,
              0.02564699947834015,
              0.8667029738426208,
              0.1294659972190857,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.05205800011754036,
              0.0290910005569458,
              0,
              0,
              0.13329699635505676,
              0.026424000039696693,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.026424000039696693,
              0.8667029738426208,
              0.4427199959754944,
              0.8667029738426208,
              0.5150049924850464,
              0.7444689869880676,
              0.4428490102291107,
              0.8667029738426208,
              0.5150049924850464,
              0.7444689869880676,
              0.5151349902153015,
              0.7444689869880676,
              0.4428490102291107,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777660131454468,
              0.5155230164527893,
              0.3777660131454468,
              0.6092299818992615,
              0.2555310130119324,
              0.5156530141830444,
              0.3777660131454468,
              0.6092299818992615,
              0.2555310130119324,
              0.6093599796295166,
              0.2555310130119324,
              0.5156530141830444,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777649998664856,
              0.02616499923169613,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.866703987121582,
              0.9726330041885376,
              0,
              0,
              0.7444689869880676,
              0.9727630019187927,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.9727630019187927,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.900443971157074,
              0,
              0,
              0.9926019906997681,
              0.9607179760932922,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.9607179760932922,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9479420185089111,
              0.9698709845542908,
              0,
              0,
              0.866703987121582,
              0.9726330041885376,
              0.9479420185089111,
              0.9698709845542908,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.7132599949836731,
              0.8667029738426208,
              0.713129997253418,
              0.7444689869880676,
              0.8176770210266113,
              0.8667029738426208,
              0.713129997253418,
              0.8667029738426208,
              0.8175479769706726,
              0.7444689869880676,
              0.8176770210266113,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.5,
              0.9730219841003418,
              0,
              0,
              0.3777660131454468,
              0.9731510281562805,
              0.5,
              0.9730219841003418,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777649998664856,
              0.23440200090408325,
              0.3777649998664856,
              0.12998400628566742,
              0.5,
              0.2342730015516281,
              0.3777649998664856,
              0.12998400628566742,
              0.5,
              0.12985500693321228,
              0.5,
              0.2342730015516281,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7083550095558167,
              0.5587859749794006,
              0.685500979423523,
              0.3693690001964569,
              0,
              0,
              0.685500979423523,
              0.3693690001964569,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.6088420152664185,
              0.7444689869880676,
              0.7132599949836731,
              0.6222350001335144,
              0.6089709997177124,
              0.7444689869880676,
              0.7132599949836731,
              0.6222350001335144,
              0.713388979434967,
              0.6222350001335144,
              0.6089709997177124,
              0.9926009774208069,
              0.036876000463962555,
              0.94794100522995,
              0.02814199961721897,
              0,
              0,
              0.94794100522995,
              0.02814199961721897,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926009774208069,
              0.036876000463962555,
              0,
              0,
              0.9926009774208069,
              0.036876000463962555,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.026424000039696693,
              0,
              0,
              0.2555310130119324,
              0.026294000446796417,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.026294000446796417,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.026294000446796417,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.13329699635505676,
              0.4434970021247864,
              0.13329699635505676,
              0.33907899260520935,
              0.2555310130119324,
              0.44336700439453125,
              0.13329699635505676,
              0.33907899260520935,
              0.2555310130119324,
              0.3389489948749542,
              0.2555310130119324,
              0.44336700439453125,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.94794100522995,
              0.13363100588321686,
              0.94794100522995,
              0.02814199961721897,
              0.9926009774208069,
              0.14804700016975403,
              0.94794100522995,
              0.02814199961721897,
              0.9926009774208069,
              0.036876000463962555,
              0.9926009774208069,
              0.14804700016975403,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073979999870061874,
              0.03791999816894531,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.621819019317627,
              0.29450300335884094,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7083550095558167,
              0.5587859749794006,
              0,
              0,
              0.6644260287284851,
              0.6562939882278442,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.39559799432754517,
              0.27246999740600586,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.43363699316978455,
              0.6208599805831909,
              0.3551110029220581,
              0.5392029881477356,
              0.5,
              0.5057690143585205,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.29164600372314453,
              0.4527519941329956,
              0,
              0,
              0.29164600372314453,
              0.4527519941329956,
              0.26955699920654297,
              0.5529429912567139,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.621819019317627,
              0.29450300335884094,
              0.4804629981517792,
              0.3218100070953369,
              0,
              0,
              0.4804629981517792,
              0.3218100070953369,
              0.46439599990844727,
              0.21503999829292297,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3355740010738373,
              0.3552440106868744,
              0,
              0,
              0.39559799432754517,
              0.27246999740600586,
              0,
              0,
              0,
              0,
              0,
              0,
              0.37818101048469543,
              0.7170349955558777,
              0.4543690085411072,
              0.7702130079269409,
              0,
              0,
              0.6044020056724548,
              0.7390679717063904,
              0,
              0,
              0.4543690085411072,
              0.7702130079269409,
              0,
              0,
              0,
              0,
              0.4543690085411072,
              0.7702130079269409,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.7023029923439026,
              0,
              0,
              0.9926019906997681,
              0.7023029923439026,
              0.9926019906997681,
              0.6032320261001587,
              0,
              0,
              0.4543690085411072,
              0.7702130079269409,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6644260287284851,
              0.6562939882278442,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.621819019317627,
              0.29450300335884094,
              0,
              0,
              0.685500979423523,
              0.3693690001964569,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.46439599990844727,
              0.21503999829292297,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.26955699920654297,
              0.5529429912567139,
              0.4543690085411072,
              0.7702130079269409,
              0.37818101048469543,
              0.7170349955558777,
              0.43363699316978455,
              0.6208599805831909,
              0.4804629981517792,
              0.3218100070953369,
              0.39559799432754517,
              0.27246999740600586,
              0.46439599990844727,
              0.21503999829292297,
              0.685500979423523,
              0.3693690001964569,
              0.7083550095558167,
              0.5587859749794006,
              0.5663629770278931,
              0.3906779885292053,
              0.7083550095558167,
              0.5587859749794006,
              0.5864440202713013,
              0.5849050283432007,
              0.5663629770278931,
              0.3906779885292053,
              0,
              0,
              0,
              0,
              0,
              0,
              0.685500979423523,
              0.3693690001964569,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.29164600372314453,
              0.4527519941329956,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6044020056724548,
              0.7390679717063904,
              0.6644260287284851,
              0.6562939882278442,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3551110029220581,
              0.5392029881477356,
              0.43363699316978455,
              0.6208599805831909,
              0.37818101048469543,
              0.7170349955558777,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6527929902076721,
              0.00036800000816583633,
              0.6222339868545532,
              0.025906000286340714,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.922095000743866,
              0.866703987121582,
              0.921966016292572,
              0.7444689869880676,
              0.9727630019187927,
              0.866703987121582,
              0.921966016292572,
              0.866703987121582,
              0.9726330041885376,
              0.7444689869880676,
              0.9727630019187927,
              0.9926019906997681,
              0.7023029923439026,
              0.9926019906997681,
              0.8013740181922913,
              0.9479420185089111,
              0.7106149792671204,
              0.9926019906997681,
              0.8013740181922913,
              0.9479420185089111,
              0.8138179779052734,
              0.9479420185089111,
              0.7106149792671204,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.4804629981517792,
              0.3218100070953369,
              0.5663629770278931,
              0.3906779885292053,
              0.5,
              0.5057690143585205,
              0.8667029738426208,
              0.713129997253418,
              0.9479420185089111,
              0.7106149792671204,
              0.8667029738426208,
              0.8175479769706726,
              0.9479420185089111,
              0.7106149792671204,
              0.9479420185089111,
              0.8138179779052734,
              0.8667029738426208,
              0.8175479769706726,
              0.9479420185089111,
              0.8138179779052734,
              0.9926019906997681,
              0.8013740181922913,
              0.9479420185089111,
              0.9170209765434265,
              0.9926019906997681,
              0.8013740181922913,
              0.9926019906997681,
              0.900443971157074,
              0.9479420185089111,
              0.9170209765434265,
              0,
              0,
              0,
              0,
              0,
              0,
              0.866703987121582,
              0.9726330041885376,
              0.866703987121582,
              0.921966016292572,
              0.9479420185089111,
              0.9698709845542908,
              0.866703987121582,
              0.921966016292572,
              0.9479420185089111,
              0.9170209765434265,
              0.9479420185089111,
              0.9698709845542908,
              0.8667029738426208,
              0.6087120175361633,
              0.9479420185089111,
              0.6074119806289673,
              0.8667029738426208,
              0.713129997253418,
              0.9479420185089111,
              0.6074119806289673,
              0.9479420185089111,
              0.7106149792671204,
              0.8667029738426208,
              0.713129997253418,
              0.7444689869880676,
              0.8176770210266113,
              0.8667029738426208,
              0.8175479769706726,
              0.7444689869880676,
              0.922095000743866,
              0.8667029738426208,
              0.8175479769706726,
              0.866703987121582,
              0.921966016292572,
              0.7444689869880676,
              0.922095000743866,
              0.7444689869880676,
              0.9727630019187927,
              0.6222350001335144,
              0.9728919863700867,
              0.7444689869880676,
              0.922095000743866,
              0.6222350001335144,
              0.9728919863700867,
              0.6222350001335144,
              0.9222249984741211,
              0.7444689869880676,
              0.922095000743866,
              0.9479420185089111,
              0.9170209765434265,
              0.866703987121582,
              0.921966016292572,
              0.9479420185089111,
              0.8138179779052734,
              0.866703987121582,
              0.921966016292572,
              0.8667029738426208,
              0.8175479769706726,
              0.9479420185089111,
              0.8138179779052734,
              0.8667029738426208,
              0.6087120175361633,
              0.7444689869880676,
              0.6088420152664185,
              0.8667029738426208,
              0.5150049924850464,
              0.7444689869880676,
              0.6088420152664185,
              0.7444689869880676,
              0.5151349902153015,
              0.8667029738426208,
              0.5150049924850464,
              0.5,
              0.8179360032081604,
              0.6222350001335144,
              0.8178070187568665,
              0.5,
              0.922353982925415,
              0.6222350001335144,
              0.8178070187568665,
              0.6222350001335144,
              0.9222249984741211,
              0.5,
              0.922353982925415,
              0.5,
              0.7135180234909058,
              0.5,
              0.6091009974479675,
              0.6222350001335144,
              0.713388979434967,
              0.5,
              0.6091009974479675,
              0.6222350001335144,
              0.6089709997177124,
              0.6222350001335144,
              0.713388979434967,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.9607179760932922,
              0,
              0,
              0.2555310130119324,
              0.6093599796295166,
              0.2555310130119324,
              0.7137770056724548,
              0.13329699635505676,
              0.6094890236854553,
              0.2555310130119324,
              0.7137770056724548,
              0.13329699635505676,
              0.71390700340271,
              0.13329699635505676,
              0.6094890236854553,
              0.9479420185089111,
              0.6074119806289673,
              0.9926019906997681,
              0.6032320261001587,
              0.9479420185089111,
              0.7106149792671204,
              0.9926019906997681,
              0.6032320261001587,
              0.9926019906997681,
              0.7023029923439026,
              0.9479420185089111,
              0.7106149792671204,
              0,
              0,
              0,
              0,
              0.3777649998664856,
              0.02616499923169613,
              0.7444689869880676,
              0.4428490102291107,
              0.7444689869880676,
              0.5151349902153015,
              0.6222339868545532,
              0.44297900795936584,
              0.7444689869880676,
              0.5151349902153015,
              0.6222339868545532,
              0.5152639746665955,
              0.6222339868545532,
              0.44297900795936584,
              0.7444689869880676,
              0.5151349902153015,
              0.7444689869880676,
              0.6088420152664185,
              0.6222339868545532,
              0.5152639746665955,
              0.7444689869880676,
              0.6088420152664185,
              0.6222350001335144,
              0.6089709997177124,
              0.6222339868545532,
              0.5152639746665955,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.8667029738426208,
              0.4427199959754944,
              0.9479420185089111,
              0.4432410001754761,
              0.8667029738426208,
              0.5150049924850464,
              0.9479420185089111,
              0.4432410001754761,
              0.9479420185089111,
              0.5147669911384583,
              0.8667029738426208,
              0.5150049924850464,
              0.05205899849534035,
              0.9708200097084045,
              0.13329699635505676,
              0.9734100103378296,
              0,
              0,
              0.13329699635505676,
              0.9734100103378296,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6222339868545532,
              0.025906000286340714,
              0,
              0,
              0.316648006439209,
              0.02622999995946884,
              0.3472070097923279,
              0.0006910000229254365,
              0.3777649998664856,
              0.02616499923169613,
              0.8667029738426208,
              0.6087120175361633,
              0.8667029738426208,
              0.713129997253418,
              0.7444689869880676,
              0.6088420152664185,
              0.8667029738426208,
              0.713129997253418,
              0.7444689869880676,
              0.7132599949836731,
              0.7444689869880676,
              0.6088420152664185,
              0.9926019906997681,
              0.8013740181922913,
              0,
              0,
              0.9926019906997681,
              0.900443971157074,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.900443971157074,
              0.3777660131454468,
              0.9731510281562805,
              0,
              0,
              0.25553199648857117,
              0.9732810258865356,
              0,
              0,
              0,
              0,
              0.25553199648857117,
              0.9732810258865356,
              0.13329699635505676,
              0.71390700340271,
              0.05205899849534035,
              0.7115640044212341,
              0.13329699635505676,
              0.6094890236854553,
              0.05205899849534035,
              0.7115640044212341,
              0.05205800011754036,
              0.6083599925041199,
              0.13329699635505676,
              0.6094890236854553,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777660131454468,
              0.9731510281562805,
              0.25553199648857117,
              0.9732810258865356,
              0.3777660131454468,
              0.9224839806556702,
              0.25553199648857117,
              0.9732810258865356,
              0.25553199648857117,
              0.9226130247116089,
              0.3777660131454468,
              0.9224839806556702,
              0.6222339868545532,
              0.025906000286340714,
              0.6833509802818298,
              0.07745199650526047,
              0.6222339868545532,
              0.12972499430179596,
              0.3777660131454468,
              0.8180660009384155,
              0.3777660131454468,
              0.9224839806556702,
              0.25553199648857117,
              0.8181949853897095,
              0.3777660131454468,
              0.9224839806556702,
              0.25553199648857117,
              0.9226130247116089,
              0.25553199648857117,
              0.8181949853897095,
              0.3777660131454468,
              0.9731510281562805,
              0.3777660131454468,
              0.9224839806556702,
              0.5,
              0.9730219841003418,
              0.3777660131454468,
              0.9224839806556702,
              0.5,
              0.922353982925415,
              0.5,
              0.9730219841003418,
              0.25553199648857117,
              0.8181949853897095,
              0.25553199648857117,
              0.9226130247116089,
              0.13329699635505676,
              0.8183249831199646,
              0.25553199648857117,
              0.9226130247116089,
              0.13329699635505676,
              0.922743022441864,
              0.13329699635505676,
              0.8183249831199646,
              0.05205899849534035,
              0.7115640044212341,
              0.05205899849534035,
              0.8147670030593872,
              0.0073990002274513245,
              0.7033460140228271,
              0.05205899849534035,
              0.8147670030593872,
              0.0073990002274513245,
              0.802416980266571,
              0.0073990002274513245,
              0.7033460140228271,
              0.13329699635505676,
              0.9734100103378296,
              0.05205899849534035,
              0.9708200097084045,
              0.13329699635505676,
              0.922743022441864,
              0.05205899849534035,
              0.9708200097084045,
              0.05205899849534035,
              0.9179700016975403,
              0.13329699635505676,
              0.922743022441864,
              0.13329699635505676,
              0.8183249831199646,
              0.13329699635505676,
              0.922743022441864,
              0.05205899849534035,
              0.8147670030593872,
              0.13329699635505676,
              0.922743022441864,
              0.05205899849534035,
              0.9179700016975403,
              0.05205899849534035,
              0.8147670030593872,
              0.13329699635505676,
              0.71390700340271,
              0.13329699635505676,
              0.8183249831199646,
              0.05205899849534035,
              0.7115640044212341,
              0.13329699635505676,
              0.8183249831199646,
              0.05205899849534035,
              0.8147670030593872,
              0.05205899849534035,
              0.7115640044212341,
              0.2555310130119324,
              0.7137770056724548,
              0.3777660131454468,
              0.7136480212211609,
              0.25553199648857117,
              0.8181949853897095,
              0.3777660131454468,
              0.7136480212211609,
              0.3777660131454468,
              0.8180660009384155,
              0.25553199648857117,
              0.8181949853897095,
              0.05205899849534035,
              0.9179700016975403,
              0.0073990002274513245,
              0.9014880061149597,
              0.05205899849534035,
              0.8147670030593872,
              0.0073990002274513245,
              0.9014880061149597,
              0.0073990002274513245,
              0.802416980266571,
              0.05205899849534035,
              0.8147670030593872,
              0,
              0,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.7137770056724548,
              0.25553199648857117,
              0.8181949853897095,
              0.13329699635505676,
              0.71390700340271,
              0.25553199648857117,
              0.8181949853897095,
              0.13329699635505676,
              0.8183249831199646,
              0.13329699635505676,
              0.71390700340271,
              0.5,
              0.026034999638795853,
              0,
              0,
              0.6222339868545532,
              0.025906000286340714,
              0.2555310130119324,
              0.6093599796295166,
              0.3777660131454468,
              0.6092299818992615,
              0.2555310130119324,
              0.7137770056724548,
              0.3777660131454468,
              0.6092299818992615,
              0.3777660131454468,
              0.7136480212211609,
              0.2555310130119324,
              0.7137770056724548,
              0,
              0,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9617609977722168,
              0,
              0,
              0.0073990002274513245,
              0.9014880061149597,
              0,
              0,
              0,
              0,
              0.0073990002274513245,
              0.9014880061149597,
              0.13329699635505676,
              0.5157819986343384,
              0.13329699635505676,
              0.6094890236854553,
              0.05205800011754036,
              0.515716016292572,
              0.13329699635505676,
              0.6094890236854553,
              0.05205800011754036,
              0.6083599925041199,
              0.05205800011754036,
              0.515716016292572,
              0.3777660131454468,
              0.7136480212211609,
              0.3777660131454468,
              0.6092299818992615,
              0.5,
              0.7135180234909058,
              0.3777660131454468,
              0.6092299818992615,
              0.5,
              0.6091009974479675,
              0.5,
              0.7135180234909058,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6833509802818298,
              0.07745199650526047,
              0.7444679737091064,
              0.025776000693440437,
              0.7444679737091064,
              0.12959599494934082,
              0.3777660131454468,
              0.6092299818992615,
              0.3777660131454468,
              0.5155230164527893,
              0.5,
              0.6091009974479675,
              0.3777660131454468,
              0.5155230164527893,
              0.5,
              0.5153939723968506,
              0.5,
              0.6091009974479675,
              0.2555310130119324,
              0.5156530141830444,
              0.13329699635505676,
              0.5157819986343384,
              0.2555310130119324,
              0.44336700439453125,
              0.13329699635505676,
              0.5157819986343384,
              0.13329699635505676,
              0.4434970021247864,
              0.2555310130119324,
              0.44336700439453125,
              0.2555310130119324,
              0.5156530141830444,
              0.2555310130119324,
              0.6093599796295166,
              0.13329699635505676,
              0.5157819986343384,
              0.2555310130119324,
              0.6093599796295166,
              0.13329699635505676,
              0.6094890236854553,
              0.13329699635505676,
              0.5157819986343384,
              0.3777649998664856,
              0.02616499923169613,
              0,
              0,
              0.5,
              0.026034999638795853,
              0.5,
              0.12985500693321228,
              0.5,
              0.026034999638795853,
              0.6222339868545532,
              0.12972499430179596,
              0.5,
              0.026034999638795853,
              0.6222339868545532,
              0.025906000286340714,
              0.6222339868545532,
              0.12972499430179596,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6833509802818298,
              0.025840999558568,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444679737091064,
              0.025776000693440437,
              0,
              0,
              0,
              0,
              0.6222339868545532,
              0.3385609984397888,
              0.6222339868545532,
              0.23414300382137299,
              0.7444689869880676,
              0.3384310007095337,
              0.6222339868545532,
              0.23414300382137299,
              0.7444689869880676,
              0.23401400446891785,
              0.7444689869880676,
              0.3384310007095337,
              0.5,
              0.2342730015516281,
              0.5,
              0.12985500693321228,
              0.6222339868545532,
              0.23414300382137299,
              0.5,
              0.12985500693321228,
              0.6222339868545532,
              0.12972499430179596,
              0.6222339868545532,
              0.23414300382137299,
              0.94794100522995,
              0.23683500289916992,
              0.9926009774208069,
              0.2471179962158203,
              0.9479420185089111,
              0.340038001537323,
              0.9926009774208069,
              0.2471179962158203,
              0.9926009774208069,
              0.3461889922618866,
              0.9479420185089111,
              0.340038001537323,
              0.5,
              0.4431079924106598,
              0.5,
              0.33869001269340515,
              0.6222339868545532,
              0.44297900795936584,
              0.5,
              0.33869001269340515,
              0.6222339868545532,
              0.3385609984397888,
              0.6222339868545532,
              0.44297900795936584,
              0.9479420185089111,
              0.340038001537323,
              0.9926009774208069,
              0.3461889922618866,
              0.9479420185089111,
              0.4432410001754761,
              0.9926009774208069,
              0.3461889922618866,
              0.9926019906997681,
              0.4452599883079529,
              0.9479420185089111,
              0.4432410001754761,
              0.8667029738426208,
              0.23388400673866272,
              0.94794100522995,
              0.23683500289916992,
              0.8667029738426208,
              0.33830198645591736,
              0.94794100522995,
              0.23683500289916992,
              0.9479420185089111,
              0.340038001537323,
              0.8667029738426208,
              0.33830198645591736,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.6222339868545532,
              0.3385609984397888,
              0.7444689869880676,
              0.3384310007095337,
              0.6222339868545532,
              0.44297900795936584,
              0.7444689869880676,
              0.3384310007095337,
              0.7444689869880676,
              0.4428490102291107,
              0.6222339868545532,
              0.44297900795936584,
              0.8667029738426208,
              0.4427199959754944,
              0.8667029738426208,
              0.33830198645591736,
              0.9479420185089111,
              0.4432410001754761,
              0.8667029738426208,
              0.33830198645591736,
              0.9479420185089111,
              0.340038001537323,
              0.9479420185089111,
              0.4432410001754761,
              0.316648006439209,
              0.07784000039100647,
              0.2555310130119324,
              0.13011400401592255,
              0.2555310130119324,
              0.026294000446796417,
              0.13329699635505676,
              0.23466099798679352,
              0.13329699635505676,
              0.13024300336837769,
              0.2555310130119324,
              0.2345310002565384,
              0.13329699635505676,
              0.13024300336837769,
              0.2555310130119324,
              0.13011400401592255,
              0.2555310130119324,
              0.2345310002565384,
              0.3777649998664856,
              0.12998400628566742,
              0.3777649998664856,
              0.02616499923169613,
              0.5,
              0.12985500693321228,
              0.3777649998664856,
              0.02616499923169613,
              0.5,
              0.026034999638795853,
              0.5,
              0.12985500693321228,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3777660131454468,
              0.4432379901409149,
              0.3777660131454468,
              0.3388200104236603,
              0.5,
              0.4431079924106598,
              0.3777660131454468,
              0.3388200104236603,
              0.5,
              0.33869001269340515,
              0.5,
              0.4431079924106598,
              0.05205800011754036,
              0.44418999552726746,
              0.05205800011754036,
              0.3409869968891144,
              0.13329699635505676,
              0.4434970021247864,
              0.05205800011754036,
              0.3409869968891144,
              0.13329699635505676,
              0.33907899260520935,
              0.13329699635505676,
              0.4434970021247864,
              0.316648006439209,
              0.02622999995946884,
              0.3777649998664856,
              0.02616499923169613,
              0.316648006439209,
              0.07784000039100647,
              0.0073990002274513245,
              0.44630300998687744,
              0.0073979999870061874,
              0.34723299741744995,
              0.05205800011754036,
              0.44418999552726746,
              0.0073979999870061874,
              0.34723299741744995,
              0.05205800011754036,
              0.3409869968891144,
              0.05205800011754036,
              0.44418999552726746,
              0.0073979999870061874,
              0.24816200137138367,
              0.05205800011754036,
              0.2377839982509613,
              0.0073979999870061874,
              0.34723299741744995,
              0.05205800011754036,
              0.2377839982509613,
              0.05205800011754036,
              0.3409869968891144,
              0.0073979999870061874,
              0.34723299741744995,
              0.13329699635505676,
              0.23466099798679352,
              0.2555310130119324,
              0.2345310002565384,
              0.13329699635505676,
              0.33907899260520935,
              0.2555310130119324,
              0.2345310002565384,
              0.2555310130119324,
              0.3389489948749542,
              0.13329699635505676,
              0.33907899260520935,
              0,
              0,
              0.3472070097923279,
              0.0006910000229254365,
              0.316648006439209,
              0.02622999995946884,
              0.316648006439209,
              0.07784000039100647,
              0.3777649998664856,
              0.12998400628566742,
              0.2555310130119324,
              0.13011400401592255,
              0.13329699635505676,
              0.13024300336837769,
              0.05205800011754036,
              0.13458000123500824,
              0.13329699635505676,
              0.026424000039696693,
              0.05205800011754036,
              0.13458000123500824,
              0.05205800011754036,
              0.0290910005569458,
              0.13329699635505676,
              0.026424000039696693,
              0.5,
              0.5153939723968506,
              0.3777660131454468,
              0.5155230164527893,
              0.5,
              0.4431079924106598,
              0.3777660131454468,
              0.5155230164527893,
              0.3777660131454468,
              0.4432379901409149,
              0.5,
              0.4431079924106598,
              0.5,
              0.9730219841003418,
              0.6222350001335144,
              0.9728919863700867,
              0,
              0,
              0.6222350001335144,
              0.9728919863700867,
              0,
              0,
              0,
              0,
              0.26955699920654297,
              0.5529429912567139,
              0.29164600372314453,
              0.4527519941329956,
              0.3551110029220581,
              0.5392029881477356,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444679737091064,
              0.12959599494934082,
              0.8667029738426208,
              0.1294659972190857,
              0.7444689869880676,
              0.23401400446891785,
              0.8667029738426208,
              0.1294659972190857,
              0.8667029738426208,
              0.23388400673866272,
              0.7444689869880676,
              0.23401400446891785,
              0.9926019906997681,
              0.5142030119895935,
              0.9926019906997681,
              0.6032320261001587,
              0.9479420185089111,
              0.5147669911384583,
              0.9926019906997681,
              0.6032320261001587,
              0.9479420185089111,
              0.6074119806289673,
              0.9479420185089111,
              0.5147669911384583,
              0.9926019906997681,
              0.5142030119895935,
              0.9479420185089111,
              0.5147669911384583,
              0.9926019906997681,
              0.4452599883079529,
              0.9479420185089111,
              0.5147669911384583,
              0.9479420185089111,
              0.4432410001754761,
              0.9926019906997681,
              0.4452599883079529,
              0.3777649998664856,
              0.12998400628566742,
              0.316648006439209,
              0.07784000039100647,
              0.3777649998664856,
              0.02616499923169613,
              0,
              0,
              0,
              0,
              0,
              0,
              0.7444689869880676,
              0.23401400446891785,
              0.8667029738426208,
              0.23388400673866272,
              0.7444689869880676,
              0.3384310007095337,
              0.8667029738426208,
              0.23388400673866272,
              0.8667029738426208,
              0.33830198645591736,
              0.7444689869880676,
              0.3384310007095337,
              0.6222339868545532,
              0.025906000286340714,
              0.6527929902076721,
              0.00036800000816583633,
              0.6833509802818298,
              0.025840999558568,
              0.43363699316978455,
              0.6208599805831909,
              0.5,
              0.5057690143585205,
              0.5864440202713013,
              0.5849050283432007,
              0.9926009774208069,
              0.2471179962158203,
              0.94794100522995,
              0.23683500289916992,
              0.9926009774208069,
              0.14804700016975403,
              0.94794100522995,
              0.23683500289916992,
              0.94794100522995,
              0.13363100588321686,
              0.9926009774208069,
              0.14804700016975403,
              0,
              0,
              0,
              0,
              0,
              0,
              0.2555310130119324,
              0.026294000446796417,
              0.316648006439209,
              0.02622999995946884,
              0.316648006439209,
              0.07784000039100647,
              0.3777660131454468,
              0.4432379901409149,
              0.3777660131454468,
              0.5155230164527893,
              0.2555310130119324,
              0.44336700439453125,
              0.3777660131454468,
              0.5155230164527893,
              0.2555310130119324,
              0.5156530141830444,
              0.2555310130119324,
              0.44336700439453125,
              0,
              0,
              0,
              0,
              0,
              0,
              0.621819019317627,
              0.29450300335884094,
              0.5663629770278931,
              0.3906779885292053,
              0.4804629981517792,
              0.3218100070953369,
              0.8667029738426208,
              0.5150049924850464,
              0.9479420185089111,
              0.5147669911384583,
              0.8667029738426208,
              0.6087120175361633,
              0.9479420185089111,
              0.5147669911384583,
              0.9479420185089111,
              0.6074119806289673,
              0.8667029738426208,
              0.6087120175361633,
              0.9479420185089111,
              0.9698709845542908,
              0.9479420185089111,
              0.9170209765434265,
              0.9926019906997681,
              0.9607179760932922,
              0.9479420185089111,
              0.9170209765434265,
              0.9926019906997681,
              0.900443971157074,
              0.9926019906997681,
              0.9607179760932922,
              0.3551110029220581,
              0.5392029881477356,
              0.29164600372314453,
              0.4527519941329956,
              0.3355740010738373,
              0.3552440106868744,
              0.7083550095558167,
              0.5587859749794006,
              0.6644260287284851,
              0.6562939882278442,
              0.5864440202713013,
              0.5849050283432007,
              0.3355740010738373,
              0.3552440106868744,
              0.4804629981517792,
              0.3218100070953369,
              0.3551110029220581,
              0.5392029881477356,
              0.4804629981517792,
              0.3218100070953369,
              0.5,
              0.5057690143585205,
              0.3551110029220581,
              0.5392029881477356,
              0.5864440202713013,
              0.5849050283432007,
              0.6044020056724548,
              0.7390679717063904,
              0.43363699316978455,
              0.6208599805831909,
              0.6044020056724548,
              0.7390679717063904,
              0.4543690085411072,
              0.7702130079269409,
              0.43363699316978455,
              0.6208599805831909,
              0.621819019317627,
              0.29450300335884094,
              0.685500979423523,
              0.3693690001964569,
              0.5663629770278931,
              0.3906779885292053,
              0.3355740010738373,
              0.3552440106868744,
              0.39559799432754517,
              0.27246999740600586,
              0.4804629981517792,
              0.3218100070953369,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.4452599883079529,
              0,
              0,
              0.9926019906997681,
              0.5142030119895935,
              0,
              0,
              0,
              0,
              0.9926019906997681,
              0.5142030119895935,
              0.5864440202713013,
              0.5849050283432007,
              0.6644260287284851,
              0.6562939882278442,
              0.6044020056724548,
              0.7390679717063904,
              0,
              0,
              0,
              0,
              0.39559799432754517,
              0.27246999740600586,
              0,
              0,
              0.46439599990844727,
              0.21503999829292297,
              0.39559799432754517,
              0.27246999740600586,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0.3355740010738373,
              0.3552440106868744,
              0.29164600372314453,
              0.4527519941329956,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ],
            "normalized": false
          }
        },
        "groups": [
          {
            "start": 0,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 6,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 9,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 12,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 21,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 24,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 30,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 33,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 39,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 48,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 51,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 57,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 63,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 69,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 72,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 78,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 81,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 87,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 90,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 93,
            "materialIndex": 4,
            "count": 78
          },
          {
            "start": 171,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 177,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 183,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 189,
            "materialIndex": 4,
            "count": 18
          },
          {
            "start": 207,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 213,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 216,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 219,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 225,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 228,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 231,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 237,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 243,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 246,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 249,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 255,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 261,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 264,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 267,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 273,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 279,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 282,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 285,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 291,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 297,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 300,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 306,
            "materialIndex": 6,
            "count": 3
          },
          {
            "start": 309,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 315,
            "materialIndex": 6,
            "count": 6
          },
          {
            "start": 321,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 333,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 342,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 345,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 363,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 369,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 378,
            "materialIndex": 0,
            "count": 30
          },
          {
            "start": 408,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 423,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 435,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 441,
            "materialIndex": 0,
            "count": 15
          },
          {
            "start": 456,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 474,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 477,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 498,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 504,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 513,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 519,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 534,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 540,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 558,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 564,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 570,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 576,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 579,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 585,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 591,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 597,
            "materialIndex": 1,
            "count": 27
          },
          {
            "start": 624,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 627,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 630,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 636,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 657,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 663,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 669,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 672,
            "materialIndex": 1,
            "count": 45
          },
          {
            "start": 717,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 720,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 741,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 744,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 750,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 753,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 756,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 768,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 774,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 777,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 786,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 789,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 792,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 795,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 810,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 816,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 822,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 825,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 828,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 834,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 837,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 843,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 861,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 867,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 873,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 876,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 894,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 900,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 921,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 924,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 942,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 945,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 948,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 951,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 957,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 975,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 984,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 1017,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1023,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1032,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1038,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1041,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1044,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 1056,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1062,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1068,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1071,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1077,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1086,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 1101,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1104,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1107,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1110,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 1125,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1128,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1131,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1140,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1146,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1149,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1155,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 1167,
            "materialIndex": 1,
            "count": 30
          },
          {
            "start": 1197,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1200,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1203,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1212,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 1233,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1236,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1242,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1248,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 1260,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1266,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 1290,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1293,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1299,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1305,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1308,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 1320,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1323,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1329,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1332,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1335,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1344,
            "materialIndex": 0,
            "count": 18
          },
          {
            "start": 1362,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1368,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1374,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1380,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1383,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1389,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1392,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1401,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1404,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1407,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1410,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1416,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1422,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1425,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1428,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1437,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1440,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 1455,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1458,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1464,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1467,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1473,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1479,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1485,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1488,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1491,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1500,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1506,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1515,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 1548,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1551,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 1554,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1563,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1566,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1569,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1572,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1581,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1584,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1587,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1593,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 1605,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1608,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 1623,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1632,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1638,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1644,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 1668,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1671,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1674,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1677,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1680,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1683,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 1698,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 1707,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1713,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1719,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1725,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1728,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1731,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1734,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1737,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1740,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1746,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1752,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 1758,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 1764,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1770,
            "materialIndex": 5,
            "count": 15
          },
          {
            "start": 1785,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 1791,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 1794,
            "materialIndex": 5,
            "count": 21
          },
          {
            "start": 1815,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1821,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 1830,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 1833,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1836,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 1842,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 1854,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 1857,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1860,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1866,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 1869,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1872,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 1878,
            "materialIndex": 1,
            "count": 27
          },
          {
            "start": 1905,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 1911,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1917,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 1929,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1935,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 1941,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 1944,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 1950,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 1956,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 1959,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 1971,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 1974,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 1983,
            "materialIndex": 5,
            "count": 12
          },
          {
            "start": 1995,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2007,
            "materialIndex": 7,
            "count": 6
          },
          {
            "start": 2013,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2022,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 2025,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2028,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2034,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2040,
            "materialIndex": 5,
            "count": 27
          },
          {
            "start": 2067,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2073,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2079,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2091,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2100,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 2109,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2112,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2115,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2121,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2130,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 2148,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2154,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 2166,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2169,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2178,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 2181,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2187,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2190,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2193,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2199,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2205,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2208,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2214,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2220,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2226,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2232,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 2241,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2250,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2256,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2262,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2265,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2271,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2274,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2280,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2283,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2289,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2295,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2301,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2307,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2310,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2316,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2319,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2322,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2325,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2328,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2334,
            "materialIndex": 5,
            "count": 12
          },
          {
            "start": 2346,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2352,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2361,
            "materialIndex": 3,
            "count": 27
          },
          {
            "start": 2388,
            "materialIndex": 5,
            "count": 12
          },
          {
            "start": 2400,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2403,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2409,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2415,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2421,
            "materialIndex": 5,
            "count": 15
          },
          {
            "start": 2436,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2442,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2448,
            "materialIndex": 5,
            "count": 21
          },
          {
            "start": 2469,
            "materialIndex": 3,
            "count": 9
          },
          {
            "start": 2478,
            "materialIndex": 5,
            "count": 15
          },
          {
            "start": 2493,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2496,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2499,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2502,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2508,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2511,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 2544,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2553,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2559,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2562,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2568,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2580,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2586,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2592,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2598,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2610,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2616,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2622,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2631,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2637,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2643,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2646,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2652,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 2655,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2658,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 2670,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2676,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2679,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2685,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 2694,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2700,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2703,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2712,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 2718,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 2724,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 2736,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2739,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2745,
            "materialIndex": 5,
            "count": 24
          },
          {
            "start": 2769,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 2781,
            "materialIndex": 5,
            "count": 24
          },
          {
            "start": 2805,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 2811,
            "materialIndex": 5,
            "count": 30
          },
          {
            "start": 2841,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 2844,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2847,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2850,
            "materialIndex": 5,
            "count": 33
          },
          {
            "start": 2883,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 2886,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2889,
            "materialIndex": 1,
            "count": 27
          },
          {
            "start": 2916,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 2925,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 2940,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 2943,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2946,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2958,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2961,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 2976,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 2982,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 2994,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 2997,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 3000,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 3006,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 3009,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 3012,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 3024,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 3027,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 3033,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 3057,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3060,
            "materialIndex": 1,
            "count": 135
          },
          {
            "start": 3195,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 3201,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 3213,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3216,
            "materialIndex": 1,
            "count": 93
          },
          {
            "start": 3309,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3312,
            "materialIndex": 1,
            "count": 111
          },
          {
            "start": 3423,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 3429,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 3438,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 3444,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 3456,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 3459,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 3468,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 3474,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 3498,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 3504,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 3510,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 3519,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 3537,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3540,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 3573,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 3582,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 3597,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3600,
            "materialIndex": 1,
            "count": 66
          },
          {
            "start": 3666,
            "materialIndex": 4,
            "count": 3
          },
          {
            "start": 3669,
            "materialIndex": 1,
            "count": 81
          },
          {
            "start": 3750,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 3756,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 3759,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 3765,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 3771,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 3774,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 3780,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 3798,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 3801,
            "materialIndex": 1,
            "count": 213
          },
          {
            "start": 4014,
            "materialIndex": 5,
            "count": 12
          },
          {
            "start": 4026,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4029,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 4032,
            "materialIndex": 1,
            "count": 63
          },
          {
            "start": 4095,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4098,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4101,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 4107,
            "materialIndex": 1,
            "count": 36
          },
          {
            "start": 4143,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 4149,
            "materialIndex": 1,
            "count": 96
          },
          {
            "start": 4245,
            "materialIndex": 7,
            "count": 6
          },
          {
            "start": 4251,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 4257,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 4275,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4278,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 4287,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4290,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 4293,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4296,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4299,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 4314,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4317,
            "materialIndex": 1,
            "count": 156
          },
          {
            "start": 4473,
            "materialIndex": 5,
            "count": 15
          },
          {
            "start": 4488,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 4494,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 4500,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4503,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4506,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4509,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 4518,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 4527,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 4548,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4551,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 4554,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4557,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 4569,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 4575,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 4578,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 4584,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 4587,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 4593,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 4605,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4608,
            "materialIndex": 1,
            "count": 57
          },
          {
            "start": 4665,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4668,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 4701,
            "materialIndex": 5,
            "count": 9
          },
          {
            "start": 4710,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 4725,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 4731,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 4755,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4758,
            "materialIndex": 1,
            "count": 153
          },
          {
            "start": 4911,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 4917,
            "materialIndex": 1,
            "count": 21
          },
          {
            "start": 4938,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 4941,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 4944,
            "materialIndex": 1,
            "count": 18
          },
          {
            "start": 4962,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 4965,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 4974,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 4980,
            "materialIndex": 1,
            "count": 102
          },
          {
            "start": 5082,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 5085,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5088,
            "materialIndex": 1,
            "count": 36
          },
          {
            "start": 5124,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5127,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 5136,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 5139,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5142,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 5157,
            "materialIndex": 7,
            "count": 6
          },
          {
            "start": 5163,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5166,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5169,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 5172,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5175,
            "materialIndex": 7,
            "count": 6
          },
          {
            "start": 5181,
            "materialIndex": 1,
            "count": 30
          },
          {
            "start": 5211,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5214,
            "materialIndex": 2,
            "count": 3
          },
          {
            "start": 5217,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5220,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 5232,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5238,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5241,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5244,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 5259,
            "materialIndex": 7,
            "count": 12
          },
          {
            "start": 5271,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5274,
            "materialIndex": 1,
            "count": 33
          },
          {
            "start": 5307,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5310,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5316,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5319,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5322,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5325,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 5334,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5337,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5340,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5343,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5346,
            "materialIndex": 0,
            "count": 18
          },
          {
            "start": 5364,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 5373,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5379,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5382,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5388,
            "materialIndex": 1,
            "count": 30
          },
          {
            "start": 5418,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5424,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5427,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 5436,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5439,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5442,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 5454,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5457,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 5472,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 5481,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5487,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5490,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5496,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5499,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5502,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5508,
            "materialIndex": 1,
            "count": 27
          },
          {
            "start": 5535,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5541,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5547,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5553,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5556,
            "materialIndex": 0,
            "count": 18
          },
          {
            "start": 5574,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5580,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 5589,
            "materialIndex": 1,
            "count": 15
          },
          {
            "start": 5604,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 5607,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 5619,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 5622,
            "materialIndex": 1,
            "count": 63
          },
          {
            "start": 5685,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 5697,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 5706,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5709,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5712,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 5715,
            "materialIndex": 0,
            "count": 15
          },
          {
            "start": 5730,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5733,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 5742,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 5745,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5748,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 5751,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5754,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 5760,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5763,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 5775,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5781,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 5784,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 5796,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5799,
            "materialIndex": 3,
            "count": 81
          },
          {
            "start": 5880,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5886,
            "materialIndex": 3,
            "count": 24
          },
          {
            "start": 5910,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5916,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 5928,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5931,
            "materialIndex": 3,
            "count": 63
          },
          {
            "start": 5994,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 5997,
            "materialIndex": 3,
            "count": 15
          },
          {
            "start": 6012,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 6015,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 6033,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 6039,
            "materialIndex": 3,
            "count": 30
          },
          {
            "start": 6069,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 6072,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 6075,
            "materialIndex": 5,
            "count": 6
          },
          {
            "start": 6081,
            "materialIndex": 3,
            "count": 39
          },
          {
            "start": 6120,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 6126,
            "materialIndex": 3,
            "count": 27
          },
          {
            "start": 6153,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 6156,
            "materialIndex": 3,
            "count": 57
          },
          {
            "start": 6213,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 6216,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 6219,
            "materialIndex": 3,
            "count": 21
          },
          {
            "start": 6240,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 6243,
            "materialIndex": 3,
            "count": 9
          },
          {
            "start": 6252,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 6255,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 6261,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 6264,
            "materialIndex": 3,
            "count": 9
          },
          {
            "start": 6273,
            "materialIndex": 5,
            "count": 3
          },
          {
            "start": 6276,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 6279,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 6291,
            "materialIndex": 7,
            "count": 24
          },
          {
            "start": 6315,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 6318,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 6324,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 6330,
            "materialIndex": 7,
            "count": 9
          },
          {
            "start": 6339,
            "materialIndex": 1,
            "count": 9
          },
          {
            "start": 6348,
            "materialIndex": 7,
            "count": 3
          },
          {
            "start": 6351,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 6357,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 6360,
            "materialIndex": 1,
            "count": 12
          },
          {
            "start": 6372,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 6375,
            "materialIndex": 1,
            "count": 3
          },
          {
            "start": 6378,
            "materialIndex": 2,
            "count": 9
          }
        ]
      }
    }
  ],
  "materials": [
    {
      "uuid": "D1857886-A395-4641-A345-C050EAE4265D",
      "type": "MeshNormalMaterial",
      "depthFunc": 3,
      "depthTest": true,
      "depthWrite": true,
      "wireframe": true
    }
  ],
  "object": {
    "uuid": "CD89F781-514D-41AE-91D8-5FC3E2B220BD",
    "type": "Mesh",
    "layers": 1,
    "matrix": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    ],
    "geometry": "6CE0F213-E13B-47C4-A522-48024F54FD50",
    "material": "D1857886-A395-4641-A345-C050EAE4265D"
  }
}
