{
  "metadata": {
    "version": 4.5,
    "type": "Object",
    "generator": "Object3D.toJSON"
  },
  "geometries": [
    {
      "uuid": "FCB29F3C-570E-416C-900F-95AE652F0073",
      "type": "BufferGeometry",
      "data": {
        "attributes": {
          "position": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1.0776599645614624,
              0.35169199109077454,
              0.06610549986362457,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              -4.114009857177734,
              0.22727400064468384,
              -0.052774399518966675,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -4.114009857177734,
              0.19965499639511108,
              -0.04080890119075775,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              1.0776599645614624,
              0.0959583967924118,
              -0.002643380081281066,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              2.16444993019104,
              0.09595809876918793,
              0.06521130353212357,
              1.0776599645614624,
              0.0959583967924118,
              -0.002643380081281066,
              3.639780044555664,
              0.22411000728607178,
              -0.08450470119714737,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2079160064458847,
              -0.07694269716739655,
              -4.114009857177734,
              0.2712709903717041,
              0.0008178260177373886,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              1.0776599645614624,
              0.2879840135574341,
              0.11060599982738495,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -4.114009857177734,
              0.17638200521469116,
              0.0242162998765707,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              -4.114009857177734,
              0.17638200521469116,
              0.0242162998765707,
              -3.5985400676727295,
              0.10260800272226334,
              -0.0025025000795722008,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              3.639780044555664,
              0.2841239869594574,
              -0.051314301788806915,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2411849945783615,
              -0.07610470056533813,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -4.114009857177734,
              0.2712709903717041,
              0.0008178260177373886,
              -4.114009857177734,
              0.2689630091190338,
              -0.028704799711704254,
              -4.114009857177734,
              0.2712709903717041,
              0.0008178260177373886,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              -4.114009857177734,
              0.2689630091190338,
              -0.028704799711704254,
              3.724760055541992,
              0.18064500391483307,
              0.14311499893665314,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              3.639780044555664,
              0.29737699031829834,
              -0.04201729968190193,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2841239869594574,
              -0.051314301788806915,
              3.639780044555664,
              0.2984139919281006,
              -0.023821700364351273,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.29737699031829834,
              -0.04201729968190193,
              3.662760019302368,
              0.2684980034828186,
              -0.14862200617790222,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.662709951400757,
              0.20822200179100037,
              -0.15841299295425415,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.639780044555664,
              0.2984139919281006,
              0.02597299963235855,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2984139919281006,
              -0.023821700364351273,
              -4.114009857177734,
              0.26912200450897217,
              0.028652800247073174,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -4.114009857177734,
              0.2712709903717041,
              0.0008178260177373886,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -2.732369899749756,
              0.15866999328136444,
              0.10811199992895126,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              3.6358399391174316,
              0.11244899779558182,
              -0.07104119658470154,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              3.639780044555664,
              0.297448992729187,
              0.042335301637649536,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2984139919281006,
              0.02597299963235855,
              3.6358399391174316,
              0.2169879972934723,
              0.13139699399471283,
              3.639780044555664,
              0.22358499467372894,
              0.08483169972896576,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.639780044555664,
              0.283935010433197,
              0.05142410099506378,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.297448992729187,
              0.042335301637649536,
              3.639780044555664,
              0.20784999430179596,
              0.07690530270338058,
              3.6358399391174316,
              0.2169879972934723,
              0.13139699399471283,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              1.0776599645614624,
              0.0959583967924118,
              -0.002643380081281066,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              1.0776599645614624,
              0.10232499986886978,
              -0.07749970257282257,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              3.6834700107574463,
              0.13237500190734863,
              0.11125099658966064,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              1.0776599645614624,
              0.10232499986886978,
              -0.07749970257282257,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              1.0776599645614624,
              0.21624800562858582,
              -0.1432729959487915,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              3.639780044555664,
              0.2385289967060089,
              0.07763879746198654,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.283935010433197,
              0.05142410099506378,
              3.7867701053619385,
              0.14998799562454224,
              -0.13819700479507446,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.786760091781616,
              0.10182499885559082,
              -0.10002200305461884,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.6358399391174316,
              0.10661300271749496,
              -0.00242291996255517,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              -4.114009857177734,
              0.22605900466442108,
              0.053515199571847916,
              -4.114009857177734,
              0.19933199882507324,
              0.04066240042448044,
              -4.114009857177734,
              0.22605900466442108,
              0.053515199571847916,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -4.114009857177734,
              0.19933199882507324,
              0.04066240042448044,
              -4.114009857177734,
              0.22605900466442108,
              0.053515199571847916,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -4.114009857177734,
              0.26912200450897217,
              0.028652800247073174,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              3.7973198890686035,
              0.26866599917411804,
              0.14799900352954865,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.639780044555664,
              0.22358499467372894,
              0.08483169972896576,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.2385289967060089,
              0.07763879746198654,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              3.639780044555664,
              0.20784999430179596,
              0.07690530270338058,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.22358499467372894,
              0.08483169972896576,
              3.639780044555664,
              0.16393400728702545,
              -0.051549699157476425,
              3.6358399391174316,
              0.11244899779558182,
              -0.07104119658470154,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              -4.114009857177734,
              0.17638200521469116,
              -0.025157399475574493,
              -3.5985400676727295,
              0.10260800272226334,
              -0.0025025000795722008,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -3.5985400676727295,
              0.10260800272226334,
              -0.0025025000795722008,
              -4.114009857177734,
              0.17638200521469116,
              0.0242162998765707,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.786750078201294,
              0.20828300714492798,
              -0.15779100358486176,
              3.786760091781616,
              0.2695449888706207,
              -0.15098999440670013,
              3.786750078201294,
              0.20828300714492798,
              -0.15779100358486176,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.786760091781616,
              0.2695449888706207,
              -0.15098999440670013,
              1.0776599645614624,
              0.3516930043697357,
              -0.06395459920167923,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              1.0776599645614624,
              0.29082900285720825,
              -0.10896400362253189,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              3.639780044555664,
              0.1631300002336502,
              0.05108629912137985,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.20784999430179596,
              0.07690530270338058,
              3.639780044555664,
              0.14923599362373352,
              0.024577800184488297,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.6358399391174316,
              0.10661300271749496,
              -0.00242291996255517,
              -4.114009857177734,
              0.19933199882507324,
              0.04066240042448044,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -4.114009857177734,
              0.17638200521469116,
              0.0242162998765707,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -4.114009857177734,
              0.22605900466442108,
              0.053515199571847916,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              3.639780044555664,
              0.15013200044631958,
              0.04214629903435707,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.1631300002336502,
              0.05108629912137985,
              3.639780044555664,
              0.2411849945783615,
              -0.07610470056533813,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              3.6358399391174316,
              0.28524500131607056,
              -0.09988299757242203,
              3.639780044555664,
              0.14923599362373352,
              0.024577800184488297,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.15013200044631958,
              0.04214629903435707,
              3.596169948577881,
              0.28903499245643616,
              0.12233199924230576,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              -3.5985400676727295,
              0.2873460054397583,
              -0.10329499840736389,
              -4.114009857177734,
              0.22727400064468384,
              -0.052774399518966675,
              -4.114009857177734,
              0.2689630091190338,
              -0.028704799711704254,
              -4.114009857177734,
              0.22727400064468384,
              -0.052774399518966675,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -4.114009857177734,
              0.2689630091190338,
              -0.028704799711704254,
              3.639780044555664,
              0.14923599362373352,
              -0.027220699936151505,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.14923599362373352,
              0.024577800184488297,
              3.6628100872039795,
              0.3189600110054016,
              -0.11570499837398529,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.662760019302368,
              0.2684980034828186,
              -0.14862200617790222,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              -4.376239776611328,
              0.22382700443267822,
              0.000006373530140990624,
              -4.114009857177734,
              0.19965499639511108,
              -0.04080890119075775,
              -4.114009857177734,
              0.17638200521469116,
              -0.025157399475574493,
              -4.114009857177734,
              0.19965499639511108,
              -0.04080890119075775,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              -4.114009857177734,
              0.17638200521469116,
              -0.025157399475574493,
              1.0776599645614624,
              0.0959583967924118,
              -0.002643380081281066,
              2.16444993019104,
              0.09595809876918793,
              0.06521130353212357,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              2.16444993019104,
              0.09595809876918793,
              0.06521130353212357,
              1.0776599645614624,
              0.1013370007276535,
              0.07692939788103104,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              -1.3707400560379028,
              0.3508619964122772,
              -0.06353870034217834,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              3.639780044555664,
              0.15029700100421906,
              -0.0426815003156662,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.14923599362373352,
              -0.027220699936151505,
              3.6358399391174316,
              0.11244899779558182,
              -0.07104119658470154,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.639780044555664,
              0.16393400728702545,
              -0.051549699157476425,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.15029700100421906,
              -0.0426815003156662,
              1.0776599645614624,
              0.2879840135574341,
              0.11060599982738495,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              1.0776599645614624,
              0.21636700630187988,
              0.1433420032262802,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -4.114009857177734,
              0.26912200450897217,
              0.028652800247073174,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -4.114009857177734,
              0.26912200450897217,
              0.028652800247073174,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              1.0776599645614624,
              0.3516930043697357,
              -0.06395459920167923,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              3.6834700107574463,
              0.15603800117969513,
              -0.12706300616264343,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              -2.732369899749756,
              0.15866999328136444,
              0.10811199992895126,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              -2.732369899749756,
              0.15866999328136444,
              0.10811199992895126,
              3.639780044555664,
              0.2079160064458847,
              -0.07694269716739655,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.16393400728702545,
              -0.051549699157476425,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266,
              4.207099914550781,
              0.22382399439811707,
              0.1465979963541031,
              4.207469940185547,
              0.23844699561595917,
              0.14846399426460266,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.6358399391174316,
              0.34103599190711975,
              -0.05862480029463768,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              3.6358399391174316,
              0.2168789952993393,
              -0.13133400678634644,
              3.639780044555664,
              0.2079160064458847,
              -0.07694269716739655,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              -1.3707400560379028,
              0.10311499983072281,
              -0.07699570059776306,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              -1.3707400560379028,
              0.0967898964881897,
              -0.002625890076160431,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.662760019302368,
              0.3440369963645935,
              0.09814769774675369,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              3.6358399391174316,
              0.2169879972934723,
              0.13139699399471283,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              3.6358399391174316,
              0.34103599190711975,
              0.060596998780965805,
              3.639780044555664,
              0.2984139919281006,
              0.02597299963235855,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.766089916229248,
              0.1802549958229065,
              0.14405600726604462,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.6358399391174316,
              0.34103599190711975,
              -0.05862480029463768,
              3.639780044555664,
              0.29737699031829834,
              -0.04201729968190193,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.673340082168579,
              0.2675580084323883,
              0.14540299773216248,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.662709951400757,
              0.23942700028419495,
              0.15841299295425415,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.652169942855835,
              0.2673580050468445,
              0.14492100477218628,
              3.662709951400757,
              0.23942700028419495,
              0.15841299295425415,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.639780044555664,
              0.2385289967060089,
              0.07763879746198654,
              3.6358399391174316,
              0.28263700008392334,
              0.10138899832963943,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.137660026550293,
              0.3454520106315613,
              0.09981650114059448,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              4.137660026550293,
              0.3454520106315613,
              0.09981650114059448,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.6358399391174316,
              0.34103599190711975,
              0.060596998780965805,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              3.6358399391174316,
              0.2168789952993393,
              -0.13133400678634644,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              4.079689979553223,
              0.2953679859638214,
              0.13384799659252167,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.099009990692139,
              0.2982249855995178,
              0.13919299840927124,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.639780044555664,
              0.15013200044631958,
              0.04214629903435707,
              3.6358399391174316,
              0.11154399812221527,
              0.07051879912614822,
              3.639780044555664,
              0.14923599362373352,
              -0.027220699936151505,
              3.6358399391174316,
              0.10661300271749496,
              -0.00242291996255517,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.639780044555664,
              0.2841239869594574,
              -0.051314301788806915,
              3.6358399391174316,
              0.28524500131607056,
              -0.09988299757242203,
              3.1659300327301025,
              0.1023240014910698,
              -0.07749950140714645,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              2.16444993019104,
              0.09595820307731628,
              -0.06785459816455841,
              1.0776599645614624,
              0.10232499986886978,
              -0.07749970257282257,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              -1.3707400560379028,
              0.0967898964881897,
              -0.002625890076160431,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              -3.5985400676727295,
              0.10260800272226334,
              -0.0025025000795722008,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -1.3707400560379028,
              0.21629799902439117,
              -0.1423420011997223,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              1.0776599645614624,
              0.21624800562858582,
              -0.1432729959487915,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              -1.3707400560379028,
              0.3508619964122772,
              -0.06353870034217834,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              -1.3707400560379028,
              0.29039400815963745,
              -0.10825499892234802,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              1.0776599645614624,
              0.29082900285720825,
              -0.10896400362253189,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              -1.3707400560379028,
              0.29039400815963745,
              -0.10825499892234802,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              3.6628100872039795,
              0.15333400666713715,
              -0.13217200338840485,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.662760019302368,
              0.10361099988222122,
              -0.09814819693565369,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              1.0776599645614624,
              0.35169199109077454,
              0.06610549986362457,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              2.16444993019104,
              0.35169199109077454,
              0.0021508699283003807,
              1.0776599645614624,
              0.3516930043697357,
              -0.06395459920167923,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              3.598400115966797,
              0.22382399439811707,
              0.14659899473190308,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              3.596169948577881,
              0.23844699561595917,
              0.14846399426460266,
              3.662760019302368,
              0.07543040066957474,
              -0.04542560130357742,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.6628100872039795,
              0.07476489990949631,
              0.0148200998082757,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              1.0776599645614624,
              0.3516930043697357,
              -0.06395459920167923,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              -0.00912248995155096,
              0.3454729914665222,
              -0.0774151012301445,
              -1.3707400560379028,
              0.3508619964122772,
              -0.06353870034217834,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              -1.3707400560379028,
              0.3508619964122772,
              -0.06353870034217834,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              -1.3707400560379028,
              0.3508619964122772,
              -0.06353870034217834,
              3.662760019302368,
              0.10361099988222122,
              -0.09814819693565369,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.662760019302368,
              0.07543040066957474,
              -0.04542560130357742,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              1.0776599645614624,
              0.2879840135574341,
              0.11060599982738495,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              1.0776599645614624,
              0.35169199109077454,
              0.06610549986362457,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              1.0776599645614624,
              0.2879840135574341,
              0.11060599982738495,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              -0.009122500196099281,
              0.3459010124206543,
              0.07716819643974304,
              1.0776599645614624,
              0.35169199109077454,
              0.06610549986362457,
              -0.00912248995155096,
              0.3516930043697357,
              0.002150659915059805,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              3.5983800888061523,
              0.25200000405311584,
              0.14275799691677094,
              3.596169948577881,
              0.23844699561595917,
              0.14846399426460266,
              -3.6673800945281982,
              0.3450450003147125,
              0.06267160177230835,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -1.3707400560379028,
              0.3508619964122772,
              0.06567610055208206,
              -2.732369899749756,
              0.3500309884548187,
              0.0021233700681477785,
              3.7867701053619385,
              0.06789640337228775,
              0.015384599566459656,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.786750078201294,
              0.08399119973182678,
              0.07474140077829361,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              3.6358399391174316,
              0.28263700008392334,
              0.10138899832963943,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.786750078201294,
              0.36365699768066406,
              -0.07474250346422195,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.7867701053619385,
              0.32324498891830444,
              -0.1211019977927208,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              1.0776599645614624,
              0.21636700630187988,
              0.1433420032262802,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              -1.3707400560379028,
              0.0967898964881897,
              -0.002625890076160431,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -1.3707400560379028,
              0.0967898964881897,
              -0.002625890076160431,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -0.009122470393776894,
              0.22984300553798676,
              0.1441739946603775,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              -1.3707400560379028,
              0.21641500294208527,
              0.14240999519824982,
              3.7867701053619385,
              0.32324498891830444,
              -0.1211019977927208,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.786760091781616,
              0.2695449888706207,
              -0.15098999440670013,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              1.0776599645614624,
              0.1013370007276535,
              0.07692939788103104,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              1.0776599645614624,
              0.21636700630187988,
              0.1433420032262802,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.652169942855835,
              0.2673580050468445,
              0.14492100477218628,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              -2.732369899749756,
              0.15866999328136444,
              0.10811199992895126,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -2.732369899749756,
              0.09762129932641983,
              0.06436429917812347,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              3.683389902114868,
              0.20894800126552582,
              -0.1510380059480667,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              -1.3707400560379028,
              0.10213299840688705,
              0.07642970234155655,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              1.0776599645614624,
              0.1013370007276535,
              0.07692939788103104,
              -0.00912248995155096,
              0.1578119993209839,
              0.10953500121831894,
              3.786760091781616,
              0.07288020104169846,
              -0.04587079957127571,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.7867701053619385,
              0.06789640337228775,
              0.015384599566459656,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.683419942855835,
              0.2666130065917969,
              -0.14217400550842285,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -1.3707400560379028,
              0.21629799902439117,
              -0.1423420011997223,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.662709951400757,
              0.08344029635190964,
              0.07503660023212433,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              1.0776599645614624,
              0.0959583967924118,
              -0.002643380081281066,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              -0.009122549556195736,
              0.09595850110054016,
              0.06521119922399521,
              -1.3707400560379028,
              0.0967898964881897,
              -0.002625890076160431,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              1.0776599645614624,
              0.10232499986886978,
              -0.07749970257282257,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              -0.00912254024296999,
              0.09595859795808792,
              -0.06785470247268677,
              -1.3707400560379028,
              0.10311499983072281,
              -0.07699570059776306,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.786750078201294,
              0.36365699768066406,
              -0.07474250346422195,
              3.7867701053619385,
              0.3797520101070404,
              -0.015385700389742851,
              3.786750078201294,
              0.36365699768066406,
              -0.07474250346422195,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.7867701053619385,
              0.3797520101070404,
              -0.015385700389742851,
              3.596169948577881,
              0.23844699561595917,
              0.14846399426460266,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              3.6628100872039795,
              0.07476489990949631,
              0.0148200998082757,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.662709951400757,
              0.08344029635190964,
              0.07503660023212433,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -1.3707400560379028,
              0.29039400815963745,
              -0.10825499892234802,
              -1.3707400560379028,
              0.21629799902439117,
              -0.1423420011997223,
              -1.3707400560379028,
              0.29039400815963745,
              -0.10825499892234802,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              -1.3707400560379028,
              0.21629799902439117,
              -0.1423420011997223,
              3.6834700107574463,
              0.08051549643278122,
              0.014229499734938145,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.652169942855835,
              0.2673580050468445,
              0.14492100477218628,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.786760091781616,
              0.07288020104169846,
              -0.04587079957127571,
              3.786760091781616,
              0.10182499885559082,
              -0.10002200305461884,
              3.786760091781616,
              0.07288020104169846,
              -0.04587079957127571,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.786760091781616,
              0.10182499885559082,
              -0.10002200305461884,
              3.683389902114868,
              0.08997610211372375,
              0.07154300063848495,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              1.0776599645614624,
              0.29082900285720825,
              -0.10896400362253189,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              1.0776599645614624,
              0.21624800562858582,
              -0.1432729959487915,
              -0.009122500196099281,
              0.2331160008907318,
              -0.14228500425815582,
              3.6358399391174316,
              0.10661300271749496,
              -0.00242291996255517,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.683419942855835,
              0.33880099654197693,
              0.09393859654664993,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.683419942855835,
              0.36580899357795715,
              0.04340960085391998,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.6358399391174316,
              0.34103599190711975,
              0.060596998780965805,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              3.683389902114868,
              0.3576720058917999,
              -0.07154349982738495,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.6358399391174316,
              0.2168789952993393,
              -0.13133400678634644,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              1.0776599645614624,
              0.29082900285720825,
              -0.10896400362253189,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              2.16444993019104,
              0.09595809876918793,
              0.06521130353212357,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              1.0776599645614624,
              0.1013370007276535,
              0.07692939788103104,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              1.0776599645614624,
              0.1013370007276535,
              0.07692939788103104,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              2.16444993019104,
              0.22984200716018677,
              0.1441739946603775,
              1.0776599645614624,
              0.21636700630187988,
              0.1433420032262802,
              2.16444993019104,
              0.1578110009431839,
              0.10953500121831894,
              3.6834700107574463,
              0.3152740001678467,
              -0.11125099658966064,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.786760091781616,
              0.3458240032196045,
              0.10002099722623825,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.786760091781616,
              0.3747679889202118,
              0.04586980119347572,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.6358399391174316,
              0.11154399812221527,
              0.07051879912614822,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              2.16444993019104,
              0.3458999991416931,
              0.07716839760541916,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.1659300327301025,
              0.35169199109077454,
              0.06610570102930069,
              3.786760091781616,
              0.3747679889202118,
              0.04586980119347572,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.7867701053619385,
              0.3797520101070404,
              -0.015385700389742851,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              2.16444993019104,
              0.3454729914665222,
              -0.07741490006446838,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              3.786760091781616,
              0.3458240032196045,
              0.10002099722623825,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.786760091781616,
              0.3458240032196045,
              0.10002099722623825,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.662760019302368,
              0.3722180128097534,
              0.04542509838938713,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.6628100872039795,
              0.37288400530815125,
              -0.014820500276982784,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.147500038146973,
              0.20867499709129333,
              0.1538109928369522,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              3.6358399391174316,
              0.28524500131607056,
              -0.09988299757242203,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.639780044555664,
              0.2984139919281006,
              -0.023821700364351273,
              3.6358399391174316,
              0.34103599190711975,
              -0.05862480029463768,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.639780044555664,
              0.283935010433197,
              0.05142410099506378,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.6358399391174316,
              0.28263700008392334,
              0.10138899832963943,
              3.639780044555664,
              0.1631300002336502,
              0.05108629912137985,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              3.6358399391174316,
              0.11154399812221527,
              0.07051879912614822,
              3.596169948577881,
              0.28903499245643616,
              0.12233199924230576,
              3.5982799530029297,
              0.2765499949455261,
              0.12805399298667908,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              -3.5985400676727295,
              0.10260800272226334,
              -0.0025025000795722008,
              -4.114009857177734,
              0.17638200521469116,
              -0.025157399475574493,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -4.114009857177734,
              0.17638200521469116,
              -0.025157399475574493,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -4.114009857177734,
              0.19965499639511108,
              -0.04080890119075775,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              3.683419942855835,
              0.10884799808263779,
              -0.09393899887800217,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.662709951400757,
              0.36420801281929016,
              -0.07503700256347656,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.6628100872039795,
              0.3189600110054016,
              -0.11570499837398529,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.6358399391174316,
              0.34103599190711975,
              -0.05862480029463768,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.1659300327301025,
              0.35169199109077454,
              -0.06395439803600311,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.596169948577881,
              0.23844699561595917,
              0.14846399426460266,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              -1.3707400560379028,
              0.29039400815963745,
              -0.10825499892234802,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -3.5985400676727295,
              0.2873460054397583,
              -0.10329499840736389,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              3.662709951400757,
              0.20822200179100037,
              -0.15841299295425415,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.6628100872039795,
              0.15333400666713715,
              -0.13217200338840485,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.683419942855835,
              0.08183939754962921,
              -0.043410200625658035,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.6358399391174316,
              0.28524500131607056,
              -0.09988299757242203,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              3.1659300327301025,
              0.29082900285720825,
              -0.1089629977941513,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              3.6358399391174316,
              0.2169879972934723,
              0.13139699399471283,
              3.1659300327301025,
              0.2163659930229187,
              0.1433420032262802,
              3.786750078201294,
              0.20828300714492798,
              -0.15779100358486176,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.7867701053619385,
              0.14998799562454224,
              -0.13819700479507446,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              -1.3707400560379028,
              0.10311499983072281,
              -0.07699570059776306,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              -1.3707400560379028,
              0.10311499983072281,
              -0.07699570059776306,
              -2.732369899749756,
              0.09762129932641983,
              -0.06697189807891846,
              -3.6673800945281982,
              0.10864300280809402,
              -0.07346659898757935,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.946589946746826,
              0.22382399439811707,
              -3.763130109746271e-7,
              3.8579399585723877,
              0.22382399439811707,
              -3.6545898751683126e-7,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.041049957275391,
              0.34114301204681396,
              0.09628059715032578,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              3.5983800888061523,
              0.25200000405311584,
              0.14275799691677094,
              3.598400115966797,
              0.22382399439811707,
              0.14659899473190308,
              3.596169948577881,
              0.23844699561595917,
              0.14846399426460266,
              3.5983800888061523,
              0.19564799964427948,
              0.14275799691677094,
              3.5982799530029297,
              0.1710990071296692,
              0.12805399298667908,
              3.596169948577881,
              0.18224400281906128,
              0.13859400153160095,
              4.207099914550781,
              0.3052699863910675,
              0.12189199775457382,
              4.207469940185547,
              0.2941490113735199,
              0.13156700134277344,
              4.207099914550781,
              0.27992498874664307,
              0.13543899357318878,
              3.5982799530029297,
              0.1710990071296692,
              0.12805399298667908,
              3.5982298851013184,
              0.14857900142669678,
              0.11261200159788132,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              4.177579879760742,
              0.2953679859638214,
              0.13384799659252167,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.207099914550781,
              0.34571701288223267,
              0.08144540339708328,
              4.207469940185547,
              0.3391439914703369,
              0.09464020282030106,
              4.207099914550781,
              0.32748499512672424,
              0.10366100072860718,
              3.5982298851013184,
              0.14857900142669678,
              0.11261200159788132,
              3.5982799530029297,
              0.12569500505924225,
              0.09771639853715897,
              3.596169948577881,
              0.13575899600982666,
              0.10706199705600739,
              3.6733899116516113,
              0.15467900037765503,
              0.12962999939918518,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.6834700107574463,
              0.13237500190734863,
              0.11125099658966064,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.6834700107574463,
              0.13237500190734863,
              0.11125099658966064,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              3.5983800888061523,
              0.19564799964427948,
              0.14275799691677094,
              3.596169948577881,
              0.18224400281906128,
              0.13859400153160095,
              4.207099914550781,
              0.3676060140132904,
              0.02859950065612793,
              4.207469940185547,
              0.366582989692688,
              0.04330499842762947,
              4.207099914550781,
              0.35926398634910583,
              0.05610039830207825,
              3.5982799530029297,
              0.12569500505924225,
              0.09771639853715897,
              3.5983800888061523,
              0.10271500051021576,
              0.08066269755363464,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.596169948577881,
              0.18224400281906128,
              0.13859400153160095,
              3.5982799530029297,
              0.1710990071296692,
              0.12805399298667908,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              4.207099914550781,
              0.3676060140132904,
              -0.028600500896573067,
              4.207469940185547,
              0.3722890019416809,
              -0.014623000286519527,
              4.207099914550781,
              0.37042298913002014,
              -5.207759841141524e-7,
              3.5983800888061523,
              0.10271500051021576,
              0.08066269755363464,
              3.5983800888061523,
              0.0894709974527359,
              0.05588480085134506,
              3.596169948577881,
              0.09225679934024811,
              0.07032430171966553,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.662709951400757,
              0.08344029635190964,
              0.07503660023212433,
              3.683389902114868,
              0.08997610211372375,
              0.07154300063848495,
              3.662709951400757,
              0.08344029635190964,
              0.07503660023212433,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.683389902114868,
              0.08997610211372375,
              0.07154300063848495,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              3.5982298851013184,
              0.14857900142669678,
              0.11261200159788132,
              3.596169948577881,
              0.13575899600982666,
              0.10706199705600739,
              4.207099914550781,
              0.3457159996032715,
              -0.08144620060920715,
              4.207469940185547,
              0.3553920090198517,
              -0.07032469660043716,
              4.207099914550781,
              0.35926398634910583,
              -0.056101299822330475,
              3.5983800888061523,
              0.0894709974527359,
              0.05588480085134506,
              3.5982799530029297,
              0.0880580022931099,
              0.027303500100970268,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.596169948577881,
              0.13575899600982666,
              0.10706199705600739,
              3.5982799530029297,
              0.12569500505924225,
              0.09771639853715897,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              4.207079887390137,
              0.3052699863910675,
              -0.1218930035829544,
              4.207469940185547,
              0.318464994430542,
              -0.11531999707221985,
              4.207099914550781,
              0.32748499512672424,
              -0.10366100072860718,
              3.5982799530029297,
              0.0880580022931099,
              0.027303500100970268,
              3.5982298851013184,
              0.08838649839162827,
              -5.8630501342804564e-8,
              3.596169948577881,
              0.08587940037250519,
              0.0137429004535079,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.6628100872039795,
              0.07476489990949631,
              0.0148200998082757,
              3.6834700107574463,
              0.08051549643278122,
              0.014229499734938145,
              3.6628100872039795,
              0.07476489990949631,
              0.0148200998082757,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.6834700107574463,
              0.08051549643278122,
              0.014229499734938145,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              3.5983800888061523,
              0.10271500051021576,
              0.08066269755363464,
              3.596169948577881,
              0.09225679934024811,
              0.07032430171966553,
              4.206960201263428,
              0.2524240016937256,
              -0.14378200471401215,
              4.207409858703613,
              0.2671299874782562,
              -0.14275899529457092,
              4.206980228424072,
              0.27992498874664307,
              -0.13544000685214996,
              3.5982298851013184,
              0.08838649839162827,
              -5.8630501342804564e-8,
              3.5982799530029297,
              0.0880580022931099,
              -0.027303600683808327,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.596169948577881,
              0.09225679934024811,
              0.07032430171966553,
              3.5983800888061523,
              0.0894709974527359,
              0.05588480085134506,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              4.206960201263428,
              0.19522400200366974,
              -0.14378200471401215,
              4.207409858703613,
              0.20920200645923615,
              -0.14846499264240265,
              4.206960201263428,
              0.22382399439811707,
              -0.14659899473190308,
              3.5982799530029297,
              0.0880580022931099,
              -0.027303600683808327,
              3.5983800888061523,
              0.08947090059518814,
              -0.05588490143418312,
              3.596169948577881,
              0.08548679947853088,
              -0.04242609813809395,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.662760019302368,
              0.07543040066957474,
              -0.04542560130357742,
              3.683419942855835,
              0.08183939754962921,
              -0.043410200625658035,
              3.662760019302368,
              0.07543040066957474,
              -0.04542560130357742,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.683419942855835,
              0.08183939754962921,
              -0.043410200625658035,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              3.5982799530029297,
              0.0880580022931099,
              0.027303500100970268,
              3.596169948577881,
              0.08587940037250519,
              0.0137429004535079,
              4.207079887390137,
              0.14237800240516663,
              -0.1218930035829544,
              4.20743989944458,
              0.1535000056028366,
              -0.13156799972057343,
              4.206980228424072,
              0.16772300004959106,
              -0.13544000685214996,
              3.5983800888061523,
              0.08947090059518814,
              -0.05588490143418312,
              3.5983800888061523,
              0.10271500051021576,
              -0.08066289871931076,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.596169948577881,
              0.08587940037250519,
              0.0137429004535079,
              3.5982298851013184,
              0.08838649839162827,
              -5.8630501342804564e-8,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              4.207099914550781,
              0.10193199664354324,
              -0.08144640177488327,
              4.207469940185547,
              0.10850399732589722,
              -0.09464109688997269,
              4.207099914550781,
              0.12016300112009048,
              -0.10366100072860718,
              3.5983800888061523,
              0.10271500051021576,
              -0.08066289871931076,
              3.5982799530029297,
              0.12569400668144226,
              -0.09771659970283508,
              3.596169948577881,
              0.11169199645519257,
              -0.09145289659500122,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.662760019302368,
              0.10361099988222122,
              -0.09814819693565369,
              3.683419942855835,
              0.10884799808263779,
              -0.09393899887800217,
              3.662760019302368,
              0.10361099988222122,
              -0.09814819693565369,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.683419942855835,
              0.10884799808263779,
              -0.09393899887800217,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              3.5982799530029297,
              0.0880580022931099,
              -0.027303600683808327,
              3.596169948577881,
              0.08548679947853088,
              -0.04242609813809395,
              4.207099914550781,
              0.08004230260848999,
              -0.028600700199604034,
              4.207469940185547,
              0.0810651034116745,
              -0.043306201696395874,
              4.207099914550781,
              0.0883847028017044,
              -0.05610150098800659,
              3.5982799530029297,
              0.12569400668144226,
              -0.09771659970283508,
              3.5982298851013184,
              0.14857900142669678,
              -0.1126129999756813,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.596169948577881,
              0.08548679947853088,
              -0.04242609813809395,
              3.5983800888061523,
              0.08947090059518814,
              -0.05588490143418312,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              4.207099914550781,
              0.08004199713468552,
              0.028599200770258904,
              4.207469940185547,
              0.07535950094461441,
              0.014621700160205364,
              4.207099914550781,
              0.07722529768943787,
              -7.889730113674887e-7,
              3.5982298851013184,
              0.14857900142669678,
              -0.1126129999756813,
              3.5982799530029297,
              0.1710990071296692,
              -0.12805500626564026,
              3.596169948577881,
              0.15861299633979797,
              -0.12233199924230576,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.6628100872039795,
              0.15333400666713715,
              -0.13217200338840485,
              3.6834700107574463,
              0.15603800117969513,
              -0.12706300616264343,
              3.6628100872039795,
              0.15333400666713715,
              -0.13217200338840485,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.6834700107574463,
              0.15603800117969513,
              -0.12706300616264343,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              3.5983800888061523,
              0.10271500051021576,
              -0.08066289871931076,
              3.596169948577881,
              0.11169199645519257,
              -0.09145289659500122,
              4.207099914550781,
              0.10193099826574326,
              0.08144509792327881,
              4.207469940185547,
              0.092256098985672,
              0.07032349705696106,
              4.207099914550781,
              0.08838420361280441,
              0.05609999969601631,
              3.5982799530029297,
              0.1710990071296692,
              -0.12805500626564026,
              3.5983800888061523,
              0.19564799964427948,
              -0.14275899529457092,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.596169948577881,
              0.11169199645519257,
              -0.09145289659500122,
              3.5982799530029297,
              0.12569400668144226,
              -0.09771659970283508,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              4.207099914550781,
              0.14237700402736664,
              0.12189199775457382,
              4.207469940185547,
              0.12918299436569214,
              0.11531899869441986,
              4.207099914550781,
              0.12016300112009048,
              0.10366000235080719,
              3.5983800888061523,
              0.19564799964427948,
              -0.14275899529457092,
              3.598400115966797,
              0.22382399439811707,
              -0.14659899473190308,
              3.596169948577881,
              0.20920200645923615,
              -0.14846499264240265,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.662709951400757,
              0.20822200179100037,
              -0.15841299295425415,
              3.683389902114868,
              0.20894800126552582,
              -0.1510380059480667,
              3.662709951400757,
              0.20822200179100037,
              -0.15841299295425415,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.683389902114868,
              0.20894800126552582,
              -0.1510380059480667,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              3.5982298851013184,
              0.14857900142669678,
              -0.1126129999756813,
              3.596169948577881,
              0.15861299633979797,
              -0.12233199924230576,
              3.598400115966797,
              0.22382399439811707,
              -0.14659899473190308,
              3.5983800888061523,
              0.25200000405311584,
              -0.14275899529457092,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.596169948577881,
              0.15861299633979797,
              -0.12233199924230576,
              3.5982799530029297,
              0.1710990071296692,
              -0.12805500626564026,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.5983800888061523,
              0.25200000405311584,
              -0.14275899529457092,
              3.5982799530029297,
              0.2765499949455261,
              -0.12805500626564026,
              3.596169948577881,
              0.26540398597717285,
              -0.13859400153160095,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.662760019302368,
              0.2684980034828186,
              -0.14862200617790222,
              3.683419942855835,
              0.2666130065917969,
              -0.14217400550842285,
              3.662760019302368,
              0.2684980034828186,
              -0.14862200617790222,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.683419942855835,
              0.2666130065917969,
              -0.14217400550842285,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.5983800888061523,
              0.19564799964427948,
              -0.14275899529457092,
              3.596169948577881,
              0.20920200645923615,
              -0.14846499264240265,
              3.5982799530029297,
              0.2765499949455261,
              -0.12805500626564026,
              3.5982298851013184,
              0.29906898736953735,
              -0.1126129999756813,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.596169948577881,
              0.20920200645923615,
              -0.14846499264240265,
              3.598400115966797,
              0.22382399439811707,
              -0.14659899473190308,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.5982298851013184,
              0.29906898736953735,
              -0.1126129999756813,
              3.5982799530029297,
              0.32195401191711426,
              -0.09771669656038284,
              3.596169948577881,
              0.31188899278640747,
              -0.10706199705600739,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.6628100872039795,
              0.3189600110054016,
              -0.11570499837398529,
              3.6834700107574463,
              0.3152740001678467,
              -0.11125099658966064,
              3.6628100872039795,
              0.3189600110054016,
              -0.11570499837398529,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.6834700107574463,
              0.3152740001678467,
              -0.11125099658966064,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.5983800888061523,
              0.25200000405311584,
              -0.14275899529457092,
              3.596169948577881,
              0.26540398597717285,
              -0.13859400153160095,
              3.5982799530029297,
              0.32195401191711426,
              -0.09771669656038284,
              3.5983800888061523,
              0.34493300318717957,
              -0.08066300302743912,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.596169948577881,
              0.26540398597717285,
              -0.13859400153160095,
              3.5982799530029297,
              0.2765499949455261,
              -0.12805500626564026,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.5983800888061523,
              0.34493300318717957,
              -0.08066300302743912,
              3.5983800888061523,
              0.3581779897212982,
              -0.05588499829173088,
              3.596169948577881,
              0.3553920090198517,
              -0.07032450288534164,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.662709951400757,
              0.36420801281929016,
              -0.07503700256347656,
              3.683389902114868,
              0.3576720058917999,
              -0.07154349982738495,
              3.662709951400757,
              0.36420801281929016,
              -0.07503700256347656,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.683389902114868,
              0.3576720058917999,
              -0.07154349982738495,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.5982298851013184,
              0.29906898736953735,
              -0.1126129999756813,
              3.596169948577881,
              0.31188899278640747,
              -0.10706199705600739,
              3.5983800888061523,
              0.3581779897212982,
              -0.05588499829173088,
              3.5982799530029297,
              0.359591007232666,
              -0.027303799986839294,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.596169948577881,
              0.31188899278640747,
              -0.10706199705600739,
              3.5982799530029297,
              0.32195401191711426,
              -0.09771669656038284,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.5982799530029297,
              0.359591007232666,
              -0.027303799986839294,
              3.5982298851013184,
              0.35926198959350586,
              -1.9120300009944913e-7,
              3.596169948577881,
              0.3617689907550812,
              -0.013743099756538868,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.6628100872039795,
              0.37288400530815125,
              -0.014820500276982784,
              3.6834700107574463,
              0.3671329915523529,
              -0.014229999855160713,
              3.6628100872039795,
              0.37288400530815125,
              -0.014820500276982784,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.6834700107574463,
              0.3671329915523529,
              -0.014229999855160713,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.5983800888061523,
              0.34493300318717957,
              -0.08066300302743912,
              3.596169948577881,
              0.3553920090198517,
              -0.07032450288534164,
              3.5982298851013184,
              0.35926198959350586,
              -1.9120300009944913e-7,
              3.5982799530029297,
              0.359591007232666,
              0.02730339951813221,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.596169948577881,
              0.3553920090198517,
              -0.07032450288534164,
              3.5983800888061523,
              0.3581779897212982,
              -0.05588499829173088,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.5982799530029297,
              0.359591007232666,
              0.02730339951813221,
              3.5983800888061523,
              0.3581779897212982,
              0.055884599685668945,
              3.596169948577881,
              0.3621619939804077,
              0.04242580011487007,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.662760019302368,
              0.3722180128097534,
              0.04542509838938713,
              3.683419942855835,
              0.36580899357795715,
              0.04340960085391998,
              3.662760019302368,
              0.3722180128097534,
              0.04542509838938713,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.683419942855835,
              0.36580899357795715,
              0.04340960085391998,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.5982799530029297,
              0.359591007232666,
              -0.027303799986839294,
              3.596169948577881,
              0.3617689907550812,
              -0.013743099756538868,
              3.5983800888061523,
              0.3581779897212982,
              0.055884599685668945,
              3.5983800888061523,
              0.34493300318717957,
              0.08066249638795853,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.596169948577881,
              0.3617689907550812,
              -0.013743099756538868,
              3.5982298851013184,
              0.35926198959350586,
              -1.9120300009944913e-7,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.5983800888061523,
              0.34493300318717957,
              0.08066249638795853,
              3.5982799530029297,
              0.32195401191711426,
              0.0977163016796112,
              3.596169948577881,
              0.33595600724220276,
              0.09145259857177734,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.662760019302368,
              0.3440369963645935,
              0.09814769774675369,
              3.683419942855835,
              0.33880099654197693,
              0.09393859654664993,
              3.662760019302368,
              0.3440369963645935,
              0.09814769774675369,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.683419942855835,
              0.33880099654197693,
              0.09393859654664993,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.5982799530029297,
              0.359591007232666,
              0.02730339951813221,
              3.596169948577881,
              0.3621619939804077,
              0.04242580011487007,
              3.5982799530029297,
              0.32195401191711426,
              0.0977163016796112,
              3.5982298851013184,
              0.29906898736953735,
              0.11261200159788132,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.596169948577881,
              0.3621619939804077,
              0.04242580011487007,
              3.5983800888061523,
              0.3581779897212982,
              0.055884599685668945,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.5982298851013184,
              0.29906898736953735,
              0.11261200159788132,
              3.5982799530029297,
              0.2765499949455261,
              0.12805399298667908,
              3.596169948577881,
              0.28903499245643616,
              0.12233199924230576,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              3.6628100872039795,
              0.29431501030921936,
              0.13217200338840485,
              3.673340082168579,
              0.2675580084323883,
              0.14540299773216248,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.5983800888061523,
              0.34493300318717957,
              0.08066249638795853,
              3.596169948577881,
              0.33595600724220276,
              0.09145259857177734,
              3.5982799530029297,
              0.2765499949455261,
              0.12805399298667908,
              3.5983800888061523,
              0.25200000405311584,
              0.14275799691677094,
              3.596169948577881,
              0.26540499925613403,
              0.13859400153160095,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.704129934310913,
              0.29540398716926575,
              0.13413099944591522,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.596169948577881,
              0.33595600724220276,
              0.09145259857177734,
              3.5982799530029297,
              0.32195401191711426,
              0.0977163016796112,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.041049957275391,
              0.2953679859638214,
              0.13384799659252167,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              3.5982298851013184,
              0.29906898736953735,
              0.11261200159788132,
              3.596169948577881,
              0.28903499245643616,
              0.12233199924230576,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.177579879760742,
              0.2953679859638214,
              0.13384799659252167,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.207099914550781,
              0.2524240016937256,
              0.14378200471401215,
              4.207469940185547,
              0.23844699561595917,
              0.14846399426460266,
              4.207099914550781,
              0.22382399439811707,
              0.1465979963541031,
              4.207099914550781,
              0.2524240016937256,
              0.14378200471401215,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.207469940185547,
              0.23844699561595917,
              0.14846399426460266,
              4.207099914550781,
              0.19522300362586975,
              0.14378100633621216,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266,
              4.207469940185547,
              0.1805180013179779,
              0.14275899529457092,
              4.207099914550781,
              0.16772200167179108,
              0.13543899357318878,
              4.207469940185547,
              0.1805180013179779,
              0.14275899529457092,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.207099914550781,
              0.14237700402736664,
              0.12189199775457382,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.207469940185547,
              0.12918299436569214,
              0.11531899869441986,
              4.207099914550781,
              0.12016300112009048,
              0.10366000235080719,
              4.207469940185547,
              0.12918299436569214,
              0.11531899869441986,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.207099914550781,
              0.10193099826574326,
              0.08144509792327881,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.207469940185547,
              0.092256098985672,
              0.07032349705696106,
              4.207099914550781,
              0.08838420361280441,
              0.05609999969601631,
              4.207469940185547,
              0.092256098985672,
              0.07032349705696106,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.207099914550781,
              0.08004199713468552,
              0.028599200770258904,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.207469940185547,
              0.07535950094461441,
              0.014621700160205364,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.8258299827575684,
              0.10686500370502472,
              0.09591999650001526,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              4.207099914550781,
              0.07722529768943787,
              -7.889730113674887e-7,
              4.207469940185547,
              0.07535950094461441,
              0.014621700160205364,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.207099914550781,
              0.08004230260848999,
              -0.028600700199604034,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.207469940185547,
              0.0810651034116745,
              -0.043306201696395874,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.8258299827575684,
              0.07397539913654327,
              -0.014777200296521187,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              4.207099914550781,
              0.0883847028017044,
              -0.05610150098800659,
              4.207469940185547,
              0.0810651034116745,
              -0.043306201696395874,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.8258299827575684,
              0.07909020036458969,
              0.04395600035786629,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              3.8258299827575684,
              0.15285800397396088,
              0.1328040063381195,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              4.207099914550781,
              0.10193199664354324,
              -0.08144640177488327,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.207469940185547,
              0.10850399732589722,
              -0.09464109688997269,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.825819969177246,
              0.08997630327939987,
              -0.07154379785060883,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.724760055541992,
              0.18064500391483307,
              0.14311499893665314,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.714639902114868,
              0.20859800279140472,
              0.1545889973640442,
              3.724760055541992,
              0.18064500391483307,
              0.14311499893665314,
              4.207099914550781,
              0.12016300112009048,
              -0.10366100072860718,
              4.207469940185547,
              0.10850399732589722,
              -0.09464109688997269,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              3.8258299827575684,
              0.07909020036458969,
              0.04395600035786629,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.8258299827575684,
              0.10686500370502472,
              0.09591999650001526,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              4.207079887390137,
              0.14237800240516663,
              -0.1218930035829544,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.20743989944458,
              0.1535000056028366,
              -0.13156799972057343,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.8258299827575684,
              0.17996299266815186,
              -0.14476299285888672,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              4.206980228424072,
              0.16772300004959106,
              -0.13544000685214996,
              4.20743989944458,
              0.1535000056028366,
              -0.13156799972057343,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.8258299827575684,
              0.12828700244426727,
              -0.11638599634170532,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.8258299827575684,
              0.07397539913654327,
              -0.014777200296521187,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              4.206960201263428,
              0.19522400200366974,
              -0.14378200471401215,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.207409858703613,
              0.20920200645923615,
              -0.14846499264240265,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.825819969177246,
              0.2387000024318695,
              -0.1510380059480667,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              4.206960201263428,
              0.22382399439811707,
              -0.14659899473190308,
              4.207409858703613,
              0.20920200645923615,
              -0.14846499264240265,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              3.8258299827575684,
              0.12828700244426727,
              -0.11638599634170532,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.825819969177246,
              0.08997630327939987,
              -0.07154379785060883,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              4.206960201263428,
              0.2524240016937256,
              -0.14378200471401215,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.207409858703613,
              0.2671299874782562,
              -0.14275899529457092,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.8258299827575684,
              0.3407829999923706,
              -0.09592100232839584,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              4.206980228424072,
              0.27992498874664307,
              -0.13544000685214996,
              4.207409858703613,
              0.2671299874782562,
              -0.14275899529457092,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.8258299827575684,
              0.29478898644447327,
              -0.1328040063381195,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              3.8258299827575684,
              0.17996299266815186,
              -0.14476299285888672,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              4.207079887390137,
              0.3052699863910675,
              -0.1218930035829544,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.207469940185547,
              0.318464994430542,
              -0.11531999707221985,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.8258299827575684,
              0.36855798959732056,
              -0.043957099318504333,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              4.207099914550781,
              0.32748499512672424,
              -0.10366100072860718,
              4.207469940185547,
              0.318464994430542,
              -0.11531999707221985,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              3.8258299827575684,
              0.29478898644447327,
              -0.1328040063381195,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.825819969177246,
              0.2387000024318695,
              -0.1510380059480667,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              4.207099914550781,
              0.3457159996032715,
              -0.08144620060920715,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.207469940185547,
              0.3553920090198517,
              -0.07032469660043716,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.825819969177246,
              0.3576720058917999,
              0.0715428963303566,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              4.207099914550781,
              0.35926398634910583,
              -0.056101299822330475,
              4.207469940185547,
              0.3553920090198517,
              -0.07032469660043716,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.8258299827575684,
              0.3736729919910431,
              0.014775999821722507,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              3.8258299827575684,
              0.3407829999923706,
              -0.09592100232839584,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              4.207099914550781,
              0.3676060140132904,
              -0.028600500896573067,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.207469940185547,
              0.3722890019416809,
              -0.014623000286519527,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              3.8258299827575684,
              0.31936201453208923,
              0.11638499796390533,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              4.207099914550781,
              0.37042298913002014,
              -5.207759841141524e-7,
              4.207469940185547,
              0.3722890019416809,
              -0.014623000286519527,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              3.8258299827575684,
              0.3736729919910431,
              0.014775999821722507,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.8258299827575684,
              0.36855798959732056,
              -0.043957099318504333,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              4.207099914550781,
              0.3676060140132904,
              0.02859950065612793,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.207469940185547,
              0.366582989692688,
              0.04330499842762947,
              3.825819969177246,
              0.20894800126552582,
              0.1510380059480667,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              4.207099914550781,
              0.35926398634910583,
              0.05610039830207825,
              4.207469940185547,
              0.366582989692688,
              0.04330499842762947,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              3.7973198890686035,
              0.26866599917411804,
              0.14799900352954865,
              3.8258299827575684,
              0.26768600940704346,
              0.14476299285888672,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              3.825819969177246,
              0.3576720058917999,
              0.0715428963303566,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              4.207099914550781,
              0.34571701288223267,
              0.08144540339708328,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.207469940185547,
              0.3391439914703369,
              0.09464020282030106,
              4.207099914550781,
              0.32748499512672424,
              0.10366100072860718,
              4.207469940185547,
              0.3391439914703369,
              0.09464020282030106,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.8258299827575684,
              0.31936201453208923,
              0.11638499796390533,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              4.207099914550781,
              0.3052699863910675,
              0.12189199775457382,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.207469940185547,
              0.2941490113735199,
              0.13156700134277344,
              4.207099914550781,
              0.27992498874664307,
              0.13543899357318878,
              4.207469940185547,
              0.2941490113735199,
              0.13156700134277344,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              3.596169948577881,
              0.18224400281906128,
              0.13859400153160095,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.673340082168579,
              0.2675580084323883,
              0.14540299773216248,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.673340082168579,
              0.2675580084323883,
              0.14540299773216248,
              3.662709951400757,
              0.23942700028419495,
              0.15841299295425415,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.8545498847961426,
              0.2533850073814392,
              0.14873600006103516,
              3.8258299827575684,
              0.26768600940704346,
              0.14476299285888672,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.724790096282959,
              0.2927669882774353,
              0.12915000319480896,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.724790096282959,
              0.2927669882774353,
              0.12915000319480896,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.714639902114868,
              0.20859800279140472,
              0.1545889973640442,
              3.7247300148010254,
              0.2387000024318695,
              0.1510380059480667,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.7559800148010254,
              0.2682119905948639,
              0.1468539983034134,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.7661099433898926,
              0.2939229905605316,
              0.13123799860477448,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.7661099433898926,
              0.2939229905605316,
              0.13123799860477448,
              3.8545498847961426,
              0.2533850073814392,
              0.14873600006103516,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.7973198890686035,
              0.26866599917411804,
              0.14799900352954865,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.7973198890686035,
              0.26866599917411804,
              0.14799900352954865,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.7661099433898926,
              0.2939229905605316,
              0.13123799860477448,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.7761900424957275,
              0.2684670090675354,
              0.14751799404621124,
              3.7867701053619385,
              0.2976610064506531,
              0.13819700479507446,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.766089916229248,
              0.1802549958229065,
              0.14405600726604462,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.755959987640381,
              0.20863400399684906,
              0.1542229950428009,
              3.766089916229248,
              0.1802549958229065,
              0.14405600726604462,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.6733899116516113,
              0.15467900037765503,
              0.12962999939918518,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.825819969177246,
              0.20894800126552582,
              0.1510380059480667,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              3.596169948577881,
              0.28903499245643616,
              0.12233199924230576,
              3.6232900619506836,
              0.29045501351356506,
              0.12497399747371674,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              3.596169948577881,
              0.33595600724220276,
              0.09145259857177734,
              3.596169948577881,
              0.31188899278640747,
              0.10706199705600739,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.854569911956787,
              0.3074299991130829,
              0.1251250058412552,
              3.8258299827575684,
              0.31936201453208923,
              0.11638499796390533,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.683419942855835,
              0.33880099654197693,
              0.09393859654664993,
              3.6733899116516113,
              0.31712600588798523,
              0.11348900198936462,
              3.724790096282959,
              0.2927669882774353,
              0.12915000319480896,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.724760055541992,
              0.33952200412750244,
              0.09465920180082321,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.7661099433898926,
              0.2939229905605316,
              0.13123799860477448,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.766089916229248,
              0.3402419984340668,
              0.0953797996044159,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.596169948577881,
              0.33595600724220276,
              0.09145259857177734,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.854569911956787,
              0.3074299991130829,
              0.1251250058412552,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.724760055541992,
              0.33952200412750244,
              0.09465920180082321,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.766089916229248,
              0.3402419984340668,
              0.0953797996044159,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.776210069656372,
              0.3207379877567291,
              0.11803299933671951,
              3.786760091781616,
              0.3458240032196045,
              0.10002099722623825,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.7559800148010254,
              0.2682119905948639,
              0.1468539983034134,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.7559800148010254,
              0.2682119905948639,
              0.1468539983034134,
              3.7661099433898926,
              0.2939229905605316,
              0.13123799860477448,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.662760019302368,
              0.3440369963645935,
              0.09814769774675369,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.6232199668884277,
              0.3380799889564514,
              0.09321790188550949,
              3.6522300243377686,
              0.31636399030685425,
              0.11253300309181213,
              3.596169948577881,
              0.3621619939804077,
              0.04242580011487007,
              3.596169948577881,
              0.3553920090198517,
              0.07032410055398941,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.8545498847961426,
              0.34992700815200806,
              0.08422870188951492,
              3.825819969177246,
              0.3576720058917999,
              0.0715428963303566,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              3.683419942855835,
              0.33880099654197693,
              0.09393859654664993,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.683419942855835,
              0.36580899357795715,
              0.04340960085391998,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.724760055541992,
              0.33952200412750244,
              0.09465920180082321,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.724760055541992,
              0.36680901050567627,
              0.0436084009706974,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.766089916229248,
              0.3402419984340668,
              0.0953797996044159,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.766089916229248,
              0.3678080141544342,
              0.04380720108747482,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.596169948577881,
              0.3621619939804077,
              0.04242580011487007,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.8545498847961426,
              0.34992700815200806,
              0.08422870188951492,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              3.724760055541992,
              0.36680901050567627,
              0.0436084009706974,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.766089916229248,
              0.3678080141544342,
              0.04380720108747482,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.7761800289154053,
              0.3606649935245514,
              0.07314229756593704,
              3.786760091781616,
              0.3747679889202118,
              0.04586980119347572,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.766089916229248,
              0.3402419984340668,
              0.0953797996044159,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.662760019302368,
              0.3722180128097534,
              0.04542509838938713,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.6232199668884277,
              0.3648090064525604,
              0.04321090131998062,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.596169948577881,
              0.3617689907550812,
              -0.013743099756538868,
              3.596169948577881,
              0.3617689907550812,
              0.013742799870669842,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.854559898376465,
              0.371755987405777,
              0.029459400102496147,
              3.8258299827575684,
              0.3736729919910431,
              0.014775999821722507,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              3.683419942855835,
              0.36580899357795715,
              0.04340960085391998,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.6834700107574463,
              0.3671329915523529,
              -0.014229999855160713,
              3.6733899116516113,
              0.3700230121612549,
              0.014526000246405602,
              3.724760055541992,
              0.36680901050567627,
              0.0436084009706974,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.724790096282959,
              0.3695110082626343,
              -0.014428899623453617,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.786750078201294,
              0.23936599493026733,
              0.15779100358486176,
              3.7761800289154053,
              0.2086150050163269,
              0.15441399812698364,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.766089916229248,
              0.3678080141544342,
              0.04380720108747482,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.7661099433898926,
              0.37188899517059326,
              -0.014627800323069096,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.596169948577881,
              0.3617689907550812,
              -0.013743099756538868,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.854559898376465,
              0.371755987405777,
              0.029459400102496147,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.724790096282959,
              0.3695110082626343,
              -0.014428899623453617,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.7661099433898926,
              0.37188899517059326,
              -0.014627800323069096,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.776210069656372,
              0.375806987285614,
              0.015004799701273441,
              3.7867701053619385,
              0.3797520101070404,
              -0.015385700389742851,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.766089916229248,
              0.3678080141544342,
              0.04380720108747482,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.6628100872039795,
              0.37288400530815125,
              -0.014820500276982784,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.6232900619506836,
              0.3647550046443939,
              -0.01403110008686781,
              3.6522300243377686,
              0.3688049912452698,
              0.014424099586904049,
              3.596169948577881,
              0.3553920090198517,
              -0.07032450288534164,
              3.596169948577881,
              0.3621619939804077,
              -0.04242619872093201,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.854559898376465,
              0.371755987405777,
              -0.029460199177265167,
              3.8258299827575684,
              0.36855798959732056,
              -0.043957099318504333,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.6834700107574463,
              0.3671329915523529,
              -0.014229999855160713,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.683389902114868,
              0.3576720058917999,
              -0.07154349982738495,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.724790096282959,
              0.3695110082626343,
              -0.014428899623453617,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.7247300148010254,
              0.3576720058917999,
              -0.07154349982738495,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.7661099433898926,
              0.37188899517059326,
              -0.014627800323069096,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.766079902648926,
              0.3576720058917999,
              -0.07154359668493271,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.596169948577881,
              0.3553920090198517,
              -0.07032450288534164,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.854559898376465,
              0.371755987405777,
              -0.029460199177265167,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.7247300148010254,
              0.3576720058917999,
              -0.07154349982738495,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.766079902648926,
              0.3576720058917999,
              -0.07154359668493271,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.7761900424957275,
              0.37128299474716187,
              -0.04483810067176819,
              3.786750078201294,
              0.36365699768066406,
              -0.07474250346422195,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.7661099433898926,
              0.37188899517059326,
              -0.014627800323069096,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.662709951400757,
              0.36420801281929016,
              -0.07503700256347656,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.623159885406494,
              0.3576720058917999,
              -0.07154349982738495,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.596169948577881,
              0.31188899278640747,
              -0.10706199705600739,
              3.596169948577881,
              0.33595600724220276,
              -0.09145300090312958,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.8545498847961426,
              0.34992700815200806,
              -0.08422940224409103,
              3.8258299827575684,
              0.3407829999923706,
              -0.09592100232839584,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              3.683389902114868,
              0.3576720058917999,
              -0.07154349982738495,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.6834700107574463,
              0.3152740001678467,
              -0.11125099658966064,
              3.673340082168579,
              0.34142300486564636,
              -0.09604799747467041,
              3.7247300148010254,
              0.3576720058917999,
              -0.07154349982738495,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.724790096282959,
              0.31676000356674194,
              -0.1131180003285408,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.766079902648926,
              0.3576720058917999,
              -0.07154359668493271,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.7661099433898926,
              0.318246990442276,
              -0.11498499661684036,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.596169948577881,
              0.31188899278640747,
              -0.10706199705600739,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.8545498847961426,
              0.34992700815200806,
              -0.08422940224409103,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.724790096282959,
              0.31676000356674194,
              -0.1131180003285408,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.7661099433898926,
              0.318246990442276,
              -0.11498499661684036,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.7761900424957275,
              0.3430289924144745,
              -0.09769699722528458,
              3.7867701053619385,
              0.32324498891830444,
              -0.1211019977927208,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.766079902648926,
              0.3576720058917999,
              -0.07154359668493271,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.6628100872039795,
              0.3189600110054016,
              -0.11570499837398529,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.6232900619506836,
              0.3137870132923126,
              -0.10938400030136108,
              3.652169942855835,
              0.3410539925098419,
              -0.0956789031624794,
              3.596169948577881,
              0.26540398597717285,
              -0.13859400153160095,
              3.596169948577881,
              0.28903499245643616,
              -0.12233199924230576,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.854569911956787,
              0.3074299991130829,
              -0.1251250058412552,
              3.8258299827575684,
              0.29478898644447327,
              -0.1328040063381195,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              3.6834700107574463,
              0.3152740001678467,
              -0.11125099658966064,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.683419942855835,
              0.2666130065917969,
              -0.14217400550842285,
              3.6733899116516113,
              0.2929700016975403,
              -0.12962999939918518,
              3.724790096282959,
              0.31676000356674194,
              -0.1131180003285408,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.724760055541992,
              0.26700299978256226,
              -0.14311499893665314,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.7661099433898926,
              0.318246990442276,
              -0.11498499661684036,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.766089916229248,
              0.26739299297332764,
              -0.1440570056438446,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              4.118340015411377,
              0.17976699769496918,
              0.14523300528526306,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.118340015411377,
              0.17976699769496918,
              0.14523300528526306,
              3.596169948577881,
              0.26540398597717285,
              -0.13859400153160095,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.854569911956787,
              0.3074299991130829,
              -0.1251250058412552,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.724760055541992,
              0.26700299978256226,
              -0.14311499893665314,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.766089916229248,
              0.26739299297332764,
              -0.1440570056438446,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.776210069656372,
              0.29578500986099243,
              -0.13470600545406342,
              3.786760091781616,
              0.2695449888706207,
              -0.15098999440670013,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.7661099433898926,
              0.318246990442276,
              -0.11498499661684036,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.662760019302368,
              0.2684980034828186,
              -0.14862200617790222,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.6232199668884277,
              0.2662230134010315,
              -0.14123199880123138,
              3.6522300243377686,
              0.2923780083656311,
              -0.12856100499629974,
              3.596169948577881,
              0.20920200645923615,
              -0.14846499264240265,
              3.596169948577881,
              0.23844699561595917,
              -0.14846499264240265,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.8545498847961426,
              0.253383994102478,
              -0.14873699843883514,
              3.825819969177246,
              0.2387000024318695,
              -0.1510380059480667,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.683419942855835,
              0.2666130065917969,
              -0.14217400550842285,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.683389902114868,
              0.20894800126552582,
              -0.1510380059480667,
              3.67330002784729,
              0.23906299471855164,
              -0.15472599864006042,
              3.724760055541992,
              0.26700299978256226,
              -0.14311499893665314,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.7247300148010254,
              0.20894800126552582,
              -0.1510380059480667,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.766089916229248,
              0.26739299297332764,
              -0.1440570056438446,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.766079902648926,
              0.20894800126552582,
              -0.1510380059480667,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              4.207099914550781,
              0.16772200167179108,
              0.13543899357318878,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.207099914550781,
              0.14237700402736664,
              0.12189199775457382,
              3.596169948577881,
              0.20920200645923615,
              -0.14846499264240265,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.8545498847961426,
              0.253383994102478,
              -0.14873699843883514,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.7247300148010254,
              0.20894800126552582,
              -0.1510380059480667,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.766079902648926,
              0.20894800126552582,
              -0.1510380059480667,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.7761800289154053,
              0.23903299868106842,
              -0.15441499650478363,
              3.786750078201294,
              0.20828300714492798,
              -0.15779100358486176,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.766089916229248,
              0.26739299297332764,
              -0.1440570056438446,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.662709951400757,
              0.20822200179100037,
              -0.15841299295425415,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.623159885406494,
              0.20894800126552582,
              -0.1510380059480667,
              3.652129888534546,
              0.23906299471855164,
              -0.15472599864006042,
              4.207099914550781,
              0.12016300112009048,
              0.10366000235080719,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.207099914550781,
              0.10193099826574326,
              0.08144509792327881,
              3.596169948577881,
              0.15861299633979797,
              -0.12233199924230576,
              3.596169948577881,
              0.18224400281906128,
              -0.13859400153160095,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.8545498847961426,
              0.1942639946937561,
              -0.14873699843883514,
              3.8258299827575684,
              0.17996299266815186,
              -0.14476299285888672,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              3.683389902114868,
              0.20894800126552582,
              -0.1510380059480667,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.6834700107574463,
              0.15603800117969513,
              -0.12706300616264343,
              3.673340082168579,
              0.180090993642807,
              -0.14540399610996246,
              3.7247300148010254,
              0.20894800126552582,
              -0.1510380059480667,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.724790096282959,
              0.15488199889659882,
              -0.12915100157260895,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.766079902648926,
              0.20894800126552582,
              -0.1510380059480667,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.7661099433898926,
              0.1537259966135025,
              -0.13123799860477448,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              4.207099914550781,
              0.08838420361280441,
              0.05609999969601631,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.207099914550781,
              0.08004199713468552,
              0.028599200770258904,
              3.596169948577881,
              0.15861299633979797,
              -0.12233199924230576,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              3.8545498847961426,
              0.1942639946937561,
              -0.14873699843883514,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.724790096282959,
              0.15488199889659882,
              -0.12915100157260895,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.7661099433898926,
              0.1537259966135025,
              -0.13123799860477448,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.7761900424957275,
              0.1791819930076599,
              -0.14751799404621124,
              3.7867701053619385,
              0.14998799562454224,
              -0.13819700479507446,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.766079902648926,
              0.20894800126552582,
              -0.1510380059480667,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.6628100872039795,
              0.15333400666713715,
              -0.13217200338840485,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              3.6232900619506836,
              0.15719400346279144,
              -0.12497500330209732,
              3.652169942855835,
              0.18029099702835083,
              -0.14492100477218628,
              4.207099914550781,
              0.07722529768943787,
              -7.889730113674887e-7,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.207099914550781,
              0.08004230260848999,
              -0.028600700199604034,
              3.596169948577881,
              0.11169199645519257,
              -0.09145289659500122,
              3.596169948577881,
              0.13575899600982666,
              -0.10706199705600739,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.854569911956787,
              0.14021900296211243,
              -0.1251250058412552,
              3.8258299827575684,
              0.12828700244426727,
              -0.11638599634170532,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              3.6834700107574463,
              0.15603800117969513,
              -0.12706300616264343,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.683419942855835,
              0.10884799808263779,
              -0.09393899887800217,
              3.6733899116516113,
              0.13052299618721008,
              -0.11349000036716461,
              3.724790096282959,
              0.15488199889659882,
              -0.12915100157260895,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.724760055541992,
              0.10812699794769287,
              -0.09465979784727097,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.7661099433898926,
              0.1537259966135025,
              -0.13123799860477448,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.766089916229248,
              0.10740599781274796,
              -0.09538059681653976,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              4.207099914550781,
              0.0883847028017044,
              -0.05610150098800659,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.207099914550781,
              0.10193199664354324,
              -0.08144640177488327,
              3.596169948577881,
              0.11169199645519257,
              -0.09145289659500122,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              3.854569911956787,
              0.14021900296211243,
              -0.1251250058412552,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.724760055541992,
              0.10812699794769287,
              -0.09465979784727097,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.766089916229248,
              0.10740599781274796,
              -0.09538059681653976,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.776210069656372,
              0.126910999417305,
              -0.11803299933671951,
              3.786760091781616,
              0.10182499885559082,
              -0.10002200305461884,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.7661099433898926,
              0.1537259966135025,
              -0.13123799860477448,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.662760019302368,
              0.10361099988222122,
              -0.09814819693565369,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              3.6232199668884277,
              0.10956799983978271,
              -0.09321829676628113,
              3.6522300243377686,
              0.13128399848937988,
              -0.11253300309181213,
              4.207099914550781,
              0.12016300112009048,
              -0.10366100072860718,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.207079887390137,
              0.14237800240516663,
              -0.1218930035829544,
              3.596169948577881,
              0.08548679947853088,
              -0.04242609813809395,
              3.596169948577881,
              0.09225659817457199,
              -0.07032439857721329,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.8545498847961426,
              0.0977218970656395,
              -0.08422960340976715,
              3.825819969177246,
              0.08997630327939987,
              -0.07154379785060883,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.683419942855835,
              0.10884799808263779,
              -0.09393899887800217,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.683419942855835,
              0.08183939754962921,
              -0.043410200625658035,
              3.67330002784729,
              0.086708202958107,
              -0.07329019904136658,
              3.724760055541992,
              0.10812699794769287,
              -0.09465979784727097,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.724760055541992,
              0.08083979785442352,
              -0.043609101325273514,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.766089916229248,
              0.10740599781274796,
              -0.09538059681653976,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.766089916229248,
              0.07984019815921783,
              -0.04380809888243675,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              4.206980228424072,
              0.16772300004959106,
              -0.13544000685214996,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.206960201263428,
              0.19522400200366974,
              -0.14378200471401215,
              3.596169948577881,
              0.08548679947853088,
              -0.04242609813809395,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              3.8545498847961426,
              0.0977218970656395,
              -0.08422960340976715,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.724760055541992,
              0.08083979785442352,
              -0.043609101325273514,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.766089916229248,
              0.07984019815921783,
              -0.04380809888243675,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.7761800289154053,
              0.08698400110006332,
              -0.07314319908618927,
              3.786760091781616,
              0.07288020104169846,
              -0.04587079957127571,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.766089916229248,
              0.10740599781274796,
              -0.09538059681653976,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.662760019302368,
              0.07543040066957474,
              -0.04542560130357742,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              3.6232199668884277,
              0.0828389972448349,
              -0.0432111993432045,
              3.652129888534546,
              0.086708202958107,
              -0.07329019904136658,
              4.206960201263428,
              0.22382399439811707,
              -0.14659899473190308,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.206960201263428,
              0.2524240016937256,
              -0.14378200471401215,
              3.596169948577881,
              0.08587940037250519,
              0.0137429004535079,
              3.596169948577881,
              0.08587940037250519,
              -0.013743000105023384,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.854559898376465,
              0.07589209824800491,
              -0.029460500925779343,
              3.8258299827575684,
              0.07397539913654327,
              -0.014777200296521187,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.683419942855835,
              0.08183939754962921,
              -0.043410200625658035,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.6834700107574463,
              0.08051549643278122,
              0.014229499734938145,
              3.6733899116516113,
              0.07762569934129715,
              -0.014526399783790112,
              3.724760055541992,
              0.08083979785442352,
              -0.043609101325273514,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.724790096282959,
              0.07813730090856552,
              0.014428099617362022,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.766089916229248,
              0.07984019815921783,
              -0.04380809888243675,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.7661099433898926,
              0.07575900107622147,
              0.014626800082623959,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              4.206980228424072,
              0.27992498874664307,
              -0.13544000685214996,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.207079887390137,
              0.3052699863910675,
              -0.1218930035829544,
              3.596169948577881,
              0.08587940037250519,
              0.0137429004535079,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              3.854559898376465,
              0.07589209824800491,
              -0.029460500925779343,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.724790096282959,
              0.07813730090856552,
              0.014428099617362022,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.7661099433898926,
              0.07575900107622147,
              0.014626800082623959,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.776210069656372,
              0.07184109836816788,
              -0.015005799941718578,
              3.7867701053619385,
              0.06789640337228775,
              0.015384599566459656,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.766089916229248,
              0.07984019815921783,
              -0.04380809888243675,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.6628100872039795,
              0.07476489990949631,
              0.0148200998082757,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              3.6232900619506836,
              0.08289380371570587,
              0.014030800200998783,
              3.6522300243377686,
              0.07884380221366882,
              -0.014424500055611134,
              4.207099914550781,
              0.32748499512672424,
              -0.10366100072860718,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.207099914550781,
              0.3457159996032715,
              -0.08144620060920715,
              3.596169948577881,
              0.09225679934024811,
              0.07032430171966553,
              3.596169948577881,
              0.08548679947853088,
              0.04242590069770813,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.854559898376465,
              0.0758918970823288,
              0.02945910021662712,
              3.8258299827575684,
              0.07909020036458969,
              0.04395600035786629,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              3.6834700107574463,
              0.08051549643278122,
              0.014229499734938145,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.683389902114868,
              0.08997610211372375,
              0.07154300063848495,
              3.673340082168579,
              0.07862880080938339,
              0.04441859945654869,
              3.724790096282959,
              0.07813730090856552,
              0.014428099617362022,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.7247300148010254,
              0.0899759978055954,
              0.07154279947280884,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.7661099433898926,
              0.07575900107622147,
              0.014626800082623959,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.766079902648926,
              0.08997590094804764,
              0.07154270261526108,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              4.207099914550781,
              0.35926398634910583,
              -0.056101299822330475,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.207099914550781,
              0.3676060140132904,
              -0.028600500896573067,
              3.596169948577881,
              0.09225679934024811,
              0.07032430171966553,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              3.854559898376465,
              0.0758918970823288,
              0.02945910021662712,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.7247300148010254,
              0.0899759978055954,
              0.07154279947280884,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.766079902648926,
              0.08997590094804764,
              0.07154270261526108,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.7761900424957275,
              0.07636550068855286,
              0.0448370985686779,
              3.786750078201294,
              0.08399119973182678,
              0.07474140077829361,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.7661099433898926,
              0.07575900107622147,
              0.014626800082623959,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.662709951400757,
              0.08344029635190964,
              0.07503660023212433,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              3.623159885406494,
              0.08997619897127151,
              0.07154320180416107,
              3.652169942855835,
              0.0791407972574234,
              0.04431689903140068,
              4.207099914550781,
              0.37042298913002014,
              -5.207759841141524e-7,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.207099914550781,
              0.3676060140132904,
              0.02859950065612793,
              3.596169948577881,
              0.13575899600982666,
              0.10706199705600739,
              3.596169948577881,
              0.11169199645519257,
              0.0914527028799057,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.8545498847961426,
              0.09772130101919174,
              0.08422829955816269,
              3.8258299827575684,
              0.10686500370502472,
              0.09591999650001526,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.683389902114868,
              0.08997610211372375,
              0.07154300063848495,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.6834700107574463,
              0.13237500190734863,
              0.11125099658966064,
              3.673340082168579,
              0.10622499883174896,
              0.09604760259389877,
              3.7247300148010254,
              0.0899759978055954,
              0.07154279947280884,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.724790096282959,
              0.13088800013065338,
              0.1131180003285408,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.766079902648926,
              0.08997590094804764,
              0.07154270261526108,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.7661099433898926,
              0.12940099835395813,
              0.11498499661684036,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              4.207099914550781,
              0.35926398634910583,
              0.05610039830207825,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.207099914550781,
              0.34571701288223267,
              0.08144540339708328,
              3.596169948577881,
              0.13575899600982666,
              0.10706199705600739,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              3.8545498847961426,
              0.09772130101919174,
              0.08422829955816269,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.724790096282959,
              0.13088800013065338,
              0.1131180003285408,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.7661099433898926,
              0.12940099835395813,
              0.11498499661684036,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.766079902648926,
              0.08997590094804764,
              0.07154270261526108,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.6628100872039795,
              0.1286890059709549,
              0.1157039999961853,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.6232900619506836,
              0.1338610053062439,
              0.10938400030136108,
              3.652169942855835,
              0.10659399628639221,
              0.09567850083112717,
              4.207099914550781,
              0.32748499512672424,
              0.10366100072860718,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.207099914550781,
              0.3052699863910675,
              0.12189199775457382,
              3.596169948577881,
              0.18224400281906128,
              0.13859400153160095,
              3.596169948577881,
              0.15861299633979797,
              0.12233199924230576,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.854569911956787,
              0.14021800458431244,
              0.125123992562294,
              3.8258299827575684,
              0.15285800397396088,
              0.1328040063381195,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              3.6834700107574463,
              0.13237500190734863,
              0.11125099658966064,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.6733899116516113,
              0.15467900037765503,
              0.12962999939918518,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.6733899116516113,
              0.15467900037765503,
              0.12962999939918518,
              3.724790096282959,
              0.13088800013065338,
              0.1131180003285408,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.7348999977111816,
              0.15313300490379333,
              0.13240599632263184,
              3.724760055541992,
              0.18064500391483307,
              0.14311499893665314,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.7661099433898926,
              0.12940099835395813,
              0.11498499661684036,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.776210069656372,
              0.1518629938364029,
              0.13470500707626343,
              3.766089916229248,
              0.1802549958229065,
              0.14405600726604462,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              4.207099914550781,
              0.27992498874664307,
              0.13543899357318878,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.207099914550781,
              0.2524240016937256,
              0.14378200471401215,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.755959987640381,
              0.20863400399684906,
              0.1542229950428009,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.766089916229248,
              0.1802549958229065,
              0.14405600726604462,
              3.755959987640381,
              0.20863400399684906,
              0.1542229950428009,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.714639902114868,
              0.20859800279140472,
              0.1545889973640442,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.724760055541992,
              0.18064500391483307,
              0.14311499893665314,
              3.714639902114868,
              0.20859800279140472,
              0.1545889973640442,
              3.854569911956787,
              0.14021800458431244,
              0.125123992562294,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.662709951400757,
              0.23942700028419495,
              0.15841299295425415,
              3.652169942855835,
              0.2673580050468445,
              0.14492100477218628,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.652169942855835,
              0.2673580050468445,
              0.14492100477218628,
              3.623159885406494,
              0.2387000024318695,
              0.1510380059480667,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.786750078201294,
              0.08399119973182678,
              0.07474140077829361,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.7761900424957275,
              0.10461899638175964,
              0.09769610315561295,
              3.786750078201294,
              0.08399119973182678,
              0.07474140077829361,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.7661099433898926,
              0.12940099835395813,
              0.11498499661684036,
              3.75600004196167,
              0.15254199504852295,
              0.1334719955921173,
              3.7559800148010254,
              0.2682119905948639,
              0.1468539983034134,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.755959987640381,
              0.20863400399684906,
              0.1542229950428009,
              3.766079902648926,
              0.2387000024318695,
              0.1510380059480667,
              3.8545498847961426,
              0.19426299631595612,
              0.14873600006103516,
              3.825819969177246,
              0.20894800126552582,
              0.1510380059480667,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.6232199668884277,
              0.181426003575325,
              0.14123199880123138,
              3.6522300243377686,
              0.1552709937095642,
              0.12856000661849976,
              3.755959987640381,
              0.20863400399684906,
              0.1542229950428009,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.7348499298095703,
              0.20863400399684906,
              0.1542229950428009,
              3.745419979095459,
              0.17862099409103394,
              0.1496410071849823,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.8258299827575684,
              0.15285800397396088,
              0.1328040063381195,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.5983800888061523,
              0.19564799964427948,
              0.14275799691677094,
              3.596169948577881,
              0.20920200645923615,
              0.14846399426460266,
              3.598400115966797,
              0.22382399439811707,
              0.14659899473190308,
              4.060369968414307,
              0.23925699293613434,
              0.1566859930753708,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.079689979553223,
              0.2387000024318695,
              0.1510380059480667,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.041049957275391,
              0.2953679859638214,
              0.13384799659252167,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.099009990692139,
              0.23929400742053986,
              0.15706999599933624,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.118340015411377,
              0.2953679859638214,
              0.13384799659252167,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.137660026550293,
              0.297995001077652,
              0.13876299560070038,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.147500038146973,
              0.20867499709129333,
              0.1538109928369522,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.118340015411377,
              0.2387000024318695,
              0.1510380059480667,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.118340015411377,
              0.2953679859638214,
              0.13384799659252167,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.041049957275391,
              0.34114301204681396,
              0.09628059715032578,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.207469940185547,
              0.2941490113735199,
              0.13156700134277344,
              4.177579879760742,
              0.2953679859638214,
              0.13384799659252167,
              4.041049957275391,
              0.2953679859638214,
              0.13384799659252167,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.041049957275391,
              0.34114301204681396,
              0.09628059715032578,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.079689979553223,
              0.2387000024318695,
              0.1510380059480667,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.079689979553223,
              0.2953679859638214,
              0.13384799659252167,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.099009990692139,
              0.17800800502300262,
              0.15103299915790558,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.099009990692139,
              0.23929400742053986,
              0.15706999599933624,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.060369968414307,
              0.1781190037727356,
              0.15066400170326233,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.060369968414307,
              0.23925699293613434,
              0.1566859930753708,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.207469940185547,
              0.23844699561595917,
              0.14846399426460266,
              4.207469940185547,
              0.2671299874782562,
              0.14275899529457092,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              4.041049957275391,
              0.3690580129623413,
              0.044055599719285965,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.031579971313477,
              0.26877298951148987,
              0.14817599952220917,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              4.079689979553223,
              0.2953679859638214,
              0.13384799659252167,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.079689979553223,
              0.34114301204681396,
              0.09628059715032578,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.041049957275391,
              0.2953679859638214,
              0.13384799659252167,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              4.041049957275391,
              0.2953679859638214,
              0.13384799659252167,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.118340015411377,
              0.2953679859638214,
              0.13384799659252167,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.118340015411377,
              0.34114301204681396,
              0.09628059715032578,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.041049957275391,
              0.3690580129623413,
              0.044055599719285965,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.207469940185547,
              0.3391439914703369,
              0.09464020282030106,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              4.041049957275391,
              0.34114301204681396,
              0.09628059715032578,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.041049957275391,
              0.3690580129623413,
              0.044055599719285965,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              3.9808900356292725,
              0.3201049864292145,
              0.11731799691915512,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.118340015411377,
              0.34114301204681396,
              0.09628059715032578,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.137660026550293,
              0.3454520106315613,
              0.09981650114059448,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.177579879760742,
              0.2953679859638214,
              0.13384799659252167,
              4.207469940185547,
              0.2941490113735199,
              0.13156700134277344,
              4.207469940185547,
              0.318464994430542,
              0.11531999707221985,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.147500038146973,
              0.37763500213623047,
              0.01514859963208437,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.147500038146973,
              0.37763500213623047,
              0.01514859963208437,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.041049957275391,
              0.3748619854450226,
              -0.014876400120556355,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.137660026550293,
              0.297995001077652,
              0.13876299560070038,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.177579879760742,
              0.2953679859638214,
              0.13384799659252167,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.079689979553223,
              0.34114301204681396,
              0.09628059715032578,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.031579971313477,
              0.32205700874328613,
              0.1196959987282753,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              4.021719932556152,
              0.2982670068740845,
              0.13927200436592102,
              4.079689979553223,
              0.34114301204681396,
              0.09628059715032578,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.079689979553223,
              0.3690580129623413,
              0.044055599719285965,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.118340015411377,
              0.34114301204681396,
              0.09628059715032578,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.118340015411377,
              0.3690580129623413,
              0.044055599719285965,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.041049957275391,
              0.3748619854450226,
              -0.014876400120556355,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.207469940185547,
              0.366582989692688,
              0.04330499842762947,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.041049957275391,
              0.3690580129623413,
              0.044055599719285965,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.041049957275391,
              0.3748619854450226,
              -0.014876400120556355,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              3.9808900356292725,
              0.3576720058917999,
              0.07154279947280884,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.118340015411377,
              0.3690580129623413,
              0.044055599719285965,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.177579879760742,
              0.34114301204681396,
              0.09628059715032578,
              4.207469940185547,
              0.3391439914703369,
              0.09464020282030106,
              4.207469940185547,
              0.3553920090198517,
              0.07032380253076553,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.041049957275391,
              0.3576720058917999,
              -0.07154359668493271,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.079689979553223,
              0.3690580129623413,
              0.044055599719285965,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.031579971313477,
              0.360385000705719,
              0.07299260050058365,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              4.021719932556152,
              0.34589800238609314,
              0.10018300265073776,
              4.079689979553223,
              0.3690580129623413,
              0.044055599719285965,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.079689979553223,
              0.3748619854450226,
              -0.014876400120556355,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.118340015411377,
              0.3690580129623413,
              0.044055599719285965,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.118340015411377,
              0.3748619854450226,
              -0.014876400120556355,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.041049957275391,
              0.3576720058917999,
              -0.07154359668493271,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.207469940185547,
              0.3722890019416809,
              -0.014623000286519527,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.041049957275391,
              0.3748619854450226,
              -0.014876400120556355,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.041049957275391,
              0.3576720058917999,
              -0.07154359668493271,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              3.9808900356292725,
              0.3748619854450226,
              0.014875399880111217,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.118340015411377,
              0.3748619854450226,
              -0.014876400120556355,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.207469940185547,
              0.366582989692688,
              0.04330499842762947,
              4.207469940185547,
              0.3722890019416809,
              0.01462200004607439,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.041049957275391,
              0.3201049864292145,
              -0.1173190027475357,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.079689979553223,
              0.3748619854450226,
              -0.014876400120556355,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.031579971313477,
              0.37792301177978516,
              0.01517689973115921,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              4.021719932556152,
              0.3749440014362335,
              0.04584119841456413,
              4.079689979553223,
              0.3748619854450226,
              -0.014876400120556355,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.079689979553223,
              0.3576720058917999,
              -0.07154359668493271,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.118340015411377,
              0.3748619854450226,
              -0.014876400120556355,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.118340015411377,
              0.3576720058917999,
              -0.07154359668493271,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.041049957275391,
              0.3201049864292145,
              -0.1173190027475357,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.207469940185547,
              0.3553920090198517,
              -0.07032469660043716,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.041049957275391,
              0.3576720058917999,
              -0.07154359668493271,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.041049957275391,
              0.3201049864292145,
              -0.1173190027475357,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              3.9808900356292725,
              0.3690580129623413,
              -0.04405660182237625,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.118340015411377,
              0.3576720058917999,
              -0.07154359668493271,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.137660026550293,
              0.36258798837661743,
              -0.07417099922895432,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.207469940185547,
              0.3722890019416809,
              -0.014623000286519527,
              4.207469940185547,
              0.366582989692688,
              -0.0433058999478817,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.147500038146973,
              0.2966809868812561,
              -0.1363060027360916,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.147500038146973,
              0.2966809868812561,
              -0.1363060027360916,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.041049957275391,
              0.26787999272346497,
              -0.14523400366306305,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.079689979553223,
              0.3576720058917999,
              -0.07154359668493271,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.031579971313477,
              0.3720009922981262,
              -0.04494930058717728,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              4.021719932556152,
              0.38098299503326416,
              -0.015479300171136856,
              4.079689979553223,
              0.3576720058917999,
              -0.07154359668493271,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.079689979553223,
              0.3201049864292145,
              -0.1173190027475357,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.118340015411377,
              0.3576720058917999,
              -0.07154359668493271,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.118340015411377,
              0.3201049864292145,
              -0.1173190027475357,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.041049957275391,
              0.26787999272346497,
              -0.14523400366306305,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.207469940185547,
              0.318464994430542,
              -0.11531999707221985,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.041049957275391,
              0.3201049864292145,
              -0.1173190027475357,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.041049957275391,
              0.26787999272346497,
              -0.14523400366306305,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              3.9808900356292725,
              0.34114301204681396,
              -0.09628129750490189,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.118340015411377,
              0.3201049864292145,
              -0.1173190027475357,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.207469940185547,
              0.3553920090198517,
              -0.07032469660043716,
              4.207469940185547,
              0.3391439914703369,
              -0.09464090317487717,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.041049957275391,
              0.20894800126552582,
              -0.1510380059480667,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.079689979553223,
              0.3201049864292145,
              -0.1173190027475357,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.031579971313477,
              0.34351998567581177,
              -0.09823240339756012,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              4.021719932556152,
              0.36309701204299927,
              -0.07444319874048233,
              4.079689979553223,
              0.3201049864292145,
              -0.1173190027475357,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.079689979553223,
              0.26787999272346497,
              -0.14523400366306305,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.118340015411377,
              0.3201049864292145,
              -0.1173190027475357,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.118340015411377,
              0.26787999272346497,
              -0.14523400366306305,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.041049957275391,
              0.20894800126552582,
              -0.1510380059480667,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.207409858703613,
              0.2671299874782562,
              -0.14275899529457092,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.041049957275391,
              0.26787999272346497,
              -0.14523400366306305,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.041049957275391,
              0.20894800126552582,
              -0.1510380059480667,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              3.9808900356292725,
              0.2953670024871826,
              -0.13384799659252167,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.118340015411377,
              0.26787999272346497,
              -0.14523400366306305,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.207469940185547,
              0.318464994430542,
              -0.11531999707221985,
              4.20743989944458,
              0.2941479980945587,
              -0.13156799972057343,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.041049957275391,
              0.1522810012102127,
              -0.13384799659252167,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.079689979553223,
              0.26787999272346497,
              -0.14523400366306305,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.031579971313477,
              0.29681700468063354,
              -0.13656100630760193,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              4.021719932556152,
              0.3240070044994354,
              -0.12207400053739548,
              4.079689979553223,
              0.26787999272346497,
              -0.14523400366306305,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.079689979553223,
              0.20894800126552582,
              -0.1510380059480667,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.118340015411377,
              0.26787999272346497,
              -0.14523400366306305,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.118340015411377,
              0.20894800126552582,
              -0.1510380059480667,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.041049957275391,
              0.1522810012102127,
              -0.13384799659252167,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.207409858703613,
              0.20920200645923615,
              -0.14846499264240265,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.041049957275391,
              0.20894800126552582,
              -0.1510380059480667,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.041049957275391,
              0.1522810012102127,
              -0.13384799659252167,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              3.9808900356292725,
              0.2387000024318695,
              -0.1510380059480667,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.118340015411377,
              0.20894800126552582,
              -0.1510380059480667,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.137660026550293,
              0.20840199291706085,
              -0.1565849930047989,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.207409858703613,
              0.2671299874782562,
              -0.14275899529457092,
              4.207409858703613,
              0.23844699561595917,
              -0.14846499264240265,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.147500038146973,
              0.12577499449253082,
              -0.11947300285100937,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.147500038146973,
              0.12577499449253082,
              -0.11947300285100937,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.041049957275391,
              0.10650599747896194,
              -0.09628160297870636,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.079689979553223,
              0.20894800126552582,
              -0.1510380059480667,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.031579971313477,
              0.23900200426578522,
              -0.1540990024805069,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              4.021719932556152,
              0.26966598629951477,
              -0.15112000703811646,
              4.079689979553223,
              0.20894800126552582,
              -0.1510380059480667,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.079689979553223,
              0.1522810012102127,
              -0.13384799659252167,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.118340015411377,
              0.20894800126552582,
              -0.1510380059480667,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.118340015411377,
              0.1522810012102127,
              -0.13384799659252167,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.041049957275391,
              0.10650599747896194,
              -0.09628160297870636,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.20743989944458,
              0.1535000056028366,
              -0.13156799972057343,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.041049957275391,
              0.1522810012102127,
              -0.13384799659252167,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.041049957275391,
              0.10650599747896194,
              -0.09628160297870636,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              3.9808900356292725,
              0.17976799607276917,
              -0.14523400366306305,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.118340015411377,
              0.1522810012102127,
              -0.13384799659252167,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.207409858703613,
              0.20920200645923615,
              -0.14846499264240265,
              4.207409858703613,
              0.1805189996957779,
              -0.14275899529457092,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.041049957275391,
              0.07859069854021072,
              -0.04405679926276207,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.079689979553223,
              0.1522810012102127,
              -0.13384799659252167,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.031579971313477,
              0.17887499928474426,
              -0.14817699790000916,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              4.021719932556152,
              0.20834499597549438,
              -0.1571590006351471,
              4.079689979553223,
              0.1522810012102127,
              -0.13384799659252167,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.079689979553223,
              0.10650599747896194,
              -0.09628160297870636,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.118340015411377,
              0.1522810012102127,
              -0.13384799659252167,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.118340015411377,
              0.10650599747896194,
              -0.09628160297870636,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.041049957275391,
              0.07859069854021072,
              -0.04405679926276207,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.207469940185547,
              0.10850399732589722,
              -0.09464109688997269,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.041049957275391,
              0.10650599747896194,
              -0.09628160297870636,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.041049957275391,
              0.07859069854021072,
              -0.04405679926276207,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              3.9808900356292725,
              0.12754300236701965,
              -0.1173190027475357,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.118340015411377,
              0.10650599747896194,
              -0.09628160297870636,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.20743989944458,
              0.1535000056028366,
              -0.13156799972057343,
              4.207469940185547,
              0.12918399274349213,
              -0.11531999707221985,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.041049957275391,
              0.07278619706630707,
              0.014875099994242191,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.079689979553223,
              0.10650599747896194,
              -0.09628160297870636,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.031579971313477,
              0.12559199333190918,
              -0.11969699710607529,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              4.021719932556152,
              0.14938199520111084,
              -0.139273002743721,
              4.079689979553223,
              0.10650599747896194,
              -0.09628160297870636,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.079689979553223,
              0.07859069854021072,
              -0.04405679926276207,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.118340015411377,
              0.10650599747896194,
              -0.09628160297870636,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.118340015411377,
              0.07859060168266296,
              -0.04405679926276207,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.041049957275391,
              0.07278619706630707,
              0.014875099994242191,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.207469940185547,
              0.0810651034116745,
              -0.043306201696395874,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.041049957275391,
              0.07859069854021072,
              -0.04405679926276207,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.041049957275391,
              0.07278619706630707,
              0.014875099994242191,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              3.9808900356292725,
              0.08997630327939987,
              -0.07154390215873718,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.118340015411377,
              0.07859060168266296,
              -0.04405679926276207,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.137660026550293,
              0.07325699925422668,
              -0.045674800872802734,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.207469940185547,
              0.10850399732589722,
              -0.09464109688997269,
              4.207469940185547,
              0.09225670248270035,
              -0.07032489776611328,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.147500038146973,
              0.07592339813709259,
              0.04486420005559921,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.147500038146973,
              0.07592339813709259,
              0.04486420005559921,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.041049957275391,
              0.08997569978237152,
              0.0715423971414566,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.079689979553223,
              0.07859069854021072,
              -0.04405679926276207,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.031579971313477,
              0.08726400136947632,
              -0.07299359887838364,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              4.021719932556152,
              0.10175099968910217,
              -0.10018400102853775,
              4.079689979553223,
              0.07859069854021072,
              -0.04405679926276207,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.079689979553223,
              0.07278619706630707,
              0.014875099994242191,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.118340015411377,
              0.07859060168266296,
              -0.04405679926276207,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.118340015411377,
              0.07278610020875931,
              0.014875099994242191,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.041049957275391,
              0.08997569978237152,
              0.0715423971414566,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.207469940185547,
              0.07535950094461441,
              0.014621700160205364,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.041049957275391,
              0.07278619706630707,
              0.014875099994242191,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.041049957275391,
              0.08997569978237152,
              0.0715423971414566,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              3.9808900356292725,
              0.07278630137443542,
              -0.01487670000642538,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.118340015411377,
              0.07278610020875931,
              0.014875099994242191,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.207469940185547,
              0.0810651034116745,
              -0.043306201696395874,
              4.207469940185547,
              0.07535959780216217,
              -0.014623199589550495,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.021719932556152,
              0.12364000082015991,
              0.12207300215959549,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.041049957275391,
              0.12754200398921967,
              0.11731799691915512,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.079689979553223,
              0.07278619706630707,
              0.014875099994242191,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.031579971313477,
              0.06972560286521912,
              -0.015178199857473373,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              4.021719932556152,
              0.07270459830760956,
              -0.045842401683330536,
              4.079689979553223,
              0.07278619706630707,
              0.014875099994242191,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.079689979553223,
              0.08997569978237152,
              0.0715423971414566,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.118340015411377,
              0.07278610020875931,
              0.014875099994242191,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.118340015411377,
              0.08997560292482376,
              0.0715423971414566,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.060369968414307,
              0.12394200265407562,
              0.12170500308275223,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.041049957275391,
              0.12754200398921967,
              0.11731799691915512,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.207469940185547,
              0.092256098985672,
              0.07032349705696106,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.041049957275391,
              0.08997569978237152,
              0.0715423971414566,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.041049957275391,
              0.12754200398921967,
              0.11731799691915512,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              3.9808900356292725,
              0.07859030365943909,
              0.04405530169606209,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.118340015411377,
              0.08997560292482376,
              0.0715423971414566,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.207469940185547,
              0.07535950094461441,
              0.014621700160205364,
              4.207469940185547,
              0.08106470108032227,
              0.043304599821567535,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.079689979553223,
              0.17976699769496918,
              0.14523300528526306,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.099009990692139,
              0.17800800502300262,
              0.15103299915790558,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.079689979553223,
              0.08997569978237152,
              0.0715423971414566,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.031579971313477,
              0.07564730197191238,
              0.04494800046086311,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              4.021719932556152,
              0.06666480004787445,
              0.015478000044822693,
              4.079689979553223,
              0.08997569978237152,
              0.0715423971414566,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.079689979553223,
              0.12754200398921967,
              0.11731799691915512,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.021719932556152,
              0.12364000082015991,
              0.12207300215959549,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.118340015411377,
              0.08997560292482376,
              0.0715423971414566,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.118340015411377,
              0.12754200398921967,
              0.11731799691915512,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.021719932556152,
              0.17798100411891937,
              0.15111899375915527,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.041049957275391,
              0.17976699769496918,
              0.14523300528526306,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              3.9808900356292725,
              0.20894800126552582,
              0.1510380059480667,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.207469940185547,
              0.12918299436569214,
              0.11531899869441986,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.041049957275391,
              0.12754200398921967,
              0.11731799691915512,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.041049957275391,
              0.17976699769496918,
              0.14523300528526306,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              3.9808900356292725,
              0.10650499910116196,
              0.0962802991271019,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.118340015411377,
              0.12754200398921967,
              0.11731799691915512,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.137660026550293,
              0.1240060031414032,
              0.12162700295448303,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.207469940185547,
              0.092256098985672,
              0.07032349705696106,
              4.207469940185547,
              0.10850399732589722,
              0.09463980048894882,
              4.060369968414307,
              0.12394200265407562,
              0.12170500308275223,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.079689979553223,
              0.12754200398921967,
              0.11731799691915512,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.031579971313477,
              0.10412699729204178,
              0.09823130071163177,
              4.021719932556152,
              0.12364000082015991,
              0.12207300215959549,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.021719932556152,
              0.12364000082015991,
              0.12207300215959549,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              4.021719932556152,
              0.08455099910497665,
              0.07444199919700623,
              4.079689979553223,
              0.12754200398921967,
              0.11731799691915512,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.079689979553223,
              0.17976699769496918,
              0.14523300528526306,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.021719932556152,
              0.17798100411891937,
              0.15111899375915527,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.118340015411377,
              0.12754200398921967,
              0.11731799691915512,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.118340015411377,
              0.17976699769496918,
              0.14523300528526306,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              4.207469940185547,
              0.1805180013179779,
              0.14275899529457092,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              3.9808900356292725,
              0.15228000283241272,
              0.13384699821472168,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.060369968414307,
              0.12394200265407562,
              0.12170500308275223,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.060369968414307,
              0.1781190037727356,
              0.15066400170326233,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.207469940185547,
              0.12918299436569214,
              0.11531899869441986,
              4.207469940185547,
              0.15349900722503662,
              0.13156700134277344,
              3.673340082168579,
              0.2675580084323883,
              0.14540299773216248,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              3.683389902114868,
              0.2387000024318695,
              0.1510380059480667,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.6834700107574463,
              0.291610985994339,
              0.12706199288368225,
              4.118340015411377,
              0.17976699769496918,
              0.14523300528526306,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.137660026550293,
              0.1781489998102188,
              0.15056699514389038,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              4.207469940185547,
              0.23844699561595917,
              0.14846399426460266,
              4.177579879760742,
              0.2387000024318695,
              0.1510380059480667,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266,
              4.137660026550293,
              0.1240060031414032,
              0.12162700295448303,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.137660026550293,
              0.1781489998102188,
              0.15056699514389038,
              4.127820014953613,
              0.15096600353717804,
              0.13630500435829163,
              3.9808900356292725,
              0.26788100600242615,
              0.14523300528526306,
              3.949709892272949,
              0.2534329891204834,
              0.14885400235652924,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.949709892272949,
              0.13950400054454803,
              0.12619200348854065,
              4.060369968414307,
              0.23925699293613434,
              0.1566859930753708,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.207099914550781,
              0.19522300362586975,
              0.14378100633621216,
              4.207099914550781,
              0.22382399439811707,
              0.1465979963541031,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266,
              4.207099914550781,
              0.19522300362586975,
              0.14378100633621216,
              4.207469940185547,
              0.1805180013179779,
              0.14275899529457092,
              4.207099914550781,
              0.16772200167179108,
              0.13543899357318878,
              4.099009990692139,
              0.12369699776172638,
              0.1220029965043068,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.099009990692139,
              0.17800800502300262,
              0.15103299915790558,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.079689979553223,
              0.2387000024318695,
              0.1510380059480667,
              4.079689979553223,
              0.17976699769496918,
              0.14523300528526306,
              4.079689979553223,
              0.2387000024318695,
              0.1510380059480667,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.079689979553223,
              0.17976699769496918,
              0.14523300528526306,
              3.6733899116516113,
              0.15467900037765503,
              0.12962999939918518,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              4.060369968414307,
              0.1781190037727356,
              0.15066400170326233,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.041049957275391,
              0.17976699769496918,
              0.14523300528526306,
              4.050529956817627,
              0.15094199776649475,
              0.13635000586509705,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              3.693510055541992,
              0.07824479788541794,
              0.0444812998175621,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.693510055541992,
              0.10595899820327759,
              0.09633190184831619,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.079689979553223,
              0.2953679859638214,
              0.13384799659252167,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              3.662760019302368,
              0.1791509985923767,
              0.14862200617790222,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.67330002784729,
              0.2085849940776825,
              0.15472500026226044,
              3.662709951400757,
              0.23942700028419495,
              0.15841299295425415,
              3.652129888534546,
              0.2085849940776825,
              0.15472500026226044,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.6935598850250244,
              0.0765371024608612,
              -0.014615500345826149,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.7559800148010254,
              0.07705900073051453,
              0.044679999351501465,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              3.734869956970215,
              0.0775694027543068,
              0.04457860067486763,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.7559800148010254,
              0.1051350012421608,
              0.09720689803361893,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              3.745450019836426,
              0.12619400024414062,
              0.11886099725961685,
              3.734869956970215,
              0.10550300031900406,
              0.09683900326490402,
              3.7454099655151367,
              0.0843311995267868,
              0.07455989718437195,
              4.050529956817627,
              0.2086700052022934,
              0.15386199951171875,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              4.041049957275391,
              0.17976699769496918,
              0.14523300528526306,
              4.041049957275391,
              0.2387000024318695,
              0.1510380059480667,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              4.041049957275391,
              0.17976699769496918,
              0.14523300528526306,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.6934800148010254,
              0.08682870119810104,
              -0.07322590053081512,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.6935598850250244,
              0.12984399497509003,
              -0.1143449991941452,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.693510055541992,
              0.17993000149726868,
              -0.1457580029964447,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.6934800148010254,
              0.2390500009059906,
              -0.15458999574184418,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.6935598850250244,
              0.29350000619888306,
              -0.13058499991893768,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.693510055541992,
              0.34168899059295654,
              -0.09633249789476395,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.6934800148010254,
              0.3608199954032898,
              0.07322529703378677,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.704129934310913,
              0.29540398716926575,
              0.13413099944591522,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.6935598850250244,
              0.3178049921989441,
              0.1143449991941452,
              3.704129934310913,
              0.29540398716926575,
              0.13413099944591522,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.704129934310913,
              0.29540398716926575,
              0.13413099944591522,
              3.693510055541992,
              0.26771900057792664,
              0.14575700461864471,
              3.8258299827575684,
              0.07909020036458969,
              0.04395600035786629,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.75600004196167,
              0.07324399799108505,
              -0.014885599724948406,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.745450019836426,
              0.07075399905443192,
              0.015140100382268429,
              3.7348999977111816,
              0.07445819675922394,
              -0.014783999882638454,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.786760091781616,
              0.1781029999256134,
              0.15098899602890015,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.7973198890686035,
              0.2086150050163269,
              0.15441399812698364,
              3.8258299827575684,
              0.10686500370502472,
              0.09591999650001526,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              3.7867701053619385,
              0.1244020015001297,
              0.1211009994149208,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.8258299827575684,
              0.12828600406646729,
              0.11638499796390533,
              3.797329902648926,
              0.1512719988822937,
              0.13577300310134888,
              3.8258299827575684,
              0.17996299266815186,
              -0.14476299285888672,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              3.786750078201294,
              0.08399119973182678,
              0.07474140077829361,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.825819969177246,
              0.08997579663991928,
              0.07154250144958496,
              3.7973198890686035,
              0.10425099730491638,
              0.09806440025568008,
              3.8258299827575684,
              0.07397539913654327,
              -0.014777200296521187,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              3.7867701053619385,
              0.06789640337228775,
              0.015384599566459656,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.8258299827575684,
              0.07397539913654327,
              0.014775799587368965,
              3.7973198890686035,
              0.07585450261831284,
              0.044938698410987854,
              3.825819969177246,
              0.08997630327939987,
              -0.07154379785060883,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.786760091781616,
              0.07288020104169846,
              -0.04587079957127571,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.8258299827575684,
              0.07909049838781357,
              -0.04395730048418045,
              3.797329902648926,
              0.07062540203332901,
              -0.015107500366866589,
              3.8258299827575684,
              0.12828700244426727,
              -0.11638599634170532,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.786760091781616,
              0.10182499885559082,
              -0.10002200305461884,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.8258299827575684,
              0.10686600208282471,
              -0.09592120349407196,
              3.7973198890686035,
              0.08698400110006332,
              -0.07314330339431763,
              3.8258299827575684,
              0.36855798959732056,
              -0.043957099318504333,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              3.7867701053619385,
              0.14998799562454224,
              -0.13819700479507446,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.8258299827575684,
              0.15285900235176086,
              -0.1328040063381195,
              3.797329902648926,
              0.12615099549293518,
              -0.11898799985647202,
              3.825819969177246,
              0.2387000024318695,
              -0.1510380059480667,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              3.786750078201294,
              0.20828300714492798,
              -0.15779100358486176,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.825819969177246,
              0.20894800126552582,
              -0.1510380059480667,
              3.7973198890686035,
              0.17898300290107727,
              -0.14799900352954865,
              3.8258299827575684,
              0.29478898644447327,
              -0.1328040063381195,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.786760091781616,
              0.2695449888706207,
              -0.15098999440670013,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.8258299827575684,
              0.2676849961280823,
              -0.14476299285888672,
              3.7973198890686035,
              0.23903299868106842,
              -0.15441499650478363,
              3.8258299827575684,
              0.3407829999923706,
              -0.09592100232839584,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              3.7867701053619385,
              0.32324498891830444,
              -0.1211019977927208,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.8258299827575684,
              0.31936201453208923,
              -0.11638599634170532,
              3.797329902648926,
              0.29637598991394043,
              -0.13577300310134888,
              3.8258299827575684,
              0.3736729919910431,
              0.014775999821722507,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.786750078201294,
              0.36365699768066406,
              -0.07474250346422195,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.825819969177246,
              0.3576720058917999,
              -0.07154359668493271,
              3.7973198890686035,
              0.34339699149131775,
              -0.09806539863348007,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.825819969177246,
              0.20894800126552582,
              0.1510380059480667,
              3.7867701053619385,
              0.3797520101070404,
              -0.015385700389742851,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.8258299827575684,
              0.3736729919910431,
              -0.014777000062167645,
              3.7973198890686035,
              0.3717939853668213,
              -0.0449398010969162,
              3.825819969177246,
              0.3576720058917999,
              0.0715428963303566,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              3.786760091781616,
              0.3747679889202118,
              0.04586980119347572,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.8258299827575684,
              0.36855798959732056,
              0.043956298381090164,
              3.797329902648926,
              0.37702301144599915,
              0.015106399543583393,
              3.8258299827575684,
              0.31936201453208923,
              0.11638499796390533,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              3.786760091781616,
              0.3458240032196045,
              0.10002099722623825,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.797329902648926,
              0.32149800658226013,
              0.11898700147867203,
              3.8258299827575684,
              0.3407829999923706,
              0.09592030197381973,
              3.7973198890686035,
              0.3606649935245514,
              0.07314229756593704,
              3.8258299827575684,
              0.26768600940704346,
              0.14476299285888672,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.8258299827575684,
              0.29478999972343445,
              0.1328040063381195,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.8545498847961426,
              0.2533850073814392,
              0.14873600006103516,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.9808900356292725,
              0.15228000283241272,
              0.13384699821472168,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              3.755959987640381,
              0.08715389668941498,
              -0.0730523020029068,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.745419979095459,
              0.07428880035877228,
              -0.04555189982056618,
              3.7348499298095703,
              0.08715389668941498,
              -0.07305219769477844,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.75600004196167,
              0.12779000401496887,
              -0.11693400144577026,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.745419979095459,
              0.10287199914455414,
              -0.09902749955654144,
              3.7348999977111816,
              0.1285489946603775,
              -0.11597999930381775,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.7559800148010254,
              0.17943699657917023,
              -0.1468539983034134,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.745450019836426,
              0.1513720005750656,
              -0.135684996843338,
              3.734869956970215,
              0.17963600158691406,
              -0.14637300372123718,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.755959987640381,
              0.23901399970054626,
              -0.1542229950428009,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.7454099655151367,
              0.2083210051059723,
              -0.1574079990386963,
              3.7348499298095703,
              0.23901399970054626,
              -0.1542229950428009,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.75600004196167,
              0.2951059937477112,
              -0.1334729939699173,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.745419979095459,
              0.2690269947052002,
              -0.1496410071849823,
              3.7348999977111816,
              0.2945150136947632,
              -0.13240699470043182,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.7559800148010254,
              0.34251299500465393,
              -0.0972077026963234,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.745450019836426,
              0.3214539885520935,
              -0.11886200308799744,
              3.734869956970215,
              0.34214499592781067,
              -0.0968398004770279,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.7559800148010254,
              0.3705889880657196,
              -0.04468090087175369,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.7454099655151367,
              0.36331701278686523,
              -0.07456079870462418,
              3.734869956970215,
              0.37007901072502136,
              -0.0445794016122818,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.75600004196167,
              0.37440401315689087,
              0.014884700067341328,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.745450019836426,
              0.3768939971923828,
              -0.015141000039875507,
              3.7348999977111816,
              0.3731899857521057,
              0.01478319987654686,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.755959987640381,
              0.360495001077652,
              0.07305149734020233,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.745419979095459,
              0.3733600080013275,
              0.04555109888315201,
              3.7348499298095703,
              0.360495001077652,
              0.07305149734020233,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.75600004196167,
              0.31985899806022644,
              0.11693300306797028,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.745419979095459,
              0.3447760045528412,
              0.09902679920196533,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.7559800148010254,
              0.2682119905948639,
              0.1468539983034134,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.7454099655151367,
              0.23932799696922302,
              0.1574070006608963,
              3.8545498847961426,
              0.19426299631595612,
              0.14873600006103516,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              4.060369968414307,
              0.1781190037727356,
              0.15066400170326233,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              4.070209980010986,
              0.15094199776649475,
              0.13635000586509705,
              4.079689979553223,
              0.17976699769496918,
              0.14523300528526306,
              4.070209980010986,
              0.2086700052022934,
              0.15386199951171875,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.854569911956787,
              0.14021800458431244,
              0.125123992562294,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.8579399585723877,
              0.14035199582576752,
              0.12492600083351135,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.854569911956787,
              0.14021800458431244,
              0.125123992562294,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              3.8545498847961426,
              0.09772130101919174,
              0.08422829955816269,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.854559898376465,
              0.0758918970823288,
              0.02945910021662712,
              3.8579399585723877,
              0.07646430283784866,
              0.02931240014731884,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.854559898376465,
              0.0758918970823288,
              0.02945910021662712,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.854559898376465,
              0.07589209824800491,
              -0.029460500925779343,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8579399585723877,
              0.0764641985297203,
              -0.02931229956448078,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.854559898376465,
              0.07589209824800491,
              -0.029460500925779343,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.8545498847961426,
              0.0977218970656395,
              -0.08422960340976715,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.8579399585723877,
              0.0988985002040863,
              -0.08347330242395401,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8545498847961426,
              0.0977218970656395,
              -0.08422960340976715,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.854569911956787,
              0.14021900296211243,
              -0.1251250058412552,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.8545498847961426,
              0.1942639946937561,
              -0.14873699843883514,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.8579399585723877,
              0.1945129930973053,
              -0.14735999703407288,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.8545498847961426,
              0.1942639946937561,
              -0.14873699843883514,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.8545498847961426,
              0.253383994102478,
              -0.14873699843883514,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.8579399585723877,
              0.25313600897789,
              -0.14735999703407288,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.8545498847961426,
              0.253383994102478,
              -0.14873699843883514,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.854569911956787,
              0.3074299991130829,
              -0.1251250058412552,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8579399585723877,
              0.3072969913482666,
              -0.12492600083351135,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.854569911956787,
              0.3074299991130829,
              -0.1251250058412552,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8545498847961426,
              0.34992700815200806,
              -0.08422940224409103,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.854559898376465,
              0.371755987405777,
              -0.029460199177265167,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.854559898376465,
              0.371755987405777,
              0.029459400102496147,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8579399585723877,
              0.37118399143218994,
              0.029311200603842735,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.854559898376465,
              0.371755987405777,
              0.029459400102496147,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.8545498847961426,
              0.34992700815200806,
              0.08422870188951492,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.8579399585723877,
              0.3487499952316284,
              0.0834721028804779,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8545498847961426,
              0.34992700815200806,
              0.08422870188951492,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.854569911956787,
              0.3074299991130829,
              0.1251250058412552,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.714639902114868,
              0.20859800279140472,
              0.1545889973640442,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.7040600776672363,
              0.2393999993801117,
              0.15814100205898285,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.8579399585723877,
              0.3072969913482666,
              0.12492500245571136,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.854569911956787,
              0.3074299991130829,
              0.1251250058412552,
              3.8579399585723877,
              0.25313600897789,
              0.14735999703407288,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.8545498847961426,
              0.2533850073814392,
              0.14873600006103516,
              3.9808900356292725,
              0.10650499910116196,
              0.0962802991271019,
              4.01186990737915,
              0.10412699729204178,
              0.09823130071163177,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.8545498847961426,
              0.19426299631595612,
              0.14873600006103516,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.9808900356292725,
              0.20894800126552582,
              0.1510380059480667,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              3.825819969177246,
              0.2387000024318695,
              0.1510380059480667,
              3.8258299827575684,
              0.26768600940704346,
              0.14476299285888672,
              3.7973198890686035,
              0.26866599917411804,
              0.14799900352954865,
              4.021719932556152,
              0.12364000082015991,
              0.12207300215959549,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.031579971313477,
              0.1508300006389618,
              0.13655999302864075,
              4.021719932556152,
              0.17798100411891937,
              0.15111899375915527,
              4.01186990737915,
              0.1508300006389618,
              0.13655999302864075,
              4.099009990692139,
              0.12369699776172638,
              0.1220029965043068,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.118340015411377,
              0.12754200398921967,
              0.11731799691915512,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.118340015411377,
              0.08997560292482376,
              0.0715423971414566,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.118340015411377,
              0.07278610020875931,
              0.014875099994242191,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.118340015411377,
              0.07859060168266296,
              -0.04405679926276207,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.118340015411377,
              0.10650599747896194,
              -0.09628160297870636,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.118340015411377,
              0.1522810012102127,
              -0.13384799659252167,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.118340015411377,
              0.20894800126552582,
              -0.1510380059480667,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.118340015411377,
              0.26787999272346497,
              -0.14523400366306305,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.118340015411377,
              0.3201049864292145,
              -0.1173190027475357,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.118340015411377,
              0.3576720058917999,
              -0.07154359668493271,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.118340015411377,
              0.3748619854450226,
              -0.014876400120556355,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.118340015411377,
              0.3690580129623413,
              0.044055599719285965,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.118340015411377,
              0.34114301204681396,
              0.09628059715032578,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.099009990692139,
              0.2982249855995178,
              0.13919299840927124,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.118340015411377,
              0.2953679859638214,
              0.13384799659252167,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.070209980010986,
              0.10431099683046341,
              0.09808050096035004,
              4.060369968414307,
              0.12394200265407562,
              0.12170500308275223,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.060369968414307,
              0.12394200265407562,
              0.12170500308275223,
              4.050529956817627,
              0.10431099683046341,
              0.09808050096035004,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.070209980010986,
              0.0758747011423111,
              0.044879000633955,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              4.060369968414307,
              0.08497019857168198,
              0.07421790063381195,
              4.050529956817627,
              0.0758747011423111,
              0.044879000633955,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              3.9808900356292725,
              0.07859030365943909,
              0.04405530169606209,
              4.01186990737915,
              0.07564719766378403,
              0.04494800046086311,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              3.9808900356292725,
              0.07278630137443542,
              -0.01487670000642538,
              4.01186990737915,
              0.06972560286521912,
              -0.015178199857473373,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              3.9808900356292725,
              0.08997630327939987,
              -0.07154390215873718,
              4.01186990737915,
              0.08726400136947632,
              -0.07299359887838364,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              3.9808900356292725,
              0.12754300236701965,
              -0.1173190027475357,
              4.01186990737915,
              0.12559199333190918,
              -0.11969699710607529,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              3.9808900356292725,
              0.17976799607276917,
              -0.14523400366306305,
              4.01186990737915,
              0.17887499928474426,
              -0.14817699790000916,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              3.9808900356292725,
              0.2387000024318695,
              -0.1510380059480667,
              4.01186990737915,
              0.23900200426578522,
              -0.1540990024805069,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              3.9808900356292725,
              0.2953670024871826,
              -0.13384799659252167,
              4.01186990737915,
              0.29681700468063354,
              -0.13656100630760193,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              3.9808900356292725,
              0.34114301204681396,
              -0.09628129750490189,
              4.01186990737915,
              0.34351998567581177,
              -0.09823240339756012,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              3.9808900356292725,
              0.3690580129623413,
              -0.04405660182237625,
              4.01186990737915,
              0.3720009922981262,
              -0.04494930058717728,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              3.9808900356292725,
              0.3748619854450226,
              0.014875399880111217,
              4.01186990737915,
              0.37792301177978516,
              0.01517689973115921,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              3.9808900356292725,
              0.3576720058917999,
              0.07154279947280884,
              4.01186990737915,
              0.360385000705719,
              0.07299260050058365,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              3.9808900356292725,
              0.3201049864292145,
              0.11731799691915512,
              4.01186990737915,
              0.32205700874328613,
              0.1196959987282753,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              3.9808900356292725,
              0.26788100600242615,
              0.14523300528526306,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              4.021719932556152,
              0.17798100411891937,
              0.15111899375915527,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.031579971313477,
              0.2086459994316101,
              0.1540980041027069,
              4.021719932556152,
              0.23930299282073975,
              0.1571590006351471,
              4.01186990737915,
              0.2086459994316101,
              0.1540980041027069,
              4.108870029449463,
              0.10416200011968613,
              0.09820269793272018,
              4.099009990692139,
              0.12369699776172638,
              0.1220029965043068,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              4.099009990692139,
              0.12369699776172638,
              0.1220029965043068,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              3.949709892272949,
              0.13950400054454803,
              0.12619200348854065,
              3.9808900356292725,
              0.15228000283241272,
              0.13384699821472168,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              4.108870029449463,
              0.07569029927253723,
              0.04493499919772148,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.070209980010986,
              0.06996209919452667,
              -0.015154900029301643,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.060369968414307,
              0.06713789701461792,
              0.015431400388479233,
              4.050529956817627,
              0.06996209919452667,
              -0.015154900029301643,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.070209980010986,
              0.08747349679470062,
              -0.07288160175085068,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.060369968414307,
              0.07315939664840698,
              -0.045704398304224014,
              4.050529956817627,
              0.08747360110282898,
              -0.07288160175085068,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.070209980010986,
              0.12574300169944763,
              -0.11951299756765366,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.060369968414307,
              0.10211800038814545,
              -0.0998821035027504,
              4.050529956817627,
              0.12574300169944763,
              -0.11951299756765366,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.070209980010986,
              0.17894400656223297,
              -0.1479489952325821,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.060369968414307,
              0.14960600435733795,
              -0.1388539969921112,
              4.050529956817627,
              0.17894400656223297,
              -0.1479489952325821,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.070209980010986,
              0.23897799849510193,
              -0.15386199951171875,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.060369968414307,
              0.20839199423789978,
              -0.1566859930753708,
              4.050529956817627,
              0.23897799849510193,
              -0.15386199951171875,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.070209980010986,
              0.2967050075531006,
              -0.13635100424289703,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.060369968414307,
              0.26952800154685974,
              -0.15066500008106232,
              4.050529956817627,
              0.2967050075531006,
              -0.13635100424289703,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.070209980010986,
              0.34333598613739014,
              -0.09808160364627838,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.060369968414307,
              0.3237060010433197,
              -0.12170600146055222,
              4.050529956817627,
              0.34333598613739014,
              -0.09808160364627838,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.070209980010986,
              0.37177300453186035,
              -0.044880300760269165,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.060369968414307,
              0.36267799139022827,
              -0.07421910017728806,
              4.050529956817627,
              0.37177300453186035,
              -0.044880300760269165,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.070209980010986,
              0.3776859939098358,
              0.01515359990298748,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.060369968414307,
              0.38051000237464905,
              -0.015432699583470821,
              4.050529956817627,
              0.3776859939098358,
              0.01515359990298748,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.070209980010986,
              0.3601750135421753,
              0.07288060337305069,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.060369968414307,
              0.3744890093803406,
              0.04570319876074791,
              4.050529956817627,
              0.3601750135421753,
              0.07288060337305069,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.070209980010986,
              0.3219060003757477,
              0.11951199918985367,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              4.060369968414307,
              0.3455300033092499,
              0.09988120198249817,
              4.050529956817627,
              0.3219060003757477,
              0.11951199918985367,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              4.060369968414307,
              0.23925699293613434,
              0.1566859930753708,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              4.070209980010986,
              0.26870399713516235,
              0.1479489952325821,
              4.060369968414307,
              0.29804301261901855,
              0.13885299861431122,
              4.050529956817627,
              0.26870399713516235,
              0.1479489952325821,
              3.9808900356292725,
              0.26788100600242615,
              0.14523300528526306,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              4.01186990737915,
              0.26877298951148987,
              0.14817599952220917,
              3.9808900356292725,
              0.20894800126552582,
              0.1510380059480667,
              3.949709892272949,
              0.19421400129795074,
              0.14885400235652924,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.9808900356292725,
              0.10650499910116196,
              0.0962802991271019,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              3.9808900356292725,
              0.15228000283241272,
              0.13384699821472168,
              3.949709892272949,
              0.13950400054454803,
              0.12619200348854065,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.9808900356292725,
              0.07859030365943909,
              0.04405530169606209,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              3.9808900356292725,
              0.10650499910116196,
              0.0962802991271019,
              3.949709892272949,
              0.09763109683990479,
              0.08431830257177353,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.9808900356292725,
              0.07278630137443542,
              -0.01487670000642538,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              3.9808900356292725,
              0.07859030365943909,
              0.04405530169606209,
              3.949709892272949,
              0.07496970146894455,
              0.029608100652694702,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.9808900356292725,
              0.08997630327939987,
              -0.07154390215873718,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              3.9808900356292725,
              0.07278630137443542,
              -0.01487670000642538,
              3.949709892272949,
              0.07496999949216843,
              -0.02960970066487789,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.9808900356292725,
              0.12754300236701965,
              -0.1173190027475357,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              3.9808900356292725,
              0.08997630327939987,
              -0.07154390215873718,
              3.949709892272949,
              0.0976317971944809,
              -0.08431970328092575,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.9808900356292725,
              0.17976799607276917,
              -0.14523400366306305,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              3.9808900356292725,
              0.12754300236701965,
              -0.1173190027475357,
              3.949709892272949,
              0.13950499892234802,
              -0.12619300186634064,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.9808900356292725,
              0.2387000024318695,
              -0.1510380059480667,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              3.9808900356292725,
              0.17976799607276917,
              -0.14523400366306305,
              3.949709892272949,
              0.19421499967575073,
              -0.14885400235652924,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.9808900356292725,
              0.2953670024871826,
              -0.13384799659252167,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              3.9808900356292725,
              0.2387000024318695,
              -0.1510380059480667,
              3.949709892272949,
              0.2534329891204834,
              -0.14885400235652924,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.9808900356292725,
              0.34114301204681396,
              -0.09628129750490189,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              3.9808900356292725,
              0.2953670024871826,
              -0.13384799659252167,
              3.949709892272949,
              0.3081429898738861,
              -0.12619300186634064,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.9808900356292725,
              0.3690580129623413,
              -0.04405660182237625,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              3.9808900356292725,
              0.34114301204681396,
              -0.09628129750490189,
              3.949709892272949,
              0.3500170111656189,
              -0.08431950211524963,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.9808900356292725,
              0.3748619854450226,
              0.014875399880111217,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              3.9808900356292725,
              0.3690580129623413,
              -0.04405660182237625,
              3.949709892272949,
              0.3726780116558075,
              -0.029609400779008865,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.9808900356292725,
              0.3576720058917999,
              0.07154279947280884,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              3.9808900356292725,
              0.3748619854450226,
              0.014875399880111217,
              3.949709892272949,
              0.3726780116558075,
              0.02960840053856373,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.9808900356292725,
              0.3201049864292145,
              0.11731799691915512,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              3.9808900356292725,
              0.3576720058917999,
              0.07154279947280884,
              3.949709892272949,
              0.3500170111656189,
              0.08431869745254517,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.9808900356292725,
              0.26788100600242615,
              0.14523300528526306,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.137660026550293,
              0.1240060031414032,
              0.12162700295448303,
              4.127820014953613,
              0.1043509989976883,
              0.09804820269346237,
              3.9808900356292725,
              0.3201049864292145,
              0.11731799691915512,
              3.949709892272949,
              0.3081440031528473,
              0.12619200348854065,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              3.949709892272949,
              0.19421400129795074,
              0.14885400235652924,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.9808900356292725,
              0.20894800126552582,
              0.1510380059480667,
              4.108870029449463,
              0.06977029889822006,
              -0.015173699706792831,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.108870029449463,
              0.08730360120534897,
              -0.07297240197658539,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.108870029449463,
              0.1256210058927536,
              -0.11966200172901154,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.108870029449463,
              0.1788879930973053,
              -0.1481339931488037,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.108870029449463,
              0.23899699747562408,
              -0.15405400097370148,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.108870029449463,
              0.2967959940433502,
              -0.13652099668979645,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.108870029449463,
              0.3434849977493286,
              -0.09820389747619629,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.108870029449463,
              0.3719579875469208,
              -0.044936299324035645,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.108870029449463,
              0.37787801027297974,
              0.015172500163316727,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.108870029449463,
              0.3603450059890747,
              0.0729714035987854,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.108870029449463,
              0.3220280110836029,
              0.11966100335121155,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.099009990692139,
              0.2982249855995178,
              0.13919299840927124,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.099009990692139,
              0.2982249855995178,
              0.13919299840927124,
              4.099009990692139,
              0.23929400742053986,
              0.15706999599933624,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.108870029449463,
              0.26875999569892883,
              0.14813299477100372,
              4.099009990692139,
              0.2982249855995178,
              0.13919299840927124,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.147500038146973,
              0.07592339813709259,
              0.04486420005559921,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.127820014953613,
              0.07592350244522095,
              0.04486420005559921,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.946589946746826,
              0.14035099744796753,
              0.12492500245571136,
              3.949709892272949,
              0.13950400054454803,
              0.12619200348854065,
              4.099009990692139,
              0.17800800502300262,
              0.15103299915790558,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              4.108870029449463,
              0.15085099637508392,
              0.13651999831199646,
              4.118340015411377,
              0.17976699769496918,
              0.14523300528526306,
              4.108870029449463,
              0.20865100622177124,
              0.15405400097370148,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.949709892272949,
              0.09763109683990479,
              0.08431830257177353,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.949709892272949,
              0.07496970146894455,
              0.029608100652694702,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.949709892272949,
              0.07496999949216843,
              -0.02960970066487789,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.949709892272949,
              0.0976317971944809,
              -0.08431970328092575,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.949709892272949,
              0.13950499892234802,
              -0.12619300186634064,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.949709892272949,
              0.19421499967575073,
              -0.14885400235652924,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.949709892272949,
              0.2534329891204834,
              -0.14885400235652924,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.949709892272949,
              0.3081429898738861,
              -0.12619300186634064,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.949709892272949,
              0.3500170111656189,
              -0.08431950211524963,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.949709892272949,
              0.3726780116558075,
              -0.029609400779008865,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.949709892272949,
              0.3726780116558075,
              0.02960840053856373,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.949709892272949,
              0.3500170111656189,
              0.08431869745254517,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.949709892272949,
              0.3081440031528473,
              0.12619200348854065,
              4.446829795837402,
              0.3724600076675415,
              -2.137760048981363e-7,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.949709892272949,
              0.2534329891204834,
              0.14885400235652924,
              3.949709892272949,
              0.09763109683990479,
              0.08431830257177353,
              3.9808900356292725,
              0.10650499910116196,
              0.0962802991271019,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.8258299827575684,
              0.10686500370502472,
              0.09591999650001526,
              3.8545498847961426,
              0.09772130101919174,
              0.08422829955816269,
              3.8560400009155273,
              0.1299850046634674,
              0.09384019672870636,
              4.137660026550293,
              0.07325699925422668,
              -0.045674800872802734,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              4.127820014953613,
              0.07001280039548874,
              -0.015149899758398533,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.137660026550293,
              0.07325699925422668,
              -0.045674800872802734,
              4.127820014953613,
              0.08751849830150604,
              -0.07285760343074799,
              4.147500038146973,
              0.12577499449253082,
              -0.11947300285100937,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.127820014953613,
              0.12577499449253082,
              -0.11947300285100937,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.137660026550293,
              0.20840199291706085,
              -0.1565849930047989,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.127820014953613,
              0.17895899713039398,
              -0.1479009985923767,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.137660026550293,
              0.20840199291706085,
              -0.1565849930047989,
              4.127820014953613,
              0.238973006606102,
              -0.1538109928369522,
              4.147500038146973,
              0.2966809868812561,
              -0.1363060027360916,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.127820014953613,
              0.2966809868812561,
              -0.1363060027360916,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.137660026550293,
              0.36258798837661743,
              -0.07417099922895432,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.127820014953613,
              0.34329700469970703,
              -0.09804929792881012,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.137660026550293,
              0.36258798837661743,
              -0.07417099922895432,
              4.127820014953613,
              0.371724009513855,
              -0.044865500181913376,
              4.147500038146973,
              0.37763500213623047,
              0.01514859963208437,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.127820014953613,
              0.37763500213623047,
              0.01514859963208437,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.137660026550293,
              0.3454520106315613,
              0.09981650114059448,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.127820014953613,
              0.3601300120353699,
              0.07285650074481964,
              4.137660026550293,
              0.297995001077652,
              0.13876299560070038,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.147500038146973,
              0.3218730092048645,
              0.11947300285100937,
              4.137660026550293,
              0.3454520106315613,
              0.09981650114059448,
              4.127820014953613,
              0.3218730092048645,
              0.11947300285100937,
              4.147500038146973,
              0.26868900656700134,
              0.14790000021457672,
              4.137660026550293,
              0.297995001077652,
              0.13876299560070038,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.137660026550293,
              0.297995001077652,
              0.13876299560070038,
              4.127820014953613,
              0.26868900656700134,
              0.14790000021457672,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.137660026550293,
              0.1781489998102188,
              0.15056699514389038,
              4.147500038146973,
              0.20867499709129333,
              0.1538109928369522,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              4.147500038146973,
              0.20867499709129333,
              0.1538109928369522,
              4.137660026550293,
              0.23924699425697327,
              0.1565839946269989,
              4.127820014953613,
              0.20867499709129333,
              0.1538109928369522,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.9808900356292725,
              0.15228000283241272,
              0.13384699821472168,
              3.9808900356292725,
              0.17976699769496918,
              0.14523300528526306,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.9484899044036865,
              0.17303800582885742,
              0.12260700017213821,
              3.949709892272949,
              0.19421400129795074,
              0.14885400235652924,
              3.8258299827575684,
              0.15285800397396088,
              0.1328040063381195,
              3.854569911956787,
              0.14021800458431244,
              0.125123992562294,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.9808900356292725,
              0.12754200398921967,
              0.11731799691915512,
              3.949709892272949,
              0.13950400054454803,
              0.12619200348854065,
              3.9484899044036865,
              0.12998400628566742,
              0.09383949637413025,
              3.946589946746826,
              0.09889820218086243,
              0.08347219973802567,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.949709892272949,
              0.09763109683990479,
              0.08431830257177353,
              3.949709892272949,
              0.07496970146894455,
              0.029608100652694702,
              3.9808900356292725,
              0.07859030365943909,
              0.04405530169606209,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.9808900356292725,
              0.08997569978237152,
              0.0715423971414566,
              3.949709892272949,
              0.09763109683990479,
              0.08431830257177353,
              3.9484899044036865,
              0.10121600329875946,
              0.05078529939055443,
              3.946589946746826,
              0.07646399736404419,
              0.029311100021004677,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.949709892272949,
              0.07496970146894455,
              0.029608100652694702,
              3.949709892272949,
              0.07496999949216843,
              -0.02960970066487789,
              3.9808900356292725,
              0.07278630137443542,
              -0.01487670000642538,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.9808900356292725,
              0.07278610020875931,
              0.014875099994242191,
              3.949709892272949,
              0.07496970146894455,
              0.029608100652694702,
              3.9484899044036865,
              0.09111429750919342,
              -3.694800057019165e-7,
              3.946589946746826,
              0.07646410167217255,
              -0.029312100261449814,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.949709892272949,
              0.07496999949216843,
              -0.02960970066487789,
              3.949709892272949,
              0.0976317971944809,
              -0.08431970328092575,
              3.9808900356292725,
              0.08997630327939987,
              -0.07154390215873718,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.9808900356292725,
              0.07859069854021072,
              -0.04405679926276207,
              3.949709892272949,
              0.07496999949216843,
              -0.02960970066487789,
              3.9484899044036865,
              0.10121600329875946,
              -0.050786200910806656,
              3.946589946746826,
              0.09889829903841019,
              -0.0834731012582779,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.949709892272949,
              0.0976317971944809,
              -0.08431970328092575,
              3.949709892272949,
              0.13950499892234802,
              -0.12619300186634064,
              3.9808900356292725,
              0.12754300236701965,
              -0.1173190027475357,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.9808900356292725,
              0.10650599747896194,
              -0.096281498670578,
              3.949709892272949,
              0.0976317971944809,
              -0.08431970328092575,
              3.9484899044036865,
              0.12998400628566742,
              -0.09384030103683472,
              3.946589946746826,
              0.14035099744796753,
              -0.12492600083351135,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.949709892272949,
              0.13950499892234802,
              -0.12619300186634064,
              3.949709892272949,
              0.19421499967575073,
              -0.14885400235652924,
              3.9808900356292725,
              0.17976799607276917,
              -0.14523400366306305,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.9808900356292725,
              0.1522810012102127,
              -0.13384799659252167,
              3.949709892272949,
              0.13950499892234802,
              -0.12619300186634064,
              3.9484899044036865,
              0.17303800582885742,
              -0.1226079985499382,
              3.946589946746826,
              0.1945119947195053,
              -0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.949709892272949,
              0.19421499967575073,
              -0.14885400235652924,
              3.949709892272949,
              0.2534329891204834,
              -0.14885400235652924,
              3.9808900356292725,
              0.2387000024318695,
              -0.1510380059480667,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.9808900356292725,
              0.20894800126552582,
              -0.1510380059480667,
              3.949709892272949,
              0.19421499967575073,
              -0.14885400235652924,
              3.9484899044036865,
              0.22382399439811707,
              -0.13270999491214752,
              3.946589946746826,
              0.25313600897789,
              -0.14735999703407288,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.949709892272949,
              0.2534329891204834,
              -0.14885400235652924,
              3.949709892272949,
              0.3081429898738861,
              -0.12619300186634064,
              3.9808900356292725,
              0.2953670024871826,
              -0.13384799659252167,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.9808900356292725,
              0.26787999272346497,
              -0.14523400366306305,
              3.949709892272949,
              0.2534329891204834,
              -0.14885400235652924,
              3.9484899044036865,
              0.2746100127696991,
              -0.1226079985499382,
              3.946589946746826,
              0.3072969913482666,
              -0.12492600083351135,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.949709892272949,
              0.3081429898738861,
              -0.12619300186634064,
              3.949709892272949,
              0.3500170111656189,
              -0.08431950211524963,
              3.9808900356292725,
              0.34114301204681396,
              -0.09628129750490189,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.9808900356292725,
              0.3201049864292145,
              -0.1173190027475357,
              3.949709892272949,
              0.3081429898738861,
              -0.12619300186634064,
              3.9484899044036865,
              0.3176639974117279,
              -0.09384039789438248,
              3.946589946746826,
              0.3487499952316284,
              -0.0834731012582779,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.949709892272949,
              0.3500170111656189,
              -0.08431950211524963,
              3.949709892272949,
              0.3726780116558075,
              -0.029609400779008865,
              3.9808900356292725,
              0.3690580129623413,
              -0.04405660182237625,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.9808900356292725,
              0.3576720058917999,
              -0.07154359668493271,
              3.949709892272949,
              0.3500170111656189,
              -0.08431950211524963,
              3.9484899044036865,
              0.34643200039863586,
              -0.050786200910806656,
              3.946589946746826,
              0.37118399143218994,
              -0.029312100261449814,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.949709892272949,
              0.3726780116558075,
              -0.029609400779008865,
              3.949709892272949,
              0.3726780116558075,
              0.02960840053856373,
              3.9808900356292725,
              0.3748619854450226,
              0.014875399880111217,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.9808900356292725,
              0.3748619854450226,
              -0.014876400120556355,
              3.949709892272949,
              0.3726780116558075,
              -0.029609400779008865,
              3.9484899044036865,
              0.3565340042114258,
              -3.378389976660401e-7,
              3.946589946746826,
              0.37118399143218994,
              0.029311399906873703,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.949709892272949,
              0.3726780116558075,
              0.02960840053856373,
              3.949709892272949,
              0.3500170111656189,
              0.08431869745254517,
              3.9808900356292725,
              0.3576720058917999,
              0.07154279947280884,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.9808900356292725,
              0.3690580129623413,
              0.044055599719285965,
              3.949709892272949,
              0.3726780116558075,
              0.02960840053856373,
              3.9484899044036865,
              0.34643200039863586,
              0.050785500556230545,
              3.946589946746826,
              0.3487499952316284,
              0.08347240090370178,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.949709892272949,
              0.3500170111656189,
              0.08431869745254517,
              3.949709892272949,
              0.3081440031528473,
              0.12619200348854065,
              3.9808900356292725,
              0.3201049864292145,
              0.11731799691915512,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.9808900356292725,
              0.34114301204681396,
              0.09628059715032578,
              3.949709892272949,
              0.3500170111656189,
              0.08431869745254517,
              3.9484899044036865,
              0.3176639974117279,
              0.0938396006822586,
              3.946589946746826,
              0.3072969913482666,
              0.12492500245571136,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.949709892272949,
              0.3081440031528473,
              0.12619200348854065,
              3.949709892272949,
              0.2534329891204834,
              0.14885400235652924,
              3.9808900356292725,
              0.26788100600242615,
              0.14523300528526306,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.9808900356292725,
              0.2953679859638214,
              0.13384799659252167,
              3.949709892272949,
              0.3081440031528473,
              0.12619200348854065,
              3.9484899044036865,
              0.2746100127696991,
              0.1226079985499382,
              3.946589946746826,
              0.25313600897789,
              0.14735999703407288,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.949709892272949,
              0.2534329891204834,
              0.14885400235652924,
              3.949709892272949,
              0.19421400129795074,
              0.14885400235652924,
              3.9808900356292725,
              0.20894800126552582,
              0.1510380059480667,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.9808900356292725,
              0.2387000024318695,
              0.1510380059480667,
              3.949709892272949,
              0.2534329891204834,
              0.14885400235652924,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              4.446829795837402,
              0.3289259970188141,
              0.10510200262069702,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.3724600076675415,
              -2.137760048981363e-7,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              3.8258299827575684,
              0.15285800397396088,
              0.1328040063381195,
              3.8560400009155273,
              0.1730390042066574,
              0.1226079985499382,
              3.8258299827575684,
              0.17996199429035187,
              0.14476199448108673,
              3.9484899044036865,
              0.22382399439811707,
              0.13270899653434753,
              3.946589946746826,
              0.1945119947195053,
              0.14735999703407288,
              3.949709892272949,
              0.19421400129795074,
              0.14885400235652924,
              4.446829795837402,
              0.22382399439811707,
              0.14863599836826324,
              4.172019958496094,
              0.1669429987668991,
              0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.172019958496094,
              0.1669429987668991,
              0.13732199370861053,
              4.446829795837402,
              0.11872199922800064,
              0.10510200262069702,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.11872199922800064,
              0.10510200262069702,
              4.446829795837402,
              0.07518749684095383,
              -2.492130022346828e-7,
              4.446829795837402,
              0.11872199922800064,
              0.10510200262069702,
              4.172019958496094,
              0.08650189638137817,
              0.05688050016760826,
              4.446829795837402,
              0.07518749684095383,
              -2.492130022346828e-7,
              3.724790096282959,
              0.2927669882774353,
              0.12915000319480896,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.734869956970215,
              0.26801300048828125,
              0.14637300372123718,
              3.745450019836426,
              0.2962769865989685,
              0.135684996843338,
              3.7348999977111816,
              0.3190999925136566,
              0.11597999930381775,
              3.8579399585723877,
              0.1945129930973053,
              0.14735999703407288,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              3.8545498847961426,
              0.19426299631595612,
              0.14873600006103516,
              3.8258299827575684,
              0.07397539913654327,
              -0.014777200296521187,
              3.854559898376465,
              0.07589209824800491,
              -0.029460500925779343,
              3.8560400009155273,
              0.09111440181732178,
              -5.738299933000235e-7,
              3.704129934310913,
              0.12729500234127045,
              0.11745999753475189,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.724790096282959,
              0.13088800013065338,
              0.1131180003285408,
              3.7146999835968018,
              0.1535560041666031,
              0.13165299594402313,
              3.7040600776672363,
              0.08368109911680222,
              0.07490760087966919,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.7247300148010254,
              0.0899759978055954,
              0.07154279947280884,
              3.714669942855835,
              0.10559099912643433,
              0.09670069813728333,
              3.704129934310913,
              0.072530597448349,
              0.015002699568867683,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.724790096282959,
              0.07813730090856552,
              0.014428099617362022,
              3.714669942855835,
              0.0777330994606018,
              0.044582899659872055,
              3.704090118408203,
              0.0746385008096695,
              -0.045555900782346725,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.724760055541992,
              0.08083979785442352,
              -0.043609101325273514,
              3.7146999835968018,
              0.07531999796628952,
              -0.014717300422489643,
              3.704090118408203,
              0.10306300222873688,
              -0.09873440116643906,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.724760055541992,
              0.10812699794769287,
              -0.09465979784727097,
              3.714639902114868,
              0.0868287980556488,
              -0.07322590053081512,
              3.704129934310913,
              0.15224500000476837,
              -0.13413099944591522,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.724790096282959,
              0.15488199889659882,
              -0.12915100157260895,
              3.7146999835968018,
              0.12908299267292023,
              -0.11530099809169769,
              3.7040600776672363,
              0.20824900269508362,
              -0.15814200043678284,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.7247300148010254,
              0.20894800126552582,
              -0.1510380059480667,
              3.714669942855835,
              0.17972999811172485,
              -0.1462399959564209,
              3.704090118408203,
              0.26882898807525635,
              -0.1493529975414276,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.724760055541992,
              0.26700299978256226,
              -0.14311499893665314,
              3.714639902114868,
              0.2390500009059906,
              -0.15458999574184418,
              3.704129934310913,
              0.3203530013561249,
              -0.11746100336313248,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.724790096282959,
              0.31676000356674194,
              -0.1131180003285408,
              3.7146999835968018,
              0.29409199953079224,
              -0.13165299594402313,
              3.7040600776672363,
              0.36396700143814087,
              -0.07490819692611694,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.7247300148010254,
              0.3576720058917999,
              -0.07154349982738495,
              3.714669942855835,
              0.342058002948761,
              -0.09670130163431168,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.724790096282959,
              0.3695110082626343,
              -0.014428899623453617,
              3.714669942855835,
              0.3699150085449219,
              -0.044583600014448166,
              3.704090118408203,
              0.3730100095272064,
              0.04555530101060867,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.724760055541992,
              0.36680901050567627,
              0.0436084009706974,
              3.7146999835968018,
              0.3723280131816864,
              0.014716600067913532,
              3.704090118408203,
              0.34458601474761963,
              0.0987337976694107,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.724760055541992,
              0.33952200412750244,
              0.09465920180082321,
              3.714639902114868,
              0.3608199954032898,
              0.07322529703378677,
              3.704129934310913,
              0.29540398716926575,
              0.13413099944591522,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.714669942855835,
              0.2679179906845093,
              0.1462389975786209,
              3.724790096282959,
              0.2927669882774353,
              0.12915000319480896,
              3.7146999835968018,
              0.3185659945011139,
              0.1152999997138977,
              3.8579399585723877,
              0.3487499952316284,
              -0.0834731012582779,
              3.8545498847961426,
              0.34992700815200806,
              -0.08422940224409103,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.825819969177246,
              0.3576720058917999,
              0.0715428963303566,
              3.8545498847961426,
              0.34992700815200806,
              0.08422870188951492,
              3.8560400009155273,
              0.34643200039863586,
              0.05078519880771637,
              3.8258299827575684,
              0.36855798959732056,
              -0.043957099318504333,
              3.854559898376465,
              0.371755987405777,
              -0.029460199177265167,
              3.8560400009155273,
              0.34643200039863586,
              -0.050786200910806656,
              3.8258299827575684,
              0.3407829999923706,
              -0.09592100232839584,
              3.8545498847961426,
              0.34992700815200806,
              -0.08422940224409103,
              3.8560400009155273,
              0.3176639974117279,
              -0.09384030103683472,
              3.8258299827575684,
              0.3736729919910431,
              0.014775999821722507,
              3.854559898376465,
              0.371755987405777,
              0.029459400102496147,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.8579399585723877,
              0.14035199582576752,
              -0.12492600083351135,
              3.854569911956787,
              0.14021900296211243,
              -0.1251250058412552,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.825819969177246,
              0.2387000024318695,
              -0.1510380059480667,
              3.8545498847961426,
              0.253383994102478,
              -0.14873699843883514,
              3.8560400009155273,
              0.22382399439811707,
              -0.13270999491214752,
              3.8258299827575684,
              0.17996299266815186,
              -0.14476299285888672,
              3.8545498847961426,
              0.1942639946937561,
              -0.14873699843883514,
              3.8560400009155273,
              0.1730390042066574,
              -0.1226079985499382,
              3.8258299827575684,
              0.29478898644447327,
              -0.1328040063381195,
              3.854569911956787,
              0.3074299991130829,
              -0.1251250058412552,
              3.8560400009155273,
              0.2746100127696991,
              -0.1226079985499382,
              3.8258299827575684,
              0.12828700244426727,
              -0.11638599634170532,
              3.854569911956787,
              0.14021900296211243,
              -0.1251250058412552,
              3.8560400009155273,
              0.12998400628566742,
              -0.09384050220251083,
              3.8579399585723877,
              0.09889879822731018,
              0.08347319811582565,
              3.8545498847961426,
              0.09772130101919174,
              0.08422829955816269,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.825819969177246,
              0.08997630327939987,
              -0.07154379785060883,
              3.8545498847961426,
              0.0977218970656395,
              -0.08422960340976715,
              3.8560400009155273,
              0.10121600329875946,
              -0.050786398351192474,
              3.8258299827575684,
              0.07909020036458969,
              0.04395600035786629,
              3.854559898376465,
              0.0758918970823288,
              0.02945910021662712,
              3.8560400009155273,
              0.10121700167655945,
              0.050786200910806656,
              3.8258299827575684,
              0.26768600940704346,
              0.14476299285888672,
              3.8545498847961426,
              0.2533850073814392,
              0.14873600006103516,
              3.8560400009155273,
              0.2746100127696991,
              0.12260700017213821,
              3.8258299827575684,
              0.31936201453208923,
              0.11638499796390533,
              3.854569911956787,
              0.3074299991130829,
              0.1251250058412552,
              3.8560400009155273,
              0.3176639974117279,
              0.09383939951658249,
              3.8579399585723877,
              0.37118399143218994,
              -0.029312200844287872,
              3.854559898376465,
              0.371755987405777,
              -0.029460199177265167,
              3.8560400009155273,
              0.3565340042114258,
              -4.95781989684474e-7,
              3.825819969177246,
              0.20894800126552582,
              0.1510380059480667,
              3.8545498847961426,
              0.19426299631595612,
              0.14873600006103516,
              3.8560400009155273,
              0.22382499277591705,
              0.13270899653434753,
              4.446829795837402,
              0.3289259970188141,
              0.10510200262069702,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              4.172019958496094,
              0.28070399165153503,
              0.13732199370861053,
              4.172019958496094,
              0.3611460030078888,
              0.05688070133328438,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.28070399165153503,
              0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.3289259970188141,
              0.10510200262069702,
              4.446829795837402,
              0.22382399439811707,
              0.14863599836826324,
              4.446829795837402,
              0.3289259970188141,
              0.10510200262069702,
              4.172019958496094,
              0.28070399165153503,
              0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              0.14863599836826324,
              4.172019958496094,
              0.1669429987668991,
              0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              0.14863599836826324,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.446829795837402,
              0.22382399439811707,
              0.14863599836826324,
              4.172019958496094,
              0.28070399165153503,
              0.13732199370861053,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.446829795837402,
              0.11872199922800064,
              0.10510200262069702,
              4.172019958496094,
              0.1669429987668991,
              0.13732199370861053,
              4.172019958496094,
              0.08650189638137817,
              0.05688050016760826,
              4.172019958496094,
              0.1669429987668991,
              0.13732199370861053,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.08650189638137817,
              0.05688050016760826,
              4.446829795837402,
              0.07518749684095383,
              -2.492130022346828e-7,
              4.172019958496094,
              0.08650189638137817,
              0.05688050016760826,
              4.172019958496094,
              0.08650179952383041,
              -0.05688070133328438,
              4.172019958496094,
              0.08650189638137817,
              0.05688050016760826,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.08650179952383041,
              -0.05688070133328438,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.07518749684095383,
              -2.492130022346828e-7,
              4.446829795837402,
              0.11872199922800064,
              -0.10510200262069702,
              4.446829795837402,
              0.07518749684095383,
              -2.492130022346828e-7,
              4.172019958496094,
              0.08650179952383041,
              -0.05688070133328438,
              4.446829795837402,
              0.11872199922800064,
              -0.10510200262069702,
              4.446829795837402,
              0.11872199922800064,
              -0.10510200262069702,
              4.172019958496094,
              0.08650179952383041,
              -0.05688070133328438,
              4.172019958496094,
              0.1669429987668991,
              -0.13732199370861053,
              4.172019958496094,
              0.08650179952383041,
              -0.05688070133328438,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.1669429987668991,
              -0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.11872199922800064,
              -0.10510200262069702,
              4.446829795837402,
              0.22382399439811707,
              -0.14863699674606323,
              4.446829795837402,
              0.11872199922800064,
              -0.10510200262069702,
              4.172019958496094,
              0.1669429987668991,
              -0.13732199370861053,
              4.446829795837402,
              0.22382399439811707,
              -0.14863699674606323,
              4.446829795837402,
              0.22382399439811707,
              -0.14863699674606323,
              4.172019958496094,
              0.1669429987668991,
              -0.13732199370861053,
              4.172019958496094,
              0.28070399165153503,
              -0.13732300698757172,
              4.172019958496094,
              0.1669429987668991,
              -0.13732199370861053,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.28070399165153503,
              -0.13732300698757172,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.446829795837402,
              0.22382399439811707,
              -0.14863699674606323,
              4.446829795837402,
              0.3289259970188141,
              -0.10510200262069702,
              4.446829795837402,
              0.22382399439811707,
              -0.14863699674606323,
              4.172019958496094,
              0.28070399165153503,
              -0.13732300698757172,
              4.446829795837402,
              0.3289259970188141,
              -0.10510200262069702,
              4.446829795837402,
              0.3289259970188141,
              -0.10510200262069702,
              4.172019958496094,
              0.28070399165153503,
              -0.13732300698757172,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.172019958496094,
              0.28070399165153503,
              -0.13732300698757172,
              4.14955997467041,
              0.22382399439811707,
              -2.157690062176698e-7,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.446829795837402,
              0.3289259970188141,
              -0.10510200262069702,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              4.172019958496094,
              0.3611460030078888,
              -0.056881699711084366,
              4.446829795837402,
              0.3724600076675415,
              -2.137760048981363e-7,
              4.446829795837402,
              0.22382399439811707,
              -2.072780063144819e-7,
              3.662760019302368,
              0.3440369963645935,
              0.09814769774675369,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.67330002784729,
              0.36094000935554504,
              0.07328979671001434,
              3.662760019302368,
              0.3722180128097534,
              0.04542509838938713,
              3.652129888534546,
              0.36094000935554504,
              0.07328979671001434,
              3.6628100872039795,
              0.37288400530815125,
              -0.014820500276982784,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.673340082168579,
              0.3690199851989746,
              -0.044419098645448685,
              3.662709951400757,
              0.36420801281929016,
              -0.07503700256347656,
              3.652169942855835,
              0.3685080111026764,
              -0.044317200779914856,
              3.6834700107574463,
              0.3671329915523529,
              -0.014229999855160713,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.6935598850250244,
              0.3711110055446625,
              0.014614899642765522,
              3.704129934310913,
              0.3751179873943329,
              -0.01500330027192831,
              3.693510055541992,
              0.36940398812294006,
              -0.044481899589300156,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.6358399391174316,
              0.28263700008392334,
              0.10138899832963943,
              3.1659300327301025,
              0.2879830002784729,
              0.11060699820518494,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              3.639780044555664,
              0.22411000728607178,
              -0.08450470119714737,
              3.6358399391174316,
              0.2168789952993393,
              -0.13133400678634644,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.639780044555664,
              0.297448992729187,
              0.042335301637649536,
              3.6358399391174316,
              0.34103599190711975,
              0.060596998780965805,
              3.6358399391174316,
              0.11154399812221527,
              0.07051879912614822,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              3.639780044555664,
              0.2411849945783615,
              -0.07610470056533813,
              3.639780044555664,
              0.22382499277591705,
              0.000006972929895709967,
              3.639780044555664,
              0.22411000728607178,
              -0.08450470119714737,
              3.6358399391174316,
              0.11244899779558182,
              -0.07104119658470154,
              3.639780044555664,
              0.15029700100421906,
              -0.0426815003156662,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.6358399391174316,
              0.232341006398201,
              -0.13042700290679932,
              3.639780044555664,
              0.2411849945783615,
              -0.07610470056533813,
              3.639780044555664,
              0.22411000728607178,
              -0.08450470119714737,
              3.6358399391174316,
              0.2168789952993393,
              -0.13133400678634644,
              3.639780044555664,
              0.22411000728607178,
              -0.08450470119714737,
              3.639780044555664,
              0.2079160064458847,
              -0.07694269716739655,
              3.6358399391174316,
              0.28524500131607056,
              -0.09988299757242203,
              3.639780044555664,
              0.2841239869594574,
              -0.051314301788806915,
              3.639780044555664,
              0.2411849945783615,
              -0.07610470056533813,
              3.6358399391174316,
              0.33533498644828796,
              -0.07096350193023682,
              3.639780044555664,
              0.29737699031829834,
              -0.04201729968190193,
              3.639780044555664,
              0.2841239869594574,
              -0.051314301788806915,
              3.6358399391174316,
              0.34103599190711975,
              -0.05862480029463768,
              3.639780044555664,
              0.2984139919281006,
              -0.023821700364351273,
              3.639780044555664,
              0.29737699031829834,
              -0.04201729968190193,
              3.6358399391174316,
              0.34103599190711975,
              0.001971790101379156,
              3.639780044555664,
              0.2984139919281006,
              0.02597299963235855,
              3.639780044555664,
              0.2984139919281006,
              -0.023821700364351273,
              3.6358399391174316,
              0.34103599190711975,
              0.060596998780965805,
              3.639780044555664,
              0.297448992729187,
              0.042335301637649536,
              3.639780044555664,
              0.2984139919281006,
              0.02597299963235855,
              3.6358399391174316,
              0.3357270061969757,
              0.07073789834976196,
              3.639780044555664,
              0.283935010433197,
              0.05142410099506378,
              3.639780044555664,
              0.297448992729187,
              0.042335301637649536,
              3.6358399391174316,
              0.28263700008392334,
              0.10138899832963943,
              3.639780044555664,
              0.2385289967060089,
              0.07763879746198654,
              3.639780044555664,
              0.283935010433197,
              0.05142410099506378,
              3.6358399391174316,
              0.22934100031852722,
              0.1321599930524826,
              3.639780044555664,
              0.22358499467372894,
              0.08483169972896576,
              3.639780044555664,
              0.2385289967060089,
              0.07763879746198654,
              3.6358399391174316,
              0.2169879972934723,
              0.13139699399471283,
              3.639780044555664,
              0.20784999430179596,
              0.07690530270338058,
              3.639780044555664,
              0.22358499467372894,
              0.08483169972896576,
              3.6358399391174316,
              0.16331200301647186,
              0.10040699690580368,
              3.639780044555664,
              0.1631300002336502,
              0.05108629912137985,
              3.639780044555664,
              0.20784999430179596,
              0.07690530270338058,
              3.6358399391174316,
              0.11154399812221527,
              0.07051879912614822,
              3.639780044555664,
              0.15013200044631958,
              0.04214629903435707,
              3.639780044555664,
              0.1631300002336502,
              0.05108629912137985,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.639780044555664,
              0.14923599362373352,
              0.024577800184488297,
              3.639780044555664,
              0.15013200044631958,
              0.04214629903435707,
              3.6358399391174316,
              0.10661300271749496,
              -0.00242291996255517,
              3.639780044555664,
              0.14923599362373352,
              -0.027220699936151505,
              3.639780044555664,
              0.14923599362373352,
              0.024577800184488297,
              3.6358399391174316,
              0.10661400109529495,
              -0.062199901789426804,
              3.639780044555664,
              0.15029700100421906,
              -0.0426815003156662,
              3.639780044555664,
              0.14923599362373352,
              -0.027220699936151505,
              3.6358399391174316,
              0.11244899779558182,
              -0.07104119658470154,
              3.639780044555664,
              0.16393400728702545,
              -0.051549699157476425,
              3.639780044555664,
              0.15029700100421906,
              -0.0426815003156662,
              3.6358399391174316,
              0.164109006524086,
              -0.10086700320243835,
              3.639780044555664,
              0.2079160064458847,
              -0.07694269716739655,
              3.639780044555664,
              0.16393400728702545,
              -0.051549699157476425,
              -2.732369899749756,
              0.15866999328136444,
              0.10811199992895126,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              -3.6673800945281982,
              0.2167550027370453,
              0.13589200377464294,
              -4.114009857177734,
              0.19933199882507324,
              0.04066240042448044,
              -3.5985400676727295,
              0.10770700126886368,
              0.07293280214071274,
              3.6358399391174316,
              0.10661300271749496,
              0.05977720022201538,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              3.1659300327301025,
              0.10133600234985352,
              0.0769295021891594,
              2.16444993019104,
              0.09595809876918793,
              0.06521130353212357,
              3.1659300327301025,
              0.09595800191164017,
              -0.0026432399172335863,
              -2.732369899749756,
              0.34389200806617737,
              -0.07640810310840607,
              -3.5985400676727295,
              0.2873460054397583,
              -0.10329499840736389,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              -3.5985400676727295,
              0.2873460054397583,
              -0.10329499840736389,
              -4.114009857177734,
              0.2689630091190338,
              -0.028704799711704254,
              -3.6673800945281982,
              0.3450450003147125,
              -0.06062600016593933,
              3.683419942855835,
              0.18103599548339844,
              0.14217300713062286,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              3.6935598850250244,
              0.15414799749851227,
              0.1305840015411377,
              3.704090118408203,
              0.17881900072097778,
              0.1493529975414276,
              3.6934800148010254,
              0.20859800279140472,
              0.1545889973640442,
              -1.3707400560379028,
              0.28756698966026306,
              0.1098880022764206,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              -2.732369899749756,
              0.34431400895118713,
              0.07616580277681351,
              -3.6673800945281982,
              0.2846490144729614,
              0.10485900193452835,
              -2.732369899749756,
              0.22976499795913696,
              0.14230099320411682,
              3.1659300327301025,
              0.21624800562858582,
              -0.1432729959487915,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              2.16444993019104,
              0.1586810052394867,
              -0.11003699898719788,
              1.0776599645614624,
              0.21624800562858582,
              -0.1432729959487915,
              2.16444993019104,
              0.23311500251293182,
              -0.14228500425815582,
              -3.6673800945281982,
              0.21664300560951233,
              -0.13582000136375427,
              -4.114009857177734,
              0.22727400064468384,
              -0.052774399518966675,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -4.114009857177734,
              0.22727400064468384,
              -0.052774399518966675,
              -3.5985400676727295,
              0.2873460054397583,
              -0.10329499840736389,
              -2.732369899749756,
              0.23299500346183777,
              -0.14043399691581726,
              -1.3707400560379028,
              0.10311499983072281,
              -0.07699570059776306,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              -2.732369899749756,
              0.1595280021429062,
              -0.1086060032248497,
              -1.3707400560379028,
              0.21629799902439117,
              -0.1423420011997223,
              -0.009122519753873348,
              0.1586810052394867,
              -0.11003699898719788,
              4.079689979553223,
              0.34114301204681396,
              0.09628059715032578,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.089159965515137,
              0.3220280110836029,
              0.11966100335121155,
              4.099009990692139,
              0.34582799673080444,
              0.1001259982585907,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.147500038146973,
              0.37763500213623047,
              0.01514859963208437,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.137660026550293,
              0.37439098954200745,
              0.04567360132932663,
              4.147500038146973,
              0.3601300120353699,
              0.07285650074481964,
              4.177579879760742,
              0.3690580129623413,
              0.044055599719285965,
              4.079689979553223,
              0.3690580129623413,
              0.044055599719285965,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.089159965515137,
              0.3603450059890747,
              0.0729714035987854,
              4.099009990692139,
              0.37485799193382263,
              0.04581499844789505,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.137660026550293,
              0.38040900230407715,
              -0.015422699972987175,
              4.147500038146973,
              0.37763500213623047,
              0.01514859963208437,
              4.177579879760742,
              0.3748619854450226,
              -0.014876400120556355,
              4.079689979553223,
              0.3748619854450226,
              -0.014876400120556355,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.089159965515137,
              0.37787801027297974,
              0.015172399580478668,
              4.099009990692139,
              0.3808940052986145,
              -0.015470500104129314,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.137660026550293,
              0.36258798837661743,
              -0.07417099922895432,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.137660026550293,
              0.36258798837661743,
              -0.07417099922895432,
              4.147500038146973,
              0.371724009513855,
              -0.044865500181913376,
              4.177579879760742,
              0.3576720058917999,
              -0.07154359668493271,
              4.079689979553223,
              0.3576720058917999,
              -0.07154359668493271,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.089159965515137,
              0.3719579875469208,
              -0.044936299324035645,
              4.099009990692139,
              0.3630169928073883,
              -0.07440079748630524,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.147500038146973,
              0.2966809868812561,
              -0.1363060027360916,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.137660026550293,
              0.32364100217819214,
              -0.12162700295448303,
              4.147500038146973,
              0.34329700469970703,
              -0.09804929792881012,
              4.177579879760742,
              0.3201049864292145,
              -0.1173190027475357,
              4.079689979553223,
              0.3201049864292145,
              -0.1173190027475357,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.089159965515137,
              0.3434849977493286,
              -0.09820389747619629,
              4.099009990692139,
              0.32394999265670776,
              -0.12200400233268738,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.137660026550293,
              0.26949799060821533,
              -0.15056699514389038,
              4.147500038146973,
              0.2966809868812561,
              -0.1363060027360916,
              4.177579879760742,
              0.26787999272346497,
              -0.14523400366306305,
              4.079689979553223,
              0.26787999272346497,
              -0.14523400366306305,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.089159965515137,
              0.2967959940433502,
              -0.13652099668979645,
              4.099009990692139,
              0.2696399986743927,
              -0.15103399753570557,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.137660026550293,
              0.20840199291706085,
              -0.1565849930047989,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.137660026550293,
              0.20840199291706085,
              -0.1565849930047989,
              4.147500038146973,
              0.238973006606102,
              -0.1538109928369522,
              4.177579879760742,
              0.20894800126552582,
              -0.1510380059480667,
              4.079689979553223,
              0.20894800126552582,
              -0.1510380059480667,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.089159965515137,
              0.23899699747562408,
              -0.15405400097370148,
              4.099009990692139,
              0.20835399627685547,
              -0.15706999599933624,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.147500038146973,
              0.12577499449253082,
              -0.11947300285100937,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.137660026550293,
              0.14965400099754333,
              -0.13876399397850037,
              4.147500038146973,
              0.17895899713039398,
              -0.1479009985923767,
              4.177579879760742,
              0.1522810012102127,
              -0.13384799659252167,
              4.079689979553223,
              0.1522810012102127,
              -0.13384799659252167,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.089159965515137,
              0.1788879930973053,
              -0.1481339931488037,
              4.099009990692139,
              0.1494240015745163,
              -0.13919399678707123,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.137660026550293,
              0.10219699889421463,
              -0.09981740266084671,
              4.147500038146973,
              0.12577499449253082,
              -0.11947300285100937,
              4.177579879760742,
              0.10650599747896194,
              -0.09628160297870636,
              4.079689979553223,
              0.10650599747896194,
              -0.09628160297870636,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.089159965515137,
              0.1256210058927536,
              -0.11966200172901154,
              4.099009990692139,
              0.10181999951601028,
              -0.10012699663639069,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.137660026550293,
              0.07325699925422668,
              -0.045674800872802734,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.137660026550293,
              0.07325699925422668,
              -0.045674800872802734,
              4.147500038146973,
              0.08751849830150604,
              -0.07285760343074799,
              4.177579879760742,
              0.07859060168266296,
              -0.04405679926276207,
              4.079689979553223,
              0.07859069854021072,
              -0.04405679926276207,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.089159965515137,
              0.08730360120534897,
              -0.07297240197658539,
              4.099009990692139,
              0.07279060035943985,
              -0.04581629857420921,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.147500038146973,
              0.07592339813709259,
              0.04486420005559921,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.137660026550293,
              0.06723929941654205,
              0.015421399846673012,
              4.147500038146973,
              0.07001280039548874,
              -0.015149899758398533,
              4.177579879760742,
              0.07278610020875931,
              0.014875099994242191,
              4.079689979553223,
              0.07278619706630707,
              0.014875099994242191,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.089159965515137,
              0.06977040320634842,
              -0.015173699706792831,
              4.099009990692139,
              0.0667542964220047,
              0.015469199977815151,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.137660026550293,
              0.08506009727716446,
              0.07416979968547821,
              4.147500038146973,
              0.07592339813709259,
              0.04486420005559921,
              4.177579879760742,
              0.08997560292482376,
              0.0715423971414566,
              4.079689979553223,
              0.08997569978237152,
              0.0715423971414566,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.089159965515137,
              0.07569029927253723,
              0.04493499919772148,
              4.099009990692139,
              0.08463030308485031,
              0.07439950108528137,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.137660026550293,
              0.1240060031414032,
              0.12162700295448303,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.137660026550293,
              0.1240060031414032,
              0.12162700295448303,
              4.147500038146973,
              0.1043509989976883,
              0.09804820269346237,
              4.177579879760742,
              0.12754200398921967,
              0.11731799691915512,
              4.079689979553223,
              0.12754200398921967,
              0.11731799691915512,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.089159965515137,
              0.10416200011968613,
              0.09820280224084854,
              4.099009990692139,
              0.12369699776172638,
              0.1220029965043068,
              4.089159965515137,
              0.15085099637508392,
              0.13651999831199646,
              4.147500038146973,
              0.20867499709129333,
              0.1538109928369522,
              4.137660026550293,
              0.1781489998102188,
              0.15056699514389038,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              4.137660026550293,
              0.1781489998102188,
              0.15056699514389038,
              4.147500038146973,
              0.15096600353717804,
              0.13630500435829163,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              4.099009990692139,
              0.23929400742053986,
              0.15706999599933624,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.089159965515137,
              0.26875999569892883,
              0.14813299477100372,
              4.079689979553223,
              0.2387000024318695,
              0.1510380059480667,
              4.089159965515137,
              0.20865100622177124,
              0.15405400097370148,
              4.177579879760742,
              0.17976699769496918,
              0.14523300528526306,
              4.207469940185547,
              0.1805180013179779,
              0.14275899529457092,
              4.207469940185547,
              0.20920099318027496,
              0.14846399426460266
            ],
            "normalized": false
          },
          "normal": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              1.9178999366431526e-7,
              0.9970329999923706,
              0.0769817978143692,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -0.1770090013742447,
              -0.39125698804855347,
              -0.9030979871749878,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              -1.9266299489117955e-7,
              -1,
              -0.0000010078499599330826,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              -0.056912001222372055,
              0.9983789920806885,
              0,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              2.5743300824387916e-8,
              0.5,
              0.866025984287262,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              -0.05873339995741844,
              -0.9960010051727295,
              0.0673225000500679,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.17443299293518066,
              0.9816740155220032,
              -0.07673919945955276,
              -0.008728289976716042,
              -0.36664900183677673,
              0.930296003818512,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.04092530161142349,
              0.414777010679245,
              -0.9089940190315247,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              0.059328000992536545,
              -0.1560720056295395,
              -0.9859309792518616,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.174577996134758,
              0.9817209839820862,
              0.07579849660396576,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              -0.0006102219922468066,
              -0.5,
              0.8660249710083008,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9964830279350281,
              -0.005166939925402403,
              0.08364120125770569,
              0.9964830279350281,
              -0.005166939925402403,
              0.08364120125770569,
              0.9964830279350281,
              -0.005166939925402403,
              0.08364120125770569,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -1.7237600502539863e-7,
              -0.9964029788970947,
              -0.08474209904670715,
              -0.04583879932761192,
              -0.5819270014762878,
              0.8119450211524963,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              -2.7242199607258044e-8,
              -0.5,
              -0.866025984287262,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -0.08966340124607086,
              -0.4821920096874237,
              -0.8714560270309448,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              -0.07290869951248169,
              -0.7550280094146729,
              -0.6516010165214539,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000013172899571145535,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000013172899571145535,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000013172899571145535,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.1743679940700531,
              -0.4267460107803345,
              0.8874030113220215,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              -0.16855299472808838,
              0.4928460121154785,
              0.8536350131034851,
              0.07815790176391602,
              0.3086639940738678,
              0.9479349851608276,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              0.007241849787533283,
              0.9999740123748779,
              4.582730070978869e-7,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              -0.15400800108909607,
              -0.988070011138916,
              0,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              -0.05899230018258095,
              -0.10931699723005295,
              -0.992247998714447,
              -0.07287819683551788,
              0.3143410086631775,
              -0.9465010166168213,
              -0.05899230018258095,
              -0.10931699723005295,
              -0.992247998714447,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.07287819683551788,
              0.3143410086631775,
              -0.9465010166168213,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1.831519966799533e-7,
              0.5945860147476196,
              -0.804032027721405,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9957489967346191,
              -0.09211049973964691,
              -1.2136699467646395e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.2136699467646395e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.2136699467646395e-7,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.16106200218200684,
              -0.5748760104179382,
              0.802232027053833,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              -0.05945440009236336,
              0.41498398780822754,
              0.9078840017318726,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              -0.9340800046920776,
              0.19666099548339844,
              0.2979219853878021,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              -0.15400999784469604,
              0.494035005569458,
              -0.855692982673645,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.018250100314617157,
              0.5747249722480774,
              -0.8181099891662598,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              0.04092530161142349,
              0.414777010679245,
              -0.9089940190315247,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -0.17399199306964874,
              -0.5495479702949524,
              -0.817143976688385,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -2.0936100497692678e-7,
              -0.9977229833602905,
              0.06743799895048141,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              -0.0026239899452775717,
              0.9969549775123596,
              -0.07793419808149338,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.02554509975016117,
              -0.8343120217323303,
              -0.5507000088691711,
              0.02554509975016117,
              -0.8343120217323303,
              -0.5507000088691711,
              0.02554509975016117,
              -0.8343120217323303,
              -0.5507000088691711,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              4.443419854283093e-9,
              0.41571900248527527,
              0.9094930291175842,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              -0.05925209820270538,
              0.5716339945793152,
              0.8183659911155701,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              2.069770062007592e-7,
              0.9969589710235596,
              -0.07793410122394562,
              -0.04583879932761192,
              -0.5274209976196289,
              -0.8483229875564575,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              -0.002621979918330908,
              -0.4333840012550354,
              0.9012060165405273,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811,
              0.9825429916381836,
              0,
              -0.18601000308990479,
              0.7549669742584229,
              0.06424149870872498,
              0.6525470018386841,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007540700025856495,
              0.41791799664497375,
              -0.9084529876708984,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.007541059982031584,
              -0.9963750243186951,
              -0.08473990112543106,
              0.022670000791549683,
              0.9997429847717285,
              4.916889793094015e-7,
              0.022670000791549683,
              0.9997429847717285,
              4.916889793094015e-7,
              0.022670000791549683,
              0.9997429847717285,
              4.916889793094015e-7,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              -0.0006358170066960156,
              -0.9964029788970947,
              -0.08474169671535492,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              0.041016899049282074,
              0.6861780285835266,
              0.7262489795684814,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.007241480052471161,
              -0.49998700618743896,
              0.8660020232200623,
              0.025444800034165382,
              -0.06163749843835831,
              0.9977740049362183,
              0.025444800034165382,
              -0.06163749843835831,
              0.9977740049362183,
              0.025444800034165382,
              -0.06163749843835831,
              0.9977740049362183,
              0.9957489967346191,
              0.09211049973964691,
              4.6391498642606166e-8,
              0.9957489967346191,
              0.09211049973964691,
              4.6391498642606166e-8,
              0.9957489967346191,
              0.09211049973964691,
              4.6391498642606166e-8,
              -0.03387549892067909,
              -0.3325909972190857,
              0.942441999912262,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.007567840162664652,
              0.415706992149353,
              0.9094669818878174,
              0.9964399933815002,
              0.07653330266475677,
              -0.03536489978432655,
              0.9964399933815002,
              0.07653330266475677,
              -0.03536489978432655,
              0.9964399933815002,
              0.07653330266475677,
              -0.03536489978432655,
              0.05142369866371155,
              0.40806299448013306,
              0.9114959836006165,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              0.059328000992536545,
              0.1560720056295395,
              0.9859309792518616,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              -0.10873699933290482,
              0.41074898838996887,
              0.9052090048789978,
              0.059328000992536545,
              0.1560720056295395,
              0.9859309792518616,
              0.9957489967346191,
              0.046055201441049576,
              0.07977010309696198,
              0.9957489967346191,
              0.046055201441049576,
              0.07977010309696198,
              0.9957489967346191,
              0.046055201441049576,
              0.07977010309696198,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              -0.05648979917168617,
              0.7717519998550415,
              0.6333810091018677,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              -0.05648979917168617,
              0.7717519998550415,
              0.6333810091018677,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              0.025518199428915977,
              0.8856300115585327,
              0.46369001269340515,
              0.025518199428915977,
              0.8856300115585327,
              0.46369001269340515,
              0.025518199428915977,
              0.8856300115585327,
              0.46369001269340515,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.007241650018841028,
              -0.4999859929084778,
              -0.8660029768943787,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              0.022669799625873566,
              -0.49987098574638367,
              -0.8658030033111572,
              -0.00943022035062313,
              0.4713580012321472,
              0.881862998008728,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              0,
              0.4713580012321472,
              0.8819239735603333,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              0.9964860081672668,
              -0.0761243999004364,
              0.03494039922952652,
              0.9964860081672668,
              -0.0761243999004364,
              0.03494039922952652,
              0.9964860081672668,
              -0.0761243999004364,
              0.03494039922952652,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0332600197671127e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0332600197671127e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0332600197671127e-7,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -5.024470084435961e-8,
              -0.5580589771270752,
              -0.8298010230064392,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0026115200016647577,
              -0.9999970197677612,
              -1.1345800032813713e-7,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0003571530105546117,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0006424430175684392,
              0.5945860147476196,
              -0.804032027721405,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              -0.0003394279920030385,
              0.5,
              -0.8660249710083008,
              0.018250100314617157,
              -0.5358740091323853,
              -0.8440809845924377,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              0.041016899049282074,
              -0.6861780285835266,
              -0.7262489795684814,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              1.9195699962892832e-7,
              1,
              4.582850010592665e-7,
              -0.6885589957237244,
              0,
              -0.7251499891281128,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              -0.8652909994125366,
              0.10422100126743317,
              0.49028000235557556,
              0.041016899049282074,
              -0.9850760102272034,
              -0.16702799499034882,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              0.018250100314617157,
              -0.9995419979095459,
              0.02334669977426529,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.00035144301364198327,
              0.9969580173492432,
              -0.07793450355529785,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              -0.0006101540056988597,
              1,
              4.612830082351138e-7,
              0.041016899049282074,
              -0.6861780285835266,
              -0.7262489795684814,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              0.041016899049282074,
              -0.9850760102272034,
              -0.16702799499034882,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              3.4048600383584926e-8,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.0006353320204652846,
              0.5726400017738342,
              0.8198069930076599,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.00033924399758689106,
              0.5,
              0.8660249710083008,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.0003517319855745882,
              0.9970319867134094,
              0.0769817978143692,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              -0.41062700748443604,
              -0.3127230107784271,
              -0.8564710021018982,
              -0.8652909994125366,
              0.10422100126743317,
              0.49028000235557556,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.0026260199956595898,
              0.9970290064811707,
              0.07698120176792145,
              -0.08966340124607086,
              -0.9924619793891907,
              0.08322399854660034,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              -0.06134219840168953,
              -0.8802449703216553,
              0.4705039858818054,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              -0.06134219840168953,
              0.8802449703216553,
              -0.4705039858818054,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              -0.08966340124607086,
              0.6205940246582031,
              -0.778954029083252,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.00035149799077771604,
              -0.4333859980106354,
              0.9012079834938049,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006301479879766703,
              -0.9977229833602905,
              0.06743840128183365,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.0006375240045599639,
              0.41571900248527527,
              0.9094930291175842,
              -0.08966340124607086,
              0.6205940246582031,
              -0.778954029083252,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              -0.07287819683551788,
              0.3143410086631775,
              -0.9465010166168213,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -1.681430035205267e-7,
              -0.5,
              0.8660249710083008,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.10873699933290482,
              0.41074898838996887,
              0.9052090048789978,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              -0.0027330799493938684,
              -0.5824790000915527,
              0.81284099817276,
              0.00320443999953568,
              -0.14941899478435516,
              -0.988739013671875,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.00035541399847716093,
              -0.5824810266494751,
              0.8128439784049988,
              -0.07290869951248169,
              -0.9612410068511963,
              -0.26578599214553833,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              -0.08966340124607086,
              -0.9924619793891907,
              0.08322399854660034,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.019196100533008575,
              0.4004330039024353,
              -0.9161049723625183,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              -0.0026653099339455366,
              -0.39753299951553345,
              -0.9175840020179749,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              0.056550800800323486,
              -0.8804900050163269,
              0.47062599658966064,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.00033962601446546614,
              -1,
              -5.599470114248106e-7,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              -0.0003509109956212342,
              -0.5580599904060364,
              -0.8298010230064392,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              -0.06134219840168953,
              0.8802449703216553,
              -0.4705039858818054,
              -0.08966340124607086,
              0.9924619793891907,
              -0.08322399854660034,
              -0.06134219840168953,
              0.8802449703216553,
              -0.4705039858818054,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.08966340124607086,
              0.9924619793891907,
              -0.08322399854660034,
              -0.8652909994125366,
              0.10422100126743317,
              0.49028000235557556,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              0.018250100314617157,
              -0.9995419979095459,
              0.02334669977426529,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              0.056550800800323486,
              -0.8804900050163269,
              0.47062599658966064,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.0006357000092975795,
              0.4179289937019348,
              -0.9084789752960205,
              -0.04583879932761192,
              -0.9983829855918884,
              0.03274640068411827,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              -0.10873699933290482,
              0.41074898838996887,
              0.9052090048789978,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              -0.07290869951248169,
              -0.9612410068511963,
              -0.26578599214553833,
              -0.07290869951248169,
              -0.7550280094146729,
              -0.6516010165214539,
              -0.07290869951248169,
              -0.9612410068511963,
              -0.26578599214553833,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.07290869951248169,
              -0.7550280094146729,
              -0.6516010165214539,
              0.00006103699706727639,
              -0.88189297914505,
              0.4713889956474304,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              1.6168900174307055e-7,
              0.417930006980896,
              -0.9084789752960205,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000011269800097579719,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000011269800097579719,
              0.022669600322842598,
              -0.9997429847717285,
              -0.0000011269800097579719,
              -0.01922670006752014,
              0.6971650123596191,
              0.7166360020637512,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.01922670006752014,
              0.9831839799880981,
              0.18152399361133575,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              0.022670000791549683,
              0.9997429847717285,
              5.082230245534447e-7,
              0.022670000791549683,
              0.9997429847717285,
              5.082230245534447e-7,
              0.022670000791549683,
              0.9997429847717285,
              5.082230245534447e-7,
              0.00006103699706727639,
              0.88189297914505,
              -0.4713889956474304,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              0.025277599692344666,
              0.05849109962582588,
              -0.9979680180549622,
              0.025277599692344666,
              0.05849109962582588,
              -0.9979680180549622,
              0.025277599692344666,
              0.05849109962582588,
              -0.9979680180549622,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              1.8023500558683736e-7,
              0.5,
              -0.8660249710083008,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7689400522158394e-7,
              -0.5824819803237915,
              0.8128439784049988,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -1.7018899711729318e-7,
              -0.4333859980106354,
              0.9012079834938049,
              -0.04583879932761192,
              0.5819270014762878,
              -0.8119140267372131,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.07290869951248169,
              0.7550280094146729,
              0.6516010165214539,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              -0.07290869951248169,
              0.9612410068511963,
              0.26578599214553833,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              0.02542410045862198,
              -0.9085440039634705,
              0.4170140027999878,
              0.02542410045862198,
              -0.9085440039634705,
              0.4170140027999878,
              0.02542410045862198,
              -0.9085440039634705,
              0.4170140027999878,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              0.007541889790445566,
              0.5726240277290344,
              0.8197839856147766,
              -0.07290869951248169,
              0.9612410068511963,
              0.26578599214553833,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              -0.08966340124607086,
              0.9924619793891907,
              -0.08322399854660034,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.007621670141816139,
              0.5945690274238586,
              -0.8040080070495605,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              -0.07290869951248169,
              0.7550280094146729,
              0.6516010165214539,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              -0.07290869951248169,
              0.7550280094146729,
              0.6516010165214539,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              0.041016899049282074,
              0.9850760102272034,
              0.16702799499034882,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              0.018250100314617157,
              0.9995419979095459,
              -0.02334669977426529,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              0.07126069813966751,
              -0.09775079786777496,
              0.9926450252532959,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.9957489967346191,
              0.09211049973964691,
              4.530129871227473e-8,
              0.9957489967346191,
              0.09211049973964691,
              4.530129871227473e-8,
              0.9957489967346191,
              0.09211049973964691,
              4.530129871227473e-8,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              -0.9340800046920776,
              0.19666099548339844,
              0.2979219853878021,
              -0.7548750042915344,
              -0.3696709871292114,
              -0.541733980178833,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.05775890126824379,
              -0.9979140162467957,
              -0.028839200735092163,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.056924499571323395,
              -0.49918898940086365,
              -0.8646209836006165,
              -0.01922670006752014,
              -0.6971650123596191,
              -0.716605007648468,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              0.056550800800323486,
              0.8804900050163269,
              -0.47062599658966064,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              0.018250100314617157,
              0.5747249722480774,
              -0.8181099891662598,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              0.025333799421787262,
              0.9074789881706238,
              -0.41933301091194153,
              0.025333799421787262,
              0.9074789881706238,
              -0.41933301091194153,
              0.025333799421787262,
              0.9074789881706238,
              -0.41933301091194153,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.8652909994125366,
              0.10422100126743317,
              0.49028000235557556,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              -0.00261241989210248,
              0.4999980032444,
              -0.866021990776062,
              0.059328000992536545,
              -0.1560720056295395,
              -0.9859309792518616,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              0.018250100314617157,
              -0.5358740091323853,
              -0.8440809845924377,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              -0.01922670006752014,
              -0.9831839799880981,
              -0.18152399361133575,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.022670000791549683,
              0.49987199902534485,
              -0.865801990032196,
              0.022669600322842598,
              -0.49987199902534485,
              0.865801990032196,
              0.022669600322842598,
              -0.49987199902534485,
              0.865801990032196,
              0.022669600322842598,
              -0.49987199902534485,
              0.865801990032196,
              -0.05899230018258095,
              -0.10931699723005295,
              -0.992247998714447,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              -0.08966340124607086,
              -0.4821920096874237,
              -0.8714560270309448,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              -0.002618409926071763,
              -0.5580580234527588,
              -0.8297979831695557,
              1,
              0,
              0,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              -0.9999690055847168,
              0,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              1,
              0,
              0,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.9999690055847168,
              0,
              0,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              1,
              0,
              0,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              -0.9999690055847168,
              0,
              0,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              1,
              0,
              0,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              1,
              0,
              0,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              -0.9999690055847168,
              0,
              0,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              1,
              0,
              0,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              1,
              0,
              0,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              -0.9999690055847168,
              0,
              0,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              1,
              0,
              0,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.9999690055847168,
              0,
              0,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              1,
              0,
              0,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              -0.9999690055847168,
              0,
              0,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              1,
              0,
              0,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              1,
              0,
              0,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              -0.9999690055847168,
              0,
              0,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              1,
              0,
              0,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              1,
              0,
              0,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              -0.9999690055847168,
              0,
              0,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              1,
              0,
              0,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.9999690055847168,
              0,
              0,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              1,
              0,
              0,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              -0.9999690055847168,
              0,
              0,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              1,
              0,
              0,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              1,
              0,
              0,
              0.5169230103492737,
              0.8560140132904053,
              0,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              -0.9999690055847168,
              0,
              0,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              1,
              0,
              0,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.9999690055847168,
              0,
              0,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              1,
              0,
              0,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              -0.9999690055847168,
              0,
              0,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              1,
              0,
              0,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              1,
              0,
              0,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              -0.9999690055847168,
              0,
              0,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              1,
              0,
              0,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              1,
              0,
              0,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              -0.9999690055847168,
              0,
              0,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              1,
              0,
              0,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.9999690055847168,
              0,
              0,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              1,
              0,
              0,
              0.5169230103492737,
              0,
              0.8560140132904053,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              -0.9999690055847168,
              0,
              0,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              1,
              0,
              0,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              1,
              0,
              0,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              -0.9999690055847168,
              0,
              0,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              1,
              0,
              0,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              -0.9999690055847168,
              0,
              0,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              1,
              0,
              0,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              -0.9999690055847168,
              0,
              0,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              1,
              0,
              0,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.9999690055847168,
              0,
              0,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              1,
              0,
              0,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              -0.9999690055847168,
              0,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.5169230103492737,
              0.8560140132904053,
              0,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.5169230103492737,
              0,
              0.8560140132904053,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              0.011627599596977234,
              0.7729420065879822,
              0.6343269944190979,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              -0.41062700748443604,
              -0.3127230107784271,
              -0.8564710021018982,
              -0.6885589957237244,
              0,
              -0.7251499891281128,
              -0.8652909994125366,
              0.10422100126743317,
              0.49028000235557556,
              -0.41065698862075806,
              0.3127230107784271,
              -0.8564410209655762,
              -0.7548750042915344,
              0.36970099806785583,
              -0.5416730046272278,
              -0.8846399784088135,
              -0.18723100423812866,
              0.4270150065422058,
              0.9825130105018616,
              -0.10339699685573578,
              -0.15466800332069397,
              0.7549669742584229,
              0.3090910017490387,
              0.5782949924468994,
              0.9825429916381836,
              -0.07113859802484512,
              -0.17184999585151672,
              -0.7548750042915344,
              0.36970099806785583,
              -0.5416730046272278,
              -0.7692800164222717,
              0.35486900806427,
              -0.5312659740447998,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              0.10177899897098541,
              0.46894699335098267,
              0.8773159980773926,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.9825429916381836,
              -0.15463699400424957,
              -0.10333599895238876,
              0.7549970149993896,
              0.5068510174751282,
              0.4159669876098633,
              0.9825429916381836,
              -0.13141299784183502,
              -0.13153499364852905,
              -0.7692800164222717,
              0.35486900806427,
              -0.5312659740447998,
              -0.7549059987068176,
              0.35889801383018494,
              -0.5488449931144714,
              -0.9340800046920776,
              -0.19992700219154358,
              0.2957240045070648,
              0.0209966991096735,
              -0.532181978225708,
              0.8463389873504639,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              -0.04583879932761192,
              -0.5819270014762878,
              0.8119450211524963,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              -0.04583879932761192,
              -0.5819270014762878,
              0.8119450211524963,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              -0.41065698862075806,
              0.3127230107784271,
              -0.8564410209655762,
              -0.8846399784088135,
              -0.18723100423812866,
              0.4270150065422058,
              0.9825429916381836,
              -0.18237900733947754,
              -0.036286499351263046,
              0.7549970149993896,
              0.6274610161781311,
              0.19034400582313538,
              0.9825429916381836,
              -0.1717890053987503,
              -0.07116919755935669,
              -0.7549059987068176,
              0.35889801383018494,
              -0.5488449931144714,
              -0.4201180040836334,
              0.6695460081100464,
              -0.6125069856643677,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.8846399784088135,
              -0.18723100423812866,
              0.4270150065422058,
              -0.7548750042915344,
              0.36970099806785583,
              -0.5416730046272278,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              0.9825429916381836,
              -0.18237900733947754,
              0.036286499351263046,
              0.7550280094146729,
              0.6525160074234009,
              -0.06427200138568878,
              0.9825429916381836,
              -0.18591900169849396,
              0,
              -0.4201180040836334,
              0.6695460081100464,
              -0.6125069856643677,
              -0.4201180040836334,
              0.8812829852104187,
              -0.21637600660324097,
              -0.8414869904518127,
              -0.4764550030231476,
              0.2546769976615906,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              0.056550800800323486,
              -0.8804900050163269,
              0.47062599658966064,
              0.00006103699706727639,
              -0.88189297914505,
              0.4713889956474304,
              0.056550800800323486,
              -0.8804900050163269,
              0.47062599658966064,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              0.00006103699706727639,
              -0.88189297914505,
              0.4713889956474304,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              -0.7692800164222717,
              0.35486900806427,
              -0.5312659740447998,
              -0.9340800046920776,
              -0.19992700219154358,
              0.2957240045070648,
              0.9825739860534668,
              -0.15442399680614471,
              0.10333599895238876,
              0.7549970149993896,
              0.5782650113105774,
              -0.3090910017490387,
              0.9825429916381836,
              -0.1717890053987503,
              0.07116919755935669,
              -0.4201180040836334,
              0.8812829852104187,
              -0.21637600660324097,
              -0.7549669742584229,
              0.6556900143623352,
              0.006439410150051117,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.9340800046920776,
              -0.19992700219154358,
              0.2957240045070648,
              -0.7549059987068176,
              0.35889801383018494,
              -0.5488449931144714,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              0.9823909997940063,
              -0.10474000126123428,
              0.15460699796676636,
              0.7557600140571594,
              0.41508200764656067,
              -0.5064240097999573,
              0.9824820160865784,
              -0.1317790001630783,
              0.13162599503993988,
              -0.7549669742584229,
              0.6556900143623352,
              0.006439410150051117,
              -0.7692800164222717,
              0.6388739943504333,
              0,
              -0.9340800046920776,
              -0.3570359945297241,
              0.0019837000872939825,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              0.018250100314617157,
              -0.9995419979095459,
              0.02334669977426529,
              -0.04583879932761192,
              -0.9983829855918884,
              0.03274640068411827,
              0.018250100314617157,
              -0.9995419979095459,
              0.02334669977426529,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              -0.04583879932761192,
              -0.9983829855918884,
              0.03274640068411827,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              -0.4201180040836334,
              0.6695460081100464,
              -0.6125069856643677,
              -0.8414869904518127,
              -0.4764550030231476,
              0.2546769976615906,
              0.9762259721755981,
              -0.04220709949731827,
              0.21253100037574768,
              0.7606430053710938,
              0.18808600306510925,
              -0.6212959885597229,
              0.9763479828834534,
              -0.08371230214834213,
              0.19925500452518463,
              -0.7692800164222717,
              0.6388739943504333,
              0,
              -0.7549669742584229,
              0.6556900143623352,
              -0.006439410150051117,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.8414869904518127,
              -0.4764550030231476,
              0.2546769976615906,
              -0.4201180040836334,
              0.8812829852104187,
              -0.21637600660324097,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              0.9762259721755981,
              0.04226810112595558,
              0.21253100037574768,
              0.7614369988441467,
              -0.063509002327919,
              -0.6450999975204468,
              0.9761959910392761,
              0,
              0.21686500310897827,
              -0.7549669742584229,
              0.6556900143623352,
              -0.006439410150051117,
              -0.42014798521995544,
              0.8812519907951355,
              0.21640700101852417,
              -0.8846399784088135,
              -0.4590590000152588,
              -0.08151490241289139,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              0.041016899049282074,
              -0.9850760102272034,
              -0.16702799499034882,
              -0.01922670006752014,
              -0.9831839799880981,
              -0.18152399361133575,
              0.041016899049282074,
              -0.9850760102272034,
              -0.16702799499034882,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              -0.01922670006752014,
              -0.9831839799880981,
              -0.18152399361133575,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              -0.7549669742584229,
              0.6556900143623352,
              0.006439410150051117,
              -0.9340800046920776,
              -0.3570359945297241,
              0.0019837000872939825,
              0.9823600053787231,
              0.10498400032520294,
              0.15460699796676636,
              0.7582020163536072,
              -0.30542901158332825,
              -0.5760059952735901,
              0.9763479828834534,
              0.08371230214834213,
              0.19925500452518463,
              -0.42014798521995544,
              0.8812519907951355,
              0.21640700101852417,
              -0.4200870096683502,
              0.6695460081100464,
              0.6125069856643677,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.9340800046920776,
              -0.3570359945297241,
              0.0019837000872939825,
              -0.7692800164222717,
              0.6388739943504333,
              0,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              0.9825429916381836,
              0.15463699400424957,
              0.10333599895238876,
              0.7549669742584229,
              -0.5069119930267334,
              -0.4159669876098633,
              0.9825130105018616,
              0.13153499364852905,
              0.13162599503993988,
              -0.4200870096683502,
              0.6695460081100464,
              0.6125069856643677,
              -0.7548750042915344,
              0.35898900032043457,
              0.5488749742507935,
              -0.8846399784088135,
              -0.32282501459121704,
              -0.33640599250793457,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              0.041016899049282074,
              -0.6861780285835266,
              -0.7262489795684814,
              -0.01922670006752014,
              -0.6971650123596191,
              -0.716605007648468,
              0.041016899049282074,
              -0.6861780285835266,
              -0.7262489795684814,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              -0.01922670006752014,
              -0.6971650123596191,
              -0.716605007648468,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              -0.7549669742584229,
              0.6556900143623352,
              -0.006439410150051117,
              -0.8846399784088135,
              -0.4590590000152588,
              -0.08151490241289139,
              0.9825429916381836,
              0.18237900733947754,
              0.036286499351263046,
              0.7549669742584229,
              -0.6274909973144531,
              -0.19034400582313538,
              0.9825429916381836,
              0.1717890053987503,
              0.07116919755935669,
              -0.7548750042915344,
              0.35898900032043457,
              0.5488749742507935,
              -0.7692499756813049,
              0.35493001341819763,
              0.5312659740447998,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.8846399784088135,
              -0.4590590000152588,
              -0.08151490241289139,
              -0.42014798521995544,
              0.8812519907951355,
              0.21640700101852417,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              0.9825429916381836,
              0.18237900733947754,
              -0.036286499351263046,
              0.7549359798431396,
              -0.6526079773902893,
              0.06424149870872498,
              0.9825429916381836,
              0.18591900169849396,
              0,
              -0.7692499756813049,
              0.35493001341819763,
              0.5312659740447998,
              -0.7549059987068176,
              0.3696399927139282,
              0.541703999042511,
              -0.9340800046920776,
              -0.19666099548339844,
              -0.2979219853878021,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              0.018250100314617157,
              -0.5358740091323853,
              -0.8440809845924377,
              -0.04583879932761192,
              -0.5274209976196289,
              -0.8483229875564575,
              0.018250100314617157,
              -0.5358740091323853,
              -0.8440809845924377,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              -0.04583879932761192,
              -0.5274209976196289,
              -0.8483229875564575,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              -0.4200870096683502,
              0.6695460081100464,
              0.6125069856643677,
              -0.8846399784088135,
              -0.32282501459121704,
              -0.33640599250793457,
              0.9825130105018616,
              0.1548510044813156,
              -0.10333599895238876,
              0.7549669742584229,
              -0.5783259868621826,
              0.3090910017490387,
              0.9825429916381836,
              0.1717890053987503,
              -0.07116919755935669,
              -0.7549059987068176,
              0.3696399927139282,
              0.541703999042511,
              -0.41065698862075806,
              0.3127540051937103,
              0.8564410209655762,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.8846399784088135,
              -0.32282501459121704,
              -0.33640599250793457,
              -0.7548750042915344,
              0.35898900032043457,
              0.5488749742507935,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              0.9825130105018616,
              0.10339699685573578,
              -0.15466800332069397,
              0.7549669742584229,
              -0.41599801182746887,
              0.5068510174751282,
              0.9825130105018616,
              0.13165700435638428,
              -0.13153499364852905,
              -0.41065698862075806,
              0.3127540051937103,
              0.8564410209655762,
              -0.6885589957237244,
              0,
              0.7251499891281128,
              -0.8652610182762146,
              -0.10425099730491638,
              -0.49031001329421997,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              0.059328000992536545,
              -0.1560720056295395,
              -0.9859309792518616,
              0.00320443999953568,
              -0.14941899478435516,
              -0.988739013671875,
              0.059328000992536545,
              -0.1560720056295395,
              -0.9859309792518616,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              0.00320443999953568,
              -0.14941899478435516,
              -0.988739013671875,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              -0.7692499756813049,
              0.35493001341819763,
              0.5312659740447998,
              -0.9340800046920776,
              -0.19666099548339844,
              -0.2979219853878021,
              -0.6885589957237244,
              0,
              0.7251499891281128,
              -0.41065698862075806,
              -0.3127540051937103,
              0.8564410209655762,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.9340800046920776,
              -0.19666099548339844,
              -0.2979219853878021,
              -0.7549059987068176,
              0.3696399927139282,
              0.541703999042511,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              -0.41065698862075806,
              -0.3127540051937103,
              0.8564410209655762,
              -0.7548750042915344,
              -0.3696709871292114,
              0.541733980178833,
              -0.8846399784088135,
              0.18723100423812866,
              -0.4269840121269226,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              0.04092530161142349,
              0.414777010679245,
              -0.9089940190315247,
              -0.019196100533008575,
              0.4004330039024353,
              -0.9161049723625183,
              0.04092530161142349,
              0.414777010679245,
              -0.9089940190315247,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              -0.019196100533008575,
              0.4004330039024353,
              -0.9161049723625183,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              -0.41065698862075806,
              0.3127540051937103,
              0.8564410209655762,
              -0.8652610182762146,
              -0.10425099730491638,
              -0.49031001329421997,
              -0.7548750042915344,
              -0.3696709871292114,
              0.541733980178833,
              -0.7692499756813049,
              -0.35493001341819763,
              0.5312359929084778,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.8652610182762146,
              -0.10425099730491638,
              -0.49031001329421997,
              -0.6885589957237244,
              0,
              0.7251499891281128,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              -0.7692499756813049,
              -0.35493001341819763,
              0.5312359929084778,
              -0.7549059987068176,
              -0.35895898938179016,
              0.5488449931144714,
              -0.9340800046920776,
              0.19998799264431,
              -0.2957240045070648,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              0.018250100314617157,
              0.5747249722480774,
              -0.8181099891662598,
              -0.04583879932761192,
              0.5819270014762878,
              -0.8119140267372131,
              0.018250100314617157,
              0.5747249722480774,
              -0.8181099891662598,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              -0.04583879932761192,
              0.5819270014762878,
              -0.8119140267372131,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              -0.41065698862075806,
              -0.3127540051937103,
              0.8564410209655762,
              -0.8846399784088135,
              0.18723100423812866,
              -0.4269840121269226,
              -0.7549059987068176,
              -0.35895898938179016,
              0.5488449931144714,
              -0.4201180040836334,
              -0.6695150136947632,
              0.6125370264053345,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.8846399784088135,
              0.18723100423812866,
              -0.4269840121269226,
              -0.7548750042915344,
              -0.3696709871292114,
              0.541733980178833,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              -0.4201180040836334,
              -0.6695150136947632,
              0.6125370264053345,
              -0.4200870096683502,
              -0.8812829852104187,
              0.21634599566459656,
              -0.8414559960365295,
              0.476485013961792,
              -0.2546460032463074,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              0.056550800800323486,
              0.8804900050163269,
              -0.47062599658966064,
              0.00006103699706727639,
              0.88189297914505,
              -0.4713889956474304,
              0.056550800800323486,
              0.8804900050163269,
              -0.47062599658966064,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              0.00006103699706727639,
              0.88189297914505,
              -0.4713889956474304,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              -0.7692499756813049,
              -0.35493001341819763,
              0.5312359929084778,
              -0.9340800046920776,
              0.19998799264431,
              -0.2957240045070648,
              -0.4200870096683502,
              -0.8812829852104187,
              0.21634599566459656,
              -0.7548450231552124,
              -0.6558120250701904,
              -0.006439410150051117,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.9340800046920776,
              0.19998799264431,
              -0.2957240045070648,
              -0.7549059987068176,
              -0.35895898938179016,
              0.5488449931144714,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              -0.7548450231552124,
              -0.6558120250701904,
              -0.006439410150051117,
              -0.7692499756813049,
              -0.6389049887657166,
              0,
              -0.9341109991073608,
              0.3569450080394745,
              -0.0019837000872939825,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              0.018250100314617157,
              0.9995419979095459,
              -0.02334669977426529,
              -0.04583879932761192,
              0.9983829855918884,
              -0.03274640068411827,
              0.018250100314617157,
              0.9995419979095459,
              -0.02334669977426529,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              -0.04583879932761192,
              0.9983829855918884,
              -0.03274640068411827,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              -0.4201180040836334,
              -0.6695150136947632,
              0.6125370264053345,
              -0.8414559960365295,
              0.476485013961792,
              -0.2546460032463074,
              -0.7692499756813049,
              -0.6389049887657166,
              0,
              -0.7548750042915344,
              -0.6557819843292236,
              0.006439410150051117,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.8414559960365295,
              0.476485013961792,
              -0.2546460032463074,
              -0.4200870096683502,
              -0.8812829852104187,
              0.21634599566459656,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              -0.7548750042915344,
              -0.6557819843292236,
              0.006439410150051117,
              -0.42014798521995544,
              -0.8812519907951355,
              -0.21637600660324097,
              -0.8846399784088135,
              0.4590289890766144,
              0.08151490241289139,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              0.041016899049282074,
              0.9850760102272034,
              0.16702799499034882,
              -0.01922670006752014,
              0.9831839799880981,
              0.18152399361133575,
              0.041016899049282074,
              0.9850760102272034,
              0.16702799499034882,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              -0.01922670006752014,
              0.9831839799880981,
              0.18152399361133575,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              -0.7548450231552124,
              -0.6558120250701904,
              -0.006439410150051117,
              -0.9341109991073608,
              0.3569450080394745,
              -0.0019837000872939825,
              -0.42014798521995544,
              -0.8812519907951355,
              -0.21637600660324097,
              -0.4201180040836334,
              -0.6695759892463684,
              -0.6124759912490845,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.9341109991073608,
              0.3569450080394745,
              -0.0019837000872939825,
              -0.7692499756813049,
              -0.6389049887657166,
              0,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.4201180040836334,
              -0.6695759892463684,
              -0.6124759912490845,
              -0.7548750042915344,
              -0.35889801383018494,
              -0.5489060282707214,
              -0.8846399784088135,
              0.32282501459121704,
              0.33640599250793457,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              0.041016899049282074,
              0.6861780285835266,
              0.7262489795684814,
              -0.01922670006752014,
              0.6971650123596191,
              0.7166360020637512,
              0.041016899049282074,
              0.6861780285835266,
              0.7262489795684814,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              -0.01922670006752014,
              0.6971650123596191,
              0.7166360020637512,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.7548750042915344,
              -0.6557819843292236,
              0.006439410150051117,
              -0.8846399784088135,
              0.4590289890766144,
              0.08151490241289139,
              -0.7548750042915344,
              -0.35889801383018494,
              -0.5489060282707214,
              -0.7692800164222717,
              -0.35486900806427,
              -0.5312659740447998,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.8846399784088135,
              0.4590289890766144,
              0.08151490241289139,
              -0.42014798521995544,
              -0.8812519907951355,
              -0.21637600660324097,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              -0.7692800164222717,
              -0.35486900806427,
              -0.5312659740447998,
              -0.7548750042915344,
              -0.3696709871292114,
              -0.541733980178833,
              -0.9340800046920776,
              0.19666099548339844,
              0.2979219853878021,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              0.018250100314617157,
              0.5358740091323853,
              0.8440809845924377,
              0.05142369866371155,
              0.40806299448013306,
              0.9114959836006165,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              -0.4201180040836334,
              -0.6695759892463684,
              -0.6124759912490845,
              -0.8846399784088135,
              0.32282501459121704,
              0.33640599250793457,
              -0.7548750042915344,
              -0.3696709871292114,
              -0.541733980178833,
              -0.41062700748443604,
              -0.3127230107784271,
              -0.8564710021018982,
              -0.8873260021209717,
              0.1795099973678589,
              0.4246959984302521,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              -0.048158198595047,
              0.5179299712181091,
              0.8540300130844116,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.8846399784088135,
              0.32282501459121704,
              0.33640599250793457,
              -0.7548750042915344,
              -0.35889801383018494,
              -0.5489060282707214,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              0.011627599596977234,
              0.4713580012321472,
              0.8818320035934448,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              -0.7692800164222717,
              -0.35486900806427,
              -0.5312659740447998,
              -0.9340800046920776,
              0.19666099548339844,
              0.2979219853878021,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              0.10177899897098541,
              0.46894699335098267,
              0.8773159980773926,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.9825429916381836,
              -0.03625600039958954,
              -0.18243999779224396,
              0.7549669742584229,
              0.06424149870872498,
              0.6525470018386841,
              0.9825429916381836,
              0,
              -0.18601000308990479,
              0.9825429916381836,
              -0.03625600039958954,
              -0.18243999779224396,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.7549669742584229,
              0.06424149870872498,
              0.6525470018386841,
              0.9825130105018616,
              0.036316998302936554,
              -0.18243999779224396,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811,
              0.7549669742584229,
              -0.1903740018606186,
              0.6274610161781311,
              0.9825130105018616,
              0.0712302029132843,
              -0.17184999585151672,
              0.7549669742584229,
              -0.1903740018606186,
              0.6274610161781311,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.9825130105018616,
              0.10339699685573578,
              -0.15466800332069397,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.7549669742584229,
              -0.41599801182746887,
              0.5068510174751282,
              0.9825130105018616,
              0.13165700435638428,
              -0.13153499364852905,
              0.7549669742584229,
              -0.41599801182746887,
              0.5068510174751282,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.9825130105018616,
              0.1548510044813156,
              -0.10333599895238876,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.7549669742584229,
              -0.5783259868621826,
              0.3090910017490387,
              0.9825429916381836,
              0.1717890053987503,
              -0.07116919755935669,
              0.7549669742584229,
              -0.5783259868621826,
              0.3090910017490387,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.9825429916381836,
              0.18237900733947754,
              -0.036286499351263046,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.7549359798431396,
              -0.6526079773902893,
              0.06424149870872498,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.1943719983100891,
              -0.6910610198974609,
              0.6961269974708557,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              0.9825429916381836,
              0.18591900169849396,
              0,
              0.7549359798431396,
              -0.6526079773902893,
              0.06424149870872498,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.9825429916381836,
              0.18237900733947754,
              0.036286499351263046,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.7549669742584229,
              -0.6274909973144531,
              -0.19034400582313538,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.19327400624752045,
              -0.9811090230941772,
              -0.006530960090458393,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              0.9825429916381836,
              0.1717890053987503,
              0.07116919755935669,
              0.7549669742584229,
              -0.6274909973144531,
              -0.19034400582313538,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.20148299634456635,
              -0.9174169898033142,
              0.3430890142917633,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              0.19635599851608276,
              -0.40061599016189575,
              0.8949549794197083,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.9825429916381836,
              0.15463699400424957,
              0.10333599895238876,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.7549669742584229,
              -0.5069119930267334,
              -0.4159669876098633,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.19983500242233276,
              -0.8997470140457153,
              -0.38795098662376404,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.008728289976716042,
              -0.36664900183677673,
              0.930296003818512,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              0.07782220095396042,
              -0.13687600195407867,
              0.9875180125236511,
              -0.008728289976716042,
              -0.36664900183677673,
              0.930296003818512,
              0.9825130105018616,
              0.13153499364852905,
              0.13162599503993988,
              0.7549669742584229,
              -0.5069119930267334,
              -0.4159669876098633,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              0.20148299634456635,
              -0.9174169898033142,
              0.3430890142917633,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              0.1943719983100891,
              -0.6910610198974609,
              0.6961269974708557,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.9823600053787231,
              0.10498400032520294,
              0.15460699796676636,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.7582020163536072,
              -0.30542901158332825,
              -0.5760059952735901,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.1943719983100891,
              -0.3786740005016327,
              -0.9048740267753601,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              0.9763479828834534,
              0.08371230214834213,
              0.19925500452518463,
              0.7582020163536072,
              -0.30542901158332825,
              -0.5760059952735901,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.19635599851608276,
              -0.6735129952430725,
              -0.7125769853591919,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              0.19327400624752045,
              -0.9811090230941772,
              -0.006530960090458393,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.9762259721755981,
              0.04226810112595558,
              0.21253100037574768,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.7614369988441467,
              -0.063509002327919,
              -0.6450999975204468,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.20142200589179993,
              0.021118799224495888,
              -0.979246973991394,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              0.9761959910392761,
              0,
              0.21686500310897827,
              0.7614369988441467,
              -0.063509002327919,
              -0.6450999975204468,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              0.19635599851608276,
              -0.6735129952430725,
              -0.7125769853591919,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              0.19983500242233276,
              -0.8997470140457153,
              -0.38795098662376404,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.9762259721755981,
              -0.04220709949731827,
              0.21253100037574768,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.7606430053710938,
              0.18808600306510925,
              -0.6212959885597229,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              0.1943719983100891,
              0.6910610198974609,
              -0.6961269974708557,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              0.9763479828834534,
              -0.08371230214834213,
              0.19925500452518463,
              0.7606430053710938,
              0.18808600306510925,
              -0.6212959885597229,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.19635599851608276,
              0.40058600902557373,
              -0.8949549794197083,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              0.1943719983100891,
              -0.3786740005016327,
              -0.9048740267753601,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.9823909997940063,
              -0.10474000126123428,
              0.15460699796676636,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.7557600140571594,
              0.41508200764656067,
              -0.5064240097999573,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.20148299634456635,
              0.9174169898033142,
              -0.3431200087070465,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              0.9824820160865784,
              -0.1317790001630783,
              0.13162599503993988,
              0.7557600140571594,
              0.41508200764656067,
              -0.5064240097999573,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              0.19635599851608276,
              0.40058600902557373,
              -0.8949549794197083,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              0.20142200589179993,
              0.021118799224495888,
              -0.979246973991394,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.9825739860534668,
              -0.15442399680614471,
              0.10333599895238876,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.7549970149993896,
              0.5782650113105774,
              -0.3090910017490387,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.19983500242233276,
              0.8997470140457153,
              0.387921005487442,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              0.9825429916381836,
              -0.1717890053987503,
              0.07116919755935669,
              0.7549970149993896,
              0.5782650113105774,
              -0.3090910017490387,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.19327400624752045,
              0.9811090230941772,
              0.0065004401840269566,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              0.1943719983100891,
              0.6910610198974609,
              -0.6961269974708557,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              0.9825429916381836,
              -0.18237900733947754,
              0.036286499351263046,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.7550280094146729,
              0.6525160074234009,
              -0.06427200138568878,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              0.19635599851608276,
              0.6735129952430725,
              0.7125769853591919,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              0.9825429916381836,
              -0.18591900169849396,
              0,
              0.7550280094146729,
              0.6525160074234009,
              -0.06427200138568878,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              0.19327400624752045,
              0.9811090230941772,
              0.0065004401840269566,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              0.20148299634456635,
              0.9174169898033142,
              -0.3431200087070465,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.9825429916381836,
              -0.18237900733947754,
              -0.036286499351263046,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.7549970149993896,
              0.6274610161781311,
              0.19034400582313538,
              0.20142200589179993,
              -0.021118799224495888,
              0.979246973991394,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              0.9825429916381836,
              -0.1717890053987503,
              -0.07116919755935669,
              0.7549970149993896,
              0.6274610161781311,
              0.19034400582313538,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.07815790176391602,
              0.3086639940738678,
              0.9479349851608276,
              0.1943719983100891,
              0.3786740005016327,
              0.9048740267753601,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              0.19983500242233276,
              0.8997470140457153,
              0.387921005487442,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.9825429916381836,
              -0.15463699400424957,
              -0.10333599895238876,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.7549970149993896,
              0.5068510174751282,
              0.4159669876098633,
              0.9825429916381836,
              -0.13141299784183502,
              -0.13153499364852905,
              0.7549970149993896,
              0.5068510174751282,
              0.4159669876098633,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              0.19635599851608276,
              0.6735129952430725,
              0.7125769853591919,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              0.9825130105018616,
              -0.10339699685573578,
              -0.15466800332069397,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.7549669742584229,
              0.3090910017490387,
              0.5782949924468994,
              0.9825429916381836,
              -0.07113859802484512,
              -0.17184999585151672,
              0.7549669742584229,
              0.3090910017490387,
              0.5782949924468994,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              -0.8846399784088135,
              -0.18723100423812866,
              0.4270150065422058,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              0.05142369866371155,
              0.40806299448013306,
              0.9114959836006165,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              0.05142369866371155,
              0.40806299448013306,
              0.9114959836006165,
              0.059328000992536545,
              0.1560720056295395,
              0.9859309792518616,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              0.2987760007381439,
              0.1877799928188324,
              0.9356359839439392,
              0.1943719983100891,
              0.3786740005016327,
              0.9048740267753601,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.03610340133309364,
              0.5102999806404114,
              0.8592180013656616,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              -0.03610340133309364,
              0.5102999806404114,
              0.8592180013656616,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              0.07782220095396042,
              -0.13687600195407867,
              0.9875180125236511,
              0.013916400261223316,
              0.1333959996700287,
              0.9909359812736511,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              0.035798199474811554,
              0.34098300337791443,
              0.9393600225448608,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              -0.06088440120220184,
              0.4923250079154968,
              0.8682519793510437,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.06088440120220184,
              0.4923250079154968,
              0.8682519793510437,
              0.2987760007381439,
              0.1877799928188324,
              0.9356359839439392,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              0.07815790176391602,
              0.3086639940738678,
              0.9479349851608276,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              0.07815790176391602,
              0.3086639940738678,
              0.9479349851608276,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              -0.06088440120220184,
              0.4923250079154968,
              0.8682519793510437,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.09915459901094437,
              0.3223060071468353,
              0.9414039850234985,
              -0.08966340124607086,
              0.4821920096874237,
              0.8714560270309448,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              -0.03387549892067909,
              -0.3325909972190857,
              0.942441999912262,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              0.05960259959101677,
              -0.12128099799156189,
              0.9908139705657959,
              -0.03387549892067909,
              -0.3325909972190857,
              0.942441999912262,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              0.0209966991096735,
              -0.532181978225708,
              0.8463389873504639,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              0.20142200589179993,
              -0.021118799224495888,
              0.979246973991394,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              -0.9340800046920776,
              0.19666099548339844,
              0.2979219853878021,
              -0.13815699517726898,
              0.5256509780883789,
              0.8393809795379639,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              -0.8846399784088135,
              0.32282501459121704,
              0.33640599250793457,
              -0.9349650144577026,
              0.20136100053787231,
              0.29197099804878235,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              0.1608940064907074,
              0.5482959747314453,
              0.8206120133399963,
              0.19635599851608276,
              0.6735129952430725,
              0.7125769853591919,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.01922670006752014,
              0.6971650123596191,
              0.7166360020637512,
              0.0209966991096735,
              0.5782650113105774,
              0.8155459761619568,
              -0.03610340133309364,
              0.5102999806404114,
              0.8592180013656616,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.008728289976716042,
              0.7218539714813232,
              0.6919460296630859,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              -0.06088440120220184,
              0.4923250079154968,
              0.8682519793510437,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.03387549892067909,
              0.7448650002479553,
              0.6663110256195068,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              -0.8846399784088135,
              0.32282501459121704,
              0.33640599250793457,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              0.1608940064907074,
              0.5482959747314453,
              0.8206120133399963,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              -0.008728289976716042,
              0.7218539714813232,
              0.6919460296630859,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.03387549892067909,
              0.7448650002479553,
              0.6663110256195068,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              -0.12762799859046936,
              0.6139410138130188,
              0.7789239883422852,
              -0.07290869951248169,
              0.7550280094146729,
              0.6516010165214539,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              0.035798199474811554,
              0.34098300337791443,
              0.9393600225448608,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              0.035798199474811554,
              0.34098300337791443,
              0.9393600225448608,
              -0.06088440120220184,
              0.4923250079154968,
              0.8682519793510437,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              0.041016899049282074,
              0.6861780285835266,
              0.7262489795684814,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              -0.13400700688362122,
              0.6902679800987244,
              0.7109900116920471,
              -0.12494300305843353,
              0.5731070041656494,
              0.8098700046539307,
              -0.8846399784088135,
              0.4590289890766144,
              0.08151490241289139,
              -0.8473160266876221,
              0.4683679938316345,
              0.25031301379203796,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              0.2987459897994995,
              0.7925350069999695,
              0.5315709710121155,
              0.19983500242233276,
              0.8997470140457153,
              0.387921005487442,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              -0.01922670006752014,
              0.6971650123596191,
              0.7166360020637512,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.01922670006752014,
              0.9831839799880981,
              0.18152399361133575,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              -0.008728289976716042,
              0.7218539714813232,
              0.6919460296630859,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.008728289976716042,
              0.9763789772987366,
              0.21576599776744843,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              -0.03387549892067909,
              0.7448650002479553,
              0.6663110256195068,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              -0.03387549892067909,
              0.9678639769554138,
              0.2491230070590973,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              -0.8846399784088135,
              0.4590289890766144,
              0.08151490241289139,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              0.2987459897994995,
              0.7925350069999695,
              0.5315709710121155,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              -0.008728289976716042,
              0.9763789772987366,
              0.21576599776744843,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.03387549892067909,
              0.9678639769554138,
              0.2491230070590973,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              -0.0791039988398552,
              0.8791469931602478,
              0.4698930084705353,
              -0.07290869951248169,
              0.9612410068511963,
              0.26578599214553833,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              -0.03387549892067909,
              0.7448650002479553,
              0.6663110256195068,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              0.041016899049282074,
              0.9850760102272034,
              0.16702799499034882,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              -0.13400700688362122,
              0.9746699929237366,
              0.17892999947071075,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              -0.9341109991073608,
              0.3569450080394745,
              -0.0019837000872939825,
              -0.9349960088729858,
              0.35456401109695435,
              0.005157629959285259,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              0.20831899344921112,
              0.9595929980278015,
              0.1890619993209839,
              0.19327400624752045,
              0.9811090230941772,
              0.0065004401840269566,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              -0.01922670006752014,
              0.9831839799880981,
              0.18152399361133575,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              -0.04583879932761192,
              0.9983829855918884,
              -0.03274640068411827,
              0.0209966991096735,
              0.9993900060653687,
              0.027710799127817154,
              -0.008728289976716042,
              0.9763789772987366,
              0.21576599776744843,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.03610340133309364,
              0.9979249835014343,
              -0.05304120108485222,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              -0.05899230018258095,
              0.10931699723005295,
              0.992247998714447,
              -0.07614369690418243,
              -0.11316300183534622,
              0.9906309843063354,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              -0.03387549892067909,
              0.9678639769554138,
              0.2491230070590973,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              -0.06088440120220184,
              0.9954530000686646,
              -0.07300029695034027,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              -0.9341109991073608,
              0.3569450080394745,
              -0.0019837000872939825,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              0.20831899344921112,
              0.9595929980278015,
              0.1890619993209839,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              -0.03610340133309364,
              0.9979249835014343,
              -0.05304120108485222,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              -0.06088440120220184,
              0.9954530000686646,
              -0.07300029695034027,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.12762799859046936,
              0.988739013671875,
              0.07773060351610184,
              -0.08966340124607086,
              0.9924619793891907,
              -0.08322399854660034,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              -0.03387549892067909,
              0.9678639769554138,
              0.2491230070590973,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              0.018250100314617157,
              0.9995419979095459,
              -0.02334669977426529,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.13815699517726898,
              0.9899590015411377,
              -0.02926729992032051,
              -0.12494300305843353,
              0.9917910099029541,
              0.026581600308418274,
              -0.8414559960365295,
              0.476485013961792,
              -0.2546460032463074,
              -0.8873260021209717,
              0.45286399126052856,
              -0.0866725966334343,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              0.2082889974117279,
              0.9595929980278015,
              -0.18903200328350067,
              0.20148299634456635,
              0.9174169898033142,
              -0.3431200087070465,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              -0.04583879932761192,
              0.9983829855918884,
              -0.03274640068411827,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              0.00006103699706727639,
              0.88189297914505,
              -0.4713889956474304,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              -0.03610340133309364,
              0.9979249835014343,
              -0.05304120108485222,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              0.010986699722707272,
              0.881862998008728,
              -0.4713580012321472,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              -0.06088440120220184,
              0.9954530000686646,
              -0.07300029695034027,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.014648900367319584,
              0.8818020224571228,
              -0.4713279902935028,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              -0.8414559960365295,
              0.476485013961792,
              -0.2546460032463074,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              0.2082889974117279,
              0.9595929980278015,
              -0.18903200328350067,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.010986699722707272,
              0.881862998008728,
              -0.4713580012321472,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.014648900367319584,
              0.8818020224571228,
              -0.4713279902935028,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.09918519854545593,
              0.9613639712333679,
              -0.25666099786758423,
              -0.06134219840168953,
              0.8802449703216553,
              -0.4705039858818054,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              -0.06088440120220184,
              0.9954530000686646,
              -0.07300029695034027,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              0.056550800800323486,
              0.8804900050163269,
              -0.47062599658966064,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              -0.13242000341415405,
              0.8741419911384583,
              -0.46723800897598267,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.9340800046920776,
              0.19998799264431,
              -0.2957240045070648,
              -0.8873260021209717,
              0.32364898920059204,
              -0.3283790051937103,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              0.2987459897994995,
              0.7925660014152527,
              -0.5315709710121155,
              0.1943719983100891,
              0.6910610198974609,
              -0.6961269974708557,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              0.00006103699706727639,
              0.88189297914505,
              -0.4713889956474304,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              -0.04583879932761192,
              0.5819270014762878,
              -0.8119140267372131,
              0.05157630145549774,
              0.6902980208396912,
              -0.7216410040855408,
              0.010986699722707272,
              0.881862998008728,
              -0.4713580012321472,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.03610340133309364,
              0.5985289812088013,
              -0.8002560138702393,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              -0.014648900367319584,
              0.8818020224571228,
              -0.4713279902935028,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.06088440120220184,
              0.6137580275535583,
              -0.787132978439331,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              -0.9340800046920776,
              0.19998799264431,
              -0.2957240045070648,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              0.2987459897994995,
              0.7925660014152527,
              -0.5315709710121155,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              -0.03610340133309364,
              0.5985289812088013,
              -0.8002560138702393,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.06088440120220184,
              0.6137580275535583,
              -0.787132978439331,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -0.09918519854545593,
              0.7475200295448303,
              -0.6567280292510986,
              -0.08966340124607086,
              0.6205940246582031,
              -0.778954029083252,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              -0.014648900367319584,
              0.8818020224571228,
              -0.4713279902935028,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              0.018250100314617157,
              0.5747249722480774,
              -0.8181099891662598,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.13815699517726898,
              0.5743280053138733,
              -0.8068479895591736,
              -0.1091649979352951,
              0.6874899864196777,
              -0.7179170250892639,
              -0.8846399784088135,
              0.18723100423812866,
              -0.4269840121269226,
              -0.9349650144577026,
              0.19269399344921112,
              -0.2977690100669861,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              0.160862997174263,
              0.5483260154724121,
              -0.8206120133399963,
              0.19635599851608276,
              0.40058600902557373,
              -0.8949549794197083,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              -0.04583879932761192,
              0.5819270014762878,
              -0.8119140267372131,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.019196100533008575,
              0.4004330039024353,
              -0.9161049723625183,
              0.0209966991096735,
              0.532181978225708,
              -0.8463389873504639,
              -0.03610340133309364,
              0.5985289812088013,
              -0.8002560138702393,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.008728289976716042,
              0.36664900183677673,
              -0.930296003818512,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              -0.06088440120220184,
              0.6137580275535583,
              -0.787132978439331,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -0.033844999969005585,
              0.3325909972190857,
              -0.942441999912262,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              0.01193269994109869,
              -0.29026201367378235,
              0.9568470120429993,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0.01193269994109869,
              -0.29026201367378235,
              0.9568470120429993,
              -0.8846399784088135,
              0.18723100423812866,
              -0.4269840121269226,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              0.160862997174263,
              0.5483260154724121,
              -0.8206120133399963,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              -0.008728289976716042,
              0.36664900183677673,
              -0.930296003818512,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              -0.033844999969005585,
              0.3325909972190857,
              -0.942441999912262,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.12762799859046936,
              0.4846949875354767,
              -0.8652909994125366,
              -0.07287819683551788,
              0.3143410086631775,
              -0.9465010166168213,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              -0.06088440120220184,
              0.6137580275535583,
              -0.787132978439331,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              0.04092530161142349,
              0.414777010679245,
              -0.9089940190315247,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              -0.13327400386333466,
              0.4082159996032715,
              -0.9030730128288269,
              -0.12494300305843353,
              0.5288860201835632,
              -0.8394119739532471,
              -0.8652610182762146,
              -0.10425099730491638,
              -0.49031001329421997,
              -0.8693199753761292,
              0.09997860342264175,
              -0.4839929938316345,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              0.2987149953842163,
              0.1878110021352768,
              -0.9356669783592224,
              0.20142200589179993,
              0.021118799224495888,
              -0.979246973991394,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              -0.019196100533008575,
              0.4004330039024353,
              -0.9161049723625183,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              0.00320443999953568,
              -0.14941899478435516,
              -0.988739013671875,
              0.07626579701900482,
              0.15274499356746674,
              -0.985289990901947,
              -0.008728289976716042,
              0.36664900183677673,
              -0.930296003818512,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              0.013916400261223316,
              -0.1333959996700287,
              -0.9909359812736511,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              -0.033844999969005585,
              0.3325909972190857,
              -0.942441999912262,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.011688600294291973,
              -0.11755699664354324,
              -0.9929810166358948,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              0.9825130105018616,
              0.0712302029132843,
              -0.17184999585151672,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.9825130105018616,
              0.10339699685573578,
              -0.15466800332069397,
              -0.8652610182762146,
              -0.10425099730491638,
              -0.49031001329421997,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              0.2987149953842163,
              0.1878110021352768,
              -0.9356669783592224,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.013916400261223316,
              -0.1333959996700287,
              -0.9909359812736511,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.011688600294291973,
              -0.11755699664354324,
              -0.9929810166358948,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.07617419958114624,
              0.11316300183534622,
              -0.9906309843063354,
              -0.05899230018258095,
              -0.10931699723005295,
              -0.992247998714447,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              -0.033844999969005585,
              0.3325909972190857,
              -0.942441999912262,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              0.059328000992536545,
              -0.1560720056295395,
              -0.9859309792518616,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              -0.13007000088691711,
              -0.15860499441623688,
              -0.9787290096282959,
              -0.096316397190094,
              0.15704800188541412,
              -0.9828490018844604,
              0.9825130105018616,
              0.13165700435638428,
              -0.13153499364852905,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.9825130105018616,
              0.1548510044813156,
              -0.10333599895238876,
              -0.9340800046920776,
              -0.19666099548339844,
              -0.2979219853878021,
              -0.8873260021209717,
              -0.17947900295257568,
              -0.4247260093688965,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              0.2987149953842163,
              -0.1878110021352768,
              -0.9356669783592224,
              0.1943719983100891,
              -0.3786740005016327,
              -0.9048740267753601,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              0.00320443999953568,
              -0.14941899478435516,
              -0.988739013671875,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.04583879932761192,
              -0.5274209976196289,
              -0.8483229875564575,
              0.05142369866371155,
              -0.40806299448013306,
              -0.9114959836006165,
              0.013916400261223316,
              -0.1333959996700287,
              -0.9909359812736511,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.03610340133309364,
              -0.5102999806404114,
              -0.8592180013656616,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              -0.011688600294291973,
              -0.11755699664354324,
              -0.9929810166358948,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.06088440120220184,
              -0.4923250079154968,
              -0.8682519793510437,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              0.9825429916381836,
              0.1717890053987503,
              -0.07116919755935669,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.9825429916381836,
              0.18237900733947754,
              -0.036286499351263046,
              -0.9340800046920776,
              -0.19666099548339844,
              -0.2979219853878021,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              0.2987149953842163,
              -0.1878110021352768,
              -0.9356669783592224,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              -0.03610340133309364,
              -0.5102999806404114,
              -0.8592180013656616,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.06088440120220184,
              -0.4923250079154968,
              -0.8682519793510437,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              -0.09915459901094437,
              -0.3223060071468353,
              -0.9414039850234985,
              -0.08966340124607086,
              -0.4821920096874237,
              -0.8714560270309448,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              -0.011688600294291973,
              -0.11755699664354324,
              -0.9929810166358948,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              0.018250100314617157,
              -0.5358740091323853,
              -0.8440809845924377,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              -0.13815699517726898,
              -0.5256509780883789,
              -0.8393809795379639,
              -0.10873699933290482,
              -0.41074898838996887,
              -0.9052090048789978,
              0.9825429916381836,
              0.18591900169849396,
              0,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.9825429916381836,
              0.18237900733947754,
              0.036286499351263046,
              -0.8846399784088135,
              -0.32282501459121704,
              -0.33640599250793457,
              -0.9349650144577026,
              -0.2013310045003891,
              -0.29200100898742676,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              0.16083300113677979,
              -0.5483260154724121,
              -0.8206430077552795,
              0.19635599851608276,
              -0.6735129952430725,
              -0.7125769853591919,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              -0.04583879932761192,
              -0.5274209976196289,
              -0.8483229875564575,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.01922670006752014,
              -0.6971650123596191,
              -0.716605007648468,
              0.0209966991096735,
              -0.5782650113105774,
              -0.8155459761619568,
              -0.03610340133309364,
              -0.5102999806404114,
              -0.8592180013656616,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.008728289976716042,
              -0.7218539714813232,
              -0.6919460296630859,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              -0.06088440120220184,
              -0.4923250079154968,
              -0.8682519793510437,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              -0.03387549892067909,
              -0.7448650002479553,
              -0.6663410067558289,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              0.9825429916381836,
              0.1717890053987503,
              0.07116919755935669,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.9825429916381836,
              0.15463699400424957,
              0.10333599895238876,
              -0.8846399784088135,
              -0.32282501459121704,
              -0.33640599250793457,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              0.16083300113677979,
              -0.5483260154724121,
              -0.8206430077552795,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              -0.008728289976716042,
              -0.7218539714813232,
              -0.6919460296630859,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.03387549892067909,
              -0.7448650002479553,
              -0.6663410067558289,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.12762799859046936,
              -0.6139410138130188,
              -0.7789239883422852,
              -0.07290869951248169,
              -0.7550280094146729,
              -0.6516010165214539,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              -0.06088440120220184,
              -0.4923250079154968,
              -0.8682519793510437,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              0.041016899049282074,
              -0.6861780285835266,
              -0.7262489795684814,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              -0.13400700688362122,
              -0.6902679800987244,
              -0.7109900116920471,
              -0.12494300305843353,
              -0.5731070041656494,
              -0.8098700046539307,
              0.9825130105018616,
              0.13153499364852905,
              0.13162599503993988,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.9823600053787231,
              0.10498400032520294,
              0.15460699796676636,
              -0.8846399784088135,
              -0.4590590000152588,
              -0.08151490241289139,
              -0.8473160266876221,
              -0.4683369994163513,
              -0.25031301379203796,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              0.2987149953842163,
              -0.7925660014152527,
              -0.5315709710121155,
              0.19983500242233276,
              -0.8997470140457153,
              -0.38795098662376404,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              -0.01922670006752014,
              -0.6971650123596191,
              -0.716605007648468,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.01922670006752014,
              -0.9831839799880981,
              -0.18152399361133575,
              0.07431259751319885,
              -0.8794819712638855,
              -0.4700770080089569,
              -0.008728289976716042,
              -0.7218539714813232,
              -0.6919460296630859,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.008728289976716042,
              -0.9763789772987366,
              -0.21576599776744843,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              -0.03387549892067909,
              -0.7448650002479553,
              -0.6663410067558289,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.03387549892067909,
              -0.9678639769554138,
              -0.2491530030965805,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              0.9763479828834534,
              0.08371230214834213,
              0.19925500452518463,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.9762259721755981,
              0.04226810112595558,
              0.21253100037574768,
              -0.8846399784088135,
              -0.4590590000152588,
              -0.08151490241289139,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              0.2987149953842163,
              -0.7925660014152527,
              -0.5315709710121155,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              -0.008728289976716042,
              -0.9763789772987366,
              -0.21576599776744843,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.03387549892067909,
              -0.9678639769554138,
              -0.2491530030965805,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.0791039988398552,
              -0.8791469931602478,
              -0.4698930084705353,
              -0.07290869951248169,
              -0.9612410068511963,
              -0.26578599214553833,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              -0.03387549892067909,
              -0.7448650002479553,
              -0.6663410067558289,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              0.041016899049282074,
              -0.9850760102272034,
              -0.16702799499034882,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              -0.13400700688362122,
              -0.9746699929237366,
              -0.17892999947071075,
              -0.09872739762067795,
              -0.877590000629425,
              -0.4690690040588379,
              0.9761959910392761,
              0,
              0.21686500310897827,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.9762259721755981,
              -0.04220709949731827,
              0.21253100037574768,
              -0.9340800046920776,
              -0.3570359945297241,
              0.0019837000872939825,
              -0.9349650144577026,
              -0.35465601086616516,
              -0.005157629959285259,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              0.2082889974117279,
              -0.9595929980278015,
              -0.1890619993209839,
              0.19327400624752045,
              -0.9811090230941772,
              -0.006530960090458393,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              -0.01922670006752014,
              -0.9831839799880981,
              -0.18152399361133575,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              -0.04583879932761192,
              -0.9983829855918884,
              0.03274640068411827,
              0.0209966991096735,
              -0.9993900060653687,
              -0.027710799127817154,
              -0.008728289976716042,
              -0.9763789772987366,
              -0.21576599776744843,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.03610340133309364,
              -0.9979249835014343,
              0.05304120108485222,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              -0.03387549892067909,
              -0.9678639769554138,
              -0.2491530030965805,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.06088440120220184,
              -0.9954530000686646,
              0.07300029695034027,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              0.9763479828834534,
              -0.08371230214834213,
              0.19925500452518463,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.9823909997940063,
              -0.10474000126123428,
              0.15460699796676636,
              -0.9340800046920776,
              -0.3570359945297241,
              0.0019837000872939825,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              0.2082889974117279,
              -0.9595929980278015,
              -0.1890619993209839,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              -0.03610340133309364,
              -0.9979249835014343,
              0.05304120108485222,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              -0.06088440120220184,
              -0.9954530000686646,
              0.07300029695034027,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.12765899300575256,
              -0.988739013671875,
              -0.07773060351610184,
              -0.08966340124607086,
              -0.9924619793891907,
              0.08322399854660034,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              -0.03387549892067909,
              -0.9678639769554138,
              -0.2491530030965805,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              0.018250100314617157,
              -0.9995419979095459,
              0.02334669977426529,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              -0.13815699517726898,
              -0.9899590015411377,
              0.02926729992032051,
              -0.12494300305843353,
              -0.9917910099029541,
              -0.026581600308418274,
              0.9824820160865784,
              -0.1317790001630783,
              0.13162599503993988,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.9825739860534668,
              -0.15442399680614471,
              0.10333599895238876,
              -0.8414869904518127,
              -0.4764550030231476,
              0.2546769976615906,
              -0.8873260021209717,
              -0.45286399126052856,
              0.0866725966334343,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              0.2082889974117279,
              -0.9596239924430847,
              0.18897099792957306,
              0.20148299634456635,
              -0.9174169898033142,
              0.3430890142917633,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              -0.04583879932761192,
              -0.9983829855918884,
              0.03274640068411827,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              0.00006103699706727639,
              -0.88189297914505,
              0.4713889956474304,
              0.05157630145549774,
              -0.983551025390625,
              0.1730400025844574,
              -0.03610340133309364,
              -0.9979249835014343,
              0.05304120108485222,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              0.010986699722707272,
              -0.881862998008728,
              0.4713580012321472,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              -0.06088440120220184,
              -0.9954530000686646,
              0.07300029695034027,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.014648900367319584,
              -0.8818020224571228,
              0.4713279902935028,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              0.9825429916381836,
              -0.1717890053987503,
              0.07116919755935669,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.9825429916381836,
              -0.18237900733947754,
              0.036286499351263046,
              -0.8414869904518127,
              -0.4764550030231476,
              0.2546769976615906,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              0.2082889974117279,
              -0.9596239924430847,
              0.18897099792957306,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              0.010986699722707272,
              -0.881862998008728,
              0.4713580012321472,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.014648900367319584,
              -0.8818020224571228,
              0.4713279902935028,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.09918519854545593,
              -0.9613639712333679,
              0.25666099786758423,
              -0.06134219840168953,
              -0.8802449703216553,
              0.4705039858818054,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              -0.06088440120220184,
              -0.9954530000686646,
              0.07300029695034027,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              0.056550800800323486,
              -0.8804900050163269,
              0.47062599658966064,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              -0.13242000341415405,
              -0.8741419911384583,
              0.46723800897598267,
              -0.10919500142335892,
              -0.9788810014724731,
              0.17276500165462494,
              0.9825429916381836,
              -0.18591900169849396,
              0,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.9825429916381836,
              -0.18237900733947754,
              -0.036286499351263046,
              -0.9340800046920776,
              -0.19992700219154358,
              0.2957240045070648,
              -0.8873260021209717,
              -0.3235880136489868,
              0.32844001054763794,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              0.2987459897994995,
              -0.7925959825515747,
              0.5315099954605103,
              0.1943719983100891,
              -0.6910610198974609,
              0.6961269974708557,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              0.00006103699706727639,
              -0.88189297914505,
              0.4713889956474304,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              -0.04583879932761192,
              -0.5819270014762878,
              0.8119450211524963,
              0.05157630145549774,
              -0.6902980208396912,
              0.7216410040855408,
              0.010986699722707272,
              -0.881862998008728,
              0.4713580012321472,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.03610340133309364,
              -0.5985289812088013,
              0.8002560138702393,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              -0.014648900367319584,
              -0.8818020224571228,
              0.4713279902935028,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.06088440120220184,
              -0.6137580275535583,
              0.787102997303009,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              0.9825429916381836,
              -0.1717890053987503,
              -0.07116919755935669,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.9825429916381836,
              -0.15463699400424957,
              -0.10333599895238876,
              -0.9340800046920776,
              -0.19992700219154358,
              0.2957240045070648,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              0.2987459897994995,
              -0.7925959825515747,
              0.5315099954605103,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              -0.03610340133309364,
              -0.5985289812088013,
              0.8002560138702393,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.06088440120220184,
              -0.6137580275535583,
              0.787102997303009,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              -0.014648900367319584,
              -0.8818020224571228,
              0.4713279902935028,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              0.018250100314617157,
              -0.5747249722480774,
              0.8181099891662598,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              -0.13815699517726898,
              -0.5743280053138733,
              0.8068479895591736,
              -0.10919500142335892,
              -0.6874899864196777,
              0.7179170250892639,
              0.9825429916381836,
              -0.13141299784183502,
              -0.13153499364852905,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.9825130105018616,
              -0.10339699685573578,
              -0.15466800332069397,
              -0.8846399784088135,
              -0.18723100423812866,
              0.4270150065422058,
              -0.9349650144577026,
              -0.19275499880313873,
              0.2977389991283417,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              0.1608940064907074,
              -0.5483869910240173,
              0.8205819725990295,
              0.19635599851608276,
              -0.40061599016189575,
              0.8949549794197083,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              -0.04583879932761192,
              -0.5819270014762878,
              0.8119450211524963,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              0.0209966991096735,
              -0.532181978225708,
              0.8463389873504639,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              0.0209966991096735,
              -0.532181978225708,
              0.8463389873504639,
              -0.03610340133309364,
              -0.5985289812088013,
              0.8002560138702393,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              -0.1130099967122078,
              -0.5028839707374573,
              0.8568990230560303,
              -0.008728289976716042,
              -0.36664900183677673,
              0.930296003818512,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              -0.06088440120220184,
              -0.6137580275535583,
              0.787102997303009,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              -0.12762799859046936,
              -0.4846949875354767,
              0.8652909994125366,
              -0.03387549892067909,
              -0.3325909972190857,
              0.942441999912262,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              0.9825429916381836,
              -0.07113859802484512,
              -0.17184999585151672,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.9825429916381836,
              -0.03625600039958954,
              -0.18243999779224396,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              0.05960259959101677,
              -0.12128099799156189,
              0.9908139705657959,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              -0.03387549892067909,
              -0.3325909972190857,
              0.942441999912262,
              0.05960259959101677,
              -0.12128099799156189,
              0.9908139705657959,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              0.07782220095396042,
              -0.13687600195407867,
              0.9875180125236511,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              -0.008728289976716042,
              -0.36664900183677673,
              0.930296003818512,
              0.07782220095396042,
              -0.13687600195407867,
              0.9875180125236511,
              0.1608940064907074,
              -0.5483869910240173,
              0.8205819725990295,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              0.059328000992536545,
              0.1560720056295395,
              0.9859309792518616,
              -0.10873699933290482,
              0.41074898838996887,
              0.9052090048789978,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              -0.10873699933290482,
              0.41074898838996887,
              0.9052090048789978,
              -0.13007000088691711,
              0.15860499441623688,
              0.9787290096282959,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              -0.06134219840168953,
              -0.8802449703216553,
              0.4705039858818054,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              -0.09918519854545593,
              -0.7475510239601135,
              0.6567280292510986,
              -0.06134219840168953,
              -0.8802449703216553,
              0.4705039858818054,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              -0.06088440120220184,
              -0.6137580275535583,
              0.787102997303009,
              0.005981630180031061,
              -0.4975129961967468,
              0.8673970103263855,
              0.035798199474811554,
              0.34098300337791443,
              0.9393600225448608,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              0.05960259959101677,
              -0.12128099799156189,
              0.9908139705657959,
              -0.011688600294291973,
              0.11755699664354324,
              0.9929810166358948,
              0.2987760007381439,
              -0.18784099817276,
              0.9356359839439392,
              0.20142200589179993,
              -0.021118799224495888,
              0.979246973991394,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              -0.13327400386333466,
              -0.4082159996032715,
              0.9030730128288269,
              -0.12494300305843353,
              -0.5288860201835632,
              0.8394119739532471,
              0.05960259959101677,
              -0.12128099799156189,
              0.9908139705657959,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              -0.06183049827814102,
              -0.12894099950790405,
              0.9897149801254272,
              -0.02291939966380596,
              -0.348581999540329,
              0.9369789958000183,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.19635599851608276,
              -0.40061599016189575,
              0.8949549794197083,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              -0.41065698862075806,
              0.3127230107784271,
              -0.8564410209655762,
              -0.8693199753761292,
              -0.09997860342264175,
              0.4839929938316345,
              -0.6885589957237244,
              0,
              -0.7251499891281128,
              0,
              0.09799490123987198,
              0.9951779842376709,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              -0.00943022035062313,
              0.09799490123987198,
              0.9951170086860657,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              0.011627599596977234,
              0.4713580012321472,
              0.8818320035934448,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              0,
              0.09799490123987198,
              0.9951779842376709,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0.01193269994109869,
              0.4713279902935028,
              0.8818320035934448,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              -0.05648979917168617,
              0.47062599658966064,
              0.8804900050163269,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              0.07126069813966751,
              -0.09775079786777496,
              0.9926450252532959,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              0.01193269994109869,
              0.09799490123987198,
              0.9950860142707825,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              0.01193269994109869,
              0.4713279902935028,
              0.8818320035934448,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0,
              0.7730029821395874,
              0.6343579888343811,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              0.011627599596977234,
              0.7729420065879822,
              0.6343269944190979,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.7549669742584229,
              0.3090910017490387,
              0.5782949924468994,
              0.10177899897098541,
              0.46894699335098267,
              0.8773159980773926,
              0.011627599596977234,
              0.4713580012321472,
              0.8818320035934448,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              0.011627599596977234,
              0.7729420065879822,
              0.6343269944190979,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              -0.00943022035062313,
              0.09799490123987198,
              0.9951170086860657,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              -0.00943022035062313,
              0.4713580012321472,
              0.881862998008728,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              0,
              -0.29026201367378235,
              0.9569380283355713,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0,
              0.09799490123987198,
              0.9951779842376709,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              0,
              -0.29029199481010437,
              0.9569079875946045,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              0,
              0.09799490123987198,
              0.9951779842376709,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              0.7549669742584229,
              0.06424149870872498,
              0.6525470018386841,
              0.7557299733161926,
              0.19006900489330292,
              0.6266670227050781,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              0.011627599596977234,
              0.9568470120429993,
              0.29023098945617676,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              0.06839200109243393,
              0.2895900011062622,
              0.9546800255775452,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              -0.00943022035062313,
              0.4713580012321472,
              0.881862998008728,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              -0.00943022035062313,
              0.7729730010032654,
              0.6343579888343811,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              0.011627599596977234,
              0.4713580012321472,
              0.8818320035934448,
              0,
              0.4713889956474304,
              0.88189297914505,
              0.011627599596977234,
              0.4713580012321472,
              0.8818320035934448,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              0,
              0.4713889956474304,
              0.88189297914505,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              0.01193269994109869,
              0.4713279902935028,
              0.8818320035934448,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              0.01193269994109869,
              0.7729420065879822,
              0.6343269944190979,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0,
              0.9569380283355713,
              0.29026201367378235,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              0.011627599596977234,
              0.9568470120429993,
              0.29023098945617676,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.7549970149993896,
              0.5068510174751282,
              0.4159669876098633,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              0.011627599596977234,
              0.7729420065879822,
              0.6343269944190979,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              0.011627599596977234,
              0.9568470120429993,
              0.29023098945617676,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              -0.18277500569820404,
              0.6826990246772766,
              0.7074189782142639,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              0.01193269994109869,
              0.7729420065879822,
              0.6343269944190979,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              -0.05648979917168617,
              0.7717519998550415,
              0.6333810091018677,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              0.10177899897098541,
              0.46894699335098267,
              0.8773159980773926,
              0.7549669742584229,
              0.3090910017490387,
              0.5782949924468994,
              0.7557299733161926,
              0.41544800996780396,
              0.50621098279953,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              0.07126069813966751,
              0.9926450252532959,
              0.09775079786777496,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.07126069813966751,
              0.9926450252532959,
              0.09775079786777496,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              0.011627599596977234,
              0.9951170086860657,
              -0.09799490123987198,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              -0.05648979917168617,
              0.47062599658966064,
              0.8804900050163269,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              0.10177899897098541,
              0.46894699335098267,
              0.8773159980773926,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              0,
              0.7730029821395874,
              0.6343579888343811,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              -0.00943022035062313,
              0.7729730010032654,
              0.6343579888343811,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              0.06839200109243393,
              0.6328930258750916,
              0.7711719870567322,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              0.062990203499794,
              0.4704430103302002,
              0.880154013633728,
              -0.00943022035062313,
              0.7729730010032654,
              0.6343579888343811,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              -0.00943022035062313,
              0.9568769931793213,
              0.29026201367378235,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              0.01193269994109869,
              0.7729420065879822,
              0.6343269944190979,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              0.01193269994109869,
              0.9568470120429993,
              0.29026201367378235,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              0.011627599596977234,
              0.9951170086860657,
              -0.09799490123987198,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.7549970149993896,
              0.6274610161781311,
              0.19034400582313538,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              0.011627599596977234,
              0.9568470120429993,
              0.29023098945617676,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              0.011627599596977234,
              0.9951170086860657,
              -0.09799490123987198,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              -0.18277500569820404,
              0.9014559984207153,
              0.39231500029563904,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              0.01193269994109869,
              0.9568470120429993,
              0.29026201367378235,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              0.10177899897098541,
              0.768975019454956,
              0.6310920119285583,
              0.7549970149993896,
              0.5068510174751282,
              0.4159669876098633,
              0.7556989789009094,
              0.5775629878044128,
              0.3086639940738678,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              0.011627599596977234,
              0.881862998008728,
              -0.4713580012321472,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              -0.00943022035062313,
              0.9568769931793213,
              0.29026201367378235,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              0.06839200109243393,
              0.8798490166664124,
              0.47029000520706177,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              0.062990203499794,
              0.7714769840240479,
              0.633105993270874,
              -0.00943022035062313,
              0.9568769931793213,
              0.29026201367378235,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              -0.00943022035062313,
              0.9951170086860657,
              -0.09799490123987198,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              0.01193269994109869,
              0.9568470120429993,
              0.29026201367378235,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              0.01193269994109869,
              0.9950860142707825,
              -0.09799490123987198,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0,
              0.88189297914505,
              -0.4713889956474304,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              0.011627599596977234,
              0.881862998008728,
              -0.4713580012321472,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.7550280094146729,
              0.6525160074234009,
              -0.06427200138568878,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              0.011627599596977234,
              0.9951170086860657,
              -0.09799490123987198,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              0.011627599596977234,
              0.881862998008728,
              -0.4713580012321472,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              -0.18277500569820404,
              0.9829710125923157,
              0.017456600442528725,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              0.01193269994109869,
              0.9950860142707825,
              -0.09799490123987198,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              0.7549970149993896,
              0.6274610161781311,
              0.19034400582313538,
              0.7556989789009094,
              0.6517230272293091,
              0.0641803964972496,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              0.011627599596977234,
              0.6343269944190979,
              -0.7729420065879822,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              -0.00943022035062313,
              0.9951170086860657,
              -0.09799490123987198,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              0.06839200109243393,
              0.9928280115127563,
              0.09778130054473877,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              0.062990203499794,
              0.955016016960144,
              0.289682000875473,
              -0.00943022035062313,
              0.9951170086860657,
              -0.09799490123987198,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              -0.00943022035062313,
              0.881862998008728,
              -0.4713580012321472,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              0.01193269994109869,
              0.9950860142707825,
              -0.09799490123987198,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              0.01193269994109869,
              0.8818320035934448,
              -0.4713580012321472,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              0.011627599596977234,
              0.6343269944190979,
              -0.7729420065879822,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.7549970149993896,
              0.5782650113105774,
              -0.3090910017490387,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              0.011627599596977234,
              0.881862998008728,
              -0.4713580012321472,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              0.011627599596977234,
              0.6343269944190979,
              -0.7729420065879822,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              -0.18277500569820404,
              0.9148529767990112,
              -0.3600269854068756,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              0.01193269994109869,
              0.8818320035934448,
              -0.4713580012321472,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              -0.05648979917168617,
              0.8804900050163269,
              -0.47062599658966064,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              0.7550280094146729,
              0.6525160074234009,
              -0.06427200138568878,
              0.7557299733161926,
              0.6266670227050781,
              -0.19009999930858612,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              0.07129120081663132,
              0.47019898891448975,
              -0.8796650171279907,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.07129120081663132,
              0.47019898891448975,
              -0.8796650171279907,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              0.011627599596977234,
              0.29026201367378235,
              -0.9568470120429993,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              -0.00943022035062313,
              0.881862998008728,
              -0.4713580012321472,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              0.06839200109243393,
              0.9546800255775452,
              -0.2895900011062622,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              0.062990203499794,
              0.9931939840316772,
              -0.09781180322170258,
              -0.00943022035062313,
              0.881862998008728,
              -0.4713580012321472,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              -0.00943022035062313,
              0.6343269944190979,
              -0.7729730010032654,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              0.01193269994109869,
              0.8818320035934448,
              -0.4713580012321472,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              0.01193269994109869,
              0.6343269944190979,
              -0.7729420065879822,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              0.011627599596977234,
              0.29026201367378235,
              -0.9568470120429993,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.7557600140571594,
              0.41508200764656067,
              -0.5064240097999573,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              0.011627599596977234,
              0.6343269944190979,
              -0.7729420065879822,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              0.011627599596977234,
              0.29026201367378235,
              -0.9568470120429993,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              -0.18277500569820404,
              0.7074189782142639,
              -0.6826990246772766,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              0.01193269994109869,
              0.6343269944190979,
              -0.7729420065879822,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              0.7549970149993896,
              0.5782650113105774,
              -0.3090910017490387,
              0.7557299733161926,
              0.5061799883842468,
              -0.41544800996780396,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              0.011627599596977234,
              -0.09799490123987198,
              -0.9951170086860657,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              -0.00943022035062313,
              0.6343269944190979,
              -0.7729730010032654,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              0.06839200109243393,
              0.7711719870567322,
              -0.6328930258750916,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              0.062990203499794,
              0.880154013633728,
              -0.4704430103302002,
              -0.00943022035062313,
              0.6343269944190979,
              -0.7729730010032654,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              -0.00943022035062313,
              0.29026201367378235,
              -0.9568769931793213,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              0.01193269994109869,
              0.6343269944190979,
              -0.7729420065879822,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              0.01193269994109869,
              0.29026201367378235,
              -0.9568470120429993,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              0.011627599596977234,
              -0.09799490123987198,
              -0.9951170086860657,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.7606430053710938,
              0.18808600306510925,
              -0.6212959885597229,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              0.011627599596977234,
              0.29026201367378235,
              -0.9568470120429993,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              0.011627599596977234,
              -0.09799490123987198,
              -0.9951170086860657,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              -0.18277500569820404,
              0.39231500029563904,
              -0.9014559984207153,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              0.01193269994109869,
              0.29026201367378235,
              -0.9568470120429993,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              0.7557600140571594,
              0.41508200764656067,
              -0.5064240097999573,
              0.758965015411377,
              0.30500200390815735,
              -0.5752429962158203,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              0.011627599596977234,
              -0.4713580012321472,
              -0.8818320035934448,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              -0.00943022035062313,
              0.29026201367378235,
              -0.9568769931793213,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              0.06839200109243393,
              0.47029000520706177,
              -0.8798490166664124,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              0.062990203499794,
              0.633105993270874,
              -0.7714470028877258,
              -0.00943022035062313,
              0.29026201367378235,
              -0.9568769931793213,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              -0.00943022035062313,
              -0.09799490123987198,
              -0.9951170086860657,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              0.01193269994109869,
              0.29026201367378235,
              -0.9568470120429993,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              0.01193269994109869,
              -0.09799490123987198,
              -0.9950860142707825,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              0.011627599596977234,
              -0.4713580012321472,
              -0.8818320035934448,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.7614369988441467,
              -0.063509002327919,
              -0.6450999975204468,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              0.011627599596977234,
              -0.09799490123987198,
              -0.9951170086860657,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              0.011627599596977234,
              -0.4713580012321472,
              -0.8818320035934448,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              -0.18277500569820404,
              0.017456600442528725,
              -0.9829710125923157,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              0.01193269994109869,
              -0.09799490123987198,
              -0.9950860142707825,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              -0.05648979917168617,
              -0.09784229844808578,
              -0.9935910105705261,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              0.7606430053710938,
              0.18808600306510925,
              -0.6212959885597229,
              0.7621690034866333,
              0.06341750174760818,
              -0.644245982170105,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              0.07126069813966751,
              -0.6327710151672363,
              -0.7710199952125549,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.07126069813966751,
              -0.6327710151672363,
              -0.7710199952125549,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              0.011627599596977234,
              -0.7729420065879822,
              -0.6343269944190979,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              -0.00943022035062313,
              -0.09799490123987198,
              -0.9951170086860657,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              0.06839200109243393,
              0.09778130054473877,
              -0.9928280115127563,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              0.062990203499794,
              0.289682000875473,
              -0.955016016960144,
              -0.00943022035062313,
              -0.09799490123987198,
              -0.9951170086860657,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              -0.00943022035062313,
              -0.4713580012321472,
              -0.881862998008728,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              0.01193269994109869,
              -0.09799490123987198,
              -0.9950860142707825,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              0.01193269994109869,
              -0.4713279902935028,
              -0.8818320035934448,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              0.011627599596977234,
              -0.7729420065879822,
              -0.6343269944190979,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.7582020163536072,
              -0.30542901158332825,
              -0.5760059952735901,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              0.011627599596977234,
              -0.4713580012321472,
              -0.8818320035934448,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              0.011627599596977234,
              -0.7729420065879822,
              -0.6343269944190979,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              -0.18277500569820404,
              -0.3600269854068756,
              -0.9148529767990112,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              0.01193269994109869,
              -0.4713279902935028,
              -0.8818320035934448,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              0.7614369988441467,
              -0.063509002327919,
              -0.6450999975204468,
              0.7613760232925415,
              -0.1878110021352768,
              -0.6204720139503479,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              0.011627599596977234,
              -0.9568470120429993,
              -0.29026201367378235,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              -0.00943022035062313,
              -0.4713580012321472,
              -0.881862998008728,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              0.06839200109243393,
              -0.2895900011062622,
              -0.9546800255775452,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              0.062990203499794,
              -0.09781180322170258,
              -0.9931939840316772,
              -0.00943022035062313,
              -0.4713580012321472,
              -0.881862998008728,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              -0.00943022035062313,
              -0.7729420065879822,
              -0.6343579888343811,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              0.01193269994109869,
              -0.4713279902935028,
              -0.8818320035934448,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              0.01193269994109869,
              -0.7729420065879822,
              -0.6343579888343811,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              0.011627599596977234,
              -0.9568470120429993,
              -0.29026201367378235,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.7549669742584229,
              -0.5069119930267334,
              -0.4159669876098633,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              0.011627599596977234,
              -0.7729420065879822,
              -0.6343269944190979,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              0.011627599596977234,
              -0.9568470120429993,
              -0.29026201367378235,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              -0.18277500569820404,
              -0.6826990246772766,
              -0.7074189782142639,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              0.01193269994109869,
              -0.7729420065879822,
              -0.6343579888343811,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              0.7582020163536072,
              -0.30542901158332825,
              -0.5760059952735901,
              0.7565230131149292,
              -0.41456300020217896,
              -0.5057219862937927,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              0.011627599596977234,
              -0.9951170086860657,
              0.09799490123987198,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              -0.00943022035062313,
              -0.7729420065879822,
              -0.6343579888343811,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              0.06839200109243393,
              -0.6328930258750916,
              -0.7712029814720154,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              0.062990203499794,
              -0.4704430103302002,
              -0.880154013633728,
              -0.00943022035062313,
              -0.7729420065879822,
              -0.6343579888343811,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              -0.00943022035062313,
              -0.9568769931793213,
              -0.29026201367378235,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              0.01193269994109869,
              -0.7729420065879822,
              -0.6343579888343811,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              0.01193269994109869,
              -0.9568470120429993,
              -0.29026201367378235,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              0.011627599596977234,
              -0.9951170086860657,
              0.09799490123987198,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.7549669742584229,
              -0.6274909973144531,
              -0.19034400582313538,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              0.011627599596977234,
              -0.9568470120429993,
              -0.29026201367378235,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              0.011627599596977234,
              -0.9951170086860657,
              0.09799490123987198,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              -0.18277500569820404,
              -0.9014559984207153,
              -0.39231500029563904,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              0.01193269994109869,
              -0.9568470120429993,
              -0.29026201367378235,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              -0.05648979917168617,
              -0.9553819894790649,
              -0.28980401158332825,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              0.7549669742584229,
              -0.5069119930267334,
              -0.4159669876098633,
              0.7557299733161926,
              -0.5775020122528076,
              -0.308694988489151,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              0.07126069813966751,
              -0.9544969797134399,
              0.2895289957523346,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.07126069813966751,
              -0.9544969797134399,
              0.2895289957523346,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              0.011627599596977234,
              -0.881862998008728,
              0.4713279902935028,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              -0.00943022035062313,
              -0.9568769931793213,
              -0.29026201367378235,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              0.06839200109243393,
              -0.8798490166664124,
              -0.47029000520706177,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              0.062990203499794,
              -0.7714769840240479,
              -0.633105993270874,
              -0.00943022035062313,
              -0.9568769931793213,
              -0.29026201367378235,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              -0.00943022035062313,
              -0.9951170086860657,
              0.09799490123987198,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              0.01193269994109869,
              -0.9568470120429993,
              -0.29026201367378235,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              0.01193269994109869,
              -0.9950860142707825,
              0.09799490123987198,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              0.011627599596977234,
              -0.881862998008728,
              0.4713279902935028,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.7549359798431396,
              -0.6526079773902893,
              0.06424149870872498,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              0.011627599596977234,
              -0.9951170086860657,
              0.09799490123987198,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              0.011627599596977234,
              -0.881862998008728,
              0.4713279902935028,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              -0.18277500569820404,
              -0.9829710125923157,
              -0.017456600442528725,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              0.01193269994109869,
              -0.9950860142707825,
              0.09799490123987198,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              0.7549669742584229,
              -0.6274909973144531,
              -0.19034400582313538,
              0.7557299733161926,
              -0.6516919732093811,
              -0.0641803964972496,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.062990203499794,
              -0.6331369876861572,
              0.7714470028877258,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              0.011627599596977234,
              -0.6343579888343811,
              0.7729420065879822,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              -0.00943022035062313,
              -0.9951170086860657,
              0.09799490123987198,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              0.06839200109243393,
              -0.9928280115127563,
              -0.09778130054473877,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              0.062990203499794,
              -0.955016016960144,
              -0.289682000875473,
              -0.00943022035062313,
              -0.9951170086860657,
              0.09799490123987198,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              -0.00943022035062313,
              -0.881862998008728,
              0.4713580012321472,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              0.01193269994109869,
              -0.9950860142707825,
              0.09799490123987198,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              0.01193269994109869,
              -0.8818320035934448,
              0.4713580012321472,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0,
              -0.6343880295753479,
              0.7729730010032654,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.011627599596977234,
              -0.6343579888343811,
              0.7729420065879822,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.7549669742584229,
              -0.5783259868621826,
              0.3090910017490387,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              0.011627599596977234,
              -0.881862998008728,
              0.4713279902935028,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              0.011627599596977234,
              -0.6343579888343811,
              0.7729420065879822,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              -0.18277500569820404,
              -0.9148529767990112,
              0.3600269854068756,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              0.01193269994109869,
              -0.8818320035934448,
              0.4713580012321472,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              0.7549359798431396,
              -0.6526079773902893,
              0.06424149870872498,
              0.7557600140571594,
              -0.6266369819641113,
              0.19009999930858612,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              -0.00943022035062313,
              -0.29026201367378235,
              0.9568769931793213,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              0,
              -0.29026201367378235,
              0.9569380283355713,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              -0.00943022035062313,
              -0.881862998008728,
              0.4713580012321472,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              0.06839200109243393,
              -0.9546800255775452,
              0.2895900011062622,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              0.062990203499794,
              -0.9931939840316772,
              0.09781180322170258,
              -0.00943022035062313,
              -0.881862998008728,
              0.4713580012321472,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              -0.00943022035062313,
              -0.6343579888343811,
              0.7729730010032654,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              0.062990203499794,
              -0.6331369876861572,
              0.7714470028877258,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              0.01193269994109869,
              -0.8818320035934448,
              0.4713580012321472,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              0.01193269994109869,
              -0.6343269944190979,
              0.7729420065879822,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0.062990203499794,
              -0.28971201181411743,
              0.955016016960144,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              0.011627599596977234,
              -0.29026201367378235,
              0.9568470120429993,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              -0.18277500569820404,
              -0.017456600442528725,
              0.9829710125923157,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0.7549669742584229,
              -0.41599801182746887,
              0.5068510174751282,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              0.011627599596977234,
              -0.6343579888343811,
              0.7729420065879822,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.011627599596977234,
              -0.29026201367378235,
              0.9568470120429993,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              -0.18277500569820404,
              -0.7074189782142639,
              0.6826990246772766,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              0.01193269994109869,
              -0.6343269944190979,
              0.7729420065879822,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              -0.05648979917168617,
              -0.6333510279655457,
              0.7717519998550415,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              0.7549669742584229,
              -0.5783259868621826,
              0.3090910017490387,
              0.7557299733161926,
              -0.5061799883842468,
              0.41544800996780396,
              0,
              -0.6343880295753479,
              0.7729730010032654,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              -0.00943022035062313,
              -0.6343579888343811,
              0.7729730010032654,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              0.06839200109243393,
              -0.7711719870567322,
              0.6328930258750916,
              0.062990203499794,
              -0.6331369876861572,
              0.7714470028877258,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              0.062990203499794,
              -0.6331369876861572,
              0.7714470028877258,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              0.062990203499794,
              -0.880154013633728,
              0.4704430103302002,
              -0.00943022035062313,
              -0.6343579888343811,
              0.7729730010032654,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              -0.00943022035062313,
              -0.29026201367378235,
              0.9568769931793213,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              0.062990203499794,
              -0.28971201181411743,
              0.955016016960144,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.01193269994109869,
              -0.6343269944190979,
              0.7729420065879822,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              0.01193269994109869,
              -0.29026201367378235,
              0.9568470120429993,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.7549669742584229,
              -0.1903740018606186,
              0.6274610161781311,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              -0.18277500569820404,
              -0.39231500029563904,
              0.9014559984207153,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              0,
              -0.6343880295753479,
              0.7729730010032654,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              0,
              -0.29029199481010437,
              0.9569079875946045,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              0.7549669742584229,
              -0.41599801182746887,
              0.5068510174751282,
              0.7557299733161926,
              -0.308694988489151,
              0.5775319933891296,
              0.05142369866371155,
              0.40806299448013306,
              0.9114959836006165,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              0.00320443999953568,
              0.14941899478435516,
              0.988739013671875,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              -0.04583879932761192,
              0.5274209976196289,
              0.8483539819717407,
              0.01193269994109869,
              -0.29026201367378235,
              0.9568470120429993,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              -0.05648979917168617,
              -0.28980401158332825,
              0.9553819894790649,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              0.7549669742584229,
              0.06424149870872498,
              0.6525470018386841,
              0.10177899897098541,
              0.09750659763813019,
              0.9899899959564209,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811,
              -0.05648979917168617,
              -0.6333510279655457,
              0.7717519998550415,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              -0.05648979917168617,
              -0.28980401158332825,
              0.9553819894790649,
              -0.052552901208400726,
              -0.47074800729751587,
              0.8806729912757874,
              -0.18277500569820404,
              0.3600269854068756,
              0.9148529767990112,
              -0.32065799832344055,
              0.1847900003194809,
              0.9289529919624329,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              -0.32065799832344055,
              -0.5262309908866882,
              0.7875300049781799,
              0,
              0.09799490123987198,
              0.9951779842376709,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              0.9825130105018616,
              0.036316998302936554,
              -0.18243999779224396,
              0.9825429916381836,
              0,
              -0.18601000308990479,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811,
              0.9825130105018616,
              0.036316998302936554,
              -0.18243999779224396,
              0.7549669742584229,
              -0.1903740018606186,
              0.6274610161781311,
              0.9825130105018616,
              0.0712302029132843,
              -0.17184999585151672,
              0,
              -0.6343579888343811,
              0.7730029821395874,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              0,
              -0.29026201367378235,
              0.9569380283355713,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              -0.00943022035062313,
              0.09799490123987198,
              0.9951170086860657,
              -0.00943022035062313,
              -0.29026201367378235,
              0.9568769931793213,
              -0.00943022035062313,
              0.09799490123987198,
              0.9951170086860657,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              -0.00943022035062313,
              -0.29026201367378235,
              0.9568769931793213,
              0.0209966991096735,
              -0.532181978225708,
              0.8463389873504639,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              0,
              -0.29029199481010437,
              0.9569079875946045,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              0.011627599596977234,
              -0.29026201367378235,
              0.9568470120429993,
              -0.053712598979473114,
              -0.47068700194358826,
              0.8806419968605042,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              -0.09643849730491638,
              -0.9770200252532959,
              0.1899780035018921,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              -0.09643849730491638,
              -0.7007660269737244,
              0.7068089842796326,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              0,
              0.4713889956474304,
              0.88189297914505,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              -0.00943022035062313,
              0.4713580012321472,
              0.881862998008728,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              0.04092530161142349,
              -0.414777010679245,
              0.9089940190315247,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              0.07626579701900482,
              -0.15274499356746674,
              0.985289990901947,
              0.059328000992536545,
              0.1560720056295395,
              0.9859309792518616,
              -0.096316397190094,
              -0.15704800188541412,
              0.9828490018844604,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              -0.1239359974861145,
              -0.9915459752082825,
              -0.03769040107727051,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              0.03582869842648506,
              -0.9698780179023743,
              0.2408519983291626,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              -0.08487199991941452,
              -0.9708849787712097,
              0.2239139974117279,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              0.03582869842648506,
              -0.7391279935836792,
              0.6725969910621643,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              -0.0490432009100914,
              -0.6067389845848083,
              0.7933589816093445,
              -0.08487199991941452,
              -0.7255780100822449,
              0.682852029800415,
              -0.0047608898021280766,
              -0.88189297914505,
              0.4713580012321472,
              -0.053712598979473114,
              -0.09787289798259735,
              0.9937440156936646,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              0.011627599596977234,
              -0.29026201367378235,
              0.9568470120429993,
              0.011627599596977234,
              0.09799490123987198,
              0.9951170086860657,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              0.011627599596977234,
              -0.29026201367378235,
              0.9568470120429993,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              -0.07751700282096863,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              -0.1239359974861145,
              -0.5822319984436035,
              -0.8034909963607788,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              -0.09625539928674698,
              -0.389629989862442,
              -0.9159219861030579,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              -0.07388529926538467,
              0.14462700486183167,
              -0.9866939783096313,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              -0.1239359974861145,
              0.5195170044898987,
              -0.8453930020332336,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              -0.09643849730491638,
              0.7007660269737244,
              -0.7068089842796326,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              -0.07751700282096863,
              0.8792380094528198,
              0.4699549973011017,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              -0.048158198595047,
              0.5179299712181091,
              0.8540300130844116,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              -0.1239359974861145,
              0.5822319984436035,
              0.8034909963607788,
              -0.048158198595047,
              0.5179299712181091,
              0.8540300130844116,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              -0.048158198595047,
              0.5179299712181091,
              0.8540300130844116,
              -0.09625539928674698,
              0.389629989862442,
              0.9159219861030579,
              0.20148299634456635,
              -0.9174169898033142,
              0.3430890142917633,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              0.005981630180031061,
              -0.9976500272750854,
              -0.06820890307426453,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              -0.0490432009100914,
              -0.9967340230941772,
              0.06369210034608841,
              -0.1130099967122078,
              -0.9918820261955261,
              -0.05792409926652908,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              -0.07287819683551788,
              -0.3143410086631775,
              0.9465010166168213,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              0.08975490182638168,
              -0.10666199773550034,
              0.9902039766311646,
              0.1943719983100891,
              -0.6910610198974609,
              0.6961269974708557,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              -0.08966340124607086,
              -0.6205940246582031,
              0.778954029083252,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.2060610055923462,
              -0.6722620129585266,
              0.7110199928283691,
              0.0636615976691246,
              -0.48023900389671326,
              0.8748130202293396,
              0.1943719983100891,
              -0.3786740005016327,
              -0.9048740267753601,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              -0.06134219840168953,
              -0.8802449703216553,
              0.4705039858818054,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.21372100710868835,
              -0.8971220254898071,
              0.3865779936313629,
              0.07818839699029922,
              -0.7585679888725281,
              0.64683997631073,
              0.19327400624752045,
              -0.9811090230941772,
              -0.006530960090458393,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              -0.08966340124607086,
              -0.9924619793891907,
              0.08322399854660034,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.20297899842262268,
              -0.9791560173034668,
              0.006408889777958393,
              0.07818839699029922,
              -0.9592880010604858,
              0.271340012550354,
              0.19983500242233276,
              -0.8997470140457153,
              -0.38795098662376404,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              -0.07290869951248169,
              -0.9612410068511963,
              -0.26578599214553833,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.21359899640083313,
              -0.9149140119552612,
              -0.34241798520088196,
              0.0636311024427414,
              -0.9941710233688354,
              -0.0867030993103981,
              0.19635599851608276,
              -0.6735129952430725,
              -0.7125769853591919,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              -0.07290869951248169,
              -0.7550280094146729,
              -0.6516010165214539,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.2064879983663559,
              -0.6892300248146057,
              -0.6944490075111389,
              0.08856470137834549,
              -0.878445029258728,
              -0.469527006149292,
              0.20148299634456635,
              0.9174169898033142,
              -0.3431200087070465,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              -0.08966340124607086,
              -0.4821920096874237,
              -0.8714560270309448,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.2060610055923462,
              -0.3996399939060211,
              -0.893185019493103,
              0.0636311024427414,
              -0.624439001083374,
              -0.778436005115509,
              0.20142200589179993,
              0.021118799224495888,
              -0.979246973991394,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              -0.05899230018258095,
              -0.10931699723005295,
              -0.992247998714447,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.21530799567699432,
              -0.020813599228858948,
              -0.9763180017471313,
              0.07815790176391602,
              -0.3086639940738678,
              -0.9479349851608276,
              0.19635599851608276,
              0.40058600902557373,
              -0.8949549794197083,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              -0.07287819683551788,
              0.3143410086631775,
              -0.9465010166168213,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.2064879983663559,
              0.3778190016746521,
              -0.9025239944458008,
              0.08975490182638168,
              0.10666199773550034,
              -0.9902039766311646,
              0.1943719983100891,
              0.6910610198974609,
              -0.6961269974708557,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              -0.08966340124607086,
              0.6205940246582031,
              -0.778954029083252,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              0.2060610055923462,
              0.6722620129585266,
              -0.7110199928283691,
              0.0636615976691246,
              0.48023900389671326,
              -0.8748130202293396,
              0.19327400624752045,
              0.9811090230941772,
              0.0065004401840269566,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              -0.06134219840168953,
              0.8802449703216553,
              -0.4705039858818054,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.21372100710868835,
              0.8971220254898071,
              -0.3865779936313629,
              0.07818839699029922,
              0.7585679888725281,
              -0.6468700170516968,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              0.20142200589179993,
              -0.021118799224495888,
              0.979246973991394,
              -0.08966340124607086,
              0.9924619793891907,
              -0.08322399854660034,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.20297899842262268,
              0.9791560173034668,
              -0.006408889777958393,
              0.07818839699029922,
              0.9592880010604858,
              -0.271340012550354,
              0.19983500242233276,
              0.8997470140457153,
              0.387921005487442,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              -0.07290869951248169,
              0.9612410068511963,
              0.26578599214553833,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.21359899640083313,
              0.9149140119552612,
              0.34241798520088196,
              0.0636615976691246,
              0.9941710233688354,
              0.0867030993103981,
              0.19635599851608276,
              0.6735129952430725,
              0.7125769853591919,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              -0.07290869951248169,
              0.7550280094146729,
              0.6516010165214539,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.0636615976691246,
              0.624439001083374,
              0.778465986251831,
              0.2064879983663559,
              0.6892300248146057,
              0.6944490075111389,
              0.08856470137834549,
              0.878445029258728,
              0.469527006149292,
              0.1943719983100891,
              0.3786740005016327,
              0.9048740267753601,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.2060610055923462,
              0.3996399939060211,
              0.893185019493103,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              0.2987760007381439,
              0.1877799928188324,
              0.9356359839439392,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              -0.18277500569820404,
              -0.39231500029563904,
              0.9014559984207153,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              0.05722219869494438,
              -0.8804590106010437,
              -0.47062599658966064,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              -0.02291939966380596,
              -0.9719840288162231,
              -0.2338629961013794,
              -0.06512650102376938,
              -0.8800320029258728,
              -0.4703820049762726,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              0.005981630180031061,
              -0.6109809875488281,
              -0.7915890216827393,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              -0.02291939966380596,
              -0.7344580292701721,
              -0.6782429814338684,
              -0.1130099967122078,
              -0.5992310047149658,
              -0.7925350069999695,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              0.035798199474811554,
              -0.34098300337791443,
              -0.9393600225448608,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              -0.0490432009100914,
              -0.5007780194282532,
              -0.864162027835846,
              -0.08478040248155594,
              -0.3564560115337372,
              -0.9304479956626892,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              0.05960259959101677,
              0.12128099799156189,
              -0.9908139705657959,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              -0.0023499298840761185,
              -0.12506499886512756,
              -0.9921259880065918,
              -0.06183049827814102,
              0.12894099950790405,
              -0.9897149801254272,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              0.005981630180031061,
              0.4975129961967468,
              -0.8673970103263855,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              -0.02291939966380596,
              0.348581999540329,
              -0.9369789958000183,
              -0.1130099967122078,
              0.5028839707374573,
              -0.8568990230560303,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              0.03582869842648506,
              0.739096999168396,
              -0.6725969910621643,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.0490432009100914,
              0.6067389845848083,
              -0.7933589816093445,
              -0.08487199991941452,
              0.7255780100822449,
              -0.682852029800415,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              0.03582869842648506,
              0.9698780179023743,
              -0.2408519983291626,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              -0.08487199991941452,
              0.9708849787712097,
              -0.2239139974117279,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              0.005981630180031061,
              0.9976500272750854,
              0.06820890307426453,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              -0.0490432009100914,
              0.9967340230941772,
              -0.06369210034608841,
              -0.1130099967122078,
              0.9918820261955261,
              0.05792409926652908,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              0.057191699743270874,
              0.8804590106010437,
              0.47062599658966064,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              -0.02291939966380596,
              0.9719840288162231,
              0.2338629961013794,
              -0.06512650102376938,
              0.8800320029258728,
              0.4703820049762726,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              0.005981630180031061,
              0.6109809875488281,
              0.7915890216827393,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              -0.02291939966380596,
              0.7344580292701721,
              0.6782429814338684,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              0.035798199474811554,
              0.34098300337791443,
              0.9393600225448608,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              -0.0023499298840761185,
              0.12506499886512756,
              0.9921259880065918,
              0.2987760007381439,
              -0.18784099817276,
              0.9356359839439392,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              0,
              -0.29029199481010437,
              0.9569079875946045,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              0.054628100246191025,
              -0.47068700194358826,
              0.8805810213088989,
              -0.00943022035062313,
              -0.29026201367378235,
              0.9568769931793213,
              0.054628100246191025,
              -0.09784229844808578,
              0.9936829805374146,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              0.1608940064907074,
              -0.5483869910240173,
              0.8205819725990295,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              -0.3112890124320984,
              -0.527878999710083,
              0.7901849746704102,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              0.1608940064907074,
              -0.5483869910240173,
              0.8205819725990295,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              0.2987459897994995,
              -0.7925959825515747,
              0.5315099954605103,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              0.2082889974117279,
              -0.9596239924430847,
              0.18897099792957306,
              -0.2614220082759857,
              -0.9470199942588806,
              0.1864680051803589,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.2082889974117279,
              -0.9596239924430847,
              0.18897099792957306,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              0.2082889974117279,
              -0.9595929980278015,
              -0.1890619993209839,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              -0.2614220082759857,
              -0.9470199942588806,
              -0.18640699982643127,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              0.2082889974117279,
              -0.9595929980278015,
              -0.1890619993209839,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              0.2987149953842163,
              -0.7925660014152527,
              -0.5315709710121155,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              -0.14288799464702606,
              -0.8220160007476807,
              -0.5512250065803528,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              0.2987149953842163,
              -0.7925660014152527,
              -0.5315709710121155,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              0.16083300113677979,
              -0.5483260154724121,
              -0.8206430077552795,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              0.2987149953842163,
              -0.1878110021352768,
              -0.9356669783592224,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              -0.14288799464702606,
              -0.19467799365520477,
              -0.9703670144081116,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.2987149953842163,
              -0.1878110021352768,
              -0.9356669783592224,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              0.2987149953842163,
              0.1878110021352768,
              -0.9356669783592224,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              -0.14288799464702606,
              0.19470800459384918,
              -0.9703670144081116,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              0.2987149953842163,
              0.1878110021352768,
              -0.9356669783592224,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              0.160862997174263,
              0.5483260154724121,
              -0.8206120133399963,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              -0.3113189935684204,
              0.5279399752616882,
              -0.7901239991188049,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.160862997174263,
              0.5483260154724121,
              -0.8206120133399963,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              0.2987459897994995,
              0.7925660014152527,
              -0.5315709710121155,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.2082889974117279,
              0.9595929980278015,
              -0.18903200328350067,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              0.20831899344921112,
              0.9595929980278015,
              0.1890619993209839,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              -0.2613910138607025,
              0.9470499753952026,
              0.18640699982643127,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.20831899344921112,
              0.9595929980278015,
              0.1890619993209839,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              0.2987459897994995,
              0.7925350069999695,
              0.5315709710121155,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              -0.14285700023174286,
              0.8220160007476807,
              0.5511950254440308,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              0.2987459897994995,
              0.7925350069999695,
              0.5315709710121155,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              0.1608940064907074,
              0.5482959747314453,
              0.8206120133399963,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.07782220095396042,
              -0.13687600195407867,
              0.9875180125236511,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              -0.0023499298840761185,
              0.14062899351119995,
              0.9900509715080261,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              -0.3112579882144928,
              0.527970016002655,
              0.7901239991188049,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              0.1608940064907074,
              0.5482959747314453,
              0.8206120133399963,
              -0.14282700419425964,
              0.19470800459384918,
              0.9703969955444336,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.2987760007381439,
              0.1877799928188324,
              0.9356359839439392,
              -0.18277500569820404,
              -0.7074189782142639,
              0.6826990246772766,
              -0.07770010083913803,
              -0.7706530094146729,
              0.6324660181999207,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              0.2987760007381439,
              -0.18784099817276,
              0.9356359839439392,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              -0.18277500569820404,
              -0.017456600442528725,
              0.9829710125923157,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.21530799567699432,
              0.020813599228858948,
              0.9763180017471313,
              0.1943719983100891,
              0.3786740005016327,
              0.9048740267753601,
              0.07815790176391602,
              0.3086639940738678,
              0.9479349851608276,
              0.062990203499794,
              -0.6331369876861572,
              0.7714470028877258,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              0.06839200109243393,
              -0.47029000520706177,
              0.8798490166664124,
              0.062990203499794,
              -0.28971201181411743,
              0.955016016960144,
              -0.07770010083913803,
              -0.4699549973011017,
              0.8792380094528198,
              0,
              -0.6343579888343811,
              0.7730029821395874,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0.01193269994109869,
              -0.6343269944190979,
              0.7729420065879822,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              0,
              -0.88189297914505,
              0.4713889956474304,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0.01193269994109869,
              -0.8818320035934448,
              0.4713580012321472,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0.01193269994109869,
              -0.9950860142707825,
              0.09799490123987198,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0.01193269994109869,
              -0.9568470120429993,
              -0.29026201367378235,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0.01193269994109869,
              -0.7729420065879822,
              -0.6343579888343811,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0.01193269994109869,
              -0.4713279902935028,
              -0.8818320035934448,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0.01193269994109869,
              -0.09799490123987198,
              -0.9950860142707825,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0.01193269994109869,
              0.29026201367378235,
              -0.9568470120429993,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0.01193269994109869,
              0.6343269944190979,
              -0.7729420065879822,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0.01193269994109869,
              0.8818320035934448,
              -0.4713580012321472,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0.01193269994109869,
              0.9950860142707825,
              -0.09799490123987198,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0.01193269994109869,
              0.9568470120429993,
              0.29026201367378235,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0,
              0.7730029821395874,
              0.6343880295753479,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0.01193269994109869,
              0.7729420065879822,
              0.6343269944190979,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0,
              0.4713580012321472,
              0.8819239735603333,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0.01193269994109869,
              0.4713279902935028,
              0.8818320035934448,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0.054628100246191025,
              -0.7718440294265747,
              0.6334419846534729,
              0,
              -0.6343880295753479,
              0.7729730010032654,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              0,
              -0.6343880295753479,
              0.7729730010032654,
              -0.053712598979473114,
              -0.7718740105628967,
              0.6334729790687561,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              0.054628100246191025,
              -0.9555040001869202,
              0.28983399271965027,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0,
              -0.8819239735603333,
              0.4713580012321472,
              -0.053712598979473114,
              -0.9555349946022034,
              0.28986498713493347,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              -0.18277500569820404,
              -0.9148529767990112,
              0.3600269854068756,
              -0.07770010083913803,
              -0.9540389776229858,
              0.28937599062919617,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              -0.18277500569820404,
              -0.9829710125923157,
              -0.017456600442528725,
              -0.07770010083913803,
              -0.992156982421875,
              -0.09772030264139175,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              -0.18277500569820404,
              -0.9014559984207153,
              -0.39231500029563904,
              -0.07770010083913803,
              -0.8792380094528198,
              -0.4699549973011017,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              -0.18277500569820404,
              -0.6826990246772766,
              -0.7074189782142639,
              -0.07770010083913803,
              -0.6324660181999207,
              -0.7706530094146729,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              -0.18277500569820404,
              -0.3600269854068756,
              -0.9148529767990112,
              -0.07770010083913803,
              -0.28940701484680176,
              -0.9540389776229858,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              -0.18277500569820404,
              0.017456600442528725,
              -0.9829710125923157,
              -0.07770010083913803,
              0.09772030264139175,
              -0.992156982421875,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              -0.18277500569820404,
              0.39231500029563904,
              -0.9014559984207153,
              -0.07770010083913803,
              0.4699549973011017,
              -0.8792380094528198,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              -0.18277500569820404,
              0.7074189782142639,
              -0.6826990246772766,
              -0.07770010083913803,
              0.7706530094146729,
              -0.6324660181999207,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              -0.18277500569820404,
              0.9148529767990112,
              -0.3600269854068756,
              -0.07770010083913803,
              0.9540389776229858,
              -0.28940701484680176,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              -0.18277500569820404,
              0.9829710125923157,
              0.017456600442528725,
              -0.07770010083913803,
              0.992156982421875,
              0.09768970310688019,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              -0.18277500569820404,
              0.9014559984207153,
              0.39231500029563904,
              -0.07770010083913803,
              0.8792380094528198,
              0.4699549973011017,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              -0.18277500569820404,
              0.6826990246772766,
              0.7074189782142639,
              -0.07770010083913803,
              0.6324660181999207,
              0.7706530094146729,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              -0.18277500569820404,
              0.3600269854068756,
              0.9148529767990112,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              0.062990203499794,
              -0.28971201181411743,
              0.955016016960144,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.06839200109243393,
              -0.09778130054473877,
              0.9928280115127563,
              0.062990203499794,
              0.09781180322170258,
              0.9931939840316772,
              -0.07770010083913803,
              -0.09772030264139175,
              0.992156982421875,
              0.06759849935770035,
              -0.7712330222129822,
              0.6329230070114136,
              0,
              -0.6343579888343811,
              0.7730029821395874,
              0,
              -0.88189297914505,
              0.4713889956474304,
              0,
              -0.6343579888343811,
              0.7730029821395874,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              0,
              -0.88189297914505,
              0.4713889956474304,
              -0.32065799832344055,
              -0.5262309908866882,
              0.7875300049781799,
              -0.18277500569820404,
              -0.39231500029563904,
              0.9014559984207153,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              0.06759849935770035,
              -0.9547410011291504,
              0.2895900011062622,
              0,
              -0.88189297914505,
              0.4713889956474304,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0,
              -0.88189297914505,
              0.4713889956474304,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0.054628100246191025,
              -0.9936829805374146,
              -0.09787289798259735,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              -0.053712598979473114,
              -0.9937440156936646,
              -0.09787289798259735,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0.054628100246191025,
              -0.8805810213088989,
              -0.47068700194358826,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              -0.053712598979473114,
              -0.8806419968605042,
              -0.47071701288223267,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0.054628100246191025,
              -0.6334120035171509,
              -0.7718440294265747,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              -0.053712598979473114,
              -0.6334419846534729,
              -0.7718740105628967,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              0.054628100246191025,
              -0.28983399271965027,
              -0.9555040001869202,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0,
              -0.4713889956474304,
              -0.88189297914505,
              -0.053712598979473114,
              -0.28983399271965027,
              -0.9555349946022034,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0.054628100246191025,
              0.09784229844808578,
              -0.9936829805374146,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              -0.053712598979473114,
              0.09787289798259735,
              -0.9937440156936646,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0.054628100246191025,
              0.47068700194358826,
              -0.8805810213088989,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              -0.053712598979473114,
              0.47068700194358826,
              -0.8806419968605042,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              0.054628100246191025,
              0.7718440294265747,
              -0.6334419846534729,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0,
              0.6343880295753479,
              -0.7730029821395874,
              -0.053712598979473114,
              0.7718740105628967,
              -0.6334729790687561,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0.054628100246191025,
              0.9555040001869202,
              -0.28983399271965027,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0,
              0.88189297914505,
              -0.4713889956474304,
              -0.053712598979473114,
              0.9555349946022034,
              -0.28986498713493347,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0.054628100246191025,
              0.9936829805374146,
              0.09784229844808578,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              -0.053712598979473114,
              0.9937440156936646,
              0.09787289798259735,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0.054628100246191025,
              0.8805810213088989,
              0.47065600752830505,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0,
              0.7730029821395874,
              0.6343579888343811,
              0,
              0.9569380283355713,
              0.29026201367378235,
              -0.053712598979473114,
              0.8806419968605042,
              0.47071701288223267,
              0,
              0.7730029821395874,
              0.6343579888343811,
              0.054628100246191025,
              0.6334419846534729,
              0.7718440294265747,
              0,
              0.7730029821395874,
              0.6343579888343811,
              0,
              0.4713889956474304,
              0.88189297914505,
              0,
              0.7730029821395874,
              0.6343579888343811,
              -0.053712598979473114,
              0.6334729790687561,
              0.7718740105628967,
              0,
              0.4713889956474304,
              0.88189297914505,
              0,
              0.09799490123987198,
              0.9951779842376709,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              0.054628100246191025,
              0.28983399271965027,
              0.9555040001869202,
              0,
              0.4713889956474304,
              0.88189297914505,
              -0.053712598979473114,
              0.28983399271965027,
              0.9555349946022034,
              -0.18277500569820404,
              0.3600269854068756,
              0.9148529767990112,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              -0.07770010083913803,
              0.28940701484680176,
              0.9540389776229858,
              -0.18277500569820404,
              -0.017456600442528725,
              0.9829710125923157,
              -0.32065799832344055,
              -0.1847900003194809,
              0.9289529919624329,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              -0.18277500569820404,
              -0.7074189782142639,
              0.6826990246772766,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              -0.18277500569820404,
              -0.39231500029563904,
              0.9014559984207153,
              -0.32065799832344055,
              -0.5262309908866882,
              0.7875300049781799,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.18277500569820404,
              -0.9148529767990112,
              0.3600269854068756,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              -0.18277500569820404,
              -0.7074189782142639,
              0.6826990246772766,
              -0.32065799832344055,
              -0.7875609993934631,
              0.526199996471405,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              -0.18277500569820404,
              -0.9829710125923157,
              -0.017456600442528725,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              -0.18277500569820404,
              -0.9148529767990112,
              0.3600269854068756,
              -0.32065799832344055,
              -0.9289829730987549,
              0.1847590059041977,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.18277500569820404,
              -0.9014559984207153,
              -0.39231500029563904,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              -0.18277500569820404,
              -0.9829710125923157,
              -0.017456600442528725,
              -0.32062700390815735,
              -0.9289829730987549,
              -0.1847900003194809,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.18277500569820404,
              -0.6826990246772766,
              -0.7074189782142639,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              -0.18277500569820404,
              -0.9014559984207153,
              -0.39231500029563904,
              -0.32065799832344055,
              -0.7875300049781799,
              -0.5262309908866882,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.18277500569820404,
              -0.3600269854068756,
              -0.9148529767990112,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              -0.18277500569820404,
              -0.6826990246772766,
              -0.7074189782142639,
              -0.32062700390815735,
              -0.526199996471405,
              -0.7875609993934631,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.18277500569820404,
              0.017456600442528725,
              -0.9829710125923157,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              -0.18277500569820404,
              -0.3600269854068756,
              -0.9148529767990112,
              -0.32062700390815735,
              -0.1847900003194809,
              -0.9289829730987549,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.18277500569820404,
              0.39231500029563904,
              -0.9014559984207153,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              -0.18277500569820404,
              0.017456600442528725,
              -0.9829710125923157,
              -0.32062700390815735,
              0.1847900003194809,
              -0.9289829730987549,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.18277500569820404,
              0.7074189782142639,
              -0.6826990246772766,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              -0.18277500569820404,
              0.39231500029563904,
              -0.9014559984207153,
              -0.32065799832344055,
              0.5262309908866882,
              -0.7875609993934631,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.18277500569820404,
              0.9148529767990112,
              -0.3600269854068756,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              -0.18277500569820404,
              0.7074189782142639,
              -0.6826990246772766,
              -0.32065799832344055,
              0.7875300049781799,
              -0.5262309908866882,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.18277500569820404,
              0.9829710125923157,
              0.017456600442528725,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              -0.18277500569820404,
              0.9148529767990112,
              -0.3600269854068756,
              -0.32065799832344055,
              0.9289829730987549,
              -0.1847900003194809,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.18277500569820404,
              0.9014559984207153,
              0.39231500029563904,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              -0.18277500569820404,
              0.9829710125923157,
              0.017456600442528725,
              -0.32065799832344055,
              0.9289829730987549,
              0.1847590059041977,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.18277500569820404,
              0.6826990246772766,
              0.7074189782142639,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              -0.18277500569820404,
              0.9014559984207153,
              0.39231500029563904,
              -0.32068800926208496,
              0.7875300049781799,
              0.526199996471405,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.18277500569820404,
              0.3600269854068756,
              0.9148529767990112,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              -0.05648979917168617,
              -0.6333510279655457,
              0.7717519998550415,
              -0.052552901208400726,
              -0.771934986114502,
              0.6335030198097229,
              -0.18277500569820404,
              0.6826990246772766,
              0.7074189782142639,
              -0.32068800926208496,
              0.5262309908866882,
              0.7875300049781799,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              -0.32065799832344055,
              -0.1847900003194809,
              0.9289529919624329,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.18277500569820404,
              -0.017456600442528725,
              0.9829710125923157,
              0.06759849935770035,
              -0.9928889870643616,
              -0.09778130054473877,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0.06759849935770035,
              -0.8798789978027344,
              -0.47029000520706177,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0.06759849935770035,
              -0.6329230070114136,
              -0.7712330222129822,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              0.06759849935770035,
              -0.2896209955215454,
              -0.9547410011291504,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0.06759849935770035,
              0.09778130054473877,
              -0.9928889870643616,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0.06759849935770035,
              0.47032099962234497,
              -0.8798789978027344,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              0.06759849935770035,
              0.7712330222129822,
              -0.6329230070114136,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0.06759849935770035,
              0.9547410011291504,
              -0.2895900011062622,
              0,
              0.88189297914505,
              -0.4713889956474304,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0,
              0.88189297914505,
              -0.4713889956474304,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0.06759849935770035,
              0.9928889870643616,
              0.09778130054473877,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0.06759849935770035,
              0.8798789978027344,
              0.47029000520706177,
              0,
              0.9569380283355713,
              0.29026201367378235,
              0,
              0.7730029821395874,
              0.6343880295753479,
              0,
              0.9569380283355713,
              0.29026201367378235,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              0,
              0.7730029821395874,
              0.6343880295753479,
              0.06759849935770035,
              0.6329230070114136,
              0.7712029814720154,
              0,
              0.7730029821395874,
              0.6343880295753479,
              0,
              0.4713580012321472,
              0.8819239735603333,
              0,
              0.7730029821395874,
              0.6343880295753479,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              0,
              0.4713580012321472,
              0.8819239735603333,
              0,
              0.09799490123987198,
              0.9951779842376709,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              0.06759849935770035,
              0.2896209955215454,
              0.9547410011291504,
              0,
              0.4713580012321472,
              0.8819239735603333,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              0.07126069813966751,
              -0.9544969797134399,
              0.2895289957523346,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              -0.052552901208400726,
              -0.9555960297584534,
              0.28986498713493347,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              0.10660099983215332,
              -0.5523549914360046,
              0.8267459869384766,
              -0.32065799832344055,
              -0.5262309908866882,
              0.7875300049781799,
              0,
              -0.29026201367378235,
              0.9569380283355713,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0.06759849935770035,
              -0.47032099962234497,
              0.8798789978027344,
              0.01193269994109869,
              -0.29026201367378235,
              0.9568470120429993,
              0.06759849935770035,
              -0.09778130054473877,
              0.9928889870643616,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              -0.32065799832344055,
              -0.7875609993934631,
              0.526199996471405,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              -0.32065799832344055,
              -0.9289829730987549,
              0.1847590059041977,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              -0.32062700390815735,
              -0.9289829730987549,
              -0.1847900003194809,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              -0.32065799832344055,
              -0.7875300049781799,
              -0.5262309908866882,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              -0.32062700390815735,
              -0.526199996471405,
              -0.7875609993934631,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              -0.32062700390815735,
              -0.1847900003194809,
              -0.9289829730987549,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              -0.32062700390815735,
              0.1847900003194809,
              -0.9289829730987549,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              -0.32065799832344055,
              0.5262309908866882,
              -0.7875609993934631,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              -0.32065799832344055,
              0.7875300049781799,
              -0.5262309908866882,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              -0.32065799832344055,
              0.9289829730987549,
              -0.1847900003194809,
              0.5169230103492737,
              0.8560140132904053,
              0,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              -0.32065799832344055,
              0.9289829730987549,
              0.1847590059041977,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              -0.32068800926208496,
              0.7875300049781799,
              0.526199996471405,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              -0.32068800926208496,
              0.5262309908866882,
              0.7875300049781799,
              0.4077579975128174,
              0.9130529761314392,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              -1,
              0,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              -0.32065799832344055,
              0.1847900003194809,
              0.9289529919624329,
              -0.32065799832344055,
              -0.7875609993934631,
              0.526199996471405,
              -0.18277500569820404,
              -0.7074189782142639,
              0.6826990246772766,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              0.1943719983100891,
              -0.6910610198974609,
              0.6961269974708557,
              0.2987459897994995,
              -0.7925959825515747,
              0.5315099954605103,
              -0.5160980224609375,
              -0.6017940044403076,
              0.6094549894332886,
              -0.05648979917168617,
              -0.9553819894790649,
              -0.28980401158332825,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              -0.052552901208400726,
              -0.9938049912452698,
              -0.09787289798259735,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              -0.05648979917168617,
              -0.9553819894790649,
              -0.28980401158332825,
              -0.052552901208400726,
              -0.8806729912757874,
              -0.47071701288223267,
              0.07126069813966751,
              -0.6327710151672363,
              -0.7710199952125549,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              -0.052552901208400726,
              -0.6335030198097229,
              -0.771934986114502,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              -0.05648979917168617,
              -0.09784229844808578,
              -0.9935910105705261,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              -0.052552901208400726,
              -0.28986498713493347,
              -0.9555960297584534,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              -0.05648979917168617,
              -0.09784229844808578,
              -0.9935910105705261,
              -0.052552901208400726,
              0.09787289798259735,
              -0.9938049912452698,
              0.07129120081663132,
              0.47019898891448975,
              -0.8796650171279907,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              -0.052552901208400726,
              0.47074800729751587,
              -0.8806729912757874,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              -0.05648979917168617,
              0.8804900050163269,
              -0.47062599658966064,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              -0.052552901208400726,
              0.771934986114502,
              -0.6335030198097229,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              -0.05648979917168617,
              0.8804900050163269,
              -0.47062599658966064,
              -0.052552901208400726,
              0.9555960297584534,
              -0.28986498713493347,
              0.07126069813966751,
              0.9926450252532959,
              0.09775079786777496,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              -0.052552901208400726,
              0.9938049912452698,
              0.09787289798259735,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              -0.05648979917168617,
              0.7717519998550415,
              0.6333810091018677,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              -0.052552901208400726,
              0.8807029724121094,
              0.47071701288223267,
              -0.05648979917168617,
              0.47062599658966064,
              0.8804900050163269,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              0.07126069813966751,
              0.6327710151672363,
              0.7710199952125549,
              -0.05648979917168617,
              0.7717519998550415,
              0.6333810091018677,
              -0.052552901208400726,
              0.6335030198097229,
              0.7719050049781799,
              0.07126069813966751,
              0.2895289957523346,
              0.9544969797134399,
              -0.05648979917168617,
              0.47062599658966064,
              0.8804900050163269,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              -0.05648979917168617,
              0.47062599658966064,
              0.8804900050163269,
              -0.052552901208400726,
              0.28986498713493347,
              0.9555960297584534,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              -0.05648979917168617,
              -0.28980401158332825,
              0.9553819894790649,
              0.07126069813966751,
              -0.09775079786777496,
              0.9926450252532959,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              0.07126069813966751,
              -0.09775079786777496,
              0.9926450252532959,
              -0.05648979917168617,
              0.09784229844808578,
              0.9935910105705261,
              -0.052552901208400726,
              -0.09787289798259735,
              0.9938049912452698,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.18277500569820404,
              -0.39231500029563904,
              0.9014559984207153,
              -0.19525699317455292,
              -0.35929399728775024,
              0.9125639796257019,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              0.5169230103492737,
              -0.32755500078201294,
              0.7908570170402527,
              -0.32065799832344055,
              -0.1847900003194809,
              0.9289529919624329,
              0.19635599851608276,
              -0.40061599016189575,
              0.8949549794197083,
              0.1608940064907074,
              -0.5483869910240173,
              0.8205819725990295,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              -0.19525699317455292,
              -0.6811429858207703,
              0.7055879831314087,
              -0.32065799832344055,
              -0.5262309908866882,
              0.7875300049781799,
              0.5169230103492737,
              -0.6052740216255188,
              0.6052740216255188,
              0.10660099983215332,
              -0.8266850113868713,
              0.5524160265922546,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.32065799832344055,
              -0.7875609993934631,
              0.526199996471405,
              -0.32065799832344055,
              -0.9289829730987549,
              0.1847590059041977,
              -0.18277500569820404,
              -0.9148529767990112,
              0.3600269854068756,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              -0.19525699317455292,
              -0.8993189930915833,
              0.3912169933319092,
              -0.32065799832344055,
              -0.7875609993934631,
              0.526199996471405,
              0.5169230103492737,
              -0.7908570170402527,
              0.32758599519729614,
              0.10663200169801712,
              -0.9751579761505127,
              0.19400599598884583,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              -0.32065799832344055,
              -0.9289829730987549,
              0.1847590059041977,
              -0.32062700390815735,
              -0.9289829730987549,
              -0.1847900003194809,
              -0.18277500569820404,
              -0.9829710125923157,
              -0.017456600442528725,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              -0.19525699317455292,
              -0.9805899858474731,
              0.017273500561714172,
              -0.32065799832344055,
              -0.9289829730987549,
              0.1847590059041977,
              0.5169230103492737,
              -0.8560140132904053,
              0,
              0.10666199773550034,
              -0.9751880168914795,
              -0.1939450055360794,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.32062700390815735,
              -0.9289829730987549,
              -0.1847900003194809,
              -0.32065799832344055,
              -0.7875300049781799,
              -0.5262309908866882,
              -0.18277500569820404,
              -0.9014559984207153,
              -0.39231500029563904,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              -0.19525699317455292,
              -0.9125639796257019,
              -0.35929399728775024,
              -0.32062700390815735,
              -0.9289829730987549,
              -0.1847900003194809,
              0.5169230103492737,
              -0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              -0.8267160058021545,
              -0.5523549914360046,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.32065799832344055,
              -0.7875300049781799,
              -0.5262309908866882,
              -0.32062700390815735,
              -0.526199996471405,
              -0.7875609993934631,
              -0.18277500569820404,
              -0.6826990246772766,
              -0.7074189782142639,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              -0.19525699317455292,
              -0.7055879831314087,
              -0.6811429858207703,
              -0.32065799832344055,
              -0.7875300049781799,
              -0.5262309908866882,
              0.5169230103492737,
              -0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              -0.5523849725723267,
              -0.8267160058021545,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.32062700390815735,
              -0.526199996471405,
              -0.7875609993934631,
              -0.32062700390815735,
              -0.1847900003194809,
              -0.9289829730987549,
              -0.18277500569820404,
              -0.3600269854068756,
              -0.9148529767990112,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              -0.19525699317455292,
              -0.3912169933319092,
              -0.8993189930915833,
              -0.32062700390815735,
              -0.526199996471405,
              -0.7875609993934631,
              0.5169230103492737,
              -0.32755500078201294,
              -0.7908570170402527,
              0.10666199773550034,
              -0.19400599598884583,
              -0.9751579761505127,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.32062700390815735,
              -0.1847900003194809,
              -0.9289829730987549,
              -0.32062700390815735,
              0.1847900003194809,
              -0.9289829730987549,
              -0.18277500569820404,
              0.017456600442528725,
              -0.9829710125923157,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              -0.19525699317455292,
              -0.017273500561714172,
              -0.9805899858474731,
              -0.32062700390815735,
              -0.1847900003194809,
              -0.9289829730987549,
              0.5169230103492737,
              0,
              -0.8560140132904053,
              0.10663200169801712,
              0.1939450055360794,
              -0.9751880168914795,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.32062700390815735,
              0.1847900003194809,
              -0.9289829730987549,
              -0.32065799832344055,
              0.5262309908866882,
              -0.7875609993934631,
              -0.18277500569820404,
              0.39231500029563904,
              -0.9014559984207153,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              -0.19525699317455292,
              0.35929399728775024,
              -0.9125639796257019,
              -0.32062700390815735,
              0.1847900003194809,
              -0.9289829730987549,
              0.5169230103492737,
              0.32758599519729614,
              -0.7908570170402527,
              0.10663200169801712,
              0.5523849725723267,
              -0.8267160058021545,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.32065799832344055,
              0.5262309908866882,
              -0.7875609993934631,
              -0.32065799832344055,
              0.7875300049781799,
              -0.5262309908866882,
              -0.18277500569820404,
              0.7074189782142639,
              -0.6826990246772766,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              -0.19525699317455292,
              0.6811429858207703,
              -0.7055879831314087,
              -0.32065799832344055,
              0.5262309908866882,
              -0.7875609993934631,
              0.5169230103492737,
              0.6052740216255188,
              -0.6052740216255188,
              0.10663200169801712,
              0.8267160058021545,
              -0.5523849725723267,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.32065799832344055,
              0.7875300049781799,
              -0.5262309908866882,
              -0.32065799832344055,
              0.9289829730987549,
              -0.1847900003194809,
              -0.18277500569820404,
              0.9148529767990112,
              -0.3600269854068756,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              -0.19525699317455292,
              0.8993189930915833,
              -0.3912169933319092,
              -0.32065799832344055,
              0.7875300049781799,
              -0.5262309908866882,
              0.5169230103492737,
              0.7908570170402527,
              -0.32758599519729614,
              0.10663200169801712,
              0.9751880168914795,
              -0.1939450055360794,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.32065799832344055,
              0.9289829730987549,
              -0.1847900003194809,
              -0.32065799832344055,
              0.9289829730987549,
              0.1847590059041977,
              -0.18277500569820404,
              0.9829710125923157,
              0.017456600442528725,
              0.5169230103492737,
              0.8560140132904053,
              0,
              -0.19525699317455292,
              0.9805899858474731,
              -0.017273500561714172,
              -0.32065799832344055,
              0.9289829730987549,
              -0.1847900003194809,
              0.5169230103492737,
              0.8560140132904053,
              0,
              0.10660099983215332,
              0.9751880168914795,
              0.1939759999513626,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.32065799832344055,
              0.9289829730987549,
              0.1847590059041977,
              -0.32068800926208496,
              0.7875300049781799,
              0.526199996471405,
              -0.18277500569820404,
              0.9014559984207153,
              0.39231500029563904,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              -0.19525699317455292,
              0.9125639796257019,
              0.35926398634910583,
              -0.32065799832344055,
              0.9289829730987549,
              0.1847590059041977,
              0.5169230103492737,
              0.7908570170402527,
              0.32755500078201294,
              0.10657099634408951,
              0.8266850113868713,
              0.5524160265922546,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.32068800926208496,
              0.7875300049781799,
              0.526199996471405,
              -0.32068800926208496,
              0.5262309908866882,
              0.7875300049781799,
              -0.18277500569820404,
              0.6826990246772766,
              0.7074189782142639,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              -0.19525699317455292,
              0.7055879831314087,
              0.6811429858207703,
              -0.32068800926208496,
              0.7875300049781799,
              0.526199996471405,
              0.5169230103492737,
              0.6052740216255188,
              0.6052740216255188,
              0.10657099634408951,
              0.5523549914360046,
              0.8267459869384766,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.32068800926208496,
              0.5262309908866882,
              0.7875300049781799,
              -0.32065799832344055,
              0.1847900003194809,
              0.9289529919624329,
              -0.18277500569820404,
              0.3600269854068756,
              0.9148529767990112,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              -0.19525699317455292,
              0.3912169933319092,
              0.8993189930915833,
              -0.32068800926208496,
              0.5262309908866882,
              0.7875300049781799,
              0.5169230103492737,
              0.32755500078201294,
              0.7908570170402527,
              0.10660099983215332,
              0.1939450055360794,
              0.9751880168914795,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.32065799832344055,
              0.1847900003194809,
              0.9289529919624329,
              -0.32065799832344055,
              -0.1847900003194809,
              0.9289529919624329,
              -0.18277500569820404,
              -0.017456600442528725,
              0.9829710125923157,
              0.5169230103492737,
              0,
              0.8560140132904053,
              -0.19525699317455292,
              0.017273500561714172,
              0.9805899858474731,
              -0.32065799832344055,
              0.1847900003194809,
              0.9289529919624329,
              0.5169230103492737,
              0,
              0.8560140132904053,
              0.4077579975128174,
              0.6456189751625061,
              0.6456189751625061,
              1,
              0,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              1,
              0,
              0,
              0.4077579975128174,
              0.9130529761314392,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              0.19635599851608276,
              -0.40061599016189575,
              0.8949549794197083,
              -0.5160980224609375,
              -0.33277401328086853,
              0.7892090082168579,
              0.2064879983663559,
              -0.3778190016746521,
              0.9025239944458008,
              0.5169230103492737,
              0,
              0.8560140132904053,
              0.10660099983215332,
              -0.1938840001821518,
              0.9751880168914795,
              -0.32065799832344055,
              -0.1847900003194809,
              0.9289529919624329,
              0.4077579975128174,
              0,
              0.9130529761314392,
              -0.38502201437950134,
              -0.35315999388694763,
              0.8526260256767273,
              1,
              0,
              0,
              -0.38502201437950134,
              -0.35315999388694763,
              0.8526260256767273,
              0.4077579975128174,
              -0.6456189751625061,
              0.6456189751625061,
              1,
              0,
              0,
              1,
              0,
              0,
              0.4077579975128174,
              -0.6456189751625061,
              0.6456189751625061,
              0.4077579975128174,
              -0.9130529761314392,
              0,
              0.4077579975128174,
              -0.6456189751625061,
              0.6456189751625061,
              -0.38502201437950134,
              -0.8526260256767273,
              0.35315999388694763,
              0.4077579975128174,
              -0.9130529761314392,
              0,
              -0.03610340133309364,
              0.5102999806404114,
              0.8592180013656616,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.08478040248155594,
              0.3564560115337372,
              0.9304479956626892,
              -0.0490432009100914,
              0.5007780194282532,
              0.864162027835846,
              -0.1130099967122078,
              0.5992310047149658,
              0.7925350069999695,
              -0.14282700419425964,
              -0.19458599388599396,
              0.9703969955444336,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.2987760007381439,
              -0.18784099817276,
              0.9356359839439392,
              0.19327400624752045,
              -0.9811090230941772,
              -0.006530960090458393,
              0.2082889974117279,
              -0.9595929980278015,
              -0.1890619993209839,
              -0.5174720287322998,
              -0.8556780219078064,
              0,
              -0.048158198595047,
              -0.5908079743385315,
              0.8053529858589172,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              -0.03610340133309364,
              -0.5985289812088013,
              0.8002560138702393,
              0.023285599425435066,
              -0.5147860050201416,
              0.8569599986076355,
              -0.00473036989569664,
              -0.88189297914505,
              0.4713889956474304,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              0.010986699722707272,
              -0.881862998008728,
              0.4713580012321472,
              0.05355999991297722,
              -0.7149270176887512,
              0.6971340179443359,
              -0.048158198595047,
              -0.9978640079498291,
              0.04379409924149513,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              -0.03610340133309364,
              -0.9979249835014343,
              0.05304120108485222,
              0.05355999991297722,
              -0.9768360257148743,
              0.20712900161743164,
              -0.022370100021362305,
              -0.979308009147644,
              -0.20102499425411224,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              -0.008728289976716042,
              -0.9763789772987366,
              -0.21576599776744843,
              0.023285599425435066,
              -0.9985659718513489,
              -0.048066701740026474,
              -0.022370100021362305,
              -0.7112339735031128,
              -0.7025669813156128,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              -0.008728289976716042,
              -0.7218539714813232,
              -0.6919460296630859,
              0.07556380331516266,
              -0.8793910145759583,
              -0.4700460135936737,
              -0.048158198595047,
              -0.5179299712181091,
              -0.8540300130844116,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              -0.03610340133309364,
              -0.5102999806404114,
              -0.8592180013656616,
              0.023285599425435066,
              -0.5947449803352356,
              -0.803551971912384,
              -0.0023499298840761185,
              -0.14062899351119995,
              -0.9900509715080261,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              0.013916400261223316,
              -0.1333959996700287,
              -0.9909359812736511,
              0.05346839874982834,
              -0.3744620084762573,
              -0.9256870150566101,
              -0.02233950048685074,
              0.3817259967327118,
              -0.9239779710769653,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              -0.008728289976716042,
              0.36664900183677673,
              -0.930296003818512,
              0.07782220095396042,
              0.13687600195407867,
              -0.9875180125236511,
              -0.048158198595047,
              0.5908079743385315,
              -0.8053529858589172,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              -0.03610340133309364,
              0.5985289812088013,
              -0.8002560138702393,
              0.023285599425435066,
              0.5147860050201416,
              -0.8569899797439575,
              -0.0047608898021280766,
              0.88189297914505,
              -0.4713889956474304,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              0.010986699722707272,
              0.881862998008728,
              -0.4713580012321472,
              0.05355999991297722,
              0.7149270176887512,
              -0.6971340179443359,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              -0.03610340133309364,
              0.9979249835014343,
              -0.05304120108485222,
              0.05355999991297722,
              0.9768360257148743,
              -0.20712900161743164,
              -0.022370100021362305,
              0.979308009147644,
              0.20102499425411224,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              -0.008728289976716042,
              0.9763789772987366,
              0.21576599776744843,
              0.023285599425435066,
              0.9985659718513489,
              0.048066701740026474,
              -0.022370100021362305,
              0.7112339735031128,
              0.7025669813156128,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              -0.008728289976716042,
              0.7218539714813232,
              0.6919460296630859,
              0.07556380331516266,
              0.8793910145759583,
              0.4700460135936737,
              -0.048158198595047,
              0.5179299712181091,
              0.8540300130844116,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              0.05346839874982834,
              0.3744620084762573,
              0.9256870150566101,
              -0.03610340133309364,
              0.5102999806404114,
              0.8592180013656616,
              0.023285599425435066,
              0.5947449803352356,
              0.803551971912384,
              -0.14285700023174286,
              0.8220160007476807,
              -0.5512250065803528,
              0.2987459897994995,
              0.7925660014152527,
              -0.5315709710121155,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.19983500242233276,
              0.8997470140457153,
              0.387921005487442,
              0.2987459897994995,
              0.7925350069999695,
              0.5315709710121155,
              -0.5136880278587341,
              0.7940919995307922,
              0.3247779905796051,
              0.20148299634456635,
              0.9174169898033142,
              -0.3431200087070465,
              0.2082889974117279,
              0.9595929980278015,
              -0.18903200328350067,
              -0.5136880278587341,
              0.7940919995307922,
              -0.3247779905796051,
              0.1943719983100891,
              0.6910610198974609,
              -0.6961269974708557,
              0.2987459897994995,
              0.7925660014152527,
              -0.5315709710121155,
              -0.5160980224609375,
              0.6017940044403076,
              -0.6094549894332886,
              0.19327400624752045,
              0.9811090230941772,
              0.0065004401840269566,
              0.20831899344921112,
              0.9595929980278015,
              0.1890619993209839,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              -0.3113189935684204,
              -0.5279399752616882,
              -0.7901239991188049,
              0.16083300113677979,
              -0.5483260154724121,
              -0.8206430077552795,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.20142200589179993,
              0.021118799224495888,
              -0.979246973991394,
              0.2987149953842163,
              0.1878110021352768,
              -0.9356669783592224,
              -0.5127419829368591,
              0,
              -0.8585159778594971,
              0.1943719983100891,
              -0.3786740005016327,
              -0.9048740267753601,
              0.2987149953842163,
              -0.1878110021352768,
              -0.9356669783592224,
              -0.5160980224609375,
              -0.33277401328086853,
              -0.7892090082168579,
              0.19635599851608276,
              0.40058600902557373,
              -0.8949549794197083,
              0.160862997174263,
              0.5483260154724121,
              -0.8206120133399963,
              -0.5160980224609375,
              0.33277401328086853,
              -0.7892090082168579,
              0.19635599851608276,
              -0.6735129952430725,
              -0.7125769853591919,
              0.16083300113677979,
              -0.5483260154724121,
              -0.8206430077552795,
              -0.5160980224609375,
              -0.6017940044403076,
              -0.6094549894332886,
              -0.14285700023174286,
              -0.8219550251960754,
              0.5513169765472412,
              0.2987459897994995,
              -0.7925959825515747,
              0.5315099954605103,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.19983500242233276,
              -0.8997470140457153,
              -0.38795098662376404,
              0.2987149953842163,
              -0.7925660014152527,
              -0.5315709710121155,
              -0.5136880278587341,
              -0.7940919995307922,
              -0.3247779905796051,
              0.20148299634456635,
              -0.9174169898033142,
              0.3430890142917633,
              0.2082889974117279,
              -0.9596239924430847,
              0.18897099792957306,
              -0.5136880278587341,
              -0.7940919995307922,
              0.3247779905796051,
              0.1943719983100891,
              0.3786740005016327,
              0.9048740267753601,
              0.2987760007381439,
              0.1877799928188324,
              0.9356359839439392,
              -0.5160980224609375,
              0.33274298906326294,
              0.7892090082168579,
              0.19635599851608276,
              0.6735129952430725,
              0.7125769853591919,
              0.1608940064907074,
              0.5482959747314453,
              0.8206120133399963,
              -0.5160980224609375,
              0.6017940044403076,
              0.6094549894332886,
              -0.2613910138607025,
              0.9470499753952026,
              -0.18637700378894806,
              0.2082889974117279,
              0.9595929980278015,
              -0.18903200328350067,
              -0.5174720287322998,
              0.8556780219078064,
              0,
              0.20142200589179993,
              -0.021118799224495888,
              0.979246973991394,
              0.2987760007381439,
              -0.18784099817276,
              0.9356359839439392,
              -0.5127419829368591,
              0,
              0.8585159778594971,
              0.4077579975128174,
              0.6456189751625061,
              0.6456189751625061,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              -0.38502201437950134,
              0.35315999388694763,
              0.8526260256767273,
              -0.38502201437950134,
              0.8526260256767273,
              0.35315999388694763,
              -1,
              0,
              0,
              -0.38502201437950134,
              0.35315999388694763,
              0.8526260256767273,
              1,
              0,
              0,
              0.4077579975128174,
              0.6456189751625061,
              0.6456189751625061,
              0.4077579975128174,
              0,
              0.9130529761314392,
              0.4077579975128174,
              0.6456189751625061,
              0.6456189751625061,
              -0.38502201437950134,
              0.35315999388694763,
              0.8526260256767273,
              0.4077579975128174,
              0,
              0.9130529761314392,
              -0.38502201437950134,
              -0.35315999388694763,
              0.8526260256767273,
              0.4077579975128174,
              0,
              0.9130529761314392,
              -1,
              0,
              0,
              0.4077579975128174,
              0,
              0.9130529761314392,
              -0.38502201437950134,
              0.35315999388694763,
              0.8526260256767273,
              -1,
              0,
              0,
              0.4077579975128174,
              -0.6456189751625061,
              0.6456189751625061,
              -0.38502201437950134,
              -0.35315999388694763,
              0.8526260256767273,
              -0.38502201437950134,
              -0.8526260256767273,
              0.35315999388694763,
              -0.38502201437950134,
              -0.35315999388694763,
              0.8526260256767273,
              -1,
              0,
              0,
              -0.38502201437950134,
              -0.8526260256767273,
              0.35315999388694763,
              0.4077579975128174,
              -0.9130529761314392,
              0,
              -0.38502201437950134,
              -0.8526260256767273,
              0.35315999388694763,
              -0.38502201437950134,
              -0.8526260256767273,
              -0.35315999388694763,
              -0.38502201437950134,
              -0.8526260256767273,
              0.35315999388694763,
              -1,
              0,
              0,
              -0.38502201437950134,
              -0.8526260256767273,
              -0.35315999388694763,
              1,
              0,
              0,
              0.4077579975128174,
              -0.9130529761314392,
              0,
              0.4077579975128174,
              -0.6456189751625061,
              -0.6456189751625061,
              0.4077579975128174,
              -0.9130529761314392,
              0,
              -0.38502201437950134,
              -0.8526260256767273,
              -0.35315999388694763,
              0.4077579975128174,
              -0.6456189751625061,
              -0.6456189751625061,
              0.4077579975128174,
              -0.6456189751625061,
              -0.6456189751625061,
              -0.38502201437950134,
              -0.8526260256767273,
              -0.35315999388694763,
              -0.38502201437950134,
              -0.35315999388694763,
              -0.8526260256767273,
              -0.38502201437950134,
              -0.8526260256767273,
              -0.35315999388694763,
              -1,
              0,
              0,
              -0.38502201437950134,
              -0.35315999388694763,
              -0.8526260256767273,
              1,
              0,
              0,
              0.4077579975128174,
              -0.6456189751625061,
              -0.6456189751625061,
              0.4077579975128174,
              0,
              -0.9130529761314392,
              0.4077579975128174,
              -0.6456189751625061,
              -0.6456189751625061,
              -0.38502201437950134,
              -0.35315999388694763,
              -0.8526260256767273,
              0.4077579975128174,
              0,
              -0.9130529761314392,
              0.4077579975128174,
              0,
              -0.9130529761314392,
              -0.38502201437950134,
              -0.35315999388694763,
              -0.8526260256767273,
              -0.38502201437950134,
              0.35315999388694763,
              -0.8526260256767273,
              -0.38502201437950134,
              -0.35315999388694763,
              -0.8526260256767273,
              -1,
              0,
              0,
              -0.38502201437950134,
              0.35315999388694763,
              -0.8526260256767273,
              1,
              0,
              0,
              0.4077579975128174,
              0,
              -0.9130529761314392,
              0.4077579975128174,
              0.6456189751625061,
              -0.6456189751625061,
              0.4077579975128174,
              0,
              -0.9130529761314392,
              -0.38502201437950134,
              0.35315999388694763,
              -0.8526260256767273,
              0.4077579975128174,
              0.6456189751625061,
              -0.6456189751625061,
              0.4077579975128174,
              0.6456189751625061,
              -0.6456189751625061,
              -0.38502201437950134,
              0.35315999388694763,
              -0.8526260256767273,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              -0.38502201437950134,
              0.35315999388694763,
              -0.8526260256767273,
              -1,
              0,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              0.4077579975128174,
              0.6456189751625061,
              -0.6456189751625061,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              1,
              0,
              0,
              -0.38502201437950134,
              0.8526260256767273,
              -0.35315999388694763,
              0.4077579975128174,
              0.9130529761314392,
              0,
              1,
              0,
              0,
              0.041016899049282074,
              0.6861780285835266,
              0.7262489795684814,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              0.07431259751319885,
              0.8794819712638855,
              0.4700770080089569,
              0.041016899049282074,
              0.9850760102272034,
              0.16702799499034882,
              -0.09872739762067795,
              0.877590000629425,
              0.4690690040588379,
              0.018250100314617157,
              0.9995419979095459,
              -0.02334669977426529,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              0.05157630145549774,
              0.983551025390625,
              -0.1730400025844574,
              0.056550800800323486,
              0.8804900050163269,
              -0.47062599658966064,
              -0.1091649979352951,
              0.9788810014724731,
              -0.17276500165462494,
              -0.04583879932761192,
              0.9983829855918884,
              -0.03274640068411827,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              -0.1239359974861145,
              0.9915459752082825,
              0.03769040107727051,
              -0.048158198595047,
              0.9978640079498291,
              -0.04379409924149513,
              -0.09643849730491638,
              0.9770200252532959,
              -0.1899780035018921,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              0.022669799625873566,
              0.49987098574638367,
              0.8658030033111572,
              0.9963979721069336,
              0.0049617700278759,
              -0.08465740084648132,
              0.9963979721069336,
              0.0049617700278759,
              -0.08465740084648132,
              0.9963979721069336,
              0.0049617700278759,
              -0.08465740084648132,
              0.9965119957923889,
              0.07392379641532898,
              0.03870439901947975,
              0.9965119957923889,
              0.07392379641532898,
              0.03870439901947975,
              0.9965119957923889,
              0.07392379641532898,
              0.03870439901947975,
              0.022669600322842598,
              -0.49987199902534485,
              0.8658030033111572,
              0.022669600322842598,
              -0.49987199902534485,
              0.8658030033111572,
              0.022669600322842598,
              -0.49987199902534485,
              0.8658030033111572,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9965310096740723,
              -0.06945859640836716,
              -0.045847199857234955,
              0.9965310096740723,
              -0.06945859640836716,
              -0.045847199857234955,
              0.9965310096740723,
              -0.06945859640836716,
              -0.045847199857234955,
              0.9961569905281067,
              0.038665201514959335,
              -0.07859630137681961,
              0.9961569905281067,
              0.038665201514959335,
              -0.07859630137681961,
              0.9961569905281067,
              0.038665201514959335,
              -0.07859630137681961,
              0.9962649941444397,
              -0.036533601582050323,
              -0.07823839783668518,
              0.9962649941444397,
              -0.036533601582050323,
              -0.07823839783668518,
              0.9962649941444397,
              -0.036533601582050323,
              -0.07823839783668518,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9957489967346191,
              0.046055298298597336,
              -0.07976999878883362,
              0.9962660074234009,
              0.04958150163292885,
              -0.07068350166082382,
              0.9962660074234009,
              0.04958150163292885,
              -0.07068350166082382,
              0.9962660074234009,
              0.04958150163292885,
              -0.07068350166082382,
              0.9961040019989014,
              0.08804730325937271,
              -0.005016250070184469,
              0.9961040019989014,
              0.08804730325937271,
              -0.005016250070184469,
              0.9961040019989014,
              0.08804730325937271,
              -0.005016250070184469,
              0.9957489967346191,
              0.09211049973964691,
              1.1025699819811052e-7,
              0.9957489967346191,
              0.09211049973964691,
              1.1025699819811052e-7,
              0.9957489967346191,
              0.09211049973964691,
              1.1025699819811052e-7,
              0.9961130023002625,
              0.08792989701032639,
              0.0051877801306545734,
              0.9961130023002625,
              0.08792989701032639,
              0.0051877801306545734,
              0.9961130023002625,
              0.08792989701032639,
              0.0051877801306545734,
              0.9961720108985901,
              0.04878450185060501,
              0.07253739982843399,
              0.9961720108985901,
              0.04878450185060501,
              0.07253739982843399,
              0.9961720108985901,
              0.04878450185060501,
              0.07253739982843399,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9957489967346191,
              0.046055201441049576,
              0.07976999878883362,
              0.9962069988250732,
              0.037734098732471466,
              0.07840079814195633,
              0.9962069988250732,
              0.037734098732471466,
              0.07840079814195633,
              0.9962069988250732,
              0.037734098732471466,
              0.07840079814195633,
              0.9961069822311401,
              -0.03965729847550392,
              0.07872310280799866,
              0.9961069822311401,
              -0.03965729847550392,
              0.07872310280799866,
              0.9961069822311401,
              -0.03965729847550392,
              0.07872310280799866,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9957489967346191,
              -0.046055298298597336,
              0.07976999878883362,
              0.9962249994277954,
              -0.04919629916548729,
              0.07152830064296722,
              0.9962249994277954,
              -0.04919629916548729,
              0.07152830064296722,
              0.9962249994277954,
              -0.04919629916548729,
              0.07152830064296722,
              0.9960730075836182,
              -0.08841490000486374,
              0.0045111700892448425,
              0.9960730075836182,
              -0.08841490000486374,
              0.0045111700892448425,
              0.9960730075836182,
              -0.08841490000486374,
              0.0045111700892448425,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0599200095384731e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0599200095384731e-7,
              0.9957489967346191,
              -0.09211049973964691,
              -1.0599200095384731e-7,
              0.9961699843406677,
              -0.08723609894514084,
              -0.00598698016256094,
              0.9961699843406677,
              -0.08723609894514084,
              -0.00598698016256094,
              0.9961699843406677,
              -0.08723609894514084,
              -0.00598698016256094,
              0.9960820078849792,
              -0.048212699592113495,
              -0.07413849979639053,
              0.9960820078849792,
              -0.048212699592113495,
              -0.07413849979639053,
              0.9960820078849792,
              -0.048212699592113495,
              -0.07413849979639053,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              0.9957489967346191,
              -0.046055201441049576,
              -0.07977010309696198,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              -0.05690649896860123,
              -0.5258899927139282,
              0.8486459851264954,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              0.00747400987893343,
              -0.9976959824562073,
              0.06743550300598145,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.05851290002465248,
              0.5474960207939148,
              -0.8347600102424622,
              -0.019196100533008575,
              -0.4004330039024353,
              0.9161049723625183,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              -0.1239359974861145,
              -0.5195170044898987,
              0.8453930020332336,
              -0.02233950048685074,
              -0.3817259967327118,
              0.9239779710769653,
              -0.07388529926538467,
              -0.14462700486183167,
              0.9866939783096313,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              -0.002531789941713214,
              0.4999980032444,
              0.8660230040550232,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              1.7038900068655494e-8,
              -0.39753299951553345,
              -0.9175879955291748,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.058024000376462936,
              0.46330100297927856,
              -0.8842989802360535,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.0006106719956733286,
              -0.5,
              -0.8660249710083008,
              -0.00943022035062313,
              0.7729730010032654,
              0.6343579888343811,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              -0.06653030216693878,
              0.6329839825630188,
              0.7712939977645874,
              0,
              0.7730029821395874,
              0.6343880295753479,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              0.07126069813966751,
              0.9926450252532959,
              0.09775079786777496,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              -0.05648979917168617,
              0.9553819894790649,
              0.28980401158332825,
              0.07126069813966751,
              0.8796650171279907,
              0.47016799449920654,
              0.10177899897098541,
              0.9519640207290649,
              0.2887659966945648,
              -0.00943022035062313,
              0.9568769931793213,
              0.29026201367378235,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              -0.06653030216693878,
              0.8799399733543396,
              0.4703510105609894,
              0,
              0.9569380283355713,
              0.29026201367378235,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              -0.05648979917168617,
              0.9935910105705261,
              -0.09784229844808578,
              0.07126069813966751,
              0.9926450252532959,
              0.09775079786777496,
              0.10177899897098541,
              0.9899899959564209,
              -0.09750659763813019,
              -0.00943022035062313,
              0.9951170086860657,
              -0.09799490123987198,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              -0.06653030216693878,
              0.9929500222206116,
              0.09778130054473877,
              0,
              0.9951779842376709,
              -0.09799490123987198,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              -0.05648979917168617,
              0.8804900050163269,
              -0.47062599658966064,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              -0.05648979917168617,
              0.8804900050163269,
              -0.47062599658966064,
              0.07126069813966751,
              0.9544969797134399,
              -0.2895289957523346,
              0.10177899897098541,
              0.8773159980773926,
              -0.46894699335098267,
              -0.00943022035062313,
              0.881862998008728,
              -0.4713580012321472,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              -0.06653030216693878,
              0.9548019766807556,
              -0.2896209955215454,
              0,
              0.88189297914505,
              -0.4713889956474304,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              0.07129120081663132,
              0.47019898891448975,
              -0.8796650171279907,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              -0.05648979917168617,
              0.6333510279655457,
              -0.7717519998550415,
              0.07126069813966751,
              0.7710199952125549,
              -0.6327710151672363,
              0.10181000083684921,
              0.6310920119285583,
              -0.768975019454956,
              -0.00943022035062313,
              0.6343269944190979,
              -0.7729730010032654,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              -0.06653030216693878,
              0.7712640166282654,
              -0.6329839825630188,
              0,
              0.6343579888343811,
              -0.7730029821395874,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              -0.05648979917168617,
              0.28980401158332825,
              -0.9553819894790649,
              0.07129120081663132,
              0.47019898891448975,
              -0.8796650171279907,
              0.10187099874019623,
              0.2887659966945648,
              -0.9519330263137817,
              -0.00943022035062313,
              0.29026201367378235,
              -0.9568769931793213,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              -0.06653030216693878,
              0.47032099962234497,
              -0.8799399733543396,
              0,
              0.29026201367378235,
              -0.9569380283355713,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              -0.05648979917168617,
              -0.09784229844808578,
              -0.9935910105705261,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              -0.05648979917168617,
              -0.09784229844808578,
              -0.9935910105705261,
              0.07129120081663132,
              0.09775079786777496,
              -0.9926450252532959,
              0.10187099874019623,
              -0.09747610241174698,
              -0.9899899959564209,
              -0.00943022035062313,
              -0.09799490123987198,
              -0.9951170086860657,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              -0.06653030216693878,
              0.09778130054473877,
              -0.9929500222206116,
              0,
              -0.09799490123987198,
              -0.9951779842376709,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              0.07126069813966751,
              -0.6327710151672363,
              -0.7710199952125549,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              -0.05648979917168617,
              -0.47062599658966064,
              -0.8804900050163269,
              0.07129120081663132,
              -0.2895289957523346,
              -0.9544969797134399,
              0.10183999687433243,
              -0.46891701221466064,
              -0.8773159980773926,
              -0.00943022035062313,
              -0.4713580012321472,
              -0.881862998008728,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              -0.06653030216693878,
              -0.2896209955215454,
              -0.9548019766807556,
              0,
              -0.4713889956474304,
              -0.8819239735603333,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              -0.05648979917168617,
              -0.7717519998550415,
              -0.6333810091018677,
              0.07126069813966751,
              -0.6327710151672363,
              -0.7710199952125549,
              0.10177899897098541,
              -0.768975019454956,
              -0.6310920119285583,
              -0.00943022035062313,
              -0.7729420065879822,
              -0.6343579888343811,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              -0.06653030216693878,
              -0.6329839825630188,
              -0.7712939977645874,
              0,
              -0.7730029821395874,
              -0.6343880295753479,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              -0.05648979917168617,
              -0.9553819894790649,
              -0.28980401158332825,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              -0.05648979917168617,
              -0.9553819894790649,
              -0.28980401158332825,
              0.07126069813966751,
              -0.8796650171279907,
              -0.47016799449920654,
              0.10177899897098541,
              -0.9519640207290649,
              -0.2887659966945648,
              -0.00943022035062313,
              -0.9568769931793213,
              -0.29026201367378235,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              -0.06653030216693878,
              -0.8799399733543396,
              -0.4703510105609894,
              0,
              -0.9569380283355713,
              -0.29026201367378235,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              0.07126069813966751,
              -0.9544969797134399,
              0.2895289957523346,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              -0.05648979917168617,
              -0.9935910105705261,
              0.09784229844808578,
              0.07126069813966751,
              -0.9926450252532959,
              -0.09775079786777496,
              0.10177899897098541,
              -0.9899899959564209,
              0.09747610241174698,
              -0.00943022035062313,
              -0.9951170086860657,
              0.09799490123987198,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              -0.06653030216693878,
              -0.9929500222206116,
              -0.09778130054473877,
              0,
              -0.9951779842376709,
              0.09799490123987198,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              -0.05648979917168617,
              -0.8804900050163269,
              0.47062599658966064,
              0.07126069813966751,
              -0.9544969797134399,
              0.2895289957523346,
              0.10177899897098541,
              -0.8773159980773926,
              0.46891701221466064,
              -0.00943022035062313,
              -0.881862998008728,
              0.4713580012321472,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              -0.06653030216693878,
              -0.9548019766807556,
              0.2896209955215454,
              0,
              -0.88189297914505,
              0.4713889956474304,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              -0.05648979917168617,
              -0.6333510279655457,
              0.7717519998550415,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              -0.05648979917168617,
              -0.6333510279655457,
              0.7717519998550415,
              0.07126069813966751,
              -0.7710199952125549,
              0.6327710151672363,
              0.10177899897098541,
              -0.6310920119285583,
              0.768975019454956,
              -0.00943022035062313,
              -0.6343579888343811,
              0.7729730010032654,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              -0.06653030216693878,
              -0.7712939977645874,
              0.6329839825630188,
              0,
              -0.6343579888343811,
              0.7730029821395874,
              -0.06653030216693878,
              -0.4703510105609894,
              0.8799399733543396,
              0.07126069813966751,
              -0.09775079786777496,
              0.9926450252532959,
              -0.05648979917168617,
              -0.28980401158332825,
              0.9553819894790649,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              -0.05648979917168617,
              -0.28980401158332825,
              0.9553819894790649,
              0.07126069813966751,
              -0.47019898891448975,
              0.8796650171279907,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              0,
              0.09799490123987198,
              0.9951779842376709,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              -0.06653030216693878,
              0.2896209955215454,
              0.9548019766807556,
              -0.00943022035062313,
              0.09799490123987198,
              0.9951170086860657,
              -0.06653030216693878,
              -0.09778130054473877,
              0.9929500222206116,
              0.10177899897098541,
              -0.2887659966945648,
              0.9519640207290649,
              0.7549669742584229,
              -0.1903740018606186,
              0.6274610161781311,
              0.7557299733161926,
              -0.0641803964972496,
              0.6516919732093811
            ],
            "normalized": false
          },
          "color": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1
            ],
            "normalized": false
          }
        },
        "groups": [
          {
            "start": 0,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 6,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 12,
            "materialIndex": 0,
            "count": 21
          },
          {
            "start": 33,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 39,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 42,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 48,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 54,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 60,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 66,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 69,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 75,
            "materialIndex": 0,
            "count": 27
          },
          {
            "start": 102,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 108,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 117,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 123,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 126,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 132,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 138,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 144,
            "materialIndex": 0,
            "count": 15
          },
          {
            "start": 159,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 165,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 171,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 183,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 189,
            "materialIndex": 0,
            "count": 15
          },
          {
            "start": 204,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 207,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 216,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 222,
            "materialIndex": 2,
            "count": 6
          },
          {
            "start": 228,
            "materialIndex": 0,
            "count": 27
          },
          {
            "start": 255,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 261,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 267,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 273,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 282,
            "materialIndex": 3,
            "count": 9
          },
          {
            "start": 291,
            "materialIndex": 0,
            "count": 24
          },
          {
            "start": 315,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 321,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 333,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 339,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 348,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 354,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 357,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 363,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 375,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 381,
            "materialIndex": 0,
            "count": 39
          },
          {
            "start": 420,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 426,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 432,
            "materialIndex": 3,
            "count": 9
          },
          {
            "start": 441,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 453,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 459,
            "materialIndex": 0,
            "count": 24
          },
          {
            "start": 483,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 486,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 492,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 504,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 507,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 513,
            "materialIndex": 0,
            "count": 18
          },
          {
            "start": 531,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 537,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 543,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 549,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 555,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 561,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 567,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 579,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 585,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 603,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 615,
            "materialIndex": 3,
            "count": 15
          },
          {
            "start": 630,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 636,
            "materialIndex": 3,
            "count": 24
          },
          {
            "start": 660,
            "materialIndex": 0,
            "count": 9
          },
          {
            "start": 669,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 681,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 684,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 690,
            "materialIndex": 0,
            "count": 21
          },
          {
            "start": 711,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 723,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 726,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 732,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 738,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 744,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 750,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 768,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 780,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 783,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 789,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 795,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 807,
            "materialIndex": 0,
            "count": 3
          },
          {
            "start": 810,
            "materialIndex": 3,
            "count": 3
          },
          {
            "start": 813,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 819,
            "materialIndex": 3,
            "count": 12
          },
          {
            "start": 831,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 837,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 843,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 849,
            "materialIndex": 3,
            "count": 3918
          },
          {
            "start": 4767,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 4773,
            "materialIndex": 3,
            "count": 225
          },
          {
            "start": 4998,
            "materialIndex": 4,
            "count": 6
          },
          {
            "start": 5004,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 5010,
            "materialIndex": 4,
            "count": 12
          },
          {
            "start": 5022,
            "materialIndex": 3,
            "count": 147
          },
          {
            "start": 5169,
            "materialIndex": 4,
            "count": 72
          },
          {
            "start": 5241,
            "materialIndex": 3,
            "count": 18
          },
          {
            "start": 5259,
            "materialIndex": 0,
            "count": 90
          },
          {
            "start": 5349,
            "materialIndex": 3,
            "count": 6
          },
          {
            "start": 5355,
            "materialIndex": 0,
            "count": 12
          },
          {
            "start": 5367,
            "materialIndex": 1,
            "count": 6
          },
          {
            "start": 5373,
            "materialIndex": 0,
            "count": 6
          },
          {
            "start": 5379,
            "materialIndex": 3,
            "count": 165
          }
        ]
      }
    }
  ],
  "materials": [
    {
      "uuid": "84BC5FAA-54FE-464C-9E8B-C7F3D14184D3",
      "type": "MeshNormalMaterial",
      "depthFunc": 3,
      "depthTest": true,
      "depthWrite": true,
      "wireframe": true
    }
  ],
  "object": {
    "uuid": "677DBE11-A47B-4175-8D51-24277A78D50C",
    "type": "Mesh",
    "layers": 1,
    "matrix": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    ],
    "geometry": "FCB29F3C-570E-416C-900F-95AE652F0073",
    "material": "84BC5FAA-54FE-464C-9E8B-C7F3D14184D3"
  }
}
